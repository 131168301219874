import EmptyPoolIcon from '@images/emptyPool.svg'
import { EmptyPoolBox } from './style'

const EmptyPool = () => {
  return (
    <EmptyPoolBox>
      <img src={EmptyPoolIcon} alt="icon" />
      <strong>EMPTy POOL</strong>
      <b>PLEASE WAIT...</b>
    </EmptyPoolBox>
  )
}

export default EmptyPool
