import styled, { css } from 'styled-components'

interface TotalsItemContainerProps {
  fontColor: string
  backgroundColor: string
  bold: boolean
}

interface ArrowWrapperProps {
  percentage: number
}

export const TotalsItemContainer = styled.div<TotalsItemContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 1.6rem;
  padding-left: 1.95rem;
  padding-right: 1rem;

  ${({ bold }) =>
    bold
      ? css`
          font-weight: bold;
        `
      : ''}

  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ fontColor }) => fontColor};
`

export const RightGroup = styled.div`
  display: flex;
  align-items: center;
`

export const DescriptionGroup = styled.div`
  font-size: 1rem;
`

type CountSpanProps = {
  countColor: string
}

export const CountSpan = styled.span<CountSpanProps>`
  color: ${({ countColor }) => countColor};
  text-align: right;
  font-family: Nebula;
  font-size: 1rem;
  margin-right: 1.7rem;
`

export const PercentGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.3rem;
`
export const Percent = styled.span`
  font-family: 'Oxanium';
  font-weight: 400;
  font-size: 0.8rem;
  color: #7c7c80;
  width: 3rem;
  text-align: right;
  height: 100%;
`

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
  position: relative;
  min-width: 20px;
  text-align: center;

  svg {
    height: 0.8rem;
    color: ${({ percentage }) =>
    percentage >= 1 ? '#32D74B' : percentage < 0 ? '#FF3B30' : '#8E8E93'};
  }
`
