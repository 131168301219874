import styled from "styled-components";

interface StyledProps {
    typeBG?: Blob;
    typeColor?: string;
    first?: boolean;
    password?: boolean;
    hasValue?: boolean;
    hasError?: boolean;
    disable?: boolean;
    name?: string;
    src?: any;
}

const Background = styled.div<StyledProps>`
    background-image: url(${({ typeBG }: any) => typeBG});
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-repeat: no-repeat;
`;

const BackgroundGredientlayer = styled.div`
    padding: 80px 0;
    background: linear-gradient(to right, rgba(12, 12, 13, 10%) 0%, rgba(12, 12, 13, 0%) 50%, rgba(12, 12, 13, 70%) 100%);
    height: 100%;
    width: 100%;
    @media (max-width: 1400px) {
        padding: 50px 0;
    }
`;

const FormBox = styled.div`
    background-color: #0C0C0D;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 440px;
    height: 100%;
    margin: 0 auto;
    border-radius: 12px;
    opacity: 1;
    text-align: center;
    padding:80px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.80);
    @media (max-width: 1400px) {
        padding:60px 80px;
        width: 400px;
    }
`;

const LoginImgBox = styled.div<StyledProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 35px;
    @media (max-width: 1400px) {
        margin-bottom:25px;
    }
`;

const ViswalImgBox = styled.div<StyledProps>`
    border: 2px solid #1C1C1E;
    border-radius: 7px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const LoginTitleBox = styled.div`
    margin-bottom: 76px;
    letter-spacing: 8px;
    font-family: Oxanium;
    font-size:13px;
    @media (max-width: 1400px) {
        margin-bottom:40px;
    }
`;

const LoginTitleSecond = styled.span<StyledProps>`
    color: #FF813E;
    margin-left: 10px;
`;

const InputSection = styled.div`
    margin-bottom:64px;
    display: flex;
    flex-direction: column;
    @media (max-width: 1400px) {
        margin-bottom:30px;
    }
`;

const Input = styled.input<StyledProps>`
    width:100%;
    height: 100%;
    border: 1px solid ${({ hasValue, hasError }: any) => hasError ? "#E44130" : (hasValue ? "#FF813E" : "#FF813E")};
    color: #ffffff; 
    padding: 0px 10px;
    padding-right: ${({ password }: any) => password ? '72px' : '37px'};
    background:transparent;
    border-radius:5px ;
    font-size: var(--fz-xxs);
    font-family: Oxanium;
    caret-color: #FF813E;
    &:focus-visible{
        border: 2px solid #FF813E !important;
        outline: none !important;
        color: #AEAEB2 !important;
    }
`;

const LoginText = styled.div<StyledProps>`
    font-family: Nebula;
    font-weight: bold;
    color: #FF813E;
    font-size: var(--fz-md);
    margin-bottom: 45px;
    @media (max-width: 1400px) {
        margin-bottom:30px;
    }
`;

const BottomTitleBox = styled.div<StyledProps>`
    margin-bottom: 7px;
    letter-spacing: 3px;
    font-family: Nebula;
    font-weight: bold;
    color: #FF813E;
    font-size:13px;
`;

const InputBox = styled.div<StyledProps>`
    position : relative;
    height: 40px;
    margin-top: ${({ first }: any) => !first && '15px'};
    @media (max-width: 1400px) {
        height: 35px;
    }
`;

const InputButtonContainer = styled.label`
    position : absolute;
    right:13px;
    top: 0px;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
`;

const ErrorBox = styled.div`
    display: flex;
    gap: 8px;
    font-family: Oxanium;
    color: #E44130;
    font-size: var(--fz-xxs);
    margin-top: 5px;
`;

const ClosePasswordErrorText = styled.div`
`;

const BottomTitleFirst = styled.span`
`;

const BottomTitleSecond = styled.span`
    opacity: 40%;
`;

const BottomSubTitle = styled.div`
    font-family: Oxanium;
    color: #545456;
    font-size: var(--fz-xxs);
    letter-spacing: 3px;
`;

const FormItems = styled.div`
    width: 100%;
`;

const EnterButton = styled.div<StyledProps>`
    width: 100%;
    height: 40px;
    margin-bottom: 80px;
    background-color: #FF813E;
    color: #121212;
    display: flex;
    opacity: ${({ disable }: any) => disable ? '15%' : '0%'};
    justify-content: center;
    align-items: center;
    font-size:var(--fz-xs);
    font-family: Oxanium;
    font-weight: bold;
    opacity: ${({ disable }: any) => disable ? '15%' : '100%'};
    border-radius: 5px;
    cursor: ${({ disable }: any) => disable ? 'default' : 'pointer'};
    @media (max-width: 1400px) {
        height: 35px;
        margin-bottom:50px;
    }
`

export {
    Background,
    BackgroundGredientlayer,
    FormBox,
    LoginTitleBox,
    LoginText,
    BottomSubTitle,
    Input,
    InputSection,
    BottomTitleBox,
    BottomTitleFirst,
    BottomTitleSecond,
    LoginTitleSecond,
    EnterButton,
    LoginImgBox,
    InputBox,
    InputButtonContainer,
    FormItems,
    ErrorBox,
    ClosePasswordErrorText,
    ViswalImgBox
}