import { useCallback } from 'react'
import useDiary from '@src/hooks/useDiary'
import Layout from '@src/components/Layout'
import moment from 'moment'
import { useLocation, useNavigate } from 'react-router-dom'

import { LandingOnType } from '@viswals/services'
import { Icon, UserPhoto } from '@viswals/components'
import {
  ButtonGroup,
  ExpandedSearchBar,
  LinkGroup,
  LinkProps,
  WhoIsIn,
  UpperCalendar,
} from '@viswals/components-platform'

import useClinicianRegister from '@src/hooks/useClinicianRegister'
import {
  ButtonGroupWrapper,
  CurrentDate,
  LinkGroupWrapper,
  SearchWrapper,
} from '@src/components/Layout/UpperBar/style'
import {
  whoIsInTooltip,
  upperBarLandingOn,
} from '@src/components/Layout/UpperBar/upperBarData'

import useLayout from '@src/hooks/useLayout'
import GoogleMap from './Maps'
import {
  CalendarAction,
  Container,
  ContainerUpperCalendarChildren,
  MapArea,
  ProfileIcon,
  TBProfileIconContainer,
  TBProfileIconSection,
  TopLabel,
} from './style'

import GlobalViewTotal from './components/GlobalViewTotals'

const options: LinkProps[] = [
  {
    value: '/global-view',
    title: 'Global View',
    icon: 'GlobalView',
  },
  {
    value: '/global-accounts',
    title: 'Global Accounts',
    icon: 'GlobalView',
  },
  {
    value: '/livepool',
    title: 'Live Pool',
    icon: 'Roadmap',
  },
]

const GlobalView = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { layout, setLayout } = useLayout()
  const { diary, updateLandingOn, updateUpperCalendarSelect } = useDiary()
  const { clinician } = useClinicianRegister()

  const useRouterCallback = useCallback(() => {
    return options.find((f) => f.value === location.pathname)?.value
  }, [location])

  const activeLink = useRouterCallback()
  const openUpperCalendar = layout.openUpperCalendar ?? false

  return (
    <Layout
      upperBarChilden={
        <ContainerUpperCalendarChildren>
          <div className="leftBox">
            <CurrentDate>{moment().format('D MMMM YYYY')}</CurrentDate>

            <LinkGroupWrapper>
              <LinkGroup
                linkActive={activeLink}
                options={options}
                styleButton="CC_BUTTON"
                handleClick={(value) => navigate(value as string)}
              />
            </LinkGroupWrapper>
          </div>

          <div className="rightBox">
            <CalendarAction
              onClick={() => setLayout('openUpperCalendar', !openUpperCalendar)}
              aria-hidden="true"
              className={openUpperCalendar ? 'active' : ''}
            >
              <Icon name="CalendarCcHeader" />
              <span>Calendar</span>
            </CalendarAction>
            <ButtonGroupWrapper>
              {upperBarLandingOn && (
                <ButtonGroup
                  isActive={diary?.landingOn}
                  options={upperBarLandingOn}
                  handleClick={(value) => {
                    updateLandingOn(value as LandingOnType)
                    if (value === 'AS_LEFT') {
                      setLayout('openUpperCalendar', false)
                    }
                  }}
                />
              )}
            </ButtonGroupWrapper>

            <CalendarAction
              onClick={() => navigate('/global-view/pre-registration')}
              aria-hidden="true"
              style={{ marginLeft: '53px', marginRight: '24px' }}
            >
              <Icon name="GlobalView" />
              <span>Worldwide Pre-Registration</span>
            </CalendarAction>

            <TBProfileIconContainer focus={true}>
              <TBProfileIconSection focus={true}>
                <ProfileIcon src={'https://i.pravatar.cc/300?img=5'} />
              </TBProfileIconSection>
            </TBProfileIconContainer>

          </div>
        </ContainerUpperCalendarChildren>
      }
    >
      <Container>
        {openUpperCalendar && (
          <UpperCalendar
            weekStart={diary.diary?.weekStart ?? 'MON'}
            viewMode={diary.landingOn ?? 'WEEK'}
            dayOfData={[]}
            progressData={[]}
            onChangeSelect={(startSelect, endSelect) =>
              updateUpperCalendarSelect(startSelect, endSelect)
            }
            onChangeCalendar={() => { }}
            selectedDate={{
              start: diary.upperCalendarStartSelect ?? moment(),
              end: diary.upperCalendarEndSelect ?? moment(),
            }}
          />
        )}

        <MapArea>
          <TopLabel>
            <p>
              <span>Global Live </span> Stats
            </p>
          </TopLabel>

          <GoogleMap />
        </MapArea>

        <GlobalViewTotal />
      </Container>
    </Layout>
  )
}

export default GlobalView
