import styled from 'styled-components'

export function LoadingTable() {
  return (
    <div>
      <LoadingContainer className="vw-loadingContainer">
        <div>
          <div className="ring">
            <span />
          </div>
        </div>
      </LoadingContainer>
    </div>
  )
}

const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  overflow: hidden;
  top: 0;
  left: 0;
  background-color: #222224;
  .ring {
    position: absolute;
    bottom: calc(50% - 25px);
    right: calc(50% - 25px);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-transform: uppercase;
    width: 50px;
    height: 50px;
  }
  .ring:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid var(--emerald-color);
    border-right: 3px solid var(--emerald-color);
    border-radius: 50%;
    animation: animateC 1s linear infinite;
  }

  @keyframes animateC {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
`

export default LoadingContainer
