import React from 'react'
import { Icon } from '@viswals/components'
import { PlansCardArea } from './RegistrationModeStyles'
import { PlansCardProps } from './props'
import { plansItemsData, plansItemsCheck } from './data'

type ItemProps = { item: PlansCardProps; index: number }

const PlansCard = ({ item, index }: ItemProps) => {
  const checklist = plansItemsCheck[index]
  const { category2, priceMonth } = item

  return (
    <PlansCardArea>
      <div className="headerArea">
        <h2 className="title">{category2}</h2>
        <h2 className="price">
          ${priceMonth} <span> / Month</span>
        </h2>
      </div>

      <div className="middleArea">
        <ul>
          {plansItemsData.map((plan, i) => {
            return (
              <li className="gsapCardItem">
                <span>
                  {checklist[i] === true ? (
                    <Icon
                      name="Check1"
                      styles={{ color: '#0CE53E', height: '1.3rem' }}
                    />
                  ) : (
                    <Icon name="Close3" styles={{ color: '#FF5555' }} />
                  )}
                </span>
                <p className={`${checklist[i] === false && 'itemFalse'}`}>
                  {i === 5 && index === 1
                    ? 'Live X & Live Now subjacent to Clinic´s subscription'
                    : plan}
                </p>
              </li>
            )
          })}
        </ul>
      </div>
    </PlansCardArea>
  )
}

export default PlansCard
