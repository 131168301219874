import { Rate, RateType } from '@viswals/services'
import { Icon } from '@viswals/components-platform'

import { getLabelRate } from '../../utils/utils'
import ContainerIconRate from './styles'

export type IconRateProps = {
  itemReport: Rate
}

export default function IconRate({ itemReport }: IconRateProps) {
  return (
    <ContainerIconRate
      color={getLabelRate(itemReport.rate as RateType)?.color}
      className="content-info"
    >
      <div className="content-sub-info">
        <div className="icon-rate-box-radius">
          <Icon name={getLabelRate(itemReport.rate as RateType)?.icon} />
        </div>
        <div>{getLabelRate(itemReport.rate as RateType)?.label}</div>
      </div>
      <div className="content-icon-stars">
        {Array.from(
          { length: getLabelRate(itemReport.rate as RateType)?.value },
          (_, i) => i + 1
        ).map((page, _indexed) => {
          return <Icon key={_indexed} name="Star1" />
        })}
      </div>
    </ContainerIconRate>
  )
}
