import {
  Country,
  CountryFilter,
  OccupationFilter,
  LanguageFilter,
  Occupation,
  Registration,
  RegulatoryBodyFilter,
  Request,
  retrieveCountry,
  retrieveOccupation,
  retrieveLanguage,
  retrieveRegulatoryBody,
} from '@viswals/services'
import store from '@src/store'
import {
  setDataCountries,
  setDataOccupations,
  setDataFields,
  setDataLanguages,
  setDataRegulatoryBodies,
} from '@src/store/actions/datas'
import { RegistrationProp } from '@src/store/reducers/clinician/props'

async function getCountries() {
  const request: Request<CountryFilter> = {
    fields: [
      'uuid',
      'name',
      'icon',
      'isoCode',
      'countryCode',
      'latitude',
      'longitude',
      'continent',
      'enabled',
      'updatedAt',
      'createdAt',
    ],
    orderBy: {
      column: 'name',
      direction: 'asc',
    },
    pagination: {
      itemsPerPage: 250,
      page: 1,
    },
  }
  const resp = await retrieveCountry(request)
  const dataCountries: Country[] = resp.data.data
  store.dispatch(setDataCountries(dataCountries))
  return dataCountries ?? []
}

async function getOccupations() {
  const request: Request<OccupationFilter> = {
    fields: ['uuid', 'name', 'enabled', 'updatedAt', 'createdAt'],
    pagination: { itemsPerPage: 200, page: 1 },
    orderBy: {
      column: 'name',
      direction: 'asc',
    },
  }
  const resp = await retrieveOccupation(request)
  const dataOccupations: Occupation[] = resp.data.data
  store.dispatch(setDataOccupations(dataOccupations))
  return dataOccupations ?? []
}

async function getLanguages() {
  const request: Request<LanguageFilter> = {
    fields: ['uuid', 'name', 'isoCode'],
  }
  const resp = await retrieveLanguage(request)
  store.dispatch(setDataLanguages(resp.data.data))
}

async function getRegulatoryBodies() {
  const request: Request<RegulatoryBodyFilter> = {
    fields: ['uuid', 'name', 'isoCode'],
  }
  const resp = await retrieveRegulatoryBody(request)
  store.dispatch(setDataRegulatoryBodies(resp.data.data))
  return resp.data.data ?? []
}

async function getFields() {
  const request: Request<any> = {
    fields: ['uuid', 'name', 'description'],
  }
  // const resp = await retrieveField(request)
  // store.dispatch(setDataFields(resp.data.data))
  // return resp.data.data ?? []
}

async function mergeRegistration(
  registrations: Registration[],
  countries: Country[]
) {
  const data = registrations?.map((r: Registration) => ({
    ...r,
    country: countries.find((f) => f.uuid === r.countryRegistration),
  })) as RegistrationProp[]
  return data
}

async function getDatas() {
  await getCountries()
  await getOccupations()
  await getLanguages()
}

export {
  getCountries,
  getOccupations,
  getRegulatoryBodies,
  mergeRegistration,
  getFields,
  getLanguages,
  getDatas,
}
