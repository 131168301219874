import { Bank } from '@viswals/services'
import { ActionProps, DatasProps } from './props'

const INITIAL_STATE: DatasProps = {
  countries: [],
  occupations: [],
  regulatoryBodies: [],
  fields: [],
  cities: [],
  banks: [],
  clinicianBirthDate: '',
}

export default function reducer(state = INITIAL_STATE, action: ActionProps) {
  if (action.type === 'SET_DATA_COUNTRIES') {
    return {
      ...state,
      countries: action.countries,
    }
  }

  if (action.type === 'SET_DATA_OCCUPATIONS') {
    return {
      ...state,
      occupations: action.occupations,
    }
  }

  if (action.type === 'SET_DATA_LANGUAGES') {
    return {
      ...state,
      languages: action.languages,
    }
  }

  if (action.type === 'SET_DATA_FIELDS') {
    return {
      ...state,
      fields: action.fields,
    }
  }

  if (action.type === 'SET_DATA_CLINICIANBIRTHDATE') {
    return {
      ...state,
      clinicianBirthDate: action.clinicianBirthDate,
    }
  }

  if (action.type === 'SET_DATA_REGULATORY_BODIES') {
    return {
      ...state,
      regulatoryBodies: action.regulatoryBodies,
    }
  }
  if (action.type === 'SET_DATA_BANKS') {
    const { countryUuid } = (action.banks as Bank[])[0]
    if (state.banks?.find((f) => f.countryUuid === countryUuid)) {
      return state
    }
    return {
      ...state,
      banks: [...(state.banks ?? []), ...(action.banks ?? [])],
    }
  }

  if (action.type === 'SET_DATA_CITIES') {
    const { countryUuid } = (action.cities as Bank[])[0]
    if (state.cities?.find((f) => f.countryUuid === countryUuid)) {
      return state
    }
    return {
      ...state,
      cities: [...(state.cities ?? []), ...(action.cities ?? [])],
    }
  }

  return state
}
