import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-personalInformation-header {
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-personalInformation-cardArea {
    display: flex;
    .vw-card-cardBox {
      width: 48.4rem;
      padding: 0 2.5rem 5rem;
      margin-right: 2.5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .vw-select-BoxInput {
        background: #242426;
      }

      .vw-personalInformation-img {
        height: 33.8rem;
        padding-top: 7.8rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
          width: 14.3rem;
        }
        &.img2 {
          padding: 7.5rem 5.3rem 0 0;

          img {
            width: 20.6rem;
          }
        }

        &.img3 {
          padding: 8.8rem 0 0 1.6rem;

          img {
            width: 16.6rem;
          }
        }
      }

      p {
        font-family: var(--font-primary);
        font-weight: 700;
        font-size: 1.4rem;
        text-align: start;

        margin-bottom: 1rem;
      }

      .vw-personalInformation-oldForm {
        .vw-select-boxIconRight {
          margin: 0;
          padding-left: 0.1rem;
        }
        div {
          margin-bottom: 0.5rem;
          .vw-selectOptions-boxSelectOptions {
            background: #24242670;
            .vw-selectOptions-boxScrollOptions {
              height: 36rem;
              gap: 0rem;

              .vw-selectOptions-boxContentScrollItem {
                .vw-selectOptions-boxScrollItemOptions {
                  padding: 0.6rem 1rem 0.7rem;
                }
              }
            }
          }
        }

        .vw-input-boxContentInput {
          margin-bottom: 1rem;
        }
      }

      .vw-personalInformation-genderSelectArea {
        > label {
          height: 5.2rem;
          padding: 1.4rem 2rem;

          display: flex;
          justify-content: space-between;
          background-color: #242426;
          border-radius: 0.5rem;
          margin-bottom: 1rem;

          cursor: pointer;

          p {
            font-family: var(--font-secondary);
            font-weight: 400;
            line-height: 2.4rem;
            color: var(--gray-200-color);
          }
          &.isDisabled {
            p {
              opacity: 0.5;
            }
          }
        }

        .isCheck {
          border-color: #cccccc;
        }

        .vw-sectionRadio {
          background: #cccccc;
        }
      }
    }
  }
`
