import styled from 'styled-components'

export const ContainerBox = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
`

export const HeaderBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1.6rem;

  svg {
    height: 4rem;
    padding: 0;
  }

  .col {
    font-family: 'Nebula';
    font-size: 1rem;
    color: #545456;

    &:nth-child(1) {
      margin-bottom: -0.3rem;
    }
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      div:last-child {
        font-size: 0.9rem;
        color: #aeaeb2;
      }
    }
    &:nth-child(3) {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      div:last-child {
        font-size: 0.9rem;
        color: #aeaeb2;
        letter-spacing: 0.6rem;
      }
    }
  }
`

export const ColorxBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;

  margin-top: 4rem;
  margin-bottom: 1rem;

  div {
    content: '';
    height: 0.1rem;
    width: 100%;
    background-color: #3a3a3c;

    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(n + 2) {
      width: 3.2rem;
    }

    &:nth-child(2) {
      background-color: #ff3b30;
    }
    &:nth-child(3) {
      background-color: #ff9f0a;
    }
    &:nth-child(4) {
      background-color: #5bdf6f;
    }
  }
`

export const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const ItemLabelBox = styled.div`
  padding: 0 2rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: 'Oxanium';
  font-size: 1.2rem;
  font-weight: bold;
  color: #7c7c80;

  .action-question {
    cursor: pointer;
    width: 2.6rem;
    height: 1.6rem;
    &:hover {
      opacity: 0.7;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
`

export const VerificationItemBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;
  width: 100%;

  .card-label {
    flex: 1;
  }

  .radio {
    width: 3.2rem;

    .click {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      cursor: pointer;

      .circle {
        cursor: pointer;
        height: 1.6rem;
        width: 1.6rem;
        background-color: #2c2c2e;
        border-radius: 2rem;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
`

export const QuickActions = styled.div`
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.4rem;

  .action {
    cursor: pointer;
  }

  div {
    width: 100%;
    font-family: 'Nebula';
    font-size: 1rem;
    font-weight: bold;
    color: #7c7c80;
    background-color: #1c1c1e;
    height: 3.2rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);

    &:nth-child(1) {
      flex: 1;
      padding: 0 2rem;
      display: flex;
      align-items: center;
    }

    &:nth-child(n + 2) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.2rem;

      img {
        height: 1.6rem;
        width: 1.6rem;
      }
    }
  }
`
