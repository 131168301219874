/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import { Button, Icon, Tooltip } from '@viswals/components'
import {
  ContainerAllCourses,
  ContainerBorderWithMap,
  ContainerFloatIcon,
  InnerMap,
} from './styles'

import '../../../styles/tooltip.scss'

type SubstepBigPaginationProps = {
  label: string
  data: any[]
  active: number
  onChangeActive: (index: number) => void
}

const SubstepBigPagination = ({
  label,
  data,
  active,
  onChangeActive,
}: SubstepBigPaginationProps) => {
  const [open, setOpen] = useState<boolean>(false)

  const onVisibleChange = (visible: boolean) => {
    setOpen(visible)
  }
  const BoxAllCourses = () => {
    return (
      <ContainerAllCourses>
        {data?.map((item, _index) => {
          return (
            <div
              className={`${_index === active ? 'selected' : ''} ${
                item.destroy === true ? 'destroy' : ''
              }`}
            >
              <Button
                key={_index}
                label={`${label} ${_index + 1}`}
                styleType="primary"
                handleClick={() => {
                  if (item.destroy !== true) {
                    onVisibleChange(false)
                    onChangeActive(_index)
                  }
                }}
              />
            </div>
          )
        })}
      </ContainerAllCourses>
    )
  }

  return (
    <>
      <ContainerBorderWithMap completed={data?.length !== 0}>
        {data?.map((item, _index) => {
          // @ts-ignore
          const isUnderCompletion =
            // @ts-ignore
            item.courseProgression &&
            // @ts-ignore
            item.courseProgression.length > 0
          if (_index > 4) {
            return null
          }
          return (
            <InnerMap
              className={`innerMap ${_index === active ? 'selected' : ''} ${
                item.destroy === true ? 'destroy' : ''
              }`}
              key={`button-key-${_index}`}
            >
              {isUnderCompletion && (
                <div className="vw-contentIconEduc">
                  <Icon name="Education" />
                </div>
              )}
              <Button
                label={`${label} ${_index + 1}`}
                styleType="primary"
                handleClick={() => {
                  if (item.destroy !== true) {
                    onVisibleChange(false)
                    onChangeActive(_index)
                  }
                }}
              />
              {isUnderCompletion && (
                <div className="vw-contentLabelUnder">Under Completion</div>
              )}
            </InnerMap>
          )
        })}
      </ContainerBorderWithMap>
      {data?.length > 5 ? (
        <Tooltip
          visible={open}
          placement="bottom"
          overlay={BoxAllCourses}
          destroyTooltipOnHide
          trigger={['click']}
          motion={{
            motionName: 'rc-tooltip-fade',
          }}
          align={{
            offset: [0, 10],
          }}
          onVisibleChange={onVisibleChange}
        >
          <ContainerFloatIcon>
            <div className={`${open === true ? 'open' : ''}`}>
              <Icon name="ArrowUp1" />
            </div>
          </ContainerFloatIcon>
        </Tooltip>
      ) : null}
    </>
  )
}

export default SubstepBigPagination
