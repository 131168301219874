import { FieldValues, UseFormRegister } from 'react-hook-form'
import { Account } from '@src/types/account'
import useDatas from '@src/hooks/useDatas'
import moment from 'moment'
import { ModalInformationBox, CardInput } from './style'

type ModalInformationProps = {
  account?: Account
  register: UseFormRegister<FieldValues>
}

const ModalInformation = ({ account, register }: ModalInformationProps) => {
  const { countries } = useDatas()

  function showNationality() {
    if (account?.nationality) {
      const find = countries.find((f) => f.uuid === account.nationality)
      if (find) {
        return find?.name
      }
    }

    return ''
  }

  return (
    <div className="info">
      <h4 className="info-title">Personal Information</h4>

      <ModalInformationBox>
        <CardInput className="disabled">
          <span>Professional Name</span>
          <span>
            {account?.firstName
              ? `${account.firstName} ${account.lastName}`
              : ''}
          </span>
        </CardInput>
        <CardInput className="disabled">
          <span>Nationality</span>
          <span>{showNationality()}</span>
        </CardInput>
        <CardInput className="disabled">
          <span>Date Of Birth</span>
          <span>
            {account?.dateOfBirth
              ? moment(account?.dateOfBirth).format('DD MMMM YYYY')
              : ''}
          </span>
        </CardInput>
        <CardInput>
          <label>
            <span>Mobile Number</span>
            <input
              type="tel"
              disabled={!!account?.uuid}
              {...register('mobileNumber', { required: true })}
              required
            />
          </label>
        </CardInput>
        <CardInput>
          <label>
            <span>Viswals Email</span>
            <input
              type="email"
              disabled={!!account?.uuid}
              {...register('username', {
                required: true,
                pattern: /^\S+@\S+$/i,
              })}
              required
            />
          </label>
        </CardInput>
      </ModalInformationBox>
    </div>
  )
}

export default ModalInformation
