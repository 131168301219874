import styled from 'styled-components'

import WorldMap from '@images/cc_map_upload_photo.png'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  background-image: url(${WorldMap});
  background-position: 50% 99.55%;
  background-repeat: no-repeat;
  background-size: 145.5rem;

  .vw-ownWebsite-header {
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-ownWebsite-inputArea {
    width: 66rem;
    height: 18.8rem;
    padding: 4.3rem 5rem 5rem;
    text-align: center;
    margin-top: 19.5rem;
    margin-left: 39.4rem;

    background-color: #242831da;

    > p {
      font-size: 1.6rem;
      font-weight: 700;
      color: var(--gray-100-color);

      margin-bottom: 1.8rem;
    }

    input {
      padding-bottom: 0.2rem;
      &::placeholder {
        text-align: center;
        font-size: 1.2rem;
        margin-right: 2rem;
      }
    }
  }
`

export default Container
