/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Button, Input, Toggle } from '@viswals/components'

import { StyledContentField } from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'
import { updateFieldDentistry, Request } from '@viswals/services'

export default function FormUpdateField() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, reset, control, setValue } = useForm()

  useEffect(() => {
    ; (async () => {
      const getCurrencyToSave: any = JSON.parse(
        window.localStorage.getItem('currencyEdit') as string
      )
      reset({
        ...getCurrencyToSave,
      })
    })()
  }, [])

  async function onSubmit(data: any) {
    const dataToSend: any = {
      uuid: watch('uuid'),
      name: data.name,
      icon: data.icon,
      description: "dentistry",
      position: 1,
      area: {
        uuid: "4beed17b-a38a-4da1-8b26-94d2f1513001"
      }
    }

    try {
      setIsError('')
      await updateFieldDentistry(dataToSend as Request<any>)
      window.localStorage.removeItem('fieldEdit')
      navigate('/administration/common/field-dentistry')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentField className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Edit Field"
            description="Here you can edit field"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />
          <Input label="Icon Id" formRegister={{ ...register('icon') }} />

          <Button label="Save" type="submit" />
          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/field-dentistry')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentField>
  )
}
