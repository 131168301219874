import styled from 'styled-components'

export const BoxBoundarySteps = styled.section`
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 4.3rem 2.4rem 2.5rem 5.1rem;
  position: relative;

  h1 {
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: var(--gray-100-color);
  }
  p {
    margin-top: 0.3rem;
    font-size: 1.3rem;
    font-weight: 400;
    font-family: var(--font-primary);
    color: var(--gray-200-color);
    line-height: 2rem;
    margin-bottom: 5.4rem;
  }
  .FloatItems {
    position: absolute;
    top: 8rem;
    right: 5rem;

    display: flex;

    &.Verified {
      div {
        &:first-child {
          margin-right: 0;
        }
      }
    }
    .boxDeleteCard {
      margin-right: 1rem;
      .vw-button-buttonstyle {
        width: 15.4rem;
        height: 4.2rem;
        border-radius: 0.5rem;
        background-color: var(--red-color);
        padding: 0.9rem 0.9rem 0.9rem 1rem;
        span {
          margin-left: 0.7rem;
          margin-bottom: 0.1rem;
          font-family: var(--font-primary);
          font-size: 1.2rem;
        }
      }
    }

    .boxArrayItems {
      display: flex;
      gap: 1rem;

      background-color: tomato;
    }

    .vw-button-buttonstyle {
      width: 13.4rem;
      height: 4.2rem;
      border-radius: 0.5rem;
      background-color: var(--info-color);
      padding: 0.9rem 0.9rem 0.9rem 1.1rem;
      span {
        margin-left: 0.2rem;
        margin-bottom: 0.1rem;
        font-family: var(--font-primary);
        font-size: 1.2rem;
      }
    }
  }
`

export const BoxContentCards = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .card-item {
    width: 100%;
    display: block;
  }

  .vw-card-cardBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    cursor: pointer;
    article {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 0 2.5rem 5rem;
      .boxLeftOption {
        width: calc(50% - 0.05rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-right: 3.75rem;
        img {
          width: 21.372rem;
          margin-top: 11.1rem;
          margin-right: 2.2rem;
          margin-bottom: 13.8rem;
        }
        .vw-select-boxContentInput {
          input {
            margin-left: -0.3rem;
          }
        }
        .vw-selectOptions-boxSelectOptions {
          width: 32rem;
          top: -43.9rem;
          right: 1rem;
          z-index: 2;
          backdrop-filter: blur(2rem) brightness(115%);

          .vw-selectOptions-boxSelectOptionsLabel {
            padding: 2.7rem 0 1.7rem 2rem;
          }
          .vw-selectOptions-boxScrollOptions {
            height: 100%;
            max-height: 69.5rem;
            overflow: scroll;
            overflow-x: auto;
            ::-webkit-scrollbar {
              display: none;
            }
          }
        }
      }
      .boxRightOption {
        width: calc(50% - 0.05rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-left: 3.75rem;
        img {
          width: 35.751rem;
          margin-top: 10.7rem;
          margin-bottom: 10.1rem;
          margin-left: 0.3rem;
        }
        .vw-upload-boxContainerInputUpload {
          .vw-upload-boxInputUpload {
            .vw-upload-boxIconUpload {
              padding-top: 0.3rem;
            }
            label {
              margin: 0.1rem 0 0 0.1rem;
            }
          }
        }
      }
    }
  }
`

export const SafeAreaClickDelete = styled.div`
  width: 100%;
`
