import React from 'react'
import { Card, Select } from '@viswals/components'
import HelloLanguages from '@images/helloLanguages.svg'

import MiniPagination from '@src/components/pagination/SubstepMiniPagination'
import {
  BoxContentCards,
  ContainerLanguages,
  SafeAreaClickDelete,
} from './LanguagesStyles'
import useLogic from './useLogic'

const StepLanguages = () => {
  const {
    newLanguagesLevel,
    newCommonLanguages,

    fields,
    verified,

    watch,
    register,
    handleSubmit,
    onSubmit,

    setPaginationActive,

    getFieldProps,

    pagination,
    paginationActive,
    indexShowStart,
    indexShowEnd,
  } = useLogic()

  return (
    <ContainerLanguages>
      <h1>Languages</h1>
      <p>Languages & Linguistic competences</p>
      <div className={`vw-regis-boxButtonsFload ${verified ? 'Verified' : ''}`}>
        {fields.length > 3 ? (
          <MiniPagination
            pages={pagination}
            active={paginationActive}
            onChangeActive={setPaginationActive}
          />
        ) : null}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BoxContentCards>
          {fields.map((item, _index) => {
            if (pagination > 1) {
              if (!(_index >= indexShowStart && _index < indexShowEnd)) {
                return null
              }
            }

            return (
              <div id={item.id} className={`card-item item-${_index}`}>
                <Card
                  key={item.id}
                  // readyDelete={indexToDelete.includes(_index)}
                  isVerified={verified}
                >
                  <input
                    type="hidden"
                    {...register(`data.${_index}.destroy`, {
                      value: false,
                    })}
                  />
                  <article>
                    <img src={HelloLanguages} alt="HelloLanguages" />
                    <SafeAreaClickDelete>
                      <Select
                        options={newCommonLanguages}
                        label={`Language ${_index + 1}`}
                        placeholder="Select Language here"
                        labelTitle="Select the Language"
                        formRegister={{
                          ...register(`data.${_index}.language`, {
                            required: true,
                          }),
                          watch,
                        }}
                        placement="middle"
                        {...getFieldProps(`data.${_index}.language`)}
                      />
                      <div className="vw-regis-contentSelect">
                        <div className="vw-regis-cotentSelectLabel">
                          Level of listening
                        </div>
                        <Select
                          options={newLanguagesLevel}
                          labelTitle="Level of listening"
                          formRegister={{
                            ...register(`data.${_index}.levelListening`, {
                              required: true,
                            }),
                            watch,
                          }}
                          {...getFieldProps(`data.${_index}.levelListening`)}
                        />
                      </div>
                      <div className="vw-regis-contentSelect">
                        <div className="vw-regis-cotentSelectLabel">
                          Level of speaking
                        </div>
                        <Select
                          options={newLanguagesLevel}
                          labelTitle="Level of speaking"
                          formRegister={{
                            ...register(`data.${_index}.levelSpeaking`, {
                              required: true,
                            }),
                            watch,
                          }}
                          {...getFieldProps(`data.${_index}.levelSpeaking`)}
                        />
                      </div>
                      <div className="vw-regis-contentSelect">
                        <div className="vw-regis-cotentSelectLabel">
                          Level of writing
                        </div>
                        <Select
                          options={newLanguagesLevel}
                          labelTitle="Level of writing"
                          formRegister={{
                            ...register(`data.${_index}.levelWriting`, {
                              required: true,
                            }),
                            watch,
                          }}
                          {...getFieldProps(`data.${_index}.levelWriting`)}
                        />
                      </div>
                    </SafeAreaClickDelete>
                  </article>
                </Card>
              </div>
            )
          })}
        </BoxContentCards>
      </form>
    </ContainerLanguages>
  )
}

export default StepLanguages
