import React from 'react'
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import CoursesYourTitle from '@images/step3degreeWhatsYourTitle.svg'
import {
  Input,
  Select,
  SelectedOptionsProps,
  Upload,
} from '@viswals/components'
import transformToSelectOptions from '@src/core/transformToSelectOptions'
import {
  ContainerLengthOfStudyCourses,
  ContentCourseProgression,
  ContentMiniForm,
} from './styles'
import { dataCourseUnderProgression } from '../../common/data'

type WhatsYourTitleProps = {
  keyIndex?: number
  type?: 'major' | 'short'
  coursesTitle: SelectedOptionsProps[]
  watch: UseFormWatch<FieldValues>
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  isCourseUnderCompletion: boolean
}

const WhatsYourTitleCourses = ({
  coursesTitle,
  keyIndex,
  type,
  watch,
  register,
  setValue,
  isCourseUnderCompletion,
}: WhatsYourTitleProps) => {
  const UnderProgressionDuration = transformToSelectOptions({
    array: dataCourseUnderProgression,
    keyLabel: 'name',
    keyValue: 'uuid',
  })
  return (
    <ContainerLengthOfStudyCourses
      className={`${
        type === 'major' ? 'vw-container-major' : 'vw-container-short'
      }`}
    >
      <h3>
        {isCourseUnderCompletion === true
          ? 'Future Title & Progression'
          : 'What’s Your Title'}
      </h3>
      <img src={CoursesYourTitle} alt="CoursesYourTitle" />
      <p
        className={`${
          isCourseUnderCompletion ? 'isCourseUnderCompletion' : ''
        }`}
      >
        {isCourseUnderCompletion === true
          ? 'Future Course Title'
          : 'Awarded Course Title'}
      </p>
      <div className="vw-regis-safeArea">
        {isCourseUnderCompletion === false &&
          coursesTitle.slice(0, 3).map((fieldOfDentistry, _index) => {
            return (
              <label
                key={_index}
                className={`vw-form vw-regis-safeAreaItems ${
                  fieldOfDentistry.value ===
                  watch(`data.${keyIndex}.awardCourseTitle`)
                    ? 'checked'
                    : ''
                }`}
              >
                {fieldOfDentistry.label}
                <input
                  type="radio"
                  value={fieldOfDentistry.value}
                  {...register(`data.${keyIndex}.awardCourseTitle`, {
                    onChange: (e) => {
                      if (e.target.value) {
                        setValue(
                          `data.${keyIndex}.awardCourseTitleText`,
                          undefined
                        )
                        setValue(
                          `data.${keyIndex}.awardCourseTitleSelect`,
                          undefined
                        )
                      }
                    },
                  })}
                />
              </label>
            )
          })}
        <ContentMiniForm className="vw-contentMiniForm-input">
          <Select
            labelTitle="Other"
            placeholder={isCourseUnderCompletion ? 'Select from list' : 'Other'}
            options={
              isCourseUnderCompletion === true
                ? coursesTitle
                : coursesTitle.slice(3, coursesTitle.length)
            }
            placement="top"
            formRegister={{
              ...register(`data.${keyIndex}.awardCourseTitleSelect`, {
                onChange: (e) => {
                  if (e.target.value) {
                    setValue(`data.${keyIndex}.awardCourseTitle`, undefined)
                    setValue(`data.${keyIndex}.awardCourseTitleText`, undefined)
                  }
                },
              }),
              watch,
            }}
            isDisabled
          />
          <Input
            placeholder="If other, write here"
            formRegister={register(`data.${keyIndex}.awardCourseTitleText`, {
              onChange: (e) => {
                if (e.target.value) {
                  setValue(`data.${keyIndex}.awardCourseTitle`, undefined)
                  setValue(`data.${keyIndex}.awardCourseTitleSelect`, undefined)
                }
              },
            })}
          />
        </ContentMiniForm>

        {isCourseUnderCompletion === true ? (
          <ContentCourseProgression className="contentCourseProgression">
            <p>Course Progression</p>
            {UnderProgressionDuration.map((progression, _index) => {
              return (
                <label
                  key={_index}
                  className={`vw-regis-safeAreaItems ${
                    progression.value ===
                    watch(`data.${keyIndex}.courseProgression`)
                      ? 'checked'
                      : ''
                  }`}
                >
                  {progression.label}
                  <input
                    type="radio"
                    value={progression.value}
                    {...register(`data.${keyIndex}.courseProgression`, {
                      onChange: (e) => {
                        if (e.target.value) {
                          setValue(
                            `data.${keyIndex}.courseProgressionText`,
                            undefined
                          )
                        }
                      },
                    })}
                  />
                </label>
              )
            })}
            <Input
              placeholder="If other, write here"
              formRegister={register(`data.${keyIndex}.courseProgressionText`, {
                onChange: (e) => {
                  if (e.target.value) {
                    setValue(`data.${keyIndex}.courseProgression`, undefined)
                  }
                },
              })}
            />
          </ContentCourseProgression>
        ) : null}
        <div
          className={`vw-regis-safeAreaBottom ${
            isCourseUnderCompletion ? 'isCourseUnderCompletion' : ''
          }`}
        >
          <ContentMiniForm>
            <Upload
              placeholder="Upload Certificate"
              label={
                isCourseUnderCompletion
                  ? 'Enrolment Certificate'
                  : 'Course Certificate'
              }
              formRegister={{
                ...register(`data.${keyIndex}.certificate`, {
                  required: !watch(`data.${keyIndex}.certificate`)?.fileName,
                }),
                watch,
                setValue,
              }}
            />
          </ContentMiniForm>
        </div>
      </div>
    </ContainerLengthOfStudyCourses>
  )
}

export default WhatsYourTitleCourses
