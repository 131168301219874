// @ts-nocheck
import React, { useEffect, useRef, useState } from 'react'
import * as faceapi from 'face-api.js'

interface FaceDetectionProps {
  imageBase64: string
  showFace?: boolean
}

const FaceDetectionComponent: React.FC<FaceDetectionProps> = ({
  imageBase64,
  showFace,
}) => {
  const [initializing, setInitializing] = useState(false)
  const [pic, setPic] = useState<string | null>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    loadModelsAndDetectFaces()
  }, [])

  const loadModelsAndDetectFaces = async () => {
    setInitializing(true)
    await Promise.all([
      faceapi.nets.tinyFaceDetector.load('/models'),
      faceapi.nets.faceLandmark68Net.load('/models'),
      faceapi.nets.faceRecognitionNet.load('/models'),
      faceapi.nets.faceExpressionNet.load('/models'),
    ]).then(() => {
      setInitializing(false)
      runFaceDetection()
    })
  }

  const runFaceDetection = async () => {
    if (!imageRef.current || !canvasRef.current) return

    const img = await faceapi.fetchImage(imageBase64)
    imageRef.current.src = img.src

    const detections = await faceapi.detectSingleFace(
      imageRef.current,
      new faceapi.TinyFaceDetectorOptions({
        scoreThreshold: 0.2,
      })
    )
    if (detections) {
      const displaySize = {
        width: imageRef.current.width,
        height: imageRef.current.height,
      }
      faceapi.matchDimensions(canvasRef.current, displaySize)
      const resizedDetections = faceapi.resizeResults(detections, displaySize)
      const context = canvasRef.current.getContext('2d')
      if (context) {
        context.clearRect(0, 0, displaySize.width, displaySize.height)
        faceapi.draw.drawDetections(canvasRef.current, resizedDetections)
      }
      extractFaceFromBox(detections.box)
    }
  }

  const extractFaceFromBox = async (box: faceapi.Box) => {
    const regionsToExtract = [
      new faceapi.Rect(
        box.x + 10,
        box.y - 25,
        box.width + 50,
        box.height + 100
      ),
    ]
    const faceImages = await faceapi.extractFaces(
      imageRef.current,
      regionsToExtract
    )

    if (faceImages.length > 0) {
      const outputImage = new Image()
      outputImage.src = faceImages[0].toDataURL()
      setPic(outputImage.src)
    } else {
      setPic(null)
    }
  }

  if (showFace && pic) {
    return <img src={pic} alt="face" />
  }

  return (
    <div>
      <span>{initializing ? 'Initializing' : 'Ready'}</span>
      <div style={{ position: 'relative', display: 'inline-block' }}>
        <img ref={imageRef} src="" alt="face" crossOrigin="anonymous" />
        <canvas
          ref={canvasRef}
          style={{ position: 'absolute', top: 0, left: 0 }}
        />
      </div>
      <br />
      {pic && <img src={pic} alt="face" />}
    </div>
  )
}

export default FaceDetectionComponent
