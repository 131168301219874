import { useCallback, useEffect, useState, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { debounce } from '@mui/material'

import {
  AccountStatus,
  PatientStatus,
  PatientStatusFilter,
  PlatformType,
  Request,
  retrievePatientCounter,
  retrievePatientStatusList,
} from '@viswals/services'
import { LinkProps } from '@viswals/components-platform'
import useDatas from '@src/hooks/useDatas'
import useOnScreen from '@src/hooks/useOnScreen'
import gsap from 'gsap'
import axios from '../../services/api'

export type localSelectFilter = {
  platform?: PlatformType
  state?: AccountStatus
}

let searchTxtTimeout: any = null

const useLogic = () => {
  const options: LinkProps[] = [
    {
      value: '/global-view',
      title: 'Global View',
      icon: 'GlobalView',
    },
    {
      value: '/global-accounts',
      title: 'Global Accounts',
      icon: 'GlobalView',
    },
    {
      value: '/livepool',
      title: 'Live Pool',
      icon: 'Roadmap',
    },
  ]

  const navigate = useNavigate()
  const location = useLocation()

  const { countries } = useDatas()

  const [patientStatus, setPatientStatus] = useState<PatientStatus[]>([])
  const [totalCounter, setTotalCounter] = useState<any>()
  const [globalIdSearchTxt, setGlobalIdSearchTxt] = useState<string>()

  // const [listTotal, setListTotal] = useState<number>(0)
  const [isRequesting, setIsRequesting] = useState(false)
  const [superLoading, setSuperLoading] = useState(true)
  const loadingBoxRef = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(loadingBoxRef)

  const [filterPlatform, setFilterApplyPlatform] = useState<
    Array<PlatformType | undefined>
  >(['PATIENT'])
  const [filterState, setFilterApplyState] = useState<
    Array<AccountStatus | undefined>
  >([])

  useEffect(() => {
    window.localStorage.removeItem('page_global_accounts')
    handleActiveLoading()
    setTimeout(async () => {
      await handleGetPatientStatus().then(() => {
        handleDisabledLoading()
      })
      if (!totalCounter) {
        await getCounters()
      }
    }, 400)
  }, [])

  useEffect(() => { }, [])

  useEffect(() => {
    if (searchTxtTimeout) {
      clearTimeout(searchTxtTimeout)
      searchTxtTimeout = null
    }
    searchTxtTimeout = setTimeout(() => {
      if (globalIdSearchTxt || globalIdSearchTxt === '') {
        handleGetPatientStatus()
      }
    }, 500);
  }, [globalIdSearchTxt])

  useEffect(() => {
    getByVisible()
  }, [isVisible])

  async function getByVisible() {
    if (
      isVisible === true &&
      superLoading === false
      // patientStatus?.length !== listTotal
    ) {
      newGetUpdate()
    }
  }

  const newGetUpdate = useCallback(
    debounce(async () => {
      await handleGetPatientStatus()
    }, 350),
    []
  )

  const useRouterCallback = useCallback(() => {
    return options.find((f) => f.value === location.pathname)?.value
  }, [location])

  const activeLink = useRouterCallback()

  async function handleGetPatientStatus(filterN?: AccountStatus[]) {
    setIsRequesting(true)

    const cleanFilter: AccountStatus[] = []

    for (const st of filterN ?? filterState) {
      if (st) {
        cleanFilter.push(st)
      }
    }

    const request: Request<PatientStatusFilter> = {
      filters: {
        states: cleanFilter,
      },
      pagination: {
        itemsPerPage: 20,
        page:
          Number(window.localStorage.getItem('page_global_accounts') ?? 0) + 1,
      },
    }

    try {

      let url = 'https://services.viswalslab.com/professional/v1/accounts/registrations-status'
      if (globalIdSearchTxt) {
        url = `${url}?global-id=${globalIdSearchTxt}`
      }

      const { data: { result, page } } = await axios.get(url)

      const convertedData = result.map((ele: any) => ({
        "patientUuid": "49283d8d-774e-4176-b940-6d818d43f116",
        "state": "SIGN_UP",
        "createdAt": ele.log_datetime,
        "countryUuid": ele.country,
        "firstName": ele.name,
        "lastName": '',
        "email": ele.email,
        "accountNumber": ele.global_id
      }))

      if (convertedData) {
        const newData = generateDataPatientStatus(convertedData)

        window.localStorage.setItem(
          'page_global_accounts',
          page.toString()
        )

        if (filterN) {
          setPatientStatus(newData)
        } else {
          const dataConcat: PatientStatus[] = patientStatus ?? []

          newData.forEach(async (rep) => {
            dataConcat.push(rep)
          })

          setPatientStatus(dataConcat)
        }
        // setListTotal(newData.length + listTotal)
        setIsRequesting(false)
      } else {
        setIsRequesting(false)
        setPatientStatus([])
      }
    } catch (error) {
      setIsRequesting(false)
      setPatientStatus([])
    }
  }

  async function getCounters() {
    const request = {}
    try {
      const {
        data: { data },
      } = await retrievePatientCounter(request)

      setTotalCounter(data[0])
    } catch (error) {
      console.error(error)
    }
  }

  function generateDataPatientStatus(data: PatientStatus[]) {
    const convertPatients = data.map((value) => {
      const newPatient = value

      newPatient.countryUuid =
        countries.find(
          (item) =>
            item.uuid === value.countryUuid || item.name === value.countryUuid
        )?.name ?? ''
      return newPatient
    })
    return convertPatients
  }

  function handleGeneralFilter(platform?: PlatformType, state?: AccountStatus) {
    if (platform) {
      setFilterApplyPlatform(handleSetFilterPlatform(filterPlatform, platform))
    } else {
      setFilterApplyState(handleSetFilterPlatform(filterState, state))

      handleActiveLoading()
      setTimeout(async () => {
        await handleGetPatientStatus(
          handleSetFilterPlatform(filterState, state)
        ).then(() => {
          handleDisabledLoading()
        })
      }, 400)
    }
  }

  function handleSetFilterPlatform(arr: any, key?: any) {
    const firstValue = arr[0]
    const secondValue = arr[1]

    let toReturn: Array<any | undefined> = []

    if (firstValue === undefined) {
      if (firstValue === key) {
        toReturn = [undefined, undefined]
      } else {
        toReturn = [key, undefined]
      }
    }

    if (
      firstValue !== undefined &&
      secondValue === undefined &&
      firstValue !== key
    ) {
      if (secondValue === key) {
        toReturn = [firstValue, undefined]
      } else {
        toReturn = [firstValue, key]
      }
    } else if (firstValue === key) {
      toReturn = [undefined, undefined]
    }

    if (firstValue !== undefined && secondValue !== undefined) {
      if (firstValue === key) {
        toReturn = [undefined, secondValue]
      } else if (secondValue === key) {
        toReturn = [firstValue, undefined]
      } else {
        toReturn = [firstValue, key]
      }
    }

    return toReturn
  }

  function handleDisabledLoading() {
    setSuperLoading(false)
    const getClass = window.document.getElementsByClassName(
      'vw-loadingContainer'
    )
    gsap.to(getClass, {
      opacity: 0,
      duration: 0.25,
      onComplete: () => {
        gsap.to(getClass, {
          zIndex: -1,
          duration: 0.15,
        })
      },
    })
  }

  async function handleActiveLoading() {
    setSuperLoading(true)
    const getClass = window.document.getElementsByClassName(
      'vw-loadingContainer'
    )
    gsap.to(getClass, {
      zIndex: 999,
      duration: 0.1,
      onComplete: () => {
        gsap.to(getClass, {
          opacity: 1,
          duration: 0.25,
        })
      },
    })
  }

  return {
    activeLink,
    options,
    navigate,
    dataPatientStatus: patientStatus,
    handleGeneralFilter,
    filterState,
    filterPlatform,
    totalCounter,
    // listTotal,
    loadingBoxRef,
    isRequesting,
    globalIdSearchTxt,
    setGlobalIdSearchTxt,
  }
}

export default useLogic
