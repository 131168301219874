import styled from 'styled-components'

export const ContainerBodyLayout = styled.div`
  .search-area {
    margin-top: 1rem;
    justify-content: space-between;

    .content-input-search {
      position: relative;
      svg {
        width: 1.2rem;
        position: absolute;
        top: 1.2rem;
        left: 1.6rem;
        color: #8e8e93;
      }
      input {
        width: 44.75rem;
        height: 3.2rem;

        background-color: #1c1c1e;
        border-radius: 0.5rem;

        padding: 0.3rem 1rem 0 4.4rem;

        border: 0 solid transparent;

        outline: none;
        decoration: none;

        cursor: pointer;

        font-size: 1rem;
        font-family: var(--font-secondary);

        color: #aeaeb2;

        &::placeholder {
          color: #545456;
        }

        /* position: absolute;
        top: 0;
        left: 0; */
      }
    }

    .content-buttons-filter {
      height: 3.2rem;
      width: 44.75rem;

      display: flex;
      align-items: center;
      justify-content: space-between;

      background-color: #1c1c1e;
      border-radius: 0.5rem;

      padding: 0.4rem 1rem;

      gap: 1rem;

      .button-filter {
        width: 100%;
        height: 2.4rem;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #2c2c2e;
        border-radius: 0.5rem;
        font-size: 1rem;
        font-family: var(--font-secondar);

        cursor: pointer;

        color: #7c7c80;

        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

        &.active {
          background-color: transparent;
          font-weight: bold;
          box-shadow: none;
        }
      }
    }
    .content-buttons-free {
      width: 100%;
      max-width: 90.5rem;

      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 1rem;

      .button-free {
        width: 100%;
        height: 3.2rem;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #2c2c2e;
        border-radius: 0.5rem;
        font-size: 1rem;
        line-height: 1rem;
        font-family: var(--font-secondar);

        cursor: pointer;

        color: #7c7c80;

        box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

        border: 0.05rem solid transparent;

        transition: all 0.2s ease-in;

        &.app {
          &.active {
            background-color: #2c7dff15;
            border: 0.05rem solid #2c7dff;
            color: #2c7dff;
          }
        }

        &.clinician {
          &.active {
            background-color: #36ba9115;
            border: 0.05rem solid #36ba91;
            color: #36ba91;
          }
        }

        &.clinic {
          &.active {
            background-color: #b665d515;
            border: 0.05rem solid #b665d5;
            color: #b665d5;
          }
        }

        &.sign_up {
          &.active {
            background-color: #ebebeb15;
            border: 0.05rem solid #ebebeb;
            color: #ebebeb;
          }
        }

        &.acquired {
          &.active {
            background-color: #222b26;
            border: 0.05rem solid #32d74b;
            color: #32d74b;
          }
        }

        &.dropout {
          &.active {
            background-color: #63636615;
            border: 0.05rem solid #636366;
            color: #7c7c80;
          }
        }
      }
    }
  }

  .search-list-area {
    height: 80.2rem;
    margin-top: 1rem;

    display: flex;
    flex-direction: column;

    padding: 1rem 0.5rem 1rem 0.5rem;

    gap: 0.9rem;

    .content-scroll-items {
      width: calc(100% - 1rem);
      height: 100%;
      margin-left: 0.5rem;

      overflow-y: scroll;

      display: flex;
      flex-direction: column;

      gap: 0.8rem;

      position: relative;
    }
  }
`

export const ContentLoadingList = styled.div<{ isFull?: boolean }>`
  height: 5.6rem;

  padding-bottom: 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: ${(props) => (props.isFull ? 'hidden' : 'visible')};

  svg {
    color: #5bdf6f;
  }
`

export const ContainerUpperCalendarChildren = styled.div`
  width: 100%;
  height: 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1.5rem;

    margin-bottom: 0.4rem;
    font-family: var(--font-third);
    font-size: 1.4rem;
    color: #5bdf6f;
  }
  .rightBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .vw-expandedSearchBar-containerArea {
      width: 10.5rem;
      margin-left: 7.3rem;
    }
  }
`
