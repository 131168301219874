// current-working
import React from 'react'
import { Card, Input, Select } from '@viswals/components'
import CurrentWorking from '@images/currentWorking.svg'
import MiniPagination from '@src/components/pagination/SubstepMiniPagination'
import Container, { ContentSafeForm } from './CurrentWorkingLocationStyles'
import { clinicType, position, monthSelect } from '../period-of-coverage/data'
import useLogic from './useLogic'

const StepCurrentWorkingLocation = () => {
  const {
    fields,
    errors,
    indexToDelete,
    verified,
    yearSelectAgo,

    handleSubmit,
    onSubmit,
    register,
    watch,

    getFieldProps,
    setPaginationActive,
    setValue,

    pagination,
    paginationActive,
    indexShowStart,
    indexShowEnd,
  } = useLogic()

  return (
    <Container>
      <div className="vw-currentWorkingLocation-header">
        <div>
          <h2>Current Working Locations</h2>
          <span>Add and list your current places of work</span>
        </div>

        <div className={`buttonArea ${verified ? 'Verified' : ''}`}>
          {fields.length > 3 ? (
            <MiniPagination
              pages={pagination}
              active={paginationActive}
              onChangeActive={setPaginationActive}
            />
          ) : null}
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-currentWorkingLocation-cardArea">
          {fields.map((field, index) => {
            if (pagination > 1) {
              if (!(index >= indexShowStart && index < indexShowEnd)) {
                return null
              }
            }
            // @ts-ignore
            if (field.destroy === true) {
              return null
            }
            // @ts-ignore
            const hasCardError = errors && errors.data && errors.data[index]

            const isReadyDelete = indexToDelete.includes(index)
            const clinicName = watch(`data.${index}.clinicName`)

            const isCheck =
              watch(`data.${index}.clinicType`) === 'CLINIC_REGISTERED' &&
              watch(`data.${index}.clinicNameCheck`) === 'isCheck'
                ? true
                : undefined
            const isInvalid =
              watch(`data.${index}.clinicType`) === 'CLINIC_REGISTERED' &&
              watch(`data.${index}.clinicNameCheck`) === 'isInvalid'
                ? true
                : undefined

            return (
              <div key={field.id} className={`card-item item-${index}`}>
                <input
                  type="hidden"
                  {...register(`data.${index}.dateStarted`, {
                    required: true,
                  })}
                />
                <Card
                  readyDelete={isReadyDelete}
                  isError={hasCardError}
                  isVerified={verified}
                >
                  <div className="form">
                    <div className="vw-currentWorkingLocation-img">
                      <img
                        src={CurrentWorking}
                        alt="Select your date of birth"
                      />
                    </div>
                    <ContentSafeForm className="vw-currentWorkingTitle">
                      <p>Clinic {index + 1}</p>
                      <Select
                        labelTitle="Select the clinic type"
                        // @ts-ignore
                        options={clinicType}
                        placeholder="Clinic Type"
                        formRegister={{
                          ...register(`data.${index}.clinicType`, {
                            required: true,
                          }),
                          watch,
                        }}
                        {...getFieldProps(`data.${index}.clinicType`)}
                        placement="middle"
                      />
                      <Input
                        placeholder="Clinic name here"
                        formRegister={register(`data.${index}.clinicName`, {
                          required: true,
                          onBlur: () => {
                            if (clinicName?.length >= 1) {
                              setValue(
                                `data.${index}.clinicNameCheck`,
                                clinicName === '123' ? 'isCheck' : 'isInvalid'
                              )
                            }
                          },
                        })}
                        isCheck={isCheck}
                        isInvalid={isInvalid}
                        {...getFieldProps(`data.${index}.clinicName`)}
                      />
                      <Select
                        labelTitle="Select the position"
                        // @ts-ignore
                        options={position}
                        placeholder="Select position"
                        formRegister={{
                          ...register(`data.${index}.position`, {
                            required: true,
                          }),
                          watch,
                        }}
                        {...getFieldProps(`data.${index}.position`)}
                        placement="middle"
                      />
                      <div className="doubleSelect">
                        <p>Date started</p>

                        <Select
                          placeholder="Month"
                          labelTitle="Select the month"
                          options={monthSelect}
                          formRegister={{
                            ...register(`data.${index}.dateStarted_month`),
                            watch,
                          }}
                          {...getFieldProps(`data.${index}.dateStarted_month`)}
                          placement="top"
                        />
                        <Select
                          placeholder="Year"
                          labelTitle="Select the year"
                          options={yearSelectAgo}
                          formRegister={{
                            ...register(`data.${index}.dateStarted_year`),
                            watch,
                          }}
                          {...getFieldProps(`data.${index}.dateStarted_year`)}
                          placement="top"
                        />
                      </div>
                      <div className="doubleInput">
                        <p>Date finished</p>
                        <Input placeholder="Current Month" isDisabled />
                        <Input placeholder="Current Year" isDisabled />
                      </div>
                    </ContentSafeForm>
                  </div>
                </Card>
              </div>
            )
          })}
        </div>
      </form>
    </Container>
  )
}

export default StepCurrentWorkingLocation
