/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react'
import { setNavigationActiveNext } from '@src/store/actions/navigation'
import { RegistrationProp } from '@src/store/reducers/clinician/props'
import {
  Request,
  retrieveShortCourse,
  ShortCourseFilter,
  ShortCourse,
  Institution,
} from '@viswals/services'
import { FieldValues, UseFieldArrayAppend } from 'react-hook-form'
import handleRetrieveInstituions from '../../degree/utils/handleRetrieveInstitutions'

type handleRetrieveShortCoursesProps = {
  append: UseFieldArrayAppend<FieldValues, 'data'>
  dispatch: Dispatch<any>
  registrationActive: RegistrationProp
  institutionsLocal: Institution[]
  setInstitutionsLocal: React.Dispatch<React.SetStateAction<Institution[]>>
  getCities: any
}

async function handleRetrieveShortCourses({
  append,
  dispatch,
  registrationActive,
  institutionsLocal,
  setInstitutionsLocal,
  getCities,
}: handleRetrieveShortCoursesProps) {
  try {
    const request: Request<ShortCourseFilter> = {
      fields: [
        'uuid',
        'clinicianRegisterUuid',
        'continent',
        'country',
        'city',
        'courseProvider',
        'institution',
        'fieldDentistry',
        'shortCourse',
        'courseDuration',
        'certifiedCourseHours',
        'studyMode',
        'dateGrantedYear',
        'dateGrantedMonth',
        'awardCourseTitle',
        'courseCertificate',
        'courseCertificateName',
      ],
      filters: {
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      },
    }
    const resp = await retrieveShortCourse(request)
    const data: ShortCourse[] | null = resp.data.data ?? []

    setTimeout(async () => {
      // @ts-ignore
      const majorCoursesSize: ShortCourse[] = data

      const emptyCourses: ShortCourse[] = []

      for (const majorCourse of majorCoursesSize) {
        const findInstitution = institutionsLocal.find((institution) => {
          return institution.uuid === majorCourse.institution
        })
        // const findCity = cities.find((city) => {
        //     return city.value === majorCourse.city;
        // });
        if (!findInstitution) {
          await handleRetrieveInstituions({
            country: majorCourse.country as string,
            setInstitutionsLocal,
          })
        }
        getCities(majorCourse.country)
        // if (!findCity) {
        //     await handleRetrieveCities({
        //         country: majorCourse.country,
        //         setCitiesLocal,
        //     });
        // }

        emptyCourses.push({
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          ...majorCourse,
          // @ts-ignore
          courseDuration: `${majorCourse.courseDuration}`,
          // @ts-ignore
          continent: `${majorCourse.continent}`,
          // @ts-ignore
          certifiedCourseHours:
            majorCourse.certifiedCourseHours === 0
              ? undefined
              : majorCourse.certifiedCourseHours,
          certifiedCourseHoursUnknow: majorCourse.certifiedCourseHours === 0,
          certificate: {
            file: majorCourse.courseCertificate,
            fileName: majorCourse.courseCertificateName,
          },
        })
      }
      append(emptyCourses as any)
      dispatch(setNavigationActiveNext(true))
    }, 200)
  } catch (error) {
    return null
  }
}

export default handleRetrieveShortCourses
