import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-periodOfCoverage-header {
    width: 100%;
    margin-top: -0.1rem;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-periodOfCoverage-cardArea {
    display: flex;
    .vw-card-cardBox {
      width: 72.4rem;
      padding: 0 2.5rem 5rem;
      margin-right: 2.5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .vw-periodOfCoverage-img {
        height: 40.1rem;
        padding-top: 12.6rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
          width: 28.7rem;
        }
        &.img2 {
          padding: 12.4rem 0 0 0.3rem;

          img {
            width: 33.9rem;
          }
        }
      }

      p {
        font-family: var(--font-primary);
        font-weight: 700;
        font-size: 1.4rem;
        text-align: start;

        margin-bottom: 0.93rem;
      }

      .upload {
        justify-content: flex-start;
        .vw-upload-boxInputUpload {
          padding-left: 0;
        }
        & .vw-filled {
          .vw-upload-boxIconUpload {
            justify-content: flex-start !important;

            > label {
              width: auto;
            }
            > svg {
              width: 1.9rem;
              margin-left: 2.2rem;
              margin-right: 1.9rem;
            }
          }
        }
      }

      .vw-periodOfCoverage-selectArea {
        > div {
          height: 5.2rem;
          padding: 1.4rem 2rem;

          display: flex;
          justify-content: space-between;
          background-color: var(--content-color);
          border-radius: 0.5rem;
          margin-bottom: 1rem;

          p {
            font-family: var(--font-secondary);
            font-weight: 400;
            line-height: 2.4rem;
            color: var(--gray-200-color);
            margin-bottom: 0;
          }
        }

        .form-dates {
          padding-bottom: 2rem;
          padding-right: 0;
          > p {
            width: 12.5rem;
          }
          .vw-select-boxContentInput,
          .vw-input-boxContentInput {
            width: 15.6rem;
            height: 3.4rem;
            margin-top: -0.5rem;
            margin-right: 2rem;
            .vw-select-BoxInput {
              padding: 0 1rem 0 1.7rem;
              background-color: var(--dark-color);
            }
          }

          .vw-selectOptions-boxSelectOptions {
            .vw-selectOptions-boxSelectOptionsLabel {
              padding: 2.4rem 0rem 0.5rem 0.8rem;
            }

            .vw-selectOptions-boxScrollOptions {
              height: 36rem;

              .vw-selectOptions-boxContentScrollItem {
                margin-bottom: 0.1rem;
                margin-left: -0.3rem;
                height: 2.9rem;
                padding-left: 0;
              }
            }
          }
          .vw-select-boxIconRight {
            width: 3.2rem !important;
            max-width: 3.2em !important;
            min-width: 3.2rem !important;
            padding-bottom: 0.1rem;
          }
        }
      }
    }
  }
`
