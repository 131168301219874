import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateDrug from './form_create'
import FormUpdateDrug from './form_update'
import DrugTable from './table'

export default function DrugPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <DrugTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateDrug />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateDrug />
          </Layout>
        }
      />
    </Routes>
  )
}
