import { Icon } from '@viswals/components-platform'
import IconEquals from '@images/map-pins/equals.svg'
import {
  TotalsItemContainer,
  DescriptionGroup,
  PercentGroup,
  ArrowWrapper,
  CountSpan,
  RightGroup,
  Percent,
} from './style'

export interface TotalsItemProps {
  forceShow?: boolean
  description: string
  amount: number
  backgroundColor: string
  fontColor: string
  percentage: number
  bold: boolean
}

export const TotalsItem = ({
  forceShow,
  description,
  amount,
  backgroundColor,
  fontColor,
  percentage,
  bold,
}: TotalsItemProps) => {
  return (
    <TotalsItemContainer
      fontColor={fontColor}
      backgroundColor={backgroundColor}
      bold={bold}
      className={forceShow ? '' : 'item-secondary'}
    >
      <DescriptionGroup>{description}</DescriptionGroup>
      <RightGroup>
        <CountSpan countColor={fontColor}>{amount}</CountSpan>
        <PercentGroup>
          <ArrowWrapper percentage={percentage}>
            {percentage > 0 ? (
              <Icon name="ArrowUp" />
            ) : percentage < 0 ? (
              <Icon name="ArrowDown" />
            ) : (
              <img src={IconEquals} alt="Equals" />
            )}
          </ArrowWrapper>
        </PercentGroup>
        <Percent>{`${Math.ceil(
          percentage < 0 ? percentage * -1 : percentage
        )}%`}</Percent>
      </RightGroup>
    </TotalsItemContainer>
  )
}
