/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import {
  ViswalsPolicy,
  ViswalsPolicyFilter,
  createViswalsPolicy,
  Request,
  retrieveViswalsPolicy,
  updateViswalsPolicy,
} from '@viswals/services'
import useLoading from '@src/hooks/useLoading'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { useNavigate } from 'react-router-dom'
import { FinishRegistrationProps, TermsOfService } from '../data'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { watch, control, setValue, handleSubmit } = useForm()

  const { fields } = useFieldArray({
    control,
    name: 'data',
  })

  const { setLoading } = useLoading()
  const { subStepDone, nextRoute, verified } = useClinicianRegister()

  const [checkAll, setCheckAll] = useState<boolean>(true)
  const [isReady, setIsReady] = useState<boolean>(false)
  const { next } = useSelector((state: ReducersProps) => state.navigation)

  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const isTermsDone = true // subStepList[0].done
    if (next && isTermsDone && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, { type }) => {
      const isAllTrue = form.data.every(
        (item: any) => item.isConfirmed === true
      )
      const isTermsDone = true // subStepList[0].done
      if (isAllTrue && isTermsDone && type === 'change') {
        setCheckAll(true)
        dispatch(setNavigationConfirm(true))
        subStepDone()
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  const handleScroll = (event: any, indexCard: number) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.target

    if (scrollTop + offsetHeight > scrollHeight - 20) {
      const watchValue = watch(`data.${indexCard}.isDisabled`)
      if (watchValue === true && !verified) {
        setValue(`data.${indexCard}.isDisabled`, false)
      }
    }
  }

  const getData = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      const requestRetrieve = await retrieveViswalsPolicy({
        fields: [
          'uuid',
          'countryRegistration',
          'brandCopyright',
          'dutyResponsibilities',
          'missionRights',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        },
      })
      const data: ViswalsPolicy[] = requestRetrieve?.data?.data ?? []

      const newArray: FinishRegistrationProps[] = TermsOfService.map(
        (term: FinishRegistrationProps) => {
          let isConfirmed = false
          if (data.length > 0) {
            isConfirmed =
              term.type === 'brandCopyright'
                ? data[0].brandCopyright === true
                : term.type === 'dutyResponsibilities'
                ? data[0].dutyResponsibilities === true
                : data[0].missionRights === true
          }
          const newData: FinishRegistrationProps = {
            uuid: term.uuid,
            title: term.title,
            img: term.img,
            description: term.description,
            isDisabled: true,
            isConfirmed,
            isVerified: verified,
            isError: false,
            item: data.length > 0 ? data[0].uuid : null,
          }
          return newData
        }
      )
      if (newArray?.length) {
        setCheckAll(true)
      }
      setValue(`data`, newArray)
    } finally {
      setIsReady(true)
      setLoading(false)
    }
  }

  const onSubmit = async () => {
    if (!checkAll) {
      return false
    }
    const newFields = watch(`data`)
    const isAllTrue = newFields.every((item: any) => item.isConfirmed === true)

    if (!isAllTrue) {
      return null
    }

    const dataCreate: ViswalsPolicy = {
      uuid: newFields[0].item ?? undefined,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      countryRegistration: registrationActive.countryRegistration,
      brandCopyright: true,
      dutyResponsibilities: true,
      missionRights: true,
    }

    if (dataCreate.uuid) {
      await updateViswalsPolicy(dataCreate as Request<ViswalsPolicyFilter>)
    } else {
      await createViswalsPolicy(dataCreate as Request<ViswalsPolicyFilter>)
    }

    navigate(nextRoute)
  }

  return {
    fields,
    control,
    verified,

    watch,
    onSubmit,
    handleSubmit,

    handleScroll,
  }
}

export default useLogic
