import { Route } from 'react-router-dom'
import ClinicianRegistrationPage from '@pages/ClinicianRegistration'
import Layout from '@src/components/Layout'
import LivePool from '@src/pages/LivePool'
import GlobalView from '@src/pages/GlobalView'
import CommonPage from '@src/pages/administration/common'
import ReportsPage from '@src/pages/reports'
import GlobalAccounts from '@src/pages/GlobalAccounts'
import UserVerificationPage from '@src/pages/UserVerification'
import FishPool from '@src/pages/FishPool'
import SuperAdministratorPage from '@src/pages/SuperAdministrator'
import PrivateRouteCheck from './PrivateRouteCheck'
import ClinicianRegistrationRoute from './ClinicianRegistrationRoute'
import PreRegistrationView from '@src/pages/PreRegistration'

const PrivateRoute = (
  <Route element={<PrivateRouteCheck />}>
    <Route
      path="/clinician-registration/:step/:subStep/:country/*"
      element={
        <Layout>
          <ClinicianRegistrationPage />
        </Layout>
      }
    />
    <Route
      path="/clinician-registration/*"
      element={<ClinicianRegistrationRoute />}
    />

    <Route
      path="/notifications"
      element={
        <Layout>
          <div>Notifications</div>
        </Layout>
      }
    />
    <Route path="/global-view" element={<GlobalView />} />
    <Route path="/global-view/pre-registration" element={<PreRegistrationView />} />
    <Route path="/global-accounts" element={<GlobalAccounts />} />
    <Route path="/livepool" element={<LivePool />} />
    <Route path="/fishpool" element={<FishPool />} />

    <Route
      path="/administration/*"
      element={
        <Layout>
          <CommonPage />
        </Layout>
      }
    />
    <Route
      path="/super-administrator/:slug?"
      element={<SuperAdministratorPage />}
    />
    <Route path="/reports/*" element={<ReportsPage />} />
    <Route path="/verification/:uuid" element={<UserVerificationPage />} />
  </Route>
)
export default PrivateRoute
