/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import {
  PeriodRegistrationCertificate,
  PeriodRegistrationCertificateFilter,
  Request,
  retrievePeriodRegistrationCertificate,
  updatePeriodRegistrationCertificate,
} from '@viswals/services'
import useStepsError from '@src/hooks/useStepsError'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useLoading from '@src/hooks/useLoading'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm()

  const { subStepDone, nextRoute, verified } = useClinicianRegister()

  const { getErrors, getFieldProps } = useStepsError(errors)
  const [isReady, setIsReady] = useState<boolean>(false)
  const { loading, setLoading } = useLoading()

  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change' && !confirm) {
        setValue('verified', false)
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && !loading && isReady) {
      if (!confirm) {
        navigate(nextRoute)
      } else {
        handleSubmit(onSubmit)()
      }
    }
  }, [next])

  async function getData() {
    setLoading(true)
    try {
      const request: Request<PeriodRegistrationCertificateFilter> = {
        fields: [
          'uuid',
          'expireDateDay',
          'expireDateMonth',
          'expireDateYear',
          'registrationCertificate',
          'registrationCertificateName',
          'expireDateNotApplicable',
          'verified',
        ],
        filters: {
          countryRegistration: registrationActive.country.uuid,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrievePeriodRegistrationCertificate(request)
      const data = resp.data.data[0]
      const dataResponse = {
        uuid: data.uuid,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        countryRegistration: registrationActive.country.uuid,
        expireDateDay: data.expireDateDay,
        expireDateMonth: data.expireDateMonth,
        expireDateYear: data.expireDateYear,
        registrationCertificate: {
          file: data.registrationCertificate,
          fileName: data.registrationCertificateName,
        },
        expireDateNotApplicable: !!data.expireDateNotApplicable,
        verified: data?.verified,
      }
      setTimeout(() => {
        reset({ ...dataResponse })
      }, 200)
    } finally {
      setIsReady(true)
      setLoading(false)
      dispatch(setNavigationActiveNext(true))
    }
  }

  async function onSubmit(data: any) {
    setLoading(true)
    try {
      data = {
        ...data,
        registrationCertificate: data.registrationCertificate.file,
        registrationCertificateName: data.registrationCertificate.fileName,
      }
      await updatePeriodRegistrationCertificate(
        data as Request<PeriodRegistrationCertificate>
      )
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setLoading(false)
    }
  }

  const dateNotApplicable = Boolean(watch('ExpireDateNotApplicable'))

  return {
    confirm,
    verified,
    dateNotApplicable,
    getValues,
    dispatch,
    watch,
    register,
    onSubmit,
    handleSubmit,
    setValue,
    setNavigationConfirm,
    getFieldProps,
  }
}

export default useLogic
