import React from 'react'
import { Card, Toggle } from '@viswals/components'
import Container from './ViswalsPoliciesStyles'
// import TooltipMessage from '../tooltipMessage'
import useLogic from './useLogic'
import { FinishRegistrationProps } from '../data'

const StepViswalsPolicies = () => {
  const {
    fields,
    control,
    verified,

    watch,
    onSubmit,
    handleSubmit,
  } = useLogic()
  return (
    <Container>
      <div className="vw-termsOfService-header">
        <div>
          <h2>Viswals Policies</h2>
          <span>Read carefully and accept the below policies</span>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-termsOfService-cardArea">
          {fields.map((i, indexCard) => {
            const item = i as unknown as FinishRegistrationProps
            const isDisabled = watch(`data.${indexCard}.isDisabled`)
            return (
              <React.Fragment key={i.id}>
                <Card isVerified={verified}>
                  <div className="vw-termsOfService-img">
                    <img
                      src={item.img}
                      alt="Terms of Service"
                      className={`img_${item.uuid}`}
                    />
                  </div>
                  <div
                    className="descriptionArea"
                    // onScroll={(event) => handleScroll(event, indexCard)}
                  >
                    <h2>{item.title}</h2>
                    <div className="description">{item.description}</div>
                  </div>
                  <div className={`toggleArea ${!isDisabled ? 'enabled' : ''}`}>
                    <p>I understand and agree with the above</p>

                    <Toggle
                      name={`data.${indexCard}.isConfirmed`}
                      control={control}
                      isDisabled
                      required
                    />
                  </div>
                </Card>
              </React.Fragment>
            )
          })}
        </div>
      </form>
    </Container>
  )
}

export default StepViswalsPolicies
