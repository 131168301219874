import styled from 'styled-components'

export type HeaderContentCommonProps = {
  name: string
  description: string
  onClickAdd?: () => void
}

export default function HeaderContentCommon({
  name,
  description,
  onClickAdd,
}: HeaderContentCommonProps) {
  function handleOpenModal() {
    onClickAdd && onClickAdd()
  }

  return (
    <StyledHeaderContentCommon>
      <div>
        <h3>{name}</h3>
        <span>{description}</span>
      </div>
      {onClickAdd ? (
        <div onClick={handleOpenModal} aria-hidden className="button-add">
          Add
        </div>
      ) : (
        <></>
      )}
    </StyledHeaderContentCommon>
  )
}

const StyledHeaderContentCommon = styled.div`
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: 4rem;

  h3 {
    font-size: 1.8rem;
    color: #aeaeb2;
    font-family: 'Poppins';
    font-weight: bold;
  }

  span {
    font-size: 1.4rem;
  }

  .button-add {
    padding: 1.6rem 2rem 1.8rem;

    background-color: #8e8e93;

    color: #242426;
    font-size: 1.2rem;
    font-family: 'Poppins';
    font-weight: bold;

    border-radius: 0.5rem;

    cursor: pointer;
  }
`
