/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { SelectedOptionsProps } from '@viswals/components'
import { useFieldArray, useForm } from 'react-hook-form'

import gsap from 'gsap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ReducersProps } from '@src/types/reducers'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
  setNavigationSkip,
} from '@src/store/actions/navigation'
import {
  Request,
  retrieveSpecialtyCertificate,
  saveSpecialtyCertificate,
  SpecialtyCertificate,
  SpecialtyCertificateFilter,
} from '@viswals/services'

import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useStepsError from '@src/hooks/useStepsError'
import { ItemsProps } from './props'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    reset,
    setError,
    formState: { errors },
    control,
  } = useForm()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'data',
  })

  const { loading, setLoading } = useLoading()
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { getErrors, getFieldProps } = useStepsError(errors)

  const [isModalOpen, setIsModelOpen] = useState<boolean>(false)
  const [selectDelete, setSelectDelete] = useState<number | null>()
  const [isReady, setIsReady] = useState<boolean>(false)
  const [paginationActive, setPaginationActive] = useState<number>(1)
  const [destroyList, setDestroyList] = useState<SpecialtyCertificate[]>([])

  const elementClickable = useRef(null)
  const querySelector = gsap.utils.selector(elementClickable)

  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const { fields: FieldSpecialty } = useSelector(
    (state: ReducersProps) => state.datas
  )

  const dataSpecialty: SelectedOptionsProps[] =
    FieldSpecialty.map((item: any) => ({
      label: item.name as string,
      value: item.uuid as string,
    })) ?? []

  // Pagination
  const paginationTotal = 1
  const pagination = Math.ceil(fields.length / paginationTotal)

  useLayoutEffect(() => {
    setPaginationActive(pagination)
  }, [pagination])

  useEffect(() => {
    if (fields.length === 0) {
      setTimeout(() => {
        dispatch(setNavigationSkip(true))
      }, 100)
    } else {
      setTimeout(() => {
        dispatch(setNavigationSkip(false))
      }, 100)
    }
  }, [fields.length])

  useLayoutEffect(() => {
    if (isModalOpen) {
      gsap.fromTo(
        querySelector('.vw-questionModal-backlog'),
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 0.2,
          stagger: 0.2,
        }
      )
    }
  }, [isModalOpen])

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (next && !loading && isReady) {
      if (!confirm) {
        navigate(nextRoute)
      } else {
        handleSubmit(onSubmit)()
      }
    }
  }, [next])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (!verified) {
      document.addEventListener('mousedown', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }
    }
  }, [selectDelete, verified])

  function handleClick(e: any) {
    if (document.getElementsByClassName('vw-select').length) {
      return false
    }
    const pathList = e.path as any[]
    const getCardMap = pathList
      ?.map((m) => m.className)
      ?.filter((f) => f && f?.includes('card-item'))
    const checkCard = !!getCardMap?.length
    if (checkCard) {
      const safeArea = !!pathList
        ?.map((m) => m.className)
        ?.filter((f) => f && f?.includes('vw-form'))?.length
      if (!safeArea) {
        const getIndex = Number(getCardMap[0].split('item-')[1])
        handleSelectDelete(getIndex)
      }
    }
  }

  async function getData() {
    setLoading(true)
    try {
      const request: Request<SpecialtyCertificateFilter> = {
        fields: [
          'uuid',
          'specialistCertificate',
          'specialistCertificateName',
          'specialty',
        ],
        filters: {
          countryRegistration: registrationActive.country.uuid,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveSpecialtyCertificate(request)
      const data: SpecialtyCertificate[] | null = resp.data?.data ?? []

      setTimeout(() => {
        if (data?.length) {
          const newReset = data.map((item: SpecialtyCertificate) => ({
            clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
            countryRegistration: registrationActive.country.uuid,
            specialistCertificate: {
              file: item.specialistCertificate,
              fileName: item.specialistCertificateName,
            },
            specialty: item.specialty,
            uuid: item.uuid,
            destroy: false,
          }))
          reset({ data: newReset })
        }
        setLoading(false)
      }, 200)
    } finally {
      setIsReady(true)
      setLoading(false)
      dispatch(setNavigationActiveNext(true))
    }
  }

  async function onSubmit(data: any) {
    setLoading(true)
    try {
      const convert = Object.values(data?.data) as ItemsProps[]
      const request = convert
        .filter((f) => f.specialty && (!f.destroy || f.uuid))
        .map((item) => ({
          ...item,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.country.uuid,
          specialistCertificate: item.specialistCertificate?.file,
          specialistCertificateName: item.specialistCertificate?.fileName,
        }))

      const destroyedList = destroyList.map((item) => ({
        ...item,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        countryRegistration: registrationActive.country.uuid,
        // @ts-ignore
        specialistCertificate: item.specialistCertificate?.file,
        // @ts-ignore
        specialistCertificateName: item.specialistCertificate?.fileName,
      }))

      await saveSpecialtyCertificate([
        ...destroyedList,
        ...request,
      ] as Request<SpecialtyCertificate>)
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleSelectDelete = (index: number) => {
    if (selectDelete === index) {
      setSelectDelete(null)
      return null
    }
    setSelectDelete(index)
  }

  const handleOpenModel = useCallback(() => {
    setIsModelOpen(true)
  }, [isModalOpen])

  const handleCancelModelDelete = useCallback(() => {
    setSelectDelete(null)
    setIsModelOpen(false)
  }, [isModalOpen])

  const handleConfirmModelDelete = useCallback(() => {
    const uuid = getValues(`data.${selectDelete}.uuid`)
    setValue(`data.${selectDelete}.destroy`, true)
    if (!uuid) {
      remove(selectDelete as number)
    } else {
      update(selectDelete as number, {
        ...watch(`data.${selectDelete}`),
        destroy: true,
      })
      setDestroyList((list) => [
        {
          ...watch(`data.${selectDelete}`),
          destroy: true,
        },
        ...list,
      ])
    }
    if (!confirm) {
      dispatch(setNavigationConfirm(true))
    }
    setSelectDelete(null)
    setIsModelOpen(false)
  }, [isModalOpen])

  return {
    elementClickable,
    confirm,
    verified,
    selectDelete,
    isModalOpen,
    dataSpecialty,
    fields,
    dispatch,
    setPaginationActive,
    watch,
    append,
    setValue,
    register,
    handleSubmit,
    onSubmit,
    handleOpenModel,
    handleCancelModelDelete,
    handleConfirmModelDelete,

    getFieldProps,

    pagination,
    paginationActive,
  }
}

export default useLogic
