import { PatientStatus } from '@viswals/services'
import { StatusDetailType } from '../../components/DetailMapitem/type'
import statusData from './status'

function getFindStatusActive(
  patientStatus: PatientStatus[],
  actualState: StatusDetailType
) {
  const lastStatus = patientStatus.pop()
  if (actualState.status === lastStatus?.state) {
    return {
      backgroundColor: actualState.backgroundActive,
    }
  }
  if (patientStatus.map((m) => m.state).includes(actualState.status)) {
    return {
      borderColor: actualState.beforeBorder,
      iconColor: actualState.beforeIcon,
    }
  }

  return {}
}

export function getFindStatusColor(patientStatus: PatientStatus[]) {
  const statusList = getStatusList(patientStatus)
  return (
    statusList.map((s) => s.style).find((f) => f?.backgroundColor)
      ?.backgroundColor ?? 'red'
  )
}

export default function getStatusList(
  patientStatus: PatientStatus[]
): StatusDetailType[] {
  return statusData.map((s) => ({
    ...s,
    style: getFindStatusActive(JSON.parse(JSON.stringify(patientStatus)), s),
  }))
}
