/* eslint-disable no-plusplus */
type mountYearSelectProps = {
  startYear: number
  finishYear: number
}
const createArrayOfYears = ({
  startYear,
  finishYear,
}: mountYearSelectProps) => {
  const currentYear = new Date().getFullYear()
  const subtractYear = currentYear - finishYear
  const years = []
  while (startYear <= currentYear - subtractYear) {
    years.push(startYear++)
  }
  return years
}

export default createArrayOfYears
