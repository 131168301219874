import React from 'react'

export type FinishRegistrationProps = {
  id?: string
  uuid: string
  title: string
  img: string
  description: React.ReactChild
  isDisabled?: boolean
  isConfirmed?: boolean
  isError?: boolean
  isVerified?: boolean
  item?: string | null
  type?:
    | 'legalNotice'
    | 'privacyPolicy'
    | 'termsConditions'
    | 'brandCopyright'
    | 'dutyResponsibilities'
    | 'missionRights'
    | 'inTeamPolicy'
    | 'freelancerPolicy'
    | 'cancellationPolicy'
}

const TermsOfService: FinishRegistrationProps[] = [
  {
    uuid: '01',
    type: 'termsConditions',
    title: 'Terms & Conditions',
    img: require('@images/terms01.svg').default,
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
  },
  {
    uuid: '02',
    type: 'privacyPolicy',
    title: 'Privacy Policy',
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
    img: require('@images/terms02.svg').default,
  },
  {
    uuid: '03',
    type: 'legalNotice',
    title: 'Legal Notice',
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
    img: require('@images/terms03.svg').default,
  },
]

const ViswalsPolicies: FinishRegistrationProps[] = [
  {
    uuid: '01',
    title: 'Brand & Copyright Infringement',
    type: 'brandCopyright',
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
    img: require('@images/terms04.svg').default,
  },
  {
    uuid: '02',
    type: 'dutyResponsibilities',
    title: 'Professional Duty & Responsibilities',
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
    img: require('@images/terms05.svg').default,
  },
  {
    uuid: '03',
    type: 'missionRights',
    title: 'Viswals Mission & Rights',
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
    img: require('@images/terms06.svg').default,
  },
]

const PaymentAccountCancellationPolicies: FinishRegistrationProps[] = [
  {
    uuid: '01',
    type: 'inTeamPolicy',
    title: 'Viswals InTeam Payment Policy',
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
    img: require('@images/terms07.svg').default,
  },
  {
    uuid: '02',
    type: 'freelancerPolicy',
    title: 'Viswals Freelancer Payment Policy',
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
    img: require('@images/terms08.svg').default,
  },
  {
    uuid: '03',
    type: 'cancellationPolicy',
    title: 'Account Cancellation Policy',
    description: (
      <>
        <p key="01">
          Please be aware that oral health professionals have a legal duty of
          complying with the regulations of the country of practice.
        </p>
        <p key="02">
          As an oral health professional you have the responsibility to hold
          valid dental professional insurance, if this is a requirement in the
          country of practice
        </p>
        <p key="03">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
        <p key="04">
          Performing as an oral health professional without dental insurance in
          countries that require professionals to do so, is a serious offence
          that may lead to erase from the registrar and the inability to perform
          on the ViswalsClinician Platform, in the selected country.
        </p>
      </>
    ),
    img: require('@images/terms09.svg').default,
  },
]

export { TermsOfService, ViswalsPolicies, PaymentAccountCancellationPolicies }
