import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  padding-bottom: 2.1rem;
  padding-right: 0.3rem;

  .vw-tooltip-tooltipChildren {
    width: 31.7rem;
    height: 4.8rem;
    padding: 0.7rem 1rem 1.3rem;

    font-family: var(--font-secondary);
    font-size: 1rem;
    text-align: center;
    line-height: 1.5rem;

    color: #fff;
    background-color: #19aeff;
    border-radius: 0.5rem;

    position: relative;
    z-index: 1;
    > h2 {
      font-size: 1rem;
      line-height: 1.5rem;
    }

    &::before {
      content: '';
      width: 2rem;
      height: 2rem;
      background-color: #19aeff;

      position: absolute;
      bottom: -0.1rem;
      left: 47%;
      transform: rotate(45deg);
      z-index: -1;
    }
  }
  .pendingApproved {
    .vw-tooltip-tooltipChildren {
      background-color: #fd3333;

      &::before {
        background-color: #fd3333;
      }
    }
  }
`

export default Container
