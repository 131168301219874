/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import gsap from 'gsap'
import useLoading from '@src/hooks/useLoading'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import {
  Request,
  RegistrationMode,
  RegistrationModeFilter,
  retrieveRegistrationMode,
  createRegistrationMode,
  updateRegistrationMode,
} from '@viswals/services'
import { useNavigate } from 'react-router-dom'
import useClinicianRegister from '@src/hooks/useClinicianRegister'

const useLogic = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { control, watch, register, handleSubmit, reset } = useForm()

  const { setLoading } = useLoading()
  const { subStepDone, verified, nextRoute } = useClinicianRegister()

  const PlansCardRef = useRef(null)
  const CommissionCardRef = useRef(null)
  const RegistrationCardRef = useRef(null)
  const [showViewMode, setShowViewMode] = useState<boolean>(false)
  const [checkTogglePlan, setCheckTogglePlan] = useState<boolean>(false)
  const [isReady, setIsReady] = useState<boolean>(false)

  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  const { confirm, next } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change') {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  const handleShowViewMode = () => {
    setShowViewMode((value) => !value)
    gsap.to(RegistrationCardRef.current, {
      onStart: () => {
        gsap.fromTo(
          '.gsapCardRegistration',
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1.5,
            ease: 'power2.out',
            stagger: 0.06,
          }
        )
        gsap.fromTo(
          '.gsapCard',
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1.5,
            ease: 'power2.out',
            stagger: 0.04,
          }
        )
        gsap.fromTo(
          '.headerArea',
          { opacity: 0 },
          {
            opacity: 1,
            duration: 1.7,
            delay: -0.2,
          }
        )
        gsap.fromTo(
          '.gsapCardItem',
          {
            opacity: 0,
          },
          {
            delay: -0.2,
            opacity: 1,
            duration: 1.7,
            ease: 'power2.out',
            transformOrigin: '50% 100%',
          }
        )
      },
    })
  }

  const handleCheckTogglePlans = () => {
    setCheckTogglePlan((value) => !value)
    gsap.to(CommissionCardRef.current, {
      onStart: () => {
        gsap.fromTo(
          '.gsapCard',
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1.5,
            ease: 'power2.out',
            stagger: 0.04,
          }
        )
      },
    })
    gsap.to(PlansCardRef.current, {
      onStart: () => {
        gsap.fromTo(
          '.gsapCardItem',
          {
            opacity: 0,
          },
          {
            opacity: 1,
            duration: 1.5,
            ease: 'power2.out',
            stagger: 0.02,
          }
        )
      },
    })
  }

  const getData = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      const request: Request<RegistrationModeFilter> = {
        fields: ['uuid', 'clinicianRegisterUuid', 'mode'],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveRegistrationMode(request)
      const data = resp?.data?.data ?? []
      if (data.length > 0) {
        reset({
          uuid: data[0].uuid,
          mode: data[0].mode,
        })
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    } finally {
      setIsReady(true)
    }
  }

  const onSubmit = async () => {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const dataPayload: RegistrationMode = {
        uuid: watch('uuid'),
        mode: watch('mode'),
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      }
      if (dataPayload.uuid) {
        await updateRegistrationMode(
          dataPayload as Request<RegistrationModeFilter>
        )
      } else {
        await createRegistrationMode(
          dataPayload as Request<RegistrationModeFilter>
        )
      }
      subStepDone()
      navigate(nextRoute)
    } finally {
      setLoading(false)
    }
  }

  return {
    PlansCardRef,
    CommissionCardRef,
    RegistrationCardRef,
    checkTogglePlan,

    showViewMode,

    control,
    watch,
    register,

    verified,

    handleCheckTogglePlans,
    handleShowViewMode,
  }
}

export default useLogic
