import { useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import {
  Dropout,
  DropoutFilter,
  Feedback,
  FeedbackFilter,
  PaginationRequest,
  Rate,
  RateFilter,
  Report,
  ReportCounter,
  ReportFilter,
  Request,
  ShareOurApp,
  ShareOurAppFilter,
  retrieveDropout,
  retrieveFeedback,
  retrieveRate,
  retrieveReport,
  retrieveShare,
} from '@viswals/services'

import { ReducersProps } from '@src/types/reducers'
import {
  setActionRangeUpperCalendar,
  setActionReportCounter,
  setActionReportPlatform,
  setActionReports,
} from '@src/store/reducers/report/report/actions'

import { PlatformType } from '@src/pages/reports/data'
import setActionFeedbacks from '@src/store/reducers/report/feedback/actions'
import setActionRates from '@src/store/reducers/report/rate/actions'
import api from '@src/services/api'
import setActionShares from '@src/store/reducers/report/share/actions'
import setActionDropouts from '@src/store/reducers/report/dropout/actions'

const useReports = () => {
  const totalReportsPerPage = 20

  const dispatch = useDispatch()
  const { reports, feedbacks, rates, shares, dropouts } = useSelector(
    (state: ReducersProps) => state
  )

  const [lastPaginationReportUnusual, setLastPaginationReportUnusual] =
    useState<PaginationRequest>()
  const [lastPaginationFeedback, setLastPaginationFeedback] =
    useState<PaginationRequest>()

  const [reportTotal, setReportTotal] = useState<number>(0)
  const [feedbackTotal, setFeedbackTotal] = useState<number>(0)
  const [rateTotal, setRateTotal] = useState<number>(0)
  const [shareTotal, setShareTotal] = useState<number>(0)
  const [dropoutTotal, setDropoutTotal] = useState<number>(0)

  function setRangeUpperCalendar({
    startSelect,
    endSelect,
  }: {
    startSelect?: string
    endSelect?: string
  }) {
    dispatch(
      setActionRangeUpperCalendar({
        endSelect,
        startSelect,
      })
    )
  }

  function setPlafform(platform: PlatformType) {
    dispatch(setActionReportPlatform(platform))
  }

  function setReports(reportsList: Report[]) {
    dispatch(setActionReports(reportsList))
  }

  function setFeedbacks(feedbackList: Feedback[]) {
    dispatch(setActionFeedbacks(feedbackList))
  }

  function setRates(ratesList: Rate[]) {
    dispatch(setActionRates(ratesList))
  }

  function setShares(ratesList: ShareOurApp[]) {
    dispatch(setActionShares(ratesList))
  }

  function setDropouts(ratesList: Dropout[]) {
    dispatch(setActionDropouts(ratesList))
  }

  function setReportCounter(counter: ReportCounter) {
    dispatch(setActionReportCounter(counter))
  }

  async function getReportUnusual(): Promise<Report[]> {
    getReportCounters()
    try {
      const request: Request<ReportFilter> = {
        filters: {
          platform: reports.platform === 'ALL' ? undefined : reports.platform,
          communication: 'REPORT_ANYTHING_UNUSUAL',
          startDate: reports.startSelect,
          finishDate: reports.endSelect,
        },
        pagination: {
          page: Number(lastPaginationReportUnusual?.page ?? 0) + 1,
          itemsPerPage: totalReportsPerPage,
        },
        orderBy: {
          column: 'createdAt',
          direction: 'desc',
        },
        fields: [
          'uuid',
          'platform',
          'communication',
          'accountUsername',
          'message',
          'createdAt',
          'read',
        ],
      }

      const { data } = await retrieveReport(request)

      if (data.data) {
        const toConvert: Report[] = (data.data as Report[]).map((item) => ({
          ...item,
        }))

        setReports(toConvert)

        const toSetPagination: PaginationRequest = data.pagination

        window.localStorage.setItem(
          'page_report_unusual',
          toSetPagination.page.toString()
        )

        setReportTotal(data.pagination.total)
        return toConvert
      }
      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async function getFeedback(): Promise<Feedback[]> {
    getReportCounters()
    try {
      const request: Request<FeedbackFilter> = {
        filters: {
          platform: reports.platform === 'ALL' ? undefined : reports.platform,
          startDate: reports.startSelect,
          finishDate: reports.endSelect,
        },
        pagination: {
          page: Number(lastPaginationFeedback?.page ?? 0) + 1,
          itemsPerPage: totalReportsPerPage,
        },
        orderBy: {
          column: 'createdAt',
          direction: 'desc',
        },
        fields: [
          'uuid',
          'platform',
          'accountUsername',
          'feedbackType',
          'feedbackWhere',
          'message',
          'read',
          'createdAt',
        ],
      }

      const { data } = await retrieveFeedback(request)

      if (data.data) {
        const toConvert: Feedback[] = (data.data as Feedback[]).map((item) => ({
          ...item,
        }))

        setFeedbacks(toConvert)

        const toSetPagination: PaginationRequest = data.pagination

        window.localStorage.setItem(
          'page_report_feedback',
          toSetPagination.page.toString()
        )

        setFeedbackTotal(data.pagination.total)
        return toConvert
      }
      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async function getRate(): Promise<Rate[]> {
    getReportCounters()
    try {
      const request: Request<RateFilter> = {
        filters: {
          platform: reports.platform === 'ALL' ? undefined : reports.platform,
          startDate: reports.startSelect,
          finishDate: reports.endSelect,
        },
        pagination: {
          page: Number(lastPaginationFeedback?.page ?? 0) + 1,
          itemsPerPage: totalReportsPerPage,
        },
        orderBy: {
          column: 'createdAt',
          direction: 'desc',
        },
        fields: [
          'uuid',
          'platform',
          'accountUsername',
          'rate',
          'read',
          'createdAt',
        ],
      }

      const { data } = await retrieveRate(request)

      if (data.data) {
        const toConvert: Rate[] = (data.data as Rate[]).map((item) => ({
          ...item,
        }))

        setRates(toConvert)

        const toSetPagination: PaginationRequest = data.pagination

        window.localStorage.setItem(
          'page_report_rate',
          toSetPagination.page.toString()
        )
        setRateTotal(data.pagination.total)
        return toConvert
      }
      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async function getShare(): Promise<ShareOurApp[]> {
    getReportCounters()
    try {
      const request: Request<ShareOurAppFilter> = {
        filters: {
          platform: reports.platform === 'ALL' ? undefined : reports.platform,
          startDate: reports.startSelect,
          finishDate: reports.endSelect,
        },
        pagination: {
          page: Number(lastPaginationFeedback?.page ?? 0) + 1,
          itemsPerPage: totalReportsPerPage,
        },
        orderBy: {
          column: 'createdAt',
          direction: 'desc',
        },
        fields: [
          'uuid',
          'platform',
          'accountUsername',
          'share',
          'read',
          'createdAt',
        ],
      }

      const { data } = await retrieveShare(request)

      if (data.data) {
        const toConvert: ShareOurApp[] = (data.data as ShareOurApp[]).map(
          (item) => ({
            ...item,
          })
        )

        setShares(toConvert)

        const toSetPagination: PaginationRequest = data.pagination

        window.localStorage.setItem(
          'page_report_share',
          toSetPagination.page.toString()
        )

        setShareTotal(data.pagination.total)
        return toConvert
      }
      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async function getDropouts(): Promise<Dropout[]> {
    console.log({
      startDate: reports.startSelect,
      finishDate: reports.endSelect,
    })
    getReportCounters()
    try {
      const request: Request<DropoutFilter> = {
        filters: {
          platform: reports.platform === 'ALL' ? undefined : reports.platform,
          startDate: reports.startSelect,
          finishDate: reports.endSelect,
        },
        pagination: {
          page: Number(lastPaginationFeedback?.page ?? 0) + 1,
          itemsPerPage: totalReportsPerPage,
        },
        orderBy: {
          column: 'createdAt',
          direction: 'desc',
        },
        fields: [
          'uuid',
          'platform',
          'accountUsername',
          'reason',
          'read',
          'createdAt',
        ],
      }

      const { data } = await retrieveDropout(request)

      if (data.data) {
        const toConvert: Dropout[] = (data.data as Dropout[]).map((item) => ({
          ...item,
        }))

        setDropouts(toConvert)

        const toSetPagination: PaginationRequest = data.pagination

        window.localStorage.setItem(
          'page_report_dropout',
          toSetPagination.page.toString()
        )

        setDropoutTotal(data.pagination.total)
        return toConvert
      }
      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async function getReportCounters() {
    try {
      const request: Request<ReportFilter> = {
        filters: {
          platform: reports.platform === 'ALL' ? undefined : reports.platform,
          startDate: reports.startSelect,
          finishDate: reports.endSelect,
        },
        fields: [
          'reportTotal',
          'reportTotalRead',
          'feedbackTotal',
          'feedbackTotalRead',
          'rateTotal',
          'rateTotalRead',
        ],
      }

      const { data } = await api.post('/report/counter/retrieve', request)

      if (data.code === 200) {
        setReportCounter(data.data[0])
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    totalReportsPerPage,
    endSelect: reports?.endSelect,
    startSelect: reports?.startSelect,

    rateTotal,
    setRateTotal,

    reportTotal,
    setReportTotal,

    feedbackTotal,
    setFeedbackTotal,

    shareTotal,
    setShareTotal,

    dropoutTotal,
    setDropoutTotal,

    setRangeUpperCalendar,

    platform: reports.platform,
    setPlafform,

    getRate,
    getFeedback,
    getReportUnusual,
    getShare,
    getDropouts,

    lastPaginationReportUnusual,
    setLastPaginationReportUnusual,

    lastPaginationFeedback,
    setLastPaginationFeedback,

    rates: rates.rates,
    setRates,

    reports,
    setReports,

    feedbacks,
    setFeedbacks,

    shares,
    setShares,

    dropouts,
    setDropouts,

    getReportCounters,
    setReportCounter,
  }
}

export default useReports
