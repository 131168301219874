/* eslint-disable no-plusplus */

import createArrayOfYears from '@src/core/createArrayOfYears'
import { Gender } from '@viswals/services'

const DEFAULT_CLINICIAN_START_AGE = 17
const DEFAULT_CLINICIAN_FINAL_AGE = 92

type genderSelectProps = {
  label: string
  value: Gender
}

const genderSelect: genderSelectProps[] = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]

const daySelect = Array.from({ length: 31 }, (_, i) => i + 1).map((d) => ({
  label: d.toString(),
  value: d,
}))
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const monthSelect = Array.from({ length: 12 }, (_, i) => i + 1).map((d) => ({
  label: months[d - 1],
  value: d,
}))

const yearSelect = createArrayOfYears({
  startYear: new Date().getFullYear() - DEFAULT_CLINICIAN_FINAL_AGE,
  finishYear: new Date().getFullYear() - DEFAULT_CLINICIAN_START_AGE,
})
  .map((d) => ({
    label: d.toString(),
    value: d,
  }))
  .reverse()

export { genderSelect, daySelect, monthSelect, yearSelect }
