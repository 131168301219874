/* eslint-disable import/prefer-default-export */
import { ActionProps, ItemProps, ValueProps } from './props'

export function setActionLayout(
  item: ItemProps,
  value: ValueProps
): ActionProps {
  return {
    type: 'SET_LAYOUT',
    item,
    value,
  }
}
