import styled, { css } from 'styled-components'
import MobileInitial from '@images/mobileInitial.svg'
import MobileFocused from '@images/mobileValid.svg'
import MobileInvalid from '@images/mobileInvalid.svg'
import MobileDelete from '@images/mobileDelete.svg'

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .vw-questionModal-description {
    width: 45.9rem !important;
    min-width: 39.9rem !important;
    margin-bottom: 5.6rem;
    margin-top: -0.3rem;
  }
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-socialMedia-header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .socialMedia {
      h2 {
        font-size: 2rem;
        font-weight: 700;
        color: var(--gray-100-color);
        margin-bottom: 0.3rem;
      }

      span {
        font-size: 1.3rem;
        color: var(--gray-200-color);
      }

      button {
        width: 16.6rem;
        height: 4.2rem;
        margin-top: 3.6rem;
        margin-right: 2.5rem;
        padding: 1rem;
        background-color: #fd3333;
        border-radius: 0.5rem;

        span {
          font-family: var(--font-primary);
          font-size: 1.2rem;
          color: #fff;
          padding: 0;
          padding-bottom: 0.2rem;
        }
      }
    }
  }

  .socialArea {
    display: flex;
    gap: 3.5rem;
  }
`

export type SocialMediaProps = {
  focused?: boolean
  valid?: boolean
  error?: boolean
  mobileActive?: boolean
  delete?: boolean
  background?: string
}

export const SocialMedia = styled.div<SocialMediaProps>`
  display: flex;
  padding-bottom: 3.8rem;
  padding-left: 0.1rem;

  .mobileContainer {
    height: 53.2rem;
    width: 26.6rem;

    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    color: var(--gray-100-color);

    &::before {
      content: '';
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      z-index: -1;

      width: 100%;
      height: 100%;
      max-width: 26.6rem !important;
      max-height: 53.2rem !important;
      padding: 0 !important;

      ${(props) => {
        if (props.error) {
          return css`
            background-image: url(${MobileInvalid});
          `
        }
        if (props.focused) {
          return css`
            background-image: url(${MobileFocused});
          `
        }

        if (props.valid) {
          return css`
            background-image: url(${props.background});
          `
        }
        return css`
          background-image: url(${MobileInitial});
        `
      }}
      background-repeat: no-repeat;
      background-position: center;
      background-size: 120%;
    }

    ${(props) => {
      if (props.delete) {
        return css`
          background-image: url(${MobileDelete});
          background-repeat: no-repeat;
          background-position: center;
          background-size: 120%;
        `
      }
    }}

    .topArea {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 9.3rem;
      margin-bottom: 11.6rem;
      ${(props) => {
        if (props.focused) {
          return css`
            opacity: 1;
          `
        }
        if (props.error) {
          return css`
            opacity: 1;
          `
        }
        if (props.delete) {
          return css`
            opacity: 0.4;
          `
        }
        if (props.valid) {
          return css`
            opacity: 1;
            color: #fff;
          `
        }
        return css`
          opacity: 0.2;
        `
      }}

      > p {
        font-size: 1.8rem;
        font-weight: 700;
        text-transform: capitalize;
        margin-bottom: 3.4rem;
      }

      .vw-icon {
        height: 6.1rem;
      }
    }

    > .vw-icon {
      height: 2rem;
      margin-bottom: 5.5rem;
      ${(props) => {
        if (props.focused) {
          return css`
            opacity: 1;
          `
        }
        if (props.error) {
          return css`
            opacity: 1;
          `
        }
        if (props.delete) {
          return css`
            opacity: 0.4;
          `
        }
        if (props.valid) {
          return css`
            opacity: 1;
            color: #fff;
          `
        }
        return css`
          opacity: 0.4;
        `
      }}
    }

    > button {
      height: 3.2rem;
      width: 20.2rem;

      background-color: transparent;
      border: 0.1rem solid #1674eb;
      border-radius: 0.5rem;

      font-size: 1.1rem;
      font-family: var(--font-secondary);
      color: var(--gray-100-color);
      text-align: center;
      position: relative;

      ${(props) => {
        if (props.focused) {
          return css`
            border: 0.1rem solid #1674eb;
          `
        }
        if (props.error) {
          return css`
            border: 0.1rem solid #798089;
          `
        }
        if (props.valid) {
          if (props.delete)
            return css`
              border: 0.1rem solid #798089;

              opacity: 0.4;
            `
          return css`
            border: 0.1rem solid #fff;
            color: #fff;
          `
        }

        return css`
          border: 0.1rem solid #bebebe45;
          opacity: 0.1;
        `
      }}
      &:hover {
        opacity: 1 !important;
      }

      .vw-socialMedia-inputArea {
        width: 66rem;
        height: 18.8rem;
        top: -9.6rem;
        left: -23rem;
        padding: 4.3rem 5rem 5rem;
        text-align: center;

        background-color: #242831f2;
        opacity: 1 !important;
        position: absolute;
        z-index: 999 !important;

        &.TWITTER {
          left: -38rem;
        }

        &:hover {
          opacity: 1 !important;
        }

        > p {
          font-size: 1.6rem;
          font-weight: 700;
          font-family: var(--font-primary);
          color: var(--gray-100-color);

          margin-bottom: 1.8rem;
        }

        input {
          padding-bottom: 0.2rem;
          &::placeholder {
            text-align: center;
            font-size: 1.2rem;
            margin-right: 2rem;
          }
        }
      }
    }

    .validationArea {
      height: 9rem;
      padding-top: 2.3rem;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .vw-icon {
        width: 2.2rem;
        height: 2.2rem;
        color: #fd3333;
        margin-bottom: 0.5rem;
      }
      > p {
        font-size: 1rem;
        font-family: var(--font-secondary);
      }

      .likedIcon {
        width: 2.2rem;
        height: 2.2rem;
        background-color: #0e4d9d57;
        border-radius: 50%;
        margin-bottom: 0.5rem;

        .vw-icon {
          width: 0.6rem;
          color: #fff;

          ${(props) => {
            if (props.delete) {
              return css`
                opacity: 0.4;
              `
            }
          }}
        }
      }
      .likedP {
        color: #0e4d9dc2;
      }
    }
  }
`
