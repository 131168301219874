/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button, Input } from '@viswals/components'
import { createFieldPsychology, Request, FieldMedicine } from '@viswals/services'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import { StyledContentFieldMedicine } from './styles'

export default function FormCreateFieldMedicine() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, setValue } = useForm()

  async function onSubmit(data: any) {
    const dataToSend: any = {
      name: data.name,
      enabled: true,
      description: "psychologist",
      icon: "psychologist",
      position: 1,
      area: {
        uuid: "e117dcf1-4acd-499f-80d2-7c868f23d6d0"
      }
    }

    try {
      setIsError('')
      await createFieldPsychology(dataToSend as Request<FieldMedicine>)
      navigate('/administration/common/field-psychology')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentFieldMedicine className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Psychology Field"
            description="Here you can register on new Psychology Field"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/field-psychology')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentFieldMedicine>
  )
}
