import { Report, ReportCounter } from '@viswals/services'

import { PlatformType } from '@src/pages/reports/data'
import { ActionProps } from './props'

export function setActionRangeUpperCalendar({
  startSelect,
  endSelect,
}: {
  startSelect?: string
  endSelect?: string
}): ActionProps {
  return {
    type: 'SET_RANGE',
    startSelect,
    endSelect,
  }
}

export function setActionReportPlatform(platform: PlatformType): ActionProps {
  return {
    type: 'SET_PLATFORM',
    platform,
  }
}

export function setActionReports(reports: Report[]): ActionProps {
  return {
    type: 'SET_REPORTS',
    reports,
  }
}

export function setActionReportCounter(counter: ReportCounter): ActionProps {
  return {
    type: 'SET_COUNTER',
    counter,
  }
}
