import styled from 'styled-components'

export const ContainerBox = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
`

export const HeaderBoxQuestion = styled.div`
  display: flex;
  gap: 2rem;
  div {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
    h4 {
      font-family: 'Nebula';
      font-weight: bold;
      font-size: 14px;
      color: #ff9f0a;
    }
    span {
      font-family: 'Oxanium';
      font-size: 0.9rem;
      font-weight: normal;
      color: #7c7c80;
    }
  }
`

export const ColorxBox = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.4rem;

  margin-top: 4rem;
  margin-bottom: 1rem;

  div {
    content: '';
    height: 0.1rem;
    width: 100%;
    background-color: #ff9f0a;

    &:nth-child(1) {
      flex: 1;
    }

    &:nth-child(n + 2) {
      width: 3.2rem;
    }

    &:nth-child(2) {
      background-color: #ff9f0a;
    }
    &:nth-child(3) {
      background-color: #ff9f0a;
    }
    &:nth-child(4) {
      background-color: #ff9f0a;
    }
  }
`

export const TextArea = styled.textarea`
  flex: 1;
  resize: none;
  background-color: #222224;
  border: 1px solid #2c2c2e;
  border-radius: 0.5rem;
  padding: 2rem;

  font-family: 'Oxanium';
  font-weight: normal;
  font-size: 1.3rem;
  caret-color: #ff9f0a;
  color: #7c7c80;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #363638;
  }
`
