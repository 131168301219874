import { SelectedOptionsProps } from '@viswals/components'

const sortFieldsOfDentistry = (
  fields: SelectedOptionsProps[]
): SelectedOptionsProps[] => {
  fields.sort((fieldA, fieldB) => {
    const _fieldA = fieldA.label.toLocaleLowerCase()
    const _fieldB = fieldB.label.toLocaleLowerCase()
    if (_fieldA < _fieldB) {
      return -1
    }
    if (_fieldA > _fieldB) {
      return 1
    }
    return 0
  })

  return fields
}

export default sortFieldsOfDentistry
