import moment from 'moment'
import { ActionProps, ReportStoreProps } from './props'

const INITIAL_STATE: ReportStoreProps = {
  platform: 'PATIENT',
  startSelect: moment().format('YYYY-MM-DD'),
  endSelect: moment().format('YYYY-MM-DD'),
}

export default function reducer(
  state = INITIAL_STATE,
  action: ActionProps
): ReportStoreProps {
  if (action.type === 'SET_RANGE') {
    return {
      ...state,
      endSelect: action.endSelect,
      startSelect: action.startSelect,
    }
  }
  if (action.type === 'SET_PLATFORM') {
    return {
      ...state,
      platform: action.platform,
    }
  }
  if (action.type === 'SET_REPORTS') {
    return {
      ...state,
      reports: action.reports,
    }
  }
  if (action.type === 'SET_COUNTER') {
    return {
      ...state,
      counter: action.counter,
    }
  }

  return state
}
