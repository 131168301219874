import * as React from "react";

const BuildingIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            data-name="Path 92309"
            d="M10.718 6.334v8.665h2.721v-7.09ZM5.832 8.943l1.615.933v5.122h1.106V4.452l1.615.932V1.401l-4.336 2.5Zm-.55 6.055v-5.74L2.561 7.687v7.312ZM10.718.45v5.251l3.271 1.889v7.408H16v.55H0v-.55h2.012V6.737l3.271 1.888V3.587Z"
            fill={props.color || "#b665d5"}
        />
    </svg>
);

export default BuildingIcon;