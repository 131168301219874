/* eslint-disable @typescript-eslint/no-explicit-any */

import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {Button, Input, Select, SelectedOptionsProps,} from '@viswals/components'
import {createOralHealthProblem, OralHealthProblem, Request} from '@viswals/services'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import {StyledContentOralHealthProblem} from './styles'

export default function FormCreateOralHealthProblem() {
    const navigate = useNavigate()

    const [isError, setIsError] = useState<string>('')

    const {watch, register, handleSubmit} = useForm()

    const listGroups = [{label: 'Face', value: 'FACE'}, {label: 'Jaws', value: 'JAWS'}, {
        label: 'Teeth',
        value: 'TEETH'
    }, {label: 'Gums', value: 'GUMS'}] as SelectedOptionsProps[];

    async function onSubmit(data: any) {
        const dataToSend: OralHealthProblem = {
            title: data.title,
            groupName: data.groupName,
            enabled: true,
        }

        try {
            setIsError('')
            await createOralHealthProblem(dataToSend as Request<OralHealthProblem>)
            navigate('/administration/common/oral-health-problem')
        } catch (error: any) {
            setIsError(error.message)
        }
    }

    return (
        <StyledContentOralHealthProblem className="styled-form">
            <form
                onSubmit={handleSubmit(onSubmit)}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
            >
                <div className="content-mini-form-box">
                    <HeaderContentCommon
                        name="Add new Oral Health Problem"
                        description="Here you can register on new Oral Health Problem"
                    />
                    <Input label="Title" formRegister={{...register('title')}}/>

                    <Select
                        label="Group"
                        labelTitle="Groups"
                        options={
                            listGroups.map((value) => ({
                                label: value.label,
                                value: value.value,
                            })) as SelectedOptionsProps[]
                        }
                        formRegister={{...register('groupName'), watch}}
                    />

                    <Button label="Create" type="submit"/>
                    <div
                        className="content-button-cancell"
                        aria-hidden
                        onClick={() => navigate('/administration/common/oral-health-problem')}
                    >
                        Cancel
                    </div>
                    <div className="boxError">
                        {isError.length > 0 ? `${isError}` : ''}
                    </div>
                </div>
            </form>
        </StyledContentOralHealthProblem>
    )
}
