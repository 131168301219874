import React from 'react'
import { UploadCircle } from '@viswals/components'

import { ContainerUploadPhoto } from './UploadPhotoStyles'
import useLogic from './useLogic'

const StepUploadPhoto = () => {
  const {
    inputRefFile,

    handleSubmit,
    onSubmit,
    register,
    watch,
    setValue,
    uploadChanged,
  } = useLogic()

  return (
    <ContainerUploadPhoto onSubmit={handleSubmit(onSubmit)}>
      <h1>Upload Photo</h1>
      <p>You can upload your photo, or you can also drag the photo here</p>

      <UploadCircle
        title="JPG, PNG or PDF, smaller than 10MB"
        description="Drag and drop your image here or"
        labelButtonUpload="Upload Photo"
        formRegister={{ ...register('upload'), watch, setValue }}
        onChanged={() => uploadChanged()}
        inputRefExtra={inputRefFile}
      />
    </ContainerUploadPhoto>
  )
}

export default StepUploadPhoto
