import ContainerCardLayout from './styles'

export type CardLayoutGlobalAccountsProps = {
  children: React.ReactNode
  classNames?: string
}

export default function CardLayoutGlobalAccounts({
  children,
  classNames,
}: CardLayoutGlobalAccountsProps) {
  return (
    <ContainerCardLayout
      className={`vw-card-layout-global-accounts ${classNames}`}
    >
      {children}
    </ContainerCardLayout>
  )
}
