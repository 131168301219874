import { Routes, Route } from 'react-router-dom'

import Layout from '../layout'

import RegulatoryBodyTable from './table'
import FormUpdateLanguage from './form_update'
import FormCreateRegulatoryBody from './form_create'

export default function RegulatoryBodyPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <RegulatoryBodyTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateRegulatoryBody />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateLanguage />
          </Layout>
        }
      />
    </Routes>
  )
}
