import { AccountGroup } from '@src/types/account'
import { useParams } from 'react-router-dom'

const useLogic = () => {
  const params = useParams()

  function getGroupUuid(groups: AccountGroup[]) {
    if (!params?.slug) {
      return groups[0]?.uuid
    }
    return groups?.find((f) => f.slug === params?.slug)?.uuid
  }

  return { getGroupUuid }
}

export default useLogic
