/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Button, Input, Select, Toggle } from '@viswals/components'
import { Request, CourseTitle, updateCourseTitle } from '@viswals/services'

import { StyledContentInstitution } from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'
import { courseTitleTypes } from './form_create'

export default function FormUpdateCourseTitle() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, reset, control } = useForm()

  useEffect(() => {
    (async () => {
      const getCourseToSave: CourseTitle = JSON.parse(
        window.localStorage.getItem('courseTitleEdit') as string
      )
      reset(getCourseToSave)
    })()
  }, [])

  async function onSubmit(data: any) {
    const dataToSend: any = {
      uuid: watch('uuid'),
      name: data.title,
      type: data.type,
      enabled: data.enabled,
      area: {
        uuid: data.area
      }
    }

    try {
      setIsError('')
      await updateCourseTitle(dataToSend as Request<CourseTitle>)
      window.localStorage.removeItem('courseTitleEdit')
      navigate('/administration/common/course-title')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentInstitution className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Edit Course Title"
            description="Here you can edit course title"
          />
          <Input label="Title" formRegister={{ ...register('title') }} />

          <Select
            label="Course Type"
            labelTitle="Course Types"
            placeholder="Select here the type of course"
            options={courseTitleTypes.map((value) => ({
              label: value.label,
              value: value.value,
              icon: '',
              isoCode: '',
            }))}
            formRegister={{ ...register('type'), watch }}
          />

          <div style={{ height: 20 }} />

          <Select
            label="Area"
            labelTitle="Select Area"
            placeholder="Select area"
            options={[
              {
                label: 'Medicine',
                value: '84ce4d70-a442-412b-bf27-06f4544a8661',
                icon: '',
                isoCode: '',
              },
              {
                label: 'Dentistry',
                value: '4beed17b-a38a-4da1-8b26-94d2f1513001',
                icon: '',
                isoCode: '',
              },
              {
                label: 'Psychology',
                value: 'e117dcf1-4acd-499f-80d2-7c868f23d6d0',
                icon: '',
                isoCode: '',
              }
            ]}
            formRegister={{ ...register('area'), watch }}
          />

          <div className="content-toggle-switch">
            <Toggle isBig name="enabled" control={control} />
            <div className="box-preview">Enable Course Title</div>
          </div>

          <Button label="Save" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/course-title')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentInstitution>
  )
}
