import StepPayments from './payments'
import StepRegistrationMode from './registration-mode'
import StepWallet from './wallet'

const listStep6 = [
  {
    name: 'Registration Mode',
    subStep: 'REGISTRATION_MODE',
    route: 'registration-mode',
    component: <StepRegistrationMode />,
  },
  {
    name: 'Wallet',
    subStep: 'WALLET',
    route: 'wallet',
    component: <StepWallet />,
  },
  {
    name: 'Payments',
    subStep: 'PAYMENTS',
    route: 'payments',
    component: <StepPayments />,
  },
]

export default listStep6
