import moment, { Moment } from "moment";

const fullDateFormate = "YYYY-MM-DD", yearMonthFormate = "YYYY-MM", dayFormate = "ddd", dateFormate = "DD", monthNumFormate = "MM",
    monthStringFormate = "MMM", monthFullStringFormate = "MMMM", yearStringFormate = "YYYY", monthYearFormate = "MMMM YYYY"

const useLogic = () => {

    const preAppendZero = (count: number) => {
        return count > 9 ? count.toString() : `0${count}`
    }

    const getDayDateForMCYear = (fromDate: string, selectedDate: Moment) => {
        let daysData: any = [], startDateOfMonth = moment(fromDate, fullDateFormate).startOf('week').format(fullDateFormate),
            startDate = moment(startDateOfMonth, fullDateFormate), today = moment().format(fullDateFormate);

        for (let index = 0; index < 42; index++) {
            let updatedDate = startDate
            daysData.push({
                day: updatedDate.format(dayFormate),
                date: updatedDate.format(dateFormate),
                is_today: updatedDate.format(fullDateFormate) === today,
                disable: (updatedDate.format(yearMonthFormate) !== selectedDate.format(yearMonthFormate)),
                date_string: updatedDate.format(fullDateFormate),
            })
            startDate.add(1, 'day')
        }
        return daysData
    }

    const getWeekDateForUC = (fromDate: string, selectedDate: Moment, forFastCal: boolean = false) => {
        let daysData: any = [], startDateOfMonth = moment(fromDate, fullDateFormate).startOf('week').format(fullDateFormate),
            startDate = moment(startDateOfMonth, fullDateFormate), today = moment().format(fullDateFormate)

        for (let index = 0; index < 6; index++) {
            let weekData = []
            let isThisWeekActive = false
            for (let j = 0; j < 7; j++) {
                let updatedDate = startDate
                let isThisDateIsToday = updatedDate.format(fullDateFormate) === selectedDate.format(fullDateFormate)
                isThisWeekActive = isThisWeekActive || isThisDateIsToday
                weekData.push({
                    day: updatedDate.format(dayFormate),
                    date: updatedDate.format(dateFormate),
                    active: updatedDate.format(fullDateFormate) === selectedDate.format(fullDateFormate),
                    is_today: updatedDate.format(fullDateFormate) === today,
                    disable: forFastCal ? ((updatedDate.format(yearMonthFormate) !== moment(fromDate, fullDateFormate).format(yearMonthFormate))) : (updatedDate.format(yearMonthFormate) !== selectedDate.format(yearMonthFormate)),
                    date_string: updatedDate.format(fullDateFormate),
                    date_no: updatedDate.day(),
                })
                startDate.add(1, 'day')
            }
            daysData.push({ active: isThisWeekActive, items: weekData })
        }
        return daysData
    }

    const getMonthDateForUC = (fromDate: string, selectedDate: Moment) => {
        let daysData: any = [], startDateOfMonth = moment(fromDate, fullDateFormate).startOf('week').format(fullDateFormate),
            startDate = moment(startDateOfMonth, fullDateFormate), today = moment().format(fullDateFormate),
            monthData = [], isThisMonthActive = false

        for (let index = 0; index < 42; index++) {

            let updatedDate = startDate, isThisDateIsToday = updatedDate.format(fullDateFormate) === selectedDate.format(fullDateFormate);

            if (updatedDate.format(dateFormate) === "01" && monthData.length) {
                daysData.push({ active: isThisMonthActive, items: [...monthData] })
                isThisMonthActive = false
                monthData = []
            }

            isThisMonthActive = isThisMonthActive || isThisDateIsToday

            monthData.push({
                day: updatedDate.format(dayFormate),
                date: updatedDate.format(dateFormate),
                active: isThisDateIsToday,
                is_today: updatedDate.format(fullDateFormate) === today,
                disable: updatedDate.format(yearMonthFormate) !== selectedDate.format(yearMonthFormate),
                date_string: updatedDate.format(fullDateFormate),
            })

            startDate.add(1, 'day')

            if (index === 41 && monthData.length) {
                daysData.push({ active: isThisMonthActive, items: [...monthData] })
            }
        }

        return daysData
    }

    const getYearDateForUC = (selectedDate: Moment) => {
        const months: Array<any> = [['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'], ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']];
        return months.map((ele: any, index: number) => {
            let isThisSlotSelected = false
            let halfMonthData: any = ele.map((eleMonth: any, eleMonthIndex: number) => {
                isThisSlotSelected = isThisSlotSelected || selectedDate.format(monthStringFormate) === eleMonth
                let eleMonthNum = moment(eleMonth, monthStringFormate).format(monthNumFormate)
                return {
                    is_today: moment().format(yearMonthFormate) === `${selectedDate.format(yearStringFormate)}-${eleMonthNum}`,
                    month_num: index === 0 ? '01' : '07',
                    real_month_num: preAppendZero(eleMonthIndex + (index === 0 ? 1 : 7))
                }
            })
            return { active: isThisSlotSelected, items: halfMonthData }
        })
    }

    const getWeekName = () => {
        return ['LABEL.Sun', 'LABEL.Mon', 'LABEL.Tue', 'LABEL.Wed', 'LABEL.Thu', 'LABEL.Fri', 'LABEL.Sat'];
    }

    const getAllMonthUC = (selectedDate: Moment) => {
        const months: Array<string> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const updatedMonth: any = months.map((ele: string, index: number) => ({ month_num: index, name: `LABEL.${ele}`, active: selectedDate.format(monthStringFormate) === ele }))
        return updatedMonth
    }

    const getYearMonthUC = (selectedDate: Moment) => {
        const months: Array<any> = [['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'], ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']];
        return months.map((ele: any, index: number) => {
            let isThisSlotSelected = false
            let halfMonthData: any = ele.map((eleMonth: any) => {
                isThisSlotSelected = isThisSlotSelected || selectedDate.format(monthStringFormate) === eleMonth
                let eleMonthNum = moment(eleMonth, monthStringFormate).format(monthNumFormate)
                return { name: `LABEL.${eleMonth}`, month_num: index === 0 ? '01' : '07', is_today: moment().format(yearMonthFormate) === `${selectedDate.format(yearStringFormate)}-${eleMonthNum}` }
            })
            return { active: isThisSlotSelected, items: halfMonthData }
        })
    }

    const getQuickActionForUC = (type: number) => {
        if (type === 0) {
            return {
                left: [{ name: '- 12 M', amount: 12, type: 'month' }, { name: '- 6 M', amount: 6, type: 'month' }, { name: '- 3 M', amount: 3, type: 'month' }, { name: '- 1 M', amount: 1, type: 'month' }, { name: '- 2 w', amount: 2, type: 'week' }, { name: '- 1 w', amount: 1, type: 'week' }],
                right: [{ name: '+ 1 w', amount: 1, type: 'week' }, { name: '+ 2 w', amount: 2, type: 'week' }, { name: '+ 1 M', amount: 1, type: 'month' }, { name: '+ 3 M', amount: 3, type: 'month' }, { name: '+ 6 M', amount: 6, type: 'month' }, { name: '+ 12 M', amount: 12, type: 'month' }]
            }
        } else if (type === 1) {
            return {
                left: [{ name: '- 12 M', amount: 12, type: 'month' }, { name: '- 9 M', amount: 9, type: 'month' }, { name: '- 6 M', amount: 6, type: 'month' }, { name: '- 3 M', amount: 3, type: 'month' }, { name: '- 2 M', amount: 2, type: 'month' }, { name: '- 1 M', amount: 1, type: 'month' }],
                right: [{ name: '+ 1 M', amount: 1, type: 'month' }, { name: '+ 2 M', amount: 2, type: 'month' }, { name: '+ 3 M', amount: 3, type: 'month' }, { name: '+ 6 M', amount: 6, type: 'month' }, { name: '+ 9 M', amount: 9, type: 'month' }, { name: '+ 12 M', amount: 12, type: 'month' }]
            }
        } else if (type === 2) {
            return {
                left: [{ name: '- 18 M', amount: 18, type: 'month' }, { name: '- 12 M', amount: 12, type: 'month' }, { name: '- 9 M', amount: 9, type: 'month' }, { name: '- 6 M', amount: 6, type: 'month' }, { name: '- 3 M', amount: 3, type: 'month' }, { name: '- 1 M', amount: 1, type: 'month' }],
                right: [{ name: '+ 1 M', amount: 1, type: 'month' }, { name: '+ 3 M', amount: 3, type: 'month' }, { name: '+ 6 M', amount: 6, type: 'month' }, { name: '+ 9 M', amount: 9, type: 'month' }, { name: '+ 12 M', amount: 12, type: 'month' }, { name: '+ 18 M', amount: 18, type: 'month' }]
            }
        }

    }

    const getDateForFC = (startOfCalendar: Moment, selectedDate: Moment) => {
        const yearsData: any = [], startedDate = startOfCalendar.startOf('month')
        let currentSelectedDateDiff = moment().add(24, 'month').diff(startedDate, 'month')
        for (let i = 0; i < currentSelectedDateDiff; i++) {
            const yearItemData = {
                month_name: startedDate.format(monthFullStringFormate),
                month_no: parseInt(startedDate.format(monthNumFormate)),
                year_no: parseInt(startedDate.format(yearStringFormate)),
                selected: startedDate.format(yearMonthFormate) === selectedDate.format(yearMonthFormate),
                items: getWeekDateForUC(startedDate.format(fullDateFormate), selectedDate, true)
            }
            yearsData.push(yearItemData)
            startedDate.add(42, 'day').startOf('month')
        }
        return yearsData
    }

    const getDateForMonthViewMC = (startOfCalendar: Moment, selectedDate: Moment, numberOfMonth: number = 1) => {
        const yearsData: any = [], startedDate = startOfCalendar.startOf('month')
        for (let i = 0; i < numberOfMonth; i++) {
            const { data, count } = getMonthDateForMC(startedDate.format(fullDateFormate), selectedDate, i)
            const yearItemData = {
                month: startedDate.format(monthStringFormate),
                year: startedDate.format(yearStringFormate),
                selected: startedDate.format(yearMonthFormate) === selectedDate.format(yearMonthFormate),
                items: data
            }
            yearsData.push(yearItemData)
            if (i === 0) {
                startedDate.startOf('week')
            }
            startedDate.add(count, 'day')
        }
        return yearsData
    }

    const getDateForYearViewMC = (startOfCalendar: Moment, selectedDate: Moment, numberOfMonth: number = 1) => {
        const yearsData: any = [], startedDate = startOfCalendar.startOf('month')
        for (let i = 0; i < numberOfMonth; i++) {
            const { data, count } = getMonthDateForMC(startedDate.format(fullDateFormate), selectedDate, i)
            const yearItemData = {
                month: startedDate.format(monthStringFormate),
                month_num: startedDate.format(monthNumFormate),
                year: startedDate.format(yearStringFormate),
                selected: startedDate.format(yearMonthFormate) === selectedDate.format(yearMonthFormate),
                items: data
            }
            yearsData.push(yearItemData)
            if (i === 0) {
                startedDate.startOf('week')
            }
            startedDate.add(count, 'day')
        }
        return yearsData
    }

    const getMonthDateForMC = (fromDate: string, selectedDate: Moment, index: number) => {
        let daysData: any = [], startDateOfMonth = moment(fromDate, fullDateFormate).format(fullDateFormate),
            startDate = moment(startDateOfMonth, fullDateFormate), today = moment().format(fullDateFormate), count = 0

        if (index === 0) {
            startDate.startOf('week')
        }

        while (moment(fromDate, fullDateFormate).format(monthYearFormate) === startDate.format(monthYearFormate) || count === 0) {
            let weekData = []
            let isThisWeekActive = false
            for (let j = 0; j < 7; j++) {
                let updatedDate = startDate
                let isThisDateIsToday = updatedDate.format(fullDateFormate) === selectedDate.format(fullDateFormate)
                isThisWeekActive = isThisWeekActive || isThisDateIsToday
                weekData.push({
                    day: updatedDate.format(dayFormate),
                    date: updatedDate.format(dateFormate),
                    active: updatedDate.format(fullDateFormate) === selectedDate.format(fullDateFormate),
                    is_today: updatedDate.format(fullDateFormate) === today,
                    date_string: updatedDate.format(fullDateFormate),
                })
                startDate.add(1, 'day')
                count++
            }
            daysData.push({ active: isThisWeekActive, items: weekData })
        }

        return { data: daysData, count }
    }

    const getWeekDateForMC = (fromDate: string, selectedDate: Moment) => {
        let weekData = [], startDateOfMonth = moment(fromDate, fullDateFormate).startOf('week').format(fullDateFormate),
            startDate = moment(startDateOfMonth, fullDateFormate), today = moment().format(fullDateFormate)

        for (let j = 0; j < 7; j++) {
            weekData.push({
                day: startDate.format(dayFormate),
                date: startDate.format(dateFormate),
                active: startDate.format(fullDateFormate) === selectedDate.format(fullDateFormate),
                is_today: startDate.format(fullDateFormate) === today,
                disable: (startDate.format(yearMonthFormate) !== selectedDate.format(yearMonthFormate)),
                date_string: startDate.format(fullDateFormate),
            })
            startDate.add(1, 'day')
        }
        return weekData
    }

    const getDateForMonth = (monthString: string) => {
        const dateData: any = [], startDate = moment(monthString, yearMonthFormate).startOf('month'),
            endDateCount = parseInt(moment(monthString, yearMonthFormate).endOf('month').format("DD")),
            today = moment().format(fullDateFormate)
        for (let i = 0; i < 31; i++) {
            if (endDateCount - 1 < i) {
                dateData.push({})
            } else {
                dateData.push({
                    day: startDate.format(dayFormate),
                    date: startDate.format(dateFormate),
                    date_string: startDate.format(fullDateFormate),
                    is_today: startDate.format(fullDateFormate) === today,
                })
                startDate.add(1, 'day')
            }
        }
        return dateData
    }

    return {
        getDayDateForMCYear,
        getWeekDateForUC,
        getMonthDateForUC,
        getAllMonthUC,
        getWeekName,
        getQuickActionForUC,
        getDateForFC,
        getYearMonthUC,
        getYearDateForUC,
        getWeekDateForMC,
        getDateForMonthViewMC,
        getDateForYearViewMC,
        getDateForMonth
    }
}

export default useLogic