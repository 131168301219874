/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Input,
  Select,
  SelectedOptionsProps,
} from '@viswals/components'
import { Request, createBank, Bank } from '@viswals/services'
import useDatas from '@src/hooks/useDatas'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import { StyledContentCity } from './styles'

export default function FormCreateBank() {
  const navigate = useNavigate()

  const { countries } = useDatas()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit } = useForm()

  async function onSubmit(data: any) {
    const dataToSend: Bank = {
      name: data.name,
      countryUuid: data.countryUuid,
      enabled: true,
    }

    try {
      setIsError('')
      await createBank(dataToSend as Request<Bank>)
      navigate('/administration/common/bank')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentCity className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Bank"
            description="Here you can register on new bank"
          />
          <Input label="Name" formRegister={{ ...register('name') }} />

          <Select
            label="Country"
            labelTitle="Countries"
            options={
              countries.map((value) => ({
                label: value.name,
                value: value.uuid,
                icon: value.icon,
              })) as SelectedOptionsProps[]
            }
            formRegister={{ ...register('countryUuid'), watch }}
          />
          <Button label="Create" type="submit" />
          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/bank')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentCity>
  )
}
