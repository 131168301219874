/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { FieldErrors, FieldErrorsImpl } from 'react-hook-form'

import useClinicianRegister from './useClinicianRegister'

const useStepsError = (errors: any) => {
  const { subObj, verified } = useClinicianRegister()
  // const { registrationActive } = useSelector(
  //   (state: ReducersProps) => state.clinician
  // )

  // @ts-ignore
  function getErrors(setError: any) {
    // const listErros = subObj?.registers.find(
    //   (f) =>
    //     !f.countryRegistration ||
    //     f.countryRegistration === registrationActive.countryRegistration
    // )?.errors

    // const fields = []
    // if (fields.length && setError) {
    //   for (const field of fields) {
    //     setError(field as string, { type: 'manual' })
    //   }
    // } else {
    //   return fields
    // }
    return []
  }

  function fnResolve(path: string, obj: any) {
    return path.split('.').reduce(
      (prev: { [x: string]: any }, curr: string | number) =>
        prev ? prev[curr] : null,
      // eslint-disable-next-line no-restricted-globals
      obj || self
    )
  }

  function getDisabled(field: string) {
    if (verified) {
      return true
    }
    if (
      Object.keys(errors).length &&
      // @ts-ignore
      ['verify', 'not-correct'].includes(subObj?.status as string)
    ) {
      const findField = fnResolve(field, errors)
      return !findField
    }
    return false
  }

  function getFieldProps(field: string) {
    const findField = fnResolve(field, errors)
    return {
      isError: Boolean(findField),
      isDisabled: true,
    }
  }

  return { getErrors, getFieldProps }
}

export default useStepsError
