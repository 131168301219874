/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'

import {Job, Request, updateJob} from '@viswals/services'
import {Button, Input, Select, SelectedOptionsProps, Toggle,} from '@viswals/components'
import useDatas from '@src/hooks/useDatas'

import {StyledContentJob} from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'

export default function FormUpdateJob() {
    const navigate = useNavigate()

    const {occupations} = useDatas()

    const [isError, setIsError] = useState<string>('')

    const {watch, register, handleSubmit, reset, control} = useForm()

    useEffect(() => {
        ;(async () => {
            const getOccupationToSave: Job = JSON.parse(
                window.localStorage.getItem('jobEdit') as string
            )
            reset(getOccupationToSave)
        })()
    }, [])

    async function onSubmit(data: any) {
        const dataToSend: Job = {
            name: data.name,
            occupationUuid: data.occupationUuid,
            uuid: watch('uuid'),
            enabled: data.enabled,
        }

        try {
            setIsError('')
            await updateJob(dataToSend as Request<Job>)
            window.localStorage.removeItem('jobEdit')
            navigate('/administration/common/job')
        } catch (error: any) {
            setIsError(error.message)
        }
    }

    return (
        <StyledContentJob className="styled-form">
            <form
                onSubmit={handleSubmit(onSubmit)}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
            >
                <div className="content-mini-form-box">
                    <HeaderContentCommon
                        name="Edit Job"
                        description="Here you can edit job"
                    />
                    <Input label="Name" formRegister={{...register('name')}}/>

                    <Select
                        label="Occupation"
                        labelTitle="Occupations"
                        options={
                            occupations.map((value) => ({
                                label: value.name,
                                value: value.uuid,
                            })) as SelectedOptionsProps[]
                        }
                        formRegister={{...register('occupationUuid'), watch}}
                    />
                    <div className="content-toggle-switch">
                        <Toggle isBig name="enabled" control={control}/>
                        <div className="box-preview">Enable Job</div>
                    </div>
                    <Button label="Save" type="submit"/>
                    <div
                        className="content-button-cancell"
                        aria-hidden
                        onClick={() => navigate('/administration/common/job')}
                    >
                        Cancel
                    </div>
                    <div className="boxError">
                        {isError.length > 0 ? `${isError}` : ''}
                    </div>
                </div>
            </form>
        </StyledContentJob>
    )
}
