/* eslint-disable @typescript-eslint/no-explicit-any */

import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {Button, Input, Select, SelectedOptionsProps} from '@viswals/components'
import {createDrug, Drug, Request} from '@viswals/services'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import {StyledContentDrug} from './styles'

export default function FormCreateDrug() {
    const navigate = useNavigate()

    const [isError, setIsError] = useState<string>('')

    const {watch, register, handleSubmit, setValue} = useForm()

    const listGroups = [
        {label: 'Most Common', value: 'MOST'},
        {label: 'Less Common', value: 'LESS'}] as SelectedOptionsProps[];

    async function onSubmit(data: any) {
        const dataToSend: Drug = {
            name: data.name,
            groupName: data.groupName,
            enabled: true,
        }

        try {
            setIsError('')
            await createDrug(dataToSend as Request<Drug>)
            navigate('/administration/common/drug')
        } catch (error: any) {
            setIsError(error.message)
        }
    }

    return (
        <StyledContentDrug className="styled-form">
            <form
                onSubmit={handleSubmit(onSubmit)}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
            >
                <div className="content-mini-form-box">
                    <HeaderContentCommon
                        name="Add new Drug"
                        description="Here you can register on new drug"
                    />

                    <Input label="Name" formRegister={{...register('name')}}/>

                    <Select
                        label="Group"
                        labelTitle="Groups"
                        options={
                            listGroups.map((value) => ({
                                label: value.label,
                                value: value.value,
                            })) as SelectedOptionsProps[]
                        }
                        formRegister={{...register('groupName'), watch}}
                    />

                    <Button label="Create" type="submit"/>

                    <div
                        className="content-button-cancell"
                        aria-hidden
                        onClick={() => navigate('/administration/common/drug')}
                    >
                        Cancel
                    </div>
                    <div className="boxError">
                        {isError.length > 0 ? `${isError}` : ''}
                    </div>
                </div>
            </form>
        </StyledContentDrug>
    )
}
