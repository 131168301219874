import useDatas from '@src/hooks/useDatas'
import { LoadingTable } from '@src/pages/administration/common/_components/loading-table'
import { Country, Patient, retrievePatient } from '@viswals/services'
import React from 'react'
import ReactDOMServer from 'react-dom/server'
import DetailMapItem from '../components/DetailMapitem'
import { LoadingBox } from './style'

const Marker = (options: google.maps.MarkerOptions) => {
  const { getCountryByUuid } = useDatas()
  const [marker, setMarker] = React.useState<google.maps.Marker>()
  const infoWindow = new google.maps.InfoWindow({
    pixelOffset: new google.maps.Size(180, 180),
  })
  // fix

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker())
    }

    return () => {
      if (marker) {
        marker.setMap(null)
      }
    }
  }, [marker])

  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options)
    }
  }, [marker, options])

  React.useEffect(() => {
    if (marker) {
      marker.addListener('click', async () => {
        infoWindow.close()
        const markerCurrent = marker.getIcon()
        const { dataSet, urlActive, scaledSize } = markerCurrent as any
        const markerActive = {
          url: urlActive,
          scaledSize,
        }

        marker.setClickable(false)
        marker.setIcon(markerActive)
        marker.set
        infoWindow.setContent(
          ReactDOMServer.renderToString(
            <LoadingBox>
              <LoadingTable />
            </LoadingBox>
          )
        )
        const { patientUuid, createdAt } = dataSet
        infoWindow.open(marker.getMap(), marker)
        getPatient(patientUuid, createdAt)
        infoWindow.addListener('closeclick', () => {
          marker.setClickable(true)
          marker.setIcon(markerCurrent)
          infoWindow.unbindAll()
        })
        infoWindow.unbindAll()
      })
      return () => {
        infoWindow.unbindAll()
        marker.unbindAll()
      }
    }
  }, [marker])

  async function getPatient(uuid: string, createdAt: string) {
    const request = {
      fields: [
        'uuid',
        'email',
        'accountNumber',
        'firstName',
        'lastName',
        'countryUuid',
      ],
      filters: { uuid, createdAt },
      relations: {
        status: {
          fields: ['state', 'createdAt'],
        },
      },
    }
    const {
      data: { data },
    } = await retrievePatient(request)
    const patient: Patient = JSON.parse(JSON.stringify(data[0]))

    const country = (await getCountryByUuid(
      patient.countryUuid as string
    )) as Country

    infoWindow.setContent(
      ReactDOMServer.renderToString(
        <DetailMapItem patient={patient} country={country} />
      )
    )
  }

  return null
}

export default Marker
