import ContainerItemListHeader from './styles'

export default function ItemListHeader() {
  return (
    <ContainerItemListHeader>
      <div className="item-header-list first">Log Platform</div>
      <div className="item-header-list date">Log Date</div>
      <div className="item-header-list time">Log Time</div>
      <div className="item-header-list country">Country Of Registration</div>
      <div className="item-header-list name">Account Name</div>
      <div className="item-header-list email">Account Email</div>
      <div className="item-header-list global">Global ID Number</div>
      <div className="item-header-list global">App Account Number</div>
      <div className="item-header-list global">Clinician Account Number</div>
      <div className="item-header-list global">Clinic Account Number</div>
      <div className="item-header-list buttons">Status</div>
      <div className="item-header-list buttons">Client Page</div>
    </ContainerItemListHeader>
  )
}
