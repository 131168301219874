/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReducersProps } from '@src/types/reducers'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'

import {
  Request,
  retrieveTypeRegistration,
  TypeRegistration,
  TypeRegistrationFilter,
  updateTypeRegistration,
} from '@viswals/services'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useStepsError from '@src/hooks/useStepsError'
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'

export type ListTypesProps = {
  label: string
  value: TypeRegistration
}

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, setLoading } = useLoading()
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm()
  const { getErrors, getFieldProps } = useStepsError(errors)
  const [initialData, setInitialData] = useState<TypeRegistration>()
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && !loading && isReady) {
      if (!confirm) {
        navigate(nextRoute)
      } else {
        handleSubmit(onSubmit)()
      }
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change' && !confirm) {
        setValue('verified', true)
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  async function getData() {
    setLoading(true)
    try {
      const request: Request<TypeRegistrationFilter> = {
        fields: ['uuid', 'registration', 'verified'],
        filters: {
          countryRegistration: registrationActive.country.uuid,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }

      const resp = await retrieveTypeRegistration(request)
      const data = resp.data.data[0]
      setInitialData(data)
      setTimeout(() => {
        reset({
          uuid: registrationActive.clinicianRegisterUuid,
          registration: data.registration,
          verified: data?.verified,
        })
      }, 200)
    } finally {
      setIsReady(true)
      setLoading(false)
      dispatch(setNavigationActiveNext(true))
    }
  }

  const professionalRegistration = [
    { label: 'Dental Clinician', value: 'DENTAL_CLINICIAN' },
    { label: 'Dental Hygienist', value: 'DENTAL_HYGIENIST' },
    { label: 'Dental Therapist', value: 'DENTAL_THERAPIST' },
    {
      label: 'Dental Hygienist & Therapist',
      value: 'DENTAL_HYGIENIST_THERAPIST',
    },
  ]

  async function onSubmit(data: any) {
    data = {
      ...data,
      countryRegistration: registrationActive.country.uuid,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
    }
    setLoading(true)
    try {
      const request = {
        ...data,
      }
      await updateTypeRegistration(request as Request<TypeRegistration>)
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setLoading(false)
    }
  }

  return {
    handleSubmit,
    onSubmit,
    register,
    professionalRegistration,
    verified,
    watch,
    initialData,
    getFieldProps,
  }
}

export default useLogic
