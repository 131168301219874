import useDiary from '@src/hooks/useDiary'
import useLayout from '@src/hooks/useLayout'
import Config from '@src/services/api/config'
import { retrievePatientCounter } from '@viswals/services'
import moment from 'moment'
import { useEffect, useState } from 'react'
import axios from 'axios'

const useLogic = () => {
  const { diary }: any = useDiary()
  const { layout, setLayout } = useLayout()
  const [totalUser, setTotalUser] = useState<any>({})

  useEffect(() => {
    getPatients()
  }, [diary?.diary?.selectedAgentData, diary.landingOn, diary.upperCalendarStartSelect])

  async function getPatients() {

    const referralCode = diary?.diary?.selectedAgentData?.referral_code

    let url = '/pre-register/v2/get-counts', hasDateRange = diary.landingOn !== "AS_LEFT" && diary.upperCalendarStartSelect && diary.upperCalendarEndSelect
    if (hasDateRange)
      url = `${url}?from=${diary.upperCalendarStartSelect}&to=${diary.upperCalendarEndSelect}`;

    if (referralCode)
      url = `${url}${hasDateRange ? '&' : '?'}referral-code=${referralCode}`

    const allData = await axios.get(Config.REACT_APP_GATEWAY_URL + url, { headers: { 'Authorization': "Bearer 21c24623-0daa-452a-a7d4-156b5702a962" } })
    if (allData.status === 200 && allData.data) {
      setTotalUser({ ...allData.data })
    }
  }

  const platforms = [
    { label: 'app', key: 'current_regular', keyPercent: 'regular_percent' },
    { label: 'PRO', key: 'current_professional', keyPercent: 'business_percent' },
    { label: 'BUSINESS', key: 'current_business', keyPercent: 'professional_percent' },
    { label: 'INvESTOR', key: 'current_investor', keyPercent: 'investor_percent' },
    { label: 'By Interest', key: 'current_total', keyPercent: 'total_percent' },
    { label: 'By Logs ', key: 'current_total_unique', keyPercent: 'total_unique_percent' },
  ]

  const types = [
    {
      forceShow: true,
      key: 'total',
      keyPercent: 'totalPercent',
      description: 'total',
      fontColor: '#AEAEB2',
      backgroundColor: '#2C2C2E25',
      bold: true,
    },
  ]

  function getAmount(key: string): number {
    return totalUser ? totalUser[key] ?? 0 : 0
  }

  const getTotalFontcolor = (type: string) => {
    switch (type) {
      case 'app':
        return '#2C7DFF'
      case 'PRO':
        return '#36BA91'
      case 'BUSINESS':
        return '#B665D5'
      case 'INvESTOR':
        return '#FF7C7C'
      default:
        return '#AEAEB2'
    }
  }

  const totalsPlatforms = platforms.map((platform) => ({
    title: platform.label,
    items: types.map((type) => ({
      key: type.key,
      keyPercent: type.keyPercent,
      forceShow: type.forceShow,
      description: type.description,
      amount: getAmount(platform.key),
      percentage: getAmount(platform.keyPercent),
      backgroundColor: type.backgroundColor,
      fontColor: getTotalFontcolor(platform.label)
    })),
  }))

  const totals = [
    ...totalsPlatforms,
  ]

  return { totals, layout, setLayout }
}

export default useLogic
