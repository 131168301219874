import createArrayOfYears from '@src/core/createArrayOfYears'
import { DentistryAward } from '@viswals/services'

const mockDataDentistry: DentistryAward[] = [
  {
    uuid: '0',
    country: 'ca198d17-82fe-4af1-b67b-d794dd4f27ee',
    officialName: 'Offical Name Here',
    year: '2001',
    nominee: 1,
    awardCategory: 'Some Category',
    awardResult: 1,
    // @ts-ignore
    awardResultText: 'Other',
    uploadCertificate: 'data',
    uploadCertificateName: 'name.jpg',
  },
]

const dataNominee = [
  {
    value: 1,
    label: 'Individual Nomination',
  },
  {
    value: 2,
    label: 'Team Nomination',
  },
]

const dataResultAward = [
  {
    value: 1,
    label: 'Award',
  },
  {
    value: 2,
    label: 'Highly Commended',
  },
  {
    value: 3,
    label: 'Nomination',
  },
]

const yearSelect = createArrayOfYears({
  startYear: 1960,
  finishYear: 2022,
})
  .map((d) => ({
    label: d.toString(),
    value: d,
  }))
  .reverse()
export { mockDataDentistry, yearSelect, dataNominee, dataResultAward }
