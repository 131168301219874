import React from 'react'
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import CoursesCap from '@images/capStudy.svg'
import { Input, Select, SelectedOptionsProps } from '@viswals/components'
import { ContainerWithWhomCourses, ContentMiniForm } from './styles'
import sortFieldsOfDentistry from './sorteFields'

type WhatCoursesProps = {
  watch: UseFormWatch<FieldValues>
  register: UseFormRegister<FieldValues>
  keyIndex?: number
  fieldsOfDentistry: SelectedOptionsProps[]
  type?: 'major' | 'short'
  setValue: UseFormSetValue<FieldValues>
}

const WhatCourses = ({
  type,
  keyIndex,
  watch,
  register,
  setValue,
  fieldsOfDentistry,
}: WhatCoursesProps) => {
  return (
    <ContainerWithWhomCourses>
      <h3>What Course</h3>
      <img src={CoursesCap} alt="CoursesCap" />
      <p>Field of Dentistry</p>
      <div className="vw-regis-safeArea">
        {sortFieldsOfDentistry(fieldsOfDentistry)
          .slice(0, 3)
          .map((fieldOfDentistry, _index) => {
            return (
              <label
                key={_index}
                className={`vw-form vw-regis-safeAreaItems ${
                  fieldOfDentistry.value ===
                  watch(`data.${keyIndex}.fieldDentistry`)
                    ? 'checked'
                    : ''
                }`}
              >
                {fieldOfDentistry.label}
                <input
                  type="radio"
                  value={fieldOfDentistry.value}
                  {...register(`data.${keyIndex}.fieldDentistry`, {
                    onChange: () => {
                      setValue(
                        `data.${keyIndex}.fieldDentistrySelect`,
                        undefined
                      )
                    },
                  })}
                />
              </label>
            )
          })}

        <ContentMiniForm
          style={{ marginTop: '1rem' }}
          className={`vw-contentMiniForm-select ${
            watch(`data.${keyIndex}.fieldDentistrySelect`) === undefined
              ? ''
              : 'fill'
          }`}
        >
          <Select
            labelTitle="Other"
            placeholder="Other"
            options={fieldsOfDentistry.slice(4, fieldsOfDentistry.length)}
            placement="bottom"
            formRegister={{
              ...register(`data.${keyIndex}.fieldDentistrySelect`, {
                onChange: () => {
                  setValue(`data.${keyIndex}.fieldDentistry`, undefined)
                },
              }),
              watch,
            }}
            isDisabled
          />
        </ContentMiniForm>
      </div>
      <div className="paragraphFirst">
        What’s your {type === 'major' ? 'Major' : 'Short'} Course?
      </div>
      <ContentMiniForm className="vw-contentMiniForm-input">
        <Input
          placeholder="Type Course Name Here"
          formRegister={{
            ...register(
              `data.${keyIndex}.${
                type === 'major' ? 'majorCourse' : 'shortCourse'
              }`,
              { required: true }
            ),
          }}
        />
      </ContentMiniForm>
    </ContainerWithWhomCourses>
  )
}

export default WhatCourses
