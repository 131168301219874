import { ActionProps } from './props'

const INITIAL_STATE = false

export default function reducer(state = INITIAL_STATE, action: ActionProps) {
  if (action.type === 'SET_LOADING') {
    return action.loading
  }
  return state
}
