import iconUserAdministrator from '@images/userAdministrator.svg'
import { ModalPhotoBox } from './style'

type ModalPhotoProps = {
  photo?: string
}

const ModalPhoto = ({ photo }: ModalPhotoProps) => {
  const hasPhoto = !!photo
  return (
    <div className="info">
      <h4 className="info-title">Professional Photo</h4>

      <ModalPhotoBox
        color={hasPhoto ? '#80DB7C' : '#8E8E93'}
        hasPhoto={hasPhoto}
      >
        <div className="circle-box">
          <div className="line b1">
            <div className="line b2">
              <div className="line b3">
                <div className="line b4">
                  <div className="containerInfo">
                    <img
                      src={
                        photo
                          ? `data:image/png;base64,${photo}`
                          : iconUserAdministrator
                      }
                      alt="User Administrator"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalPhotoBox>
    </div>
  )
}

export default ModalPhoto
