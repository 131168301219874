import isURL from '@src/core/isUrl'
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'

import {
  Request,
  retrieveSocialMedia,
  saveSocialMedia,
  SocialMedia,
  SocialMediaFilter,
} from '@viswals/services'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormSocialMedia, mobileRedesProps } from './props'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isModalOpen, setIsModalOPen] = useState<mobileRedesProps | null>()
  const [mobileActive, setMobileActive] = useState<mobileRedesProps | null>(
    null
  )
  const [showInput, setShowInput] = useState<number | null>(null)
  const safeArea = useRef<Array<HTMLDivElement | null>>([])
  const socialRef = useRef<HTMLDivElement | null>(null)
  const btbRef = useRef<HTMLDivElement | null>(null)
  const { loading, setLoading } = useLoading()

  const { subStepDone, nextRoute } = useClinicianRegister()
  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const [isReady, setIsReady] = useState<boolean>(false)

  const { register, handleSubmit, reset, setFocus, watch, setValue } =
    useForm<FormSocialMedia>()

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (next && !loading && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showInput])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, info) => {
      if (info.type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleClickOutside(event: any) {
    const index = showInput as number
    if (index) {
      const isDiff = !(
        safeArea.current[index] &&
        safeArea.current[index]?.contains(event.target)
      )
      if (isDiff) {
        setShowInput(null)
      }
    }

    if (
      !socialRef.current?.contains(event.target as Node) &&
      !btbRef.current?.contains(event.target as Node)
    ) {
      setMobileActive(null)
      setIsModalOPen(null)
    }
  }

  async function getData() {
    setLoading(true)
    try {
      const request: Request<SocialMediaFilter> = {
        fields: ['uuid', 'clinicianRegisterUuid', 'socialMediaType', 'link'],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveSocialMedia(request)
      const data = resp?.data?.data ? resp?.data.data : []
      let updateInfo: FormSocialMedia
      if (data) {
        const newArray = mobileRedes.map((item) => {
          const dataFind = data?.find(
            (f: SocialMedia) => f.socialMediaType === item.socialMediaType
          )

          return {
            uuid: dataFind ? dataFind.uuid : null,
            socialMediaType: item.socialMediaType,
            link: dataFind ? dataFind.link : null,
          }
        })

        updateInfo = { data: newArray }
      }

      setTimeout(() => {
        reset(updateInfo)
        dispatch(setNavigationActiveNext(true))
      }, 200)
    } finally {
      setIsReady(true)
      setLoading(false)
    }
  }

  function handleMobileFocused(item: mobileRedesProps) {
    setMobileActive(item)
  }

  function handleSocialMedia(index: number) {
    setShowInput(index)
    setTimeout(() => {
      setFocus(`data.${index}.link`)
    }, 200)
  }

  async function onSubmit(data: FormSocialMedia) {
    const checkUrlInvalid = data.data.filter((f) => f.link && !isURL(f.link))
    if (checkUrlInvalid.length) {
      return
    }

    if (!confirm) {
      navigate(nextRoute)
      return false
    }

    const request = data.data
      ?.filter((f) => f.link || f.uuid)
      .map((item) => ({
        ...item,
        destroy: !!(item?.uuid && !item.link),
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      }))

    try {
      await saveSocialMedia(request as Request<SocialMedia>)
      subStepDone()
      navigate(nextRoute)
    } catch (e) {
      console.error(e)
    }
  }

  const mobileRedes: mobileRedesProps[] = [
    {
      uuid: '001',
      socialMediaType: 'FACEBOOK',
      name: 'Facebook',
      icon: 'Facebook',
      background: require('@images/mobileFacebook.svg').default,
      // valid: true,
    },
    {
      uuid: '002',
      socialMediaType: 'INSTAGRAM',
      name: 'Instagram',
      icon: 'Instagram',
      background: require('@images/mobileInstagram.svg').default,
      // error: true,
    },
    {
      uuid: '003',
      socialMediaType: 'LINKEDIN',
      name: 'Linkedin',
      icon: 'Linkedin',
      background: require('@images/mobileLinkedin.svg').default,
      // valid: true,
    },
    {
      uuid: '004',
      socialMediaType: 'YOUTUBE',
      name: 'Youtube',
      icon: 'Youtube',
      background: require('@images/mobileYoutube.svg').default,
      // valid: true,
      // delete: true,
    },
    {
      uuid: '005',
      socialMediaType: 'TWITTER',
      name: 'Twitter',
      icon: 'Twitter2',
      background: require('@images/mobileTwitter.svg').default,
      // valid: true,
      // delete: true,
    },
  ]

  function getPress(e: React.KeyboardEvent) {
    const { value } = e.target as HTMLInputElement
    if (isURL(value)) {
      if (['Enter', 'Escape'].includes(e.code)) {
        setShowInput(null)
      }
    }
  }

  function handleCancelSocialMediaModal() {
    setIsModalOPen(null)
    setMobileActive(null)
  }

  function handleRemoveSocialMediaModal(socialMediaType: string) {
    const index = mobileRedes.findIndex(
      (f) => f.socialMediaType === socialMediaType
    )
    setValue(`data.${index}.link`, '')
    setIsModalOPen(null)
    setMobileActive(null)
    if (!confirm) {
      dispatch(setNavigationConfirm(true))
    }
  }

  return {
    socialRef,
    btbRef,
    mobileActive,
    mobileRedes,
    handleMobileFocused,
    handleSocialMedia,
    showInput,
    safeArea,
    register,
    watch,
    getPress,
    isModalOpen,
    setIsModalOPen,
    handleCancelSocialMediaModal,
    handleRemoveSocialMediaModal,
  }
}

export default useLogic
