import Box2QuestionReased from '@images/box2QuestionReased.svg'
import { VerificationItemProps } from '../../type'
import { HeaderBox } from '../VerificationItems/style'
import { ContainerBox, HeaderBoxQuestion, ColorxBox, TextArea } from './style'

type QuestionPageProps = {
  itemQuestion: VerificationItemProps
  changeQuestionReased: (value: string) => void
}

const QuestionPage = ({
  itemQuestion,
  changeQuestionReased,
}: QuestionPageProps) => {
  return (
    <ContainerBox>
      <HeaderBox>
        <div className="col">
          <HeaderBoxQuestion>
            <img src={Box2QuestionReased} alt="Verification Box Icon" />
            <div>
              <h4>Questions raised</h4>
              <span>Tyspane any questions your may have below</span>
            </div>
          </HeaderBoxQuestion>
        </div>
        <div className="col">
          <div>COUNTEr</div>
          <div>00:00</div>
        </div>
      </HeaderBox>
      <ColorxBox>
        <div />
        <div />
        <div />
        <div />
      </ColorxBox>
      <TextArea
        value={itemQuestion.question}
        onChange={(e) => changeQuestionReased(e.target.value)}
        placeholder="Type any questions here"
      />
    </ContainerBox>
  )
}

export default QuestionPage
