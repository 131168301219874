import { setActionLayout } from '@src/store/reducers/layout/actions'
import { ItemProps, ValueProps } from '@src/store/reducers/layout/props'
import { ReducersProps } from '@src/types/reducers'
import { useDispatch, useSelector } from 'react-redux'

const useLayout = () => {
  const dispatch = useDispatch()
  const layout = useSelector((state: ReducersProps) => state.layout)

  function setLayout(item: ItemProps, value: ValueProps) {
    dispatch(setActionLayout(item, value))
  }

  return { layout, setLayout }
}

export default useLayout
