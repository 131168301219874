/* eslint-disable @typescript-eslint/no-explicit-any */
import moment, { Moment } from 'moment'
import { useForm } from 'react-hook-form'
import {
  retrievePracticing,
  createPracticing,
  updatePracticing,
  Request,
  Practicing,
  PracticingFilter,
} from '@viswals/services'
import { useEffect, useState } from 'react'
import { ReducersProps } from '@src/types/reducers'
import { useDispatch, useSelector } from 'react-redux'
import useLoading from '@src/hooks/useLoading'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import useStepsError from '@src/hooks/useStepsError'
import { useNavigate } from 'react-router-dom'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import createArrayOfYears from '@src/core/createArrayOfYears'
import useDatas from '@src/hooks/useDatas'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    register,
    reset,
    watch,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm()

  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { loading, setLoading } = useLoading()
  const { getErrors, getFieldProps } = useStepsError(errors)
  const [userBirthYear, setUserBirthYear] = useState<number>(0)

  const { getClinicianBirthDate } = useDatas()

  const [isReady, setIsReady] = useState<boolean>(false)

  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  const { clinicianBirthDate } = useSelector(
    (state: ReducersProps) => state.datas
  )

  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  const yearSelectAgo = createArrayOfYears({
    startYear: new Date().getFullYear() - (userBirthYear - 15),
    finishYear: 2022,
  })
    .map((d) => ({
      label: d.toString(),
      value: d,
    }))
    .reverse()

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const monthSelect = Array.from({ length: 12 }, (_, i) => i + 1).map((d) => ({
    label: months[d - 1],
    value: d,
  }))

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, info) => {
      if (info.type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  useEffect(() => {
    ;(async () => {
      const userAge = await getClinicianBirthDate(
        registrationActive.clinicianRegisterUuid as string
      )
      setUserBirthYear(userAge.diffYear)
    })()
  }, [clinicianBirthDate])

  async function getData() {
    setIsReady(false)
    setLoading(true)
    try {
      const request: Request<PracticingFilter> = {
        fields: [
          'uuid',
          'clinicianRegisterUuid',
          'countryRegistration',
          'currentlyPracticing',
          'currentModePracticing',
          'practiceStartDate',
          'practiceEndDate',
          'practicedBefore',
          'reason',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        },
      }
      const resp = await retrievePracticing(request)
      const data = resp?.data ? resp.data.data[0] : null
      let updateInfo = {}
      if (data) {
        updateInfo = {
          ...data,
          currentlyPracticing: data?.currentlyPracticing ? '1' : '0',
          startMonth: data?.practiceStartDate
            ? moment(new Date(data?.practiceStartDate)).format('M')
            : undefined,
          startYear: data?.practiceStartDate
            ? moment(new Date(data?.practiceStartDate)).format('YYYY')
            : undefined,
          endMonth: data?.practiceEndDate
            ? moment(new Date(data?.practiceEndDate)).format('M')
            : undefined,
          endYear: data?.practiceEndDate
            ? moment(new Date(data?.practiceEndDate)).format('YYYY')
            : undefined,

          practicedBefore: data?.practicedBefore ? '1' : '0',
          // currentModePracticing: data?.currentModePracticing,
          // practiceStartDate: data?.practiceStartDate,
          // practiceEndDate: data?.practiceEndDate,
          // reason: data?.reason,
        }
      }

      const resetData = {
        ...updateInfo,
        uuid: data?.uuid,
        countryRegistration: registrationActive?.countryRegistration,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      }

      setTimeout(() => {
        reset(resetData)
        setLoading(false)
        dispatch(setNavigationActiveNext(true))
      }, 200)
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  const validteDateDiff = ({
    start,
    end,
  }: {
    start: Moment
    end: Moment
  }): {
    ok: boolean
    diff: number
  } => {
    const diff = end.diff(start, 'days')
    if (diff < 0) {
      return {
        diff,
        ok: false,
      }
    }
    return {
      diff,
      ok: true,
    }
  }

  const practicedBefore = watch('practicedBefore')
  const currentlyPracticing = watch('currentlyPracticing')
  const currentModePracticing = watch('currentModePracticing')

  async function onSubmit(data: any) {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)

    const validteDateDiffer: {
      ok: boolean
      diff: number
    } = validteDateDiff({
      start: moment(`${data?.startYear}-${data?.startMonth}-1`, 'YYYY-MM-DD'),
      end: moment(`${data?.endYear}-${data?.endMonth}-1`, 'YYYY-MM-DD'),
    })

    if (!validteDateDiffer.ok && currentlyPracticing.toString() === '0') {
      setLoading(false)
      setError('practiceStartDate', {})
      setError('practiceEndDate', {})
      setError('startYear', {})
      setError('startMonth', {})
      setError('endYear', {})
      setError('endMonth', {})
      return false
    }

    delete data?.startMonth
    delete data?.startYear
    delete data?.endMonth
    delete data?.endYear

    data = {
      ...data,
      currentlyPracticing: Boolean(data?.currentlyPracticing === '1'),
      practicedBefore: Boolean(data?.practicedBefore === '1'),
      currentModePracticing:
        currentlyPracticing.toString() === '0'
          ? 'FULL_TIME'
          : data?.currentModePracticing,
      practiceStartDate:
        String(practicedBefore) === '0'
          ? '1970-01-01'
          : data?.practiceStartDate,
      practiceEndDate:
        currentlyPracticing.toString() === '1' || practicedBefore === '0'
          ? '1970-01-01'
          : data?.practiceEndDate,
      reason: data?.reason ?? 'NO_SPECIFIC',
      countryRegistration: registrationActive?.countryRegistration,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
    }

    if (String(practicedBefore) === '0') {
      delete data?.practicedBefore
      data = {
        ...data,
        practicedBefore: false,
      }
    }

    if (!data.uuid) {
      await createPracticing(data as Request<Practicing>)
    } else {
      await updatePracticing(data as Request<Practicing>)
    }
    subStepDone()
    navigate(nextRoute)
  }

  const startMonth = watch('startMonth')
  const startYear = watch('startYear')
  const endMonth = watch('endMonth')
  const endYear = watch('endYear')

  function setDate(name: string, month: string, year: string) {
    if (month && year) {
      const newDAte = moment(new Date(`${year}-${month}-01`)).format(
        'YYYY-MM-DD'
      )
      setValue(name, newDAte)
      clearErrors(name)
    }
  }

  useEffect(
    () => setDate('practiceStartDate', startMonth, startYear),
    [startMonth, startYear]
  )
  useEffect(
    () => setDate('practiceEndDate', endMonth, endYear),
    [endMonth, endYear]
  )

  return {
    monthSelect,
    verified,
    yearSelectAgo,
    register,
    handleSubmit,
    onSubmit,
    watch,
    currentlyPracticing,
    practicedBefore,
    currentModePracticing,
    getFieldProps,
    errors,
  }
}

export default useLogic
