import useLayout from '@src/hooks/useLayout'
import { Icon, UpperBar as VwUpperBar } from '@viswals/components-platform'

import { UpperBarLeftContent, IconWrapper } from './style'

export type UpperBarProps = {
  children?: React.ReactNode
}

const UpperBar = ({ children }: UpperBarProps) => {
  const { layout, setLayout } = useLayout()

  return (
    <VwUpperBar>
      <UpperBarLeftContent>
        <IconWrapper
          onClick={() => setLayout('openMainMenu', !layout.openMainMenu)}
        >
          <Icon name={layout.openMainMenu ? 'MenuOut' : 'Menu2'} />
        </IconWrapper>
      </UpperBarLeftContent>
      {children}
    </VwUpperBar>
  )
}

export default UpperBar
