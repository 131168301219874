/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import useLoading from '@src/hooks/useLoading'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import {
  Request,
  Country,
  saveWallet,
  Wallet,
  WalletFilter,
  retrieveWallet,
} from '@viswals/services'
import useStepsError from '@src/hooks/useStepsError'
import { useNavigate } from 'react-router-dom'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useDatas from '@src/hooks/useDatas'

export type localWallet = {
  countryOpen?: boolean
  colorPickerOpen?: boolean
} & Wallet

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { banks, getBank } = useDatas()

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    formState: { errors },
    getValues,
  } = useForm()

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'data',
  })

  const { loading, setLoading } = useLoading()
  const { getFieldProps, getErrors } = useStepsError(errors)
  const { subStepDone, verified, nextRoute } = useClinicianRegister()

  const [paginationActive, setPaginationActive] = useState(1)
  const [indexToDelete, setIndexToDelete] = useState<number[]>([])
  const [isModalOpen, setIsModelOpen] = useState<boolean>(false)
  const [destroyList, setDestroyList] = useState<any[]>([])
  const [isReady, setIsReady] = useState<boolean>(false)

  const safeArea = useRef<Array<HTMLDivElement | null>>([])
  const safeArea2 = useRef<Array<HTMLDivElement | null>>([])
  const safeArea3 = useRef<Array<HTMLDivElement | null>>([])
  const safeArea4 = useRef<Array<HTMLDivElement | null>>([])

  const FlipCardRef = useRef(null)

  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  const { confirm, next } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  const countries: Country[] = useSelector((state: ReducersProps) => {
    const filterCountries = state.datas.countries.filter((coun) => {
      const test =
        coun.name === 'Portugal' ||
        coun.name === 'France' ||
        coun.name === 'Germany' ||
        coun.name === 'Spain' ||
        coun.name === 'United Kingdom' ||
        coun.name === 'Italy'
      return test
    })
    return filterCountries
  })

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type, name }) => {
      const isChangeCountry = name?.split('.')[2] === 'country'
      const isChangeBank = name?.split('.')[2] === 'bank'
      if (isChangeCountry) {
        const countryUuid = watch(name)
        getBank(countryUuid)
        dispatch(setNavigationConfirm(true))

        update(name?.split('.')[1] as unknown as number, {
          ...watch(`data.${name?.split('.')[1]}`),
          bank: undefined,
          accountType: undefined,
          accountName: undefined,
          accountNumber: undefined,
          sortCode: undefined,
          color: undefined,
        })
      }
      if (isChangeBank) {
        update(name?.split('.')[1] as unknown as number, {
          ...watch(`data.${name?.split('.')[1]}`),
          accountType: undefined,
          accountName: undefined,
          accountNumber: undefined,
          sortCode: undefined,
        })
      }
      if (type === 'change') {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  const getData = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      const request: Request<WalletFilter> = {
        fields: [
          'uuid',
          'clinicianRegisterUuid',
          'country',
          'bank',
          'accountType',
          'accountName',
          'accountNumber',
          'sortCode',
          'color',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveWallet(request)
      const data: localWallet[] = resp?.data?.data ?? []

      if (data.length > 0) {
        const oldData: localWallet[] = []
        data.map((bank) => {
          getBank(bank.country as string)
          oldData.push({
            ...bank,
            destroy: false,
            countryOpen: false,
            colorPickerOpen: false,
          })
          return null
        })
        append(oldData)
      } else {
        const oneWallet: localWallet = {
          destroy: false,
          countryOpen: true,
          colorPickerOpen: false,
        }
        append(oneWallet)
      }
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  const onSubmit = async (data: any) => {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const dataPayload = data.data.map((card: localWallet) => {
        const newCard: localWallet = {
          ...card,
          countryOpen: undefined,
          colorPickerOpen: undefined,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        }
        return newCard
      })
      await saveWallet([
        ...destroyList,
        ...dataPayload,
      ] as Request<WalletFilter>)
      subStepDone()
      navigate(nextRoute)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleOpenCountries = (country?: string, indexCard?: number) => {
    if (country) {
      setValue(`data.${indexCard}.country`, country)
      setValue(
        `data.${indexCard}.countryOpen`,
        !watch(`data.${indexCard}.countryOpen`)
      )
    } else {
      setValue(
        `data.${indexCard}.countryOpen`,
        !watch(`data.${indexCard}.countryOpen`)
      )
    }
  }

  const handleAddCard = () => {
    const oneWallet = {
      destroy: false,
      countryOpen: true,
    }
    append(oneWallet)
  }
  const handleOpenModel = useCallback(() => {
    setIsModelOpen(true)
  }, [isModalOpen])

  const handleClickCard = useCallback(
    (event: unknown, index: number) => {
      if (document.getElementsByClassName('vw-select').length) {
        return false
      }
      const ifDiff1 =
        safeArea.current[index] &&
        // @ts-ignore
        !safeArea.current[index].contains(event.target as Node)
      const ifDiff2 =
        safeArea2.current[index] &&
        // @ts-ignore
        !safeArea2.current[index].contains(event.target as Node)
      const ifDiff3 =
        safeArea3.current[index] &&
        // @ts-ignore
        !safeArea3.current[index].contains(event.target as Node)
      const ifDiff4 =
        safeArea4.current[index] &&
        // @ts-ignore
        !safeArea4.current[index].contains(event.target as Node)
      if (
        (ifDiff1 && ifDiff2 && ifDiff3 === null && ifDiff4 === null) ||
        (ifDiff1 && ifDiff2 && ifDiff3 !== false && ifDiff4 !== false)
      ) {
        if (indexToDelete.includes(index)) {
          setIndexToDelete([])
          return null
        }
        handleSelectIndexToDelete(index)
      }
    },
    [indexToDelete]
  )

  const handleSelectIndexToDelete = (index: number) => {
    if (indexToDelete.includes(index)) {
      setIndexToDelete([])
      return null
    }
    setIndexToDelete((items) => [...items, index])
  }

  const handleConfirmModelDelete = useCallback(() => {
    for (const item of indexToDelete) {
      const uuid = getValues(`data.${item}.uuid`)
      const obj = {
        ...watch(`data.${item}`),
        destroy: true,
      }
      if (uuid) {
        setDestroyList((list) => [...list, obj])
      }
    }
    remove(indexToDelete)
    setIndexToDelete([])

    if (!confirm) {
      dispatch(setNavigationConfirm(true))
    }
    setTimeout(() => {
      setIsModelOpen(false)
    }, 200)
  }, [isModalOpen])

  const handleCancelModelDelete = useCallback(() => {
    setIndexToDelete([])
    setIsModelOpen(false)
  }, [isModalOpen])

  // Pagination Info
  const paginationTotal = 3
  const pagination = Math.ceil(fields.length / paginationTotal)
  const indexShowEnd = paginationActive * paginationTotal
  const indexShowStart = indexShowEnd - paginationTotal

  useLayoutEffect(() => {
    setPaginationActive(pagination)
  }, [pagination])

  const handleSelectColor = (indexCard: number, color: string) => {
    setValue(`data.${indexCard}.color`, color)
    setValue(`data.${indexCard}.colorPickerOpen`, false)
    dispatch(setNavigationConfirm(true))
  }

  return {
    fields,
    FlipCardRef,
    countries,
    errors,
    verified,
    indexToDelete,
    isModalOpen,
    safeArea,
    safeArea2,
    safeArea3,
    safeArea4,
    setPaginationActive,
    getFieldProps,
    watch,
    setValue,
    register,
    onSubmit,
    handleSubmit,
    handleAddCard,
    handleOpenModel,
    handleClickCard,
    handleSelectColor,
    handleOpenCountries,
    handleCancelModelDelete,
    handleConfirmModelDelete,
    pagination,
    paginationActive,
    indexShowStart,
    indexShowEnd,
    banks,
  }
}

export default useLogic
