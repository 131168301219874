import styled, { css } from 'styled-components'

export const HeaderMenuBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeaderMenuNav = styled.ul`
  display: flex;
  flex-direction: row;
  gap: 5.4rem;
  list-style: none;
  li {
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: bold;
    color: #adadb1;
    cursor: pointer;

    &.active {
      color: #5bdf6f;
    }
  }
`

export const HeaderMenuDetail = styled.div`
  content: ' ';
  display: block;
  border: 1px solid #80db7c;
  height: 1.8rem;
  width: 1.1rem;
  border-radius: 0.2rem;
`

export const GroupMenuBox = styled.div`
  width: 100%;

  .boxTitle {
    margin-top: 12.6rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    h4 {
      color: #aeaeb2;
      font-size: 1rem;
      font-family: 'Poppins';
      font-weight: bold;
    }
    .boxHighlight {
      height: 2.4rem;
      background-color: #5bdf6f09;
      padding: 0.8rem 0.6rem 0.7rem 1.2rem;
      border-radius: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      cursor: pointer;
      span {
        color: #5bdf6f;
        font-size: 0.9rem;
        font-family: 'Oxanium';
        font-weight: 700;
        line-height: 1.3rem;
        &:first-of-type {
          color: #d4f7da;
        }
      }
    }
  }
`

export const GroupMenuItemBox = styled.div`
  width: 100%;
  display: flex;
  padding: 2rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  .contentLeft {
    width: 100%;
    display: flex;

    .contentIcon {
      width: 1.6rem;
      height: 1.6rem;
      color: #8e8e93;
      margin-top: 0.3rem;
    }
    .contentLabel {
      padding-left: 2.4rem;
      font-size: 1rem;
      font-family: 'Poppins';
      font-weight: 700;
      color: #aeaeb2;
      margin-top: 0.3rem;
    }
  }
  .contentNotify {
    width: 6rem;
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.6rem;
    gap: 0.4rem;
    div {
      width: 2.8rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      background-color: #5bdf6f09;
      color: #5bdf6f;
      font-size: 1rem;
      font-family: 'Poppins';
      font-weight: 700;
      &:first-of-type {
        background-color: #f72a1e15;
        color: #f72a1e;
      }
    }
  }

  &:hover {
    background-color: #2c2c2e;
  }
  &.isActive {
    background-color: #1c1c1e;
  }
`

export const SuperAdministratorContentBox = styled.div`
  width: 100%;
  padding: 2rem;
  padding-top: 5rem;

  .header-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .button-action {
      width: 12.6rem;
      button {
        height: 3.4rem;
        width: 100%;
        background-color: #5bdf6f;
        border-radius: 0.5rem;
        outline: none;
        border: none;
        color: #ffffff;
        font-family: 'Poppins';
        font-size: 1rem;
        font-weight: bold;
      }
    }
  }

  .title {
    font-family: 'Poppins';
    font-size: 1.2rem;
    font-weight: bold;
    color: #5bdf6f;
  }
  .subTitle {
    margin-top: 0.7rem;
    font-family: 'Poppins';
    font-size: 1rem;
    font-weight: normal;
    color: #7c7c80;
  }
`

export const TableBox = styled.table`
  width: 100%;
  font-family: 'Oxanium';
  font-size: 1rem;
  border-collapse: separate;
  border-spacing: 0rem 0.5rem;

  thead {
    tr {
      height: 5.6rem;
      th {
        &:nth-child(1) {
          width: 3.2rem;
        }
        &:last-child {
          width: 4rem;
        }

        padding: 0 1rem;
        color: #545456;
        text-align: left;
      }
    }
  }

  tbody {
    tr {
      height: 5.6rem;
      background-color: #2c2c2e;
      -webkit-box-shadow: 0px 2px 5px 0px #00000027;
      -moz-box-shadow: 0px 2px 5px 0px #00000027;
      box-shadow: 0px 2px 5px 0px #00000027;

      &:hover {
        background-color: #2c2c2e90;
      }

      &.suspend {
        .containerInfo {
          border-color: #ffb23b !important;
        }
        td {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7) {
            opacity: 0.25;
          }
        }
      }

      td {
        padding: 0 1rem;
        vertical-align: center;

        &:first-child {
          border-top-left-radius: 0.5rem;
          border-bottom-left-radius: 0.5rem;
        }
        &:last-child {
          border-top-right-radius: 0.5rem;
          border-bottom-right-radius: 0.5rem;
        }

        &.action-view {
          text-align: center;
          cursor: pointer;
          svg {
            width: 1.6rem;
            fill: #636366;
            color: #636366;
          }
        }
      }
    }
  }
`

type TableStatusProps = {
  color?: string
}

export const TableStatus = styled.div<TableStatusProps>`
  font-family: 'Oxanium';
  font-size: 1rem;
  font-weight: bold;
  width: 100%;
  height: 2.4rem;

  ${({ color }) => {
    if (color) {
      return css`
        color: ${color};
        background-color: ${color}15;
      `
    }
    return css`
      color: #7c7c80;
      background-color: #3a3a3c;
    `
  }}

  text-transform: capitalize;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
`
type TablePhotoBoxProps = {
  hasPhoto: boolean
}

export const TablePhotoBox = styled.div<TablePhotoBoxProps>`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 100%;

  .containerInfo {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3a3a3c;
    border-radius: 100%;
    border: 0.2rem solid #8e8e93;
    padding: ${({ hasPhoto }) => (hasPhoto ? 0 : '0.8rem')};

    figure {
      width: 100%;
      img {
        width: 100%;
      }
    }
  }
`
