/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Input,
  Select,
  SelectedOptionsProps,
} from '@viswals/components'
import { createJob, Job, Request } from '@viswals/services'
import useDatas from '@src/hooks/useDatas'

import HeaderContentCommon from '../_components/HeaderContentCommon'
import { StyledContentJob } from './styles'

export default function FormCreateJob() {
  const navigate = useNavigate()

  const { occupations } = useDatas()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit } = useForm()

  async function onSubmit(data: any) {
    const dataToSend: Job = {
      name: data.name,
      occupationUuid: data.occupationUuid,
      enabled: true,
    }

    try {
      setIsError('')
      await createJob(dataToSend as Request<Job>)
      navigate('/administration/common/job')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentJob className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Job"
            description="Here you can register on new job"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Select
            label="Occupation"
            labelTitle="Occupations"
            options={
              occupations.map((value) => ({
                label: value.name,
                value: value.uuid,
              })) as SelectedOptionsProps[]
            }
            formRegister={{ ...register('occupationUuid'), watch }}
          />

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/job')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentJob>
  )
}
