/* eslint-disable import/prefer-default-export */

import { LandingOnType } from '@viswals/services'

export const dairyFilterPanelOptions = [
  {
    id: 1,
    label: 'Diary existences',
    quantity: 0,
  },
  {
    id: 2,
    label: 'Type of appointment',
    quantity: 0,
  },
  {
    id: 3,
    label: 'Field of appointment',
    quantity: 0,
  },
  {
    id: 4,
    label: 'Nature of appointment',
    quantity: 0,
  },
  {
    id: 5,
    label: 'Category of appointment',
    quantity: 0,
  },
  {
    id: 6,
    label: 'Length of appointment',
    quantity: 0,
  },
  {
    id: 7,
    label: 'Appointment status',
    quantity: 0,
  },
  {
    id: 8,
    label: 'Patient status',
    quantity: 0,
  },
  {
    id: 9,
    label: 'Clinicians',
    quantity: 0,
  },
  {
    id: 10,
    label: 'Clinicians Status',
    quantity: 0,
  },
  {
    id: 11,
    label: 'Nurses',
    quantity: 0,
  },
]

export type upperBarLandingOnProps = {
  value: LandingOnType
  label: string
}

export const upperBarLandingOn: upperBarLandingOnProps[] = [
  {
    value: 'DAY',
    label: 'Day',
  },
  {
    value: 'WEEK',
    label: 'Week',
  },
  {
    value: 'MONTH',
    label: 'Month',
  },
  {
    value: 'YEAR',
    label: 'Year',
  },
  {
    // TODO: validar enum para ALL_TIME
    value: 'AS_LEFT',
    label: 'All time',
  },
]

export type DairyFilterPanelOptionsProps = {
  id: number
  label: string
  quantity: number
}

export const whoIsInTooltip = {
  placement: 'bottom',
  trigger: 'hover',
  destroyTooltipOnHide: true,
  overlayClassName: 'rc-tooltip-whoisin',
  overlay: (
    <div style={{ background: '#2c2c2e', width: '20rem', height: '30rem' }}>
      Who's In Tooltip
    </div>
  ),
}
