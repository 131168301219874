import { ActionProps } from '../reducers/navigation/props'

export function setNavigationReset(): ActionProps {
  return {
    type: 'SET_NAVIGATION_RESET',
  }
}

export function setNavigationNext(): ActionProps {
  return {
    type: 'SET_NAVIGATION_NEXT',
    next: Date.now(),
  }
}

export function setNavigationLoading(loading: boolean): ActionProps {
  return {
    type: 'SET_NAVIGATION_LOADING',
    loading,
  }
}

export function setNavigationActivePrev(activePrev: boolean): ActionProps {
  return {
    type: 'SET_NAVIGATION_ACTIVE_PREV',
    activePrev,
  }
}

export function setNavigationActiveNext(activeNext: boolean): ActionProps {
  return {
    type: 'SET_NAVIGATION_ACTIVE_NEXT',
    activeNext,
  }
}

export function setNavigationConfirm(confirm: boolean): ActionProps {
  return {
    type: 'SET_NAVIGATION_CONFIRM',
    confirm,
  }
}

export function setNavigationSkip(skip: boolean): ActionProps {
  return {
    type: 'SET_NAVIGATION_SKIP',
    skip,
  }
}
