import * as React from "react";

const HeartIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={13}
        height={12}
        viewBox="0 0 13 12"
        {...props}
    >
        <path
            d="m6.449 11.134-.182-.107a19.338 19.338 0 0 1-3.912-3.145A6.012 6.012 0 0 1 .5 4.183v-.1c0-.052 0-.109.007-.175A3.292 3.292 0 0 1 3.873.866c.078 0 .151 0 .222.006a3.684 3.684 0 0 1 2.42 1.294A3.681 3.681 0 0 1 8.953.907h.164A3.287 3.287 0 0 1 12.5 3.993c.087 1.633-1.046 3.036-1.905 3.95a19.325 19.325 0 0 1-3.956 3.087l-.184.1ZM5.577 5.61l.56 2.493a.432.432 0 0 0 .4.334h.023a.425.425 0 0 0 .407-.3l.8-2.487.2.761a.429.429 0 0 0 .341.313.4.4 0 0 0 .073.006.429.429 0 0 0 .353-.185l.362-.526h1.282a.428.428 0 0 0 .428-.428.43.43 0 0 0-.428-.428H8.867a.431.431 0 0 0-.31.132l-.334-1.274a.429.429 0 0 0-.4-.32h-.014a.426.426 0 0 0-.407.3L6.63 6.39l-.561-2.5a.43.43 0 0 0-.4-.334h-.016a.426.426 0 0 0-.408.3l-.619 1.918-.145-.341a.426.426 0 0 0-.394-.261H2.621a.428.428 0 1 0 0 .856H3.8l.485 1.138a.426.426 0 0 0 .393.261H4.7a.431.431 0 0 0 .388-.3l.489-1.517Z"
            fill={props.color || "#4dafeb"}
            stroke="rgba(0,0,0,0)"
            strokeMiterlimit={10}
        />
    </svg>
);

export default HeartIcon;