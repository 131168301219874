import styled, { css } from 'styled-components'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const MapArea = styled.div`
  flex-grow: 1;
  width: 100%;
  height: 58.8rem;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  color: #1c1c1e;
  font-size: 24rem;
  font-weight: bold;

  background: #1c1c1e;
  border-radius: 5px;
  overflow: hidden;
`

export const TopLabel = styled.div`
  width: auto;
  height: 3.4rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
  position: absolute;
  top: 2rem;
  left: 2rem;
  background: #151517;
  border-radius: 5px;
  font-family: Nebula;
  font-size: 1rem;
  color: #aeaeb2;
  font-weight: bold;

  span {
    color: #32d74b;
  }
  z-index: 10;
`
export const ExpandedBox = styled.div`
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 14px;

  figure {
    height: 2.4rem;
    width: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0.3rem;

    &:hover {
      background-color: #1c1c1e;
    }

    img {
      max-height: 1.4rem;
    }
  }
`

type TotalsGroupProps = {
  openMapTotalExpanded?: boolean;
  focus?: any
  show?: any
  agent?: boolean;
  active?: boolean;
}

export const TotalsGroup = styled.div<TotalsGroupProps>`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #0c0c0d;
  border: 1px solid #363639;
  border-radius: 1rem;
  ${({ openMapTotalExpanded }) =>
    !openMapTotalExpanded &&
    css`
      .item-secondary {
        display: none;
      }
    `}
`

export const CalendarAction = styled.div<TotalsGroupProps>`
  cursor: pointer;
  padding: 0.7rem 1rem;
  background-color: #0c0c0d;
  border-radius: 0.5rem;
  margin-right: ${({ agent }) => agent ? 1 : 2}rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  svg {
    height: 1rem;
  }
  span {
    font-size: 1rem;
    font-family: Oxanium;
  }

  &.active {
    svg,
    span {
      color: #32d74b;
    }
    background-color: #32d74b25;
  }
`

export const ContainerUpperCalendarChildren = styled.div`
  width: 100%;
  height: 4rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .leftBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1.5rem;

    margin-bottom: 0.4rem;
    font-family: var(--font-third);
    font-size: 1.4rem;
    color: #5bdf6f;
  }
  .rightBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .vw-expandedSearchBar-containerArea {
      width: 10.5rem;
      margin-left: 7.3rem;
    }
  }
`

export const TBProfileIconSection = styled.div<TotalsGroupProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ focus }) => focus ? '#FF813E' : 'transparent'};
    width: 24px;
    height: 24px;
    border-radius: 50%;
`;

export const TBProfileIconContainer = styled.div<TotalsGroupProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ focus }) => focus ? `#FF813E45` : 'transparent'};
    background-color: ${({ focus }) => focus ? `#FF813E25` : 'transparent'};
    width: 28px;
    height: 28px;
    border-radius: 50%;
`;

export const ProfileIcon = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => "#AEAEB2"};
`;


export const CalenderUpperWrapper = styled.div<TotalsGroupProps>`
    height:${({ show }) => show ? '126px' : "0px"};
    opacity: ${({ show }) => show ? '1' : "0"};
    height: ${({ show }) => !show && "0px"};
    overflow: hidden;
    transition: all 0.3s ease-in-out;
`;

export const AgentSearchContainer = styled.div<TotalsGroupProps>`
    width: 300px;
    margin-top: 20px;
    background-color: #0C0C0D;
    border-radius: 15px;
    border: 1px solid #48484A;
    box-shadow: 0 5px 10px 0 #000000;
    padding: 10px;
    z-index: 9;
    position: relative;
`;

export const AgentSearchField = styled.div`
    flex-shirnk: 0;
    height: 32px;
    width: 100%;
    padding: 10px;
    gap: 12px;
    display: flex;
    align-items: center;
    background-color: #151517;
    border-radius: 5px;
`;

export const AgentSearchFieldInput = styled.input`
    width: 100%;
    border: none;
    outline: none;
    font-size: 9px;
    font-family: Oxanium;
    font-weight: normal;
    color: #7C7C80;
    &::placeholder{
      color: #444446;
    }
    background-color: transparent;
`;

export const AgentTable = styled.div`
    padding: 20px 0 0;
`;

export const AgentTableHead = styled.div``;

export const AgentTableHeadRow = styled.div`
    flex-shrink: 0;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 22px;
`;

export const AgentTableHeadColCode = styled.div`
    font-size: 10px;
    font-family: Oxanium;
    font-weight: bold;
    color: #545456;
`;

export const AgentTableHeadColName = styled.div`
    font-size: 10px;
    font-family: Oxanium;
    font-weight: bold;
    color: #545456;
    width: 100%;
`;

export const AgentTablBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-height: 546px;
    overflow-y: auto;
    overflow-x: hidden;
    
    @media (max-width:1400px) {
        max-height: 446px;
    }
    &::-webkit-scrollbar {
        width: 0.1px;
    }
`;

export const AgentTablBodyRow = styled.div<TotalsGroupProps>`
    flex-shrink: 0;
    height: 24px;
    display: flex;
    align-items: center;
    padding: 10px;
    gap: 12px;
    background-color: ${({ active }) => active ? '#32d74b25' : "#151517"};
    color: ${({ active }) => active ? '#32d74b' : "#7C7C80"};
    width: 100%;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover{ 
      background-color: ${({ active }) => active ? '#32d74b35' : "#3A3A3C"};
    }
`;

export const AgentTablBodyColCode = styled.div`
    font-size: 10px;
    font-family: Oxanium;
    font-weight: bold;
    margin-right: 10px;
`;

export const AgentTablBodyColName = styled.div`
    font-size: 10px;
    font-family: Oxanium;
    font-weight: normal;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;