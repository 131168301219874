import { Country, PatientStatus } from '@viswals/services'
import {
  NotificationItemMapBox,
  NotificationItemMapContent,
  NotificationItemMapHeader,
} from './style'

type NotificationItemMapProps = {
  patient: PatientStatus
  country?: Country
}

const NotificationItemMap = ({
  patient,
  country,
}: NotificationItemMapProps) => {
  return (
    <NotificationItemMapBox>
      <NotificationItemMapHeader>
        <figure>
          <img src={country?.icon} alt={country?.name} />
        </figure>
        <span>{`${country?.name}`}</span>
      </NotificationItemMapHeader>
      <NotificationItemMapContent>{`New ${patient.state}`}</NotificationItemMapContent>
    </NotificationItemMapBox>
  )
}

export default NotificationItemMap
