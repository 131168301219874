import { TooltipChildren } from '@viswals/components'
import Container from './TooltipMessageStyles'

const TooltipMessage = () => {
  return (
    <Container>
      <div>
        <TooltipChildren>
          <h2>Approved</h2>
          <p>Viswals has confirmed you as a Freelancer Clinician</p>
        </TooltipChildren>
      </div>
    </Container>
  )
}

export default TooltipMessage
