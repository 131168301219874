import { Icon } from '@viswals/components-platform'
import { Separator, ZoomControl, ZoomIconWrapper } from './style'

const MapZoom = ({ map }: { map?: google.maps.Map }) => {
  function changeZoom(zoom: number) {
    map?.setZoom(Number(map.getZoom()) + zoom)
  }

  return (
    <ZoomControl>
      <ZoomIconWrapper onClick={() => changeZoom(1)}>
        <Icon name="Add3" />
      </ZoomIconWrapper>

      <Separator margin="0px" />

      <ZoomIconWrapper onClick={() => changeZoom(-1)}>
        <Icon name="Remove1" />
      </ZoomIconWrapper>
    </ZoomControl>
  )
}

export default MapZoom
