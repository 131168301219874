import { useForm } from 'react-hook-form'
import { SelectedOptionsProps } from '@viswals/components'
import { ReducersProps } from '@src/types/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  createRegulatoryBody,
  RegistrationFilter,
  Request,
  retrieveRegistration,
  RegulatoryBody,
  updateRegistration,
  Registration,
} from '@viswals/services'

import {
  setNavigationActiveNext,
  setNavigationActivePrev,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import { useNavigate } from 'react-router-dom'
import useStepsError from '@src/hooks/useStepsError'
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { getRegulatoryBodies } from '@src/utils/getDatas'
import { FormSubmitProps } from './props'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { loading, setLoading } = useLoading()
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm()

  const { getErrors, getFieldProps } = useStepsError(errors)
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { countries, regulatoryBodies } = useSelector(
    (state: ReducersProps) => state.datas
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const newDataCountries: SelectedOptionsProps[] =
    countries?.map((item) => ({
      label: item.name as string,
      value: item.uuid as string,
    })) ?? []

  const newRegulatoryBodies: SelectedOptionsProps[] =
    regulatoryBodies?.map((item) => ({
      label: item.name as string,
      value: item.uuid as string,
    })) ?? []

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && !loading) {
      if (!confirm) {
        navigate(nextRoute)
      } else {
        handleSubmit(onSubmit)()
      }
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change' && !confirm) {
        setValue('verified', false)
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  async function getData() {
    setLoading(true)
    try {
      const request: Request<RegistrationFilter> = {
        fields: [
          'uuid',
          'clinicianRegisterUuid',
          'countryRegistration',
          'nationalBodyRegistration',
          'professionalLicenseNumber',
          'nameRegistrationForename',
          'nameRegistrationSurname',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.country?.uuid,
        },
      }
      const resp = await retrieveRegistration(request)
      const data = resp.data.data[0]

      setTimeout(() => {
        reset({
          countryRegistration: data.countryRegistration,
          nationalBodyRegistration: data.nationalBodyRegistration,
          professionalLicenseNumber: data.professionalLicenseNumber,
          nameRegistrationForename: data.nameRegistrationForename,
          nameRegistrationSurname: data.nameRegistrationSurname,
          newBody: null,
          uuid: data.uuid,
          verified: data.verified,
        })
      }, 200)
    } finally {
      setLoading(false)
      dispatch(setNavigationActiveNext(true))
      dispatch(setNavigationActivePrev(false))
    }
  }

  async function createRegulatory(name: string) {
    setLoading(true)
    try {
      const request: Request<RegulatoryBody> = {
        data: [
          {
            name,
            isoCode: registrationActive.country.isoCode as string,
          },
        ],
      }
      const resp = await createRegulatoryBody(request)
      await getRegulatoryBodies()
      setLoading(false)
      return resp.data.data[0]
    } catch (error) {
      setLoading(false)
      return ''
    }
  }

  async function onSubmit(data: FormSubmitProps) {
    data = {
      ...data,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
    }
    setLoading(true)
    try {
      if (data.newBody) {
        data = {
          ...data,
          nationalBodyRegistration: await createRegulatory(data.newBody),
        }
      }
      delete data.newBody
      await updateRegistration(data as Request<Registration>)
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setLoading(false)
    }
  }

  return {
    verified,
    errors,
    newDataCountries,
    newRegulatoryBodies,
    handleSubmit,
    onSubmit,
    register,
    watch,
    setValue,
    getFieldProps,
  }
}

export default useLogic
