import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

export const TotalWrapper = styled.div`
  width: 100%;
  min-height: 4rem;
  max-height: 4rem;
  display: flex;
  gap: 1rem;
`

export const PartialWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
`

export const PartialColumn = styled.div`
  width: 100%;
`

export const PriorityWrapper = styled.div`
  width: 100%;
  height: 87.9rem;
  display: flex;
  gap: 1rem;
`

export const TooltipContent = styled.span`
  color: #8e8e93;
  font-family: Oxanium;
  font-size: 0.9rem;
  white-space: nowrap;
  display: inline-block;
`
