/* eslint-disable @typescript-eslint/no-explicit-any */
import { Icon } from '@viswals/components'
import {
  Report,
  Feedback,
  Rate,
  FeedbackWhereType,
  ShareOurApp,
  Dropout,
  ShareOurAppType,
  ReasonDropoutType,
} from '@viswals/services'
import gsap from 'gsap'
import moment from 'moment'
import { PlatformType } from '../../data'
import { FixedPopUpReportUnusual, BoxPopUpReportUnusual } from './styles'
import { getLabelFeedBackType, getLabelFeedBackWhere } from '../../utils/utils'
import IconRate from '../IconRate'

export type AllComunicationType = Report &
  Feedback &
  Rate &
  ShareOurApp &
  Dropout

export default function PopUpReportUnusual({
  itemReport,
  title,
}: {
  itemReport: AllComunicationType
  title?: string
}) {
  function handleCloseModal(event: any) {
    if (
      event.target.id === 'popUpReportUnusual' ||
      event.target.id === 'buttonToClosePopUp'
    ) {
      const getQuery = window.document.getElementById('popUpReportUnusual')

      const toDisabled = window.document.getElementsByClassName('isSelected')

      for (const itemToDisabled of toDisabled) {
        itemToDisabled.classList.remove('isSelected')
      }

      gsap.to(getQuery, {
        opacity: 0,
        duration: 0.2,
        onComplete: () => {
          gsap.to(getQuery, {
            opacity: 0,
            duration: 0.1,
            zIndex: '-999',
          })
        },
      })

      const itemDelete =
        window.document.getElementById('popUpReportUnusual')?.dataset

      for (const itemToSet of Object.keys(itemDelete as object)) {
        // @ts-ignore
        getQuery?.removeAttribute(itemToSet)
      }
    }
  }

  const getLabelPlatform = (value?: PlatformType): string => {
    if (value === 'PATIENT') {
      return 'Viswals App'
    }
    if (value === 'CLINIC') {
      return 'Viswals Clinic'
    }
    return 'Viswals Clinician'
  }

  const getLabelShare = (share: ShareOurAppType) => {
    if (share === 'FRIEND') return 'Share with a Friend'
    if (share === 'CLINICIAN') return 'Share with a Clinician'
    return 'Share with a Friend'
  }

  const getReasonLabel = (value: ReasonDropoutType) => {
    if (value === 'NOT_USE') return 'I do not use the app'
    if (value === 'DISLIKE') return 'I dislike the product'
    if (value === 'WHAT_EXPECTED') return 'It wasn’t what I expected'
    if (value === 'OTHER') return 'Other'
    return undefined
  }

  return (
    <FixedPopUpReportUnusual
      id="popUpReportUnusual"
      aria-hidden
      onClick={(event) => handleCloseModal(event)}
    >
      <BoxPopUpReportUnusual>
        <div className="contentTitleHeader">
          <div className="titleHeader">{title ?? 'Report'}</div>
          <div id="buttonToClosePopUp" className="buttonClose">
            close
          </div>
        </div>
        <div className="contentLinePopUp">
          <div
            className={`linePopUp platform ${
              itemReport?.platform === 'PATIENT'
                ? 'app'
                : itemReport?.platform === 'CLINICIAN'
                ? 'clinician'
                : itemReport?.platform === 'CLINIC'
                ? 'clinic'
                : ''
            }`}
          >
            <div className="titleLinePopUp">Viswals Platform</div>
            <div className="valueLinePopUp">
              <div className="tbOneIconBox">
                <Icon name="Viswals3" />
              </div>
              <div className="labelTbOne">
                {getLabelPlatform(itemReport?.platform)}
              </div>
            </div>
          </div>
          <div className="linePopUp">
            <div className="titleLinePopUp">User</div>
            <div className="valueLinePopUp">{itemReport.accountUsername}</div>
          </div>
          <div className="linePopUp">
            <div className="titleLinePopUp">Date Incoming</div>
            <div className="valueLinePopUp">
              {moment(itemReport.createdAt).format('DD MMMM YYYY')}
            </div>
          </div>
          <div className="linePopUp">
            <div className="titleLinePopUp">Hour Incoming</div>
            <div className="valueLinePopUp">
              {moment(itemReport.createdAt).format('HH:mm a')}
            </div>
          </div>
          {itemReport.feedbackType !== undefined && (
            <>
              <div className="linePopUp">
                <div className="titleLinePopUp">Feedback Type</div>
                <div className="valueLinePopUp">
                  {getLabelFeedBackType(itemReport.feedbackType)}
                </div>
              </div>
              <div className="linePopUp">
                <div className="titleLinePopUp">Location</div>
                <div className="valueLinePopUp">
                  {getLabelFeedBackWhere(
                    itemReport.feedbackWhere as FeedbackWhereType
                  )}
                </div>
              </div>
            </>
          )}
          {itemReport.rate !== undefined && (
            <>
              <div className="linePopUp">
                <div className="titleLinePopUp">Rate</div>
                <div className="valueLinePopUp">
                  <IconRate itemReport={itemReport} />
                </div>
              </div>
            </>
          )}
        </div>
        {itemReport.rate === undefined && (
          <div className="contentMessageBox">
            <div className="titleMessageBox">Message</div>
            <div className="textMessageBox">
              {itemReport.message ??
                getReasonLabel(itemReport.reason as ReasonDropoutType) ??
                getLabelShare(itemReport.share as ShareOurAppType)}
            </div>
          </div>
        )}
      </BoxPopUpReportUnusual>
    </FixedPopUpReportUnusual>
  )
}
