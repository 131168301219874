import { useAlertModalProps } from '@src/hooks/useAlertModal'
import { useToastifyProps } from '@src/hooks/useToastify'
import {
  Diary,
  LandingOnType,
  Event,
  Notification,
  Shift,
} from '@viswals/services'
import { ActionProps } from './props'

export function setActionCustomizeDiary(diary: any): ActionProps {
  return {
    type: 'SET_DIARY',
    diary,
  }
}

export function setActionEventTemp(eventTemp?: Event): ActionProps {
  return {
    type: 'SET_DIARY_EVENT_TEMP',
    eventTemp,
  }
}

export function setActionEventCopy(eventCopy?: Event): ActionProps {
  return {
    type: 'SET_DIARY_EVENT_COPY',
    eventCopy,
  }
}

export function setActionResetTemp(): ActionProps {
  return {
    type: 'SET_DIARY_RESET_TEMP',
  }
}

export function setActionDiaryEvents(events: Event[]): ActionProps {
  return {
    type: 'SET_DIARY_EVENTS',
    events,
  }
}

export function setActionDiaryShifts(shifts: Shift[]): ActionProps {
  return {
    type: 'SET_DIARY_SHIFTS',
    shifts,
  }
}

export function setActionShiftTemp(shiftTemp?: Shift): ActionProps {
  return {
    type: 'SET_DIARY_SHIFT_TEMP',
    shiftTemp,
  }
}

export function setActionCustomizeDiaryTemp(diaryTemp?: Diary): ActionProps {
  return {
    type: 'SET_DIARY_TEMP',
    diaryTemp,
  }
}

export function setActionWebSocket(wsConnected?: boolean): ActionProps {
  return {
    type: 'SET_DIARY_WS',
    wsConnected,
  }
}

export function setActionOverlay(overlay?: boolean): ActionProps {
  return {
    type: 'SET_DIARY_OVERLAY',
    overlay,
  }
}

export function setActionLiveBoardNotifications(
  liveBoardNotifications: Notification[]
): ActionProps {
  return {
    type: 'SET_DIARY_LIVEBOARD',
    liveBoardNotifications,
  }
}

export function setActionCustomizeDiaryLandingOn(
  landingOn?: LandingOnType,
  upperCalendarStartSelect?: string,
  upperCalendarEndSelect?: string
): ActionProps {
  return {
    type: 'SET_DIARY_LANDING_ON',
    landingOn,
    upperCalendarStartSelect,
    upperCalendarEndSelect
  }
}

export function setActionDiaryUpperCalendarSelect(
  upperCalendarStartSelect?: string,
  upperCalendarEndSelect?: string
): ActionProps {
  return {
    type: 'SET_UPPERCALENDAR_SELECT',
    upperCalendarStartSelect,
    upperCalendarEndSelect,
  }
}

export function setActionDiaryInitDataEvent(
  day: string,
  hour: string
): ActionProps {
  return {
    type: 'SET_INIT_DATA_EVENT',
    initDataEvent: {
      day,
      hour,
    },
  }
}

export function setActionDiaryAlertModal(
  alertModal: useAlertModalProps
): ActionProps {
  return {
    type: 'SET_DIARY_ALERT_MODAL',
    alertModal,
  }
}

export function setActionDiaryAlertToastify(
  alertToastify: useToastifyProps
): ActionProps {
  return {
    type: 'SET_DIARY_ALERT_TOASTIFY',
    alertToastify,
  }
}
