import { ActionProps, DropoutStoreProps } from './props'

const INITIAL_STATE: DropoutStoreProps = {
  dropouts: [],
}

export default function reducer(
  state = INITIAL_STATE,
  action: ActionProps
): DropoutStoreProps {
  if (action.type === 'SET_DROPOUT') {
    return {
      ...state,
      dropouts: action.dropouts,
    }
  }

  return state
}
