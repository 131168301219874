import useDiary from '@src/hooks/useDiary'

import {
  PatientStatus,
} from '@viswals/services'
import { useEffect, useMemo, useState } from 'react'
import patientUrlMapStatus from './utils/status_patient'
import axios from 'axios'
import Config from '@src/services/api/config'

const useLogic = () => {
  const { diary } = useDiary()
  const [patients, setPatients] = useState<PatientStatus[]>([])
  const [totalCount, setTotalCount] = useState<any>({})

  const markers = useMemo(() => {
    return patients.map((ele: any) => {
      return {
        title: `${ele.forename} ${ele.surname}`,
        icon: {
          url: patientUrlMapStatus('SIGN_UP'),
          urlActive: patientUrlMapStatus('SIGN_UP', true),
          // scaledSize: new google.maps.Size(32, 32),
          dataSet: "",
        },
        position: { lat: ele.country.latitude, lng: ele.country.longitude },
      }
    })
  }, [patients])

  return { markers, totalCount }
}

export default useLogic
