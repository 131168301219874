/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Country, updateCountry, Request } from '@viswals/services'
import { Button, Input, Select, Upload, Toggle } from '@viswals/components'

import dataContinents from '@src/store/continents'

import { StyledContentContry } from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'

export default function FormUpdateCountry() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, setValue, register, handleSubmit, control, reset } = useForm()

  useEffect(() => {
    const getCountryToSave: Country = JSON.parse(
      window.localStorage.getItem('countryEdit') as string
    )
    reset({
      ...getCountryToSave,
      icon: {
        fileName: getCountryToSave.name,
        file: getCountryToSave.icon,
      },
      continent: `${getCountryToSave.continent}`,
    })
  }, [])

  async function onSubmit(data: any) {
    const dataToSend: Country = {
      name: data.name,
      isoCode: data.isoCode,
      icon: data.icon.file,
      countryCode: data.countryCode,
      continent: Number(data.continent),
      uuid: watch('uuid'),
      enabled: data.enabled,
      latitude: Number(data.latitude),
      longitude: Number(data.longitude),
    }

    try {
      setIsError('')
      await updateCountry(dataToSend as Request<Country>)
      window.localStorage.removeItem('countryEdit')
      navigate('/administration/common/country')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentContry className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Edit Country"
            description="Here you can edit country"
          />
          <Input
            label="Country Code"
            formRegister={{ ...register('countryCode') }}
          />
          <div className="content-icon-upload">
            <Upload
              label="Icon"
              placeholder="Upload Icon"
              formRegister={{ ...register('icon'), watch, setValue }}
            />
            <div className="box-preview">
              <span className="box-preview-title">Preview</span>
              <div className="box-preview-image">
                <img src={watch('ico.file')} alt="" />
              </div>
            </div>
          </div>
          <Input label="Name" formRegister={{ ...register('name') }} />
          <Input
            label="ISO Code"
            formRegister={{
              ...register('isoCode'),
            }}
          />
          <Select
            label="Continent"
            labelTitle="Continents"
            options={dataContinents.map((value) => ({
              label: value.name,
              value: value.uuid,
              icon: '',
              isoCode: '',
            }))}
            formRegister={{ ...register('continent'), watch }}
          />
          <div className="space-latitude" />
          <Input
            type="tel"
            label="Latitude"
            formRegister={{ ...register('latitude') }}
          />
          <Input
            type="tel"
            label="Longitude"
            formRegister={{ ...register('longitude') }}
          />
          <div className="content-toggle-switch">
            <Toggle isBig name="enabled" control={control} />
            <div className="box-preview">Enable Country</div>
          </div>
          <Button label="Save" type="submit" />
          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/country')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentContry>
  )
}
