import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateAllergy from './form_create'
import FormUpdateAllergy from './form_update'
import AllergyTable from './table'

export default function AllergyPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <AllergyTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateAllergy />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateAllergy />
          </Layout>
        }
      />
    </Routes>
  )
}
