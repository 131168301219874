import { LayoutStyled } from './style'
import { LayoutProps } from './props'
import useLogicLayout from './hook'

import UpperBar from './UpperBar'
import VwMainMenu from './MainMenu'

const Layout = ({ children, upperBarChilden }: LayoutProps) => {
  const { layout } = useLogicLayout()

  return (
    <LayoutStyled
      className="vw-layoutStyled"
      openWorkspaceSubMenu={false}
      openMainMenu={layout.openMainMenu ?? false}
    >
      <VwMainMenu />

      <div className=".vw-upperBar-containerUpperBar">
        <UpperBar>{upperBarChilden}</UpperBar>
        <div className="contentArea">{children}</div>
      </div>
    </LayoutStyled>
  )
}

export default Layout
