import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateFood from './form_create'
import FormUpdateFood from './form_update'
import FoodTable from './table'

export default function FoodPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <FoodTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateFood />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateFood />
          </Layout>
        }
      />
    </Routes>
  )
}
