import styled from 'styled-components'

const ContainerCardLayout = styled.div`
  background-color: #222224;

  padding: 1rem;

  border-radius: 0.5rem;

  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

  display: flex;

  gap: 1rem;
`

export default ContainerCardLayout
