import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.6rem 5rem;

  .vw-dentalInsurance-header {
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-dentalInsurance-cardArea {
    display: flex;
    .vw-card-cardBox {
      width: 48.4rem;
      padding: 0 2.5rem 5rem;
      margin-right: 2.5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .vw-dentalInsurance-img {
        height: 28.7rem;
        padding-top: 6.19rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
          width: 13.3rem;
        }
        &.img2 {
          padding: 9rem 0 0 0;

          img {
            width: 18.9rem;
          }
        }

        &.img3 {
          padding: 8rem 0 0.01rem 3.1rem;

          img {
            width: 19.9rem;
          }
        }
        &.img5 {
          padding: 7.75rem 0 0 0;
          img {
            width: 9.8rem;
          }
        }
      }

      p {
        font-family: var(--font-primary);
        font-weight: 700;
        font-size: 1.4rem;
        text-align: start;

        margin-bottom: 1rem;
      }

      .form {
        div {
          /* margin-bottom: 0.4rem; */
        }
        .vw-select-boxContentInput {
          margin-bottom: 1rem;
        }

        .vw-input-boxContentInput {
          margin-bottom: 1rem;
        }

        &.vw-dentalInsurance-radioSelectArea,
        &.statutoryTerms {
          > div,
          > label {
            cursor: pointer;
            height: 5.2rem;
            padding: 1.4rem 2rem;

            display: flex;
            justify-content: space-between;
            background-color: var(--content-color);
            border-radius: 0.5rem;
            margin-bottom: 1rem;

            p {
              font-family: var(--font-secondary);
              font-weight: 400;
              line-height: 2.4rem;
              color: var(--gray-200-color);
              margin-bottom: 0;
            }
          }
        }

        &.statutoryTerms {
          > p {
            font-family: var(--font-secondary);
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;
            color: #798089;

            width: 41rem;
            margin: -0.25rem 0 3.7rem;
          }

          .toggleArea {
            height: 6.8rem;
            padding: 1.4rem 2rem;

            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: var(--content-color);
            border-radius: 0.5rem;

            > p {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
`
