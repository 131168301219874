import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateFieldPsychology from './form_create'
import FormUpdateFieldPsychology from './form_update'
import FieldPsychologyTable from './table'

export default function FieldPsychologyPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <FieldPsychologyTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateFieldPsychology />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateFieldPsychology />
          </Layout>
        }
      />
    </Routes>
  )
}
