import { createGlobalStyle } from 'styled-components'
import '../assets/styles/fonts.css'
import 'rc-tooltip/assets/bootstrap.css'
import './tooltip.scss'

const GlobalStyle = createGlobalStyle`
    :root {
        font-family: var(--font-primary);
         /* COLORS */
         --primary-color: #32d74b;  //avaliar se vale a pena neste caso

         --blue-100-color:#9BBCCB;
         --blue-500-color:#1278ED;
         --purple-500-color: #AD5AFE;

         --green-100-color: #0FE3D1;
         --green-200-color: #00D5C5;
         --green-300-color: #33D64C; 
         --green-400-color: #34C759;
         --green-500-color: #32d74b;

         --red-100-color: #FF6452;
         --red-300-color: #FF453A;
         --red-500-color: #FF1755;

         --yellow-200-color: #F7C03C;
         --yellow-300-color: #FFE620;
         --yellow-800-color: #F4A900;
         --yellow-900-color: #FF9F0A;

         --gray-50-color: #E1E4E8;
         --gray-100-color: #BEBEBE;
         --gray-150-color: #8E8E93;
         --gray-200-color: #798089;
         --gray-400-color: #7B7B7E;
         --gray-430-color: #7C7C80;
         --gray-450-color: #707070;
         --gray-500-color:#3a3a3c;
         --gray-700-color: #242426;
         --gray-800-color: #2C2C2E;
         --gray-900-color: #1C1C1E;
         --black-100-color: #222224;
         --black-200-color: #151517;

         //cores platform
         --primary-color: #19AEFF;
         --event-color: #E200FF;
         --primary-light-color: #08DAFF;
         --gray-100-color: #BEBEBE;
         --gray-200-color: #798089;
         --gray-700-color: #2B2B2D;
         --gray-200-rgba-5: rgba(121, 128, 137, 0.5);
         --background-color: #21252E;
         --content-color: #333843;
         --dark-color: #1C1C1E;
         --danger-color: #FF3333;
         --shark-color: #1C1C1E;
         --gray-color: #8E8E93;
         --gray-color-r25: rgb(142, 142, 147, 0.25);
         --abbey-color: #545456;
         --jumbo-color: #7c7c80;
         --tuna-color: #363638;
         --jaguar-color: #242426;
         --raisin-color: #222224;
         --raisin-color-r7: rgb(34, 34, 36, 0.7) ;
         --lightgray-color: #bebebe;
         --red-color: #ff0000;
         --emerald-color: #32d74b;
         --candy-apple-red-color: #f60b0b;
         --red-orange-color: #ff3b30;
         --california-color: #ff9f0a;
         --yellow-broom-color: #fafa20;
         --coral-red-color: #ff453a;
         --shamrock-color: #2ecc71;
         --shark-color: #1c1c1e;
         --shark-second-color: #262629;
         --pine-color: #2c2c2e;
         --abbey-rgb-color: rgb(84, 84, 86);
         --bombay-color: #aeaeb2;
         --bombay-rgb-color: rgb(174, 174, 178);
         --woodsmoke-color: #151517;
         --payne-color: #444446;
         --scorpion-color: #5d5d5d;
         --alto-color: #d5d5d5;
         --midgray-color: #636366;
         --paynesgrey-color: #48484a;
         --blackrussian-color: #17191c;
         --iridium-color: #3a3a3c;
         --dove-gray-color: #696969;
         --tundera-color: #484848;
         --platinum-color: #e8e8e8;
         --dove-color: #b5b4b4;
         --candy-red-color: #ff0e00;
         --red-ruby-color: #f60b0b;
         --lime-green-color: #33d64c;
         --ufo-green-color: #34c759;
         --pastel-green-color: #70d35f;
         --wild-blue-yonder-color: #9bbccb;
         --bright-turquoise-color: #0fe3d1;
         --sunset-orange-color: #ff6452;
         --sunset-orange-approx-color: #fa4242;
         --saffron-color: #f7c03c;
         --crayola-color: #1278ed;
         --chinese-color: #151515;
         --smoky-color: #0c0c0c;
         --mantis-color: #6bd35f;
         --carmine-color: #ed5545;
         --yellow-light-color: #ffe620;
         --yellow-dark-color: #f4a900;
         --orange-color: #ce4f00;
         --purple-color: #ad5afe;
         --green-color: #00d5c5;
         --pink-color: #ff1755;
         --blue-dark-color: #242426;
         --blackpanel-color: #39393b;
         --white-color: #ffffff;
         --white-200: #F2F2F2;
         --white-200-rgba-2: rgba(242,242,242,0.2);
         --grey-300: #585B62;
         --grey-300-rgba-2: rgba(88,91,98,0.2);

         /* FONTS */
         --font-primary: "Poppins", Arial, Helvetica, sans-serif;
         --font-secondary: "Oxanium", Arial, Helvetica, cursive;
         --font-third: "Nebula",  Arial, Helvetica, cursive;
    }

   
    * {
        margin: 0;  
        padding: 0;
        box-sizing: border-box;
        
    }

    body {
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        /* padding: 0 !important; */
        overflow: hidden;
        background-color: #1C1C1E;
        color: #e8e8e8;

        .rc-tooltip-inner {
            background-color: transparent !important;
        }
    }

    button {
        cursor: pointer;
        transition: all .3s ease-in-out;
        
        &:hover{
            opacity: .8;
        }
        &:disabled {
            opacity: .8;
            cursor: not-allowed;
        }
    }

    ::-webkit-scrollbar {
      width: 0.8rem;
      display: flex;

      background-color: #1c1c1e !important;
      border-radius: 8rem;
      padding: 0.1rem !important;
      border: 2px solid #242426;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #242426 !important;
      border-radius: 8rem;
      
    }


    @media (max-width: 600px) {
        html {
            font-size: 40%;
        }
    }
    @media (min-width: 601px) and (max-width: 1024px) {
        html {
            font-size: 33%;
        }
    }
    @media (min-width: 1025px) {
        html {
            font-size: 38%;
        }
    }
    @media (min-width: 1201px) {
        html {
            font-size: 40%; 
        }
    }
    @media (min-width: 1300px) {
        html {
            font-size: 44%; 
        }
    }
    @media (min-width: 1400px) {
        html {
            font-size: 46.5%; 
        }
    }
    @media (min-width: 1801px) and (max-width: 1919px) {
        html {
            font-size: 58%;
        }
    }
    @media (min-width: 1920px) and (max-width: 1999px) {
        html {
            font-size: 62.5%;
        }
    }
    @media (min-width: 2000px) {
        html {
            font-size: 65%;
        }
    }
    @media (min-width: 2500px) {
        html {
            font-size: 86%;
        }
    }


    body {
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        padding: 0 !important;
        overflow: hidden;
    }

    button {
        cursor: pointer;
        transition: all .3s ease-in-out;
        
        &:hover{
            opacity: .8;
        }
        &:disabled {
            cursor: not-allowed;
        }
    }

    input {
            &:disabled {
                cursor: not-allowed !important;
                padding-left: 0;
                color: #aeaeb2 !important;

                &::placeholder {
                    color: #aeaeb2 !important;
                }
            }
        }
      
    .vw-select-boxIconRight {
        display: none;
    }
    .rc-tooltip {
        width: min-content !important;
    }


    .vw-maincalendar-card-view{
        z-index:12;
    }


    .gm-style-iw-d{
        max-height: auto !important ;
        overflow: hidden  !important;
        display:flex ;
        justify-content: center;
        align-items:center ; 
    }

    
    .gm-ui-hover-effect{ 
        display:none !important;
        z-index:9 !important;
        top:20px !important;
        right:20px  !important;
        width:20px !important;
        height:20px !important;
        background-color: #48484A !important;
        border-radius:4px;
        &>span {
            margin:0 auto !important;
            height:12px !important;
            width:12px !important;
            
            background-color: #1C1C1E !important;
            background-size: 100% ;
        }
    }

    .gm-style-iw-c:hover{
        .gm-ui-hover-effect{ 
            display:  block !important;
        }
    }
   

    .gm-style .gm-style-iw-c {
    padding: 0 !important; 
    position: absolute;
    box-sizing: border-box;  
    transform: translate(-50%,-100%);
    background-color: #1c1c1e; 
    border: 1px solid #48484a;
    }

    .gm-style .gm-style-iw-tc{
       display: none  !important;
    }


`

export default GlobalStyle
