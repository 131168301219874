import React from 'react'
import { TotalsItem, TotalsItemProps } from './TotalsItem/TotalsItem'
import { TotalsContainer, TotalsTitle, TotalsContent } from './style'

export interface GlobalViewTotalsProps {
  title: string
  items: TotalsItemProps[]
}

export const GlobalViewTotalGroup = ({
  title,
  items,
}: GlobalViewTotalsProps) => {
  return (
    <TotalsContainer>
      <TotalsTitle>{title}</TotalsTitle>
      <TotalsContent>
        {items?.map((item, index) => (
          <TotalsItem key={index} {...item} />
        ))}
      </TotalsContent>
    </TotalsContainer>
  )
}
