/* eslint-disable @typescript-eslint/no-explicit-any */
const dataContinents: any[] = [
  {
    name: 'America',
    uuid: '1',
  },
  {
    name: 'Europe',
    uuid: '2',
  },
  {
    name: 'Asia',
    uuid: '3',
  },
  {
    name: 'Oceania',
    uuid: '4',
  },
  {
    name: 'Africa',
    uuid: '5',
  },
]

export default dataContinents
