import styled, { css } from 'styled-components'

export const ContainerMajorCourses = styled.section`
  width: 100%;
  height: 100%;

  padding: 4.3rem 5rem 2.5rem 5.1rem;

  position: relative;

  fieldset {
    border: none;
  }

  h1 {
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: var(--gray-100-color);
  }

  .vw-regis-headerButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.9rem;
    .vw-regis-boxLeft {
      display: flex;
      border-radius: 0.6rem;

      &.full {
        border: 0.1rem solid #bebebe;
      }
      border-radius: 0.6rem;
      margin-top: 0rem;
      .innerMap {
        &.selected {
          .vw-button-buttonstyle {
            background-color: #bebebe;
            span {
              color: #292e38;
            }
          }
        }
      }
    }
    .vw-regis-boxRight {
      display: flex;
      gap: 1rem;

      &.short {
        .vw-button-buttonstyle {
          &:last-of-type {
            width: 15.2rem;
            padding: 0.9rem 0.7rem 0.9rem 0.8rem;
          }
        }
      }

      .vw-regist-buttonDanger {
        margin-right: 0.1rem;
        .vw-button-buttonstyle {
          background-color: #fd3333 !important;
          width: 16.9rem !important;
          padding: 0.9rem 0.6rem 0.9rem 1.1rem;
        }
      }
      .vw-button-buttonstyle {
        border-radius: 0.5rem;
        font-family: var(--font-primary);
        font-size: 1.2rem;
        &:first-of-type {
          width: 27rem;
          height: 4.2rem;
          padding: 0.9rem 0rem 0.9rem 0rem;
        }
        &:last-of-type {
          width: 15.5rem;
          height: 4.2rem;
          background-color: var(--info-color);
          padding: 0.9rem 0.7rem 0.9rem 1rem;
          span {
            margin-bottom: 0.1rem;
          }
        }
      }
    }
  }

  .vw-questionModal-description {
    width: 42.9rem !important;
    min-width: 39.9rem !important;
    margin-bottom: 5.6rem;
    margin-top: -0.3rem;
  }

  .vw-card-cardBox {
    margin-top: 2.4rem;
    width: 147.1rem;
    height: 59.5rem;
    padding: 1.9rem 0rem 3.5rem 2.4rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .vw-regis-articleColum {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      border-right: 1px solid #333843;
      &.vw-regis-yourTitle {
        width: 26.2rem;
        border-right: 1px solid transparent;
        padding-left: 3.6rem;
        img {
          margin-top: 3.3rem;
          margin-right: 0.3rem;
          margin-bottom: 0.8rem;
        }
        h3 {
          margin-left: 0.2rem;
        }
        .vw-regis-safeArea {
          width: 100%;
          .vw-regis-safeAreaItems {
            padding-left: 0.1rem;
          }
          .vw-contentMiniForm-select {
            .vw-select-boxContentInput {
              .vw-select-BoxInput {
                .vw-select-inputFieldStyle {
                  text-align: center;
                  padding-left: 2.2rem;
                }
              }
            }
          }
          .vw-contentMiniForm-input {
            margin-top: 1rem;
            .vw-input-boxContentInput {
              .vw-input-boxInput {
                padding-left: 0;
                background-color: #33384350;
                input {
                  font-size: 1rem;
                  text-align: center;
                }
              }
            }
          }
        }
        .vw-regis-safeAreaBottom {
          margin-top: 5.8rem;
          .vw-upload-boxContainerInputUpload {
            .vw-upload-boxLabel {
              margin-bottom: 0.4rem;
              .vw-upload-boxLabelText {
                font-size: 1.1rem;
              }
            }
            .vw-upload-boxInputUpload {
              height: 7.8rem;
              .vw-upload-boxIconUpload {
                margin-top: 0.5rem;
                flex-direction: column;
                padding-bottom: 0.9rem;
                svg {
                  width: 2.2rem;
                  margin-right: 2rem;
                  margin-top: 0;
                }
                label {
                  font-size: 1rem;
                  margin-right: 2rem;
                  margin-top: 0.3rem;
                }
              }
            }
            &.vw-filled {
              .vw-upload-boxInputUpload {
                .vw-upload-boxIconUpload {
                  display: flex;
                  flex-direction: row;
                  justify-content: flex-start;
                  svg {
                    width: 2rem;
                    margin-left: -0.3rem;
                    margin-top: -0.3rem;
                    color: #de5e5e;
                  }
                  label {
                    width: min-content;
                    margin-top: -0.3rem;
                    margin-left: -1rem;
                  }
                }
                .vw-upload-boxIconClose {
                  svg {
                    width: 2rem;
                    margin-top: 0.2rem;
                    margin-left: 0.2rem;
                  }
                }
              }
            }
          }
        }
      }

      h3 {
        color: #bebebe;
        font-size: 1.6rem;
        font-family: var(--font-primary);
        font-weight: 700;
      }
      img {
        margin-top: 3rem;
      }
      p {
        width: 100%;
        margin-top: 2.9rem;
        font-size: 1.1rem;
        font-weight: 700;
        font-family: var(--font-primary);
        color: #bebebe;
        padding-left: 0.1rem;
      }
      .vw-regis-safeArea {
        width: 100%;
        margin: 0.6rem 0 0 0.1rem;
        .vw-regis-safeAreaItems {
          width: 100%;
          height: 3.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #333843;
          margin-bottom: 1rem;
          border-radius: 0.5rem;
          font-size: 1rem;
          font-family: var(--font-secondary);
          color: #798089;
          cursor: pointer;
          input {
            display: none;
          }
          &.checked {
            background-color: var(--info-color);
            color: white;
            font-weight: 700;
          }
          :last-of-type {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`

export const BoxUploadBox = styled.div``

export const ContentMiniForm = styled.div`
  .vw-input-boxContentInput {
    .vw-input-boxInput {
      height: 3.4rem;
    }
  }
  .vw-select-boxContentInput {
    .vw-select-BoxInput {
      height: 3.4rem;
      padding-left: 0.8rem;
      .vw-select-inputFieldStyle {
        font-size: 1rem;
        line-height: 1.3rem;
        padding-top: 0.1rem;
      }
      .vw-select-boxIconRight {
        width: 3rem;
        max-width: 3rem;
        min-width: 3rem;
        padding: 0;
        svg {
          width: 1rem;
          margin-top: 0.4rem;
          margin-left: 0.2rem;
        }
      }
    }
    .vw-selectOptions-boxSelectOptions {
      background-color: #10172170;
      backdrop-filter: blur(2rem) brightness(115%);
      top: -3rem;
      z-index: 9999;
    }
  }
`
type BoxContentCards = {
  selectedIndex?: number
}

export const BoxContentCards = styled.div<BoxContentCards>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .card-item {
    display: none;
    ${(props) => {
      return css`
        &.item-${props.selectedIndex} {
          width: 100%;
          display: block;
        }
      `
    }}
    .innerCardBox {
      display: flex;
    }
  }
`
