import * as React from "react";

const MobileIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        stroke={props.color || "#2c7dff"}
        {...props}
    >
        <g transform="translate(3.2)">
            <rect width={9.6} height={16} rx={2} stroke="none" />
            <rect x={0.5} y={0.5} width={8.6} height={15} rx={1.5} />
        </g>
    </svg>
);

export default MobileIcon;