import useClinicianRegister from '@src/hooks/useClinicianRegister'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useLogic = () => {
  const [enabled, setEnabled] = useState(false)
  const navigate = useNavigate()
  const {
    getClinicianRegister,
    initSteps,
    registrationActive,
    prevRoute,
    nextRoute,
    updateRegistrations,
  } = useClinicianRegister()

  useEffect(() => {
    if (registrationActive && !enabled) {
      getStatus()
    }
  }, [registrationActive])

  async function getStatus() {
    const resp = await getClinicianRegister()
    updateRegistrations(resp.registrations)
    initSteps(resp?.status)
    setEnabled(true)
  }

  function prevNext(number: number) {
    if (number >= 0) {
      navigate(nextRoute)
    } else {
      navigate(prevRoute)
    }
  }

  return { prevNext }
}

export default useLogic
