import { FeedbackType, FeedbackWhereType, RateType } from '@viswals/services'
import { ListNames } from '@viswals/components-platform'

export const getLabelFeedBackType = (value: FeedbackType) => {
  if (value === 'MAKING_SUGGESTION') {
    return 'Making an Suggestion'
  }
  if (value === 'REPORTING_PROBLEM') {
    return 'Reporting a problem'
  }
  if (value === 'REQUESTING_NEW_FEATURE') {
    return 'Requesting a new feature'
  }
  if (value === 'SENDING_APPRECIATION') {
    return 'Sending a appreciation'
  }
}

export const getLabelFeedBackWhere = (value: FeedbackWhereType) => {
  if (value === 'BOOKINGS') return 'Bookings'
  if (value === 'EXPLORE') return 'Explore'
  if (value === 'FAVOURITES') return 'Favourites'
  if (value === 'GLOBAL_ID') return 'Global ID'
  if (value === 'LITERALLY_EVERYWHERE') return 'Literally Everywhere'
  if (value === 'MAPS') return 'Maps'
  if (value === 'MORE') return 'More'
  if (value === 'MY_APPOINTMENTS') return 'My Appointments'
  if (value === 'OTHER') return 'Other'
  if (value === 'PAGES') return 'Pages'
  if (value === 'VIDA') return 'Vida'
}

export function getLabelRate(value: RateType): {
  label: string
  value: number
  color: string
  icon: ListNames
} {
  if (value === 'ANGRY')
    return { label: 'Angry', value: 1, color: '#F72A1E', icon: 'RateAngry' }
  if (value === 'UPSET')
    return { label: 'Upset', value: 2, color: '#FFB23B', icon: 'RateUpSet' }
  if (value === 'NEUTRAL')
    return { label: 'Neutral', value: 3, color: '#0093FF', icon: 'RateNeutral' }
  if (value === 'HAPPY')
    return { label: 'Happy', value: 4, color: '#E833FF', icon: 'RateHappy' }
  if (value === 'EXCITED')
    return { label: 'Excited', value: 5, color: '#5BDF6F', icon: 'RateExited' }

  return {
    label: 'Loading',
    value: 5,
    color: '#AEAEB2',
    icon: 'RateDefault',
  }
}
