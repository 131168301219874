import moment from 'moment'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const daySelect = Array.from({ length: 31 }, (_, i) => i + 1).map((d) => ({
  label: d.toString(),
  value: d,
}))
const monthSelect = Array.from({ length: 12 }, (_, i) => i + 1).map((d) => ({
  label: months[d - 1],
  value: d,
}))
const yearSelect = Array.from(
  { length: 11 },
  (_, i) => i + Number(moment().format('YYYY'))
).map((d) => ({
  label: d.toString(),
  value: d,
}))

export { months, daySelect, monthSelect, yearSelect }
