import React, { MouseEventHandler } from 'react'
import styled from 'styled-components';

interface IconProps {
    src: string;
    onClick?: MouseEventHandler<HTMLImageElement>
    alt?: string
    onPointerDown?: any;
}

const ImageIcon = styled.img``

const Icon = ({ src, alt, onClick = () => { }, onPointerDown = () => { } }: IconProps) => {
    return (
        <ImageIcon onPointerDown={e => onPointerDown(e)} onClick={onClick} src={src} alt={alt || "Viswals"} />
    )
}

export default Icon
