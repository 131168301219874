/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerWithWhomCourses = styled.div`
  width: 29.9rem;
  padding: 0 3.75rem 0 3.65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-right: 1px solid #333843;

  h3 {
    color: #bebebe;
    font-size: 1.6rem;
    font-family: var(--font-primary);
    font-weight: 700;
  }
  img {
    margin-top: 3.4rem;
  }
  p {
    width: 100%;
    margin-top: 4.3rem;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: #bebebe;
    padding-left: 0.1rem;
  }
  .vw-regis-safeArea {
    width: 100%;
    margin: 0.6rem 0 0 0.1rem;
    .vw-regis-safeAreaItems {
      width: 100%;
      height: 3.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #333843;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      font-family: var(--font-secondary);
      color: #798089;
      cursor: pointer;
      padding-left: 0.1rem;
      input {
        display: none;
      }
      &.checked {
        background-color: var(--info-color);
        color: white;
        font-weight: 700;
      }
      :last-of-type {
        margin-bottom: 0px;
      }
    }
    .vw-contentMiniForm-select {
      .vw-select-boxContentInput {
        .vw-select-BoxInput {
          .vw-select-inputFieldStyle {
            text-align: center;
            padding-left: 2.2rem;
          }
        }
      }
      .vw-selectOptions-boxSelectOptions {
        width: max-content;
      }
    }
  }

  .paragraphFirst {
    width: 100%;
    margin-top: 10.4rem;
    padding-left: 0.1rem;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: #bebebe;
  }
  .vw-contentMiniForm-input {
    width: 100%;
    margin-top: 0.6rem;
    margin-left: 0.2rem;
    .vw-input-boxContentInput {
      .vw-input-boxInput {
        padding-left: 1rem;
      }
    }
    input {
      font-size: 1rem;
      padding-left: 0;
    }
  }
`

export const ContentMiniForm = styled.div`
  .vw-input-boxContentInput {
    .vw-input-boxInput {
      height: 3.4rem;
    }
  }
  &.fill {
    .vw-select-boxContentInput {
      .vw-select-BoxInput {
        background-color: var(--primary-color);
        input {
          color: white;
          font-weight: 700;
        }
        svg {
          color: white;
        }
      }
    }
  }
  .vw-select-boxContentInput {
    .vw-select-BoxInput {
      height: 3.4rem;
      padding-left: 0.8rem;
      .vw-select-inputFieldStyle {
        font-size: 1rem;
        line-height: 1.3rem;
        padding-top: 0.1rem;
      }
      .vw-select-boxIconRight {
        width: 3rem;
        max-width: 3rem;
        min-width: 3rem;
        padding: 0;
        svg {
          width: 1rem;
          margin-top: 0.4rem;
          margin-left: 0.2rem;
        }
      }
    }
    .vw-selectOptions-boxSelectOptions {
      background-color: #10172170;
      backdrop-filter: blur(2rem) brightness(115%);
      top: -3rem;
      z-index: 9999;
    }
  }
`
