import { createGlobalStyle } from 'styled-components'

const StepGlobalStyle = createGlobalStyle`
  .vw-input-boxInput {
    background: #242426;
  }

  .vw-selectOptions-boxSelectOptions {
    background: #24242670;
  }

  .vw-select-BoxInput {
    background: #242426;
  }

  .vw-upload-boxInputUpload {
    background: #242426;
  }

  .vw-input-boxInput {
    background: #242426;
  }

  //checar
  .vw-card-cardBox {
    &.vw-card-cardBoxSelected{
      .isCheck {
        border-color: #cccccc;
      }

      .vw-sectionRadio {
        background: #cccccc;
      }
      
      &:before {
        content: '';
        border: none;
      }
    }    
  }
  
  .vw-regis-contentSelect {
    background: #242426 !important;
  }

  .vw-regis-safeAreaItems {
    background: #242426 !important;

    &.checked {
      background: #8E8E93 !important;
      color: #1C1C1E !important;
    }
  }

  .vw-practicing-radioSelectArea {
    label {
      background: #242426 !important;

      .isCheck {
        border: 0.25rem solid #CCCCCC;
      }

      .vw-sectionRadio {
        background: #CCCCCC;
      }
    }
  }

  .vw-practicing-radioSelectArea {
    & > div {
      background: #242426 !important;
    }
  }

  .vw-dentalInsurance-radioSelectArea {
    label {
      background: #242426 !important;

      .isCheck {
        border: 0.25rem solid #CCCCCC;
      }

      .vw-sectionRadio {
        background: #CCCCCC;
      }
    }
  }

  .vw-periodOfCoverage-selectArea {
    & > div {
      background: #242426 !important;
    }
  }

  .doubleSelect {
    background: #242426 !important;
  }

  .doubleInput {
    background: #242426 !important;
  }

  .doubleSelect {  
    .vw-select-BoxInput {
      background: #1C1C1E !important;
    }    
  }

  .doubleInput {
    .vw-input-boxInput {
      background: #1C1C1E !important;
    }
  }
  
  .vw-ownWebsite-inputArea {
    background: rgba(0, 0, 0, 0.7) !important;
    border-radius: 4px;
  }

  .vw-wallet-cardArea {
    .vw-countriesFlags {
      background: #1C1C1E !important;
    }
  }

  .toggleArea {
    background: #242426 !important;
  }

  .mobileContainer {
    &:before {
      z-index: 0 !important;
    }

    .topArea{ 
      z-index: 1;
    }

    .vw-icon {
      z-index: 1;      
    }
  }

`

export default StepGlobalStyle
