import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateCourseTitle from './form_create'
import FormUpdateLanguage from './form_update'
import CourseTitleTable from './table'

export default function CourseTitlePages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <CourseTitleTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateCourseTitle />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateLanguage />
          </Layout>
        }
      />
    </Routes>
  )
}
