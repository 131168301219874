import { useLocation, useNavigate } from 'react-router-dom'

import { Icon, ListNames } from '@viswals/components-platform'

export type ItemMenuComunicationProps = {
  label: string
  icon: ListNames
  route: string
  total: number
  totalOpen: number
}

export default function ItemMenuComunication({
  label,
  icon,
  route,
  total,
  totalOpen,
}: ItemMenuComunicationProps) {
  const navigate = useNavigate()
  const location = useLocation()

  const isActive: boolean =
    location.pathname.includes(route) ||
    (location.pathname === '/reports' &&
      route === '/reports/report-anything-unusual')
  return (
    <div
      className={`itemToClick ${isActive ? 'isActive' : ''}`}
      onClick={() => {
        navigate(route)
      }}
      aria-hidden
    >
      <div className="contentLeft">
        <div className="contentIcon">
          <Icon name={icon} />
        </div>
        <div className="contentLabel">{label}</div>
      </div>
      <div className="contentNotify">
        <div>{totalOpen}</div>
        <div>{total}</div>
      </div>
    </div>
  )
}
