import ContactInformation from './contact-information'
import StepLanguages from './languages'
import StepPersonalInformation from './personal-information'
import StepResidenceInformation from './residence-information'
import StepUploadID from './upload-id'
import StepUploadPhoto from './upload-photo'

const listStep2 = [
  {
    name: 'Upload Photo',
    subStep: 'UPLOAD_PHOTO',
    route: 'upload-photo',
    component: <StepUploadPhoto />,
  },
  {
    name: 'Contact Information',
    subStep: 'CONTACT_INFORMATION',
    route: 'contact-information',
    component: <ContactInformation />,
  },
  {
    name: 'Personal Information',
    subStep: 'PERSONAL_INFORMATION',
    route: 'personal-information',
    component: <StepPersonalInformation />,
  },
  {
    name: 'Upload Id',
    subStep: 'UPLOAD_ID',
    route: 'upload-id',
    component: <StepUploadID />,
  },
  {
    name: 'Residence Information',
    subStep: 'RESIDENCE_INFORMATION',
    route: 'residence-information',
    component: <StepResidenceInformation />,
  },
  {
    name: 'Languages',
    subStep: 'LANGUAGES',
    route: 'languages',
    component: <StepLanguages />,
  },
]

export default listStep2
