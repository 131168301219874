import styled from 'styled-components'

export const NotificationItemMapBox = styled.div`
  color: white;
`

export const NotificationItemMapHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    margin-left: 1rem;
    font-weight: bold;
    font-size: 1.2rem;
  }

  figure {
    height: 2rem;
    width: 2rem;
    img {
      width: 100%;
    }
  }
`

export const NotificationItemMapContent = styled.div`
  margin-top: 1rem;
`
