import { ActionProps, ShareStoreProps } from './props'

const INITIAL_STATE: ShareStoreProps = {
  shares: [],
}

export default function reducer(
  state = INITIAL_STATE,
  action: ActionProps
): ShareStoreProps {
  if (action.type === 'SET_SHARE') {
    return {
      ...state,
      shares: action.shares,
    }
  }

  return state
}
