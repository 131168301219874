/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react'
import { FieldValues, UseFieldArrayAppend } from 'react-hook-form'
import {
  Request,
  DegreeFilter,
  retrieveDegree,
  Degree,
  Institution,
} from '@viswals/services'

import { setNavigationActiveNext } from '@src/store/actions/navigation'
import { RegistrationProp } from '@src/store/reducers/clinician/props'
import handleRetrieveInstituions from './handleRetrieveInstitutions'

type handleRetrieveDegreeProps = {
  registrationActive: RegistrationProp
  append: UseFieldArrayAppend<FieldValues, 'data'>
  dispatch: Dispatch<any>
  setInstitutionsLocal: React.Dispatch<React.SetStateAction<Institution[]>>
}

async function handleRetrieveDegree({
  registrationActive,
  append,
  dispatch,
  setInstitutionsLocal,
}: handleRetrieveDegreeProps) {
  try {
    const request: Request<DegreeFilter> = {
      fields: [
        'uuid',
        'clinicianRegisterUuid',
        'continent',
        'country',
        'courseProvider',
        'institution',
        'courseDuration',
        'dateGrantedYear',
        'dateGrantedMonth',
        'awardCourseTitle',
        'courseCertificate',
        'courseCertificateName',
      ],
      filters: {
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      },
    }
    const resp = await retrieveDegree(request)
    const data: Degree[] | null = resp.data.data ?? []

    if (!data?.length) {
      append({
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      })
    }

    if (data?.length) {
      setTimeout(async () => {
        const dataReset = data?.map((item: Degree) => ({
          ...item,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          courseDuration: `${item.courseDuration}`,
          continent: `${item.continent}`,
          certificate: {
            file: item.courseCertificate,
            fileName: item.courseCertificateName,
          },
        }))
        append(dataReset as any as Degree[])
        dispatch(setNavigationActiveNext(true))
        if (dataReset && dataReset?.length > 0) {
          handleRetrieveInstituions({
            country: dataReset[0].country as string,
            setInstitutionsLocal,
          })
        }
      }, 200)
    }
  } catch (error) {
    return null
  }
}

export default handleRetrieveDegree
