import { useEffect, useState } from 'react'

import useLoading from '@src/hooks/useLoading'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import { SelectedOptionsProps } from '@viswals/components'
import {
  createPersonalInformation,
  PersonalInformation,
  PersonalInformationFilter,
  Request,
  retrievePersonalInformation,
  updatePersonalInformation,
} from '@viswals/services'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useStepsError from '@src/hooks/useStepsError'
import useClinicianRegister from '@src/hooks/useClinicianRegister'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm()

  const { getErrors, getFieldProps } = useStepsError(errors)

  const { loading, setLoading } = useLoading()
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { countries } = useSelector((state: ReducersProps) => state.datas)
  const [isReady, setIsReady] = useState<boolean>(false)

  const newDataCountries: SelectedOptionsProps[] =
    countries?.map((item) => ({
      label: item.name as string,
      value: item.uuid as string,
      icon: item.icon as string,
    })) ?? []

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && !loading && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  useEffect(() => {
    getData()
  }, [registrationActive])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, { type }) => {
      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  async function getData() {
    setLoading(true)
    try {
      const request: Request<PersonalInformationFilter> = {
        fields: [
          'uuid',
          'birthdateDay',
          'birthdateMonth',
          'birthdateYear',
          'gender',
          'nationality',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrievePersonalInformation(request)
      const data: PersonalInformation | null = resp.data
        ? resp.data.data[0]
        : {}

      setTimeout(() => {
        const dataReset: PersonalInformation = {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          uuid: data?.uuid,
          birthdateDay: data?.birthdateDay,
          birthdateMonth: data?.birthdateMonth,
          birthdateYear: data?.birthdateYear,
          gender: data?.gender,
          nationality: data?.nationality,
        }
        reset(dataReset)
        setLoading(false)
        dispatch(setNavigationActiveNext(true))
      }, 200)
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  async function onSubmit(data: PersonalInformation) {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const dataToSend: PersonalInformation = {
        ...data,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      }

      if (!data.uuid) {
        await createPersonalInformation(
          dataToSend as Request<PersonalInformation>
        )
      } else {
        await updatePersonalInformation(
          dataToSend as Request<PersonalInformation>
        )
      }
      subStepDone()
      navigate(nextRoute)
    } finally {
      setLoading(false)
    }
  }

  return {
    errors,
    verified,
    newDataCountries,
    register,
    handleSubmit,
    onSubmit,
    watch,
    getFieldProps,
  }
}

export default useLogic
