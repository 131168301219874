import { ClinicianRoutesProps } from '@src/types/stepsClinician'
import step1 from './step1'
import step2 from './step2'
import step3 from './step3'
import step4 from './step4'
import step5 from './step5'
import step6 from './step6'
import step7 from './step7'

const clinicianRoutes: ClinicianRoutesProps[] = [
  {
    title: 'Professional License',
    step: 'PROFESSIONAL_LICENSE',
    subSteps: step1,
  },
  {
    title: 'Clinician Profile',
    step: 'CLINICIAN_PROFILE',
    subSteps: step2,
  },
  {
    title: 'Career and Education',
    step: 'CAREER_AND_EDUCATION',
    subSteps: step3,
  },
  {
    title: 'Working Status',
    step: 'WORKING_STATUS',
    subSteps: step4,
  },
  {
    title: 'Online Activity',
    step: 'ONLINE_ACTIVITY',
    subSteps: step5,
  },
  {
    title: 'Platform Registration',
    step: 'PLATFORM_REGISTRATION',
    subSteps: step6,
  },
  {
    title: 'Finish Registration',
    step: 'FINISH_REGISTRATION',
    subSteps: step7,
  },
]

export default clinicianRoutes
