import * as React from "react";

const NetworkTowerIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        {...props}
    >
        <path
            d="M1.373 13.877H3.19a1.438 1.438 0 0 0 1.028-.334 1.287 1.287 0 0 0 .351-.978V7.09a1.287 1.287 0 0 0-.351-.978 1.438 1.438 0 0 0-1.028-.334H1.373a1.427 1.427 0 0 0-1.027.334 1.294 1.294 0 0 0-.348.979v5.474a1.293 1.293 0 0 0 .348.978 1.427 1.427 0 0 0 1.027.334Zm5.712 0h1.823a1.427 1.427 0 0 0 1.025-.334 1.293 1.293 0 0 0 .348-.978V5.259a1.285 1.285 0 0 0-.348-.972 1.427 1.427 0 0 0-1.025-.334H7.085a1.418 1.418 0 0 0-1.019.334 1.285 1.285 0 0 0-.348.973v7.3a1.293 1.293 0 0 0 .348.978 1.418 1.418 0 0 0 1.019.339Zm5.718 0h1.823a1.427 1.427 0 0 0 1.02-.334 1.293 1.293 0 0 0 .348-.978V3.432a1.292 1.292 0 0 0-.348-.977 1.427 1.427 0 0 0-1.025-.334h-1.819a1.418 1.418 0 0 0-1.019.334 1.293 1.293 0 0 0-.348.978v9.132a1.293 1.293 0 0 0 .348.978 1.418 1.418 0 0 0 1.019.334Z"
            fill={props.color || "#e3e3e3"}
        />
    </svg>
);

export default NetworkTowerIcon;