import { Route, Routes } from 'react-router-dom'

import CountryContent from './country'
import CityContent from './city'
import BankContent from './bank'
import LanguagesContent from './language'
import InstitutionContent from './institution'
import CourseTitleContent from './course-title'
import CurrencyContent from './currency'
import RegulatoryBodyContent from './regulatory-body'
import InsuranceCompanyContent from './insurance-company'
import HobbyContent from "./hobby";
import SportContent from "./sport";
import OccupationContent from "./occupation";
import JobContent from "./job";
import DiseaseContent from "./disease";
import OralHealthProblemContent from "./oral-health-problem";
import DrugContent from "./drug";
import FoodContent from "./food";
import AllergyContent from "./allergy";
import FieldMedicineContent from "./field_medicine";
import FieldContent from "./field_dentistry";
import FieldPsychologyContent from './field_psychology'

export default function CommonPage() {
    return (
        <Routes>
            <Route path="/*" element={<CountryContent />} />
            <Route path="/common/country/*" element={<CountryContent />} />
            <Route path="/common/city/*" element={<CityContent />} />
            <Route path="/common/bank/*" element={<BankContent />} />
            <Route path="/common/language/*" element={<LanguagesContent />} />
            <Route path="/common/institution/*" element={<InstitutionContent />} />
            <Route path="/common/currency/*" element={<CurrencyContent />} />
            <Route path="/common/course-title/*" element={<CourseTitleContent />} />
            <Route path="/common/regulatory-body/*" element={<RegulatoryBodyContent />} />
            <Route path="/common/insurance-company/*" element={<InsuranceCompanyContent />} />
            <Route path="/common/hobby/*" element={<HobbyContent />} />
            <Route path="/common/sport/*" element={<SportContent />} />
            <Route path="/common/occupation/*" element={<OccupationContent />} />
            <Route path="/common/job/*" element={<JobContent />} />
            <Route path="/common/disease/*" element={<DiseaseContent />} />
            <Route path="/common/oral-health-problem/*" element={<OralHealthProblemContent />} />
            <Route path="/common/drug/*" element={<DrugContent />} />
            <Route path="/common/food/*" element={<FoodContent />} />
            <Route path="/common/allergy/*" element={<AllergyContent />} />
            <Route path="/common/field-medicine/*" element={<FieldMedicineContent />} />
            <Route path="/common/field-psychology/*" element={<FieldPsychologyContent />} />
            <Route path="/common/field-dentistry/*" element={<FieldContent />} />
        </Routes>
    )
}
