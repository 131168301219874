import { TooltipChildren } from '@viswals/components'
import { ContainertMessageToolTip } from './ContactInformationStyles'

const MessageContactInfo = () => {
  return (
    <ContainertMessageToolTip>
      <TooltipChildren>
        <div className="vw-tooltipChildren-title">
          What´s the clinical name?
        </div>
        <div className="vw-tooltipChildren-message">
          This name should be the name that you are known by as an Oral Health
          Professional. Please note that this is the name shown across the
          platform and you can change it anytime
        </div>
      </TooltipChildren>
    </ContainertMessageToolTip>
  )
}

export default MessageContactInfo
