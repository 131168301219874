import styled from 'styled-components'

const LayoutStyled = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .content-children {
    width: 100%;
    height: 100%;
    border-radius: 5px;

    background-color: #222224;

    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.7);

    padding: 4rem;
  }

  gap: 2rem;
`

const LayoutMenuAdministrator = styled.div`
  width: 24.9rem;
  height: 100%;
  border-radius: 5px;
  overflow-y: auto;
  background-color: #222224;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.7);
`

const LayoutMenuAdministratorItem = styled.div`
  width: 100%;
  height: 5rem;

  display: flex;
  align-items: center;

  background-color: #222224;

  font-family: 'Poppins';
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.8rem;

  padding-left: 2rem;

  cursor: pointer;

  transition: all 0.3s ease-in-out;

  &:first-of-type {
    border-radius: 5px 5px 0px 0px;
  }

  &:hover {
    background-color: #2b2b2b;
  }

  &.isActive {
    background-color: #2b2b2b;
  }
`

export { LayoutStyled, LayoutMenuAdministrator, LayoutMenuAdministratorItem }
