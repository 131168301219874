import React from 'react'
import moment from 'moment'
import { Route, Routes } from 'react-router-dom'

import { LandingOnType } from '@viswals/services'
import { ButtonGroup, UserPhoto } from '@viswals/components'
import {
  ExpandedSearchBar,
  UpperCalendar,
  WhoIsIn,
} from '@viswals/components-platform'

import Layout from '@src/components/Layout'
import { SearchWrapper } from '@src/components/Layout/UpperBar/style'
import {
  upperBarLandingOn,
  whoIsInTooltip,
} from '@src/components/Layout/UpperBar/upperBarData'

import useLogic from './useLogic'
import ReportAnythingUnusual from './report-anything-unusual'
import {
  ContainerReportStyles,
  ContainerSection,
  LocalUpperBarLinkGroup,
} from './styles'
import { ContainerUpperCalendarChildren } from '../GlobalView/style'
import SendUsFeedbaack from './send-us-feedback/index'
import RateUsScreen from './rate-us/index'
import ItemMenuComunication from './components/ItemMenuComunication'
import ShareOurAppScreen from './share-our-app'
import ReportDeleteAccountScreen from './delete-account'

export default function ReportsPage() {
  const {
    setRangeUpperCalendar,
    startSelect,
    endSelect,
    landingOn,
    diary,
    updateLandingOn,
    clinician,
    dataUpperBarOptions,
    platform,
    reports,
    // feedbacks,
    // rates,
    handleSetPlatform,
  } = useLogic()

  return (
    <Layout
      upperBarChilden={
        <ContainerUpperCalendarChildren>
          <div className="leftBox">
            <span>Report Center</span>
          </div>
          <div className="rightBox">
            <LocalUpperBarLinkGroup>
              {dataUpperBarOptions.map((btn, _index) => {
                return (
                  <button
                    type="button"
                    className={btn.value === platform ? 'isActive' : ''}
                    key={_index}
                    onClick={() => {
                      handleSetPlatform(btn.value)
                    }}
                  >
                    {btn.label}
                  </button>
                )
              })}
            </LocalUpperBarLinkGroup>
            {upperBarLandingOn && (
              <ButtonGroup
                isActive={diary?.landingOn}
                options={upperBarLandingOn}
                handleClick={(value) => updateLandingOn(value as LandingOnType)}
              />
            )}
            <SearchWrapper>
              <ExpandedSearchBar
                isExpanded
                placeholder="Search"
                handleClick={() => {}}
              />
              <WhoIsIn isNotification tooltip={whoIsInTooltip} />
              <UserPhoto
                altLabel="User profile photo"
                src={clinician?.photo ?? 'https://i.ibb.co/GsNkyxs/200.jpg'}
                isOnline
              />
            </SearchWrapper>
          </div>
        </ContainerUpperCalendarChildren>
      }
    >
      <ContainerReportStyles>
        <UpperCalendar
          weekStart="MON"
          viewMode={landingOn as LandingOnType}
          dayOfData={[]}
          progressData={[]}
          onChangeSelect={(startSelected, endSelected) => {
            console.log({
              change: 'change',
            })
            setRangeUpperCalendar({
              startSelect: startSelected,
              endSelect: endSelected,
            })
          }}
          onChangeCalendar={() => {}}
          selectedDate={{
            start: moment(startSelect),
            end: moment(endSelect),
          }}
        />
        <ContainerSection>
          <main>
            <div className="boxTitle">
              <h4>Communication Type</h4>
              <div className="boxHighlight">
                <span>T</span>
                <span className="value">{reports.counter?.total}</span>
              </div>
            </div>
            <ItemMenuComunication
              label="Report Anything Unusual"
              route="/reports/report-anything-unusual"
              total={reports.counter ? reports.counter?.reportTotal ?? 0 : 0}
              totalOpen={
                reports.counter
                  ? reports.counter?.reportTotal -
                    reports.counter?.reportTotalRead
                  : 0
              }
              icon="MoreReportAnythingUnusual"
            />
            <ItemMenuComunication
              label="Send Us Feedback"
              route="/reports/send-us-feedback"
              total={reports.counter ? reports.counter?.feedbackTotal : 0}
              totalOpen={
                reports.counter
                  ? reports.counter?.feedbackTotal -
                    reports.counter?.feedbackTotalRead
                  : 0
              }
              icon="MoreSendUsFeedback"
            />
            <ItemMenuComunication
              label="Rate Us"
              route="/reports/rate-us"
              total={reports.counter ? reports.counter?.rateTotal : 0}
              totalOpen={
                reports.counter
                  ? reports.counter?.rateTotal - reports.counter?.rateTotalRead
                  : 0
              }
              icon="MoreRateTheViswalsApp"
            />
            <ItemMenuComunication
              label="Share our App"
              route="/reports/share-our-app"
              total={reports.counter ? reports.counter?.shareTotal : 0}
              totalOpen={
                reports.counter
                  ? reports.counter?.shareTotal -
                    reports.counter?.shareTotalRead
                  : 0
              }
              icon="MoreShare"
            />
            <ItemMenuComunication
              label="Delete Account"
              route="/reports/delete-account"
              total={reports.counter ? reports.counter?.dropoutTotal ?? 0 : 0}
              totalOpen={
                reports.counter
                  ? (reports.counter?.dropoutTotal ?? 0) -
                    (reports.counter?.dropoutTotalRead ?? 0)
                  : 0
              }
              icon="HeartBroken"
            />
          </main>
          <section>
            <Routes>
              <Route path="/" element={<ReportAnythingUnusual />} />
              <Route
                path="/report-anything-unusual"
                element={<ReportAnythingUnusual />}
              />
              <Route path="/send-us-feedback" element={<SendUsFeedbaack />} />
              <Route path="/rate-us" element={<RateUsScreen />} />
              <Route path="/share-our-app" element={<ShareOurAppScreen />} />
              <Route
                path="/delete-account"
                element={<ReportDeleteAccountScreen />}
              />
            </Routes>
          </section>
        </ContainerSection>
      </ContainerReportStyles>
    </Layout>
  )
}
