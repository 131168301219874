/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerLengthOfStudyCourses = styled.div`
  width: 26.2rem;
  padding: 0 0rem 0 3.65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-left: 3.7rem;

  border-right: 1px solid transparent;

  h3 {
    color: #bebebe;
    font-size: 1.6rem;
    font-family: var(--font-primary);
    font-weight: 700;
    margin-left: 0.2rem;
  }
  img {
    margin-top: 3.3rem;
    margin-right: 0.3rem;
    margin-bottom: 0.8rem;
  }
  p {
    width: 100%;
    margin-top: 2.9rem;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: #bebebe;
    &.isCourseUnderCompletion {
      /* background-color: tan; */
      margin-bottom: -1.1rem;
    }
  }
  .vw-regis-safeArea {
    width: 100%;
    margin: 0.6rem 0 0 0.1rem;
    .vw-regis-safeAreaItems {
      width: 100%;
      height: 3.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #333843;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      font-family: var(--font-secondary);
      color: #798089;
      cursor: pointer;
      padding-left: 0.1rem;
      input {
        display: none;
      }
      &.checked {
        background-color: var(--info-color);
        color: white;
        font-weight: 700;
      }
      :last-of-type {
        margin-bottom: 0px;
      }
    }
    .vw-contentMiniForm-input {
      margin-top: 1rem;
      .vw-select-boxContentInput {
        margin-bottom: 1rem;
        .vw-select-BoxInput {
          .vw-select-inputFieldStyle {
            text-align: center;
            padding-left: 2.2rem;
          }
        }
      }
      .vw-input-boxContentInput {
        .vw-input-boxInput {
          padding-left: 0;
          background-color: #33384350;
          input {
            font-size: 1rem;
            text-align: center;
          }
        }
      }
    }
    .contentCourseProgression {
      .vw-input-boxContentInput {
        .vw-input-boxInput {
          padding-left: 0;
          height: 3.4rem !important;
          margin-top: 1rem;
          input {
            font-size: 1rem;
            text-align: center;
          }
        }
      }
    }
    .vw-regis-safeAreaBottom {
      margin-top: 10.4rem;
      .vw-upload-boxContainerInputUpload {
        .vw-upload-boxLabel {
          margin-bottom: 0.4rem;
          .vw-upload-boxLabelText {
            font-size: 1.1rem;
          }
        }
        .vw-upload-boxInputUpload {
          height: 7.8rem;
          .vw-upload-boxIconUpload {
            margin-top: 0.5rem;
            flex-direction: column;
            padding-bottom: 0.9rem;
            svg {
              width: 2.2rem;
              margin-right: 2rem;
              margin-top: 0;
            }
            label {
              font-size: 1rem;
              margin-right: 2rem;
              margin-top: 0.3rem;
            }
          }
        }
        &.vw-filled {
          .vw-upload-boxInputUpload {
            .vw-upload-boxIconUpload {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              svg {
                width: 2rem;
                margin-left: -0.3rem;
                margin-top: -0.3rem;
                color: #de5e5e;
              }
              label {
                width: min-content;
                margin-top: -0.3rem;
                margin-left: -1rem;
              }
            }
            .vw-upload-boxIconClose {
              svg {
                width: 2rem;
                margin-top: 0.2rem;
                margin-left: 0.2rem;
              }
            }
          }
        }
      }
      &.isCourseUnderCompletion {
        .vw-upload-boxContainerInputUpload {
          margin-top: -3rem;
          .vw-upload-boxInputUpload {
            height: 3.4rem !important;
            .vw-upload-boxIconUpload {
              flex-direction: row;
              justify-content: flex-start;
              margin-top: 1.1rem;
              padding-left: 3.7rem;
              svg {
                margin-right: 0.5rem;
              }
            }
          }
          &.vw-filled {
            .vw-upload-boxInputUpload {
              padding-left: 1.2rem;
              .vw-upload-boxIconUpload {
                flex-direction: row;
                justify-content: flex-start;
                padding-left: 0;
                svg {
                  width: 1.6rem;
                  margin-right: 1.5rem;
                }
              }
              .vw-upload-boxIconClose {
                svg {
                  width: 2rem;
                  margin-top: 0.2rem;
                  margin-left: 0.2rem;
                }
              }
            }
          }
        }
      }
    }
  }
  &.vw-container-major {
    .vw-regis-safeArea {
      .vw-regis-safeAreaBottom {
        margin-top: 5.8rem;
      }
    }
  }
  &.vw-container-short {
    padding-left: 3.7rem;
    .vw-regis-safeArea {
      .vw-regis-safeAreaBottom {
        margin-top: 5.7rem;
      }
    }
  }
`

export const ContentMiniForm = styled.div`
  .vw-input-boxContentInput {
    .vw-input-boxInput {
      height: 3.4rem;
    }
  }
  .vw-select-boxContentInput {
    .vw-select-BoxInput {
      height: 3.4rem;
      padding-left: 0.8rem;
      .vw-select-inputFieldStyle {
        font-size: 1rem;
        line-height: 1.3rem;
        padding-top: 0.1rem;
      }
      .vw-select-boxIconRight {
        width: 3rem;
        max-width: 3rem;
        min-width: 3rem;
        padding: 0;
        svg {
          width: 1rem;
          margin-top: 0.4rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
`

export const ContentCourseProgression = styled.div`
  margin-top: -1.3rem;
  p {
    margin-bottom: 0.6rem;
  }
`
