import React from 'react'
import { Card, Checkbox, Select, Upload } from '@viswals/components'
import registrationCertificate from '@images/registrationCertificate.svg'
import expiryDate from '@images/expiryDate.svg'
import { daySelect, monthSelect, yearSelect } from './data'
import { Container } from './PeriodOfRegistrationStyles'
import useLogic from './useLogic'

const StepPeriodOfRegistration = () => {
  const {
    confirm,
    verified,
    dateNotApplicable,
    dispatch,
    watch,
    register,
    onSubmit,
    handleSubmit,
    getValues,
    setValue,
    setNavigationConfirm,
    getFieldProps,
  } = useLogic()

  return (
    <Container>
      <div className="vw-periodOfRegistration-header">
        <h2>Period of Registration & Certificate</h2>
        <span>Expiry date and certificate of current registration</span>
      </div>
      <div className="vw-periodOfRegistration-cardArea">
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('verified', { value: false })} />
          <Card isVerified={verified}>
            <div className="vw-periodOfRegistration-leftCard">
              <div className="vw-periodOfRegistration-img">
                <img src={expiryDate} alt="Expiry Date" />
              </div>
              <div>
                <p>Expiry Date of Current Registration</p>
                <div className="form-dates">
                  <Select
                    labelTitle="Select the day"
                    options={daySelect}
                    placeholder="Day"
                    formRegister={{
                      ...register('expireDateDay', {
                        validate: (v) => {
                          return !!v || getValues('expireDateNotApplicable')
                        },
                        disabled: dateNotApplicable,
                      }),
                      watch,
                    }}
                    {...getFieldProps('expireDateDay')}
                  />
                  <Select
                    labelTitle="Select the month"
                    options={monthSelect}
                    placeholder="Month"
                    formRegister={{
                      ...register('expireDateMonth', {
                        validate: (v) => {
                          return !!v || getValues('expireDateNotApplicable')
                        },
                        disabled: dateNotApplicable,
                      }),
                      watch,
                    }}
                    {...getFieldProps('expireDateMonth')}
                  />
                  <Select
                    labelTitle="Select the year"
                    options={yearSelect}
                    placeholder="Year"
                    formRegister={{
                      ...register('expireDateYear', {
                        validate: (v) => {
                          return !!v || getValues('expireDateNotApplicable')
                        },
                        disabled: dateNotApplicable,
                      }),
                      watch,
                    }}
                    {...getFieldProps('expireDateYear')}
                  />
                </div>

                <div className="vw-periodOfRegistration-checkBoxArea">
                  <Checkbox
                    formRegister={{
                      ...register('expireDateNotApplicable', {
                        onChange: (event) => {
                          if (event.target.checked) {
                            setValue('expireDateDay', null)
                            setValue('expireDateMonth', null)
                            setValue('expireDateYear', null)
                          }
                        },
                      }),
                    }}
                    {...getFieldProps('expireDateNotApplicable')}
                  />
                  <p>
                    Select this box if the “expiry date” is not applicable in
                    the selected country
                  </p>
                </div>
              </div>
            </div>

            <div className="vw-periodOfRegistration-rightCard">
              <div className="vw-periodOfRegistration-img">
                <img
                  src={registrationCertificate}
                  alt="Registration Certificate"
                />
              </div>
              <div>
                <p>Registration Certificate</p>

                <Upload
                  placeholder="Upload Certificate"
                  formRegister={{
                    ...register(`registrationCertificate`, {
                      required: !watch(`registrationCertificate`)?.fileName,
                      onBlur: () => {
                        if (!confirm) {
                          dispatch(setNavigationConfirm(true))
                        }
                      },
                    }),
                    watch,
                    setValue,
                  }}
                  {...getFieldProps('registrationCertificate')}
                />
              </div>
            </div>
          </Card>
        </form>
      </div>
    </Container>
  )
}

export default React.memo(StepPeriodOfRegistration)
