import { BarProgressBox } from './style'

export type BarProgressProps = {
  percent: number
  bgColor?: string
}

const BarProgress = ({ percent, bgColor }: BarProgressProps) => {
  return (
    <BarProgressBox
      percent={percent}
      bgColor={bgColor}
      className="bar-progress"
    >
      <div />
    </BarProgressBox>
  )
}

export default BarProgress
