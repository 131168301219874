/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import useStepsError from '@src/hooks/useStepsError'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
  setNavigationSkip,
} from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import {
  DentistryAward,
  DentistryAwardFilter,
  Request,
  retrieveDentistryAward,
  saveDentistryAward,
} from '@viswals/services'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import useLoading from '@src/hooks/useLoading'
import { useNavigate } from 'react-router-dom'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { SelectCountryNumberOptionsProps } from '../../step2/contact-information/useLogic'

export type DataDentistryAward = {
  awardCategory?: string
} & DentistryAward

type NewDentistryAward = {
  certificate: {
    file: string
    fileName: string
  }
} & DentistryAward

const useLogic = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    setError,
    formState: { errors },
    control,
  } = useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  })

  const { getFieldProps, getErrors } = useStepsError(errors)
  const { subStepDone, verified, nextRoute } = useClinicianRegister()
  const { loading, setLoading } = useLoading()
  const { countries } = useSelector((state: ReducersProps) => state.datas)

  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  const [indexToDelete, setIndexToDelete] = useState<number[]>([])
  const [isModalOpen, setIsModelOpen] = useState<boolean>(false)
  const [paginationActive, setPaginationActive] = useState<number>(1)
  const [isReady, setIsReady] = useState<boolean>(false)
  const [destroyList, setDestroyList] = useState<NewDentistryAward[]>([])

  const { confirm, next } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  const dataCountries: SelectCountryNumberOptionsProps[] = countries.map(
    (country): SelectCountryNumberOptionsProps => ({
      label: country.name as string,
      value: country.uuid as string,
    })
  )
  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (fields.length === 0 && destroyList.length === 0) {
      setTimeout(() => {
        dispatch(setNavigationSkip(true))
      }, 100)
    } else {
      setTimeout(() => {
        dispatch(setNavigationSkip(false))
      }, 100)
    }
  }, [fields.length])

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (!verified) {
      document.addEventListener('mousedown', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }
    }
  }, [indexToDelete, verified])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch, fields])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  const getData = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      const request: Request<DentistryAwardFilter> = {
        fields: [
          'uuid',
          'clinicianRegisterUuid',
          'country',
          'officialName',
          'year',
          'nominee',
          'awardCategory',
          'awardResult',
          'uploadCertificate',
          'uploadCertificateName',
          'step',
          'verified',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveDentistryAward(request)
      const data: DentistryAward[] | null = resp.data.data ?? []

      setTimeout(() => {
        if (data?.length) {
          data.map((item: DentistryAward) => {
            const newLanguage = {
              clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
              id: item.uuid,
              certificate: {
                file: item.uploadCertificate,
                fileName: item.uploadCertificateName,
              },
              ...item,
            }
            append(newLanguage)
            return null
          })
        }
        setLoading(false)
        dispatch(setNavigationActiveNext(true))
        setIsReady(true)
      }, 200)
    } catch (error) {
      setLoading(false)
    }
  }

  const handleOpenModel = useCallback(() => {
    setIsModelOpen(true)
  }, [isModalOpen])

  const handleCancelModelDelete = useCallback(() => {
    // TODO => Quando fechar o Modal, deve desmarcar os items?
    setIndexToDelete([])
    setIsModelOpen(false)
  }, [isModalOpen])

  const handleConfirmModelDelete = useCallback(() => {
    for (const item of indexToDelete) {
      const uuid = getValues(`data.${item}.uuid`)
      const obj = {
        ...watch(`data.${item}`),
        destroy: true,
      }
      if (uuid) {
        setDestroyList((list) => [...list, obj])
      }
    }
    remove(indexToDelete)
    setIndexToDelete([])

    if (!confirm) {
      dispatch(setNavigationConfirm(true))
    }
    setTimeout(() => {
      setIsModelOpen(false)
    }, 200)
  }, [isModalOpen])

  const onSubmit = async (data: any) => {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const dentristyAwards = data.data.map(
        (dentristyAward: NewDentistryAward) => ({
          ...dentristyAward,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          uploadCertificate: dentristyAward.certificate.file,
          uploadCertificateName: dentristyAward.certificate.fileName,
          nominee: parseInt(`${dentristyAward.nominee}`),
          awardResult: parseInt(`${dentristyAward.awardResult}`),
          certificate: undefined,
        })
      )

      const destroyedList = destroyList.map(
        (dentristyAward: NewDentistryAward) => ({
          ...dentristyAward,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          uploadCertificate: dentristyAward.certificate.file,
          uploadCertificateName: dentristyAward.certificate.fileName,
          nominee: parseInt(`${dentristyAward.nominee}`),
          awardResult: parseInt(`${dentristyAward.awardResult}`),
          certificate: undefined,
        })
      )

      await saveDentistryAward([
        ...destroyedList,
        ...dentristyAwards,
      ] as Request<DentistryAward>)
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setLoading(false)
    }
  }

  function handleClick(e: any) {
    if (document.getElementsByClassName('vw-select').length) {
      return false
    }
    const pathList = e.path as any[]
    const getCardMap = pathList
      ?.map((m) => m.className)
      ?.filter((f) => f && f?.includes('card-item'))
    const checkCard = !!getCardMap?.length

    if (checkCard) {
      const safeArea = !!pathList
        ?.map((m) => m.className)
        ?.filter((f) => f && f?.includes('vw-form'))?.length
      if (!safeArea) {
        const getIndex = Number(getCardMap[0].split('item-')[1])
        handleSelectIndexToDelete(getIndex)
      }
    }
  }

  const handleSelectIndexToDelete = (index: number) => {
    if (indexToDelete.includes(index)) {
      setIndexToDelete((items) => items.filter((item) => item !== index))
      return null
    }
    setIndexToDelete((items) => [...items, index])
    return null
  }

  // Pagination
  const paginationTotal = 3
  const pagination = Math.ceil(fields.length / paginationTotal)
  const indexShowEnd = paginationActive * paginationTotal
  const indexShowStart = indexShowEnd - paginationTotal

  useLayoutEffect(() => {
    setPaginationActive(pagination)
  }, [pagination])

  return {
    dispatch,
    confirm,
    fields,
    verified,
    isModalOpen,
    dataCountries,
    indexToDelete,

    errors,
    append,
    watch,
    onSubmit,
    register,
    setValue,
    handleSubmit,

    getFieldProps,

    setPaginationActive,
    setNavigationConfirm,

    handleOpenModel,
    handleCancelModelDelete,
    handleConfirmModelDelete,

    pagination,
    paginationActive,
    indexShowStart,
    indexShowEnd,
  }
}

export default useLogic
