import { ClinicType } from '@viswals/services'
import moment from 'moment'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

const daySelect = Array.from({ length: 31 }, (_, i) => i + 1).map((d) => ({
  label: d.toString(),
  value: d,
}))
const monthSelect = Array.from({ length: 12 }, (_, i) => i + 1).map((d) => ({
  label: months[d - 1],
  value: d,
}))
const yearSelect = Array.from(
  { length: 11 },
  (_, i) => i + Number(moment().format('YYYY'))
).map((d) => ({
  label: d.toString(),
  value: d,
}))

export type LocalPositionTypeProps = {
  label?: string
  value?:
    | 'OWNER_CLINICIAN'
    | 'PARTNER_CLINICIAN'
    | 'ASSOCIATE_CLINICIAN'
    | 'OTHER'
}

const position: LocalPositionTypeProps[] = [
  {
    label: 'Owner clinician',
    value: 'OWNER_CLINICIAN',
  },
  {
    label: 'Partner clinician',
    value: 'PARTNER_CLINICIAN',
  },
  {
    label: 'Associate clinician',
    value: 'ASSOCIATE_CLINICIAN',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
]

export type LocalClinicTypeProps = {
  label?: string
  value?: ClinicType
}

const clinicType: LocalClinicTypeProps[] = [
  // {
  //     label: "Viswals Clinic Registered",
  //     value: "CLINIC_REGISTERED",
  // },
  {
    label: 'Viswals Clinic Not Registered',
    value: 'CLINIC_NOT_REGISTERED',
  },
  {
    label: 'Viswals Clinic Registered',
    value: 'CLINIC_REGISTERED',
  },
]

export { clinicType, position, months, daySelect, monthSelect, yearSelect }
