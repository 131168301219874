import moment from 'moment'
import { Feedback, FeedbackType, FeedbackWhereType } from '@viswals/services'
import { Icon } from '@viswals/components'

import svgTool from '../components/svg_tool.svg'

export type ItemReportUnusualProps = {
  itemReport: Feedback
  platformLabel: string
  getLabelFeedBackType: (value: FeedbackType) => void
  getLabelFeedBackWhere: (value: FeedbackWhereType) => void
}

export default function ItemReportUnusual({
  itemReport,
  platformLabel,
  getLabelFeedBackType,
  getLabelFeedBackWhere,
}: ItemReportUnusualProps) {
  const itemId = `itemList-${itemReport.uuid}`
  return (
    <div
      className={`itemList itemList-report ${
        !itemReport.read ? 'isEnabled' : ''
      }`}
      id={itemId}
    >
      <div
        className={`tbOne ${
          itemReport.platform === 'PATIENT'
            ? 'app'
            : itemReport.platform === 'CLINICIAN'
            ? 'clinician'
            : itemReport.platform === 'CLINIC'
            ? 'clinic'
            : ''
        }`}
      >
        <div className="tbOneIconBox">
          <Icon name="Viswals3" />
        </div>
        <div className="labelTbOne">{platformLabel}</div>
      </div>
      <div className="tbTwo">{itemReport.accountUsername}</div>
      <div className="tbThree">{itemReport.accountUsername}</div>
      <div className="tbFour">
        {moment(itemReport.createdAt).format('DD MMMM YYYY')}
      </div>
      <div className="tbFive">
        {moment(itemReport.createdAt).format('HH:mm a')}
      </div>
      <div className="tbSix">
        {getLabelFeedBackType(itemReport.feedbackType as FeedbackType)}
      </div>
      <div className="tbSeven">
        {getLabelFeedBackWhere(itemReport.feedbackWhere as FeedbackWhereType)}
      </div>

      <div className="tbEight">{itemReport.message}</div>

      <div className="iconRight" id={`item-icon-${itemReport.uuid}`}>
        <img src={svgTool} alt="More" />
      </div>
    </div>
  )
}
