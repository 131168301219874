import { Card, Input, Radio, Select, Toggle } from '@viswals/components'

import DentalInsurance from '@images/dentalInsurance.svg'
import InsuranceCompanyImg from '@images/insuranceCompany.svg'
import PolicyNumber from '@images/policyNumber.svg'
import StatutoryTerms from '@images/statutoryTerms.svg'
import NotInsurance from '@images/notInsurance.svg'
import { Container } from './DentalInsuranceStyles'
import useLogic from './useLogic'

const StepDentalInsurance = () => {
  const {
    doYouHave,
    convertInsuranceCompanies,
    control,
    onSubmit,
    register,
    watch,
    setValue,
    handleSubmit,
    getFieldProps,
    clearErrors,
  } = useLogic()

  return (
    <Container>
      <div className="vw-dentalInsurance-header">
        <h2>Dental Insurance</h2>
        <span>Insert your Insurance policy details here</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-dentalInsurance-cardArea">
          <Card {...getFieldProps('doYouHave')}>
            <div className="vw-dentalInsurance-img">
              <img src={DentalInsurance} alt="Select your date of birth" />
            </div>
            <div>
              <p>Do you have Dental Insurance?</p>
              <div className="form vw-dentalInsurance-radioSelectArea">
                <label className="vw-labelDiff">
                  <p>Yes</p>
                  <Radio
                    formRegister={{
                      ...register('doYouHave', {
                        required: true,
                      }),
                      watch,
                    }}
                    value="1"
                    {...getFieldProps('doYouHave')}
                  />
                </label>
                <label className="vw-labelDiff">
                  <p>No</p>
                  <Radio
                    formRegister={{
                      ...register('doYouHave', {
                        required: true,
                      }),
                      watch,
                    }}
                    value="0"
                    {...getFieldProps('doYouHave')}
                  />
                </label>
              </div>
            </div>
          </Card>
          {doYouHave !== '0' && (
            <>
              <Card {...getFieldProps('insuranceCompany')}>
                <div className="vw-dentalInsurance-img img2">
                  <img src={InsuranceCompanyImg} alt="Select your Gender" />
                </div>
                <div>
                  <p>What is the Insurance Company?</p>

                  <div className="vw-dentalInsurance-companySelectArea form">
                    <Select
                      labelTitle="Select company"
                      options={convertInsuranceCompanies}
                      placeholder="Select from list"
                      formRegister={{
                        ...register('insuranceCompany', {
                          required: Boolean(!watch('newInsuranceCompany')),
                        }),
                        watch,
                      }}
                      {...getFieldProps('insuranceCompany')}
                    />
                    <Input
                      placeholder="If other, write here"
                      formRegister={register('newInsuranceCompany', {
                        onChange: (e) => {
                          if (e.target.value) {
                            setValue('insuranceCompany', undefined)
                            clearErrors('insuranceCompany')
                          }
                        },
                      })}
                    />
                  </div>
                </div>
              </Card>

              <Card {...getFieldProps('policyNumber')}>
                <div className="vw-dentalInsurance-img img3">
                  <img src={PolicyNumber} alt="Nationality" />
                </div>
                <div>
                  <p>What is the Policy Number?</p>
                  <div className="form">
                    <Input
                      placeholder="Insert Number here"
                      formRegister={register('policyNumber', {
                        required: doYouHave !== '0',
                      })}
                      {...getFieldProps('policyNumber')}
                    />
                  </div>
                </div>
              </Card>
            </>
          )}

          {/* ****** if first card = NO */}
          {doYouHave === '0' && (
            <>
              <Card {...getFieldProps('reasonForNot')}>
                <div className="vw-dentalInsurance-img img4">
                  <img src={NotInsurance} alt="Select your Gender" />
                </div>
                <div>
                  <p>Reason for not having dental insurance</p>

                  <div className="form vw-dentalInsurance-radioSelectArea">
                    <label className="vw-labelDiff">
                      <p>Not required in the selected country</p>
                      <Radio
                        formRegister={{
                          ...register('reasonForNot', {
                            required: doYouHave === '0',
                          }),
                          watch,
                        }}
                        value="0"
                        {...getFieldProps('reasonForNot')}
                      />
                    </label>
                    <label className="vw-labelDiff">
                      <p>Actively searching for dental insurance</p>
                      <Radio
                        formRegister={{
                          ...register('reasonForNot', {
                            required: doYouHave === '0',
                          }),
                          watch,
                        }}
                        value="1"
                        {...getFieldProps('reasonForNot')}
                      />
                    </label>
                    <label className="vw-labelDiff">
                      <p>Not in current clinical practice</p>
                      <Radio
                        formRegister={{
                          ...register('reasonForNot', {
                            required: doYouHave === '0',
                          }),
                          watch,
                        }}
                        value="2"
                        {...getFieldProps('reasonForNot')}
                      />
                    </label>
                  </div>
                </div>
              </Card>

              <Card {...getFieldProps('statutoryTerms')}>
                <div className="vw-dentalInsurance-img img5">
                  <img src={StatutoryTerms} alt="Nationality" />
                </div>
                <div>
                  <p>Statutory Terms</p>
                  <div className="form statutoryTerms ">
                    <p>
                      Please be aware that oral health professionals have a
                      legal duty of complying with <br /> the regulations of the
                      country of practice. <br />
                      <br />
                      As an oral health professional you have the responsibility
                      to hold valid dental professional insurance, if this is a
                      requirement in the country of practice. <br />
                      <br />
                      Performing as an oral health professional without dental
                      insurance in countries <br /> that require professionals
                      to do so, is a serious offence that may lead to erase from
                      the registrar and the inability to perform on the
                      ViswalsClinician Platform, in the selected country.
                    </p>

                    <div className="toggleArea">
                      <p>I confirm that I understand the above</p>

                      <Toggle
                        name="statutoryTerms"
                        control={control}
                        required
                      />
                    </div>
                  </div>
                </div>
              </Card>
            </>
          )}
        </div>
      </form>
    </Container>
  )
}

export default StepDentalInsurance
