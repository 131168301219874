import * as React from "react";

const BrainIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={12}
        height={12}
        viewBox="0 0 12 12"
        {...props}
    >
        <defs>
            <clipPath id="a">
                <path fill={props.color || "#48484a"} d="M0 0h12v10.823H0z" />
            </clipPath>
            <clipPath id="b">
                <path
                    d="M3.524 0a1.636 1.636 0 0 0-.527.108h-.012A.988.988 0 0 0 2.499.6a.853.853 0 0 0-.07.682.731.731 0 0 0 .366.376l-.044.11a.836.836 0 0 1-.433-.441.679.679 0 0 1-.044-.182 1.386 1.386 0 0 0-.865.107 1.16 1.16 0 0 0-.533 1.5l.031-.006a.912.912 0 0 1 .947.318l-.107.054a.81.81 0 0 0-.818-.255.976.976 0 0 0-.414.181l-.007.006a1.486 1.486 0 0 0-.2.213.08.08 0 0 0-.012.017 1.59 1.59 0 0 0-.242.74 1.06 1.06 0 0 0 .271.971 1.392 1.392 0 0 0 1.1.143l.033.114a1.936 1.936 0 0 1-.484.062 1.262 1.262 0 0 1-.555-.114l.036.036A1.394 1.394 0 0 0 .038 6.58a1.619 1.619 0 0 0 .984 1.039c.271.111.287.07.287.07a1.48 1.48 0 0 0 .117.739.991.991 0 0 0 .881.572 1.482 1.482 0 0 0 1.177-.229c.1.431 1.082.564 1.188.135V6.517a1.6 1.6 0 0 1-1.229-.5 1.238 1.238 0 0 1-.24-.933h-.04a.718.718 0 0 1-.476-.162.831.831 0 0 1-.277-.589h.119a.705.705 0 0 0 .235.5.655.655 0 0 0 .542.124.577.577 0 0 0 .485-.66l.119-.014a.705.705 0 0 1-.588.793h-.006a1.142 1.142 0 0 0 .21.869 1.489 1.489 0 0 0 1.137.455l-.01-5.335A1.069 1.069 0 0 0 3.586 0h-.062"
                    fill={props.color || "#48484a"}
                />
            </clipPath>
            <clipPath id="c">
                <path
                    d="M.021 1.543a2.486 2.486 0 0 0 2.211 2.256 17.56 17.56 0 0 1 2.251.468A1.178 1.178 0 0 1 5.3 5.724a2.769 2.769 0 0 0 .6-1.738 2.052 2.052 0 0 0-.533-1.4c-.951-1.005-2.56-.473-3.566-1.423a1.026 1.026 0 0 0 .4.885 2.819 2.819 0 0 0 .994.425c-.114.273-1.082-.022-1.5-.339a2.259 2.259 0 0 1-.773-1.005 2.662 2.662 0 0 0-.864-1.13 10.564 10.564 0 0 0-.037 1.544"
                    fill={props.color || "#48484a"}
                />
            </clipPath>
            <clipPath id="d">
                <path fill={props.color || "#48484a"} d="M0 0h4.044v4.112H0z" />
            </clipPath>
            <clipPath id="e">
                <path fill={props.color || "#48484a"} d="M0 0h5.246v4.833H0z" />
            </clipPath>
            <clipPath id="f">
                <path
                    d="M3.6 3.421c-.4-.081-.8-.14-1.189-.259A3.344 3.344 0 0 1 .726 2.038 2.335 2.335 0 0 1 .3 1.113 5.484 5.484 0 0 0 .054 0a9 9 0 0 0-.033 1.443 2.486 2.486 0 0 0 2.211 2.256 17.543 17.543 0 0 1 2.251.469 1.323 1.323 0 0 1 .763.665c-.031-.445-.1-1.1-1.644-1.412"
                    fill={props.color || "#48484a"}
                />
            </clipPath>
            <clipPath id="g">
                <path
                    d="M.014 1.066.005 6.4a1.681 1.681 0 0 0 1.342-.514 1.089 1.089 0 0 0 .2-.8h-.038a1.07 1.07 0 0 1-.161-.012.7.7 0 0 1-.586-.793l.117.019a.58.58 0 0 0 .486.66.654.654 0 0 0 .541-.124.707.707 0 0 0 .236-.5h.119a.836.836 0 0 1-.278.589.676.676 0 0 1-.32.148 1.2 1.2 0 0 1-.219.885 1.8 1.8 0 0 1-1.438.56v2.386c.106.429 1.087.3 1.188-.135A1.482 1.482 0 0 0 2.365 9a.992.992 0 0 0 .881-.576 1.485 1.485 0 0 0 .117-.74s.017.041.287-.07a1.617 1.617 0 0 0 .985-1.039 1.4 1.4 0 0 0-.417-1.348l.036-.036a1.262 1.262 0 0 1-.555.114 1.917 1.917 0 0 1-.483-.062l.032-.114a1.4 1.4 0 0 0 1.105-.143 1.065 1.065 0 0 0 .269-.971 1.582 1.582 0 0 0-.237-.736 1.521 1.521 0 0 0-.22-.234.976.976 0 0 0-.414-.181.81.81 0 0 0-.818.255l-.106-.054a.909.909 0 0 1 .945-.318l.031.006a1.158 1.158 0 0 0-.533-1.5 1.386 1.386 0 0 0-.866-.107.672.672 0 0 1-.044.182.836.836 0 0 1-.433.441l-.044-.11a.737.737 0 0 0 .367-.377A.861.861 0 0 0 2.173.6a.988.988 0 0 0-.48-.482h-.006A1.582 1.582 0 0 0 1.148 0h-.062A1.069 1.069 0 0 0 .015 1.066"
                    fill={props.color || "#48484a"}
                />
            </clipPath>
            <clipPath id="h">
                <path
                    d="M4.979 1.13a2.258 2.258 0 0 1-.773 1.005c-.421.316-1.39.612-1.5.339a2.821 2.821 0 0 0 .994-.425 1.027 1.027 0 0 0 .4-.885c-1.006.95-2.615.417-3.566 1.423a2.051 2.051 0 0 0-.533 1.4 2.768 2.768 0 0 0 .6 1.738 1.178 1.178 0 0 1 .817-1.456 17.556 17.556 0 0 1 2.251-.468 2.486 2.486 0 0 0 2.21-2.256A10.525 10.525 0 0 0 5.843.001a2.665 2.665 0 0 0-.864 1.13"
                    fill={props.color || "#48484a"}
                />
            </clipPath>
            <clipPath id="i">
                <path
                    d="M1.644 3.421c.4-.081.8-.14 1.189-.259A3.343 3.343 0 0 0 4.52 2.038a2.333 2.333 0 0 0 .427-.925A5.49 5.49 0 0 1 5.192 0a8.989 8.989 0 0 1 .033 1.443 2.486 2.486 0 0 1-2.21 2.256 17.543 17.543 0 0 0-2.251.469 1.323 1.323 0 0 0-.763.665c.031-.445.1-1.1 1.644-1.412"
                    fill={props.color || "#48484a"}
                />
            </clipPath>
        </defs>
        <g clipPath="url(#a)" transform="translate(0 .589)">
            <g clipPath="url(#b)" transform="translate(1.241)">
                <path fill={props.color || "#48484a"} d="m-.611.03 5.267-.3.547 9.575-5.267.301z" />
            </g>
            <g clipPath="url(#c)" transform="translate(0 5.099)">
                <path fill={props.color || "#48484a"} d="m-.131.002 6.032-.101L6 5.722l-6.033.101z" />
            </g>
            <g clipPath="url(#a)">
                <g clipPath="url(#d)" transform="translate(1.857 6.312)" opacity={0.3}>
                    <path
                        d="M1.441 1.005a3.393 3.393 0 0 1 1.5.727 2.418 2.418 0 0 1 .8 2.187 4.77 4.77 0 0 1-.031.192 2.831 2.831 0 0 0 .336-1.339 2.053 2.053 0 0 0-.533-1.4C2.58.385 1.013.879.002-.002c.19.662.8.833 1.441 1.005"
                        fill={props.color || "#48484a"}
                    />
                </g>
                <g
                    clipPath="url(#e)"
                    transform="translate(0 5.199)"
                    opacity={0.3}
                    style={{
                        mixBlendMode: "multiply",
                        isolation: "isolate",
                    }}
                >
                    <g clipPath="url(#f)">
                        <path fill={props.color || "#48484a"} d="m-.114.001 5.358-.09.083 4.92-5.359.09z" />
                    </g>
                </g>
            </g>
            <g clipPath="url(#g)" transform="translate(6.078)">
                <path fill={props.color || "#48484a"} d="m-.53.03 5.268-.3.547 9.575-5.267.301z" />
            </g>
            <g clipPath="url(#h)" transform="translate(6.099 5.099)">
                <path fill={props.color || "#48484a"} d="M-.097 5.72 0-.1l6.032.1-.097 5.822z" />
            </g>
            <g clipPath="url(#d)" transform="translate(6.099 6.312)" opacity={0.3}>
                <path
                    d="M2.603 1.005a3.4 3.4 0 0 0-1.5.727 2.417 2.417 0 0 0-.8 2.187c.009.063.019.127.031.192a2.829 2.829 0 0 1-.336-1.339 2.052 2.052 0 0 1 .533-1.4c.933-.987 2.5-.493 3.511-1.374-.19.662-.8.833-1.441 1.005"
                    fill={props.color || "#48484a"}
                />
            </g>
            <g
                opacity={0.3}
                style={{
                    mixBlendMode: "multiply",
                    isolation: "isolate",
                }}
            >
                <g clipPath="url(#i)" transform="translate(6.754 5.199)" opacity={0.5}>
                    <path fill={props.color || "#48484a"} d="M-.081 4.832 0-.088l5.359.09-.083 4.92z" />
                </g>
            </g>
        </g>
    </svg>
);

export default BrainIcon;