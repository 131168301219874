import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {Currency, Job, JobFilter, Request, retrieveJob,} from '@viswals/services'
import gsap from 'gsap'
import {Button} from '@viswals/components-platform'

import HeaderContentCommon from '../_components/HeaderContentCommon'
import {StyledContentJob, StyledContentJobTable} from './styles'
import gridStyle from '../_components/grid-style'
import {LoadingTable} from '../_components/loading-table'
import {LocalPaginationRequestProps} from '../country/table'

export type LocalCurrencyAdm = {
    id?: number
} & Job

export default function CurrencyTable() {
    const navigate = useNavigate()

    const [currencies, setCurrencies] = useState<LocalCurrencyAdm[]>([])
    const [pagination, setPagination] = useState<LocalPaginationRequestProps>({
        itemsPerPage: 25,
        page: 1,
        total: 0,
    })

    useEffect(() => {
        ;(async () => {
            await getLocalJobs(pagination)
        })()
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'enabled',
            headerName: 'Enabled',
            renderCell: (params) => {
                const jobToSave: Job = params.row
                return <>{jobToSave.enabled ? 'YES' : 'NO'}</>
            },
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => {
                return (
                    <div style={{display: 'flex', gap: 20}}>
                        <Button
                            label="Edit"
                            uuid=""
                            style={{backgroundColor: 'orange', color: 'black'}}
                            handleClick={() => {
                                const jobToSave = params.row
                                window.localStorage.setItem(
                                    'jobEdit',
                                    JSON.stringify(jobToSave)
                                )
                                navigate(
                                    `/administration/common/job/edit/${jobToSave.uuid}`
                                )
                            }}
                        />
                    </div>
                )
            },
            align: 'center',
            headerAlign: 'center',
            width: 100,
            sortable: false,
        },
    ]

    async function getLocalJobs(
        paginationed: LocalPaginationRequestProps | undefined
    ) {
        try {
            await getJobs(paginationed)
        } catch (error) {
            console.error({
                error,
            })
        }
    }

    async function getJobs(
        paginationed: LocalPaginationRequestProps | undefined
    ) {
        const request: Request<JobFilter> = {
            fields: [
                'uuid',
                'name',
                'occupationUuid',
                'enabled',
                'updatedAt',
                'createdAt',
            ],
            pagination: {
                itemsPerPage: 25,
                page: paginationed?.page as number,
            },
            orderBy: {
                column: 'name',
                direction: 'asc',
            },
        }
        try {
            const resp = await retrieveJob(request)
            const {data} = resp

            if (data.code === 200) {
                const dataCities: Job[] = data.data

                const newLanguages: LocalCurrencyAdm[] = dataCities.map(
                    (value, _index) => {
                        return {
                            id: _index,
                            uuid: value.uuid,
                            name: value.name,
                            occupationUuid: value.occupationUuid,
                            enabled: value?.enabled ?? false,
                        }
                    }
                )
                setCurrencies(newLanguages)

                setTimeout(() => {
                    setPagination(
                        (value) =>
                            ({
                                ...value,
                                ...{
                                    itemsPerPage: paginationed?.itemsPerPage,
                                },
                                total: data.pagination
                                    ? data.pagination.total
                                    : data.data.length,
                            } as LocalPaginationRequestProps)
                    )
                }, 200)
                disabledLoading()
            }
        } catch (e) {
            console.error(e)
        }
    }

    function handleClickAdd() {
        navigate('/administration/common/job/new')
    }

    async function addPage(page: number) {
        const pageToSet: LocalPaginationRequestProps = {
            itemsPerPage: 25,
            page: page + 1,
        }
        try {
            await getLocalJobs(pageToSet)
        } catch (error) {
            console.error({
                error,
            })
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function enabledLoading() {
        const query = window.document.querySelector('.vw-loadingContainer')
        gsap.to(query, {
            zIndex: 99,
            duration: 0.1,
            onComplete: () => {
                gsap.to(query, {
                    opacity: 1,
                    delay: 0.1,
                    duration: 0.25,
                })
            },
        })
    }

    function disabledLoading() {
        setTimeout(() => {
            const query = window.document.querySelector('.vw-loadingContainer')
            gsap.to(query, {
                opacity: 0,
                duration: 0.25,
                onComplete: () => {
                    gsap.to(query, {
                        zIndex: -1,
                        delay: 0.25,
                        duration: 0.25,
                    })
                },
            })
        }, 300)
    }

    return (
        <StyledContentJob>
            <HeaderContentCommon
                name="Jobs"
                description="Manager currency information"
                onClickAdd={() => handleClickAdd()}
            />

            <StyledContentJobTable>
                <LoadingTable/>
                <DataGrid
                    sx={gridStyle}
                    rows={currencies}
                    columns={columns}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    experimentalFeatures={{newEditingApi: false}}
                    onPageChange={(page) => {
                        enabledLoading().then(() => {
                            setTimeout(() => {
                                addPage(page)
                            }, 200)
                        })
                    }}
                    pagination
                    rowsPerPageOptions={[25]}
                    rowCount={pagination.total}
                    initialState={{
                        pagination: {
                            pageSize: pagination.itemsPerPage,
                        },
                    }}
                    paginationMode="server"
                />
            </StyledContentJobTable>
        </StyledContentJob>
    )
}
