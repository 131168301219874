import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-periodOfRegistration-header {
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-card-cardBox {
    display: flex;
    padding: 0 2.5rem 5rem;
    position: relative;

    .vw-periodOfRegistration-leftCard,
    .vw-periodOfRegistration-rightCard {
      width: 50%;
      .vw-periodOfRegistration-img {
        height: 41.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      > div {
        > p:first-of-type {
          font-size: 1.4rem;
          font-weight: 700;
          color: var(--gray-100-color);
          margin-bottom: 1.2rem;
        }
      }
    }

    .vw-periodOfRegistration-leftCard {
      padding-right: 3.7rem;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        height: 54.6rem;
        width: 0.1rem;
        background-color: var(--content-color);
        right: 0;
        top: 2.4rem;
      }

      img {
        width: 28, 5rem;
        margin: 0.8rem 0 0 5rem;
      }

      .form-dates {
        display: flex;
        gap: 2.5rem;
        margin-bottom: 1.9rem;

        .vw-selectOptions-boxSelectOptions {
          top: -20.3rem;
          right: 0.7rem;

          .vw-selectOptions-boxSelectOptionsLabel {
            padding: 2.4rem 1.2rem 0.5rem 0.8rem;
          }

          .vw-selectOptions-boxScrollOptions {
            height: 36rem;

            .vw-selectOptions-boxContentScrollItem {
              margin-bottom: 0.1rem;
              margin-left: -0.3rem;
              height: 2.9rem;
              padding-left: 0;
            }
          }
        }
      }

      .vw-periodOfRegistration-checkBoxArea {
        display: flex;
        align-items: center;
        height: 2.4rem;
        padding-top: 0.2rem;

        font-size: 1.1rem;
        color: var(--gray-400-color);
        line-height: 2.2rem;

        .vw-checkbox-boxCheckbox {
          margin: -2.3rem 0.9rem 0 0.3rem;

          .vw-checkbox-floatBoxCheckbox {
            border: 0.1rem solid #707070;
            width: 1.9rem;
            max-width: 1.9rem;
            height: 1.9rem;
            max-height: 1.9rem;
          }
        }
      }
    }

    .vw-select-inputFieldStyle {
      padding-left: 0;
    }
    .vw-select-boxIconRight {
      width: 45rem !important;
      min-width: 4rem !important;
    }
    .vw-periodOfRegistration-rightCard {
      padding-left: 3.7rem;

      img {
        width: 33.9rem;
        margin-top: -1.79rem;
        margin-right: -0.25rem;
      }
      > div {
        > p:first-of-type {
          padding-left: 1rem;
        }
      }

      form {
        margin-right: -1rem;
        padding-left: 1rem;
      }
    }
  }
`
