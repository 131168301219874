import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-practicing-header {
    width: 100%;
    margin-top: -0.1rem;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-practicing-cardArea {
    display: flex;
    .vw-card-cardBox {
      width: 48.4rem;
      padding: 0 2.5rem 5rem;
      margin-right: 2.5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .vw-practicing-img {
        height: 36.4rem;
        padding-top: 9.6rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
          width: 20.2rem;
        }
        &.img2 {
          padding-top: 9.9rem;

          img {
            width: 19.2rem;
          }
        }
        &.img3 {
          padding-top: 8.3rem;
          img {
            width: 21rem;
          }
        }

        &.img4 {
          padding-top: 9.9rem;

          img {
            width: 14.4rem;
          }
        }
        &.img5 {
          padding-top: 8rem;

          img {
            width: 14.4rem;
            margin-left: -0.2rem;
          }
        }
      }

      p {
        font-family: var(--font-primary);
        font-weight: 700;
        font-size: 1.4rem;
        text-align: start;

        margin-bottom: 0.93rem;
      }

      .form {
        .vw-selectOptions-boxSelectOptions {
          width: max-content;

          .vw-selectOptions-boxSelectOptionsLabel {
            text-align: start;
            padding-bottom: 0.5rem;
          }
          .vw-selectOptions-boxContentScrollItem {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .vw-select-boxContentInput {
          width: 13rem;
          height: 3.4rem;
          > div:first-of-type {
            padding: 0 0 0 0.8rem;
            background-color: var(--dark-color);
          }
        }

        .vw-input-boxContentInput {
          width: 13rem;
          height: 3.4rem;
          > div {
            padding: 0 0 0 0.8rem;
            background-color: var(--dark-color);
          }
        }

        .vw-select-boxIconRight {
          width: 3.2rem !important;
          max-width: 3.2em !important;
          min-width: 3.2rem !important;
          padding-bottom: 0.1rem;
        }
      }

      .vw-practicing-radioSelectArea {
        > div,
        > label {
          height: 5.2rem;
          padding: 1.4rem 2rem;
          cursor: pointer;

          display: flex;
          justify-content: space-between;
          background-color: var(--content-color);
          border-radius: 0.5rem;
          margin-bottom: 1rem;

          p {
            font-family: var(--font-secondary);
            font-weight: 400;
            line-height: 2.4rem;
            color: var(--gray-200-color);
            margin-bottom: 0;
          }

          /* .vw-radio-inputRadio {
            border-color: var(--gray-200-color);
          } */
        }

        .selectArea {
          display: flex;
          align-items: center;
          padding: 0.9rem 0.5rem 0.9rem 2rem;

          gap: 1.5rem;
        }

        .inputArea {
          display: flex;
          align-items: center;
          margin-right: -1rem;
          gap: 1.5rem;
        }
      }
    }
  }
`
