/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFieldArray, useForm } from 'react-hook-form'
import {
  Request,
  retrieveWallet,
  Wallet,
  WalletFilter,
  Payment,
  PaymentFilter,
  retrievePayment,
  RegistrationModeFilter,
  retrieveRegistrationMode,
  RegistrationMode,
  savePayment,
  WorkingLocation,
  WorkingLocationFilter,
  retrieveCurrentWorkLocation,
} from '@viswals/services'
import useLoading from '@src/hooks/useLoading'
import { ReducersProps } from '@src/types/reducers'
import { SelectedOptionsProps } from '@viswals/components'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useDatas from '@src/hooks/useDatas'

export type PaymentLocal = {
  isActive?: boolean
  clinicName?: string
} & Payment

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { subStepDone, nextRoute } = useClinicianRegister()
  const { banks, getBank } = useDatas()

  const { control, register, handleSubmit, watch, setValue } = useForm()
  const { fields, append } = useFieldArray({
    control,
    name: 'data',
  })

  const { loading, setLoading } = useLoading()
  const [isReady, setIsReady] = useState<boolean>(false)

  const [wallets, setWallets] = useState<Array<Wallet>>([])
  const [registrationMode, setRegistrationMode] = useState<RegistrationMode>({})
  const [workingLoc, setWorkingLoc] = useState<WorkingLocation[]>([])

  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  const { confirm, next } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  const selectWallets: SelectedOptionsProps[] = wallets.map(
    (wallet: Wallet) => {
      const dataReturn: SelectedOptionsProps = {
        label: banks.find((bank) => bank.uuid === wallet.bank)?.name as string,
        value: wallet.uuid as string,
      }
      return dataReturn
    }
  )

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  useEffect(() => {
    getCurrentWorkingLocation()
  }, [])

  useEffect(() => {
    if (registrationMode.uuid) {
      getPayments()
    }
  }, [registrationMode])

  const getRegistrationMode = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      const requestRegistrationMode: Request<RegistrationModeFilter> = {
        fields: ['uuid', 'clinicianRegisterUuid', 'mode'],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const response = await retrieveRegistrationMode(requestRegistrationMode)
      const data: RegistrationMode[] = response?.data?.data ?? []
      if (data && data.length > 0) {
        setRegistrationMode(data[0])
      }
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  const getWallets = async () => {
    try {
      const request: Request<WalletFilter> = {
        fields: [
          'uuid',
          'clinicianRegisterUuid',
          'country',
          'bank',
          'accountType',
          'accountName',
          'accountNumber',
          'sortCode',
          'color',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveWallet(request)
      const data: Wallet[] = resp?.data?.data ?? []

      setWallets(
        data.map((d) => {
          getBank(d.country as string)
          return d
        })
      )
    } catch (error) {
      console.error(error)
    }
  }

  const getCurrentWorkingLocation = async () => {
    try {
      const requestCurrentWorkingLoc: Request<WorkingLocationFilter> = {
        fields: ['uuid', 'clinicType', 'clinicUuid', 'clinicName'],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        },
      }

      const retrieveCurrentWorkingLoc = await retrieveCurrentWorkLocation(
        requestCurrentWorkingLoc
      )

      const currentWorkingLoc: WorkingLocation[] =
        retrieveCurrentWorkingLoc.data.data

      setWorkingLoc(currentWorkingLoc)
    } catch (error) {
      console.error({
        error,
      })
    } finally {
      getRegistrationMode()
      getWallets()
    }
  }

  const getPayments = async () => {
    try {
      const requestPayments: Request<PaymentFilter> = {
        fields: ['uuid', 'clinicianRegisterUuid', 'walletUuid', 'clinicUuid'],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const retrievePayments = await retrievePayment(requestPayments)
      const datad: Payment[] = retrievePayments?.data?.data ?? []

      // Only if is any kind of freelancer
      if (
        // @ts-ignore
        registrationMode.mode === 'FREELANCER' ||
        // @ts-ignore
        registrationMode.mode === 'FREELANCER_IN_TEAM'
      ) {
        const findFreelancer = datad.find((item) => {
          return item.clinicUuid === undefined
        })
        const dataToMap: Payment[] = [
          {
            uuid: findFreelancer?.uuid,
            clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
            walletUuid: findFreelancer?.walletUuid,
          },
        ]
        append(dataToMap)
      }

      const withoutFreelancer: PaymentLocal[] = datad.filter(
        (item: any) => item.clinicUuid
      )
      const dataToAppend: PaymentLocal[] = []

      for (const cwl of withoutFreelancer) {
        const findWorking = workingLoc.find(
          (wl) => wl.clinicUuid === cwl.clinicUuid
        )
        dataToAppend.push({
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          clinicUuid: cwl.clinicUuid,
          clinicName: findWorking?.clinicName,
          walletUuid: cwl.walletUuid,
          workingLocationUuid: findWorking?.uuid,
        })
      }

      append(dataToAppend)

      const localPayments: string[] = dataToAppend.map(
        (m: PaymentLocal) => m.clinicUuid as string
      )

      const registredWorkingLoc: WorkingLocation[] = workingLoc.filter(
        (cwl) =>
          cwl.clinicType === 'CLINIC_REGISTERED' &&
          !localPayments.includes(cwl.clinicUuid as string)
      )

      const dataToAppends: PaymentLocal[] = []

      for (const cwl of registredWorkingLoc) {
        dataToAppends.push({
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          clinicUuid: cwl.clinicUuid,
          clinicName: cwl.clinicName,
          workingLocationUuid: cwl.uuid,
        })
      }

      append(dataToAppends)
    } catch (e) {
      console.error(e)
    }
  }

  const onSubmit = async (data: any) => {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const newPayments: PaymentLocal[] = []
      data.data.map((pay: PaymentLocal) => {
        newPayments.push({
          ...pay,
          isActive: undefined,
        })
        return null
      })
      await savePayment([...newPayments] as Request<PaymentFilter>)
      subStepDone()
      navigate(nextRoute)
    } finally {
      setLoading(false)
    }
  }

  return {
    fields,
    loading,
    wallets,
    selectWallets,
    setValue,
    watch,
    register,
    onSubmit,
    handleSubmit,
    banks,
  }
}

export default useLogic
