import { ActionProps, DiaryProps } from './props'

const INITIAL_STATE: DiaryProps = {
  diary: {},
  diaryTemp: {},
  upperCalendarStartSelect: '',
  upperCalendarEndSelect: '',
  initDataEvent: {
    day: '',
    hour: '',
  },
  eventTemp: {},
  events: [],
  eventCopy: {},
  wsConnected: false,
  overlay: false,
  liveBoardNotifications: [],
  landingOn: 'DAY',
}

export default function reducer(state = INITIAL_STATE, action: ActionProps) {
  if (action.type === 'SET_DIARY') {
    return {
      ...state,
      diary: action.diary,
    }
  }
  if (action.type === 'SET_DIARY_TEMP') {
    return {
      ...state,
      diaryTemp: action.diaryTemp,
    }
  }
  if (action.type === 'SET_DIARY_LANDING_ON') {
    return {
      ...state,
      landingOn: action.landingOn,
      upperCalendarStartSelect: action.upperCalendarStartSelect ? action.upperCalendarStartSelect : state.upperCalendarStartSelect,
      upperCalendarEndSelect: action.upperCalendarEndSelect ? action.upperCalendarEndSelect : state.upperCalendarEndSelect,
    }
  }

  if (action.type === 'SET_UPPERCALENDAR_SELECT') {
    return {
      ...state,
      upperCalendarStartSelect: action.upperCalendarStartSelect,
      upperCalendarEndSelect: action.upperCalendarEndSelect,
    }
  }

  if (action.type === 'SET_INIT_DATA_EVENT') {
    return {
      ...state,
      initDataEvent: state.initDataEvent,
    }
  }

  if (action.type === 'SET_DIARY_EVENT_TEMP') {
    return {
      ...state,
      eventTemp: action.eventTemp,
    }
  }
  if (action.type === 'SET_DIARY_EVENT_COPY') {
    return {
      ...state,
      eventCopy: action.eventCopy,
    }
  }
  if (action.type === 'SET_DIARY_EVENTS') {
    return {
      ...state,
      events: action.events,
    }
  }
  if (action.type === 'SET_DIARY_RESET_TEMP') {
    return {
      ...state,
      diaryTemp: {},
      eventTemp: {},
      eventCopy: {},
    }
  }

  if (action.type === 'SET_DIARY_WS') {
    return {
      ...state,
      wsConnected: action.wsConnected,
    }
  }

  if (action.type === 'SET_DIARY_LIVEBOARD') {
    return {
      ...state,
      liveBoardNotifications: action.liveBoardNotifications,
    }
  }

  if (action.type === 'SET_DIARY_OVERLAY') {
    return {
      ...state,
      overlay: action.overlay,
    }
  }

  if (action.type === 'SET_DIARY_ALERT_MODAL') {
    return {
      ...state,
      alertModal: action.alertModal,
    }
  }

  if (action.type === 'SET_DIARY_ALERT_TOASTIFY') {
    return {
      ...state,
      alertToastify: action.alertToastify,
    }
  }

  if (action.type === 'SET_DIARY_SHIFTS') {
    return {
      ...state,
      shifts: action.shifts,
    }
  }

  if (action.type === 'SET_DIARY_SHIFT_TEMP') {
    return {
      ...state,
      shiftTemp: action.shiftTemp,
    }
  }

  return state
}
