import api from '@src/services/api'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const useLogic = () => {
  const navigate = useNavigate()

  const [fished, setFished] = useState<any>(null)

  useEffect(() => {
    getFishingPool()
  }, [])

  async function getFishingPool() {
    const { data } = await api.get('/patient/fishing-pool/retrieve')
    setFished(data)
  }

  async function goFished() {
    const payload = {
      accountUuid: localStorage.getItem('cc_uuid'),
      entityUuid: fished?.uuid,
      entityType: fished?.entityType,
    }
    try {
      const { data } = await api.post(
        '/cc-account/fishing-pool/create',
        payload
      )
      console.log(data)
      navigate(`/verification/${fished?.uuid}`)
    } catch (e) {
      console.error(e)
      // eslint-disable-next-line no-alert
      alert('Error')
    }
  }

  const image = fished?.profileVerificationPhoto?.photo

  return { image, goFished }
}

export default useLogic
