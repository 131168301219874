import React, { Suspense } from 'react'

import {
  FooterVerification,
  HeaderVerification,
  MainContainerVerification,
} from '@viswals/components'

import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { useNavigate, useParams } from 'react-router-dom'
import clinicianRoutes from '@src/components/steps/clinician'
import useLogic from './useLogic'

import { Container } from './style'
import StepGlobalStyle from './StepGlobalStyles'
import CountryOptions from './CountryOptions'

const ClinicianRegistrationPage: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const {
    headerInfo,
    stepActiveIndex,
    subObj,
    subStepActive,
    subStepList,
    SubStepActiveIndex,
    handleVerified,
    countryActive,
  } = useClinicianRegister()

  const { prevNext } = useLogic()

  function handleStep(route: string) {
    const stepIndex = route ? Number(String(route).replace('/step', '')) - 1 : 0

    const find = clinicianRoutes[stepIndex].subSteps

    navigate(
      `/clinician-registration${route}/${find[0].route}/${params.country}`
    )
  }

  return (
    <Container>
      <StepGlobalStyle />
      <HeaderVerification
        steps={headerInfo().stepsList}
        clinicianName="Dr. Anne Rosemary"
        labelOverview="Overview"
        labelProgress="Certification Progress"
        focusedStep={stepActiveIndex}
        certificationProgress={headerInfo().totalPercent}
        onClick={(route) => handleStep(route)}
      />
      <MainContainerVerification
        isoCode={countryActive?.country?.isoCode ?? ''}
        subStepList={subStepList}
        subStepActive={SubStepActiveIndex}
        handleClickSubStep={(route) =>
          navigate(
            `/clinician-registration/${params.step}/${route}/${params.country}`
          )
        }
        countryChildren={<CountryOptions />}
      >
        <Suspense fallback={<h1>Still Loading…</h1>}>
          {subStepActive && subObj?.component}
        </Suspense>
      </MainContainerVerification>

      <FooterVerification
        onPrev={() => prevNext(-1)}
        onNext={() => prevNext(1)}
        onVerify={subStepActive?.status ? () => handleVerified() : undefined}
      />
    </Container>
  )
}

export default ClinicianRegistrationPage
