import React from 'react'
import { Input, Select, SelectedOptionsProps } from '@viswals/components'
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import CoursesWhom from '@images/step3degreeWhom.svg'
import transformToSelectOptions from '@src/core/transformToSelectOptions'
import { DataApiMockProps } from '../../common/data'
import { ContainerWithWhomCourses, ContentMiniForm } from './styles'

type WhereCoursesProps = {
  institutions: SelectedOptionsProps[]
  watch: UseFormWatch<FieldValues>
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  keyIndex?: number
  courseProviders: DataApiMockProps[]
}

const WithWhomCourses = ({
  institutions,
  keyIndex,
  watch,
  register,
  setValue,
  courseProviders,
}: WhereCoursesProps) => {
  const dataProviderCourses = transformToSelectOptions({
    array: courseProviders,
    keyLabel: 'name',
    keyValue: 'uuid',
  })
  return (
    <ContainerWithWhomCourses>
      <h3>With Whom</h3>
      <img src={CoursesWhom} alt="CoursesWhom" />
      <p>Course Provider</p>
      <div className="vw-regis-safeArea">
        {dataProviderCourses.map((courseProvider, _index) => {
          return (
            <label
              key={_index}
              className={`vw-form vw-regis-safeAreaItems ${
                courseProvider.value ===
                watch(`data.${keyIndex}.courseProvider`)
                  ? 'checked'
                  : ''
              }`}
            >
              {courseProvider.label}
              <input
                type="radio"
                value={courseProvider.value}
                {...register(`data.${keyIndex}.courseProvider`, {
                  required: true,
                  onChange: () => {
                    setValue(`data.${keyIndex}.institution`, undefined)
                  },
                })}
              />
            </label>
          )
        })}
      </div>
      <div className="paragraphFirst">Institution</div>
      <ContentMiniForm className="vw-contentMiniForm-select">
        <Select
          labelTitle="Select Institution"
          placeholder="Select Institution"
          options={institutions}
          search
          placement="top"
          formRegister={{
            ...register(`data.${keyIndex}.institution`, {
              onChange: (e) => {
                if (e.target.value) {
                  setValue &&
                    setValue(`data.${keyIndex}.institutionText`, undefined)
                }
              },
            }),
            watch,
          }}
          isDisabled
        />
      </ContentMiniForm>
      <ContentMiniForm className="vw-contentMiniForm-input">
        <Input
          placeholder="If other, write here"
          formRegister={register(`data.${keyIndex}.institutionText`, {
            onChange: (e) => {
              if (e.target.value) {
                setValue && setValue(`data.${keyIndex}.institution`, undefined)
              }
            },
          })}
        />
      </ContentMiniForm>
    </ContainerWithWhomCourses>
  )
}

export default WithWhomCourses
