import React from 'react'
import { Card, Toggle } from '@viswals/components'
import RegistrationCard from './RegistrationCard'
import { Container, CardArea } from './RegistrationModeStyles'
import { registrationCard } from './data'
import CommissionsCard from './CommissionsCard'
import PlansCard from './PlansCard'

import useLogic from './useLogic'
import { CommissionCardProps, PlansCardProps } from './props'

const StepRegistrationMode = () => {
  const {
    PlansCardRef,
    CommissionCardRef,
    RegistrationCardRef,
    checkTogglePlan,
    showViewMode,
    control,
    watch,
    register,
  } = useLogic()

  return (
    <Container showViewMode={showViewMode} checkTogglePlan={checkTogglePlan}>
      {/* ***** HEADER AREA *** */}
      <div className="vw-registrationMode-header">
        <div className="headerSide">
          <h2>Registration Mode</h2>
          <span>Select the registration mode</span>
        </div>

        {showViewMode && (
          <div className="toggleSide">
            <p className="commissions">Commissions</p>
            <div aria-hidden>
              <Toggle name="check" isBig control={control} isDisabled />
            </div>
            <p className="plans">Plans</p>
          </div>
        )}
        <div className="subtitleSide">
          <div>
            <span>FL</span>
            <p>Freelancer</p>
          </div>
          <div>
            <span>IT</span>
            <p>In Team</p>
          </div>
        </div>
      </div>

      {/* ***** CARD AREA *** */}
      <CardArea>
        {registrationCard.map((item, index) => {
          return (
            <Card
              key={item.uuid}
              // onClick={() => {
              //   if (index === 0) {
              //     window.document
              //       .getElementById(`radioMode_${item.uuid}`)
              //       ?.click()
              //   }
              // }}
            >
              {showViewMode === false ? (
                <div ref={RegistrationCardRef} style={{ margin: 'auto' }}>
                  <RegistrationCard
                    item={item}
                    selectedMode={watch('mode')}
                    registerRadio={{ ...register('mode'), watch }}
                  />
                </div>
              ) : !checkTogglePlan ? (
                <div
                  ref={CommissionCardRef}
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    width: '100%',
                  }}
                >
                  <CommissionsCard item={item as CommissionCardProps} />
                </div>
              ) : (
                <div
                  ref={PlansCardRef}
                  style={{
                    marginLeft: 0,
                    marginRight: 0,
                    width: '100%',
                  }}
                >
                  <PlansCard item={item as PlansCardProps} index={index} />
                </div>
              )}
            </Card>
          )
        })}
      </CardArea>

      <div className="vw-div-footer"> </div>
    </Container>
  )
}

export default StepRegistrationMode
