import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { getCountries } from '@src/utils/getDatas'
import useDatas from '@src/hooks/useDatas'

import { dataMenuAdministration } from './data'

import {
  LayoutMenuAdministrator,
  LayoutMenuAdministratorItem,
  LayoutStyled,
} from './layout-styles'

export type LayoutProps = {
  children?: React.ReactNode
}

export default function Layout({ children }: LayoutProps) {
  const navigation = useNavigate()

  const location = useLocation()

  const { countries } = useDatas()

  useEffect(() => {
    ; (async () => {
      if (countries.length === 0) {
        await getCountries()
      }
    })()
  }, [])

  function handleClickNavigate(path: string) {
    navigation(path)
  }

  return (
    <LayoutStyled>
      <LayoutMenuAdministrator>
        {dataMenuAdministration.map((menuItem, _index) => {
          return (
            <LayoutMenuAdministratorItem
              key={_index}
              onClick={() => handleClickNavigate(menuItem.path)}
              className={`${location.pathname.includes(menuItem.path) ||
                (location.pathname === '/administration' && _index === 0)
                ? 'isActive'
                : ''
                }`}
            >
              {menuItem.label}
            </LayoutMenuAdministratorItem>
          )
        })}
      </LayoutMenuAdministrator>
      <div className="content-children">{children}</div>
    </LayoutStyled>
  )
}
