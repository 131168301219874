import StepOwnWebsite from './own-website'
import StepSocialMedia from './social-media'

const listStep5 = [
  {
    name: 'Own Website',
    subStep: 'OWN_WEBSITE',
    route: 'own-website',
    component: <StepOwnWebsite />,
  },
  {
    name: 'Social Media',
    subStep: 'SOCIAL_MEDIA',
    route: 'social-media',
    component: <StepSocialMedia />,
  },
]

export default listStep5
