import React from 'react'
import SignInPage from '@src/pages/SignIn'
import Chat from '@src/pages/Chat'
import { Routes, Route } from 'react-router-dom'

import Layout from '@src/components/Layout'

import NotFoundPage from '@src/pages/NotFound'
import PrivateRoute from './PrivateRoute'

import Logout from './logout'

const MainRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<SignInPage />} />
      {PrivateRoute}

      <Route path="/chat" element={<Chat />} />
      <Route
        path="/my-team"
        element={
          <Layout>
            <div>My Team</div>
          </Layout>
        }
      />
      <Route
        path="/tasks"
        element={
          <Layout>
            <div>Tasks</div>
          </Layout>
        }
      />

      <Route path="/login" element={<SignInPage />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  )
}

export default MainRoutes
