/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Button, Card, QuestionModal } from '@viswals/components'
import SubstepBigPagination from '@src/components/pagination/SubstepBigPagination'

import {
  dataContinents,
  dataMajorCourseDuration,
  dataProviderCourses,
  dataStudyMode,
} from '../common/data'

import WhatCourses from '../subcomponents/What'
import WhereCourses from '../subcomponents/Where'
import WithWhomCourses from '../subcomponents/WithWhom'
import LengthOfStudyCourses from '../subcomponents/LengthOfStudy'
import WhatsYourTitleCourses from '../subcomponents/WhatsYourTitle'

import { BoxContentCards, ContainerMajorCourses } from './MajorCourseStyles'
import useLogic from './useLogic'

const StepMajorCourses = () => {
  const {
    isModalOpen,
    coursesTitle,
    institutions,
    countries,
    fields,
    selectDelete,
    selectedIndex,
    dataFieldDentistry,
    handleOpenModel,
    setSelectedIndex,
    handleAddMajorCourse,
    handleConfirmModelDelete,
    handleCancelModelDelete,
    onSubmit,
    handleSubmit,
    register,
    watch,
    setValue,
  } = useLogic()

  const ModalDescription = (
    <div className="modalDescription">
      <span>
        You are about to delete a Major Course. This action cannot be undone.
        <br />
      </span>

      <span>Are you sure you want to proceed?</span>
    </div>
  )

  return (
    <ContainerMajorCourses>
      <h1>Major Courses</h1>
      <div className="vw-regis-headerButtons">
        <div className="vw-regis-boxLeft">
          <SubstepBigPagination
            label="Major Course"
            active={selectedIndex}
            onChangeActive={(index) => {
              setSelectedIndex(index)
            }}
            data={fields as any[]}
          />
        </div>
        <div className="vw-regis-boxRight">
          {selectDelete !== null ? (
            <div className="vw-regist-buttonDanger">
              <Button
                icon="Remove1"
                label="Delete Major Course"
                styleType="danger"
                handleClick={handleOpenModel}
              />
              {isModalOpen ? (
                <QuestionModal
                  title="Delete Major Course"
                  description={ModalDescription}
                  cancelLabel="Oops, take me back"
                  cancelFunction={handleCancelModelDelete}
                  confirmLabel={`Yes, delete Major Course ${selectedIndex + 1}`}
                  confirmFunction={handleConfirmModelDelete}
                />
              ) : null}
            </div>
          ) : null}
          <Button
            icon="Add3"
            label="Add Major Course Under Completion"
            styleType="dark"
            handleClick={() => handleAddMajorCourse(true)}
          />
          <Button
            icon="Add3"
            label="Add Major Course"
            styleType="primary"
            handleClick={() => handleAddMajorCourse(false)}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled>
          <BoxContentCards selectedIndex={selectedIndex}>
            {fields?.map((majorCourse, indexMajorCourse) => {
              const isCourseUnderCompletion =
                // @ts-ignore
                majorCourse.courseProgression &&
                // @ts-ignore
                majorCourse.courseProgression.length > 0
              return (
                <div
                  key={majorCourse.id}
                  className={`card-item item-${indexMajorCourse}`}
                >
                  <input
                    type="hidden"
                    {...register(`data.${indexMajorCourse}.destroy`, {
                      value: false,
                    })}
                  />
                  <Card readyDelete={Boolean(selectedIndex === selectDelete)}>
                    <div className="innerCardBox">
                      {/* Where Information */}
                      <WhereCourses
                        continents={dataContinents as any[]}
                        countries={countries}
                        register={register}
                        watch={watch}
                        keyIndex={indexMajorCourse}
                        setValue={setValue}
                      />
                      {/* With Whom Information */}
                      <WithWhomCourses
                        keyIndex={indexMajorCourse}
                        watch={watch}
                        register={register}
                        setValue={setValue}
                        courseProviders={dataProviderCourses}
                        institutions={institutions}
                      />
                      {/* What Course */}
                      <WhatCourses
                        keyIndex={indexMajorCourse}
                        watch={watch}
                        register={register}
                        fieldsOfDentistry={dataFieldDentistry}
                        type="major"
                        setValue={setValue}
                      />
                      {/* Length of Study Information */}
                      <LengthOfStudyCourses
                        keyIndex={indexMajorCourse}
                        watch={watch}
                        register={register}
                        setValue={setValue}
                        dataDuration={dataMajorCourseDuration}
                        studyModes={dataStudyMode}
                        underCompletion={isCourseUnderCompletion}
                      />
                      {/* What's Your Title Information */}
                      <WhatsYourTitleCourses
                        keyIndex={indexMajorCourse}
                        watch={watch}
                        register={register}
                        setValue={setValue}
                        type="major"
                        coursesTitle={coursesTitle}
                        isCourseUnderCompletion={!!isCourseUnderCompletion}
                      />
                    </div>
                  </Card>
                </div>
              )
            })}
          </BoxContentCards>
        </fieldset>
      </form>
    </ContainerMajorCourses>
  )
}

export default StepMajorCourses
