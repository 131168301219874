/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Request,
  Institution,
  InstitutionFilter,
  retrieveInstitution,
} from '@viswals/services'

type handleRetrieveInstituionsProps = {
  country: string
  courseProvider?: string
  setInstitutionsLocal?: React.Dispatch<React.SetStateAction<Institution[]>>
}

async function handleRetrieveInstituions({
  country,
  courseProvider,
  setInstitutionsLocal,
}: handleRetrieveInstituionsProps) {
  try {
    const request: Request<InstitutionFilter> = {
      fields: ['uuid', 'name', 'particular', 'countryUuid'],
      filters: {
        countryUuid: country,
        ...(courseProvider !== 'PUBLIC'
          ? {
              particular: true,
            }
          : undefined),
        ...(courseProvider === 'PUBLIC'
          ? {
              particular: false,
            }
          : undefined),
      },
    }
    const resp = await retrieveInstitution(request)
    const data: Institution[] | null = resp.data?.data ?? []
    const Datalist = data as Institution[]
    const listIds = Datalist?.map((d) => d.uuid)

    setInstitutionsLocal &&
      setInstitutionsLocal((institutions) => [
        ...institutions?.filter((f) => !listIds.includes(f.uuid)),
        ...Datalist,
      ])
  } catch (error) {
    return null
  }
}

export default handleRetrieveInstituions
