import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import {
  createUploadId,
  Request,
  retrieveUploadId,
  updateUploadId,
  UploadId,
  UploadIdFilter,
} from '@viswals/services'

import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { subStepDone, nextRoute } = useClinicianRegister()
  const [isReady, setIsReady] = useState<boolean>(false)
  const { handleSubmit, register, watch, reset, setValue } = useForm()
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const inputRefFile = useRef(null)

  const { loading, setLoading } = useLoading()

  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const request: Request<UploadIdFilter> = {
          fields: ['uuid', 'upload', 'clinicianRegisterUuid'],
          filters: {
            clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          },
        }
        const resp = await retrieveUploadId(request)
        const data: UploadId = resp.data ? resp.data.data[0] : null

        setTimeout(() => {
          const dataReset: UploadId = {
            upload: data?.upload,
            uuid: data?.uuid,
            clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          }
          reset(dataReset)
          dispatch(setNavigationActiveNext(true))
        }, 200)
      } finally {
        setLoading(false)
        setIsReady(true)
      }
    })()
  }, [registrationActive])

  useEffect(() => {
    if (next && !loading && isReady) {
      if (!confirm) {
        navigate(nextRoute)
      } else {
        handleSubmit(onSubmit)()
      }
    }
  }, [next])

  const onSubmit = async (data: UploadId) => {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)

    if (!data.uuid) {
      await createUploadId(data as Request<UploadId>)
    } else {
      await updateUploadId(data as Request<UploadId>)
    }
    subStepDone()
    navigate(nextRoute)
  }

  const handleUploadNewID = () => {
    setIsModalOpen(true)
  }

  const handleConfirmNewID = () => {
    setIsModalOpen(false)
    const { current } = inputRefFile
    ;(current || { click: () => {} }).click()
  }

  function uploadChanged() {
    if (!confirm) {
      setValue('verified', false)
      dispatch(setNavigationConfirm(true))
    }
  }

  const handleCancelModelDelete = () => {
    setIsModalOpen(false)
  }

  return {
    inputRefFile,
    isModalOpen,
    uploadChanged,
    handleUploadNewID,
    setValue,
    register,
    watch,
    handleCancelModelDelete,
    handleConfirmNewID,
  }
}

export default useLogic
