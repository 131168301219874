import styled from "styled-components";

interface UpperCalenderStyledProps {
    week?: boolean;
    month?: boolean;
    active?: boolean;
    left?: boolean;
    percentage?: any;
    itemCount?: number;
    type?: string;
    gridCols?: string;
    year?: boolean;
    hoverable?: boolean;
    marginBottom?: any;
    singleBox?: boolean;
    hoverDisable?: boolean;
    padding?: boolean;
    disable?: boolean;
    showFull?: boolean;
    bgColorDis?: boolean;
    txt_color?: string;
    shiftAdd?: boolean;
    warning?: any;
    borderColor?: any;
    color?: any;
    showBrder?: any;
    shiftConfigOpen?: any;
    shiftAdded?: any;
    border?: any;
}

const UpperCalenderGrid = styled.div`
    display: grid;
    grid-gap: 10px;
    height: 100%;
    grid-template-columns: auto;
`;

const EventCalnderBtnsContainer = styled.div`
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    @media (max-width:1400px) {
        gap: 8px;
    }
`;

const EventCalnderBtns = styled.div`
    border-radius: 5px;
    background-color: ${({ theme }) => '#222224'};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const UpperCalenderDatesMonthsContainer = styled.div<UpperCalenderStyledProps>`
    display: grid;
    grid-template-rows: 0.95fr 2.29fr 0.95fr;
    grid-gap: 10px;
    @media (max-width:1400px) {
        gap: 7px;
    }
`;

const UpperCalenderMonthWeekContainer = styled.div<UpperCalenderStyledProps>`
    display: grid;
    grid-template-columns: ${({ month, week, year }) => {
        if (month) return '12fr 2fr';
        if (week) return 'repeat(6, 1fr) 2fr repeat(6, 1fr)';
        if (year) return '12fr 2fr';
        return '';
    }};
    grid-gap: 1px;
    padding: 1px;
    border-radius: 5px;
    background-color: ${({ theme }) => '#222224'};
    box-shadow: ${({ theme }) => `0px 1px 1px 0px ${'rgba(0, 0, 0, 0.1)'}`};
`;

const UpperCalenderMonthWrapContainer = styled.div<UpperCalenderStyledProps>`
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    border-radius: 5px;
`;

const UpperCalenderMonthBar = styled.div<UpperCalenderStyledProps>`
    position: relative;
    display: grid;
    grid-template-columns: repeat(${({ itemCount }) => itemCount}, 1fr);
    border-radius: 5px;
    background-image: ${({ active, theme }) => active && `linear-gradient(90deg , ${"#202020"}, ${"#292929"})`};
    background-color: ${({ theme }) => '#222224'};
    &:hover{
        background-color: ${({ theme, hoverable = true }) => hoverable && "#2C2C2E"};
    }
`;

const UpperCalenderMonthName = styled.div`
    position: relative;
`;

const UpperCalenderMonthRedDot = styled.div<UpperCalenderStyledProps>`
    position: absolute;
    width: 4px;
    height: 4px;
    left: calc(100% + 5.7px);
    border-radius: 50%;
    background-color: ${({ theme }) => '#FF0E00'};
    top: calc(50% - 2px);
`;

const UpperCalenderDatesContainer = styled.div<UpperCalenderStyledProps>`
    box-shadow: ${({ theme, year }) => !year && `0px 1px 1px 0px ${'rgba(0, 0, 0, 0.1)'}`};
    background-color: ${({ theme, year }) => !year && '#222224'};
    border-radius: 5px;
    display: grid;
    padding: 1px;
    background-color: ${({ theme, year }) => year && '#222224'};
    box-shadow: ${({ year, theme }) => year && `0px 1px 1px 0px ${'rgba(0, 0, 0, 0.1)'}`};
    grid-template-columns: ${({ itemCount, gridCols }) => (itemCount && `repeat(${itemCount}, 1fr)`) || gridCols};
`;

const UpperCalenderDatesGroup = styled.div<UpperCalenderStyledProps>`
    position: relative;
    padding: ${({ padding }) => padding && '1px'};
    display: grid;
    border-radius: 5px;
    grid-template-columns: repeat(${({ itemCount }) => itemCount}, 1fr);
    cursor: pointer;
    background-image: ${({ active, theme }) => active && `linear-gradient(90deg , ${"#202020"}, ${"#292929"})`};
    background-color: ${({ theme, year, singleBox }) => (!year && singleBox) && '#222224'};
    border:  ${({ theme, active, year }) => year && `0.25px solid ${active ? `${"#ffffff"}60` : 'transparent'}`};
    box-shadow: ${({ theme, active, singleBox, year }) => year && (active || singleBox) && `0px 0px 2px ${singleBox ? 'rgba(0, 0, 0, 0.1)' : '#696969'}`};
    &:hover{
        background-color: ${({ theme, hoverDisable }) => !hoverDisable && "#2C2C2E"};
    }
`;

const UpperCalenderMonth = styled.div<UpperCalenderStyledProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ active, theme }) => active ? "#AEAEB2" : "#7C7C80"};
    border-radius: 5px;
    font-size: 10px;
    font-family: Oxanium;
    border: 0.25px solid ${({ theme, active }) => active ? `${"#ffffff"}60` : 'transparent'};
    box-shadow: ${({ theme, active }) => active && `0px 0px 2px ${'#696969'}`};
    background-image: ${({ active, theme }) => active && `linear-gradient(90deg , ${"#202020"}, ${"#292929"})`};
    cursor: pointer;
    height: 100%;
    &:hover{
        background-color: ${({ theme, year }) => !year && "#2C2C2E"};
    }
`;

const UpperCalenderYearAndDayBox = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 5px;
    font-size: 10px;
    font-family: Oxanium;
    color: ${({ theme }) => "#7C7C80"};
    gap: 1px;
`;

const UpperCalenderMonthDateContainer = styled.div<UpperCalenderStyledProps>`
    position: relative;
    border-radius: 5px;
    background-image: ${({ active, theme }) => active && `linear-gradient(90deg , ${"#202020"}, ${"#292929"})`};
    padding: ${({ year }) => year ? '5.5px 0px 4.5px' : '8px 3.5px'};
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    opacity: ${({ disable }) => disable && '0.4'};
    box-shadow: ${({ active, theme }) => active && `0 0 2px 0 ${"rgba(105, 105, 105, .97)"}`};
    @media (max-width:1400px) {
        padding: 5px 4px;
    }
    &:hover{
        background-color: ${({ theme, type }) => type === 'day' && "#2C2C2E"};
    }
`;

const DateNoContainer = styled.div<UpperCalenderStyledProps>`
    position: relative;
    font-size: 10px;
    font-family: ${({ year }) => !year ? 'Oxanium' : 'Nebula'};
    color: ${({ theme, txt_color }) => txt_color || "#AEAEB2"};
    margin-bottom: ${({ year }) => !year ? '3px' : '7px'};
`;

const UpperCalenderActiveRedDot = styled.div`
    position: absolute;
    bottom: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${({ theme }) => '#FF0E00'};
`;

const DayNameContainer = styled.div<UpperCalenderStyledProps>`
    font-size: 9px;
    font-family: Oxanium;
    color: ${({ theme, year }) => !year ? "#7C7C80" : "#7C7C80"};
    margin-bottom: ${({ marginBottom }) => marginBottom && '4px'};
    text-align: center;
`;

const DateAppointmentContainer = styled.div<UpperCalenderStyledProps>`
    max-width: 60px;
    height: 4px;
    border-radius: 4px;
    width: 100%;
    border: 1px solid ${({ theme, warning, borderColor, showBrder }) => !showBrder ? 'transparent' : (warning ? (warning === 1 ? '#FFB23B' : "#F72A1E") : borderColor)};
    opacity: ${({ shiftConfigOpen, shiftAdd }) => shiftConfigOpen ? (shiftAdd ? 1 : 0.2) : 1};
`;

const DateAppointmentAvailableContainer = styled.div<UpperCalenderStyledProps>`
    max-width: 58px;
    background-color: ${({ theme, shiftAdd }) => shiftAdd ? "#0C0C0D" : "#363638"};
    height: 2px;
    border-radius: 2px;
    width: 100%;
`;

const DateAppointmentAvailableFilledContainer = styled.div<UpperCalenderStyledProps>`
    background-image: linear-gradient(${({ theme }) => `90deg, ${"#1A5B25"}, ${"#35db4e"}`});;
    width: ${({ percentage }) => percentage}%;
    border-radius: 2px;
    height: 100%;
`;

const ArrowBtn = styled.div<UpperCalenderStyledProps>`
    cursor: pointer;
    width: 50px;
    display: flex;
    border-radius: 5px;
    @media (max-width:1400px) {
        width: 40px;
    }
`

const ArrowIconBtn = styled.div<UpperCalenderStyledProps>`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    &:hover{
        background-color: ${({ theme }) => "#2C2C2E"};
    }
`

const UpperCalenderYearAndDayTxt = styled.div`
    display: flex;
    cursor: pointer;
    flex: 1;
    border-radius: 5px;
`

const AddActionContainer = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 118px;
    border: 1px solid ${({ theme }) => 'rgba(124, 124, 128, 0.25)'};
    background-color: ${({ theme }) => "#2C2C2E"};
    border-radius: 5px;
    padding: 10px;
    gap: 1px;
`

const AddActionBtn = styled.div`
    font-size: 11px;
    font-family: Poppins;
    font-wright: bold;
    padding: 3.5px 10px;
    border-radius: 5px;
    cursor: pointer;
    color: ${({ theme }) => "#F4F4F4"};
    transition: background-color 0.2s ease-in-out;
    &:hover{
        background-color: ${({ theme }) => "#71716c"};
    }
`

const UpperCalendarQuickBtnWrapper = styled.div`
    border-radius: 5px;
`

const UpperDateSelection = styled.div`
    position: absolute;
    inset: 0;
    border-radius: 4px;
    border: 0.25px solid ${({ theme }) => `${"#ffffff"}60`};
    box-shadow: ${({ theme }) => `0px 0px 2px ${'#696969'}`};
`

const ShiftStatusTooltipContainer = styled.div`
    border-radius: 5px;
    overflow: hidden;
`;

const ShiftStatusHeader = styled.div`
    text-align: center;
    height: 24px;
    padding: 6px;
    color: ${({ theme }) => '#000000'};
    font-family: Nebula;
    font-size: 9px;
    font-weight: bold;
`;

const ShiftStatusBody = styled.div`
    background-color: ${({ theme }) => "#0C0C0D"};
`;

const ShiftStatusFlex = styled.div`
    padding: 14.5px 20px 20px 20px;
    display: flex;
    gap: 20px;
`;

const ShiftStatusItem = styled.div`
    width: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const ShiftStatusText = styled.div<UpperCalenderStyledProps>`
    color: ${({ color }) => color};
    font-family: Nebula;
    font-size: 9px;
    text-align: center;
    margin-bottom: 7px;
`;

const ShiftStatusProgressBar = styled.div<UpperCalenderStyledProps>`
    color: ${({ color }) => color};
    padding: 0.5px;
    border: 0.5px solid ${({ color }) => color};
    border-radius: 3px;
`;

const UpperCalenderYearShiftLocatorContainer = styled.div<UpperCalenderStyledProps>`
    display: flex;
    align-items: center;
    gap: 1px;
    height: 7px;
    @media (max-width:1400px) {
        gap: 0px;
    }
`

const UpperCalenderYearShiftLocatorDay = styled.div<UpperCalenderStyledProps>`
    width: 2px;
    height: ${({ shiftAdd }) => shiftAdd ? 7 : 3}px;
    border: 1px solid ${({ theme, warning, borderColor, showBrder }) => !showBrder ? 'transparent' : (warning ? (warning === 1 ? '#FFB23B' : "#F72A1E") : borderColor)};
    opacity: ${({ shiftConfigOpen, shiftAdd }) => shiftConfigOpen ? (shiftAdd ? 1 : 0.2) : 1};
    border-radius: 2px;
    background-color: ${({ theme, shiftAdd, shiftAdded }) => (shiftAdd || shiftAdded) ? "#363638" : 'transparent'};
`

export {
    UpperCalenderGrid,
    EventCalnderBtnsContainer,
    EventCalnderBtns,
    UpperCalenderDatesMonthsContainer,
    UpperCalenderMonthWeekContainer,
    UpperCalenderDatesContainer,
    UpperCalenderMonth,
    UpperCalenderYearAndDayBox,
    UpperCalenderMonthDateContainer,
    DateNoContainer,
    DayNameContainer,
    DateAppointmentContainer,
    DateAppointmentAvailableContainer,
    DateAppointmentAvailableFilledContainer,
    ArrowBtn,
    ArrowIconBtn,
    UpperCalenderYearAndDayTxt,
    UpperCalenderActiveRedDot,
    UpperCalenderDatesGroup,
    UpperCalenderMonthBar,
    UpperCalenderMonthName,
    UpperCalenderMonthRedDot,
    AddActionContainer,
    AddActionBtn,
    UpperCalenderMonthWrapContainer,
    UpperCalendarQuickBtnWrapper,
    UpperDateSelection,
    ShiftStatusTooltipContainer,
    ShiftStatusHeader,
    ShiftStatusBody,
    ShiftStatusFlex,
    ShiftStatusItem,
    ShiftStatusText,
    ShiftStatusProgressBar,
    UpperCalenderYearShiftLocatorContainer,
    UpperCalenderYearShiftLocatorDay
}