import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { SelectedOptionsProps } from '@viswals/components'
import {
  CountryOptionsContainer,
  CountryOptionsContent,
} from './CountryOptionsStyle'

const CountryOptions = () => {
  const { registration, switchCountry } = useClinicianRegister()

  const options = registration?.map((c) => ({
    label: c.country.name,
    value: c.countryRegistration,
    isoCode: c.country.isoCode,
  })) as SelectedOptionsProps[]

  return (
    <CountryOptionsContainer>
      Country
      <CountryOptionsContent
        onChange={({ target }) => switchCountry(target?.value)}
      >
        {options.map((option) => (
          <option value={option.value}>{option.isoCode}</option>
        ))}
      </CountryOptionsContent>
    </CountryOptionsContainer>
  )
}

export default CountryOptions
