/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReducersProps } from '@src/types/reducers'
import { useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { stepsListClinician } from '@src/core/clinician'
import { StepProps, SubStepVerificationProps } from '@viswals/components'
import {
  ClinicianRoutesSubStepsProps,
  ClinicianStepsProps,
} from '@src/types/stepsClinician'
import clinicianRoutes from '@src/components/steps/clinician'
import { useNavigate, useParams } from 'react-router-dom'
import { RegistrationProp } from '@src/store/reducers/clinician/props'
import {
  getCountries,
  getFields,
  getLanguages,
  getRegulatoryBodies,
  mergeRegistration,
} from '@src/utils/getDatas'
import {
  setClinicianStatus,
  setClinicianSteps,
  setClinician,
  setRegistrationActive,
  setRegistration,
} from '@src/store/reducers/clinician/actions'
import {
  Request,
  ClinicianRegisterStatus,
  skipStatus,
  ClinicianRegisterFilter,
  retrieveClinicianRegister,
  verifyStatus,
  ClinicianRegisterVerifyStatus,
} from '@viswals/services'

type InitSubStepsProps = {
  subSteps: ClinicianRoutesSubStepsProps[]
  stepIndex: number
  receivedStatus?: ClinicianRegisterStatus[]
}

type getStatusProps = {
  subStep: ClinicianRoutesSubStepsProps
  receivedStatus?: ClinicianRegisterStatus[]
  countryRegistration?: string
}

const useClinicianRegister = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const navigate = useNavigate()

  const { clinician: reducerClinician, navigation } = useSelector(
    (state: ReducersProps) => state
  )
  const { clinician, status, registration, registrationActive, steps } =
    reducerClinician

  const stepActiveIndex = params?.step
    ? Number(String(params?.step).replace('step', '')) - 1
    : 0

  const stepObj = clinicianRoutes[stepActiveIndex]

  function percentStep(step: any, receivedStatus?: ClinicianRegisterStatus[]) {
    const statusList = receivedStatus ?? status
    const totalSub = step?.subStepsHeader?.length ?? 0
    const totalDone =
      statusList?.filter(
        (f) => f.step === step.stepRoute && f.verified && !f.hasError
      )?.length ?? 0

    return Math.ceil((totalDone / totalSub) * 100)
  }

  function statusStep(step: any, receivedStatus?: ClinicianRegisterStatus[]) {
    const statusList = receivedStatus ?? status

    const error = !!statusList?.filter(
      (f) => f.step === step.stepRoute && f.hasError
    )?.length

    if (error) return 'not-correct'

    return 'normal'
  }

  function getStatus({
    subStep,
    receivedStatus,
    countryRegistration,
  }: getStatusProps) {
    const statusList = receivedStatus ?? status
    const error = !!statusList?.filter(
      (f) =>
        f.subStep === subStep.subStep &&
        f.hasError &&
        (!f.countryRegistration ||
          f.countryRegistration === countryRegistration)
    )?.length
    if (error) return 'not-correct'
    const verify = error
      ? false
      : !!statusList?.filter(
          (f) =>
            f.subStep === subStep.subStep &&
            f.verified &&
            (!f.countryRegistration ||
              f.countryRegistration === countryRegistration)
        )?.length
    if (verify) return 'verify'
    const started = error
      ? false
      : !!statusList?.filter(
          (f) =>
            f.subStep === subStep.subStep &&
            f.started &&
            (!f.countryRegistration ||
              f.countryRegistration === countryRegistration)
        )?.length
    if (started) return 'normal'

    return ''
  }

  function initSubSteps({
    subSteps,
    stepIndex,
    receivedStatus,
  }: InitSubStepsProps) {
    const newArray = []
    let x = 0
    for (const subStep of subSteps) {
      if (!wordWideList.includes(stepIndex)) {
        for (const reg of registration) {
          const countryRegistration = reg?.countryRegistration
          newArray.push({
            countryRegistration,
            id: x,
            label: subStep.name,
            status: getStatus({ subStep, receivedStatus, countryRegistration }),
            percent: 0,
            cards: [],
          })
          x += 1
        }
      } else {
        newArray.push({
          id: x,
          label: subStep.name,
          status: getStatus({ subStep, receivedStatus }),
          percent: 0,
          cards: [],
        })
        x += 1
      }
    }
    return newArray
  }

  function initSteps(receivedStatus?: ClinicianRegisterStatus[]) {
    const newList = clinicianRoutes
      .map((step, stepIndex) => {
        return {
          stepRoute: step.step,
          id: stepIndex + 1,
          label: step.title,
          route: `/step${stepIndex + 1}`,
          percent: 0,
          status: '',
          subStepsHeader: initSubSteps({
            subSteps: step.subSteps,
            stepIndex,
            receivedStatus,
          }),
        }
      })
      .map((step) => {
        const percent = percentStep(step, receivedStatus)
        const sStatus = statusStep(step, receivedStatus)
        return {
          ...step,
          percent,
          status: percent === 100 ? 'verify' : sStatus,
        }
      })

    updateSteps(newList as StepProps[])
  }

  function updateSteps(newSteps: StepProps[]) {
    dispatch(setClinicianSteps(newSteps))
  }

  const stepActiveCallback = useCallback(() => {
    return steps?.find((_, index) => index === stepActiveIndex)
  }, [steps, stepActiveIndex])

  const stepActive = stepActiveCallback()
  const wordWideList = [1, 2, 4, 5]
  const worldWide = Boolean(wordWideList.includes(stepActiveIndex))

  function mapList(convertList: ClinicianStepsProps[]) {
    return (
      convertList?.map((s, index) => ({
        step: `Step ${index + 1}`,
        labelProgress: 'Step Progress',
        title: s.label,
        route: s.route,
        percent: s.percent,
        error: Boolean(s.status === 'not-correct'),
      })) ?? []
    )
  }

  function filterSubStep() {
    const newList = steps?.map((step, stepIndex) => ({
      ...step,
      subStepsHeader: step.subStepsHeader?.filter((sub) => {
        if (
          wordWideList.includes(stepIndex) ||
          (sub as any).countryRegistration ===
            registrationActive.countryRegistration
        ) {
          return sub
        }

        return false
      }),
    }))

    return newList as unknown as StepProps[]
  }

  const headerInfo = useCallback(() => {
    const stepsList = filterSubStep()

    const totalPercent = !stepsList?.length
      ? 0
      : Math.ceil(
          stepsList?.map((m) => Number(m?.percent))?.reduce((a, b) => a + b) /
            stepsList?.length
        )
    return { totalPercent, stepsList }
  }, [steps, status, registrationActive])

  const subStepListCallback = useCallback(() => {
    const newList = (stepActive?.subStepsHeader ?? [])
      ?.filter((f: any) => {
        if (
          worldWide ||
          f.countryRegistration === registrationActive.countryRegistration
        ) {
          return f
        }

        return false
      })
      ?.map((s, i) => ({
        label: s.label,
        status: s.status,
        route: stepObj?.subSteps[i]?.route,
        countErros: 0,
      }))

    return newList as SubStepVerificationProps[]
  }, [stepActive, registrationActive])

  const subSteps = stepObj?.subSteps ?? []
  const subStepList = subStepListCallback()

  const subStepActive =
    subStepList?.find(
      (f) =>
        String(f.route).toLowerCase() === String(params?.subStep).toLowerCase()
    ) ?? undefined

  const SubStepActiveIndex =
    subStepList.findIndex(
      (f) =>
        String(f.route).toLowerCase() === String(params?.subStep).toLowerCase()
    ) ?? 0

  const subStepTotal = subStepList.length ?? 0

  function submitSkip() {
    const countryRegistration = worldWide
      ? undefined
      : registrationActive?.countryRegistration
    const stepAc = clinicianRoutes[stepActiveIndex]
    const step = stepAc.step ?? ''
    const subStep = stepAc.subSteps[SubStepActiveIndex].subStep ?? ''
    const request = {
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      countryRegistration,
      step,
      subStep,
    }
    skipStatus(request as Request<ClinicianRegisterStatus>)
  }

  function subStepDone() {
    if (navigation?.skip) {
      submitSkip()
    }
    const countryRegistration = worldWide
      ? undefined
      : registrationActive?.countryRegistration
    const stepAc = clinicianRoutes[stepActiveIndex]
    const step = stepAc.step ?? ''
    const subStep = stepAc.subSteps[SubStepActiveIndex].subStep ?? ''
    const check = status?.find(
      (f) =>
        f.uuid &&
        f.step === step &&
        f.subStep === subStep &&
        f.countryRegistration === countryRegistration
    )
    if (!check) {
      const newObject = {
        uuid: 'automatic',
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        step,
        subStep,
        countryRegistration,
        started: true,
      }
      const newStatus = [...(status ?? []), newObject]
      dispatch(setClinicianStatus(newStatus))
      initSteps(newStatus)
    }
  }

  function subStepVerify() {
    const countryRegistration = worldWide
      ? undefined
      : registrationActive?.countryRegistration
    const stepAc = clinicianRoutes[stepActiveIndex]
    const step = stepAc.step ?? ''
    const subStep = stepAc.subSteps[SubStepActiveIndex].subStep ?? ''
    const check = status?.find(
      (f) =>
        f.uuid &&
        f.step === step &&
        f.subStep === subStep &&
        f.countryRegistration === countryRegistration
    )

    let newObject = {}
    if (!check) {
      newObject = {
        uuid: 'automatic',
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        step,
        subStep,
        countryRegistration,
        verified: true,
      }
    } else {
      newObject = { ...check, verified: true }
    }
    const newStatus = [...(status ?? []), newObject]
    dispatch(setClinicianStatus(newStatus))
    initSteps(newStatus)
  }

  const checkFinished = useCallback(() => {
    let ok = true
    if (registration?.length) {
      for (const register of registration) {
        const convertList = stepsListClinician(steps, register)
        const item = mapList(convertList).find((f) => f.route === 'step4')
        if (Number(item?.percent) < 60) {
          ok = false
        }
      }
    }
    return ok
  }, [steps])

  function getPrevRoute() {
    let { step, subStep } = params

    const tempSubStep = subStepList[SubStepActiveIndex - 1] ?? null
    if (tempSubStep) {
      subStep = tempSubStep.route
    } else {
      const tempStep = clinicianRoutes[stepActiveIndex - 1] ?? null

      if (!tempStep) {
        return { step: null, subStep: null }
      }
      step = `step${stepActiveIndex}`
      const lTotal = tempStep.subSteps?.length - 1
      subStep = tempStep.subSteps[lTotal]?.route
    }

    return { step, subStep }
  }

  function getNextRoute() {
    let { step, subStep } = params

    if (subStepTotal === SubStepActiveIndex + 1) {
      const nextStep =
        clinicianRoutes.find((_, index) => index === stepActiveIndex + 1) ??
        null
      if (!nextStep) {
        return { step: null, subStep: null }
      }
      step = `step${stepActiveIndex + 2}`
      subStep = nextStep?.subSteps[0].route
    } else {
      subStep = subStepList[SubStepActiveIndex + 1]?.route
    }
    return { step, subStep }
  }

  const prevNextCallback = useCallback(() => {
    const next = `/clinician-registration/${getNextRoute().step}/${
      getNextRoute().subStep
    }/${params.country}`
    const prev = `/clinician-registration/${getPrevRoute().step}/${
      getPrevRoute().subStep
    }/${params.country}`
    return { prev, next }
  }, [params])

  function getCountryActiveIndex() {
    if (registration?.length && params.country) {
      const index = registration.findIndex(
        (f: RegistrationProp) =>
          String(f.country.isoCode).toLowerCase() ===
          String(params.country).toLowerCase()
      )
      if (index < 0) {
        // window.location.href = '/registration'
      }
      return index
    }
    return 0
  }

  async function handleVerified() {
    const request = {
      clinicianRegisterUuid: clinician.uuid,
      subStep: subObj?.subStep,
      countryRegistration: worldWide
        ? undefined
        : registrationActive.countryRegistration,
    }
    await verifyStatus(request as Request<ClinicianRegisterVerifyStatus>)
    subStepVerify()
  }

  // checar stage S1 e state = INITIAL

  async function getClinicianRegister() {
    const uuid = localStorage.getItem('clinician_register_uuid') as string
    const request: Request<ClinicianRegisterFilter> = {
      fields: [
        'uuid',
        'email',
        'status',
        'progress',
        'stage',
        'state',
        'counterValidation',
        'enabled',
        'createdAt',
        'updatedAt',
      ],
      relations: {
        status: {
          fields: [
            'uuid',
            'clinicianRegisterUuid',
            'step',
            'subStep',
            'countryRegistration',
            'started',
            'verified',
            'hasError',
            'createdAt',
          ],
        },
        registration: {
          fields: ['uuid', 'clinicianRegisterUuid', 'countryRegistration'],
        },
      },
      filters: {
        uuid,
      },
    }

    try {
      const resp = await retrieveClinicianRegister(request)
      const c = resp.data.data[0]

      if (!c) {
        window.location.href = '/auth/signin'
        return false
      }
      const countries = await getCountries()
      await getRegulatoryBodies()
      await getFields()
      await getLanguages()

      const registrations = await mergeRegistration(c?.registrations, countries)
      dispatch(setRegistration(registrations))
      dispatch(setClinician(c))
      dispatch(setClinicianStatus(c.status))

      c.registrations = registrations

      return c
    } catch (e) {
      console.error('erro in initialDatas', e)
      // window.location.href = '/auth/signin'
      return false
    }
  }

  const subObj = stepObj?.subSteps
    ? stepObj.subSteps[SubStepActiveIndex]
    : undefined

  const countryActiveIndex = getCountryActiveIndex()
  const countryActive = registration[countryActiveIndex]
  const nextRoute = prevNextCallback().next
  const prevRoute = prevNextCallback().prev
  const verified = Boolean(subStepActive?.status === 'verify')

  function updateRegistrations(registrations?: RegistrationProp[]) {
    const registrationList = registrations ?? registration

    if (registrationList?.length) {
      const rActive = registrationList[countryActiveIndex]
      const check = registrationActive?.uuid !== rActive.uuid
      if (check) {
        const active = {
          ...rActive,
        }
        updateRegistrationActive(active)
      }
    }
  }

  function updateRegistrationActive(active: RegistrationProp) {
    dispatch(setRegistrationActive(active))
  }

  function switchCountry(countryRegistration: string) {
    const find = registration?.find(
      (f) => f.countryRegistration === countryRegistration
    )
    if (find) {
      updateRegistrationActive(find)
      const country = find?.country?.isoCode?.toLowerCase()
      const route = `/clinician-registration/${params.step}/${params.subStep}/${country}`
      navigate(route)
    }
  }

  return {
    steps,
    clinician,
    registration,
    registrationActive,
    stepsListClinician,
    headerInfo,
    status,
    subSteps,
    subStepList,
    subStepDone,
    updateSteps,
    subObj,
    checkFinished,
    initSteps,
    prevRoute,
    nextRoute,
    worldWide,
    stepActive,
    stepActiveIndex,
    subStepActive,
    SubStepActiveIndex,
    countryActiveIndex,
    countryActive,
    verified,
    handleVerified,
    getClinicianRegister,
    updateRegistrations,
    switchCountry,
  }
}

export default useClinicianRegister
