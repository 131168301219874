import { ListNames } from '@viswals/components-platform'
import { TooltipProps } from 'rc-tooltip/lib/Tooltip'
import { StateProps } from '@src/types/maps'
import { TooltipContent } from './style'

export interface Total {
  iconMain: ListNames
  iconMainColor: string
  amount: number
  iconPercent: ListNames
  iconPercentColor: string
  percent: number
  tooltip: TooltipProps & React.RefAttributes<unknown>
}

export const totals: Total[] = [
  {
    iconMain: 'Pool',
    iconMainColor: '#8E8E93',
    amount: 300,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
  {
    iconMain: 'AnotherOne',
    iconMainColor: '#32d74b',
    amount: 600,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
  {
    iconMain: 'AnotherOne',
    iconMainColor: '#C432D7',
    amount: 300,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
  {
    iconMain: 'Pool',
    iconMainColor: '#32d74b',
    amount: 900,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
  {
    iconMain: 'Computer',
    iconMainColor: '#32d74b',
    amount: 110,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
  {
    iconMain: 'Validated',
    iconMainColor: '#FF9F0A',
    amount: 420,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
  {
    iconMain: 'Validated',
    iconMainColor: '#FF453A',
    amount: 640,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
  {
    iconMain: 'Validated',
    iconMainColor: '#64D2FF',
    amount: 860,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
  {
    iconMain: 'Award1',
    iconMainColor: '#32d74b',
    amount: 860,
    iconPercent: 'ArrowUp',
    iconPercentColor: '#32d74b',
    percent: 52,
    tooltip: {
      placement: 'top',
      trigger: 'hover',
      destroyTooltipOnHide: true,
      align: {
        offset: [0, 2],
      },
      overlay: <TooltipContent>Past Entries</TooltipContent>,
    },
  },
]

interface ColumnData {
  description?: string
  icon?: ListNames
  arrow?: 'UP' | 'DOWN'
  iconColor?: string
}

interface LineData {
  columnData: ColumnData[]
  theme?: string
  disabled?: boolean
}

export interface Partial {
  headerData: LineData
  contentData: LineData[]
  footerData: LineData
}

export const partials: Partial[] = [
  {
    headerData: {
      columnData: [
        {
          icon: 'Person6',
          iconColor: '#8973E6',
        },
        {
          icon: 'Pool',
          iconColor: '#8E8E93',
        },
        {
          icon: 'AnotherOne',
        },
        {
          icon: 'Pool',
        },
        {
          icon: 'Computer',
        },
        {
          icon: 'Validated',
          iconColor: '#FF9F0A',
        },
        {
          icon: 'Validated',
          iconColor: '#FF453A',
        },
        {
          icon: 'Validated',
          iconColor: '#64D2FF',
        },
      ],
    },
    contentData: [
      {
        theme: '#C1EDE8',
        columnData: [
          {
            description: 'S1',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#C1EDE8',
        columnData: [
          {
            description: 'S1R',
          },
          {
            description: '1000',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#83DBD1',
        columnData: [
          {
            description: 'S2',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#83DBD1',
        columnData: [
          {
            description: 'S2R',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#07B7A3',
        columnData: [
          {
            description: 'UPD',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#07B7A3',
        columnData: [
          {
            description: 'UPDR',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
    ],
    footerData: {
      theme: '#AEAEB2',
      columnData: [
        {
          description: 'TOT.',
        },
        {
          description: '1000',
          arrow: 'UP',
        },
        {
          description: '2000',
          arrow: 'UP',
        },
        {
          description: '300',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
      ],
    },
  },
  {
    headerData: {
      columnData: [
        {
          icon: 'Dentist1',
          iconColor: '#07B7A3',
        },
        {
          icon: 'Pool',
          iconColor: '#8E8E93',
        },
        {
          icon: 'AnotherOne',
        },
        {
          icon: 'Pool',
        },
        {
          icon: 'Computer',
        },
        {
          icon: 'Validated',
          iconColor: '#FF9F0A',
        },
        {
          icon: 'Validated',
          iconColor: '#FF453A',
        },
        {
          icon: 'Validated',
          iconColor: '#64D2FF',
        },
      ],
    },
    contentData: [
      {
        theme: '#FFCFDF',
        columnData: [
          {
            description: 'S1',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#FFCFDF',
        columnData: [
          {
            description: 'S1R',
          },
          {
            description: '1000',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#FF8CB3',
        columnData: [
          {
            description: 'S2',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#FF8CB3',
        columnData: [
          {
            description: 'S2R',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#FF4081',
        columnData: [
          {
            description: 'UPD',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#FF4081',
        columnData: [
          {
            description: 'UPDR',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
    ],
    footerData: {
      theme: '#AEAEB2',
      columnData: [
        {
          description: 'TOT.',
        },
        {
          description: '1000',
          arrow: 'UP',
        },
        {
          description: '2000',
          arrow: 'UP',
        },
        {
          description: '300',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
      ],
    },
  },
  {
    headerData: {
      columnData: [
        {
          icon: 'Clinic',
          iconColor: '#FF4081',
        },
        {
          icon: 'Pool',
          iconColor: '#8E8E93',
        },
        {
          icon: 'AnotherOne',
        },
        {
          icon: 'Pool',
        },
        {
          icon: 'Computer',
        },
        {
          icon: 'Validated',
          iconColor: '#FF9F0A',
        },
        {
          icon: 'Validated',
          iconColor: '#FF453A',
        },
        {
          icon: 'Validated',
          iconColor: '#64D2FF',
        },
      ],
    },
    contentData: [
      {
        theme: '#D6CEF6',
        columnData: [
          {
            description: 'S1',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#D6CEF6',
        columnData: [
          {
            description: 'S1R',
          },
          {
            description: '1000',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#B8ABF0',
        disabled: true,
        columnData: [
          {
            description: 'S2',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
        ],
      },
      {
        theme: '#B8ABF0',
        disabled: true,
        columnData: [
          {
            description: 'S2R',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
          {
            description: '-',
          },
        ],
      },
      {
        theme: '#8973E6',
        columnData: [
          {
            description: 'UPD',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
      {
        theme: '#8973E6',
        columnData: [
          {
            description: 'UPDR',
          },
          {
            description: '100',
            arrow: 'UP',
          },
          {
            description: '200',
            arrow: 'UP',
          },
          {
            description: '300',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
          {
            description: '65',
            arrow: 'UP',
          },
        ],
      },
    ],
    footerData: {
      theme: '#AEAEB2',
      columnData: [
        {
          description: 'TOT.',
        },
        {
          description: '1000',
          arrow: 'UP',
        },
        {
          description: '2000',
          arrow: 'UP',
        },
        {
          description: '300',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
        {
          description: '65',
          arrow: 'UP',
        },
      ],
    },
  },
]

interface GroupAmount {
  icon: ListNames
  amount: number
}

export interface Priority {
  themeColor: string
  title: string
  amount: number
  groupsAmount: GroupAmount[]
  state: StateProps
}

export const priorities: Priority[] = [
  {
    themeColor: '#32D74B',
    title: 'Low Priority',
    state: 'LOW_PRIORITY',
    amount: 280,
    groupsAmount: [
      {
        icon: 'Person6',
        amount: 12,
      },
      {
        icon: 'Dentist1',
        amount: 28,
      },
      {
        icon: 'Clinic',
        amount: 10,
      },
    ],
  },
  {
    themeColor: '#FF9F0A',
    title: 'Medium Priority',
    state: 'MEDIUM_PRIORITY',
    amount: 280,
    groupsAmount: [
      {
        icon: 'Person6',
        amount: 12,
      },
      {
        icon: 'Dentist1',
        amount: 28,
      },
      {
        icon: 'Clinic',
        amount: 10,
      },
    ],
  },
  {
    themeColor: '#EC5545',
    title: 'High Priority',
    state: 'HIGH_PRIORITY',
    amount: 280,
    groupsAmount: [
      {
        icon: 'Person6',
        amount: 12,
      },
      {
        icon: 'Dentist1',
        amount: 28,
      },
      {
        icon: 'Clinic',
        amount: 10,
      },
    ],
  },
  {
    themeColor: '#80D0FA',
    title: 'In Progress',
    state: 'IN_PROGRESS',
    amount: 280,
    groupsAmount: [
      {
        icon: 'Person6',
        amount: 12,
      },
      {
        icon: 'Dentist1',
        amount: 28,
      },
      {
        icon: 'Clinic',
        amount: 10,
      },
    ],
  },
  {
    themeColor: '#EE8FEB',
    title: 'In review',
    state: 'IN_REVIEW',
    amount: 280,
    groupsAmount: [
      {
        icon: 'Person6',
        amount: 12,
      },
      {
        icon: 'Dentist1',
        amount: 28,
      },
      {
        icon: 'Clinic',
        amount: 10,
      },
    ],
  },
  {
    themeColor: '#6BD35F',
    title: 'Completed',
    state: 'COMPLETED',
    amount: 280,
    groupsAmount: [
      {
        icon: 'Person6',
        amount: 12,
      },
      {
        icon: 'Dentist1',
        amount: 28,
      },
      {
        icon: 'Clinic',
        amount: 10,
      },
    ],
  },
]
