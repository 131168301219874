import React from 'react'
import { TotalsItem, TotalsItemProps } from './TotalsItem/TotalsItem'
import { TotalsContainer, TotalsTitle, TotalsContent } from './style'

export interface GlobalViewTotalsProps {
  title: string
  items: TotalsItemProps[];
  color?: string;
}

export const GlobalViewTotalGroup = ({
  title,
  items,
  color
}: any) => {
  return (
    <TotalsContainer>
      <TotalsTitle color={color}>{title}</TotalsTitle>
      <TotalsContent>
        {items?.map((item: any, index: number) => (
          <TotalsItem key={index} {...item} />
        ))}
      </TotalsContent>
    </TotalsContainer>
  )
}
