import { CSSProperties } from 'react'
import { PlatformType } from '@viswals/services'
import { Icon, ListNames } from '@viswals/components-platform'

import ContainerGridGlobalAccounts from './styles'

export type GridGlobalAccountsProps = {
  platform: PlatformType
  signUps: number
  acquired: number
  dropouts: number
  total: number
  filtersPlatform?: any[]
  filtersState?: any[]
}

export type GridIconGlobalAccountsProps = {
  icon: ListNames
  label: string
}

export default function GridGlobalAccounts({
  platform,
  signUps,
  acquired,
  dropouts,
  total,
  filtersPlatform,
  filtersState,
}: GridGlobalAccountsProps) {
  const getPlarformColor = (): CSSProperties => {
    if (platform === 'PATIENT') {
      return {
        color: '#2C7DFF',
        backgroundColor: '#1E2A40',
      }
    }
    if (platform === 'CLINICIAN') {
      return {
        color: '#36BA91',
        backgroundColor: '#1F342F',
      }
    }
    if (platform === 'CLINIC') {
      return {
        color: '#B665D5',
        backgroundColor: '#332739',
      }
    }
    return {
      color: '#E3E3E3',
      backgroundColor: '#1C1C1E',
    }
  }

  const getPlarformInfo = (): GridIconGlobalAccountsProps => {
    if (platform === 'PATIENT') {
      return {
        label: 'viswals app',
        icon: 'ViswalsApp',
      }
    }
    if (platform === 'CLINICIAN') {
      return {
        label: 'viswals clinician',
        icon: 'Dentist1',
      }
    }
    if (platform === 'CLINIC') {
      return {
        label: 'viswals clinic',
        icon: 'ViswalsClinics',
      }
    }
    return {
      label: 'total',
      icon: 'ViswalsClientPageTotal',
    }
  }

  return (
    <ContainerGridGlobalAccounts
      color={getPlarformColor().color as string}
      backgroundColor={getPlarformColor().backgroundColor as string}
      className={`container-grid-global ${filtersPlatform?.every((plat) => plat === undefined) ||
          platform === 'ALL'
          ? ''
          : filtersPlatform?.includes(platform)
            ? ''
            : 'DISABLED'
        }`}
    >
      <div className="header-grid">
        <div className="header-grid-left">
          <Icon name={getPlarformInfo().icon} />
          <span>{getPlarformInfo().label}</span>
        </div>
        <div className="content-counter">{total}</div>
      </div>
      <div className="body-grid">
        <div
          className={`item-body-grid grid-signups ${filtersState?.every((stat) => stat === undefined) ||
              platform === 'ALL'
              ? ''
              : filtersState?.includes('SIGN_UP')
                ? 'NORMAL'
                : 'DISABLED'
            }`}
        >
          <p>sign ups</p>
          <span>{signUps}</span>
        </div>
        <div
          className={`item-body-grid grid-acquired ${filtersState?.every((stat) => stat === undefined) ||
              platform === 'ALL'
              ? ''
              : filtersState?.includes('ACQUIRED')
                ? 'NORMAL'
                : 'DISABLED'
            }`}
        >
          <p>acquired</p>
          <span>{acquired}</span>
        </div>
        <div
          className={`item-body-grid grid-dropouts ${filtersState?.every((stat) => stat === undefined) ||
              platform === 'ALL'
              ? ''
              : filtersState?.includes('DROPOUT')
                ? 'NORMAL'
                : 'DISABLED'
            }`}
        >
          <p>dropouts</p>
          <span>{dropouts}</span>
        </div>
      </div>
    </ContainerGridGlobalAccounts>
  )
}
