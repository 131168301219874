import { NavigationProps, ActionProps } from './props'

const INITIAL_STATE: NavigationProps = {
  confirm: false,
  activePrev: true,
  activeNext: false,
  next: 0,
  prev: 0,
  loading: true,
  skip: false,
}

export default function reducer(state = INITIAL_STATE, action: ActionProps) {
  if (action.type === 'SET_NAVIGATION_RESET') {
    return {
      ...state,
      confirm: false,
      activePrev: true,
      activeNext: true,
      next: 0,
      prev: 0,
      skip: false,
    }
  }
  if (action.type === 'SET_NAVIGATION_LOADING') {
    return {
      ...state,
      loading: action.loading,
    }
  }

  if (action.type === 'SET_NAVIGATION_CONFIRM') {
    return {
      ...state,
      confirm: action.confirm,
    }
  }
  if (action.type === 'SET_NAVIGATION_SKIP') {
    return {
      ...state,
      skip: action.skip,
    }
  }
  if (action.type === 'SET_NAVIGATION_ACTIVE_PREV') {
    return {
      ...state,
      activePrev: action.activePrev,
    }
  }
  if (action.type === 'SET_NAVIGATION_ACTIVE_NEXT') {
    return {
      ...state,
      activeNext: action.activeNext,
    }
  }

  if (action.type === 'SET_NAVIGATION_PREV') {
    return {
      ...state,
      prev: action.prev,
    }
  }
  if (action.type === 'SET_NAVIGATION_NEXT') {
    return {
      ...state,
      next: action.next,
    }
  }

  return state
}
