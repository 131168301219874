import { Icon } from '@viswals/components'
import { FishedBox, InfoBox, FishedPhotoBox } from './style'

type FishedProps = {
  color: string
  image: string
}

const Fished = ({ color, image }: FishedProps) => {
  return (
    <FishedBox color={color}>
      <InfoBox color={color} top={3} left={14.7}>
        <div className="circle mb-36" />
        <b className="title-color">FROZEN WAITING</b>
        <span>24h</span>
      </InfoBox>
      <InfoBox color={color} top={-0.5}>
        <div className="circle mb-17" />
        <b className="title-color">STAGE</b>
        <div className="stage">
          <Icon name="Person6" />
          <span>S1</span>
        </div>
      </InfoBox>
      <InfoBox color={color} top={3} left={45.2}>
        <div className="circle mb-36" />
        <b className="title-color">OPEN WAITING</b>
        <span>1h</span>
      </InfoBox>
      <FishedPhotoBox color={color}>
        <figure>
          <img src={image} alt="Verification" />
        </figure>
      </FishedPhotoBox>
    </FishedBox>
  )
}

export default Fished
