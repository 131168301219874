import { ActionProps } from './props'

const INITIAL_STATE = {
  steps: [],
  status: [],
  clinician: {},
  registration: [],
  registrationActive: {},
}

export default function reducer(state = INITIAL_STATE, action: ActionProps) {
  if (action.type === 'SET_CLINICIAN_STEPS') {
    return {
      ...state,
      steps: action.steps,
    }
  }
  if (action.type === 'SET_CLINICIAN_STATUS') {
    return {
      ...state,
      status: action.status,
    }
  }
  if (action.type === 'SET_CLINICIAN') {
    return {
      ...state,
      clinician: action.clinician,
    }
  }
  if (action.type === 'SET_REGISTRATION') {
    return {
      ...state,
      registration: action.registration,
    }
  }
  if (action.type === 'SET_REGISTRATION_ACTIVE') {
    return {
      ...state,
      registrationActive: action.registrationActive,
    }
  }

  return state
}
