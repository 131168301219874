/* eslint-disable @typescript-eslint/no-explicit-any */
import { SelectedOptionsProps } from '@viswals/components'

type transformToSelectOptionsProps = {
  array: any[]
  keyLabel: string
  keyValue: string
  keyIcon?: string
}

const transformToSelectOptions = ({
  array,
  keyIcon,
  keyLabel,
  keyValue,
}: transformToSelectOptionsProps): SelectedOptionsProps[] => {
  const emptyArray: SelectedOptionsProps[] = []
  array.map((item) => {
    emptyArray.push({
      label: item[keyLabel],
      value: item[keyValue],
      ...(keyIcon
        ? {
            icon: item[keyIcon],
          }
        : {}),
    })
    return null
  })
  return emptyArray
}

export default transformToSelectOptions
