import { ButtonSend, InviteTokenBox } from './style'

type ModalIinviteTokenProps = {
  code: string
  onClose: () => void
}

const ModalIinviteToken = ({ code, onClose }: ModalIinviteTokenProps) => {
  const items = code.split('')

  return (
    <InviteTokenBox>
      <h4>CC App Access Password:</h4>
      <div className="box-code">
        <h5>Exclusive access password code generated</h5>
        <ul>
          {items.map((item) => (
            <li key={`item-${item}`}>{item}</li>
          ))}
        </ul>
      </div>
      <div className="btn-action">
        <ButtonSend type="button" onClick={() => onClose()}>
          done
        </ButtonSend>
      </div>
    </InviteTokenBox>
  )
}

export default ModalIinviteToken
