/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import {
  OralHealthProblem,
  Request,
  updateOralHealthProblem,
} from '@viswals/services'
import {
  Button,
  Input,
  Select,
  SelectedOptionsProps,
  Toggle,
} from '@viswals/components'

import { StyledContentOralHealthProblem } from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'

export default function FormUpdateOralHealthProblem() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, reset, control } = useForm()

  const listGroups = [
    { label: 'Face', value: 'FACE' },
    { label: 'Jaws', value: 'JAWS' },
    {
      label: 'Teeth',
      value: 'TEETH',
    },
    { label: 'Gums', value: 'GUMS' },
  ] as SelectedOptionsProps[]

  useEffect(() => {
    ;(async () => {
      const getOralHealthProblemToSave: OralHealthProblem = JSON.parse(
        window.localStorage.getItem('oralHealthProblemEdit') as string
      )
      reset(getOralHealthProblemToSave)
    })()
  }, [])

  async function onSubmit(data: any) {
    const dataToSend: OralHealthProblem = {
      uuid: watch('uuid'),
      title: data.title,
      groupName: data.groupName,
      enabled: data.enabled,
    }

    try {
      setIsError('')
      await updateOralHealthProblem(dataToSend as Request<OralHealthProblem>)
      window.localStorage.removeItem('oralHealthProblemEdit')
      navigate('/administration/common/oral-health-problem')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentOralHealthProblem className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Edit Oral Health Problem"
            description="Here you can edit Oral Health Problem"
          />
          <Input label="Title" formRegister={{ ...register('title') }} />

          <Select
            label="Group"
            labelTitle="Groupies"
            options={listGroups}
            formRegister={{ ...register('groupName'), watch }}
          />
          <div className="content-toggle-switch">
            <Toggle isBig name="enabled" control={control} />
            <div className="box-preview">Enable Oral Health Problem</div>
          </div>
          <Button label="Save" type="submit" />
          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() =>
              navigate('/administration/common/oral-health-problem')
            }
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentOralHealthProblem>
  )
}
