import styled from 'styled-components'

export const TotalsContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  font-family: 'Nebula';
  font-size: 1rem;
  font-weight: bold;
  color: #aeaeb2;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
`

export const TotalsTitle = styled.div`
  padding-left: 1.95rem;
  margin-bottom: 1.3rem;
`

export const TotalsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.1rem;

  width: 100%;
`
