import * as React from "react";

const ViswalLogo = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="23.146" height="20.537" viewBox="0 0 23.146 20.537">
    <defs>
      <clipPath id="clip-path">
        <path id="Caminho_44627" data-name="Caminho 44627" d="M20.08.009,19.8.037l-7.563,7.59a2.543,2.543,0,0,1-.482.407h0a.365.365,0,0,1-.379,0h0a2.543,2.543,0,0,1-.48-.407h0L3.337.036,3.061.01A2.908,2.908,0,0,0,.379,1.177h0C-.212,2.192-.156,3.05.831,4.692h0L9.376,19.36a2.4,2.4,0,0,0,2.195,1.176h0a2.4,2.4,0,0,0,2.195-1.176h0L22.31,4.692c.986-1.643,1.043-2.5.452-3.515h0A2.846,2.846,0,0,0,20.325,0h0Q20.2,0,20.08.009M11.608,9.39A2.345,2.345,0,0,0,13.2,8.533h0L20.64,1.3a1.728,1.728,0,0,1,.96.437h0c.263.342.4.807-.084,1.644h0L12.833,18.021c-.384.664-.574,1.183-1.239,1.185h-.045c-.666,0-.856-.519-1.239-1.185h0L1.627,3.382c-.484-.838-.348-1.3-.084-1.644h0A1.719,1.719,0,0,1,2.5,1.3h0L9.945,8.533a2.346,2.346,0,0,0,1.589.856h.074Z" transform="translate(0.002 0)" fill={props.color || "#8E8E93"} />
      </clipPath>
      <clipPath id="clip-path-2">
        <path id="Caminho_44628" data-name="Caminho 44628" d="M3.067,20.529l.276-.026L10.9,12.91a2.543,2.543,0,0,1,.482-.407h0a.365.365,0,0,1,.379,0h0a2.543,2.543,0,0,1,.482.407h0L19.81,20.5l.276.026a2.911,2.911,0,0,0,2.682-1.168h0c.59-1.015.535-1.873-.452-3.515h0L13.771,1.176A2.4,2.4,0,0,0,11.576,0h0A2.4,2.4,0,0,0,9.381,1.176h0L.837,15.845c-.986,1.643-1.043,2.5-.452,3.515h0a2.848,2.848,0,0,0,2.436,1.177h0q.121,0,.245-.009m8.473-9.381A2.345,2.345,0,0,0,9.95,12h0L2.507,19.236a1.728,1.728,0,0,1-.96-.437h0c-.263-.342-.4-.807.084-1.644h0L10.314,2.516c.384-.664.574-1.183,1.24-1.184H11.6c.666,0,.856.519,1.239,1.184h0L21.52,17.155c.484.838.348,1.3.084,1.644h0a1.719,1.719,0,0,1-.96.437h0L13.2,12a2.346,2.346,0,0,0-1.589-.856h-.067Z" transform="translate(-0.004)" fill={props.color || "#8E8E93"} />
      </clipPath>
    </defs>
    <g id="Grupo_20200" data-name="Grupo 20200" transform="translate(0)">
      <g id="Camada_4" data-name="Camada 4" transform="translate(0 0)">
        <g id="Grupo_5" data-name="Grupo 5" opacity="0.998">
          <g id="Grupo_20197" data-name="Grupo 20197">
            <g id="Grupo_20196" data-name="Grupo 20196" clipPath="url(#clip-path)">
              <g id="Grupo_4" data-name="Grupo 4" transform="translate(-0.209 -0.078)">
                <g id="Grupo_3" data-name="Grupo 3">
                  <path id="Caminho_1" data-name="Caminho 1" d="M-1.173-.435H22.391V20.179H-1.173Z" transform="translate(1.173 0.435)" fill={props.color || "#8E8E93"} />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Camada_3" data-name="Camada 3" transform="translate(0.001 0)">
        <g id="Grupo_6" data-name="Grupo 6" transform="translate(0)" opacity="0.805">
          <g id="Grupo_221" data-name="Grupo 221">
            <g id="Grupo_20199" data-name="Grupo 20199">
              <g id="Grupo_20198" data-name="Grupo 20198" clipPath="url(#clip-path-2)">
                <g id="Grupo_4-2" data-name="Grupo 4-2" transform="translate(-0.21 0.001)">
                  <g id="Grupo_3-2" data-name="Grupo 3-2">
                    <path id="Caminho_1-2" data-name="Caminho 1-2" d="M22.392,20.614H-1.172V0H22.392Z" transform="translate(1.172)" fill={props.color || "#8E8E93"} />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default ViswalLogo;