import { Icon } from '@viswals/components'
import { Account, AccountStatusEnum } from '@src/types/account'
import moment from 'moment'
import { TableBox, TableStatus } from './style'
import TablePhoto from './TablePhoto'

type SuperAdministratorTableProps = {
  accounts: Account[]
  handleOpen: (row: any) => void
}

const SuperAdministratorTable = ({
  accounts,
  handleOpen,
}: SuperAdministratorTableProps) => {
  function getColor(status?: AccountStatusEnum) {
    if (status === AccountStatusEnum.ACTIVE) {
      return '#15D7E5'
    }
    if (status === AccountStatusEnum.SUSPEND) {
      return '#FFB23B'
    }
    return undefined
  }

  return (
    <TableBox>
      <thead>
        <tr>
          <th>Profile</th>
          <th>Professional Name</th>
          <th>Agent Email</th>
          <th>Date Created</th>
          <th>Time Created</th>
          <th>Access</th>
          <th>Account Number</th>
          <th>Account Status</th>
          <th>Live Status</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {accounts?.map((item, index) => (
          <tr key={`tr-${index}`} className={item?.status?.toLowerCase()}>
            <td>
              <TablePhoto photo={item.photo} />
            </td>
            <td>
              {item?.firstName ? `${item.firstName} ${item.lastName}` : ''}
            </td>
            <td>{item.username}</td>
            <td>{moment(item.createdAt).format('DD MMMM YYYY')}</td>
            <td>{moment(item.createdAt).format('h:mm A')}</td>
            <td>{item.permissions?.length} Permissions</td>
            <td>{item.number}</td>
            <td>
              <TableStatus color={getColor(item?.status)}>
                {item.status?.toLowerCase()}
              </TableStatus>
            </td>
            <td>
              <TableStatus>Away</TableStatus>
            </td>
            <td
              className="action-view"
              onClick={() => handleOpen(item)}
              aria-hidden
            >
              <Icon name="EyeEnable" />
            </td>
          </tr>
        ))}
      </tbody>
    </TableBox>
  )
}

export default SuperAdministratorTable
