/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useFieldArray, useForm } from 'react-hook-form'

import { SelectedOptionsProps } from '@viswals/components'
import {
  Country,
  Degree,
  DegreeFilter,
  Request,
  saveDegree,
  createInstitution,
  InstitutionFilter,
  createCourseTitle,
  CourseTitleFilter,
  Institution,
} from '@viswals/services'

import transformToSelectOptions from '@src/core/transformToSelectOptions'
import createArrayOfYears from '@src/core/createArrayOfYears'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import handleRetrieveDegree from './utils/handleRetrieveDegree'
import handleRetrieveInstituions from './utils/handleRetrieveInstitutions'

import handleRetrieveAwardedTitle from './utils/handleRetrieveAwardedTitle'
import { dataContinents, dataDegreeDurations } from '../common/data'

type DegreeHookForm = {
  institutionText?: string
  awardCourseTitleText?: string
  awardCourseTitleSelect?: string
  certificate?: {
    file: string
    fileName: string
  }
  isError?: boolean
} & Degree

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { setLoading } = useLoading()
  const { subStepDone, nextRoute, verified } = useClinicianRegister()

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    getValues,
    setError,
    control,
  } = useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  })

  const countriesState: Country[] = useSelector(
    (state: ReducersProps) => state.datas.countries
  )
  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  const [institutionsLocal, setInstitutionsLocal] = useState<Institution[]>([])
  const [coursesTitle, setCoursesTitle] = useState<SelectedOptionsProps[]>([])
  const [isReady, setIsReady] = useState<boolean>(false)
  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [selectDelete, setSelectDelete] = useState<number | null>(null)
  const [isModalOpen, setIsModelOpen] = useState<boolean>(false)
  const [destroyList, setDestroyList] = useState<DegreeHookForm[]>([])

  const continents = transformToSelectOptions({
    array: dataContinents,
    keyLabel: 'name',
    keyValue: 'uuid',
  })

  const countries = transformToSelectOptions({
    array: countriesState.filter(
      (country) =>
        `${country.continent}` === watch(`data.${selectedIndex}.continent`)
    ),
    keyLabel: 'name',
    keyValue: 'uuid',
    keyIcon: 'icon',
  })

  const courseDurations = transformToSelectOptions({
    array: dataDegreeDurations,
    keyLabel: 'name',
    keyValue: 'uuid',
  })

  const institutions = useCallback(
    (indexDegree: number) => {
      return transformToSelectOptions({
        array:
          institutionsLocal.filter(
            (inst) =>
              `${inst.countryUuid}` === watch(`data.${indexDegree}.country`)
          ) ?? [],
        keyLabel: 'name',
        keyValue: 'uuid',
      })
    },
    [selectedIndex, institutionsLocal]
  )

  const yearSelect = createArrayOfYears({
    startYear: 1960,
    finishYear: 2008,
  })
    .map((d) => ({
      label: d.toString(),
      value: d,
    }))
    .reverse()

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]

  const monthSelect = Array.from({ length: 12 }, (_, i) => i + 1).map((d) => ({
    label: months[d - 1],
    value: d,
  }))

  useEffect(() => {
    handleAsyncRetrieves()
  }, [registrationActive])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  useEffect(() => {
    if (!verified) {
      document.addEventListener('mousedown', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }
    }
  }, [selectedIndex, selectDelete, verified])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type, name }) => {
      if (name?.split('.')[2] === 'country' && type === 'change') {
        handleRetrieveInstituions({
          country: watch(`${name}`),
          setInstitutionsLocal,
        })
      }

      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  const handleAsyncRetrieves = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      await handleRetrieveDegree({
        setInstitutionsLocal,
        registrationActive,
        dispatch,
        append,
      })
      await handleRetrieveAwardedTitle({
        type: 'DEGREE',
        setCoursesTitle,
      })
      setIsReady(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const addItem = () => {
    append({ destroy: false })
    setSelectedIndex(fields.length)
  }

  function handleClick(e: any) {
    if (
      document.getElementsByClassName('vw-select').length ||
      fields.length <= 1
    ) {
      return false
    }
    const pathList = e.path as any[]
    const getCardMap = pathList
      ?.map((m) => m.className)
      ?.filter((f) => f && f?.includes('card-item'))
    const checkCard = !!getCardMap?.length
    if (checkCard) {
      const safeArea = !!pathList
        ?.map((m) => m.className)
        ?.filter((f) => f && f?.includes('vw-form'))?.length
      if (!safeArea) {
        handleSelectDelete()
      }
    }
  }

  const handleSelectDelete = () => {
    if (selectDelete === null) {
      setSelectDelete(selectedIndex)
    } else {
      setSelectDelete(null)
    }
  }

  const handleOpenModel = useCallback(() => {
    setIsModelOpen(true)
  }, [isModalOpen])

  const handleCancelModelDelete = useCallback(() => {
    setSelectDelete(null)
    setIsModelOpen(false)
  }, [isModalOpen])

  const handleConfirmModelDelete = useCallback(() => {
    const uuid = getValues(`data.${selectDelete}.uuid`)
    const itemObj = watch(`data.${selectDelete}`)
    const obj = {
      ...itemObj,
      continent: Number(`${itemObj.continent}`),
      courseDuration: Number(`${itemObj.courseDuration}`),
      destroy: true,
    }

    if (uuid) {
      setDestroyList((list) => [...list, obj])
    }
    remove(selectDelete as number)

    if (!confirm) {
      dispatch(setNavigationConfirm(true))
    }

    setSelectedIndex((idx) => (idx > 0 ? idx - 1 : 0))

    setSelectDelete(null)
    setTimeout(() => {
      setIsModelOpen(false)
    }, 200)
  }, [isModalOpen])

  async function getConvertDegrees(
    array: DegreeHookForm[]
  ): Promise<DegreeHookForm[]> {
    const convertDegrees: DegreeHookForm[] = []
    for (const [index, item] of array.entries()) {
      let intitutionText = null
      let courseTitleText = null

      if (
        item.institution === undefined &&
        (item.institutionText === undefined || item.institutionText === '')
      ) {
        setError(`data.${index}.institution`, {})
        setError(`data.${index}.institutionText`, {})
        item.isError = true
        return [item]
      }

      if (
        item.awardCourseTitle === undefined &&
        (item.awardCourseTitleText === undefined ||
          item.awardCourseTitleText === '') &&
        item.awardCourseTitleSelect === undefined
      ) {
        setError(`data.${index}.awardCourseTitle`, {})
        setError(`data.${index}.awardCourseTitleText`, {})
        setError(`data.${index}.awardCourseTitleSelect`, {})
        item.isError = true
        return [item]
      }

      if (item.institutionText && item.institutionText.length > 0) {
        const saveIntitutions = await createInstitution({
          name: item.institutionText,
          countryUuid: item.country,
          particular: item.courseProvider !== 'PUBLIC_UNIVERSITY',
        } as Request<InstitutionFilter>)
        if (saveIntitutions.data) {
          intitutionText = saveIntitutions.data
        }
      }

      if (item.awardCourseTitleText && item.awardCourseTitleText.length > 0) {
        const saveAwardCourseTitle = await createCourseTitle({
          title: item.awardCourseTitleText,
          type: 'DEGREE',
        } as Request<CourseTitleFilter>)
        if (saveAwardCourseTitle.data) {
          courseTitleText = saveAwardCourseTitle.data
        }
      }

      convertDegrees.push({
        ...item,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        certificate: undefined,
        destroy: item?.destroy ? item.destroy : false,
        continent: Number(item.continent),
        courseDuration: Number(item.courseDuration),
        courseCertificate: item?.certificate?.file,
        courseCertificateName: item?.certificate?.fileName,
        institution: intitutionText ?? item.institution,
        institutionText: undefined,
        awardCourseTitle:
          courseTitleText ||
          (item.awardCourseTitleSelect ?? item.awardCourseTitle),
        awardCourseTitleText: undefined,
        awardCourseTitleSelect: undefined,
      })
    }
    return convertDegrees
  }

  const onSubmit = async (data: any) => {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const convertDegreesInArray: DegreeHookForm[] = Object.values(data.data)
      const dataPayload = await getConvertDegrees(convertDegreesInArray)

      const findDataPayloadError = dataPayload.filter((item) => {
        return item.isError === true
      })

      if (findDataPayloadError.length > 0) {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
        return null
      }

      await saveDegree([
        ...destroyList,
        ...dataPayload,
      ] as Request<DegreeFilter>)
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      return null
    }
  }

  function handleSelectDegree(index: number) {
    const countryValue = watch(`data.${index}.country`)
    handleRetrieveInstituions({
      country: countryValue,
      setInstitutionsLocal,
    })
    setSelectedIndex(index)
  }

  return {
    fields,

    selectedIndex,
    handleSelectDegree,

    selectDelete,

    isModalOpen,
    handleOpenModel,

    handleCancelModelDelete,
    handleConfirmModelDelete,

    addItem,

    watch,
    onSubmit,
    handleSubmit,
    register,
    setValue,

    continents,
    countries,
    institutions,
    courseDurations,
    yearSelect,
    monthSelect,
    coursesTitle,
  }
}

export default useLogic
