import Acquired from '@images/map-pins/patient/Acquired.svg'
import AcquiredOn from '@images/map-pins/patient/Acquired_on.svg'
import Disqualified from '@images/map-pins/patient/Disqualified.svg'
import DisqualifiedOn from '@images/map-pins/patient/Disqualified_on.svg'
import Dropouts from '@images/map-pins/patient/Dropouts.svg'
import DropoutsOn from '@images/map-pins/patient/Dropouts_on.svg'
import InProgress from '@images/map-pins/patient/InProgress.svg'
import InProgressOn from '@images/map-pins/patient/InProgress_on.svg'
import NewEntries from '@images/map-pins/patient/NewEntries.svg'
import NewEntriesOn from '@images/map-pins/patient/NewEntries_on.svg'
import QuestionsRaised from '@images/map-pins/patient/QuestionsRaised.svg'
import QuestionsRaisedOn from '@images/map-pins/patient/QuestionsRaised_on.svg'
import ReEntries from '@images/map-pins/patient/ReEntries.svg'
import ReEntriesOn from '@images/map-pins/patient/ReEntries_on.svg'
import SignUps from '@images/map-pins/patient/SignUps.svg'
import SignUpsOn from '@images/map-pins/patient/SignUps_on.svg'
import Verified from '@images/map-pins/patient/Verified.svg'
import VerifiedOn from '@images/map-pins/patient/Verified_on.svg'
import { AccountStatus } from '@viswals/services'

export default function patientUrlMapStatus(
  status: AccountStatus,
  active = false
): string {
  switch (status) {
    case 'NEW_ENTRY':
      return active ? NewEntriesOn : NewEntries
    case 'RE_ENTRY':
      return active ? ReEntriesOn : ReEntries
    case 'IN_PROGRESS':
      return active ? InProgressOn : InProgress
    case 'QUESTION_RAISED':
      return active ? QuestionsRaisedOn : QuestionsRaised
    case 'DISQUALIFIED':
      return active ? DisqualifiedOn : Disqualified
    case 'VERIFIED':
      return active ? VerifiedOn : Verified
    case 'ACQUIRED':
      return active ? AcquiredOn : Acquired
    case 'DROPOUT':
      return active ? DropoutsOn : Dropouts
    default:
      return active ? SignUpsOn : SignUps
  }
}
