import styled, { css } from 'styled-components'

export type ContainerCirclePercentNumberProps = {
  isRed?: boolean
  isMini?: boolean
}

const ContainerCirclePercentNumber = styled.div<ContainerCirclePercentNumberProps>`
  display: flex;
  width: 10.4rem;
  height: 10.4rem;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  border: 0.5rem solid ${(props) => (props.isRed ? '#de5e5e' : '#15aeff')};

  color: ${(props) => (props.isRed ? '#de5e5e' : '#15aeff')};

  .vwp-contentInside {
    width: 3.5rem;
    height: 2.8rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 0.1rem;
    .vwp-contentNumber {
      width: 2.4rem;
      height: 2rem;
      font-size: 2rem;
      line-height: 2rem;
      font-weight: 600;
      letter-spacing: -0.1rem;
    }
    .vwp-contentPercent {
      width: 1rem;
      height: 2rem;
      font-size: 1.4rem;
      font-weight: 300;
      line-height: 2rem;
    }
  }

  ${(props) => {
    if (props.isMini) {
      return css`
        width: 6.2rem;
        height: 6.2rem;
        border: 0.3rem solid ${() => (props.isRed ? '#de5e5e' : '#15aeff')};
      `
    }
    return null
  }}
`

export default ContainerCirclePercentNumber
