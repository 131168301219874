import { useState, useContext } from 'react';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import QueryString from 'qs'

import Components from '@components/index'
import Images from "@images/index";
import { replaceDoubleBraces } from '@src/utils/common';
import api from '@src/services/api'

import {
  Background,
  BackgroundGredientlayer,
  Input,
  FormBox,
  InputSection,
  LoginText,
  LoginTitleBox,
  LoginTitleSecond,
  BottomTitleBox,
  BottomTitleFirst,
  BottomTitleSecond,
  BottomSubTitle,
  EnterButton,
  LoginImgBox,
  FormItems,
  InputBox,
  ErrorBox,
  ClosePasswordErrorText,
  ViswalImgBox,
  InputButtonContainer
} from "./SignInStyles"

const SignInPage = () => {

  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const [focusedField, setFocusedField] = useState<string | null>(null)

  const navigate = useNavigate();
  const { t } = useTranslation();

  const onFocusInput = (type: string) => {
    setFocusedField(type)
  }

  const onBlurInput = (evt: any) => {
    formik.handleBlur(evt);
    setFocusedField(null)
  }

  const formik = useFormik({
    validateOnBlur: true,
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email(t('ERROR.INVALID_EMAIL')).required(t('ERROR.EMAIL_REQUIRED')),
      password: Yup.string().min(8, replaceDoubleBraces(t('ERROR.PASSWORD_INVALID_LEN'), { number: 8 }))
        .max(15, replaceDoubleBraces(t('ERROR.PASSWORD_INVALID_LEN'), { number: 15 }))
        .required(t('ERROR.PASSWORD_REQUIRED'))
    }),
    onSubmit: async (value) => {
      const user = { username: value.email, password: value.password }
      try {
        const {
          data: { data },
        } = await api.post(
          '/cc-account/auth/sign-in',
          QueryString.stringify(user),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        )

        localStorage.setItem('cc_uuid', data.uuid)
        localStorage.setItem('access_token', data.access_token)
        localStorage.setItem('access_token_email', user.username)
        localStorage.setItem('refresh_token', data.refresh_token)


        navigate('/global-view/pre-registration')
      } catch (e) {
        console.log(e);
      }

    }
  });


  return (
    <Background typeBG={Images.LoginBG}>
      <BackgroundGredientlayer>
        <FormBox>
          <FormItems>
            <LoginImgBox>
              <ViswalImgBox>
                <Images.ViswalLogo color={"#FF813E"} />
              </ViswalImgBox>
            </LoginImgBox>
            <LoginTitleBox>
              <Components.Icon src={Images.ViswalText} />
              <LoginTitleSecond>CC</LoginTitleSecond>
            </LoginTitleBox>
            <LoginText>{t('LABEL.LOGIN')}</LoginText>
            <InputSection>
              <InputBox first>
                <Input
                  hasValue={!!formik.values.email}
                  hasError={!!(formik.touched.email && formik.errors.email)}
                  placeholder={t('LABEL.EMAIL')}
                  type={"email"}
                  {...formik.getFieldProps('email')}
                  onFocus={() => onFocusInput('email')}
                  onBlur={onBlurInput}
                />
                {!!formik.values.email && focusedField === 'email' &&
                  <InputButtonContainer>
                    <Components.Icon
                      src={Images.CrossFillRound}
                      onPointerDown={(e: any) => {
                        e.preventDefault()
                        formik.setFieldValue('email', '')
                        formik.setFieldTouched('email', false)
                      }}
                    />
                  </InputButtonContainer>
                }
              </InputBox>

              {formik.touched.email && formik.errors.email &&
                <ErrorBox>
                  <Components.Icon src={Images.ErrorExplementary} />
                  <ClosePasswordErrorText>{formik.errors.email}</ClosePasswordErrorText>
                </ErrorBox>
              }

              <InputBox>
                <Input
                  password
                  hasValue={!!formik.values.password}
                  hasError={!!(formik.touched.password && formik.errors.password)}
                  type={isPasswordVisible ? 'text' : "password"}
                  placeholder={t('LABEL.PASSWORD')}
                  {...formik.getFieldProps('password')}
                  onFocus={() => onFocusInput('password')}
                  onBlur={onBlurInput}
                />

                {!!formik.values.password && focusedField === 'password' &&
                  <InputButtonContainer>
                    <Components.Icon
                      src={Images.EyeOpen}
                      onPointerDown={(e: any) => {
                        e.preventDefault()
                        setPasswordVisible(!isPasswordVisible)
                      }}
                    />
                    <Components.Icon
                      src={Images.CrossFillRound}
                      onPointerDown={(e: any) => {
                        e.preventDefault()
                        formik.setFieldValue('password', '')
                        formik.setFieldTouched('password', false)
                      }}
                    />
                  </InputButtonContainer>
                }

              </InputBox>

              {formik.touched.password && formik.errors.password &&
                <ErrorBox>
                  <Components.Icon src={Images.ErrorExplementary} />
                  <ClosePasswordErrorText>{formik.errors.password}</ClosePasswordErrorText>
                </ErrorBox>
              }

            </InputSection>

            <EnterButton
              onClick={() => formik.submitForm()}
              disable={!(formik.values.email && formik.values.password && !Object.keys(formik.errors).length)}
            >
              {t('BUTTON.ENTER')}
            </EnterButton>

            <BottomTitleBox>
              <BottomTitleFirst>{t('LABEL.LOGIN_FOOTER_ONE')}</BottomTitleFirst>
              <BottomTitleSecond>{t('LABEL.LOGIN_FOOTER_TWO')}</BottomTitleSecond>
            </BottomTitleBox>
            <BottomSubTitle>{t('LABEL.LOGIN_FOOTER_MESSAGE')}</BottomSubTitle>

          </FormItems>
        </FormBox>
      </BackgroundGredientlayer>
    </Background>
  )
}

export default SignInPage
