import useLayout from '@src/hooks/useLayout'
import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import dataMainMenuItems from './dataMenu'

const useLogicLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { layout, setLayout } = useLayout()

  const useRouterCallback = useCallback(() => {
    return dataMainMenuItems.findIndex((f) =>
      location.pathname?.includes(f.route)
    )
  }, [location])

  const route = useRouterCallback()

  function handleContentClick(r: string) {
    navigate(r)
  }

  function handleWorkSpaceClick() {
    setLayout('openWorkSpace', !layout.openWorkSpace)
  }

  return {
    route,
    layout,
    setLayout,
    handleContentClick,
    dataMainMenuItems,
    handleWorkSpaceClick,
  }
}

export default useLogicLayout
