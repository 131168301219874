/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Request, Currency, updateCurrency } from '@viswals/services'
import { Button, Input, Toggle, Upload } from '@viswals/components'

import { StyledContentLanguage } from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'

export default function FormUpdateCurrency() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, reset, control, setValue } = useForm()

  useEffect(() => {
    ;(async () => {
      const getCurrencyToSave: Currency = JSON.parse(
        window.localStorage.getItem('currencyEdit') as string
      )
      reset({
        ...getCurrencyToSave,
        icon: {
          fileName: getCurrencyToSave.name,
          file: getCurrencyToSave.icon,
        },
      })
    })()
  }, [])

  async function onSubmit(data: any) {
    const dataToSend: Currency = {
      uuid: watch('uuid'),
      name: data.name,
      isoCode: data.isoCode,
      icon: data.icon.file,
      enabled: data.enabled,
    }

    try {
      setIsError('')
      await updateCurrency(dataToSend as Request<Currency>)
      window.localStorage.removeItem('currencyEdit')
      navigate('/administration/common/currency')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentLanguage className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Edit Currency"
            description="Here you can edit currency"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Input label="ISO Code" formRegister={{ ...register('isoCode') }} />

          <div className="content-icon-upload">
            <Upload
              label="Icon"
              placeholder="Upload Icon"
              formRegister={{ ...register('icon'), watch, setValue }}
            />
            <div className="box-preview">
              <span className="box-preview-title">Preview</span>
              <div className="box-preview-image">
                <img src={watch('ico.file')} alt="" />
              </div>
            </div>
          </div>

          <div className="content-toggle-switch">
            <Toggle isBig name="enabled" control={control} />
            <div className="box-preview">Enable Currency</div>
          </div>
          <Button label="Save" type="submit" />
          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/currency')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentLanguage>
  )
}
