import { CircularProgress } from '@mui/material'
import { AlertModal, BoxListOptions } from '@viswals/components-platform'

import { LoadingTable } from '@src/pages/administration/common/_components/loading-table'

import PopUpReportUnusual from '../components/PopUpReportUnusual'
import {
  ContainerPopUpDeleteItem,
  ContainerTableBodyReport,
  ContainerTableReport,
  ContentLoadingList,
} from './styles'
import useLogic from './useLogic'
import ItemReportUnusual from './item'

export default function ShareOurAppScreen() {
  const {
    dropouts,
    getLabelPlatform,

    itemReport,
    handleDeleteItem,
    handleClickIconItem,
    handleConfirmDeleteItem,
    handleConfirmCancelItem,

    loadingBoxRef,
    isRequesting,

    dropoutTotal,
  } = useLogic()

  return (
    <ContainerTableReport>
      <div className="header">
        <div className="td tdOne">Viswals Platform</div>
        <div className="td tdTwo">User</div>
        <div className="td tdThree">Client Email</div>
        <div className="td tdFour">Date</div>
        <div className="td tdFive">Hour</div>
        <div className="td tdSix">Reason to Delete</div>
        <div className="td tdSeven">&nbsp;</div>
      </div>
      <ContainerTableBodyReport className="tbody">
        <LoadingTable />
        <BoxListOptions
          color="#F72A1E"
          id="vw-option-report-id"
          overlayClassName="vw-option-report"
          items={[
            {
              label: 'Delete Notification',
              onClick: handleDeleteItem,
            },
          ]}
        />
        <PopUpReportUnusual title="Rate Us" itemReport={itemReport} />
        <ContainerPopUpDeleteItem className="vw-alert-modal-first">
          <AlertModal
            title="You are about to delete a Viswals App Communication"
            message="Are you sure you want to delete?"
            handleCancel={handleConfirmCancelItem}
            handleConfirm={handleConfirmDeleteItem}
            labelCancel="Cancel"
            labelConfirm="Delete"
          />
        </ContainerPopUpDeleteItem>
        {dropouts.dropouts?.map((itemReported, _index) => {
          return (
            <div
              key={_index}
              onClick={(event) =>
                handleClickIconItem(event.target as any, itemReported)
              }
              aria-hidden
              style={{
                marginBottom:
                  _index === (dropouts.dropouts?.length ?? 1) - 1 &&
                  dropoutTotal !== dropouts.dropouts?.length
                    ? '3rem'
                    : '',
              }}
            >
              <ItemReportUnusual
                key={_index}
                isSelected={itemReported.uuid === itemReport.uuid}
                itemReport={itemReported}
                platformLabel={getLabelPlatform(itemReported.platform)}
              />
            </div>
          )
        })}
        {dropoutTotal !== dropouts.dropouts?.length ? (
          <ContentLoadingList ref={loadingBoxRef}>
            {isRequesting && <CircularProgress size={30} />}
          </ContentLoadingList>
        ) : null}
      </ContainerTableBodyReport>
    </ContainerTableReport>
  )
}
