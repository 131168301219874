/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import {
  Button,
  Card,
  Input,
  QuestionModal,
  Select,
  Upload,
} from '@viswals/components'

import DegreeWhere from '@images/step3degreeWhere.svg'
import DegreeWhom from '@images/step3degreeWhom.svg'
import DegreeLengthOfStudy from '@images/step3degreeLengthOfStudy.svg'
import DegreeYourTitle from '@images/step3degreeWhatsYourTitle.svg'

import SubstepBigPagination from '@src/components/pagination/SubstepBigPagination'
import {
  BoxContentCards,
  ContainerDegree,
  ContentMiniForm,
} from './DegreesStyles'
import useLogic from './useLogic'

const StepDegrees = () => {
  const {
    fields,

    selectedIndex,
    handleSelectDegree,

    selectDelete,

    isModalOpen,
    handleOpenModel,

    handleCancelModelDelete,
    handleConfirmModelDelete,

    watch,
    onSubmit,
    handleSubmit,
    register,
    setValue,

    continents,
    countries,
    institutions,
    courseDurations,
    yearSelect,
    monthSelect,
    coursesTitle,
  } = useLogic()

  return (
    <ContainerDegree>
      <h1>Degree</h1>
      <div className="vw-regis-headerButtons">
        <div className="vw-regis-boxLeft">
          <SubstepBigPagination
            label="Degree"
            active={selectedIndex}
            onChangeActive={(index) => {
              handleSelectDegree(index)
            }}
            data={fields}
          />
        </div>
        <div className="vw-regis-boxRight">
          {selectDelete !== null ? (
            <div className="vw-regist-buttonDanger">
              <Button
                icon="Remove1"
                label="Delete Degree"
                styleType="danger"
                handleClick={handleOpenModel}
              />
              {isModalOpen ? (
                <QuestionModal
                  title="Delete Degree"
                  description="You are about to delete a Degree. This action cannot be undone. Are you sure you want to proceed?"
                  cancelLabel="Oops, take me back"
                  cancelFunction={handleCancelModelDelete}
                  confirmLabel="Yes, delete Degree"
                  confirmFunction={handleConfirmModelDelete}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled>
          <BoxContentCards selectedIndex={selectedIndex}>
            {fields?.map((_, indexDegree) => {
              return (
                <div
                  key={`${_.id}_indexDegree`}
                  className={`card-item item-${indexDegree}`}
                >
                  <input
                    type="hidden"
                    {...register(`data.${indexDegree}.destroy`, {
                      value: false,
                    })}
                  />
                  <Card readyDelete={Boolean(selectedIndex === selectDelete)}>
                    <div className="innerCardBox">
                      {/* Where Information */}
                      <article className="vw-regis-articleColum vw-regis-where">
                        <h3>Where</h3>
                        <img src={DegreeWhere} alt={DegreeWhere} />
                        <div className="vw-regis-safeArea">
                          <p>Continent</p>
                          {continents.map((continent, _index) => {
                            const checked =
                              parseInt(`${continent.value}`) ===
                              parseInt(watch(`data.${indexDegree}.continent`))
                                ? 'checked'
                                : ''

                            return (
                              <label
                                key={_index}
                                className={`vw-form vw-regis-safeAreaItems ${checked}`}
                              >
                                {continent.label}
                                <input
                                  type="radio"
                                  value={continent.value}
                                  {...register(
                                    `data.${indexDegree}.continent`,
                                    {
                                      required: true,
                                      onChange: (event) => {
                                        if (event.target.value) {
                                          setValue(
                                            `data.${indexDegree}.country`,
                                            undefined
                                          )
                                          setValue(
                                            `data.${indexDegree}.institution`,
                                            undefined
                                          )
                                        }
                                      },
                                    }
                                  )}
                                />
                              </label>
                            )
                          })}
                          <div className="vw-regis-safeAreaBottom">
                            <p>Countries</p>
                            <ContentMiniForm>
                              <Select
                                labelTitle="Select Country"
                                placeholder="Select Country"
                                search
                                options={countries}
                                placement="top"
                                formRegister={{
                                  ...register(`data.${indexDegree}.country`, {
                                    required: true,
                                    onChange: () => {
                                      setValue(
                                        `data.${indexDegree}.institution`,
                                        undefined
                                      )
                                    },
                                  }),
                                  watch,
                                }}
                                isDisabled
                              />
                            </ContentMiniForm>
                          </div>
                        </div>
                      </article>
                      {/* With Whom Information */}
                      <article className="vw-regis-articleColum vw-regis-withWhom">
                        <h3>With Whom</h3>
                        <img src={DegreeWhom} alt={DegreeWhom} />
                        <div className="vw-regis-safeArea">
                          <p>Course Provider</p>
                          <label
                            className={`vw-form vw-regis-safeAreaItems ${
                              watch(`data.${indexDegree}.courseProvider`) ===
                              'PUBLIC'
                                ? 'checked'
                                : ''
                            }`}
                          >
                            Public University
                            <input
                              type="radio"
                              value="PUBLIC"
                              {...register(
                                `data.${indexDegree}.courseProvider`,
                                {
                                  required: true,
                                  onChange: (event) => {
                                    if (event.target.value) {
                                      setValue(
                                        `data.${indexDegree}.institution`,
                                        undefined
                                      )
                                    }
                                  },
                                }
                              )}
                            />
                          </label>
                          <label
                            className={`vw-form vw-regis-safeAreaItems ${
                              watch(`data.${indexDegree}.courseProvider`) ===
                              'PRIVATE'
                                ? 'checked'
                                : ''
                            }`}
                          >
                            Private University
                            <input
                              type="radio"
                              value="PRIVATE"
                              {...register(
                                `data.${indexDegree}.courseProvider`,
                                {
                                  required: true,
                                }
                              )}
                            />
                          </label>

                          <div className="vw-regis-safeAreaBottom">
                            <p>Institution</p>
                            <ContentMiniForm>
                              <Select
                                labelTitle="Select Institution"
                                placeholder="Select Institution"
                                search
                                options={institutions(indexDegree)}
                                formRegister={{
                                  ...register(
                                    `data.${indexDegree}.institution`,
                                    {
                                      onChange: (e) => {
                                        if (e.target.value) {
                                          setValue(
                                            `data.${indexDegree}.institutionText`,
                                            undefined
                                          )
                                        }
                                      },
                                    }
                                  ),
                                  watch,
                                }}
                                placement="top"
                                isDisabled
                              />
                            </ContentMiniForm>
                            <ContentMiniForm>
                              <Input
                                placeholder="If other, write here"
                                formRegister={register(
                                  `data.${indexDegree}.institutionText`,
                                  {
                                    onChange: (e) => {
                                      if (e.target.value) {
                                        setValue(
                                          `data.${indexDegree}.institution`,
                                          undefined
                                        )
                                      }
                                    },
                                  }
                                )}
                              />
                            </ContentMiniForm>
                          </div>
                        </div>
                      </article>
                      {/* Length of Study Information */}
                      <article className="vw-regis-articleColum vw-regis-lengthOfStudy">
                        <h3>Length of Study</h3>
                        <img
                          src={DegreeLengthOfStudy}
                          alt={DegreeLengthOfStudy}
                        />
                        <div className="vw-regis-safeArea">
                          <p>Course Duration</p>
                          <div className="vw-regis-contentOptions">
                            {courseDurations.map((duration, _key) => {
                              return (
                                <label
                                  key={_key}
                                  className={`vw-form vw-regis-safeAreaItems ${
                                    duration.value ===
                                    watch(`data.${indexDegree}.courseDuration`)
                                      ? 'checked'
                                      : ''
                                  }`}
                                >
                                  {duration.label}
                                  <input
                                    type="radio"
                                    value={duration.value}
                                    {...register(
                                      `data.${indexDegree}.courseDuration`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                </label>
                              )
                            })}
                          </div>

                          <div className="vw-regis-safeAreaBottom">
                            <p>Date Granted</p>
                            <ContentMiniForm>
                              <Select
                                labelTitle="Select Year"
                                placeholder="Select Year"
                                options={yearSelect}
                                formRegister={{
                                  ...register(
                                    `data.${indexDegree}.dateGrantedYear`,
                                    {
                                      required: true,
                                    }
                                  ),
                                  watch,
                                }}
                                placement="top"
                                isDisabled
                              />
                            </ContentMiniForm>
                            <ContentMiniForm
                              style={{
                                marginTop: '1rem',
                              }}
                            >
                              <Select
                                labelTitle="Select Month"
                                placeholder="Select Month"
                                options={monthSelect}
                                formRegister={{
                                  ...register(
                                    `data.${indexDegree}.dateGrantedMonth`,
                                    {
                                      required: true,
                                    }
                                  ),
                                  watch,
                                }}
                                placement="top"
                                isDisabled
                              />
                            </ContentMiniForm>
                          </div>
                        </div>
                      </article>
                      {/* What's Your Title Information */}
                      <article className="vw-regis-articleColum vw-regis-yourTitle">
                        <h3>What’s Your Title</h3>
                        <img src={DegreeYourTitle} alt={DegreeYourTitle} />
                        <div className="vw-regis-safeArea">
                          <p>Awarded Course Title</p>
                          {coursesTitle.slice(0, 3).map((courseTitle) => {
                            const checked =
                              watch(`data.${indexDegree}.awardCourseTitle`) ===
                              courseTitle.value
                                ? 'checked'
                                : ''
                            return (
                              <label
                                className={`vw-form vw-regis-safeAreaItems ${checked}`}
                                key={`${courseTitle.value}`}
                              >
                                {courseTitle.label}
                                <input
                                  type="radio"
                                  value={courseTitle.value}
                                  {...register(
                                    `data.${indexDegree}.awardCourseTitle`,
                                    {
                                      onChange: () => {
                                        setValue(
                                          `data.${indexDegree}.awardCourseTitleText`,
                                          undefined
                                        )
                                        setValue(
                                          `data.${indexDegree}.awardCourseTitleSelect`,
                                          undefined
                                        )
                                      },
                                    }
                                  )}
                                />
                              </label>
                            )
                          })}

                          {coursesTitle.length >= 4 ? (
                            <ContentMiniForm
                              style={{
                                marginTop: '1rem',
                              }}
                              className="vw-form vw-contentMiniForm-select"
                            >
                              <Select
                                labelTitle="Other Titles"
                                placeholder="Other Titles"
                                options={coursesTitle.slice(
                                  4,
                                  coursesTitle.length
                                )}
                                formRegister={{
                                  ...register(
                                    `data.${indexDegree}.awardCourseTitleSelect`,
                                    {
                                      onChange: () => {
                                        setValue(
                                          `data.${indexDegree}.awardCourseTitle`,
                                          undefined
                                        )
                                        setValue(
                                          `data.${indexDegree}.awardCourseTitleText`,
                                          undefined
                                        )
                                      },
                                    }
                                  ),
                                  watch,
                                }}
                                search
                                placement="top"
                                isDisabled
                              />
                            </ContentMiniForm>
                          ) : null}

                          <ContentMiniForm
                            style={{
                              marginTop: '1rem',
                            }}
                            className="vw-form vw-contentMiniForm-input"
                          >
                            <Input
                              placeholder="If other, write here"
                              formRegister={register(
                                `data.${indexDegree}.awardCourseTitleText`,
                                {
                                  onChange: (e) => {
                                    if (e.target.value) {
                                      setValue(
                                        `data.${indexDegree}.awardCourseTitleSelect`,
                                        undefined
                                      )
                                      setValue(
                                        `data.${indexDegree}.awardCourseTitle`,
                                        undefined
                                      )
                                    }
                                  },
                                }
                              )}
                            />
                          </ContentMiniForm>
                          <div className="vw-regis-safeAreaBottom vw-form ">
                            <ContentMiniForm>
                              <Upload
                                placeholder="Upload Certificate"
                                label="Course Certificate"
                                formRegister={{
                                  ...register(
                                    `data.${indexDegree}.certificate`,
                                    {
                                      required: !watch(
                                        `data.${indexDegree}.certificate`
                                      )?.fileName,
                                    }
                                  ),
                                  watch,
                                  setValue,
                                }}
                              />
                            </ContentMiniForm>
                          </div>
                        </div>
                      </article>
                    </div>
                  </Card>
                </div>
              )
            })}
          </BoxContentCards>
        </fieldset>
      </form>
    </ContainerDegree>
  )
}

export default StepDegrees
