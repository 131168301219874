import styled from 'styled-components'

const ContainerItemList = styled.div`
  width: 100%;

  padding: 0.4rem 1rem;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: #2c2c2e;

  border-radius: 0.5rem;

  cursor: pointer;

  gap: 2rem;

  transition: all 0.3s ease;

  &:hover {
    background-color: #48484a;
  }

  .item-of-list {
    width: 100%;
    height: 2.4rem;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-size: 1rem;
    line-height: 1rem;
    font-family: var(--font-secondary);
    color: #aeaeb2;

    &.first {
      width: 100%;
      max-width: 9rem;
      min-width: 9rem;
      .black {
        width: 7rem;
        height: 2.4rem;

        padding: 0 0.8rem;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        background-color: #1c1c1e;
        border-radius: 0.5rem;

        svg {
          width: 1.2rem;
          margin-right: 0.4rem;
        }

        &.PATIENT {
          color: #2c7dff;
        }

        &.CLINICIAN {
          color: #36ba91;
        }

        &.CLINIC {
          color: #b665d5;
        }
      }
    }
    &.date {
      width: 100%;
      max-width: 7rem;
      min-width: 7rem;
    }
    &.time {
      width: 100%;
      max-width: 7rem;
      min-width: 7rem;
    }
    &.country {
      width: 100%;
      max-width: 12.6rem;
      min-width: 12.6rem;
    }
    &.name {
      width: 100%;
      max-width: 13.4rem;
      min-width: 13.4rem;
    }

    &.global {
      width: 100%;
      max-width: 14.2rem;
      min-width: 14.2rem;
    }

    &.app {
      width: 100%;
      max-width: 14.2rem;
      min-width: 14.2rem;
      color: #2c7dff;
    }

    &.clinician {
      width: 100%;
      max-width: 14.2rem;
      min-width: 14.2rem;
      color: #36ba91;
    }

    &.clinic {
      width: 100%;
      max-width: 14.2rem;
      min-width: 14.2rem;
      color: #b665d5;
    }

    &.email {
      width: 100%;
      max-width: 19.5rem;
      min-width: 19.5rem;
    }

    .button-status {
      width: 16.35rem;
      height: 2.4rem;

      background-color: #ebebeb05;
      color: #ebebeb;

      border-radius: 0.5rem;

      display: flex;
      align-items: center;
      justify-content: center;
      &.ACQUIRED {
        background-color: #00ff8905;
        color: #00ff89;
      }
      &.DROPOUT {
        background-color: #63636605;
        color: #636366;
      }
    }

    .button-link {
      width: 16.35rem;
      height: 2.4rem;

      background-color: #1c1c1e;

      border-radius: 0.5rem;

      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;
    }
  }
`

export default ContainerItemList
