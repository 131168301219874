import styled from 'styled-components'

const ContainerIconRate = styled.div<{ color: string }>`
  width: 11.5rem;
  height: 1.6rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .content-sub-info {
    display: flex;
    align-items: center;
  }

  .icon-rate-box-radius {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 1.6rem;
    background-color: ${(props) => props.color};

    padding: 0.1rem 0.5rem 0rem;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-right: 1rem;

    svg {
      color: white;
    }
  }

  .content-icon-stars {
    width: 4.5rem;
    height: 0.8rem;
    display: flex;
    gap: 0.1rem;
  }
`

export default ContainerIconRate
