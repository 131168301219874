import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Country,
  Institution,
  Request,
  retrieveInstitution,
} from '@viswals/services'
import gsap from 'gsap'
import { Button } from '@viswals/components-platform'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import useDatas from '@src/hooks/useDatas'

import gridStyle from '../_components/grid-style'
import { LoadingTable } from '../_components/loading-table'
import HeaderContentCommon from '../_components/HeaderContentCommon'
import {
  StyledContentInstitution,
  StyledContentInstitutionTable,
} from './styles'
import { LocalPaginationRequestProps } from '../country/table'

export type LocalInstituionAdm = {
  id?: number
} & Institution

export default function InstitutionTable() {
  const navigate = useNavigate()

  const { countries } = useDatas()

  const [institutions, setInstitutions] = useState<LocalInstituionAdm[]>([])
  const [pagination, setPagination] = useState<LocalPaginationRequestProps>({
    itemsPerPage: 25,
    page: 1,
    total: 0,
  })

  useEffect(() => {
    ; (async () => {
      await getInstitutions(pagination)
    })()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'area_name',
      headerName: 'Area',
      width: 170,
    },
    {
      field: 'countryUuid',
      headerName: 'Country',
      width: 200,
      renderCell: (params) => {
        const findCountry: Country | undefined = countries.find(
          (v) => v.uuid === params.row.countryUuid
        )
        return <>{findCountry?.name}</>
      },
    },
    {
      field: 'particular',
      headerName: 'Private',
      width: 100,
      renderCell: (params) => {
        const institutionToSave: Institution = params.row
        return <>{institutionToSave.particular ? 'YES' : 'NO'}</>
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      renderCell: (params) => {
        const institutionToSave: Institution = params.row
        return <>{institutionToSave.enabled ? 'YES' : 'NO'}</>
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', gap: 20 }}>
            <Button
              label="Edit"
              uuid=""
              style={{ backgroundColor: 'orange', color: 'black' }}
              handleClick={() => {
                const institutionToSave: Institution = params.row
                window.localStorage.setItem(
                  'institutionEdit',
                  JSON.stringify(institutionToSave)
                )
                navigate(
                  `/administration/common/institution/edit/${institutionToSave.uuid}`
                )
              }}
            />
          </div>
        )
      },
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
    },
  ]

  async function getInstitutions(
    paginationed: LocalPaginationRequestProps | undefined
  ) {
    const request: Request<Institution> = {
      fields: [
        'uuid',
        'name',
        'countryUuid',
        'particular',
        'enabled',
        'updatedAt',
        'createdAt',
      ],
      pagination: {
        itemsPerPage: 25,
        page: paginationed?.page as number,
      },
      orderBy: {
        column: 'name',
        direction: 'asc',
      },
    }
    try {
      const resp = await retrieveInstitution(request)
      const { data } = resp

      if (data.code === 200) {
        const dataInstitutions: Institution[] = data.data

        const newInstitutions: LocalInstituionAdm[] = dataInstitutions.map(
          (value: any, _index) => {
            return {
              id: _index,
              uuid: value.uuid,
              name: value.name,
              countryUuid: value.countryUuid,
              area_name: (value?.areas || []).map((ele: any) => ele.label).join(", "),
              area: (value?.areas || []).map((ele: any) => ele.uuid),
              particular: value.particular,
              enabled: value?.enabled ?? false,
            }
          }
        )
        setInstitutions(newInstitutions)

        setTimeout(() => {
          setPagination(
            (value) =>
            ({
              ...value,
              ...{
                itemsPerPage: paginationed?.itemsPerPage,
              },
              total: data.pagination
                ? data.pagination.total
                : data.data.length,
            } as LocalPaginationRequestProps)
          )
        }, 200)
        disabledLoading()
      }
    } catch (e) {
      console.error(e)
    }
  }

  function handleClickAdd() {
    navigate('/administration/common/institution/new')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function addPage(page: number) {
    const pageToSet: LocalPaginationRequestProps = {
      itemsPerPage: 25,
      page: page + 1,
    }
    try {
      await getInstitutions(pageToSet)
    } catch (error) {
      console.error({
        error,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function enabledLoading() {
    const query = window.document.querySelector('.vw-loadingContainer')
    gsap.to(query, {
      zIndex: 99,
      duration: 0.1,
      onComplete: () => {
        gsap.to(query, {
          opacity: 1,
          delay: 0.1,
          duration: 0.25,
        })
      },
    })
  }

  function disabledLoading() {
    setTimeout(() => {
      const query = window.document.querySelector('.vw-loadingContainer')
      gsap.to(query, {
        opacity: 0,
        duration: 0.25,
        onComplete: () => {
          gsap.to(query, {
            zIndex: -1,
            delay: 0.25,
            duration: 0.25,
          })
        },
      })
    }, 300)
  }

  return (
    <StyledContentInstitution>
      <HeaderContentCommon
        name="Institution"
        description="Manager institution information"
        onClickAdd={() => handleClickAdd()}
      />

      <StyledContentInstitutionTable>
        <LoadingTable />
        <DataGrid
          sx={gridStyle}
          rows={institutions}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          experimentalFeatures={{ newEditingApi: false }}
          onPageChange={(page) => {
            enabledLoading().then(() => {
              setTimeout(() => {
                addPage(page)
              }, 200)
            })
          }}
          pagination
          rowsPerPageOptions={[25]}
          rowCount={pagination.total}
          initialState={{
            pagination: {
              pageSize: pagination.itemsPerPage,
            },
          }}
          paginationMode="server"
        />
      </StyledContentInstitutionTable>
    </StyledContentInstitution>
  )
}
