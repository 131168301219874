/* eslint-disable no-script-url */

import { Icon, ListNames } from '@viswals/components-platform'
import { Country, Patient, PatientStatus } from '@viswals/services'
import getStatusList, { getFindStatusColor } from '../../Maps/utils/status_get'
import {
  DetailBox,
  DetailBoxInfo,
  DetailButton,
  DetailButtonBox,
  DetailCountry,
  DetailIcon,
  DetailName,
  DetailPlatform,
  DetailPlatformGroup,
  DetailPlatformItem,
  DetailPlatforms,
  DetailPlatformTitle,
} from './style'

type DetailMapItemProps = {
  patient: Patient
  country: Country
}

const DetailMapItem = ({ patient, country }: DetailMapItemProps) => {
  const initialName = `${patient.firstName?.charAt(
    0
  )}${patient.lastName?.charAt(0)}`
  const patientStatus = JSON.parse(
    JSON.stringify(patient.status)
  ) as PatientStatus[]

  const color = getFindStatusColor(patientStatus)

  return (
    <DetailBox>
      <DetailCountry>
        <figure>
          <img src={country.icon} alt={country.name} />
        </figure>
        <span>{country?.name}</span>
      </DetailCountry>

      <DetailBoxInfo>
        <DetailIcon color={color}>{initialName.toUpperCase()}</DetailIcon>
        <DetailName>{`${patient.firstName} ${patient.lastName}`}</DetailName>
      </DetailBoxInfo>

      <DetailPlatforms>
        {['app', 'clinician', 'clinic'].map((platform, indexPlatform) => {
          const listStatusPlatform = getStatusList(
            platform === 'app' ? patientStatus : []
          )

          return (
            <DetailPlatform key={`plat-${indexPlatform}`}>
              <DetailPlatformTitle>{platform}</DetailPlatformTitle>
              <DetailPlatformGroup>
                {listStatusPlatform.map((s, indexItem) => (
                  <DetailPlatformItem
                    key={`item-${platform}-${indexItem}`}
                    {...s.style}
                  >
                    <Icon name={s.icon as ListNames} />
                  </DetailPlatformItem>
                ))}
              </DetailPlatformGroup>
            </DetailPlatform>
          )
        })}
      </DetailPlatforms>
      <DetailButtonBox>
        <DetailButton>See client page</DetailButton>
      </DetailButtonBox>
    </DetailBox>
  )
}

export default DetailMapItem
