/* eslint-disable @typescript-eslint/no-explicit-any */

import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {Button, Input} from '@viswals/components'
import {createOccupation, Request, Occupation} from '@viswals/services'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import {StyledContentOccupation} from './styles'

export default function FormCreateOccupation() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, setValue } = useForm()

  async function onSubmit(data: any) {
    const dataToSend: Occupation = {
      name: data.name,
      enabled: true,
    }

    try {
      setIsError('')
      await createOccupation(dataToSend as Request<Occupation>)
      navigate('/administration/common/occupation')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentOccupation className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Occupation"
            description="Here you can register on new occupation"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/occupation')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentOccupation>
  )
}
