import { Dropout } from '@viswals/services'

import { ActionProps } from './props'

export default function setActionDropouts(dropouts: Dropout[]): ActionProps {
  return {
    type: 'SET_DROPOUT',
    dropouts,
  }
}
