import {
  setDataBanks,
  setDataCities,
  setDataClinicianBirthDate,
} from '@src/store/actions/datas'
import { ReducersProps } from '@src/types/reducers'
import { getCountries } from '@src/utils/getDatas'
import {
  Request,
  retrieveBank,
  BankFilter,
  CityFilter,
  retrieveCity,
  PersonalInformationFilter,
  retrievePersonalInformation,
  PersonalInformation,
  Country,
} from '@viswals/services'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'

type ClincianBirthDateProps = {
  birthdateYear: string
  birthdateMonth: string
  birthdateDay: string
  diffYear: number
}

const useDatas = () => {
  const dispatch = useDispatch()
  const {
    cities,
    banks,
    clinicianBirthDate,
    countries,
    languages,
    occupations,
  } = useSelector((state: ReducersProps) => state.datas)

  async function getCountry() {
    if (!countries.length) {
      getCountries()
    }
  }

  async function getCountryByUuid(
    countryUuid: string
  ): Promise<Country | undefined> {
    const find = countries?.find((f) => f.uuid === countryUuid)
    return find
  }

  async function getBank(countryUuid: string) {
    const find = banks.find((f) => f.countryUuid === countryUuid)
    if (find) {
      return
    }
    const request: Request<BankFilter> = {
      fields: ['uuid', 'name', 'countryUuid'],
      filters: {
        countryUuid,
      },
    }
    try {
      const resp = await retrieveBank(request)
      const { data } = resp
      dispatch(setDataBanks(data.data))
    } catch (e) {
      console.error(e)
    }
  }

  async function getCities(countryUuid: string) {
    const request: Request<CityFilter> = {
      fields: ['uuid', 'name', 'countryUuid'],
      filters: {
        countryUuid,
      },
    }
    const resp = await retrieveCity(request)
    dispatch(setDataCities(resp.data?.data))
  }

  async function getClinicianBirthDate(
    clinicianRegisterUuid: string
  ): Promise<ClincianBirthDateProps> {
    if (Boolean(clinicianBirthDate) === false) {
      let finalReturn: ClincianBirthDateProps
      try {
        const request: Request<PersonalInformationFilter> = {
          fields: ['birthdateYear', 'birthdateMonth', 'birthdateDay'],
          filters: {
            clinicianRegisterUuid,
          },
        }
        const resp = await retrievePersonalInformation(request)
        const data: PersonalInformation | null = resp.data
          ? resp.data.data[0]
          : null
        if (!data) {
          throw new Error('Error')
        }
        const formatStringDate = `${data.birthdateYear}-${data.birthdateMonth}-${data.birthdateDay}`
        const diff = moment().diff(
          moment(formatStringDate, 'YYYY-MM-DD'),
          'years'
        )
        dispatch(
          setDataClinicianBirthDate(
            `${data.birthdateYear}-${data.birthdateMonth}-${data.birthdateDay}`
          )
        )
        finalReturn = {
          birthdateYear: data.birthdateYear as string,
          birthdateMonth: data.birthdateMonth as string,
          birthdateDay: data.birthdateDay as string,
          diffYear: diff,
        }
      } catch (error) {
        finalReturn = {
          birthdateYear: '',
          birthdateMonth: '',
          birthdateDay: '',
          diffYear: 0,
        }
      }
      return finalReturn
    }
    const formatStringDater = `${clinicianBirthDate?.split('-')[0] as string}-${
      clinicianBirthDate?.split('-')[1] as string
    }-${clinicianBirthDate?.split('-')[2] as string}`

    const differ = moment().diff(
      moment(formatStringDater, 'YYYY-MM-DD'),
      'years'
    )

    return {
      birthdateYear: clinicianBirthDate?.split('-')[0] as string,
      birthdateMonth: clinicianBirthDate?.split('-')[1] as string,
      birthdateDay: clinicianBirthDate?.split('-')[2] as string,
      diffYear: differ,
    }
  }

  return {
    cities,
    banks,
    countries,
    languages,
    occupations,
    getCountry,
    getCountryByUuid,
    getBank,
    getCities,
    getClinicianBirthDate,
  }
}

export default useDatas
