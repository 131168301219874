/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button, Input, Upload } from '@viswals/components'
import { Request, Currency, createCurrency } from '@viswals/services'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import { StyledContentLanguage } from './styles'

export default function FormCreateCurrency() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, setValue } = useForm()

  async function onSubmit(data: any) {
    const dataToSend: Currency = {
      name: data.name,
      isoCode: data.isoCode,
      icon: data.icon.file,
      enabled: true,
    }

    try {
      setIsError('')
      await createCurrency(dataToSend as Request<Currency>)
      navigate('/administration/common/currency')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentLanguage className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Currency"
            description="Here you can register on new currency"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Input label="ISO Code" formRegister={{ ...register('isoCode') }} />

          <div className="content-icon-upload">
            <Upload
              label="Icon"
              placeholder="Upload Icon"
              formRegister={{ ...register('icon'), watch, setValue }}
            />
            <div className="box-preview">
              <span className="box-preview-title">Preview</span>
              <div className="box-preview-image">
                <img src={watch('ico.file')} alt="" />
              </div>
            </div>
          </div>

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/currency')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentLanguage>
  )
}
