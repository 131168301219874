import { ActionProps, FeedbacksStoreProps } from './props'

const INITIAL_STATE: FeedbacksStoreProps = {
  feedbacks: [],
}

export default function reducer(
  state = INITIAL_STATE,
  action: ActionProps
): FeedbacksStoreProps {
  if (action.type === 'SET_FEEDBACK') {
    return {
      ...state,
      feedbacks: action.feedbacks,
    }
  }

  return state
}
