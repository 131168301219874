const titleSelect = [
  {
    label: 'Dr',
    value: 'DR',
  },
  {
    label: 'Prof',
    value: 'PROF',
  },
  {
    label: 'Mr',
    value: 'MR',
  },
  {
    label: 'Miss',
    value: 'MISS',
  },
  {
    label: 'Mrs',
    value: 'MRS',
  },
  {
    label: 'Ms',
    value: 'MS',
  },
]

export default titleSelect
