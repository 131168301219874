import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  City,
  CityFilter,
  Country,
  PaginationRequest,
  Request,
  retrieveCity,
} from '@viswals/services'
import gsap from 'gsap'
import { Button } from '@viswals/components-platform'
import useDatas from '@src/hooks/useDatas'

import HeaderContentCommon from '../_components/HeaderContentCommon'
import { StyledContentCity, StyledContentContryTable } from './styles'
import gridStyle from '../_components/grid-style'
import { LoadingTable } from '../_components/loading-table'

export type LocalCitiesAdm = {
  id?: number
} & City

export type LocalPaginationRequestProps = {
  total?: number
} & PaginationRequest

export default function CityTable() {
  const navigate = useNavigate()

  const { countries } = useDatas()

  const [cities, setCities] = useState<LocalCitiesAdm[]>([])
  const [pagination, setPagination] = useState<LocalPaginationRequestProps>({
    itemsPerPage: 25,
    page: 1,
    total: 0,
  })

  useEffect(() => {
    ;(async () => {
      await getCities()
    })()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },

    {
      field: 'countryUuid',
      headerName: 'Country',
      renderCell: (params) => {
        const findCountry: Country | undefined = countries.find(
          (v) => v.uuid === params.row.countryUuid
        )
        return <>{findCountry?.name}</>
      },
      width: 200,
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      renderCell: (params) => {
        const countryToSave: Country = params.row
        return <>{countryToSave.enabled ? 'YES' : 'NO'}</>
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', gap: 20 }}>
            <Button
              label="Edit"
              uuid=""
              style={{ backgroundColor: 'orange', color: 'black' }}
              handleClick={() => {
                const countryToSave: Country = params.row
                window.localStorage.setItem(
                  'cityEdit',
                  JSON.stringify(countryToSave)
                )
                navigate(
                  `/administration/common/city/edit/${countryToSave.uuid}`
                )
              }}
            />
          </div>
        )
      },
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
    },
  ]

  async function getCities(
    localPagination?: LocalPaginationRequestProps | undefined
  ) {
    const paginationed = localPagination
    const request: Request<CityFilter> = {
      fields: ['uuid', 'name', 'countryUuid', 'enabled'],
      pagination: {
        itemsPerPage: 25,
        page: (paginationed?.page as number) ?? 1,
      },
      orderBy: {
        column: 'name',
        direction: 'asc',
      },
    }
    try {
      const resp = await retrieveCity(request)
      const { data } = resp

      if (data.code === 200) {
        const dataCities: City[] = data.data

        const newCities: LocalCitiesAdm[] = dataCities.map((value, _index) => {
          return {
            id: _index,
            uuid: value.uuid,
            name: value.name,
            countryUuid: value.countryUuid,
            enabled: value?.enabled ?? false,
          }
        })
        setCities(newCities)

        setTimeout(() => {
          setPagination(
            (value) =>
              ({
                ...value,
                ...{
                  itemsPerPage: paginationed?.itemsPerPage,
                },
                total: data.pagination.total,
              } as LocalPaginationRequestProps)
          )
        }, 200)
        disabledLoading()
      }
    } catch (e) {
      console.error(e)
    }
  }

  function handleClickAdd() {
    navigate('/administration/common/city/new')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function addPage(page: number) {
    const pageToSet: LocalPaginationRequestProps = {
      itemsPerPage: 25,
      page: page + 1,
    }
    // setPagination(pageToSet)
    try {
      await getCities(pageToSet)
    } catch (error) {
      console.error({
        error,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function enabledLoading() {
    const query = window.document.querySelector('.vw-loadingContainer')
    gsap.to(query, {
      zIndex: 99,
      duration: 0.1,
      onComplete: () => {
        gsap.to(query, {
          opacity: 1,
          delay: 0.1,
          duration: 0.25,
        })
      },
    })
  }

  function disabledLoading() {
    setTimeout(() => {
      const query = window.document.querySelector('.vw-loadingContainer')
      gsap.to(query, {
        opacity: 0,
        duration: 0.25,
        onComplete: () => {
          gsap.to(query, {
            zIndex: -1,
            delay: 0.25,
            duration: 0.25,
          })
        },
      })
    }, 300)
  }

  return (
    <StyledContentCity>
      <HeaderContentCommon
        name="City"
        description="Manager city information"
        onClickAdd={() => handleClickAdd()}
      />

      <StyledContentContryTable>
        <LoadingTable />
        <DataGrid
          sx={gridStyle}
          rows={cities}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          experimentalFeatures={{ newEditingApi: false }}
          onPageChange={(page) => {
            enabledLoading().then(() => {
              setTimeout(() => {
                addPage(page)
              }, 200)
            })
          }}
          pagination
          rowsPerPageOptions={[25]}
          rowCount={pagination.total}
          initialState={{
            pagination: {
              pageSize: pagination.itemsPerPage,
            },
          }}
          paginationMode="server"
        />
      </StyledContentContryTable>
    </StyledContentCity>
  )
}
