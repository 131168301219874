import { FishPoolLineCircleBox } from './style'

type FishPoolLineCircleProps = {
  color: string
}

const FishPoolLineCircle = ({ color }: FishPoolLineCircleProps) => {
  return (
    <FishPoolLineCircleBox color={color}>
      <div className="line l1">
        <div className="line l2">
          <div className="line l3" />
        </div>
      </div>
    </FishPoolLineCircleBox>
  )
}

export default FishPoolLineCircle
