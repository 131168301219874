/* eslint-disable @typescript-eslint/no-explicit-any */

import {useState} from 'react'
import {useForm} from 'react-hook-form'
import {useNavigate} from 'react-router-dom'

import {Button, Input} from '@viswals/components'
import {createSport, Request, Sport} from '@viswals/services'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import {StyledContentSport} from './styles'

export default function FormCreateSport() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, setValue } = useForm()

  async function onSubmit(data: any) {
    const dataToSend: Sport = {
      name: data.name,
      enabled: true,
    }

    try {
      setIsError('')
      await createSport(dataToSend as Request<Sport>)
      navigate('/administration/common/sport')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentSport className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Sport"
            description="Here you can register on new sport"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/sport')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentSport>
  )
}
