/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { ColorPickSquare, ContainerColorPicker } from './WalletStyles'

export type ColorPickerProps = {
  colors: string[]
  refColors?: any
  indexCard?: number
  handleSelectColor: (indexCard: number, color: string) => void
  color?: string
}

const ColorPicker = ({
  color,
  colors,
  refColors,
  indexCard,
  handleSelectColor,
}: ColorPickerProps) => {
  return (
    <ContainerColorPicker
      ref={(ref) => {
        refColors.current[indexCard as number] = ref
      }}
    >
      <div className="title">Choose your bank Color</div>
      <div className="contentColors">
        {colors.map((colored, _index) => {
          const selectedColor = colored === color
          return (
            <ColorPickSquare
              key={`${color}_${_index}}`}
              color={colored}
              onClick={() => {
                handleSelectColor(indexCard as number, colored)
              }}
              isSelected={selectedColor}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                aria-hidden="true"
                focusable="false"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="3"
                  d="M1.73 12.91l6.37 6.37L22.79 4.59"
                />
              </svg>
            </ColorPickSquare>
          )
        })}
      </div>
    </ContainerColorPicker>
  )
}

export default ColorPicker
