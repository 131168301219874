import React from 'react'
import { Card, Input, Select } from '@viswals/components'

import City from '@images/city.svg'
import Address from '@images/address.svg'
import CountryResidence from '@images/countryResidence.svg'

import { Container } from './ResidenceInformationStyles'

import useLogic from './useLogic'

const StepResidenceInformation = () => {
  const {
    errors,
    verified,
    selectResidenceCountry,

    handleSubmit,
    onSubmit,
    register,
    watch,
    citiesLocal,
    setValue,
    getFieldProps,
    getCities,
  } = useLogic()

  return (
    <Container>
      <div className="vw-residenceInformation-header">
        <h2>Residence Information</h2>
        <span>Input your reside information below</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-residenceInformation-cardArea">
          <Card isVerified={verified} isError={Boolean(errors?.country)}>
            <div className="vw-residenceInformation-img">
              <img src={CountryResidence} alt="Select country" />
            </div>
            <div>
              <p>Your country of residence?</p>
              <div className="vw-residenceInformation-oldForm">
                <Select
                  labelTitle="Select a country"
                  options={selectResidenceCountry}
                  placeholder="Select country"
                  search
                  formRegister={{
                    ...register('country', {
                      required: true,
                      onChange: (e) => {
                        if (e.target.value) {
                          const countryUuid = e.target.value
                          getCities(countryUuid)
                          setValue('city', null)
                        }
                      },
                    }),
                    watch,
                  }}
                  placement="top"
                  {...getFieldProps('country')}
                />
              </div>
            </div>
          </Card>

          <Card isVerified={verified} isError={Boolean(errors?.city)}>
            <div className="vw-residenceInformation-img img2">
              <img src={City} alt="Select your city" />
            </div>
            <div>
              <p className="vw-residenceInformation-labelText">
                What is your City?
              </p>

              <div className="vw-residenceInformation-formCard2">
                <Select
                  labelTitle="Select a city"
                  options={citiesLocal()}
                  placeholder="Select city"
                  search
                  formRegister={{
                    ...register('city', {
                      onChange: (e) => {
                        if (e.target.value) {
                          setValue('newCity', undefined)
                        }
                      },
                    }),
                    watch,
                  }}
                  placement="top"
                  {...getFieldProps('city')}
                />
                <Input
                  placeholder="If other, insert here"
                  formRegister={register('newCity', {
                    onChange: (e) => {
                      if (e.target.value) {
                        setValue('city', undefined)
                      }
                    },
                  })}
                  {...getFieldProps('newCity')}
                />
              </div>
            </div>
          </Card>

          <Card
            isVerified={verified}
            isError={Boolean(errors?.address) || Boolean(errors?.postCode)}
          >
            <div className="vw-residenceInformation-img img3">
              <img src={Address} alt="Select your address" />
            </div>
            <div>
              <p>What is your address?</p>
              <div className="vw-residenceInformation-oldForm">
                <Input
                  placeholder="Your address"
                  formRegister={{
                    ...register('address', {
                      required: true,
                    }),
                  }}
                  {...getFieldProps('address')}
                />
                <Input
                  placeholder="Post Code"
                  formRegister={{
                    ...register('postCode', {
                      required: true,
                    }),
                  }}
                  {...getFieldProps('postCode')}
                />
              </div>
            </div>
          </Card>
        </div>
      </form>
    </Container>
  )
}

export default StepResidenceInformation
