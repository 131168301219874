import * as React from "react";

const ArrowUpFill = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={7.821}
        height={3.911}
        viewBox="0 0 7.821 3.911"
        {...props}
    >
        <path
            id="Path_109764"
            data-name="Path 109764"
            d="M.145,3.782a.5.5,0,0,0,.344.129H7.332a.5.5,0,0,0,.348-.129.4.4,0,0,0,0-.611L4.258.129a.522.522,0,0,0-.683,0l0,0L.145,3.171a.394.394,0,0,0,0,.611"
            fill={props.color || "#636366"}
        />
    </svg>
);
export default ArrowUpFill;