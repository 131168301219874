import { Card, Radio, Select } from '@viswals/components'

import Gender from '@images/gender.svg'
import DateOfBirth from '@images/dateOfBirth.svg'
import Nationality from '@images/nationality.svg'

import { daySelect, genderSelect, monthSelect, yearSelect } from './data'
import { Container } from './PersonalInformationStyles'
import useLogic from './useLogic'

const StepPersonalInformation = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    watch,
    getFieldProps,
    errors,
    verified,
    newDataCountries,
  } = useLogic()

  return (
    <Container>
      <div className="vw-personalInformation-header">
        <h2>Personal Information</h2>
        <span>Input your personal information below</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-personalInformation-cardArea">
          <Card
            isVerified={verified}
            isError={Boolean(
              errors?.birthdateYear ||
                errors?.birthdateMonth ||
                errors?.birthdateDay
            )}
          >
            <div className="vw-personalInformation-img">
              <img src={DateOfBirth} alt="Select your date of birth" />
            </div>
            <div>
              <p>What is your date of birth?</p>
              <div className="vw-personalInformation-oldForm">
                <Select
                  labelTitle="Select the year"
                  options={yearSelect}
                  placeholder="Select year of birth"
                  formRegister={{
                    ...register('birthdateYear', {
                      required: true,
                    }),
                    watch,
                  }}
                  {...getFieldProps('birthdateYear')}
                  placement="top"
                  search
                />
                <Select
                  labelTitle="Select the  month"
                  options={monthSelect}
                  placeholder="Select month of birth"
                  formRegister={{
                    ...register('birthdateMonth', {
                      required: true,
                    }),
                    watch,
                  }}
                  {...getFieldProps('birthdateMonth')}
                  placement="top"
                />
                <Select
                  labelTitle="Select the day"
                  options={daySelect}
                  placeholder="Select day of birth"
                  formRegister={{
                    ...register('birthdateDay', {
                      required: true,
                    }),
                    watch,
                  }}
                  {...getFieldProps('birthdateDay')}
                  placement="top"
                />
              </div>
            </div>
          </Card>

          <Card isVerified={verified} isError={Boolean(errors?.gender)}>
            <div className="vw-personalInformation-img img2">
              <img src={Gender} alt="Select your Gender" />
            </div>
            <div>
              <p>What is your gender?</p>

              <div className="vw-personalInformation-genderSelectArea">
                {genderSelect.map((item, index) => {
                  return (
                    <label
                      key={`item.label_${index}`}
                      htmlFor={`item.label_${index}`}
                      className={`${
                        verified ? 'isDisabled' : ''
                      } ${getFieldProps('gender')} `}
                    >
                      <p>{item.label}</p>
                      <Radio
                        formRegister={{
                          ...register('gender', {
                            required: true,
                          }),
                          watch,
                        }}
                        value={item.value}
                        id={`item.label_${index}`}
                        {...getFieldProps('gender')}
                      />
                    </label>
                  )
                })}
              </div>
            </div>
          </Card>

          <Card isVerified={verified} isError={Boolean(errors?.nationality)}>
            <div className="vw-personalInformation-img img3">
              <img src={Nationality} alt="Nationality" />
            </div>
            <div className="vw-personalInformation-oldForm nationality">
              <p>What is your Nationality?</p>
              <div className="vw-contentSelect">
                <Select
                  labelTitle="Select Nationality"
                  options={newDataCountries}
                  placeholder="Select Nationality"
                  formRegister={{
                    ...register('nationality', {
                      required: true,
                    }),
                    watch,
                  }}
                  search
                  placement="top"
                  {...getFieldProps('nationality')}
                />
              </div>
            </div>
          </Card>
        </div>
      </form>
    </Container>
  )
}

export default StepPersonalInformation
