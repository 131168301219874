import { AlertModal } from '@viswals/components-platform'
import { AccountStatusProps } from '@src/types/account'
import { ModalAlertBox } from './style'

export type OpenTypeProps = AccountStatusProps | 'RESET-PASSWORD' | 'DELETE'

export type ModalAlertProps = {
  openType: OpenTypeProps | null
  handleAction: (action: OpenTypeProps | null) => void
}

const ModalAlert = ({ openType, handleAction }: ModalAlertProps) => {
  if (!openType) {
    return <div />
  }

  function getTitle() {
    switch (openType) {
      case 'ACTIVE':
        return 'You are about to Activate a CC User'
      case 'DELETE':
        return 'You are about to delete a CC User'
      case 'SUSPEND':
        return 'You are about to suspend a CC User'
      default:
        return 'You are about to reset the password of a CC User'
    }
  }

  function getSubtitle() {
    switch (openType) {
      case 'ACTIVE':
        return 'Are you sure you want to remove the suspension and activate this user?'
      case 'DELETE':
        return 'Are you sure you want to delete?'
      case 'SUSPEND':
        return 'Are you sure you want to suspend?'
      default:
        return 'Are you sure you want to reset?'
    }
  }

  function getTextButton() {
    switch (openType) {
      case 'ACTIVE':
        return 'Activate User'
      case 'DELETE':
        return 'Delete'
      case 'SUSPEND':
        return 'Suspend User'
      default:
        return 'Reset'
    }
  }

  return (
    <ModalAlertBox openType={openType}>
      <AlertModal
        title={getTitle()}
        message={getSubtitle()}
        handleCancel={() => handleAction(null)}
        handleConfirm={() => handleAction(openType)}
        labelCancel="Cancel"
        labelConfirm={getTextButton()}
      />
    </ModalAlertBox>
  )
}

export default ModalAlert
