/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Request, Allergy, updateAllergy } from '@viswals/services'
import {Button, Input, Select, SelectedOptionsProps, Toggle, Upload} from '@viswals/components'

import { StyledContentAllergy } from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'

export default function FormUpdateAllergy() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, reset, control, setValue } = useForm()

  const listGroups = [
    {label: 'Most Common', value: 'MOST'},
    {label: 'Less Common', value: 'LESS'}] as SelectedOptionsProps[];

  useEffect(() => {
    ;(async () => {
      const getCurrencyToSave: Allergy = JSON.parse(
        window.localStorage.getItem('currencyEdit') as string
      )
      reset({
        ...getCurrencyToSave,
      })
    })()
  }, [])

  async function onSubmit(data: any) {
    const dataToSend: Allergy = {
      uuid: watch('uuid'),
      name: data.name,
      groupName: data.groupName,
      enabled: data.enabled,
    }

    try {
      setIsError('')
      await updateAllergy(dataToSend as Request<Allergy>)
      window.localStorage.removeItem('allergyEdit')
      navigate('/administration/common/allergy')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentAllergy className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Edit Currency"
            description="Here you can edit allergy"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Select
              label="Group"
              labelTitle="Groupies"
              options={listGroups}
              formRegister={{ ...register('groupName'), watch }}
          />

          <div className="content-toggle-switch">
            <Toggle isBig name="enabled" control={control} />
            <div className="box-preview">Enable Currency</div>
          </div>
          <Button label="Save" type="submit" />
          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/allergy')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentAllergy>
  )
}
