import { Card, Input, Radio, Select } from '@viswals/components'
import CurrentlyPracticing from '@images/currentlyPracticing.svg'
import ModePracticing from '@images/modePracticing.svg'
import YearsPracticing from '@images/yearsPracticing.svg'
import YearsPracticed from '@images/yearsPracticed.svg'
import NotPracticing from '@images/notPracticing.svg'
import PracticedBefore from '@images/practicedBefore.svg'
import { Container } from './PracticingStyle'
import useLogic from './useLogic'

const StepPracticing = () => {
  const {
    monthSelect,
    yearSelectAgo,
    handleSubmit,
    onSubmit,
    register,
    watch,
    currentlyPracticing,
    practicedBefore,
    getFieldProps,
    errors,
    verified,
  } = useLogic()

  return (
    <Container>
      <div className="vw-practicing-header">
        <h2>Practicing</h2>
        <span>Update your professional activity below</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="hidden"
          {...register('practiceStartDate', {
            required: Boolean(practicedBefore !== '0'),
          })}
        />
        <input
          type="hidden"
          {...register('practiceEndDate', {
            required: Boolean(
              currentlyPracticing === '0' && practicedBefore !== '0'
            ),
          })}
        />
        <div className="vw-practicing-cardArea">
          <Card isVerified={verified} {...getFieldProps('currentlyPracticing')}>
            <div className="vw-practicing-img">
              <img src={CurrentlyPracticing} alt="Select your date of birth" />
            </div>
            <div>
              <p>Currently Practicing?</p>
              <div className="vw-practicing-radioSelectArea form">
                <label>
                  <p>Yes</p>
                  <Radio
                    formRegister={{
                      ...register('currentlyPracticing', {
                        required: true,
                      }),
                      watch,
                    }}
                    value="1"
                    isDisabled
                  />
                </label>
                <label>
                  <p>No</p>
                  <Radio
                    formRegister={{
                      ...register('currentlyPracticing', {
                        required: true,
                      }),
                      watch,
                    }}
                    value="0"
                    isDisabled
                  />
                </label>
              </div>
            </div>
          </Card>

          {/** ****  2º card do 1º cenário *********/}
          {currentlyPracticing !== '0' && (
            <Card
              isVerified={verified}
              {...getFieldProps('currentModePracticing')}
            >
              <div className="vw-practicing-img img2">
                <img src={ModePracticing} alt="Select your Gender" />
              </div>
              <div>
                <p>Current Mode of Practicing</p>

                <div className="vw-practicing-radioSelectArea form">
                  <label>
                    <p>Full time</p>
                    <Radio
                      formRegister={{
                        ...register('currentModePracticing', {
                          required: Boolean(currentlyPracticing !== '0'),
                        }),
                        watch,
                      }}
                      value="FULL_TIME"
                      isDisabled
                    />
                  </label>
                  <label>
                    <p>Part-Time</p>
                    <Radio
                      formRegister={{
                        ...register('currentModePracticing', {
                          required: Boolean(currentlyPracticing !== '0'),
                        }),
                        watch,
                      }}
                      value="PART_TIME"
                      isDisabled
                    />
                  </label>
                </div>
              </div>
            </Card>
          )}

          {/** ****  2º card do 2º cenário *********/}
          {currentlyPracticing === '0' && (
            <>
              <Card isVerified={verified} {...getFieldProps('practicedBefore')}>
                <div className="vw-practicing-img img4">
                  <img src={PracticedBefore} alt="Select your Gender" />
                </div>
                <div>
                  <p>Have you practiced before</p>

                  <div className="vw-practicing-radioSelectArea form">
                    <label>
                      <p>Yes</p>
                      <Radio
                        formRegister={{
                          ...register('practicedBefore', {
                            required: Boolean(currentlyPracticing === '0'),
                          }),
                          watch,
                        }}
                        value="1"
                        isDisabled
                      />
                    </label>
                    <label>
                      <p>No</p>
                      <Radio
                        formRegister={{
                          ...register('practicedBefore', {
                            required: Boolean(currentlyPracticing === '0'),
                          }),
                          watch,
                        }}
                        value="0"
                        isDisabled
                      />
                    </label>
                  </div>
                </div>
              </Card>

              {practicedBefore === '0' && (
                <Card isVerified={verified} {...getFieldProps('reason')}>
                  <div className="vw-practicing-img img5">
                    <img src={NotPracticing} alt="Select your Gender" />
                  </div>
                  <div>
                    <p>Reason for not having practiced before</p>

                    <div className="vw-practicing-radioSelectArea form">
                      <label>
                        <p>Actively searching for the first dental job</p>
                        <Radio
                          formRegister={{
                            ...register('reason', {
                              required: Boolean(practicedBefore === '0'),
                            }),
                            watch,
                          }}
                          value="SEARCHING_FIRST_JOB"
                          isDisabled
                        />
                      </label>
                      <label>
                        <p>Full time Post Graduation study</p>
                        <Radio
                          formRegister={{
                            ...register('reason', {
                              required: Boolean(practicedBefore === '0'),
                            }),
                            watch,
                          }}
                          value="GRADUATION_STUDY"
                          isDisabled
                        />
                      </label>
                      <label>
                        <p>No specific reason</p>
                        <Radio
                          formRegister={{
                            ...register('reason', {
                              required: Boolean(practicedBefore === '0'),
                            }),
                            watch,
                          }}
                          value="NO_SPECIFIC"
                          isDisabled
                        />
                      </label>
                    </div>
                  </div>
                </Card>
              )}
            </>
          )}

          {(currentlyPracticing !== '0' || practicedBefore !== '0') && (
            <Card
              isVerified={verified}
              isError={Boolean(
                errors?.practiceStartDate || errors?.practiceEndDate
              )}
            >
              <div className="vw-practicing-img img3">
                <img
                  src={
                    currentlyPracticing !== '0'
                      ? YearsPracticing
                      : YearsPracticed
                  }
                  alt="Nationality"
                />
              </div>
              <div>
                <p>
                  {currentlyPracticing !== '0'
                    ? 'Years in Practice'
                    : 'For how manYears in Practicey years have you practiced?'}
                </p>

                <div className="vw-practicing-radioSelectArea form">
                  <div
                    className={`selectArea ${
                      getFieldProps('startMonth').isError ||
                      getFieldProps('startYear').isError
                        ? 'isError'
                        : ''
                    }`}
                  >
                    <p>Start date</p>
                    <div className="selectArea">
                      <Select
                        labelTitle="Select Month"
                        options={monthSelect}
                        placeholder="Month"
                        formRegister={{
                          ...register('startMonth'),
                          watch,
                        }}
                        placement="top"
                        {...getFieldProps('startMonth')}
                      />
                      <Select
                        labelTitle="Select Year"
                        options={yearSelectAgo}
                        placeholder="Year"
                        formRegister={{
                          ...register('startYear', {}),
                          watch,
                        }}
                        placement="top"
                        {...getFieldProps('startYear')}
                      />
                    </div>
                  </div>
                  {currentlyPracticing !== '0' ? (
                    <div>
                      <p>Current date</p>
                      <div className="inputArea">
                        <Input
                          placeholder={
                            monthSelect.find(
                              (f) => f.value === new Date().getMonth()
                            )?.label
                          }
                          isDisabled
                        />
                        <Input
                          placeholder={yearSelectAgo[0]?.label}
                          isDisabled
                        />
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`selectArea ${
                        getFieldProps('endMonth').isError ||
                        getFieldProps('endYear').isError
                          ? 'isError'
                          : ''
                      }`}
                    >
                      <p>End date</p>
                      <div className="selectArea">
                        <Select
                          labelTitle="Select Month"
                          options={monthSelect}
                          placement="top"
                          placeholder="Month"
                          formRegister={{
                            ...register('endMonth', {}),
                            watch,
                          }}
                          {...getFieldProps('endMonth')}
                        />
                        <Select
                          labelTitle="Select Year"
                          placement="top"
                          options={yearSelectAgo}
                          placeholder="Year"
                          formRegister={{
                            ...register('endYear', {}),
                            watch,
                          }}
                          {...getFieldProps('endYear')}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          )}
        </div>
      </form>
    </Container>
  )
}

export default StepPracticing
