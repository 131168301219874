import { ListNames } from '@viswals/components-platform'

import { AccountGroup } from '@src/types/account'

import GroupMenuItem from './GroupMenuItem'
import { GroupMenuBox } from './style'

type SuperAdministratorGroupMenuProps = {
  groups: AccountGroup[]
}

const SuperAdministratorGroupMenu = ({
  groups,
}: SuperAdministratorGroupMenuProps) => {
  return (
    <GroupMenuBox>
      <div className="boxTitle">
        <h4>Viswals Global Team</h4>
        <div className="boxHighlight">
          <span>T</span>
          <span className="value">0</span>
        </div>
      </div>
      {groups.map((item, index) => (
        <GroupMenuItem
          key={`menuItem-${index}`}
          label={item.name}
          route={`/super-administrator/${item.slug}`}
          total={0}
          totalOpen={0}
          icon={item.icon as ListNames}
        />
      ))}
    </GroupMenuBox>
  )
}

export default SuperAdministratorGroupMenu
