import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateDisease from './form_create'
import FormUpdateDisease from './form_update'
import DiseaseTable from './table'

export default function HobbyPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <DiseaseTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateDisease />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateDisease />
          </Layout>
        }
      />
    </Routes>
  )
}
