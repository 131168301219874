import IconVerificationPhoto from '@images/iconVerificationPhoto.svg'
import IconVerificationSelfiePhoto from '@images/iconVerificationSelfiePhoto.svg'
import IconVerificationSelfieVideo from '@images/iconVerificationSelfieVideo.svg'
import IconVerificationProfilePhoto from '@images/iconVerificationProfilePhoto.svg'

import StatusVerified from '@images/statusVerified.svg'
import StatusQuestionRaised from '@images/statusQuestion.svg'
import StatusError from '@images/statusError.svg'
import {
  PhotoVerificationArea,
  PhotoVerificationBox,
  PhotoVerificationContent,
  PhotoVerificationHeaderBox,
} from './style'
import { VerificationStatusProps } from '../../type'
import FaceDetection from '../FaceDetection'

type PhotoVerificationProps = {
  type: 'PHOTO' | 'SELFIE_PHOTO' | 'SELFIE_VIDEO' | 'PROFILE_PHOTO'
  status?: VerificationStatusProps
  url: string
}

const PhotoVerification = (props: PhotoVerificationProps) => {
  function getIcon() {
    switch (props.type) {
      case 'SELFIE_PHOTO':
        return IconVerificationSelfiePhoto
      case 'SELFIE_VIDEO':
        return IconVerificationSelfieVideo
      case 'PROFILE_PHOTO':
        return IconVerificationProfilePhoto
      default:
        return IconVerificationPhoto
    }
  }

  function getColor() {
    switch (props.status) {
      case 'VERIFIED':
        return '#5BDF6F'
      case 'QUESTION_RAISED':
        return '#FFB23B'
      case 'ERROR':
        return '#F72A1E'
      default:
        return '#8E8E93'
    }
  }

  function getStatusIcon() {
    switch (props.status) {
      case 'VERIFIED':
        return StatusVerified
      case 'QUESTION_RAISED':
        return StatusQuestionRaised
      default:
        return StatusError
    }
  }
  function getTitle(): string {
    switch (props.type) {
      case 'SELFIE_PHOTO':
        return 'Selfie verification Photo'
      case 'SELFIE_VIDEO':
        return 'Selfie verification video'
      case 'PROFILE_PHOTO':
        return 'Profile verification Photo'
      default:
        return 'ID verification photo'
    }
  }

  function getFile() {
    if (props.type === 'SELFIE_VIDEO') {
      return (
        <video width="100%" height="100%" controls muted>
          <source src={props.url} type="video/mp4" />
        </video>
      )
    }
    if (props.type === 'PHOTO') {
      return <FaceDetection imageBase64={props.url} showFace />
    }

    return <img src={props.url} alt="Verification" />
  }

  return (
    <PhotoVerificationContent>
      <PhotoVerificationHeaderBox color={getColor()}>
        <div className="title">{getTitle()}</div>
        <figure>
          {props?.status ? <img src={getStatusIcon()} alt="status" /> : <div />}
        </figure>
      </PhotoVerificationHeaderBox>
      <PhotoVerificationBox>
        <PhotoVerificationArea color={getColor()}>
          <div className="iconType">
            <img src={getIcon()} alt="icon" />
          </div>

          <div className="line b1">
            <div className="line b2">
              <div className="line b3">
                <div className="line b4">
                  <figure>{getFile()}</figure>
                </div>
              </div>
            </div>
          </div>
        </PhotoVerificationArea>
      </PhotoVerificationBox>
    </PhotoVerificationContent>
  )
}

export default PhotoVerification
