const useAuth = () => {
  const email = localStorage.getItem('access_token_email') ?? null
  const token = localStorage.getItem('access_token') ?? null

  if (!email || !token) {
    return false
  }
  return true
}

export default useAuth
