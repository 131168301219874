import React from 'react'
import moment from 'moment'
import { AccountStatus, PlatformType } from '@viswals/services'
import { Icon, ListNames } from '@viswals/components-platform'

import ContainerItemList from './styles'
import { ItemGlobalAccount } from '../../data/data'

export type ItemListGlobalAccount = {
  item: ItemGlobalAccount
}

export default function ItemListGlobalAccount({ item }: ItemListGlobalAccount) {
  function getPlatformInfo(value?: PlatformType): {
    icon?: ListNames
    label?: string
  } {
    if (value === 'PATIENT') {
      return {
        label: 'App',
        icon: 'ViswalsApp',
      }
    }
    if (value === 'CLINICIAN') {
      return {
        label: 'Clinician',
        icon: 'Dentist1',
      }
    }
    return {
      label: 'Clinic',
      icon: 'ViswalsClinics',
    }
  }

  function getAccountStatus(value?: AccountStatus) {
    if (value === 'SIGN_UP') {
      return 'Sign Up'
    }
    if (value === 'ACQUIRED') {
      return 'Acquired'
    }
    return 'Dropouts'
  }

  const itemPlatform = item.platform ?? 'PATIENT'

  return (
    <ContainerItemList>
      <div className="item-of-list first">
        <div className={`black ${itemPlatform}`}>
          <div className="box-icon">
            <Icon name={getPlatformInfo('PATIENT').icon ?? 'Dentist1'} />
          </div>
          {getPlatformInfo(itemPlatform).label}
        </div>
      </div>
      <div className="item-of-list date">
        {moment(item.createdAt).format('DD.MM.YYYY')}
      </div>
      <div className="item-of-list time">
        {moment(item.createdAt).format('HH:mm')}
      </div>
      <div className="item-of-list country">{item.countryUuid}</div>
      <div className="item-of-list name">
        {item.firstName} {item.lastName}
      </div>
      <div className="item-of-list email">{item.email}</div>
      <div className="item-of-list global">{item.accountNumber ?? ''}</div>
      <div className="item-of-list app"> </div>
      <div className="item-of-list clinician"> </div>
      <div className="item-of-list clinic"> </div>
      <div className="item-of-list">
        <div className={`button-status ${item.state}`}>
          {getAccountStatus(item.state)}
        </div>
      </div>
      <div className="item-of-list">
        <div className="button-link">See Page</div>
      </div>
    </ContainerItemList>
  )
}
