import styled from 'styled-components'

export const ContainerLanguages = styled.section`
  width: 100%;
  height: 100%;

  padding: 4.3rem 5rem 2.5rem 5.1rem;

  position: relative;

  h1 {
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: var(--gray-100-color);
  }
  p {
    max-width: 28.2rem;
    margin-top: 0.3rem;
    font-size: 1.3rem;
    font-weight: 400;
    font-family: var(--font-primary);
    color: var(--gray-200-color);
    line-height: 2rem;
    margin-bottom: 5.4rem;
  }
  .vw-regis-boxButtonsFload {
    position: absolute;
    top: 8rem;
    right: 5rem;
    display: flex;

    &.Verified {
      div {
        &:first-child {
          margin-right: 0;
        }
      }
    }
    .vw-button-buttonstyle {
      width: 15.9rem;
      height: 4.2rem;
      border-radius: 0.5rem;
      background-color: var(--info-color);
      padding: 0.9rem 0.9rem 0.9rem 0.5rem;
      span {
        margin-left: 0.5rem;
        margin-bottom: 0.1rem;
        font-family: var(--font-primary);
        font-size: 1.2rem;
      }
    }

    .boxDeleteCard {
      margin-right: 1rem;
      .vw-button-buttonstyle {
        width: 14.9rem;
        height: 4.2rem;
        border-radius: 0.5rem;
        background-color: var(--red-color);
        padding: 0.9rem 0rem 0.9rem 0.1rem;
        span {
          margin-left: 0rem;
          margin-bottom: 0.1rem;
          font-family: var(--font-primary);
          font-size: 1.2rem;
        }
      }
    }
  }

  .vw-questionModal-description {
    width: 39.9rem !important;
    min-width: 39.9rem !important;
    margin-bottom: 5.6rem;
    margin-top: -0.3rem;

    .modalDescription {
      width: 35.9rem;
    }
  }
`
export const BoxContentCards = styled.div`
  width: 147.2rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  .card-item {
    width: 100%;
    max-width: 47.3rem;
    margin-right: 2.5rem;
  }
  .vw-card-cardBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 2.5rem;
    margin-right: 2.6rem;
    :last-of-type {
      margin-right: 0;
    }
    article {
      width: 100%;
      max-width: 47.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      :last-of-type {
        margin-right: 0;
      }
    }
    img {
      margin-top: 6.3rem;
      margin-bottom: 5.85rem;
      margin-right: 0.1rem;
    }
    .vw-select-boxContentInput {
      .vw-Select-BoxLabel {
        .vw-select-boxLabelText {
          margin-left: -0.1rem;
        }
      }
      input {
        margin-left: -0.3rem;
      }
    }
    .vw-regis-contentSelect {
      width: 100%;
      height: 5.2rem;
      background-color: var(--content-color);
      margin-top: 1rem;
      border-radius: 0.5rem;

      padding: 0 1rem 0 2rem;

      display: flex;
      align-items: center;
      justify-content: space-between;
      .vw-regis-cotentSelectLabel {
        font-size: 1.4rem;
        font-family: var(--font-secondary);
        color: #798089;
        margin-left: -0.1rem;
      }
      .vw-select-boxContentInput {
        width: 20.8rem;

        input {
          margin-left: -1.3rem;
        }

        .vw-select-BoxInput:first-child {
          background-color: var(--dark-color) !important;
          height: 3.4rem;
          .vw-select-boxIconRight {
            svg {
              margin-left: 2rem;
              margin-top: 0.1rem;
            }
          }
        }
      }
    }
    .vw-regis-contentSelect:nth-child(3) {
      .vw-select-boxContentInput {
        .vw-selectOptions-boxSelectOptions {
          width: 19.3rem;
          .vw-selectOptions-boxSelectOptionsLabel {
            font-size: 1.4rem;
            font-family: var(--font-primary);
            font-weight: 700;
            margin-bottom: 0.9rem;
            background-color: #24242670;
          }
          .vw-selectOptions-boxScrollOptions {
            .vw-selectOptions-boxContentScrollItem {
              .vw-selectOptions-boxScrollItemOptions {
                font-size: 1.2rem;
                font-family: var(--font-primary);
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .vw-regis-contentSelect:nth-child(4) {
      .vw-select-boxContentInput {
        .vw-selectOptions-boxSelectOptions {
          width: 19.3rem;
          .vw-selectOptions-boxSelectOptionsLabel {
            font-size: 1.4rem;
            font-family: var(--font-primary);
            font-weight: 700;
            margin-bottom: 0.9rem;
          }
          .vw-selectOptions-boxScrollOptions {
            .vw-selectOptions-boxContentScrollItem {
              .vw-selectOptions-boxScrollItemOptions {
                font-size: 1.2rem;
                font-family: var(--font-primary);
                font-weight: 700;
              }
            }
          }
        }
      }
    }
    .vw-regis-contentSelect:nth-child(5) {
      .vw-select-boxContentInput {
        .vw-selectOptions-boxSelectOptions {
          width: 19.3rem;
          .vw-selectOptions-boxSelectOptionsLabel {
            font-size: 1.4rem;
            font-family: var(--font-primary);
            font-weight: 700;
            margin-bottom: 0.9rem;
          }
          .vw-selectOptions-boxScrollOptions {
            .vw-selectOptions-boxContentScrollItem {
              .vw-selectOptions-boxScrollItemOptions {
                font-size: 1.2rem;
                font-family: var(--font-primary);
                font-weight: 700;
                word-spacing: 1rem;
              }
            }
          }
        }
      }
    }
    // Style for all SelectOptions
    .vw-select-boxContentInput {
      .vw-selectOptions-boxSelectOptions {
        width: 23rem;
      }
    }
  }
`

export const SafeAreaClickDelete = styled.div`
  width: 100%;
`
