import { ActionProps, RateStoreProps } from './props'

const INITIAL_STATE: RateStoreProps = {
  rates: [],
}

export default function reducer(
  state = INITIAL_STATE,
  action: ActionProps
): RateStoreProps {
  if (action.type === 'SET_FEEDBACK') {
    return {
      ...state,
      rates: action.rates,
    }
  }

  return state
}
