import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-termsOfService-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-termsOfService-cardArea {
    display: flex;
    .vw-card-cardBox {
      width: 48.4rem;
      padding: 0 2.5rem 2.5rem;
      margin-right: 2.5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .vw-termsOfService-img {
        height: 30.3rem;
        padding-top: 6.7rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .img_01 {
          width: 15.8rem;
          padding-top: 1.3rem;
        }
        .img_02 {
          width: 15.45rem;
        }
        .img_03 {
          width: 11.7rem;
        }
      }

      .descriptionArea {
        width: 100%;
        height: 16.7rem;
        margin-bottom: 3.2rem;
        text-align: left;
        overflow-y: scroll !important;

        /* ::-webkit-scrollbar {
          width: 0.8rem;
          display: flex;

          background-color: #1c1c1e !important;
          border-radius: 8rem;
          padding: 0.1rem !important;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #242426 !important;
          border-radius: 8rem;
          border: 2px solid #242426;
        } */

        > h2 {
          font-size: 1.4rem;
          font-weight: 700;
          color: #bebebe;
          padding-top: 0.3rem;
        }

        .description {
          max-height: 14.1rem;
          height: 100%;
          width: 100%;
          margin: 0.6rem 0 2.5rem;

          > p {
            font-family: var(--font-secondary);
            font-size: 1rem;
            line-height: 1.5rem;
            color: #798089;

            margin-bottom: 1.5rem;
            padding-right: 0.6rem;
          }
        }
      }

      .toggleArea {
        display: flex;
        justify-content: space-between;
        height: 6.8rem;
        padding: 2.7rem 2.3rem 2.7rem 2rem;
        background-color: #333843;
        border-radius: 0.5rem;
        opacity: 0.4;

        transition: opacity 0.4s ease-in-out;

        position: relative;

        > p {
          font-family: var(--font-secondary);
          font-size: 1.2rem;
          color: #798089;
          margin-top: -0.1rem;
        }

        &.enabled {
          opacity: 1;
          .contentBlock {
            display: none;
          }
        }
        .contentBlock {
          width: 3em;
          height: 20px;
          position: absolute;
          bottom: 2.4rem;
          right: 2.2rem;
          z-index: 99;
          cursor: pointer;
        }
      }
    }
  }
`

export default Container
