import styled, { css } from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.3rem 2.5rem 2.5rem 5rem;

  .vw-wallet-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }

    .buttonArea {
      display: flex;
      padding-right: 1rem;
      align-items: flex-end;

      .vw-contentButtonAdd {
        button {
          width: 13.5rem;
          height: 4.2rem;
          margin-top: 3.6rem;
          margin-right: 2.5rem;
          padding: 1rem;
          border-radius: 0.5rem;
          background-color: #19aeff;

          span {
            font-family: var(--font-primary);
            font-size: 1.2rem;
            color: #fff;
            padding: 0;
            padding-bottom: 0.2rem;
          }
        }
      }
      .DangerButton {
        button {
          width: 13.5rem;
          height: 4.2rem;
          margin-top: 3.6rem;
          margin-right: 1rem;
          padding: 1rem;
          border-radius: 0.5rem;
          background-color: #ff0000;

          span {
            font-family: var(--font-primary);
            font-size: 1.2rem;
            color: #fff;
            padding: 0;
            padding-bottom: 0.2rem;
          }
        }
      }
      .vw-questionModal-backlog {
        z-index: 999;
        h2 {
          font-size: 3.6rem;
          font-weight: 900;
          font-family: var(--font-primary);
          color: var(--gray-100-color);
          margin-bottom: 2.1rem;
        }
        /* opacity: 0; */
        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          background-color: #10172130;
          backdrop-filter: blur(3rem) brightness(90%);
        }
      }
    }
  }

  form {
    display: flex;
    gap: 2.5rem;
  }

  .vw-wallet-cardArea {
    display: flex;

    .vw-card-cardBox {
      width: 47.4rem;
      padding: 3rem 5.3rem 5rem 5.9rem;
      margin-right: 2.5rem;

      font-family: var(--font-secondary);
      font-size: 1.4rem;
      text-align: center;
      color: #798089;

      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .headerArea {
        display: flex;
        justify-content: space-between;
        margin-bottom: 2.6rem;

        .countyArea {
          display: flex;
          cursor: pointer;

          > img {
            width: 2rem;
            height: 1.4rem;
            margin-right: 1rem;
          }
        }
        .pickColorArea {
          padding-right: 1.9rem;
          margin-top: -0.1rem;
          cursor: pointer;
          .vw-icon {
            width: 1.9rem;
            color: #bebebe;
          }
        }
      }

      .contentCardPayment {
        margin-bottom: 4.84rem;
      }

      .formArea {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding-right: 0.2rem;

        .vw-input-boxInput,
        .vw-select-BoxInput {
          border-radius: 1.3rem;
          padding-left: 1.7rem;
        }

        .accountNumberArea {
          display: grid;
          grid-template-columns: 24.7rem 10.3rem;
          gap: 1rem;
          padding-right: 0.2rem;
        }
        .vw-select-boxContentInput {
          .vw-selectOptions-boxSelectOptions {
            z-index: 9999;
            padding-top: 1rem;
            padding-bottom: 1.6rem;
            .vw-selectOptions-boxScrollOptions {
              padding-right: 0;
            }
          }
        }
      }
    }
  }
`

const ContainerContriesFlags = styled.div`
  width: 47rem;
  height: 52rem;

  padding: 3rem 5.3rem 5rem 5.9rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: absolute;
  top: 2.8rem;
  left: 0.2rem;
  background-color: #292e38;
  .title {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    margin-top: 0.1rem;
    margin-bottom: 2.7rem;
    font-family: var(--font-primary);
    font-size: 1.4rem;
    font-weight: 700;
    color: #bebebe;
  }
  .boxFlags {
    width: calc(100% + 2.5rem);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    gap: 0.9rem;
    row-gap: 1rem;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0.4687vw;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      width: 0.2604vw; //5px;;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb {
      background: #333843;
      border-radius: 5px;
      border: 2px solid #333843;
      height: 5.9259vh;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #333843;
    }

    .containerFlag {
      width: 5.2rem;
      height: 6.7rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      background-color: #333843;
      border-radius: 0.5rem;
      padding: 1rem 0.9rem;
      cursor: pointer;
      .contentFlag {
        width: 3.4rem;
        height: 2.3rem;
        img {
          width: 3.4rem;
          height: 2.3rem;
        }
      }
      .contentLabel {
        font-size: 0.7rem;
        font-family: var(--font-secondary);
      }
    }
  }
`

const ContainerColorPicker = styled.div`
  width: 26.5rem;
  height: 52rem;
  /* position: absolute; */
  backdrop-filter: blur(2rem) brightness(115%);
  background-color: rgba(16, 23, 33, 0.7) !important;
  /* top: -10rem; */
  z-index: 999;
  padding: 2rem;
  border-radius: 0.5rem;
  .title {
    display: flex;
    align-items: center;
    height: 1.9rem;
    line-height: 1.5rem;
    margin-bottom: 2.1rem;
    font-weight: 700;
    color: #a5abb5;
  }
  .contentColors {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }
`
type ColorPickSquareProps = {
  color: string
  isSelected: boolean
}
const ColorPickSquare = styled.div<ColorPickSquareProps>`
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 0.3rem;
  background-color: ${(props) => props.color};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 1rem;
    opacity: 0;
    transition: transform 0.1s ease-in 25ms;
    transform: scale(0);
    transform-origin: bottom left;
    color: white;
    ${(props) => {
      if (props.isSelected) {
        return css`
          opacity: 1;
          transform: scale(1);
        `
      }
    }}
  }
`

export {
  Container,
  ContainerContriesFlags,
  ContainerColorPicker,
  ColorPickSquare,
}
