import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Request,
  RegulatoryBody,
  RegulatoryBodyFilter,
  retrieveRegulatoryBody,
  Country,
} from '@viswals/services'
import gsap from 'gsap'
import { Button } from '@viswals/components-platform'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

import gridStyle from '../_components/grid-style'
import { LoadingTable } from '../_components/loading-table'
import HeaderContentCommon from '../_components/HeaderContentCommon'
import {
  StyledContentInstitution,
  StyledContentInstitutionTable,
} from './styles'
import { LocalPaginationRequestProps } from '../country/table'
import { useSelector } from 'react-redux'
import { ReducersProps } from '@src/types/reducers'

export type LocalRegulatoryBodyAdm = {
  id?: number
} & RegulatoryBody

export default function RegulatoryBodyTable() {
  const navigate = useNavigate()

  const { countries } = useSelector((state: ReducersProps) => state.datas)

  const [regulatoryBodies, setRegulatoryBodies] = useState<
    LocalRegulatoryBodyAdm[]
  >([])
  const [pagination, setPagination] = useState<LocalPaginationRequestProps>({
    itemsPerPage: 25,
    page: 1,
    total: 0,
  })

  useEffect(() => {
    ; (async () => {
      await getCourseTitles(pagination)
    })()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'area_name',
      headerName: 'Area',
    },
    {
      field: 'isoCode',
      headerName: 'ISO Code',
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 200,
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      renderCell: (params) => {
        const regulatoryBodyToSave: RegulatoryBody = params.row
        return <>{regulatoryBodyToSave.enabled ? 'YES' : 'NO'}</>
      },
      align: 'center',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', gap: 20 }}>
            <Button
              label="Edit"
              uuid=""
              style={{ backgroundColor: 'orange', color: 'black' }}
              handleClick={() => {
                const regulatoryBodyToSave: RegulatoryBody = params.row
                window.localStorage.setItem(
                  'regulatoryBodyEdit',
                  JSON.stringify(regulatoryBodyToSave)
                )
                navigate(
                  `/administration/common/regulatory-body/edit/${regulatoryBodyToSave.uuid}`
                )
              }}
            />
          </div>
        )
      },
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
    },
  ]

  async function getCourseTitles(
    paginationed: LocalPaginationRequestProps | undefined
  ) {
    const request: Request<RegulatoryBodyFilter> = {
      fields: ['uuid', 'name', 'isoCode', 'enabled', 'updatedAt', 'createdAt'],
      pagination: {
        itemsPerPage: 25,
        page: paginationed?.page as number,
      },
      orderBy: {
        column: 'name',
        direction: 'asc',
      },
    }
    try {
      const resp = await retrieveRegulatoryBody(request)
      const { data } = resp

      if (data.code === 200) {
        const dataRegulatoryBodies: any = data.data

        const newRegulatoryBodies: LocalRegulatoryBodyAdm[] =
          dataRegulatoryBodies.map((value: any, _index: number) => {
            return {
              id: _index,
              uuid: value.uuid,
              name: value.name,
              isoCode: value.isoCode,
              country: value?.country?.name ?? '',
              countryUuid: value?.country?.uuid ?? '',
              enabled: value?.enabled ?? false,
              area: value?.area?.uuid ?? '',
              area_name: value?.area?.label ?? '',
            }
          })
        setRegulatoryBodies(newRegulatoryBodies)

        setTimeout(() => {
          setPagination(
            (value) =>
            ({
              ...value,
              ...{
                itemsPerPage: paginationed?.itemsPerPage,
              },
              total: data.pagination
                ? data.pagination.total
                : data.data.length,
            } as LocalPaginationRequestProps)
          )
        }, 200)
        disabledLoading()
      }
    } catch (e) {
      console.error(e)
    }
  }

  function handleClickAdd() {
    navigate('/administration/common/regulatory-body/new')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function addPage(page: number) {
    const pageToSet: LocalPaginationRequestProps = {
      itemsPerPage: 25,
      page: page + 1,
    }
    try {
      await getCourseTitles(pageToSet)
    } catch (error) {
      console.error({
        error,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function enabledLoading() {
    const query = window.document.querySelector('.vw-loadingContainer')
    gsap.to(query, {
      zIndex: 99,
      duration: 0.1,
      onComplete: () => {
        gsap.to(query, {
          opacity: 1,
          delay: 0.1,
          duration: 0.25,
        })
      },
    })
  }

  function disabledLoading() {
    setTimeout(() => {
      const query = window.document.querySelector('.vw-loadingContainer')
      gsap.to(query, {
        opacity: 0,
        duration: 0.25,
        onComplete: () => {
          gsap.to(query, {
            zIndex: -1,
            delay: 0.25,
            duration: 0.25,
          })
        },
      })
    }, 300)
  }

  return (
    <StyledContentInstitution>
      <HeaderContentCommon
        name="Regulatory Body"
        description="Manager regulatory body information"
        onClickAdd={() => handleClickAdd()}
      />

      <StyledContentInstitutionTable>
        <LoadingTable />
        <DataGrid
          sx={gridStyle}
          rows={regulatoryBodies}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          experimentalFeatures={{ newEditingApi: false }}
          onPageChange={(page) => {
            enabledLoading().then(() => {
              setTimeout(() => {
                addPage(page)
              }, 200)
            })
          }}
          pagination
          rowsPerPageOptions={[25]}
          rowCount={pagination.total}
          initialState={{
            pagination: {
              pageSize: pagination.itemsPerPage,
            },
          }}
          paginationMode="server"
        />
      </StyledContentInstitutionTable>
    </StyledContentInstitution>
  )
}
