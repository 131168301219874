/* eslint-disable @typescript-eslint/no-explicit-any */
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import {
  Request,
  createPaymentAccount,
  PaymentAccount,
  PaymentAccountFilter,
  retrievePaymentAccount,
  updatePaymentAccount,
} from '@viswals/services'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  FinishRegistrationProps,
  PaymentAccountCancellationPolicies,
} from '../data'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { watch, control, setValue, handleSubmit } = useForm()

  const { fields } = useFieldArray({
    control,
    name: 'data',
  })

  const { setLoading } = useLoading()
  const { subStepDone, checkFinished, nextRoute, verified } =
    useClinicianRegister()

  const [checkAll, setCheckAll] = useState<boolean>(true)
  const [isReady, setIsReady] = useState<boolean>(false)
  const [openModalNotFinished, setOpenModalNotFinished] =
    useState<boolean>(false)

  const { next } = useSelector((state: ReducersProps) => state.navigation)

  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    const isTermsDone = true // subStepList[0].done
    const isPolicesDone = true // subStepList[1].done
    if (isTermsDone && isPolicesDone && next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, { type }) => {
      const isTermsDone = true
      const isPolicesDone = true
      const isAllTrue = form.data.every(
        (item: any) => item.isConfirmed === true
      )
      if (isTermsDone && isPolicesDone && isAllTrue && type === 'change') {
        setCheckAll(true)
        subStepDone()
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  const handleScroll = (event: any, indexCard: number) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.target

    if (scrollTop + offsetHeight > scrollHeight - 20) {
      const watchValue = watch(`data.${indexCard}.isDisabled`)
      if (watchValue === true && !verified) {
        setValue(`data.${indexCard}.isDisabled`, false)
      }
    }
  }

  const getData = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      const requestRetrieve = await retrievePaymentAccount({
        fields: [
          'uuid',
          'countryRegistration',
          'inTeamPolicy',
          'freelancerPolicy',
          'cancellationPolicy',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        },
      })
      const data: PaymentAccount[] = requestRetrieve?.data?.data ?? []

      const newArray: FinishRegistrationProps[] =
        PaymentAccountCancellationPolicies.map(
          (term: FinishRegistrationProps) => {
            let isConfirmed = false
            if (data.length > 0) {
              isConfirmed =
                term.type === 'inTeamPolicy'
                  ? data[0].inTeamPolicy === true
                  : term.type === 'freelancerPolicy'
                  ? data[0].freelancerPolicy === true
                  : data[0].cancellationPolicy === true
            }
            const newData: FinishRegistrationProps = {
              uuid: term.uuid,
              title: term.title,
              img: term.img,
              description: term.description,
              isDisabled: true,
              isConfirmed,
              isVerified: verified,
              isError: false,
              item: data.length > 0 ? data[0].uuid : null,
            }
            return newData
          }
        )
      if (newArray?.length) {
        setCheckAll(true)
      }
      setValue(`data`, newArray)
    } catch (error) {
      return null
    } finally {
      setIsReady(true)
      setLoading(false)
    }
  }

  const onSubmit = async () => {
    if (!checkAll) {
      return false
    }
    const newFields = watch(`data`)
    const isAllTrue = newFields.every((item: any) => item.isConfirmed === true)

    if (!isAllTrue) {
      return null
    }

    const dataCreate: PaymentAccount = {
      uuid: newFields[0].item ?? undefined,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      countryRegistration: registrationActive.countryRegistration,
      inTeamPolicy: true,
      freelancerPolicy: true,
      cancellationPolicy: true,
    }

    if (dataCreate.uuid) {
      await updatePaymentAccount(dataCreate as Request<PaymentAccountFilter>)
    } else {
      await createPaymentAccount(dataCreate as Request<PaymentAccountFilter>)
    }

    const validateFinished = checkFinished()
    if (!validateFinished) {
      setOpenModalNotFinished(true)
      return null
    }

    navigate('/submit-registration')
  }

  const handleGoToCountryRegistration = () => navigate(nextRoute)

  return {
    fields,
    control,
    verified,

    openModalNotFinished,
    setOpenModalNotFinished,

    handleGoToCountryRegistration,

    watch,
    onSubmit,
    handleSubmit,

    handleScroll,
  }
}

export default useLogic
