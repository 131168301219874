/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import {
  Button,
  Input,
  Select,
  SelectedOptionsProps,
  Toggle,
} from '@viswals/components'
import {
  Request,
  InsuranceCompany,
  createInsuranceCompany,
} from '@viswals/services'
import useDatas from '@src/hooks/useDatas'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import { StyledContentInstitution } from './styles'

export default function FormCreateInsuranceCompany() {
  const navigate = useNavigate()

  const { countries } = useDatas()

  const [isError, setIsError] = useState<string>('')

  const { register, handleSubmit, watch, control } = useForm()

  async function onSubmit(data: any) {
    const dataToSend = {
      name: data.name,
      countryUuid: data.countryUuid,
      patient: data.patient,
      enabled: true,
    } as InsuranceCompany

    try {
      setIsError('')
      await createInsuranceCompany(dataToSend as Request<InsuranceCompany>)
      navigate('/administration/common/insurance-company')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentInstitution className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Insurance Company"
            description="Here you can register on new insurance company"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Select
            label="Country"
            labelTitle="Countries"
            options={
              countries.map((value) => ({
                label: value.name,
                value: value.uuid,
                icon: value.icon,
              })) as SelectedOptionsProps[]
            }
            formRegister={{ ...register('countryUuid'), watch }}
          />

          <div className="content-toggle-switch">
            <Toggle isBig name="patient" control={control} />
            <div className="box-preview">Patient</div>
          </div>

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/insurance-company')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentInstitution>
  )
}
