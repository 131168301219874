import useDatas from '@src/hooks/useDatas'
import useDiary from '@src/hooks/useDiary'
import useLayout from '@src/hooks/useLayout'
import Config from '@src/services/api/config'
import { LatitudeLongitudeProps } from '@src/types/maps'

import {
  PatientStatus,
  PatientStatusFilter,
  Request,
  retrievePatientStatus,
} from '@viswals/services'
import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import NotificationItemMap from '../components/Notification'
import patientUrlMapStatus from './utils/status_patient'
import axios from '../../../services/api'

const useLogic = () => {
  const [mapLatLong, setMapLatLong] = useState({ latitude: 39.75, longitude: -8.27, zoom: 100 })

  const { countries, getCountryByUuid } = useDatas()
  const { diary } = useDiary()
  const { layout, setLayout } = useLayout()
  // const [clinics, setClinics] = useState<ReceivedLatLng[]>([])
  // const [clinicianRegister, setClinicianRegister] = useState<ReceivedLatLng[]>(
  //   []
  // )
  const [patients, setPatients] = useState<PatientStatus[]>([]) // ReceivedLatLng
  // const [wsActive, setWsActive] = useState(false)
  const token = localStorage.getItem('access_token')

  const wssUrlAll = `${Config.WS_URL}/patient/status/ws/retrieve-all`
  const wsAll = useRef<any>(null)

  useEffect(() => {
    getPatients()
  }, [diary.landingOn, diary.upperCalendarStartSelect, mapLatLong])

  useEffect(() => { }, [layout.updateMapLatLng])

  // WEBSCKET FOR ALL TIME
  useEffect(() => {
    wsAll.current = new WebSocket(`${wssUrlAll}?access_token=${token}`)
    wsAll.current.onopen = () =>
      console.log(`connected websocket for all time ${wssUrlAll}`)
    const wsCurrentAll = wsAll.current
    return () => wsCurrentAll.close()
  }, [diary.landingOn])

  useEffect(() => {
    if (
      !wsAll.current ||
      !layout.updateMapLatLng?.latitude ||
      diary.landingOn !== 'AS_LEFT'
    )
      return
    const sendWs = { filters: { ...layout.updateMapLatLng, radius: 10000 } }
    if (sendWs) {
      console.log({ sendWs })
      wsAll.current.send(JSON.stringify(sendWs))
    }
    wsAll.current.onmessage = ({ data }: any) => addWSAll(data)
  }, [layout.updateMapLatLng?.latitude])

  function addWSAll(data: string) {
    if (diary.landingOn !== 'AS_LEFT') return
    const patientsWs = JSON.parse(data) as PatientStatus[]
    // console.log('addWSAll', patientsWs)
    const listPatientsCreated = patientsWs.map((m) => m.patientUuid)
    setPatients((s) => [
      ...s.filter((f) => !listPatientsCreated.includes(f.patientUuid)),
      ...patientsWs,
    ])
  }

  function addWS(data: string) {
    if (
      diary.landingOn === 'DAY' &&
      (!diary.upperCalendarStartSelect ||
        diary.upperCalendarStartSelect === moment().format('YYYY-MM-DD'))
    ) {
      const patient: PatientStatus = JSON.parse(data)
      addMarkerByWS(patient)
      notificationToast(patient)
      setLayout('updateMap', Math.floor(Date.now() / 1000))
    }
  }

  function addMarkerByWS(patient: PatientStatus) {
    const newList = patients.filter(
      (f) => f.patientUuid !== patient.patientUuid
    )
    newList.push(patient)
    setPatients([...newList])
  }

  async function notificationToast(patient: PatientStatus) {
    const country = await getCountryByUuid(patient.countryUuid as string)

    toast.dark(<NotificationItemMap patient={patient} country={country} />, {
      autoClose: 3000,
      pauseOnHover: false,
    })
  }

  function getLatLngByCountry(
    countryUuid: string,
    index: number
  ): LatitudeLongitudeProps | null {
    const country = countries.find((f) => f.uuid === countryUuid)
    return {
      latitude: (country?.latitude as number) + 0.008 * index,
      longitude: (country?.longitude as number) + 0.008 * index,
    }
  }

  async function getPatients() {
    const request: Request<PatientStatusFilter> = {
      fields: ['uuid', 'patientUuid', 'state', 'createdAt'],
      relations: {
        residenceInformation: {
          fields: ['latitude', 'longitude'],
        },
      },
      filters: {
        startDate:
          diary.landingOn === 'AS_LEFT'
            ? undefined
            : diary.upperCalendarStartSelect !== ''
              ? diary.upperCalendarStartSelect
              : moment().format('YYYY-MM-DD'),
        finishDate:
          diary.landingOn === 'AS_LEFT'
            ? undefined
            : diary.upperCalendarEndSelect !== ''
              ? diary.upperCalendarEndSelect
              : moment().format('YYYY-MM-DD'),
      },
    }

    const fromDate = diary.landingOn === 'AS_LEFT' ? undefined : diary.upperCalendarStartSelect !== '' ? diary.upperCalendarStartSelect : moment().format('YYYY-MM-DD')
    const toDate = diary.landingOn === 'AS_LEFT' ? undefined : diary.upperCalendarEndSelect !== '' ? diary.upperCalendarEndSelect : moment().format('YYYY-MM-DD')

    let url = 'https://services.viswalslab.com/professional/v1/accounts/registrations-location'
    // if (fromDate && toDate) {
    //   url = `${url}?from=${fromDate}&to=${toDate}`
    // }
    url = `${url}?from=2020-10-10&to=2028-10-10&latitude=${mapLatLong.latitude}&longitude=${mapLatLong.longitude}&radius=${mapLatLong.zoom}`

    const { data: { result } } = await axios.get(url)

    const newConvertedData: any = (result || []).map((ele: any) => ({
      "uuid": "b951a317-bcc7-4d5a-b546-addbf3c928b8",
      "patientUuid": "a93ed624-9682-46cd-a4d7-c068a0a895f8",
      "state": ele.status,
      "createdAt": ele.log_datetime,
      "countryUuid": "e23a2863-851c-471a-8986-f540e4e405a8",
      "residenceInformation": {
        "latitude": parseFloat(ele.latitude),
        "longitude": parseFloat(ele.longitude)
      }
    }))

    setPatients([...newConvertedData])
  }

  function convertPatientToMaps(items: PatientStatus[]) {
    return items.map((item, index: number) => {
      const latLong = item?.residenceInformation?.latitude
        ? { ...item.residenceInformation }
        : { ...getLatLngByCountry(item.countryUuid as string, index) }

      return {
        dataSet: item,
        state: item.state,
        ...latLong,
      }
    })
  }

  // async function getClinicianRegister() {
  //   const request: Request<ClinicianRegisterFilter> = {
  //     fields: ['uuid'],
  //     relations: {
  //       residenceInformation: {
  //         fields: ['latitude', 'longitude'],
  //       },
  //     },
  //   }
  //   const {
  //     data: { data },
  //   } = await retrieveClinicianRegister(request)

  //   setClinicianRegister(
  //     data.map((s: ClinicianRegister) => ({
  //       uuid: s.uuid,
  //       name: '',
  //       latitude: s.residenceInformation?.latitude,
  //       longitude: s.residenceInformation?.longitude,
  //     }))
  //   )
  // }

  // async function getClinics() {
  //   const request: Request<ClinicFilter> = {
  //     fields: ['uuid', 'name', 'latitude', 'longitude'],
  //   }

  //   const {
  //     data: { data },
  //   } = await retrieveClinic(request)

  //   setClinics(data)
  // }

  const MarkersCallback = useCallback(() => {
    const patientsMapped = convertPatientToMaps(patients)?.map((patient) => ({
      title: '',
      icon: {
        url: patientUrlMapStatus((patient as any).state),
        urlActive: patientUrlMapStatus((patient as any).state, true),
        // scaledSize: new google.maps.Size(32, 32),
        dataSet: patient.dataSet,
      },
      position: { lat: patient.latitude, lng: patient.longitude },
    })) as google.maps.MarkerOptions[]

    return [...patientsMapped]
  }, [patients])

  const markers = MarkersCallback()
  return { markers, addWS, setMapLatLong }
}

export default useLogic
