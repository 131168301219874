/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import {
  createDentalInsurance,
  DentalInsurance,
  DentalInsuranceFilter,
  Request,
  retrieveDentalInsurance,
  updateDentalInsurance,
  InsuranceCompany,
  InsuranceCompanyFilter,
  retrieveInsuranceCompany,
} from '@viswals/services'
import { ReducersProps } from '@src/types/reducers'
import { useDispatch, useSelector } from 'react-redux'
import useLoading from '@src/hooks/useLoading'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useStepsError from '@src/hooks/useStepsError'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import transformToSelectOptions from '@src/core/transformToSelectOptions'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    unregister,
    watch,
    reset,
    setError,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm()

  const { subStepDone, nextRoute } = useClinicianRegister()
  const { loading, setLoading } = useLoading()
  const { getErrors, getFieldProps } = useStepsError(errors)

  const [insuranceCompanies, setInsuranceCompanies] = useState<
    InsuranceCompany[]
  >([])
  const [isReady, setIsReady] = useState<boolean>(false)

  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  const convertInsuranceCompanies = transformToSelectOptions({
    array: insuranceCompanies,
    keyLabel: 'name',
    keyValue: 'uuid',
  })

  useEffect(() => {
    getData()
    getInsuranceCompanies()
  }, [registrationActive])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, info) => {
      if (info.type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  async function getData() {
    setIsReady(false)
    setLoading(true)
    try {
      const request: Request<DentalInsuranceFilter> = {
        fields: [
          'uuid',
          'clinicianRegisterUuid',
          'countryRegistration',
          'doYouHave',
          'insuranceCompany',
          'policyNumber',
          'reasonForNot',
          'statutoryTerms',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        },
      }
      const resp = await retrieveDentalInsurance(request)
      const data = resp?.data?.data ? resp?.data.data[0] : null
      let updateInfo = {}
      if (data) {
        updateInfo = {
          ...data,
          doYouHave: data.doYouHave ? '1' : '0',
          reasonForNot: data?.reasonForNot ? String(data.reasonForNot) : '0',
        }
      }

      setTimeout(() => {
        reset({
          ...updateInfo,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        })
        setLoading(false)
        dispatch(setNavigationActiveNext(true))
      }, 200)
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  const getInsuranceCompanies = async () => {
    try {
      const requestInsurance: Request<InsuranceCompanyFilter> = {
        fields: ['uuid', 'countryUuid', 'name'],
        filters: {
          countryUuid: registrationActive.countryRegistration,
        },
      }

      const response = await retrieveInsuranceCompany(requestInsurance)

      const data: InsuranceCompany[] = response.data ?? []

      setInsuranceCompanies(data)
    } catch (error) {
      return null
    }
  }

  // async function createInsuranceCompany(name: string) {
  //     const request: Request<Insure> = {
  //         data: [
  //             {
  //                 name,
  //                 isoCode: registrationActive.country.isoCode as string,
  //             },
  //         ],
  //     };
  //     const resp = await createRegulatoryBody(request);
  //     await getRegulatoryBodies();
  //     return resp.data.data[0];
  // }

  async function onSubmit(data: any) {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }

    data = {
      ...data,
      doYouHave: Boolean(data.doYouHave === '1'),
      insuranceCompany: doYouHave !== '0' ? data.insuranceCompany : undefined,
      policyNumber: doYouHave !== '0' ? data.policyNumber : undefined,
      reasonForNot: doYouHave === '0' ? Number(data.reasonForNot) : undefined,
      statutoryTerms:
        doYouHave === '0' ? Boolean(data.statutoryTerms) : undefined,
    }

    // if (data.newInsuranceCompany) {
    //     data = {
    //         ...data,
    //         insuranceCompany: await createInsuranceCompany(
    //             data.newInsuranceCompany
    //         ),
    //     };
    // }
    delete data?.newInsuranceCompany

    if (!data.uuid) {
      await createDentalInsurance(data as Request<DentalInsurance>)
    } else {
      await updateDentalInsurance(data as Request<DentalInsurance>)
    }
    navigate(nextRoute)
    subStepDone()
  }

  const doYouHave = watch('doYouHave')

  useEffect(() => {
    removeRequired()
  }, [doYouHave])

  function removeRequired() {
    if (doYouHave === '0') {
      unregister('insuranceCompany', { keepIsValid: true })
    }
  }

  return {
    doYouHave,
    convertInsuranceCompanies,
    control,
    onSubmit,
    register,
    watch,
    setValue,
    handleSubmit,
    getFieldProps,
    clearErrors,
  }
}

export default useLogic
