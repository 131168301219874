/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Button,
  Card,
  QuestionModal,
  Select,
  Upload,
} from '@viswals/components'
import MiniPagination from '@src/components/pagination/SubstepMiniPagination'

import DentistAndPatient from '@images/dentistAndPatient.svg'
import DentistAndDoctor from '@images/dentistAndDoctor.svg'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import {
  BoxBoundarySteps,
  BoxContentCards,
  SafeAreaClickDelete,
} from './SpecialtyCertificateStyles'
import useLogic from './useLogic'

const SpecialtyCertificate = () => {
  const {
    elementClickable,
    confirm,
    verified,
    selectDelete,
    isModalOpen,
    dataSpecialty,
    fields,
    dispatch,
    setPaginationActive,
    watch,
    // append,
    setValue,
    register,
    handleSubmit,
    onSubmit,
    handleOpenModel,
    handleCancelModelDelete,
    handleConfirmModelDelete,

    getFieldProps,

    pagination,
    paginationActive,
  } = useLogic()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <input type="hidden" {...register('verified', { value: false })} />
      <BoxBoundarySteps>
        <h1>Specialty & Certificate</h1>
        <p>Recognised specialties and certificates</p>
        <div className={`FloatItems ${verified ? 'Verified' : ''}`}>
          {typeof selectDelete === 'number' ? (
            <div className="boxDeleteCard" ref={elementClickable}>
              <Button
                icon="Remove1"
                label="Delete Specialty"
                styleType="danger"
                handleClick={handleOpenModel}
              />
              {isModalOpen ? (
                <QuestionModal
                  title="Delete Specialty"
                  description="You are about to delete a Specialty. This action cannot be undone. Are you sure you want to proceed?"
                  cancelLabel="Oops, take me back"
                  cancelFunction={handleCancelModelDelete}
                  confirmLabel="Yes, delete Specialty"
                  confirmFunction={handleConfirmModelDelete}
                />
              ) : null}
            </div>
          ) : fields.length > 1 ? (
            <MiniPagination
              pages={pagination}
              active={paginationActive}
              onChangeActive={setPaginationActive}
            />
          ) : null}

          {/* {!verified ? (
            <Button
              icon="Add3"
              label="Add Specialty"
              styleType="primary"
              disabled
              handleClick={() => {
                append({ destroy: false })
                setPaginationActive(pagination)
              }}
            />
          ) : null} */}
        </div>
        <BoxContentCards>
          {fields?.map((field, index) => {
            if (!(paginationActive === index + 1)) {
              return null
            }
            // @ts-ignore
            if (field.destroy === true) {
              return null
            }

            return (
              <div key={field.id} className={`card-item item-${index}`}>
                <input
                  type="hidden"
                  {...register(`data.${index}.destroy`, {
                    value: false,
                  })}
                />
                <Card readyDelete={Boolean(index === selectDelete)}>
                  <article>
                    <div className="boxLeftOption">
                      <img src={DentistAndPatient} alt="DentistAndPatient" />
                      <SafeAreaClickDelete>
                        <Select
                          label="Specialty? Which"
                          labelTitle="Select the Specialty"
                          placeholder="Select Specialty"
                          options={dataSpecialty}
                          labelApply="Apply Changes"
                          formRegister={{
                            ...register(`data.${index}.specialty`, {
                              required: true,
                            }),
                            watch,
                          }}
                          {...getFieldProps(`data.${index}.specialty`)}
                        />
                      </SafeAreaClickDelete>
                    </div>
                    <div className="boxRightOption">
                      <img src={DentistAndDoctor} alt="DentistAndPatient" />
                      <SafeAreaClickDelete>
                        <Upload
                          label="Specialist Certificate"
                          placeholder="Upload Certificate"
                          formRegister={{
                            ...register(`data.${index}.specialistCertificate`, {
                              required: !watch(
                                `data.${index}.specialistCertificate`
                              )?.fileName,
                              onBlur: () => {
                                if (!confirm) {
                                  dispatch(setNavigationConfirm(true))
                                }
                              },
                            }),
                            watch,
                            setValue,
                          }}
                          {...getFieldProps(
                            `data.${index}.specialistCertificate`
                          )}
                        />
                      </SafeAreaClickDelete>
                    </div>
                  </article>
                </Card>
              </div>
            )
          })}
        </BoxContentCards>
      </BoxBoundarySteps>
    </form>
  )
}

export default SpecialtyCertificate
