import api from '@src/services/api'
import { useEffect, useState } from 'react'
import useDatas from '@src/hooks/useDatas'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  ParseTypeProps,
  VerificationItemProps,
  VerificationStatusProps,
} from './type'

const useLogic = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { getCountry } = useDatas()
  const patientUuid = params.uuid
  const [itemsList, setItemsList] = useState<VerificationItemProps[]>([])
  const [itemQuestion, setItemQuestion] =
    useState<VerificationItemProps | null>(null)
  const [image, setImage] = useState<string | undefined>()
  const [stepActive, setStepActive] = useState<number>(1)

  const parseIndex: ParseTypeProps[] = [
    {
      index: 0,
      type: 'value',
      value: 'Yes',
    },
    {
      index: 3,
      type: 'country',
    },

    {
      index: 6,
      type: 'country',
    },
    {
      index: 7,
      type: 'date',
      format: 'DD.MM.YYYY',
    },
    {
      index: 10,
      type: 'date',
      format: 'MMM/YYYY',
    },
  ]

  useEffect(() => {
    getVerificationDocument()
    getCountry()
  }, [])

  useEffect(() => {
    const itemUuid = searchParams.get('question')
    if (itemUuid && itemsList?.length) {
      const item = itemsList.find((f) => f.uuid === itemUuid)
      console.log(item)
      setItemQuestion(item as VerificationItemProps)
    } else {
      setItemQuestion(null)
    }
  }, [itemsList, searchParams])

  async function getVerificationDocument() {
    const body = {
      fields: ['uuid', 'patientUuid', 'label', 'value', 'state', 'step'],
      filters: {
        patientUuid,
      },
    }
    const { data } = await api.post(
      '/patient/verification-document/retrieve',
      body
    )

    const img = data.data[0].value
    setItemsList(data.data)
    setImage(`data:image/jpeg;base64,${img}`)
  }

  async function submit() {
    if (stepActive === 1) {
      setStepActive(2)
      return
    }

    const body = itemsList.map((i) => ({
      uuid: i.uuid,
      state: i.state,
      patientUuid,
    }))
    await api.post('/patient/verification-document/update', body)
    // eslint-disable-next-line no-alert
    alert('Submitted!')
  }

  function updateState(uuid: string, state: VerificationStatusProps) {
    setItemsList((st) => [
      ...st.map((s) => {
        if (s.uuid === uuid) {
          return {
            ...s,
            state,
          }
        }
        return s
      }),
    ])
  }

  function quickAction(state: string) {
    const totalCheck = items.filter((f) => !!f.state).length
    if (totalCheck === items.length) {
      removeAllState(state)
    } else {
      checkAllState(state)
    }
  }

  function checkAllState(state: string) {
    const newList = itemsList.map((s) => {
      if (!s?.state && s.step === stepActive) {
        return {
          ...s,
          state,
        }
      }
      return s
    })

    setItemsList(newList as VerificationItemProps[])
  }

  function removeAllState(state: string) {
    const newList = itemsList.map((s) => {
      if (s.state === state && s.step === stepActive) {
        return {
          ...s,
          state: undefined,
        }
      }
      return s
    })

    setItemsList(newList as VerificationItemProps[])
  }

  function previous() {
    setStepActive((n) => n - 1)
  }

  const items = itemsList.filter((f, i) => {
    if (stepActive === 2 && i === 0) {
      return true
    }
    if (f.step === stepActive) {
      return true
    }
    return false
  })

  function questionSave(save?: boolean) {
    if (save) {
      setItemsList((st) => [
        ...st.map((s) => {
          if (s.uuid === itemQuestion?.uuid) {
            return {
              ...s,
              question: itemQuestion?.question,
            }
          }
          return s
        }),
      ])
    }

    const url = `${window.location.pathname}`
    navigate(url, { replace: true })
  }

  function changeQuestionReased(newValue: string) {
    setItemQuestion((oldValues: any) => ({
      ...oldValues,
      question: newValue,
    }))
  }

  return {
    itemsList,
    items,
    parseIndex,
    image,
    stepActive,
    itemQuestion,
    quickAction,
    updateState,
    previous,
    questionSave,
    changeQuestionReased,
    submit,
  }
}

export default useLogic
