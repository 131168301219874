import styled, { css } from 'styled-components'

type RegistrationModeProps = {
  showViewMode: boolean
  checkTogglePlan: boolean
}

const Container = styled.div<RegistrationModeProps>`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-registrationMode-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .headerSide {
      h2 {
        font-size: 2rem;
        font-weight: 700;
        color: var(--gray-100-color);
        margin-bottom: 0.3rem;
        margin-top: -0.1rem;
        margin-left: 0.1rem;
      }

      span {
        font-size: 1.3rem;
        color: var(--gray-200-color);
        margin-left: 0.1rem;
      }
    }

    .toggleSide {
      height: 100%;
      display: flex;
      margin-left: -6rem;
      padding-top: 0.6rem;
      gap: 1.95rem;

      font-size: 1.2rem;
      font-weight: 700;

      ${(props) => {
        if (props.checkTogglePlan === true) {
          return css`
            p.commissions {
              color: #798089;
            }
            .plans {
              color: #15aeff;
            }
          `
        }
        return css`
          p.commissions {
            color: #15aeff;
          }
          p.plans {
            color: #798089;
          }
        `
      }}
    }

    .subtitleSide {
      height: 100%;
      display: flex;
      align-items: flex-start;
      padding-top: 0.4rem;
      gap: 2rem;

      /* background: teal; */

      > div {
        display: flex;
        align-items: center;
        /* background: gray; */

        font-family: var(--font-secondary);
        font-size: 1rem;
        color: #bebebe;

        > span {
          width: 3.1rem;
          height: 2.1rem;
          margin-right: 0.5rem;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 0.5rem;
          border: 0.1rem solid #bebebed1;
        }
      }
    }
  }

  .vw-button-buttonstyle {
    height: 4.2rem;
    font-size: 1.2rem;
    background-color: ${(props) =>
      props.showViewMode ? '#15aeff' : '#292e38'};
  }

  .vw-div-footer {
    height: 5rem;
  }
`

const CardArea = styled.form`
  display: flex;
  gap: 2.5rem;

  .vw-card-cardBox {
    height: 56rem;
    width: 47.4rem;
    max-width: 47.4rem;
    display: flex;
    margin-top: -0.4rem;
    border-radius: 2rem;

    position: relative;
  }
`

const RegistrationCardArea = styled.div`
  width: 100%;
  padding: 4.8rem 4.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  .imgArea {
    height: 13.1rem;
    width: auto;

    &.img2 {
      height: 13.1rem !important;

      width: 26.1rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  .title {
    font-size: 2rem;
    font-weight: 700;
    color: #15aeff;

    margin-bottom: 1.7rem;
  }

  .description {
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.8rem;
    color: #798089;

    width: 34.65rem;
    margin-bottom: 4.5rem;
  }

  .tagArea {
    width: 100%;
    height: 2.1rem;
    display: flex;
    gap: 0.9rem;
    margin-bottom: 5rem;

    .tagIconArea {
      width: 4rem;
      height: 2.1rem;
      padding: 0.1rem 0.2rem;

      display: flex;
      justify-content: center;
      align-items: center;

      border: 0.1rem solid #15aeff;
      border-radius: 0.5rem;
      gap: 0.45rem;

      font-family: var(--font-secondary);
      color: #15aeff;
      text-align: center;

      > p {
        padding-top: 0.2rem;
      }

      .vw-icon {
        width: 1.1rem;
        color: #15aeff;
      }
      &.clinic {
        .vw-icon {
          width: 1.08rem;
          padding-bottom: 0.1rem;
        }
      }

      &.green {
        color: #15fe42;
        border: 0.1rem solid #15fe42;

        .vw-icon {
          color: #15fe42;
        }
      }
    }
  }

  .flAndIt {
    display: flex;
    gap: 5rem;
  }

  .priceArea {
    width: 34.6rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.9rem;

    position: relative;
    &::before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      position: absolute;

      width: 100%;
      height: 0.1rem;
      background: #333843;
    }

    > h2 {
      font-size: 2.8rem;
      font-weight: 700;
      color: #bebebe;
      margin-bottom: 1.9rem;

      span {
        font-weight: 300;
      }
    }
  }
`

type CommissionProps = {
  uuid: string
}
const CommissionsCardArea = styled.div<CommissionProps>`
  width: 100%;
  padding: 4.1rem 4.9rem 1rem 5.1rem;

  .headerArea {
    height: 4.2rem;
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 1.6rem;
      font-weight: 700;
      color: #15aeff;
    }

    h2.price {
      color: #bebebe;

      span {
        font-weight: 400;
      }
    }
  }
  .middleArea {
    height: 28.2rem;
    padding: 3rem 0 3.3rem;

    position: relative;

    &::before,
    &::after {
      content: '';
      left: 0;
      right: 0;
      position: absolute;

      width: 100%;
      height: 0.1rem;
      background: #333843;
    }

    &::after {
      bottom: 0;
    }
    &::before {
      top: 0;
    }

    .middleItem {
      height: 10.5rem;
      margin-bottom: 0.9rem;

      display: flex;
      justify-content: space-between;

      > span {
        width: 10.4rem;
        height: 10.4rem;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        border: 0.5rem solid #15aeff;

        font-size: 2rem;
        font-weight: 600;
        color: #15aeff;
        padding-top: 0.1rem;
        padding-left: 0.1rem;

        > p {
          font-weight: 300;
          font-size: 1.4rem;
          letter-spacing: 3px;
          padding-left: 0.2rem;
        }
      }

      .doublePercent {
        display: flex;
        padding-top: 2.1rem;
        gap: 1.1rem;
        > span {
          width: 6.2rem;
          height: 6.2rem;

          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          border: 0.3rem solid #15aeff;

          font-size: 2rem;
          font-weight: 600;
          color: #15aeff;
          letter-spacing: -1px;
          padding-left: 0.2rem;

          > p {
            font-weight: 300;
            font-size: 1.4rem;
            letter-spacing: 3px;
            padding-left: 0.2rem;
          }
        }

        .red {
          color: #de5e5e;
          border-color: #de5e5e;
        }
      }

      &.red {
        > span {
          border-color: #de5e5e;

          font-size: 2rem;
          font-weight: 600;
          color: #de5e5e;
          letter-spacing: 1;

          > p {
            font-weight: 300;
            font-size: 1.4rem;
            padding-bottom: 0.5rem;
          }
        }
      }
      .descriptionArea {
        width: 22rem;
        height: 100%;
        padding-top: 1.7rem;

        > span {
          font-size: 1.2rem;
          font-weight: 700;
          color: #15aeff;
          white-space: nowrap;
        }

        > p {
          font-size: 1rem;
          font-weight: 400;
          color: #798089;
          padding-top: 0.6rem;
          line-height: 1.6;
        }

        &.red {
          > span {
            color: #de5e5e;
            &.blue {
              color: #15aeff;
            }
          }
          > p {
            padding-top: 0.5rem;
          }
        }
      }
    }
  }

  .footerArea {
    display: flex;
    padding-top: 2.5rem;

    .subtitle {
      width: 10.4rem;
      padding-top: 4.1rem;

      > span {
        display: flex;
        align-items: baseline;
        padding-bottom: 0.8rem;
        margin-bottom: 0.9rem;

        font-size: 2rem;
        font-weight: 700;
        letter-spacing: -1px;

        color: #15aeff;

        position: relative;

        &::before {
          content: '';
          bottom: 0;
          left: 0;
          position: absolute;

          width: 100%;
          height: 0.1rem;
          background-color: #15aeff;
        }

        p {
          font-size: 1.4rem;
          font-weight: 300;
          padding-left: 0.2rem;
        }
      }

      > p {
        font-size: 1rem;
        color: #15aeff;
      }

      &.red {
        text-align: end;

        > span {
          text-align: end;
          justify-content: flex-end;
          color: #de5e5e;

          &::before {
            background-color: #de5e5e;
          }
        }
        > p {
          color: #de5e5e;
        }
      }
    }
    .pieChart {
      border-radius: 50%;
      width: 14.5rem;
      height: 14.5rem;
      margin: 0 0.9rem;

      &.right {
        margin: 0 0 0 0.7rem;
      }

      ${(props) => {
        if (props.uuid === '01') {
          return css`
            background: conic-gradient(
              #15aeff 0% 17%,
              #de5e5e 17% 35%,
              #15aeff 35%
            );
          `
        }
        if (props.uuid === '02') {
          return css`
            background: conic-gradient(
              #de5e5e 0% 50%,
              #15aeff 50% 91%,
              #de5e5e 91%
            );
          `
        }
        return css`
          &.left {
            background: conic-gradient(
              #15aeff 0% 31%,
              #de5e5e 31% 50%,
              #15aeff 50%
            );
          }
          &.right {
            background: conic-gradient(
              #de5e5e 0% 50%,
              #15aeff 50% 91%,
              #de5e5e 91%
            );
          }
        `
      }}
    }

    .doublePercentArea {
      width: 15.9rem;
      display: flex;
      gap: 5.5rem;

      .doublePercent {
        display: flex;
        flex-direction: column;
        align-items: center;

        /* background: gray; */

        .pieChart {
          width: 10.9rem;
          height: 10.9rem;
          margin-right: -0.1rem;

          &.left {
            margin-top: -0.8rem;
            margin-right: -0.3rem;
          }
        }

        .subtitleArea {
          width: 15.9rem;
          display: flex;
          justify-content: space-between;
          margin-top: -6rem;

          &.left {
            margin-top: -5.3rem !important;
          }
          .subtitle {
            width: 6.25rem;

            > span {
              padding-bottom: 0;
              margin-bottom: 0 !important;
            }

            > p {
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
`

const PlansCardArea = styled.div`
  width: 100%;
  padding: 4rem 4.9rem 1rem 5.1rem;

  .headerArea {
    height: 4.3rem;
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 1.6rem;
      font-weight: 700;
      color: #15aeff;
    }

    h2.price {
      color: #bebebe;

      span {
        font-weight: 400;
      }
    }
  }
  .middleArea {
    height: 28.2rem;
    padding: 9.6rem 0 3.3rem 0;

    position: relative;

    &::before {
      content: '';
      top: 0;
      left: 0;
      right: 0;
      position: absolute;

      width: 100%;
      height: 0.1rem;
      background: #333843;
    }

    > ul {
      list-style: none;
      padding-left: 0.9rem;
      > li {
        display: flex;
        margin-bottom: 2rem;
        gap: 4.3rem;

        > span {
          height: 1.8rem;
          display: flex;
          align-items: center;
          justify-content: center;

          .vw-icon {
            height: 1.1rem;
          }
        }

        > p {
          font-size: 1.2rem;
          color: #bebebe;
          &.itemFalse {
            color: #798089;
            opacity: 0.6;
          }
        }
      }
    }
  }
`

export {
  Container,
  CardArea,
  RegistrationCardArea,
  CommissionsCardArea,
  PlansCardArea,
}
