import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateOccupation from './form_create'
import FormUpdateOccupation from './form_update'
import OccupationTable from './table'

export default function HobbyPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <OccupationTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateOccupation />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateOccupation />
          </Layout>
        }
      />
    </Routes>
  )
}
