import { Bank, City, Country, Language, Occupation, RegulatoryBody, } from '@viswals/services'
import { ActionProps } from '../reducers/datas/props'

export function setDataCountries(countries: Country[]): ActionProps {
    return {
        type: 'SET_DATA_COUNTRIES',
        countries,
    }
}

export function setDataOccupations(occupations?: Occupation[]): ActionProps {
    return {
        type: 'SET_DATA_OCCUPATIONS',
        occupations,
    }
}

export function setDataCities(cities: City[]): ActionProps {
    return {
        type: 'SET_DATA_CITIES',
        cities,
    }
}

export function setDataLanguages(languages: Language[]): ActionProps {
    return {
        type: 'SET_DATA_LANGUAGES',
        languages,
    }
}

export function setDataFields(fields?: any): ActionProps {
    return {
        type: 'SET_DATA_FIELDS',
        fields,
    }
}

export function setDataBanks(banks?: Bank[]): ActionProps {
    return {
        type: 'SET_DATA_BANKS',
        banks,
    }
}

export function setDataClinicianBirthDate(
    clinicianBirthDate?: string
): ActionProps {
    return {
        type: 'SET_DATA_CLINICIANBIRTHDATE',
        clinicianBirthDate,
    }
}

export function setDataRegulatoryBodies(
    regulatoryBodies: RegulatoryBody[]
): ActionProps {
    return {
        type: 'SET_DATA_REGULATORY_BODIES',
        regulatoryBodies,
    }
}
