/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'
import { BarProgressProps } from '.'

export const BarProgressBox = styled.div<BarProgressProps>`
  position: relative;
  height: 0.3rem;
  width: 100%;
  background-color: #3a3a3c;
  border: 0.15rem solid #222224;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8rem;

  div {
    content: '';
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    width: ${({ percent }) => `${percent}%`};
    background-color: ${({ bgColor }) => bgColor ?? '#5bdf6f'};
    border-radius: 8rem;
  }
`
