/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button, Input, Select, SelectedOptionsProps } from '@viswals/components'
import {
  Request,
  RegulatoryBody,
  createRegulatoryBody,
} from '@viswals/services'

import useDatas from '@src/hooks/useDatas'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import { StyledContentInstitution } from './styles'

export default function FormCreateRegulatoryBody() {
  const navigate = useNavigate()

  const { countries } = useDatas()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit } = useForm()

  async function onSubmit(data: any) {

    const dataToSend: any = {
      name: data.name,
      isoCode: data.isoCode,
      country: { uuid: data.countryUuid },
      enabled: true,
      area: {
        uuid: data.area
      }
    }

    try {
      setIsError('')
      await createRegulatoryBody(dataToSend as Request<RegulatoryBody>)
      navigate('/administration/common/regulatory-body')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentInstitution className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Regulatory Body"
            description="Here you can register on new regulatory body"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <Input label="ISO Code  " formRegister={{ ...register('isoCode') }} />

          <Select
            label="Country"
            labelTitle="Countries"
            options={
              countries.map((value) => ({
                label: value.name,
                value: value.uuid,
                icon: value.icon,
              })) as SelectedOptionsProps[]
            }
            formRegister={{ ...register('countryUuid'), watch }}
          />

          <div style={{ height: 20 }} />

          <Select
            label="Area"
            labelTitle="Select Area"
            placeholder="Select area"
            options={[
              {
                label: 'Medicine',
                value: '84ce4d70-a442-412b-bf27-06f4544a8661',
                icon: '',
                isoCode: '',
              },
              {
                label: 'Dentistry',
                value: '4beed17b-a38a-4da1-8b26-94d2f1513001',
                icon: '',
                isoCode: '',
              },
              {
                label: 'Psychology',
                value: 'e117dcf1-4acd-499f-80d2-7c868f23d6d0',
                icon: '',
                isoCode: '',
              }
            ]}
            formRegister={{ ...register('area'), watch }}
          />

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/regulatory-body')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentInstitution>
  )
}
