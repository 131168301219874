/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerLengthOfStudyCourses = styled.div`
  width: 29.9rem;
  padding: 0 3.75rem 0 3.65rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-right: 1px solid #333843;

  h3 {
    color: #bebebe;
    font-size: 1.6rem;
    font-family: var(--font-primary);
    font-weight: 700;
  }
  img {
    margin-top: 3.4rem;
  }
  p {
    width: 100%;
    margin-top: 3rem;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: #bebebe;
  }

  .vw-regis-safeArea {
    width: 100%;
    margin: 0.6rem 0 0 0.1rem;
    p {
      padding-bottom: 0.6rem;
      padding-left: 0rem;
    }
    .vw-regis-safeAreaItems {
      width: 100%;
      height: 3.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #333843;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      font-family: var(--font-secondary);
      color: #798089;
      cursor: pointer;
      input {
        display: none;
      }
      &.checked {
        background-color: var(--info-color);
        color: white;
        font-weight: 700;
      }
      :last-of-type {
        margin-bottom: 0px;
      }
    }
    .vw-regis-contentOptions {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0.3rem;
      padding-top: 0rem;
      .vw-regis-safeAreaItems {
        width: 7.2rem;
        margin-bottom: 0.7rem;
        &.studyMode {
          padding-top: 0.3rem !important;
          &:first-of-type {
            padding-top: 0rem !important;
            margin-right: 0.2rem;
          }
        }
      }
      &.alignLabelText {
        .vw-regis-safeAreaItems {
          text-align: center;
          font-size: 0.9rem;
          &:last-of-type {
            padding: 0 1rem;
          }
        }
      }
    }
    .subTitleParagrath {
      width: 100%;
      font-size: 1.1rem;
      font-weight: 700;
      font-family: var(--font-primary);
      color: #bebebe;
      margin-bottom: 0.5rem;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      &.first {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
      }
      &.second {
        margin-top: 1.7rem;
      }
      &.third {
        margin-top: 3.9rem;
      }
      .subTitleIconRight {
        margin-top: 0.2rem;
        margin-right: -0.1rem;
        margin-bottom: -0.1rem;
        svg {
          width: 1.2rem;
          height: 1.2rem;
        }
      }
    }
    .boxContentInputAndButton {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .vw-input-boxContentInput {
        width: 14.9rem;
        .vw-input-boxInput {
          padding-left: 0.7rem;
          padding-bottom: 0.1rem;
        }
        input {
          font-size: 1rem;
        }
      }
      label {
        width: 7.2rem;
        font-size: 0.9rem;
        margin-left: 0.4rem;
      }
    }
    .vw-contentMiniForm-select {
      margin-top: 0.5rem;
      .vw-select-boxContentInput {
        .vw-selectOptions-boxSelectOptions {
          .vw-selectOptions-boxScrollOptions {
            height: 40rem;
          }
        }
      }
      &:last-of-type {
        margin-top: 1rem;
      }
    }
  }
`

export const ContentMiniForm = styled.div`
  .vw-input-boxContentInput {
    .vw-input-boxInput {
      height: 3.4rem;
      input {
        text-decoration: none;
      }
    }
  }
  .vw-select-boxContentInput {
    .vw-select-BoxInput {
      height: 3.4rem;
      padding-left: 0.8rem;
      .vw-select-inputFieldStyle {
        font-size: 1rem;
        line-height: 1.3rem;
        padding-top: 0.1rem;
      }
      .vw-select-boxIconRight {
        width: 3rem;
        max-width: 3rem;
        min-width: 3rem;
        padding: 0;
        svg {
          width: 1rem;
          margin-top: 0.4rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
`

export const ContainertMessageToolTip = styled.div`
  width: 100%;
  padding-bottom: 1.1rem;
  .vw-tooltip-tooltipChildren {
    width: 34.6rem;
    height: 10.3rem;
    padding: 1.8rem 0.9rem 0 2rem;
    background-color: #15aeff;
    color: #fff;
    font-family: var(--font-secondary);
    font-size: 1.1rem;
    line-height: 1.7rem;
    margin-right: 0.5rem;
    position: relative;
    .vw-tooltipChildren-title {
      font-weight: 700;
    }
    &::before {
      content: '';
      width: 1.1rem;
      height: 1.1rem;
      background-color: #15aeff;

      position: absolute;
      bottom: -0.45rem;
      left: 16.9rem;
      transform: rotate(45deg);
    }
  }
`
