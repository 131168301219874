import { Country } from '@viswals/services'
import { ContainerContriesFlags } from './WalletStyles'

type CountriesFlagsProps = {
  onClickCountry: (country?: string) => void
  countries: Country[]
}

const CountriesFlags = ({ onClickCountry, countries }: CountriesFlagsProps) => {
  return (
    <ContainerContriesFlags className="vw-countriesFlags">
      <div className="title">Select your bank´s country</div>
      <div className="boxFlags">
        {countries.map((country, countryIndex) => {
          return (
            <div
              key={countryIndex}
              className="containerFlag"
              onClick={() => {
                onClickCountry(country.uuid)
              }}
              aria-hidden
            >
              <div className="contentFlag">
                <img src={country.icon} alt={country.name} />
              </div>
              <div className="contentLabel">{country.name}</div>
            </div>
          )
        })}
      </div>
    </ContainerContriesFlags>
  )
}

export default CountriesFlags
