import { Card, Select, Input } from '@viswals/components'
import DentistAndFlower from '@images/dentistAndFlower.svg'
import AstronautFlagPlanet from '@images/astronautPlanet.svg'
import UpperAndLowerCaseA from '@images/upperAndLowerCaseA.svg'
import BuildingsAndLocation from '@images/buildingsAndLocation.svg'
import useLogic from './useLogic'
import { BoxBoundarySteps, BoxContentCards } from './RegistrationStyles'

const StepRegistration = () => {
  const {
    errors,
    verified,
    newDataCountries,
    newRegulatoryBodies,
    watch,
    setValue,
    register,
    getFieldProps,
    onSubmit,
    handleSubmit,
  } = useLogic()

  return (
    <BoxBoundarySteps>
      <h1>Registration</h1>
      <p>Details of the professional registration</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('verified', { value: false })} />
        <BoxContentCards>
          <Card isVerified={verified} isError={!!errors?.countryRegistration}>
            <img src={AstronautFlagPlanet} alt="AstronautFlagPlanet" />
            <Select
              label="Country of Registration"
              labelTitle="Select Country"
              placeholder="Select Country"
              labelApply="Apply Changes"
              options={newDataCountries}
              formRegister={{
                ...register('countryRegistration', {
                  required: true,
                }),
                watch,
              }}
              search
              {...getFieldProps('countryRegistration')}
            />
          </Card>
          <Card
            isVerified={verified}
            isError={!!errors?.nationalBodyRegistration}
          >
            <img src={BuildingsAndLocation} alt="BuildingsAndLocation" />
            <Select
              label="National Body Of Registration"
              labelTitle="Select from list"
              placeholder="Select from list"
              options={newRegulatoryBodies}
              formRegister={{
                ...register('nationalBodyRegistration', {}),
                watch,
              }}
              search
              {...getFieldProps('nationalBodyRegistration')}
            />
            <Input
              placeholder="If other, insert here"
              formRegister={register('newBody', {
                onChange: (e) => {
                  if (e.target.value) {
                    setValue('nationalBodyRegistration', undefined)
                  }
                },
              })}
              {...getFieldProps('nationalBodyRegistration')}
            />
          </Card>
          <Card
            isVerified={verified}
            isError={!!errors?.professionalLicenseNumber}
          >
            <img src={DentistAndFlower} alt="BuildingsAndLocation" />
            <Input
              label="Professional Licence Number"
              placeholder="Insert number"
              formRegister={register('professionalLicenseNumber', {
                required: true,
              })}
              {...getFieldProps('professionalLicenseNumber')}
            />
          </Card>
          <Card
            isVerified={verified}
            isError={
              !!errors?.nameRegistrationForename ||
              !!errors?.nameRegistrationSurname
            }
          >
            <img src={UpperAndLowerCaseA} alt="BuildingsAndLocation" />
            <Input
              label="Name on Registration"
              placeholder="Insert forename"
              formRegister={register('nameRegistrationForename', {
                required: true,
              })}
              {...getFieldProps('nameRegistrationForename')}
            />
            <Input
              placeholder="Insert surname"
              formRegister={register('nameRegistrationSurname', {
                required: true,
              })}
              {...getFieldProps('nameRegistrationSurname')}
            />
          </Card>
        </BoxContentCards>
      </form>
    </BoxBoundarySteps>
  )
}

export default StepRegistration
