/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react'
import useLogicLayout from '../hook'

const useLogic = () => {
  const {
    layout,
    setLayout,
    route,
    handleContentClick,
    dataMainMenuItems,
    handleWorkSpaceClick,
  } = useLogicLayout()

  useEffect(() => {
    document.addEventListener('click', listenClick)
    return () => {
      document.removeEventListener('click', listenClick)
    }
  }, [layout.openMainMenu])

  function listenClick(e: any) {
    if (layout.openMainMenu) {
      const pathList = e.composedPath() as any[]

      const checkClass = !!pathList.find(
        (f) => f.className?.length && f.className?.includes('MainMenuArea')
      )
      if (!checkClass) {
        // close menu in clickOutside
        setLayout('openMainMenu', false)
      }
    }
  }

  return {
    layout,
    route,
    handleContentClick,
    dataMainMenuItems,
    handleWorkSpaceClick,
  }
}

export default useLogic
