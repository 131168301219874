import React from 'react'
import { Card, QuestionModal, Toggle } from '@viswals/components'

import { FinishRegistrationProps } from '../data'

import useLogic from './useLogic'
import Container from './PaymentsAccountStyles'

const StepPaymentsAccount = () => {
  const {
    fields,
    control,
    verified,
    watch,
    onSubmit,
    handleSubmit,
    handleScroll,

    openModalNotFinished,
    setOpenModalNotFinished,
    handleGoToCountryRegistration,
  } = useLogic()
  return (
    <Container>
      <div className="vw-termsOfService-header">
        <div>
          <h2>Payment & Account Cancelation Policies</h2>
          <span>Read carefully and accept the policies below</span>
        </div>
        {openModalNotFinished ? (
          <div className="boxDeleteCard">
            <QuestionModal
              title="Missing Registration"
              description="Please fill in all fields for all registration countries."
              confirmLabel="Ok"
              cancelLabel="Cancel"
              cancelFunction={() => setOpenModalNotFinished(false)}
              confirmFunction={handleGoToCountryRegistration}
            />
          </div>
        ) : null}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-termsOfService-cardArea">
          {fields.map((i, indexCard) => {
            const item = i as unknown as FinishRegistrationProps
            const isDisabled = watch(`data.${indexCard}.isDisabled`)
            return (
              <React.Fragment key={i.id}>
                <Card isVerified={verified}>
                  <div className="vw-termsOfService-img">
                    <img
                      src={item.img}
                      alt="Terms of Service"
                      className={`img_${item.uuid}`}
                    />
                  </div>
                  <div
                    className="descriptionArea"
                    onScroll={(event) => handleScroll(event, indexCard)}
                  >
                    <h2>{item.title}</h2>
                    <div className="description">{item.description}</div>
                  </div>
                  <div className={`toggleArea ${!isDisabled ? 'enabled' : ''}`}>
                    <p>I understand and agree with the above</p>
                    <Toggle
                      name={`data.${indexCard}.isConfirmed`}
                      control={control}
                      isDisabled
                      required
                    />
                  </div>
                </Card>
              </React.Fragment>
            )
          })}
        </div>
      </form>
    </Container>
  )
}

export default StepPaymentsAccount
