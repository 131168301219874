import { Rate } from '@viswals/services'

import { ActionProps } from './props'

export default function setActionRates(rates: Rate[]): ActionProps {
  return {
    type: 'SET_FEEDBACK',
    rates,
  }
}
