import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

const ClinicianRegistrationRoute = () => {
  const { clinician, countryActive, getClinicianRegister } =
    useClinicianRegister()

  useEffect(() => {
    getClinicianRegister()
  }, [])

  return (
    <div>
      {clinician?.uuid && countryActive ? (
        <Navigate
          to={`/clinician-registration/step1/registration/${countryActive.country.isoCode?.toLocaleLowerCase()}`}
        />
      ) : (
        <p>Loading...</p>
      )}
    </div>
  )
}

export default ClinicianRegistrationRoute
