import StepDegrees from './degree'
import StepDentistryAwards from './dentistry-awards'
import StepMajorCourses from './major-course'
import StepShortCourses from './short-course'

const listStep3 = [
  {
    name: 'Degree',
    subStep: 'DEGREE',
    route: 'degree',
    component: <StepDegrees />,
  },
  {
    name: 'Major Courses',
    subStep: 'MAJOR_COURSES',
    route: 'major-courses',
    component: <StepMajorCourses />,
  },
  {
    name: 'Short Courses',
    subStep: 'SHORT_COURSES',
    route: 'short-courses',
    component: <StepShortCourses />,
  },
  {
    name: 'Dentistry Awards',
    subStep: 'DENTISTRY_AWARDS',
    route: 'dentistry-awards',
    component: <StepDentistryAwards />,
  },
]

export default listStep3
