import { VerificationTitle } from '../../style'
import { DocumentBox, FigureArea, ImageArea, OriginalBox } from './style'

type OriginalDocumentProps = {
  image?: string
}

const OriginalDocument = ({ image }: OriginalDocumentProps) => {
  return (
    <OriginalBox>
      <VerificationTitle className="title">Original document</VerificationTitle>

      <DocumentBox>
        <ImageArea>
          <div className="line-vertical" />
          <div className="line-horizontal" />
          <FigureArea>
            <figure>
              {image && <img src={image} alt="Original Document" />}
            </figure>
          </FigureArea>
        </ImageArea>
      </DocumentBox>
    </OriginalBox>
  )
}

export default OriginalDocument
