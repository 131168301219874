import { HeaderMenuBox, HeaderMenuNav, HeaderMenuDetail } from './style'

const SuperAdministratorHeaderMenu = () => {
  return (
    <HeaderMenuBox>
      <HeaderMenuNav>
        {[
          'Team',
          'My Digital Clinic',
          'My Digital Clinic',
          'My Team',
          'Platform Registration',
          'Online Activity',
          'Interactions',
          'Pricing',
          'Salaries',
          'Safety & Compliance',
        ].map((item) => (
          <li key={item} className={item === 'Team' ? 'active' : ''}>
            {item}
          </li>
        ))}
      </HeaderMenuNav>
      <HeaderMenuDetail />
    </HeaderMenuBox>
  )
}

export default SuperAdministratorHeaderMenu
