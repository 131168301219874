import styled from "styled-components";

interface StyledProps {
  bg_color?: string;
  text_color?: string;
  grid_cols?: string;
  gap?: string;
  ellip?: true;
  height?: number;
}

const MainPatientContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const UpperBarsContainer = styled.div`
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 10px;
`

const UpperBar = styled.div<StyledProps>`
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 10px 20px;
    background-color: ${({ bg_color }) => bg_color};
    color: ${({ text_color }) => text_color};
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
`

const UpperBarIcon = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
`

const UpperBarName = styled.div`
    font-family: Nebula;
    font-size: 12px;
    line-height: 16px;
    flex-grow: 1;
`

const UpperBarCount = styled.div`
    font-family: Nebula;
    font-size: 15px;
    font-weight: bold;
    line-height: 20px;
`

const TableContainer = styled.div`
    background-color: #222224;
    flex-grow: 1;
    border-radius: 5px;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.25);
    padding: 20px 10px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 0.1px;
        height: 0px;
    }
`

const TableChildContainer = styled.div<StyledProps>`
    height: ${({ height }) => `calc(100vh - ${height || 0}px)`};
    flex-grow: 1;
    position: relative;
    min-width: fit-content;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: 0.1px;
    }
`

const TableUpperBarsContainer = styled.div`
    display: grid;
    gap: 4px;
    grid-template-columns: 211px 347px 770px 251px 144px 70px;
    margin-bottom: 17px;
`

const TableUpperBar = styled.div<StyledProps>`
    border-radius: 5px;
    background-color: ${({ bg_color }) => bg_color};
    color: ${({ text_color }) => text_color};
    font-family: Oxanium;
    font-size: 9px;
    font-weight: bold;
    line-height: 12px;
    padding: 5px 10px 3px;
`

const TableLabelsContainer = styled.div<StyledProps>`
    font-family: Oxanium;
    font-size: 10px;
    font-weight: bold;
    color: #545456;
    padding: 0 10px;
    display: grid;
    grid-template-columns: ${({ grid_cols }) => grid_cols};
    margin-bottom: 9px;
`

const TableLabel = styled.div``;

const TableRowContainer = styled.div<StyledProps>`
    display: grid;
    grid-template-columns: ${({ grid_cols }) => grid_cols};
    padding: 8px 10px;
    background-color: #2C2C2E;
    border-radius: 10px;
    margin-bottom: 2px;
    &:last-child {
        margin-bottom: 0px;
    }
    &:hover {
        background-color: rgb(53 53 53);
    }
`

const TableWebButton = styled.div<StyledProps>`
    width: 53px;
    height: 24px;
    color: #00BAFF;
    border-radius: 5px;
    background-color: #1C1C1E;
    padding: 5px 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    line-height: 14px;
    font-family: Oxanium;
    font-size: 10px;
    align-self: center;
`

const TableIcons = styled.div<StyledProps>`
    display: flex;
    align-items: center;
    gap: 8px;
`

const TableTxt = styled.div<StyledProps>`
    font-family: Oxanium;
    font-size: 10px;
    display: flex;
    gap: ${({ gap }) => gap};
    align-items: center;
    color: #7C7C80;
    line-height: 1.5;
    overflow: hidden;
    word-break: break-word;
    margin-right: 3px;
  }
`

const TableInnerTxtSpan = styled.span<StyledProps>`
    line-height: 1.5;
`

const LoadMoreContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;

  overflow: hidden;
  top: 0;
  left: 0;
  background-color: #222224;
  .ring {
    position: absolute;
    bottom: calc(50% - 25px);
    right: calc(50% - 25px);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-transform: uppercase;
    width: 50px;
    height: 50px;
  }
  .ring:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid var(--emerald-color);
    border-right: 3px solid var(--emerald-color);
    border-radius: 50%;
    animation: animateC 1s linear infinite;
  }

  @keyframes animateC {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
`

const BottomLoadMoreContainer = styled.div`
  width: 100%;
  height: 70px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: #222224;
  .ring {
    transform: translate(0%, 0%);
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  .ring:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid var(--emerald-color);
    border-right: 3px solid var(--emerald-color);
    border-radius: 50%;
    animation: animateC 1s linear infinite;
  }

  @keyframes animateC {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
`

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 15px;
  font-family: Poppins;
  color: #868686;
`

export {
  MainPatientContainer,
  UpperBarsContainer,
  UpperBar,
  UpperBarIcon,
  UpperBarName,
  UpperBarCount,
  TableContainer,
  TableUpperBarsContainer,
  TableUpperBar,
  TableLabelsContainer,
  TableLabel,
  TableRowContainer,
  TableChildContainer,
  TableWebButton,
  TableIcons,
  TableTxt,
  TableInnerTxtSpan,
  LoadMoreContainer,
  BottomLoadMoreContainer,
  NoDataContainer
}