import { ActionProps } from './props'

const INITIAL_STATE = {
  openMainMenu: false,
  openWorkSpace: false,
  openUpperCalendar: false,
  openMapTotalExpanded: false,
  updateMap: 0,
  updateMapLatLng: {},
}

export default function reducer(state = INITIAL_STATE, action: ActionProps) {
  if (action.type === 'SET_LAYOUT') {
    return {
      ...state,
      [action.item]: action.value,
    }
  }
  return state
}
