import styled from 'styled-components'

export const CountryOptionsContainer = styled.div`
  font-family: 'Poppins';
  font-size: 1erm;
  font-weight: bold;
  color: #bebebe;
`

export const CountryOptionsContent = styled.select`
  border: none;
  outline: none;
  background: #242426;

  font-family: 'Poppins';
  font-size: 1erm;
  color: #bebebe;

  cursor: pointer;
`

export const CountryOptionsItem = styled.option``
