import styled, { css } from 'styled-components'

export const VerificationStepsBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.2rem;
`

type StepItemBoxProps = {
  active?: boolean
}

export const StepItemBox = styled.div<StepItemBoxProps>`
  flex: 2;
  height: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  border-radius: 0.3rem;

  ${(props) => {
    if (props.active) {
      return css`
        background-color: #0c0c0d;
      `
    }
    return css``
  }}

  .title {
    font-family: 'Nebula';
    font-size: 8px;
    font-weight: bold;
    color: #7c7c80;
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.4rem 0;

    b {
      font-family: 'Poppins';
      font-size: 1.2rem;
      font-weight: bold;
      color: #aeaeb2;
    }

    .details-percent {
      font-family: 'Oxanium';
      font-size: 1.2rem;
      color: #545456;
      font-weight: bold;
      .color {
        color: #5bdf6f;
      }
    }
  }

  .progress {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 0.4rem;
  }

  .progress-full {
    width: 100%;
    height: 0.5rem;
  }

  .progress-steps {
    display: flex;
    flex: 1;
    height: 0.5rem;
    gap: 0.4rem;
  }
`
