/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material'
import { AlertModal, BoxListOptions } from '@viswals/components-platform'

import { LoadingTable } from '@src/pages/administration/common/_components/loading-table'

import PopUpReportUnusual from '../components/PopUpReportUnusual/index'

import useLogic from './useLogic'
import ItemReportUnusual from './item'

import { ContainerTableBodyReport, ContainerTableReport } from './styles'
import {
  ContainerPopUpDeleteItem,
  ContentLoadingList,
} from '../report-anything-unusual/styles'
import { getLabelFeedBackType, getLabelFeedBackWhere } from '../utils/utils'

export default function SendUsFeedbaack() {
  const {
    feedbacks,
    getLabelPlatform,

    itemReport,
    handleDeleteItem,
    handleClickIconItem,
    handleConfirmDeleteItem,
    handleConfirmCancelItem,

    loadingBoxRef,
    isRequesting,

    feedbackTotal,
  } = useLogic()

  return (
    <ContainerTableReport>
      <div className="header">
        <div className="td tdOne">Viswals Platform</div>
        <div className="td tdTwo">User</div>
        <div className="td tdThree">Client Email</div>
        <div className="td tdFour">Date</div>
        <div className="td tdFive">Hour</div>

        <div className="td tdEight">Feedback Type</div>
        <div className="td tdNine">Location</div>

        <div className="td tdSix">Message</div>
        <div className="td tdSeven">&nbsp;</div>
      </div>
      <ContainerTableBodyReport className="tbody">
        <LoadingTable />
        <BoxListOptions
          color="#F72A1E"
          id="vw-option-report-id"
          overlayClassName="vw-option-report"
          items={[
            {
              label: 'Delete Notification',
              onClick: handleDeleteItem,
            },
          ]}
        />
        <PopUpReportUnusual title="Send Us Feedback" itemReport={itemReport} />
        <ContainerPopUpDeleteItem className="vw-alert-modal-first">
          <AlertModal
            title="You are about to delete a Viswals App Communication"
            message="Are you sure you want to delete?"
            handleCancel={handleConfirmCancelItem}
            handleConfirm={handleConfirmDeleteItem}
            labelCancel="Cancel"
            labelConfirm="Delete"
          />
        </ContainerPopUpDeleteItem>
        {feedbacks.feedbacks?.map((itemReported, _index) => {
          return (
            <div
              aria-hidden
              key={_index}
              onClick={(event) =>
                handleClickIconItem(event.target as any, itemReported)
              }
              style={{
                marginBottom:
                  _index === (feedbacks.feedbacks?.length ?? 1) - 1 &&
                  feedbackTotal !== feedbacks.feedbacks?.length
                    ? '3rem'
                    : '',
              }}
            >
              <ItemReportUnusual
                key={_index}
                itemReport={itemReported}
                platformLabel={getLabelPlatform(itemReported.platform)}
                getLabelFeedBackType={getLabelFeedBackType}
                getLabelFeedBackWhere={getLabelFeedBackWhere}
              />
            </div>
          )
        })}
        {feedbackTotal !== feedbacks.feedbacks?.length ? (
          <ContentLoadingList ref={loadingBoxRef}>
            {isRequesting && <CircularProgress size={30} />}
          </ContentLoadingList>
        ) : null}
      </ContainerTableBodyReport>
    </ContainerTableReport>
  )
}
