/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const VerificationCardBox = styled.div`
  display: flex;
  background-color: #222224;
  border: 0.1rem solid #2c2c2e;
  width: 100%;
  min-height: 4.48rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.27);
  overflow: hidden;
`
