/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import {
  createOwnWebsite,
  OwnWebsite,
  OwnWebsiteFilter,
  Request,
  retrieveOwnWebsite,
  updateOwnWebsite,
} from '@viswals/services'
import { ReducersProps } from '@src/types/reducers'
import { useDispatch, useSelector } from 'react-redux'
import useLoading from '@src/hooks/useLoading'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useStepsError from '@src/hooks/useStepsError'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setError,
    setValue,
    clearErrors,
    control,
    formState: { errors },
  } = useForm()

  const { getErrors, getFieldProps } = useStepsError(errors)
  const { loading, setLoading } = useLoading()
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    getData()
  }, [registrationActive])
  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, info) => {
      if (info.type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  async function getData() {
    setLoading(true)
    try {
      const request: Request<OwnWebsiteFilter> = {
        fields: ['uuid', 'clinicianRegisterUuid', 'websiteUrl'],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveOwnWebsite(request)
      const data = resp?.data.data ? resp?.data.data[0] : null
      let updateInfo = {}
      if (data) {
        updateInfo = {
          ...data,
        }
      }

      setTimeout(() => {
        reset({
          ...updateInfo,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        })
        dispatch(setNavigationActiveNext(true))
      }, 200)
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  async function onSubmit(data: any) {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    if (!data.uuid) {
      await createOwnWebsite(data as Request<OwnWebsite>)
    } else {
      await updateOwnWebsite(data as Request<OwnWebsite>)
    }
    subStepDone()
    navigate(nextRoute)
  }

  return {
    handleSubmit,
    onSubmit,
    register,
    setValue,
    watch,
    getFieldProps,
    clearErrors,
    errors,
    control,
    verified,
  }
}

export default useLogic
