import styled from 'styled-components'

// TODO: eliminar borda e adicionar verified
// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  fieldset {
    border: none;
  }

  .vw-typeOfRegistration-header {
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-typeOfRegistration-cardArea {
    display: flex;
    label {
      margin-right: 2.4rem;
      cursor: pointer;

      &:last-of-type {
        margin-right: 0;
      }
      .vw-card-cardBox {
        width: 35rem;
        padding: 15.3rem 5rem;
        border-color: red;

        text-align: center;
        color: var(--gray-100-color);

        .item-ico .vw-icon {
          width: 9.49rem;
          margin-bottom: 6.4rem;
          transition: all 0.4s ease-in-out;
        }

        &.vw-card-cardBoxSelected {
          &::before {
            transition: all 0.4s ease-in-out;
          }
          .item-ico .vw-icon {
            color: #15aeff;
          }
        }

        p {
          font-family: var(--font-primary);
          font-weight: 700;
          font-size: 1.6rem;

          margin-bottom: 7.52rem;
        }

        .vw-radio-inputRadioArea {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
`
