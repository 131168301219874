/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef, useCallback } from 'react'
import gsap, { Power4 } from 'gsap'
import { debounce } from '@mui/material'

import {
  PaginationRequest,
  PlatformType,
  Report,
  ReportFilter,
  Request,
  deleteReport,
  retrieveReport,
  updateReport,
} from '@viswals/services'

import useReports from '@src/hooks/useReports'
import useOnScreen from '@src/hooks/useOnScreen'

const useLogic = () => {
  const {
    platform,
    startSelect,
    endSelect,
    reports,
    totalReportsPerPage,
    setReports,
    getReportUnusual,

    reportTotal,
    setReportTotal,
    setReportCounter,
  } = useReports()

  const [itemReport, setItemReport] = useState<Report>({})
  const [isRequesting, setIsRequesting] = useState(false)
  const [superLoading, setSuperLoading] = useState(true)

  const loadingBoxRef = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(loadingBoxRef)

  useEffect(() => {
    firstRequest()
  }, [platform, startSelect, endSelect])

  useEffect(() => {
    getByVisible()
  }, [isVisible])

  async function firstRequest() {
    window.localStorage.removeItem('page_report_unusual')
    if (isRequesting) {
      return
    }
    setIsRequesting(true)
    handleActiveLoading()
    setTimeout(async () => {
      await getReportUnusual().then(() => {
        handleDisabledLoading()
        setIsRequesting(false)
      })
    }, 350)
  }

  async function getByVisible() {
    if (
      isVisible === true &&
      superLoading === false &&
      reports.reports?.length !== reportTotal
    ) {
      newGetUpdate()
    }
  }

  const newGetUpdate = useCallback(
    debounce(async () => {
      await getMoreReports()
    }, 350),
    [reports.reports]
  )

  useEffect(() => {
    window.addEventListener('click', addListenClickReport)
    return () => {
      window.removeEventListener('click', addListenClickReport)
    }
  }, [])

  async function handleActiveLoading() {
    const getClass = window.document.getElementsByClassName(
      'vw-loadingContainer'
    )
    gsap.to(getClass, {
      zIndex: 999,
      duration: 0.1,
      onComplete: () => {
        gsap.to(getClass, {
          opacity: 1,
          duration: 0.25,
        })
      },
    })
  }

  async function getMoreReports() {
    setIsRequesting(true)

    try {
      const request: Request<ReportFilter> = {
        filters: {
          platform: reports.platform === 'ALL' ? undefined : reports.platform,
          communication: 'REPORT_ANYTHING_UNUSUAL',
        },
        pagination: {
          page:
            Number(window.localStorage.getItem('page_report_unusual') ?? 0) + 1,
          itemsPerPage: totalReportsPerPage,
        },
        orderBy: {
          column: 'createdAt',
          direction: 'desc',
        },
        fields: [
          'uuid',
          'platform',
          'communication',
          'accountUsername',
          'message',
          'createdAt',
          'read',
        ],
      }

      const { data } = await retrieveReport(request)

      if (data.data) {
        setTimeout(() => {
          const toConvert: Report[] = (data.data as Report[]).map((item) => ({
            ...item,
          }))

          const dataConcat: Report[] = reports.reports ?? []

          toConvert.forEach(async (rep) => {
            dataConcat.push(rep)
          })

          setReports(dataConcat)

          setReportTotal(data.pagination.total)
          const toSetPagination: PaginationRequest = data.pagination

          window.localStorage.setItem(
            'page_report_unusual',
            toSetPagination.page.toString()
          )
          setIsRequesting(false)
          return toConvert
        }, 1000)
      }
      return []
    } catch (error) {
      setIsRequesting(false)
      console.error(error)
      return []
    }
  }

  function handleDisabledLoading() {
    setSuperLoading(false)
    const getClass = window.document.getElementsByClassName(
      'vw-loadingContainer'
    )
    gsap.to(getClass, {
      opacity: 0,
      duration: 0.25,
      onComplete: () => {
        gsap.to(getClass, {
          zIndex: -1,
          duration: 0.15,
        })
      },
    })
  }

  async function handleClickItem(item: Report) {
    const getQuery = window.document.getElementById('popUpReportUnusual')

    gsap.to(getQuery, {
      zIndex: 999,
      duration: 0.1,
      onComplete: () => {
        gsap.to(getQuery, {
          opacity: 1,
          duration: 0.2,
        })
      },
    })

    if (item.read === true) {
      return
    }

    await updateReport({
      uuid: item.uuid,
      read: true,
    } as Request<ReportFilter>).then(() => {
      const newReports = reports.reports?.map((i) => {
        if (i.uuid === item.uuid) {
          return { ...i, read: true }
        }
        return i
      }) as Report[]
      setReports(newReports)
      const oldCounter = reports.counter
      if (oldCounter) {
        oldCounter.reportTotalRead += 1

        setReportCounter(oldCounter)
      }
    })
  }

  async function handleClickIconItem(event: any, item: Report) {
    const itemId: HTMLElement | null = window.document.getElementById(
      `item-icon-${item.uuid}`
    )

    setItemReport(item)

    if (event.className.baseVal) {
      handleClickItem(item)
      return
    }

    const includeIconRight = event.className?.includes('iconRight')
    const isMore = event.alt !== undefined && event.alt === 'More'

    const openDelete = includeIconRight || isMore

    if (!openDelete) {
      window.document
        .getElementById(`itemList-${item.uuid}`)
        ?.classList.add('isSelected')
      handleClickItem(item)
      return
    }

    if (itemId !== null) {
      const getContainer: HTMLCollectionOf<Element> =
        window.document.getElementsByClassName(
          'vw-boxListOptionsContainer vw-option-report'
        )
      if (getContainer.length > 0) {
        getContainer[0].setAttribute(
          'style',
          `position: absolute; left: ${itemId.offsetLeft - 160}px; top: ${
            itemId.offsetTop
          }px; z-index: 99;`
        )
      }
    }
  }

  async function addListenClickReport(ev: MouseEvent) {
    const pathList = ev.composedPath() as any[]

    const classFind = pathList.find(
      (f) => f?.className?.length && f.className?.includes('vw-option-report')
    )

    const classFind2 = pathList.find(
      (f) => f?.className?.length && f.className?.includes('iconRight')
    )
    const getContainer1 = window.document.getElementById('vw-option-report-id')

    if (
      classFind === undefined &&
      classFind2 === undefined &&
      getContainer1?.style.zIndex === '99'
    ) {
      const getContainer2 = window.document.getElementById(
        'vw-option-report-id'
      )
      gsap.to(getContainer2, {
        duration: 0.01,
        zIndex: '-2',
        ease: Power4.easeOut,
      })
    }
  }

  async function handleDeleteItem() {
    const getContainer2 = window.document.getElementById('vw-option-report-id')

    window.document
      .getElementById(`itemList-${itemReport.uuid}`)
      ?.classList.add('isSelected')

    gsap.to(getContainer2, {
      duration: 0.01,
      zIndex: '-2',
      ease: Power4.easeOut,
    })
    handleOpenModalDelete()
  }

  async function handleConfirmDeleteItem() {
    await deleteReport({
      uuid: itemReport.uuid,
    } as Request<ReportFilter>).then(() => {
      const newReports = reports.reports?.filter(
        (i) => i.uuid !== itemReport.uuid
      ) as Report[]
      setReports(newReports)
      const oldCounter = reports.counter
      if (oldCounter) {
        oldCounter.reportTotal -= 1
        oldCounter.total -= 1

        setReportCounter(oldCounter)
      }
    })
    handleConfirmCancelItem()
  }

  async function handleConfirmCancelItem() {
    const qtyList = window.document.getElementsByClassName('isSelected')

    for (const nameed of qtyList) {
      nameed.classList.remove('isSelected')
    }

    gsap.to(window.document.getElementsByClassName('vw-alert-modal-first'), {
      zIndex: '-2',
      duration: 0.01,
    })
  }

  async function handleOpenModalDelete() {
    gsap.to(window.document.getElementsByClassName('vw-alert-modal-first'), {
      zIndex: '99',
      duration: 0.01,
    })
  }

  const getLabelPlatform = (value?: PlatformType): string => {
    if (value === 'PATIENT') {
      return 'Viswals App'
    }
    if (value === 'CLINIC') {
      return 'Viswals Clinic'
    }
    return 'Viswals Clinician'
  }

  return {
    reports,
    getLabelPlatform,

    itemReport,
    handleDeleteItem,
    handleClickIconItem,
    handleConfirmDeleteItem,
    handleConfirmCancelItem,

    loadingBoxRef,
    isRequesting,

    reportTotal,
  }
}

export default useLogic
