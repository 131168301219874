import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { Currency, Request, retrieveFieldDentistry, } from '@viswals/services'
import gsap from 'gsap'
import { Button } from '@viswals/components-platform'

import HeaderContentCommon from '../_components/HeaderContentCommon'
import { StyledContentField, StyledContentFieldTable } from './styles'
import gridStyle from '../_components/grid-style'
import { LoadingTable } from '../_components/loading-table'
import { LocalPaginationRequestProps } from '../country/table'

export type LocalCurrencyAdm = {
    id?: number
} & any

export default function CurrencyTable() {
    const navigate = useNavigate()

    const [currencies, setCurrencies] = useState<LocalCurrencyAdm[]>([])
    const [pagination, setPagination] = useState<LocalPaginationRequestProps>({
        itemsPerPage: 25,
        page: 1,
        total: 0,
    })

    useEffect(() => {
        ; (async () => {
            await getLocalFields(pagination)
        })()
    }, [])

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'icon',
            headerName: 'Icon Id',
            flex: 1,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            renderCell: (params) => {
                return (
                    <div style={{ display: 'flex', gap: 20 }}>
                        <Button
                            label="Edit"
                            uuid=""
                            style={{ backgroundColor: 'orange', color: 'black' }}
                            handleClick={() => {
                                const fieldToSave: Currency = params.row
                                window.localStorage.setItem(
                                    'currencyEdit',
                                    JSON.stringify(fieldToSave)
                                )
                                navigate(
                                    `/administration/common/field-dentistry/edit/${fieldToSave.uuid}`
                                )
                            }}
                        />
                    </div>
                )
            },
            align: 'center',
            headerAlign: 'center',
            width: 100,
            sortable: false,
        },
    ]

    async function getLocalFields(
        paginationed: LocalPaginationRequestProps | undefined
    ) {
        try {
            await getFields(paginationed)
        } catch (error) {
            console.error({
                error,
            })
        }
    }

    async function getFields(
        paginationed: LocalPaginationRequestProps | undefined
    ) {
        const request: Request<any> = {
            fields: [
                'uuid',
                'name',
                'enabled',
                'updatedAt',
                'createdAt',
            ],
            pagination: {
                itemsPerPage: 25,
                page: paginationed?.page as number,
            },
            orderBy: {
                column: 'name',
                direction: 'asc',
            },
            filters: {
                area: {
                    uuid: "4beed17b-a38a-4da1-8b26-94d2f1513001"
                }
            }
        }
        try {
            const resp = await retrieveFieldDentistry(request)
            const { data } = resp

            if (data.code === 200) {
                const dataCities: Currency[] = data.data

                const newLanguages: LocalCurrencyAdm[] = dataCities.map(
                    (value, _index) => {
                        return {
                            id: _index,
                            uuid: value.uuid,
                            name: value.name,
                            icon: value.icon,
                            enabled: value?.enabled ?? false,
                        }
                    }
                )
                setCurrencies(newLanguages)

                setTimeout(() => {
                    setPagination(
                        (value) =>
                        ({
                            ...value,
                            ...{
                                itemsPerPage: paginationed?.itemsPerPage,
                            },
                            total: data.pagination
                                ? data.pagination.total
                                : data.data.length,
                        } as LocalPaginationRequestProps)
                    )
                }, 200)
                disabledLoading()
            } else {
                disabledLoading()
            }
        } catch (e) {
            disabledLoading()
            console.error(e)
        }
    }

    function handleClickAdd() {
        navigate('/administration/common/field-dentistry/new')
    }

    async function addPage(page: number) {
        const pageToSet: LocalPaginationRequestProps = {
            itemsPerPage: 25,
            page: page + 1,
        }
        try {
            await getLocalFields(pageToSet)
        } catch (error) {
            console.error({
                error,
            })
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async function enabledLoading() {
        const query = window.document.querySelector('.vw-loadingContainer')
        gsap.to(query, {
            zIndex: 99,
            duration: 0.1,
            onComplete: () => {
                gsap.to(query, {
                    opacity: 1,
                    delay: 0.1,
                    duration: 0.25,
                })
            },
        })
    }

    function disabledLoading() {
        setTimeout(() => {
            const query = window.document.querySelector('.vw-loadingContainer')
            gsap.to(query, {
                opacity: 0,
                duration: 0.25,
                onComplete: () => {
                    gsap.to(query, {
                        zIndex: -1,
                        delay: 0.25,
                        duration: 0.25,
                    })
                },
            })
        }, 300)
    }

    return (
        <StyledContentField>
            <HeaderContentCommon
                name="Fields of Dentistry"
                description="Manager information"
                onClickAdd={() => handleClickAdd()}
            />

            <StyledContentFieldTable>
                <LoadingTable />
                <DataGrid
                    sx={gridStyle}
                    rows={currencies}
                    columns={columns}
                    disableColumnMenu
                    disableColumnFilter
                    disableColumnSelector
                    experimentalFeatures={{ newEditingApi: false }}
                    onPageChange={(page) => {
                        enabledLoading().then(() => {
                            setTimeout(() => {
                                addPage(page)
                            }, 200)
                        })
                    }}
                    pagination
                    rowsPerPageOptions={[25]}
                    rowCount={pagination.total}
                    initialState={{
                        pagination: {
                            pageSize: pagination.itemsPerPage,
                        },
                    }}
                    paginationMode="server"
                />
            </StyledContentFieldTable>
        </StyledContentField>
    )
}
