import { combineReducers } from 'redux'

import clinician from './clinician'
import datas from './datas'
import diary from './diary'
import layout from './layout'
import loading from './loading'
import navigation from './navigation'
import steps from './steps'
import reports from './report/report'
import feedbacks from './report/feedback'
import rates from './report/rate'
import shares from './report/share'
import dropouts from './report/dropout'

export default combineReducers({
  steps,
  loading,
  layout,
  clinician,
  diary,
  datas,
  navigation,
  reports,
  feedbacks,
  rates,
  shares,
  dropouts,
})
