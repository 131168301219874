import { useEffect, useRef, useState } from 'react'

import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import useLoading from '@src/hooks/useLoading'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'

import {
  createUploadPhoto,
  Request,
  retrieveUploadPhoto,
  updateUploadPhoto,
  UploadPhoto,
  UploadPhotoFilter,
} from '@viswals/services'
import useClinicianRegister from '@src/hooks/useClinicianRegister'

const useLogic = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const inputRefFile = useRef(null)
  const { loading, setLoading } = useLoading()
  const { subStepDone, nextRoute } = useClinicianRegister()
  const { handleSubmit, register, watch, reset, setValue } = useForm()
  const [isReady, setIsReady] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [isFile, setIsFile] = useState(false)

  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  useEffect(() => {
    getData()
  }, [registrationActive])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { name }) => {
      if (name === 'upload' && !confirm) {
        dispatch(setNavigationConfirm(true))
        setIsFile(true)
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (next && !loading && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  const getData = async () => {
    setLoading(true)
    try {
      const request: Request<UploadPhotoFilter> = {
        fields: ['uuid', 'upload', 'clinicianRegisterUuid', 'verified'],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveUploadPhoto(request)
      const data: UploadPhoto =
        resp.data && resp.data?.data ? resp.data.data[0] : null

      setTimeout(() => {
        const dataReset: UploadPhoto = {
          upload: data?.upload,
          uuid: data?.uuid,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          verified: data?.verified,
        }
        reset(dataReset)
        setLoading(false)
        if (data?.uuid) {
          setIsFile(true)
        }
      }, 300)
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  const onSubmit = async (data: UploadPhoto) => {
    if (!isFile) {
      return false
    }

    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const dataToSend: UploadPhoto = {
        ...data,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      }
      if (!data.uuid) {
        await createUploadPhoto(dataToSend as Request<UploadPhoto>)
      } else {
        await updateUploadPhoto(dataToSend as Request<UploadPhoto>)
      }
      subStepDone()
      navigate(nextRoute)
    } finally {
      setLoading(false)
    }
  }

  const handleUploadNewID = () => {
    setIsModalOpen(true)
  }

  const handleConfirmNewID = () => {
    setIsModalOpen(false)
    const { current } = inputRefFile
    ;(current || { click: () => {} }).click()
  }

  const handleCancelModelDelete = () => {
    setIsModalOpen(false)
  }

  function uploadChanged() {
    if (!confirm) {
      setValue('verified', false)
      dispatch(setNavigationConfirm(true))
    }
  }
  return {
    inputRefFile,
    isModalOpen,

    handleSubmit,
    onSubmit,
    handleUploadNewID,
    register,
    watch,
    setValue,
    uploadChanged,
    handleConfirmNewID,
    handleCancelModelDelete,
  }
}

export default useLogic
