/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const OriginalBox = styled.div`
  width: 100%;
  padding: 2rem;

  .title {
    margin-top: 1.6rem;
    margin-bottom: 8rem;
  }
`

export const DocumentBox = styled.div`
  padding: 0 5rem;
  width: 100%;
  height: 61rem;
`
export const ImageArea = styled.div`
  width: 100%;
  height: 100%;

  position: relative;
  .line-vertical {
    position: absolute;
    content: '';
    height: 100%;
    width: 95%;

    &::before {
      content: '';
      position: absolute;
      top: -1.5rem;
      left: 2.5%;
      height: 0.8rem;
      width: 100%;
      border-bottom: 1px solid #3a3a3c;
      border-left: 1px solid #5bdf6f;
      border-right: 1px solid #5bdf6f;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: -1.5rem;
      left: 2.5%;
      height: 0.8rem;
      width: 100%;
      border-top: 1px solid #3a3a3c;
      border-left: 1px solid #5bdf6f;
      border-right: 1px solid #5bdf6f;
    }
  }

  .line-horizontal {
    position: absolute;
    content: '';
    height: 95%;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      left: -1.5rem;
      top: 2.5%;
      height: 100%;
      width: 0.8rem;
      border-right: 1px solid #3a3a3c;
      border-top: 1px solid #5bdf6f;
      border-bottom: 1px solid #5bdf6f;
    }
    &::after {
      content: '';
      position: absolute;
      right: -1.5rem;
      top: 2.5%;
      height: 100%;
      width: 0.8rem;
      border-left: 1px solid #3a3a3c;
      border-top: 1px solid #5bdf6f;
      border-bottom: 1px solid #5bdf6f;
    }
  }
`
export const FigureArea = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;

  figure {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      position: absolute;
      top: -60%;
      left: -10%;
      width: 120%;
    }
  }
`
