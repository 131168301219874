import { Icon } from '@viswals/components-platform'
import { useState } from 'react'
import { Country, mapControl } from '../data'
import {
  GroupWrapper,
  ItemWrapper,
  MapControlContainer,
  Separator,
} from './style'

export interface MapControlProps {
  map?: google.maps.Map
}

export const MapControl = ({ map }: MapControlProps) => {
  function changeCenter(country: Country) {
    map?.setCenter({ lat: country.lat, lng: country.lng })
    map?.setZoom(7)
  }
  const [actives, setActives] = useState<string[]>([])

  return (
    <MapControlContainer>
      <GroupWrapper>
        <ItemWrapper>
          <Icon name="Filter1" />
        </ItemWrapper>
        {mapControl.types.map((type, index) => (
          <ItemWrapper
            key={index}
            className={actives.includes(type) ? 'active' : undefined}
            onClick={() =>
              setActives((a) =>
                a.find((f) => f === type)
                  ? [...a.filter((f) => f !== type)]
                  : [...a, type]
              )
            }
          >
            <Icon name={type} />
          </ItemWrapper>
        ))}
      </GroupWrapper>

      <Separator />

      <GroupWrapper>
        {mapControl.countries?.map((country, index) => (
          <ItemWrapper
            key={index}
            onClick={() => {
              changeCenter(country)
              setActives((a) =>
                a.find((f) => f === country.name)
                  ? [...a.filter((f) => f !== country.name)]
                  : [...a, country.name]
              )
            }}
            className={actives.includes(country.name) ? 'active' : undefined}
          >
            <span>{country.name}</span>
          </ItemWrapper>
        ))}
      </GroupWrapper>

      <Separator />

      <GroupWrapper>
        {mapControl.status?.map((state, index) => (
          <ItemWrapper
            key={index}
            className={actives.includes(state) ? 'active' : undefined}
            onClick={() =>
              setActives((a) =>
                a.find((f) => f === state)
                  ? [...a.filter((f) => f !== state)]
                  : [...a, state]
              )
            }
          >
            <Icon name={state} />
          </ItemWrapper>
        ))}
      </GroupWrapper>
    </MapControlContainer>
  )
}
