/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress } from '@mui/material'
import { AlertModal, BoxListOptions } from '@viswals/components-platform'

import { LoadingTable } from '@src/pages/administration/common/_components/loading-table'
import PopUpReportUnusual from '../components/PopUpReportUnusual/index'
import useLogic from './useLogic'
import ItemReportUnusual from './item'

import {
  ContainerPopUpDeleteItem,
  ContainerTableBodyReport,
  ContainerTableReport,
  ContentLoadingList,
} from './styles'

export default function ReportAnythingUnusual() {
  const {
    reports,
    getLabelPlatform,

    itemReport,
    handleDeleteItem,
    handleClickIconItem,
    handleConfirmDeleteItem,
    handleConfirmCancelItem,
    reportTotal,
    loadingBoxRef,
    isRequesting,
  } = useLogic()

  return (
    <ContainerTableReport>
      <div className="header">
        <div className="td tdOne">Viswals Platform</div>
        <div className="td tdTwo">User</div>
        <div className="td tdThree">Client Email</div>
        <div className="td tdFour">Date</div>
        <div className="td tdFive">Hour</div>
        <div className="td tdSix">Message</div>
        <div className="td tdSeven">&nbsp;</div>
      </div>
      <ContainerTableBodyReport
        id="tbody-report"
        className="tbody tbody-report"
      >
        <LoadingTable />
        <PopUpReportUnusual
          title="Report Anything Unusual"
          itemReport={itemReport}
        />
        <BoxListOptions
          color="#F72A1E"
          id="vw-option-report-id"
          overlayClassName="vw-option-report"
          items={[
            {
              label: 'Delete Notification',
              onClick: handleDeleteItem,
            },
          ]}
        />
        <ContainerPopUpDeleteItem className="vw-alert-modal-first">
          <AlertModal
            title="You are about to delete a Viswals App Communication"
            message="Are you sure you want to delete?"
            handleCancel={handleConfirmCancelItem}
            handleConfirm={handleConfirmDeleteItem}
            labelCancel="Cancel"
            labelConfirm="Delete"
          />
        </ContainerPopUpDeleteItem>
        {reports.reports?.map((itemReported, _index) => {
          return (
            <div
              key={_index}
              onClick={(event) =>
                handleClickIconItem(event.target as any, itemReported)
              }
              style={{
                marginBottom:
                  _index === (reports.reports?.length ?? 1) - 1 &&
                  reportTotal !== reports.reports?.length
                    ? '3rem'
                    : '',
              }}
            >
              <ItemReportUnusual
                key={_index}
                itemReport={itemReported}
                platformLabel={getLabelPlatform(itemReported.platform)}
              />
            </div>
          )
        })}
        {reportTotal !== reports.reports?.length ? (
          <ContentLoadingList ref={loadingBoxRef}>
            {isRequesting && <CircularProgress size={30} />}
          </ContentLoadingList>
        ) : null}
      </ContainerTableBodyReport>
    </ContainerTableReport>
  )
}
