/* eslint-disable no-await-in-loop */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch } from 'react'
import { setNavigationActiveNext } from '@src/store/actions/navigation'
import { RegistrationProp } from '@src/store/reducers/clinician/props'
import {
  Request,
  MajorCourse,
  MajorCourseFilter,
  retrieveMajorCourse,
  Institution,
} from '@viswals/services'
import { FieldValues, UseFieldArrayAppend } from 'react-hook-form'
import handleRetrieveInstituions from '../../degree/utils/handleRetrieveInstitutions'

type handleRetrieveMajorCoursesProps = {
  append: UseFieldArrayAppend<FieldValues, 'data'>
  dispatch: Dispatch<any>
  registrationActive: RegistrationProp
  institutionsLocal: Institution[]
  setInstitutionsLocal: React.Dispatch<React.SetStateAction<Institution[]>>
}

async function handleRetrieveMajorCourses({
  append,
  dispatch,
  registrationActive,
  institutionsLocal,
  setInstitutionsLocal,
}: handleRetrieveMajorCoursesProps) {
  try {
    const request: Request<MajorCourseFilter> = {
      fields: [
        'uuid',
        'clinicianRegisterUuid',
        'continent',
        'country',
        'courseProvider',
        'institution',
        'fieldDentistry',
        'majorCourse',
        'courseDuration',
        'certifiedCourseHours',
        'studyMode',
        'dateGrantedYear',
        'dateGrantedMonth',
        'awardCourseTitle',
        'courseCertificate',
        'courseCertificateName',
        'courseProgression',
      ],
      filters: {
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      },
    }
    const resp = await retrieveMajorCourse(request)
    const data: MajorCourse[] | null = resp.data.data ?? []

    setTimeout(async () => {
      // @ts-ignore
      const majorCoursesSize: MajorCourse[] = data

      const emptyCourses: MajorCourse[] = []

      for (const majorCourse of majorCoursesSize) {
        const findInstitution = institutionsLocal.find((institution) => {
          return institution.uuid === majorCourse.institution
        })
        if (!findInstitution) {
          await handleRetrieveInstituions({
            country: majorCourse.country as string,
            setInstitutionsLocal,
          })
        }
        emptyCourses.push({
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          ...majorCourse,
          // @ts-ignore
          courseDuration: `${majorCourse.courseDuration}`,
          // @ts-ignore
          continent: `${majorCourse.continent}`,
          // @ts-ignore
          certifiedCourseHours:
            majorCourse.certifiedCourseHours === 0
              ? undefined
              : majorCourse.certifiedCourseHours,
          certifiedCourseHoursUnknow: majorCourse.certifiedCourseHours,
          // @ts-ignore
          courseProgression:
            majorCourse.courseProgression && majorCourse.courseProgression > 0
              ? `${majorCourse.courseProgression}`
              : undefined,
          certificate: {
            file: majorCourse.courseCertificate,
            fileName: majorCourse.courseCertificateName,
          },
          awardCourseTitleSelect:
            majorCourse.courseProgression && majorCourse.courseProgression > 0
              ? majorCourse.awardCourseTitle
              : undefined,
        })
      }

      append(emptyCourses as any)
      dispatch(setNavigationActiveNext(true))
    }, 200)
  } catch (error) {
    return null
  }
}

export default handleRetrieveMajorCourses
