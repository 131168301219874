import useDiary from '@src/hooks/useDiary'
import useLayout from '@src/hooks/useLayout'
import { retrievePatientCounter } from '@viswals/services'
import moment from 'moment'
import { useEffect, useState } from 'react'

const useLogic = () => {
  const { diary } = useDiary()
  const { layout, setLayout } = useLayout()
  const [totalUser, setTotalUser] = useState(null)

  useEffect(() => {
    getCounters()
  }, [diary.upperCalendarStartSelect, diary.landingOn, layout.updateMap])

  async function getCounters() {
    const request = {
      filters: {
        startDate:
          diary.landingOn === 'AS_LEFT'
            ? undefined
            : diary.upperCalendarStartSelect !== ''
            ? diary.upperCalendarStartSelect
            : moment().format('YYYY-MM-DD'),
        finishDate:
          diary.landingOn === 'AS_LEFT'
            ? undefined
            : diary.upperCalendarEndSelect !== ''
            ? diary.upperCalendarEndSelect
            : moment().format('YYYY-MM-DD'),
      },
    }
    const {
      data: { data },
    } = await retrievePatientCounter(request)
    setTotalUser(data[0])
  }

  const platforms = [
    { label: 'Totals', type: 'patient' },
    { label: 'Users', type: 'users' },
    { label: 'Clinicians', type: 'clinician' },
    { label: 'Clinics', type: 'clinic' },
  ]

  const types = [
    {
      forceShow: true,
      key: 'signUps',
      keyPercent: 'signUpsPercent',
      description: 'Sign ups',
      fontColor: '#EBEBEB',
      backgroundColor: '#EBEBEB10',
    },
    {
      key: 'newEntries',
      keyPercent: 'newEntriesPercent',
      description: 'New entries',
      fontColor: '#A123D0',
      backgroundColor: '#A123D005',
    },
    {
      key: 'reEntries',
      keyPercent: 'reEntriesPercent',
      description: 'Re-entries',
      fontColor: '#D619DE',
      backgroundColor: '#D619DE10',
    },
    {
      key: 'inProgress',
      keyPercent: 'inProgressPercent',
      description: 'In Progress',
      fontColor: '#4166FF',
      backgroundColor: '#0A39FE10',
    },
    {
      key: 'questionsRaised',
      keyPercent: 'questionsRaisedPercent',
      description: 'Questions raised',
      fontColor: '#FE9F0A',
      backgroundColor: '#FE9F0A05',
    },
    {
      key: 'disqualified',
      keyPercent: 'disqualifiedPercent',
      description: 'Disqualified',
      fontColor: '#FD453A',
      backgroundColor: '#FD453A05',
    },

    {
      key: 'verified',
      keyPercent: 'verifiedPercent',
      description: 'verified',
      fontColor: '#64D2FF',
      backgroundColor: '#64D2FF05',
    },
    {
      forceShow: true,
      key: 'acquired',
      keyPercent: 'acquiredPercent',
      description: 'acquired',
      fontColor: '#00FF89',
      backgroundColor: '#151517',
    },
    {
      forceShow: true,
      key: 'dropouts',
      keyPercent: 'dropoutsPercent',
      description: 'Dropouts',
      fontColor: '#636366',
      backgroundColor: '#15151750',
    },
    {
      forceShow: true,
      key: 'total',
      keyPercent: 'totalPercent',
      description: 'total',
      fontColor: '#AEAEB2',
      backgroundColor: '#2C2C2E25',
      bold: true,
    },
  ]

  function getAmount(platform: string, key: string): number {
    if (platform === 'users') {
      return totalUser ? totalUser[key] ?? 0 : 0
    }
    return 0
  }

  function getTotals(totals: any, object: any) {
    const sumTotal = totals.map((t: any) =>
      t.items
        .map((item: any) => ({
          [item.key]: item.amount as number,
          [item.keyPercent]: item.percentage as number,
        }))
        .reduce((a: any, b: any) => ({ ...a, ...b }))
    )
    return {
      title: object.title,
      items: object.items.map((item: any) => {
        return {
          ...item,
          description:
            item.description === 'total' ? 'all time' : item.description,
          amount: sumTotal
            .map((m: any) => m[item.key])
            .reduce((a: any, b: any) => a + b),
          percentage: sumTotal
            .map((m: any) => m[item.keyPercent])
            .reduce((a: any, b: any) => a + b),
        }
      }),
    }
  }

  const totalsPlatforms = platforms.map((platform) => ({
    title: platform.label,
    items: types.map((type) => ({
      key: type.key,
      keyPercent: type.keyPercent,
      forceShow: type.forceShow,
      description: type.description,
      amount: getAmount(platform.type, type.key),
      percentage: getAmount(platform.type, type.keyPercent),
      backgroundColor: type.backgroundColor,
      fontColor: type.fontColor,
      bold: !!type?.bold,
    })),
  }))

  const totalPlat = totalsPlatforms.filter(
    (f) => f.title.toLowerCase() !== 'totals'
  )

  const totals = [
    getTotals(
      totalPlat,
      totalsPlatforms.find((f) => f.title.toLowerCase() === 'totals')
    ),
    ...totalPlat,
  ]

  return { totals, layout, setLayout }
}

export default useLogic
