/* eslint-disable @typescript-eslint/no-explicit-any */
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useStepsError from '@src/hooks/useStepsError'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import {
  createPeriodCoverageCertificate,
  PeriodCoverageCertificate,
  PeriodCoverageCertificateFilter,
  Request,
  retrievePeriodCoverageCertificate,
  updatePeriodCoverageCertificate,
} from '@viswals/services'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

type FileProps = {
  file: string
  fileName: string
}

type FormSubmitProps = {
  registrationCertificate: FileProps
} & PeriodCoverageCertificate

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    register,
    reset,
    watch,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm<any>()
  const { getErrors, getFieldProps } = useStepsError(errors)
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { confirm, next } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const { loading, setLoading } = useLoading()
  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const [isReady, setIsReady] = useState<boolean>(false)

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && !loading && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, info) => {
      if (info.type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  async function getData() {
    setLoading(true)
    try {
      const request: Request<PeriodCoverageCertificateFilter> = {
        fields: [
          'uuid',
          'clinicianRegisterUuid',
          'countryRegistration',
          'issueDateDay',
          'issueDateMonth',
          'issueDateYear',
          'expiryDateDay',
          'expiryDateMonth',
          'expiryDateYear',
          'registrationCertificate',
          'registrationCertificateName',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        },
      }
      const resp = await retrievePeriodCoverageCertificate(request)
      const data = resp?.data ? resp.data.data[0] : null
      let updateInfo = {
        registrationCertificate: {
          file: undefined,
          fileName: undefined,
        },
      }
      if (data) {
        updateInfo = {
          ...data,
          registrationCertificate: {
            file: data?.registrationCertificate,
            fileName: data?.registrationCertificateName,
          },
        }
      }

      const resetData = {
        ...updateInfo,
        uuid: data?.uuid,
        countryRegistration: registrationActive?.countryRegistration,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      }

      setTimeout(() => {
        reset(resetData)
        dispatch(setNavigationActiveNext(true))
      }, 200)
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  async function onSubmit(data: FormSubmitProps) {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const newData = {
        ...data,
        countryRegistration: registrationActive?.countryRegistration,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        registrationCertificate: data?.registrationCertificate?.file,
        registrationCertificateName: data?.registrationCertificate?.fileName,
      }
      if (!data.uuid) {
        await createPeriodCoverageCertificate(
          newData as Request<PeriodCoverageCertificate>
        )
      } else {
        await updatePeriodCoverageCertificate(
          newData as Request<PeriodCoverageCertificate>
        )
      }
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setLoading(false)
    }
  }

  return {
    confirm,
    dispatch,
    register,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    onSubmit,
    getFieldProps,
    verified,
    errors,
  }
}

export default useLogic
