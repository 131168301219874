import LoginBG from "./login-bg.png"

import ViswalLogo from "./viswal-logo"
import ViswalText from "./viswal-text.svg"
import CrossFillRound from "./cross-fill-round.svg"
import ErrorExplementary from "./error-explementary.svg"
import EyeOpen from "./eye-open.svg"
import ArrowUpFill from "./arrow-up-fill"
import MobileIcon from "./mobile-icon"
import MenIcon from "./men-icon"
import BuildingIcon from "./building-icon"
import MenWithSuitIcon from "./men-with-suit"
import NetworkTowerIcon from "./network-tower-icon"
import EarthIcon from "./earth-icon"
import ToothIcon from "./tooth-icon"
import HeartIcon from "./heart-icon"
import BrainIcon from "./brain-icon"
import AgentSearch from "./agent-search"
import AgentSelected from "./agent-selected"
import Search from "./search"

export default {
    LoginBG,
    ViswalLogo,
    ViswalText,
    CrossFillRound,
    EyeOpen,
    ErrorExplementary,
    ArrowUpFill,
    MobileIcon,
    MenIcon,
    BuildingIcon,
    MenWithSuitIcon,
    NetworkTowerIcon,
    EarthIcon,
    ToothIcon,
    HeartIcon,
    BrainIcon,
    AgentSearch,
    AgentSelected,
    Search
}