import styled, { css } from 'styled-components'

type ContainerBorderWithMapProps = {
  completed: boolean
}

export const ContainerBorderWithMap = styled.div<ContainerBorderWithMapProps>`
  display: flex;
  border-radius: 0.6rem;

  ${(props) => {
    if (props.completed === true) {
      return css`
        border: 0.1rem solid #bebebe;
        &:hover {
          transition: all 0.3s ease-in-out;
          border: 0.1rem solid #a2a3a5;
        }
      `
    }
  }}
`

export const InnerMap = styled.div`
  position: relative;
  cursor: pointer;
  .vw-contentIconEduc,
  .vw-contentLabelUnder {
    color: #bebebe;
    position: absolute;
  }
  .vw-contentIconEduc {
    top: 0.1rem;
    left: calc(50% - 0.5rem);
    z-index: 2;
    svg {
      width: 1rem;
      color: #bebebe;
    }
  }
  .vw-contentLabelUnder {
    width: 6rem;
    bottom: 0.5rem;
    left: calc(50% - 3rem);
    z-index: 2;
    font-size: 0.6rem;
    text-align: center;
    font-family: var(--font-secondary);
  }
  .vw-button-buttonstyle {
    width: 12.95rem;
    height: 4rem;
    border-radius: 0.5rem;
    background-color: transparent;
    padding-top: 1.1rem;
    span {
      font-weight: 400;
      font-family: var(--font-secondary);
      font-size: 1.2rem;
      color: #bebebe;
    }
  }
  &.selected {
    .vw-button-buttonstyle {
      background-color: #bebebe;
      span {
        color: #292e38;
      }
    }
    .vw-contentIconEduc,
    .vw-contentLabelUnder {
      color: #292e38;
    }
    .vw-contentIconEduc {
      svg {
        color: #292e38;
      }
    }
  }
  &.destroy {
    .vw-button-buttonstyle {
      background-color: #ff3333;
      cursor: not-allowed;
      span {
        color: #fff;
        font-weight: 700;
      }
    }
  }
`

export const ContainerFloatIcon = styled.div`
  width: 5.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    margin-top: 0.6rem;
    width: 1.2rem;
    transform: rotate(180deg);
    color: #bebebe;
    transition: color 0.6s ease-in-out;
  }
  .open {
    svg {
      color: #19aeff;
    }
  }
`

export const ContainerAllCourses = styled.div`
  width: 147.3rem;
  display: flex;
  flex-wrap: wrap;
  background-color: #101721;
  backdrop-filter: blur(20px) brightness(115%) opacity(70%);
  padding: 3rem 4.1rem 3rem 4.2rem;
  margin-left: 12rem;
  border-radius: 0.5rem;
  gap: 1rem;
  .vw-button-buttonstyle {
    width: 13rem;
    height: 4.2rem;
    border-radius: 0.5rem;
    background-color: transparent;
    border: 0.1rem solid #bebebe;
    color: #bebebe;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 0;
    &:hover {
      background-color: #bebebe;
      color: #292e38;
    }
  }
  .selected {
    .vw-button-buttonstyle {
      background-color: #bebebe;
      span {
        color: #292e38;
      }
    }
  }
  .destroy {
    cursor: not-allowed;
    .vw-button-buttonstyle {
      border: 0.1rem solid #ff3333;
      background-color: #ff3333;
      cursor: not-allowed;
      span {
        color: #fff;
        font-weight: 700;
      }
    }
  }
`
