import styled, { css } from 'styled-components'

type BoxItemArrayItemsProps = {
  selected?: boolean
  destroy?: boolean
}

export const ContainerMiniPagination = styled.div`
  margin-right: 4rem;
  display: flex;
  gap: 1rem;
`
export const BoxItemArrayItems = styled.div<BoxItemArrayItemsProps>`
  width: 4.2rem;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid transparent;
  border-radius: 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: var(--font-primary);
  color: #565a62;
  background-color: #2d333e;
  ${(props) => {
    if (props.destroy) {
      return css`
        border: 0.1rem solid var(--danger-color);
        color: var(--danger-color);
        background-color: transparent;
      `
    }
    if (props.selected) {
      return css`
        border: 0.1rem solid var(--primary-color);
        color: var(--primary-color);
        background-color: transparent;
      `
    }
  }}

  cursor: pointer;
`
