import styled from 'styled-components'

export const FixedPopUpReportUnusual = styled.div`
  width: 100%;
  height: 100%;

  position: fixed;
  top: 0;
  left: 0;

  background-color: #1c1c1e90;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: -999;
  opacity: 0;
`

export const BoxPopUpReportUnusual = styled.div`
  width: 60.2rem;
  max-height: 61.9rem;

  padding: 4rem 2rem;

  background-color: #1c1c1e;

  border-radius: 1.5rem;

  border: 0.1rem solid #7c7c8025;

  -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.8);
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.8);

  .contentTitleHeader {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
    .titleHeader {
      font-size: 1.2rem;
      font-family: 'Poppins';
      font-weight: bold;
      line-height: 1.8rem;
      color: #5bdf6f;
    }
    .buttonClose {
      width: 12.6rem;
      height: 2.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #8e8e93;
      color: #242426;
      font-family: 'Nebula';
      font-size: 1rem;
      line-height: 1.1rem;
      border-radius: 0.5rem;
      cursor: pointer;
    }
  }
  .contentLinePopUp {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .linePopUp {
      width: 100%;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.2rem;

      background-color: #222224;

      border-radius: 0.5rem;

      padding: 0 1.2rem;

      -webkit-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
      -moz-box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.25);

      cursor: pointer;

      .titleLinePopUp {
        font-size: 1rem;
        font-family: 'Poppins';
        line-height: 1.6rem;
        color: #aeaeb2;
        font-weight: bold;
      }
      .valueLinePopUp {
        font-size: 1rem;
        font-family: 'Oxanium';
        line-height: 1.5rem;
        color: #aeaeb2;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.6rem;
        .tbOneIconBox {
          width: 2.4rem;
          height: 2.4rem;

          padding: 0.6rem 0.6rem 0.6rem;
          border: 0.1rem solid #08a8fd50;
          border-radius: 0.4rem;
          svg {
            color: #08a8fd;
          }
        }
        .labelTbOne {
          color: #08a8fd;
          margin-top: 0.2rem;
        }
      }

      &.platform {
        background: rgb(4, 84, 127);
        background: linear-gradient(
          0deg,
          rgba(4, 84, 127, 0.02) 0%,
          rgba(8, 168, 253, 0.1) 99%
        );
        .valueLinePopUp {
          color: #08a8fd;
        }
      }

      &.app {
        &.platform {
          background: rgb(4, 84, 127);
          background: linear-gradient(
            0deg,
            rgba(4, 84, 127, 0.02) 0%,
            rgba(8, 168, 253, 0.1) 99%
          );
          .valueLinePopUp {
            color: #08a8fd;
          }
        }
        .labelTbOne {
          color: #08a8fd;
        }
        .tbOneIconBox {
          border: 0.1rem solid #08a8fd50;
          svg {
            color: #08a8fd;
          }
        }
      }
      &.clinic {
        .labelTbOne {
          color: #1ac993;
        }
        .tbOneIconBox {
          border: 0.1rem solid #1ac99350;
          svg {
            color: #1ac993;
          }
        }

        &.platform {
          background: #1ac993;
          background: linear-gradient(
            0deg,
            rgba(4, 84, 127, 0.02) 0%,
            rgba(26, 201, 147, 0.1) 99%
          );
          .valueLinePopUp {
            color: #1ac993;
          }
        }
      }
      &.clinician {
        .labelTbOne {
          color: #b737e6;
        }
        .tbOneIconBox {
          border: 0.1rem solid #b737e650;
          svg {
            color: #b737e6;
          }
        }

        &.platform {
          background: #b737e6;
          background: linear-gradient(
            0deg,
            rgba(4, 84, 127, 0.02) 0%,
            rgba(183, 55, 230, 0.1) 99%
          );
          .valueLinePopUp {
            color: #b737e6;
          }
        }
      }
    }
  }

  .contentMessageBox {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 4rem;

    .titleMessageBox {
      font-size: 1rem;
      font-family: 'Poppins';
      line-height: 1.6rem;
      color: #aeaeb2;
      font-weight: bold;
      margin-bottom: 1.2rem;
    }

    .textMessageBox {
      width: 100%;
      background-color: #222224;
      border-radius: 1.5rem;

      padding: 2rem 3rem 2rem 2rem;
      font-size: 1rem;
      font-family: 'Oxanium';
      line-height: 1.5rem;
      color: #7c7c80;
    }
  }
`
