import StepRegistration from './registration'
import StepTypeOfRegistration from './type-of-registration'
import SpecialtyCertificate from './specialty-certificate'
import StepPeriodOfRegistration from './period-of-registration'

const listStep1 = [
  {
    name: 'Registration',
    subStep: 'REGISTRATION',
    route: 'registration',
    component: <StepRegistration />,
    cards: [
      { fields: ['countryRegistration'] },
      { fields: ['nationalBodyRegistration'] },
      { fields: ['professionalLicenseNumber'] },
      { fields: ['nameRegistrationForename', 'nameRegistrationSurname'] },
    ],
  },

  {
    name: 'Type Of Registration',
    subStep: 'TYPE_OF_REGISTRATION',
    route: 'type-of-registration',
    component: <StepTypeOfRegistration />,
    cards: [{ fields: ['registration'] }],
  },
  {
    name: 'Specialty Certificate',
    subStep: 'SPECIALTY_CERTIFICATE',
    route: 'specialty-certificate',
    component: <SpecialtyCertificate />,
  },
  {
    name: `Period of Registration\n& Certificate `,
    subStep: 'PERIOD_OF_REGISTRATION_CERTIFICATE',
    route: 'period-of-registration-certificate',
    component: <StepPeriodOfRegistration />,
    cards: [
      {
        fields: [
          'expireDateDay',
          ' expireDateMonth',
          'expireDateYear',
          'ExpireDateNotApplicable',
          'registrationCertificate',
        ],
      },
    ],
  },
]
export default listStep1
