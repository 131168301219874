import { StepProps } from '@viswals/components'
import { ClinicianRegister, ClinicianRegisterStatus } from '@viswals/services'
import { RegistrationProp, ActionProps } from './props'

export function setClinician(clinician: ClinicianRegister): ActionProps {
  return {
    type: 'SET_CLINICIAN',
    clinician,
  }
}

export function setClinicianSteps(steps: StepProps[]): ActionProps {
  return {
    type: 'SET_CLINICIAN_STEPS',
    steps,
  }
}

export function setClinicianStatus(
  status: ClinicianRegisterStatus[]
): ActionProps {
  return {
    type: 'SET_CLINICIAN_STATUS',
    status,
  }
}

export function setRegistration(registration: RegistrationProp[]): ActionProps {
  return {
    type: 'SET_REGISTRATION',
    registration,
  }
}

export function setRegistrationActive(
  registrationActive: RegistrationProp
): ActionProps {
  return {
    type: 'SET_REGISTRATION_ACTIVE',
    registrationActive,
  }
}
