import { Feedback } from '@viswals/services'

import { ActionProps } from './props'

export default function setActionFeedbacks(feedbacks: Feedback[]): ActionProps {
  return {
    type: 'SET_FEEDBACK',
    feedbacks,
  }
}
