import React from 'react'
import { Button, Icon, Input, QuestionModal } from '@viswals/components'
import isURL from '@src/core/isUrl'
import { Container, SocialMedia } from './SocialMediaStyles'
import useLogic from './useLogic'

const StepSocialMedia = () => {
  const {
    socialRef,
    btbRef,
    mobileActive,
    mobileRedes,
    handleSocialMedia,
    showInput,
    safeArea,
    register,
    watch,
    getPress,
    isModalOpen,
    setIsModalOPen,
    handleCancelSocialMediaModal,
    handleRemoveSocialMediaModal,
  } = useLogic()

  const ModalDescription = (
    <div>
      <span>
        You are about to delete a Social Media link. This action cannot be
        undone.
        <br />
      </span>

      <span>Are you sure you want to proceed?</span>
    </div>
  )

  return (
    <>
      <Container>
        <div className="vw-socialMedia-header">
          <div className="socialMedia">
            <h2>Social Media</h2>
            <span>Activate your social networks here</span>
          </div>
          <div ref={btbRef}>
            {mobileActive?.uuid && isModalOpen && (
              <QuestionModal
                title="Delete Social Media"
                description={ModalDescription}
                cancelLabel="Oops, take me back"
                cancelFunction={handleCancelSocialMediaModal}
                confirmLabel={`Yes, delete ${mobileActive.name}`}
                confirmFunction={() =>
                  handleRemoveSocialMediaModal(mobileActive.socialMediaType)
                }
              />
            )}

            {mobileActive?.uuid && mobileActive?.link && (
              <div className="socialMedia">
                <Button
                  icon="Remove1"
                  label="Delete Social Media"
                  handleClick={() => setIsModalOPen(mobileActive)}
                />
              </div>
            )}
          </div>
        </div>

        <div className="socialArea" ref={socialRef}>
          {mobileRedes.map((item, index) => {
            const link = watch(`data.${index}.link`)
            const checkValidate = isURL(link ?? '')

            return (
              <SocialMedia
                key={item.uuid}
                // onClick={() => handleMobileFocused({ ...item, link })}
                background={item.background}
                valid={link ? checkValidate : undefined}
                error={link && showInput !== index ? !checkValidate : undefined}
                delete={item.delete}
                focused={mobileActive?.uuid === item.uuid && true}
              >
                <input
                  type="hidden"
                  {...register(`data.${index}.socialMediaType`)}
                  defaultValue={item.socialMediaType}
                />

                <input
                  type="hidden"
                  {...register(`data.${index}.uuid`)}
                  defaultValue={item.uuid}
                />

                <div className="mobileContainer">
                  <div className="topArea">
                    <p>{item.name}</p>
                    <Icon name={item.icon} />
                  </div>
                  <Icon name="ArrowDown1" />

                  <button
                    type="button"
                    onClick={() =>
                      mobileActive?.uuid === item.uuid
                        ? handleSocialMedia(index)
                        : undefined
                    }
                  >
                    {showInput === index && mobileActive?.uuid === item.uuid && (
                      <div
                        ref={(ref) => {
                          safeArea.current[index] = ref
                        }}
                        className={`vw-socialMedia-inputArea ${item.socialMediaType}`}
                      >
                        <p>{item.name} Link url</p>
                        <Input
                          placeholder={`Insert your ${item.name} URL Here `}
                          formRegister={register(`data.${index}.link`, {
                            validate: (value) =>
                              value ? isURL(value) : undefined,
                          })}
                          onKeyUp={getPress}
                        />
                      </div>
                    )}

                    <p>{link || `Insert your ${item.name} URL Here`}</p>
                  </button>

                  <div className="validationArea">
                    {item.error && (
                      <>
                        <Icon name="Alert2" />
                        <p>Invalid Link</p>
                      </>
                    )}
                    {item.valid && (
                      <>
                        <div className="likedIcon">
                          <Icon name="Check1" />
                        </div>
                        <p className="likedP">Linked</p>
                      </>
                    )}
                  </div>
                </div>
              </SocialMedia>
            )
          })}
        </div>
      </Container>
    </>
  )
}
export default StepSocialMedia
