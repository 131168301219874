import { LoadingContainer } from './style'

const Loading = () => {
  return (
    <LoadingContainer className="vw-loadingContainer">
      <div className="ring">
        <span />
      </div>
    </LoadingContainer>
  )
}

export default Loading
