import styled from 'styled-components'

export const PhotoVerificationContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2rem;
`

export const PhotoVerificationBox = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

type PhotoVerificatioColor = {
  color: string
}

export const PhotoVerificationHeaderBox = styled.div<PhotoVerificatioColor>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: 'Nebula';
    font-size: 1.2rem;
    font-weight: bold;
    color: #32d74b;
  }

  figure {
    width: 3.4rem;
    height: 3.4rem;
    overflow: hidden;
    border-radius: 100%;
    background: ${({ color }) => color}15;
    padding: 0.5rem;

    img {
      width: 100%;
      height: 100%;
    }
    div {
      content: ' ';
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: #2c2c2e;
    }
  }
`

export const PhotoVerificationArea = styled.div<PhotoVerificatioColor>`
  position: relative;
  height: 27rem;
  width: 27rem;

  .iconType {
    position: absolute;
    width: 100%;
    top: -2rem;
    height: 5.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    img {
      height: 100%;
    }
  }

  figure {
    height: 100%;
    width: 100%;
    border-radius: 100%;
    border: 0.5rem solid ${({ color }) => color};
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .line {
    width: 100%;
    height: 100%;
    padding: 0.9rem;
    border-radius: 100%;
    &.b1 {
      border: 1px solid ${({ color }) => color}10;
    }
    &.b2 {
      border: 1px solid ${({ color }) => color}15;
    }
    &.b3 {
      border: 1px solid ${({ color }) => color}20;
    }
    &.b4 {
      border: 1px solid ${({ color }) => color}25;
    }
  }
`
