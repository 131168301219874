/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const VerificationBox = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 40rem 1fr 1fr 40rem;
  grid-template-rows: 7.2rem 1fr 7rem;
  gap: 1rem;

  .item {
    display: flex;
    &:not(.photos) {
      background-color: #2c2c2e;
      border-radius: 0.5rem;
      border: 1px solid rgba(124, 124, 128, 0.25);
    }

    &.steps {
      background-color: #222224;
      border: 1px solid #2c2c2e;
    }

    &.col1 {
      padding: 0.1rem;
      grid-column: 1 / span 3;
      grid-row: 1;
    }

    &.col2 {
      grid-column: 4;
      grid-row: 1;
    }

    &.col3 {
      grid-column: 1;
      grid-row: 2 / span 2;
    }

    &.col4 {
      grid-column: 2 / span 2;
      grid-row: 2 / span 2;
    }

    &.col5 {
      grid-column: 4;
      grid-row: 2;
    }

    &.col6 {
      grid-column: 4;
      grid-row: 3;
      gap: 1rem;
      padding: 1.8rem 2rem;
    }

    &.question {
      flex-direction: column;
      grid-column: 4;
      grid-row: 2 / span 2;
      z-index: 2;

      .buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 0 2rem 2rem 2rem;
        button {
          height: 3.2rem;
          &.save {
            background-color: #f0a33c;
          }
        }
      }
    }

    &.photos {
      gap: 1rem;
      grid-column: 1 / span 3;
      grid-row: 2 / span 2;

      .photo {
        width: 100%;
        display: flex;
        background-color: #2c2c2e;
        border-radius: 0.5rem;
        border: 1px solid rgba(124, 124, 128, 0.25);
      }
    }
  }
`
export const VerificationHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.2rem 0px;
`

export const LinkAccount = styled.div`
  padding: 1rem 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  b {
    font-family: 'Nebula';
    font-size: 1rem;
    font-weight: bold;
    color: #5bdf6f;
  }
  svg {
    height: 1.2rem;
    color: #5bdf6f;
  }

  &:hover {
    opacity: 0.75;
  }
`

export const VerificationTitle = styled.h2`
  font-family: 'Nebula';
  font-size: 1.2rem;
  font-weight: bold;
  color: #32d74b;
`
export const Button = styled.button`
  width: 100%;
  font-family: 'Nebula';
  font-size: 1rem;
  font-weight: bold;
  color: #242426;
  border-radius: 0.5rem;
  border: none;

  &.verify_user {
    background-color: #5bdf6f;
    color: #2c2c2e;
  }

  &.raise_questions {
    background-color: #fd9f0a;
    color: #ffffff;
  }
  &.request_fixes {
    background-color: #f72a1e;
    color: #ffffff;
  }

  &.prev {
    background-color: #3a3a3c;
    color: #7c7c80;
  }

  &:disabled {
    opacity: 0.25;
  }
`
