import {Route, Routes} from 'react-router-dom'
import Layout from '../layout'
import FormCreateOralHealthProblem from './form_create'
import FormUpdateOralHealthProblem from './form_update'
import OralHealthProblemTable from './table'

export default function OralHealthProblemPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <OralHealthProblemTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateOralHealthProblem />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateOralHealthProblem />
          </Layout>
        }
      />
    </Routes>
  )
}
