/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'

import { Icon } from '@viswals/components-platform'
import { Rate } from '@viswals/services'

import svgTool from '../components/svg_tool.svg'
import IconRate from '../components/IconRate'

export type ItemReportUnusualProps = {
  itemReport: Rate
  platformLabel: string
}

export default function ItemReportUnusual({
  itemReport,
  platformLabel,
}: ItemReportUnusualProps) {
  const itemId = `itemList-${itemReport.uuid}`
  return (
    <div
      className={`itemList itemList-report ${
        !itemReport.read ? 'isEnabled' : ''
      }`}
      id={itemId}
    >
      <div
        className={`tbOne ${
          itemReport.platform === 'PATIENT'
            ? 'app'
            : itemReport.platform === 'CLINICIAN'
            ? 'clinician'
            : itemReport.platform === 'CLINIC'
            ? 'clinic'
            : ''
        }`}
      >
        <div className="tbOneIconBox">
          <Icon name="Viswals3" />
        </div>
        <div className="labelTbOne">{platformLabel}</div>
      </div>
      <div className="tbTwo">{itemReport.accountUsername}</div>
      <div className="tbThree">{itemReport.accountUsername}</div>
      <div className="tbFour">
        {moment(itemReport.createdAt).format('DD MMMM YYYY')}
      </div>
      <div className="tbFive">
        {moment(itemReport.createdAt).format('HH:mm a')}
      </div>

      <div className="tbEight">
        <IconRate itemReport={itemReport} />
      </div>

      <div className="iconRight" id={`item-icon-${itemReport.uuid}`}>
        <img src={svgTool} alt="More" />
      </div>
    </div>
  )
}
