import styled from 'styled-components'

type FishedBoxProps = {
  color: string
}

export const FishedBox = styled.div<FishedBoxProps>`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .title-color {
    font-family: 'Oxanium';
    font-size: 1rem;
    font-weight: bold;
    color: ${({ color }) => color};
  }

  .circle {
    content: ' ';
    width: 1rem;
    height: 1rem;
    background: ${({ color }) => color};
    border-radius: 100%;
    border: 0.4rem solid #151517;
    margin: 0 auto;

    &.mb-36 {
      margin-bottom: 3.6rem;
    }
    &.mb-17 {
      margin-bottom: 1.7rem;
    }
  }
`

type InfoBoxProps = {
  top?: number
  left?: number
  color: string
}

export const InfoBox = styled.div<InfoBoxProps>`
  position: absolute;
  top: ${({ top }) => `${top}rem`};
  left: ${({ left }) => (left ? `${left}rem` : undefined)};
  width: 10rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .stage {
    width: 4.6rem;
    height: 1.7rem;
    border-radius: 0.3rem;
    background-color: ${({ color }) => color};
    padding: 0.15rem 0.6rem;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    margin-top: 0.8rem;

    svg {
      width: 0.75rem;
      margin-bottom: 0.3rem;
      color: #1c1c1e;
    }
    span {
      font-family: 'Oxanium';
      font-size: 0.9rem;
      font-weight: normal;
      color: #1c1c1e;
    }
  }

  span {
    font-family: 'Oxanium';
    font-size: 1rem;
    color: #aeaeb2;
    font-weight: normal;
    margin-top: 0.8rem;
  }
`
type FishedPhotoBoxProps = {
  color: string
}

export const FishedPhotoBox = styled.div<FishedPhotoBoxProps>`
  width: 38rem;
  height: 38rem;
  background-color: ${({ color }) => color};
  border-radius: 100%;
  padding: 2rem;

  box-shadow: 0px 0rem 8rem ${({ color }) => color};

  figure {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: 100%;
    overflow: hidden;
  }
`
