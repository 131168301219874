const generateRandomString = (length: number) => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

const replaceDoubleBraces = (str: string, result: any) => {
    return str.replace(/{{(.+?)}}/g, (_, g1) => result[g1] || g1)
}

export {
    generateRandomString,
    replaceDoubleBraces
}