import React, { useEffect, useMemo, useState } from 'react';

import { useTheme } from 'styled-components';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Images from '@src/assets/images';
import useLogic from '@src/pages/PreRegistration/hooks/uselogic';

import {
    ArrowBtn,
    ArrowIconBtn,
    DateAppointmentAvailableContainer,
    DateAppointmentAvailableFilledContainer,
    DateAppointmentContainer,
    DateNoContainer,
    DayNameContainer,
    UpperCalendarQuickBtnWrapper,
    UpperCalenderActiveRedDot,
    UpperCalenderDatesContainer,
    UpperCalenderDatesGroup,
    UpperCalenderDatesMonthsContainer,
    UpperCalenderGrid,
    UpperCalenderMonth,
    UpperCalenderMonthBar,
    UpperCalenderMonthDateContainer,
    UpperCalenderMonthName,
    UpperCalenderMonthRedDot,
    UpperCalenderMonthWeekContainer,
    UpperCalenderMonthWrapContainer,
    UpperCalenderYearAndDayBox,
    UpperCalenderYearAndDayTxt,
    UpperCalenderYearShiftLocatorContainer,
    UpperCalenderYearShiftLocatorDay,
    UpperDateSelection
} from './style';

const dateStringFormate = 'YYYY-MM-DD', yearStringFormate = 'YYYY'
const nextPreAction: any = {
    day: { amount: 1, type: 'day' },
    week: { amount: 1, type: 'week' },
    month: { amount: 1, type: 'month' },
    year: { amount: 6, type: 'month' }
}

interface UpperCalenderProps {
    onClickDate?: any;
    onClickMonth?: any;
    selectedDate: string;
    type: string;
}

const UpperCalender = ({
    onClickDate = () => { },
    onClickMonth = () => { },
    selectedDate,
    type,
}: UpperCalenderProps) => {

    const [dates, setDates] = useState<any>([])
    const [months, setMonths] = useState<any>([])
    const [quickAction, setQuickAction] = useState<any>({})
    const [calType, setCalType] = useState<string>('day')
    const [years, setYears] = useState<any>([]);

    const theme: any = useTheme();
    const { t } = useTranslation();

    const { getAllMonthUC, getQuickActionForUC, getWeekDateForUC, getMonthDateForUC, getYearDateForUC, getYearMonthUC, getDateForMonth } = useLogic()

    useEffect(() => {
        setTimeout(() => {
            if (type === 'year') {
                setYears(getYearDateForUC(moment(selectedDate, dateStringFormate)))
                setMonths(getYearMonthUC(moment(selectedDate, dateStringFormate)))
            } else {
                let newData: Array<any> = []
                if (type === 'day' || type === 'week') {
                    newData = getWeekDateForUC(moment(selectedDate, dateStringFormate).startOf('month').format(dateStringFormate), moment(selectedDate, dateStringFormate))
                } if (type === 'month') {
                    newData = getMonthDateForUC(moment(selectedDate, dateStringFormate).startOf('month').format(dateStringFormate), moment(selectedDate, dateStringFormate))
                }

                setDates([...newData])
                setMonths(getAllMonthUC(moment(selectedDate, dateStringFormate)))
            }
            setQuickAction(getQuickActionForUC(type === 'year' ? 2 : type === 'month' ? 1 : 0))
            setCalType(type)
        }, 0);
    }, [type, selectedDate])

    const onClickQuickAction = (data: any, forAdd: boolean = true) => {
        onClickDate(moment(selectedDate, dateStringFormate).add(data.amount * (forAdd ? 1 : -1), data.type).format(dateStringFormate));
    }

    let gridColsFR = useMemo(() => calType === 'month' ? dates.map((ele: any) => `${ele.items.length}fr`).join(' ') : undefined, [dates, calType])

    const weekItem = (weekData: any, index: number) => {
        return (
            <UpperCalenderDatesGroup hoverDisable={type === 'day'} type={'week'} key={index} itemCount={weekData?.items?.length} active={type === 'week' && weekData.active}>
                {type === 'week' && weekData.active && <UpperDateSelection />}

                {(weekData.items || []).map((date: any, dateIndex: number) => {

                    const hasShiftAdded = false, shiftAdd = false, shiftDuration = 0, percentage = 0
                    return (
                        <UpperCalenderMonthDateContainer
                            disable={date.disable}
                            key={dateIndex}
                            active={type === 'day' && date.active}
                            type={type === 'day' ? 'day' : undefined}
                            onClick={() => {
                                if (type === 'day') {
                                    onClickDate(date.date_string)
                                } else {
                                    onClickDate(moment(date.date_string, dateStringFormate).startOf('week').format(dateStringFormate))
                                }
                            }}
                        >
                            {type === 'day' && date.active && <UpperDateSelection />}

                            <DateNoContainer>
                                {date.is_today && <UpperCalenderActiveRedDot />}
                                {date?.date}
                            </DateNoContainer>
                            <DayNameContainer marginBottom={true}>{t(`LABEL.${date.day}`)}</DayNameContainer>
                            <DateAppointmentContainer
                                shiftAdd={!!shiftAdd}
                                showBrder={(!!shiftAdd || !!hasShiftAdded)}
                                warning={shiftDuration && (shiftDuration > 600 || shiftDuration < 60) && (shiftDuration > 600 && shiftDuration <= 720 ? 1 : 2)}
                            >
                                {(hasShiftAdded || shiftAdd) &&
                                    <DateAppointmentAvailableContainer data-tooltip-id={shiftAdd && 'shift-status-locater-upper-calendar'} disable={!shiftAdd} shiftAdd={shiftAdd}>
                                        {!!percentage && <DateAppointmentAvailableFilledContainer percentage={percentage} />}
                                    </DateAppointmentAvailableContainer>
                                }
                            </DateAppointmentContainer>

                        </UpperCalenderMonthDateContainer>
                    )
                })}
            </UpperCalenderDatesGroup>
        )
    }

    const monthItem = (monthData: any, index: number) => {
        return (
            <UpperCalenderDatesGroup key={index} itemCount={monthData?.items?.length} active={monthData.active}>
                {monthData.active && <UpperDateSelection />}
                {monthData.items.map((date: any, dateIndex: number) => {
                    const shiftAdd = false
                    const hasShiftAdded = false
                    const percentage = shiftAdd ? 0 : (hasShiftAdded ? 0 : 0)
                    let shiftDuration = 0
                    if (shiftAdd) shiftDuration = 0
                    return (
                        <UpperCalenderMonthDateContainer
                            disable={date.disable}
                            key={dateIndex}
                            onClick={() => onClickDate(moment(date.date_string, dateStringFormate).startOf('month').format(dateStringFormate))}
                        >
                            <DateNoContainer>
                                {date.is_today && <UpperCalenderActiveRedDot />}
                                {date?.date}
                            </DateNoContainer>
                            <DayNameContainer marginBottom={true}>{t(`LABEL.${date.day}`)}</DayNameContainer>
                            <DateAppointmentContainer
                                shiftAdd={!!shiftAdd}
                                warning={shiftDuration && (shiftDuration > 600 || shiftDuration < 60) && (shiftDuration > 600 && shiftDuration <= 720 ? 1 : 2)}
                            >
                                {(hasShiftAdded || shiftAdd) &&
                                    <DateAppointmentAvailableContainer data-tooltip-id={shiftAdd && 'shift-status-locater-upper-calendar'} disable={!shiftAdd} shiftAdd={shiftAdd}>
                                        {!!percentage && <DateAppointmentAvailableFilledContainer percentage={percentage} />}
                                    </DateAppointmentAvailableContainer>
                                }
                            </DateAppointmentContainer>
                        </UpperCalenderMonthDateContainer>
                    )
                })}
            </UpperCalenderDatesGroup>
        )
    }

    if (calType === 'year') {
        return (
            <UpperCalenderGrid>
                <UpperCalenderDatesMonthsContainer>
                    <UpperCalenderMonthWeekContainer year={true}>
                        <UpperCalenderMonthBar hoverable={false} itemCount={months.length}>
                            {(months || []).map((month: any) =>
                                <UpperCalenderMonthBar active={month?.active} itemCount={month?.items?.length}>
                                    {(month?.items || []).map((_month: any) =>
                                        <UpperCalenderMonth
                                            year={true}
                                            bgColorDis={true}
                                            onClick={() => onClickDate(moment(selectedDate, dateStringFormate).format(`YYYY-${_month.month_num}-01`))}
                                        >
                                            <UpperCalenderMonthName>
                                                {t(_month.name)}
                                                {_month.is_today && <UpperCalenderMonthRedDot />}
                                            </UpperCalenderMonthName>
                                        </UpperCalenderMonth>
                                    )}
                                    {month?.active && <UpperDateSelection />}
                                </UpperCalenderMonthBar>
                            )}
                        </UpperCalenderMonthBar>
                        <UpperCalenderYearAndDayBox>
                            <ArrowBtn left={true} onClick={() => onClickQuickAction({ amount: 1, type: 'year' }, false)}>
                                <ArrowIconBtn>
                                    <Images.ArrowUpFill style={{ transform: 'rotate(-90deg)' }} color={'#636366'} />
                                </ArrowIconBtn>
                            </ArrowBtn>
                            <UpperCalenderYearAndDayTxt><ArrowIconBtn>{moment(selectedDate, dateStringFormate).format(yearStringFormate)}</ArrowIconBtn></UpperCalenderYearAndDayTxt>
                            <ArrowBtn onClick={() => onClickQuickAction({ amount: 1, type: 'year' })} >
                                <ArrowIconBtn>
                                    <Images.ArrowUpFill style={{ transform: 'rotate(90deg)' }} color={'#636366'} />
                                </ArrowIconBtn>
                            </ArrowBtn>
                        </UpperCalenderYearAndDayBox>
                    </UpperCalenderMonthWeekContainer>
                    <UpperCalenderDatesContainer year={true} gridCols={`12fr 1fr 1fr`}>
                        <UpperCalenderDatesGroup hoverDisable={true} year={true} singleBox={true} itemCount={2}>
                            {years.map((_years: any, index: number) => {
                                return (
                                    <UpperCalenderDatesGroup key={index} year={true} itemCount={_years.items.length} active={_years.active}>
                                        {_years.items?.map((year: any, _index: number) => {
                                            return (
                                                <UpperCalenderMonthDateContainer
                                                    key={_index}
                                                    year={true}
                                                    onClick={() => onClickDate(moment(selectedDate, dateStringFormate).format(`YYYY-${year.month_num}-01`))}
                                                >
                                                    <DateNoContainer year={true} txt_color={'#5BDF6F'}>
                                                        0%
                                                    </DateNoContainer>
                                                    <DayNameContainer year={true} marginBottom={true}>{'Getting filled up'}</DayNameContainer>
                                                    <UpperCalenderYearShiftLocatorContainer>
                                                        {getDateForMonth(moment(selectedDate, dateStringFormate).format(`YYYY-${year.real_month_num}`)).map((dateItem: any) => {
                                                            const shiftAdd = false
                                                            const hasShiftAdded = false
                                                            let shiftDuration = 0
                                                            if (shiftAdd) shiftDuration = 0
                                                            return (
                                                                <UpperCalenderYearShiftLocatorDay
                                                                    data-tooltip-id={shiftAdd && 'shift-status-locater-upper-calendar'}
                                                                    shiftAdd={!!shiftAdd}
                                                                    shiftAdded={!!shiftAdd}
                                                                    warning={shiftDuration && (shiftDuration > 600 || shiftDuration < 60) && (shiftDuration > 600 && shiftDuration <= 720 ? 1 : 2)}
                                                                />
                                                            )
                                                        })}
                                                    </UpperCalenderYearShiftLocatorContainer>
                                                </UpperCalenderMonthDateContainer>
                                            )
                                        })}
                                    </UpperCalenderDatesGroup>
                                )
                            })}
                        </UpperCalenderDatesGroup>
                        <UpperCalenderDatesGroup year={true} singleBox={true}>
                            <UpperCalenderDatesGroup year={true} itemCount={1} active={false}>
                                <UpperCalenderMonthDateContainer year={true}>
                                    <DateNoContainer year={true} txt_color={'#5BDF6F'}>
                                        70%
                                    </DateNoContainer>
                                    <DayNameContainer year={true} marginBottom={true}>Last 6 Months</DayNameContainer>
                                    <DateAppointmentAvailableContainer>
                                        <DateAppointmentAvailableFilledContainer percentage={'70'} />
                                    </DateAppointmentAvailableContainer>
                                </UpperCalenderMonthDateContainer>
                            </UpperCalenderDatesGroup>
                        </UpperCalenderDatesGroup>
                        <UpperCalenderDatesGroup year={true} singleBox={true}>
                            <UpperCalenderDatesGroup year={true} itemCount={1} active={false}>
                                <UpperCalenderMonthDateContainer year={true}>
                                    <DateNoContainer year={true} txt_color={'#5BDF6F'}>
                                        25%
                                    </DateNoContainer>
                                    <DayNameContainer marginBottom={true} year={true}>Next 6 Months</DayNameContainer>
                                    <DateAppointmentAvailableContainer>
                                        <DateAppointmentAvailableFilledContainer percentage={'25'} />
                                    </DateAppointmentAvailableContainer>
                                </UpperCalenderMonthDateContainer>
                            </UpperCalenderDatesGroup>
                        </UpperCalenderDatesGroup>
                    </UpperCalenderDatesContainer>
                    <UpperCalenderMonthWeekContainer week={true}>
                        {(quickAction?.left || []).map((month: any) => <UpperCalendarQuickBtnWrapper><UpperCalenderMonth onClick={() => onClickQuickAction(month, false)} active={month?.active}>{month?.name}</UpperCalenderMonth></UpperCalendarQuickBtnWrapper>)}
                        <UpperCalenderYearAndDayBox>
                            <ArrowBtn left={true} onClick={() => onClickQuickAction(nextPreAction[calType], false)}>
                                <ArrowIconBtn>
                                    <Images.ArrowUpFill style={{ transform: 'rotate(-90deg)' }} color={'#636366'} />
                                </ArrowIconBtn>
                            </ArrowBtn>
                            <UpperCalenderYearAndDayTxt onClick={() => onClickDate(moment().format(`YYYY-${parseInt(moment().format("MM")) < 7 ? '01' : '07'}-01`))}><ArrowIconBtn>{t("LABEL.TODAY")}</ArrowIconBtn></UpperCalenderYearAndDayTxt>
                            <ArrowBtn onClick={() => onClickQuickAction(nextPreAction[calType])}>
                                <ArrowIconBtn>
                                    <Images.ArrowUpFill style={{ transform: 'rotate(90deg)' }} color={'#636366'} />
                                </ArrowIconBtn>
                            </ArrowBtn>
                        </UpperCalenderYearAndDayBox>
                        {(quickAction?.right || []).map((month: any) => <UpperCalendarQuickBtnWrapper><UpperCalenderMonth onClick={() => onClickQuickAction(month)} active={month?.active}>{month?.name}</UpperCalenderMonth></UpperCalendarQuickBtnWrapper>)}
                    </UpperCalenderMonthWeekContainer>
                </UpperCalenderDatesMonthsContainer>
            </UpperCalenderGrid>
        )
    }

    return (
        <UpperCalenderGrid>
            <UpperCalenderDatesMonthsContainer>
                <UpperCalenderMonthWeekContainer month={true}>
                    <UpperCalenderMonthWrapContainer>
                        {months.map((month: any) => <UpperCalenderMonth onClick={() => onClickMonth(month.month_num)} active={month?.active}>{t(month.name)}</UpperCalenderMonth>)}
                    </UpperCalenderMonthWrapContainer>
                    <UpperCalenderYearAndDayBox>
                        <ArrowBtn left={true} onClick={() => onClickQuickAction({ amount: 1, type: 'year' }, false)}>
                            <ArrowIconBtn>
                                <Images.ArrowUpFill style={{ transform: 'rotate(-90deg)' }} color={'#636366'} />
                            </ArrowIconBtn>
                        </ArrowBtn>
                        <UpperCalenderYearAndDayTxt><ArrowIconBtn>{moment(selectedDate, dateStringFormate).format(yearStringFormate)}</ArrowIconBtn></UpperCalenderYearAndDayTxt>
                        <ArrowBtn onClick={() => onClickQuickAction({ amount: 1, type: 'year' })}>
                            <ArrowIconBtn>
                                <Images.ArrowUpFill style={{ transform: 'rotate(90deg)' }} color={'#636366'} />
                            </ArrowIconBtn>
                        </ArrowBtn>
                    </UpperCalenderYearAndDayBox>
                </UpperCalenderMonthWeekContainer>
                <UpperCalenderDatesContainer gridCols={gridColsFR} itemCount={calType !== 'month' && dates.length}>
                    {dates.map((date: any, index: number) => {
                        if (calType === 'day' || calType === 'week') {
                            return weekItem(date, index)
                        } else {
                            return monthItem(date, index)
                        }
                    })}
                </UpperCalenderDatesContainer>
                <UpperCalenderMonthWeekContainer week={true}>
                    {(quickAction?.left || []).map((month: any) => <UpperCalendarQuickBtnWrapper><UpperCalenderMonth onClick={() => onClickQuickAction(month, false)} active={month?.active}>{month?.name}</UpperCalenderMonth></UpperCalendarQuickBtnWrapper>)}
                    <UpperCalenderYearAndDayBox>
                        <ArrowBtn left={true} onClick={() => onClickQuickAction(nextPreAction[calType], false)}>
                            <ArrowIconBtn>
                                <Images.ArrowUpFill style={{ transform: 'rotate(-90deg)' }} color={'#636366'} />
                            </ArrowIconBtn>
                        </ArrowBtn>
                        <UpperCalenderYearAndDayTxt onClick={() => onClickDate(moment().format(dateStringFormate))}><ArrowIconBtn>{t("LABEL.TODAY")}</ArrowIconBtn></UpperCalenderYearAndDayTxt>
                        <ArrowBtn onClick={() => onClickQuickAction(nextPreAction[calType])}>
                            <ArrowIconBtn>
                                <Images.ArrowUpFill style={{ transform: 'rotate(90deg)' }} color={'#636366'} />
                            </ArrowIconBtn>
                        </ArrowBtn>
                    </UpperCalenderYearAndDayBox>
                    {(quickAction?.right || []).map((month: any) => <UpperCalendarQuickBtnWrapper><UpperCalenderMonth onClick={() => onClickQuickAction(month)} active={month?.active}>{month?.name}</UpperCalenderMonth></UpperCalendarQuickBtnWrapper>)}
                </UpperCalenderMonthWeekContainer>
            </UpperCalenderDatesMonthsContainer>
        </UpperCalenderGrid>
    )
}

export default UpperCalender