import React from 'react'
import expandedOn from '@images/expanded_on.svg'
import expandedOff from '@images/expanded_off.svg'
import { ExpandedBox, TotalsGroup } from '../../style'
import { GlobalViewTotalGroup } from './group'
import useLogic from './useLogic'

const GlobalViewTotal = () => {
  const { totals, layout, setLayout } = useLogic()
  const openMapTotalExpanded = layout.openMapTotalExpanded ?? false

  return (
    <TotalsGroup openMapTotalExpanded={openMapTotalExpanded}>
      {totals.map((total, index) => (
        <GlobalViewTotalGroup key={index} {...total} color={total.items[0].fontColor} />
      ))}
    </TotalsGroup>
  )
}

export default GlobalViewTotal
