import axios from 'axios'
import Config from './config'

const api = axios.create({
  baseURL: Config.REACT_APP_GATEWAY_URL,
  timeout: 30000,
})

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token')
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${token}`,
    }
    return config
  },
  (error) => error
)

export default api
