import {
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'
import transformToSelectOptions from '@src/core/transformToSelectOptions'

import CoursesLengthOfStudy from '@images/step3degreeLengthOfStudy.svg'
import {
  Icon,
  Input,
  Select,
  Tooltip,
  TooltipChildren,
} from '@viswals/components'
import {
  ContainerLengthOfStudyCourses,
  ContainertMessageToolTip,
  ContentMiniForm,
} from './styles'
import {
  DataApiMockProps,
  monthSelect,
  StudyModes,
  yearSelect,
} from '../../common/data'

import '../../../../../../styles/tooltip.scss'

type LengthOfStudyCourses = {
  watch: UseFormWatch<FieldValues>
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  getValues?: UseFormGetValues<FieldValues>
  keyIndex?: number
  dataDuration: DataApiMockProps[]
  studyModes: StudyModes[]
  underCompletion: boolean
}

const LengthOfStudyCourses = ({
  studyModes,
  dataDuration,
  keyIndex,
  watch,
  register,
  setValue,
  getValues,
  underCompletion,
}: LengthOfStudyCourses) => {
  const dataCourseDuration = transformToSelectOptions({
    array: dataDuration,
    keyLabel: 'name',
    keyValue: 'uuid',
  })

  const MessageCertifiedCourseHours = () => {
    return (
      <ContainertMessageToolTip>
        <TooltipChildren>
          <div className="vw-tooltipChildren-title">
            What are Certified Course Hours?
          </div>
          <div className="vw-tooltipChildren-message">
            These are the number of certified course hours as per your course
            provider. This number varies largely across the world, and in case
            you don’t know it, please select Unknown.
          </div>
        </TooltipChildren>
      </ContainertMessageToolTip>
    )
  }

  return (
    <ContainerLengthOfStudyCourses>
      <h3>Length of Study</h3>
      <img src={CoursesLengthOfStudy} alt="CourCoursesLengthOfStudysesCap" />
      <div className="vw-regis-safeArea">
        <p>Course Duration</p>
        <div className="vw-regis-contentOptions">
          {dataCourseDuration.map((duration, _key) => {
            return (
              <label
                key={_key}
                className={`vw-form vw-regis-safeAreaItems ${
                  duration.value === watch(`data.${keyIndex}.courseDuration`)
                    ? 'checked'
                    : ''
                }`}
              >
                {duration.label}
                <input
                  type="radio"
                  value={duration.value}
                  {...register(`data.${keyIndex}.courseDuration`, {
                    required: true,
                  })}
                />
              </label>
            )
          })}
        </div>
        <div className="subTitleParagrath first">
          Certified Course Hours
          <div className="subTitleIconRight">
            <Tooltip
              placement="top"
              overlay={MessageCertifiedCourseHours}
              destroyTooltipOnHide
              trigger={['click']}
              motion={{
                motionName: 'rc-tooltip-fade',
              }}
            >
              <div>
                <Icon name="Info1" />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="boxContentInputAndButton">
          <ContentMiniForm>
            <Input
              placeholder="Insert hours here"
              type="number"
              formRegister={register(`data.${keyIndex}.certifiedCourseHours`, {
                valueAsNumber: true,
                validate: (v) => {
                  return (
                    !!v ||
                    (getValues &&
                      !!getValues(
                        `data.${keyIndex}.certifiedCourseHoursUnknow`
                      ))
                  )
                },
                // pattern: /^(0|[1-9]\d*)(\.\d+)?$/,
                onChange: (e) => {
                  if (e.target.value) {
                    setValue &&
                      setValue(
                        `data.${keyIndex}.certifiedCourseHoursUnknow`,
                        undefined
                      )
                  }
                },
              })}
            />
          </ContentMiniForm>
          <label
            className={`vw-form vw-regis-safeAreaItems ${
              watch(`data.${keyIndex}.certifiedCourseHoursUnknow`) === 0 ||
              watch(`data.${keyIndex}.certifiedCourseHoursUnknow`) === true
                ? 'checked'
                : ''
            }`}
          >
            Unknown
            <input
              type="checkbox"
              {...register(`data.${keyIndex}.certifiedCourseHoursUnknow`, {
                onChange: (e) => {
                  if (e.target.value) {
                    setValue(`data.${keyIndex}.certifiedCourseHours`, null)
                  }
                },
              })}
            />
          </label>
        </div>
        <div className="subTitleParagrath second">Study Mode</div>
        <div className="vw-regis-contentOptions alignLabelText">
          {studyModes.map((duration, _key) => {
            return (
              <label
                key={_key}
                className={`vw-form vw-regis-safeAreaItems studyMode ${
                  duration.uuid === watch(`data.${keyIndex}.studyMode`)
                    ? 'checked'
                    : ''
                }`}
              >
                {duration.name}
                <input
                  type="radio"
                  value={duration.uuid}
                  {...register(`data.${keyIndex}.studyMode`, {
                    required: true,
                  })}
                />
              </label>
            )
          })}
        </div>
        {underCompletion !== true ? (
          <>
            <div className="subTitleParagrath third">Date Granted</div>
            <ContentMiniForm className="vw-contentMiniForm-select">
              <Select
                labelTitle="Select Year"
                placeholder="Select Year"
                options={yearSelect}
                formRegister={{
                  ...register(`data.${keyIndex}.dateGrantedYear`),
                  watch,
                }}
                placement="top"
                search
                isDisabled
              />
            </ContentMiniForm>
            <ContentMiniForm className="vw-contentMiniForm-select second">
              <Select
                labelTitle="Select Month"
                placeholder="Select Month"
                options={monthSelect}
                formRegister={{
                  ...register(`data.${keyIndex}.dateGrantedMonth`),
                  watch,
                }}
                search
                placement="top"
                isDisabled
              />
            </ContentMiniForm>
          </>
        ) : null}
      </div>
    </ContainerLengthOfStudyCourses>
  )
}

export default LengthOfStudyCourses
