/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const CardFileGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`

export const OpenBox = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 70vh;
  border: 1px solid white;
  margin: 5px;
  border-radius: 7px;

  figure {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    max-width: 100%;
    max-height: 100%;
  }
`

export const CardFileBox = styled.div`
  legend {
    font-size: 40px;
  }

  figure {
    width: 200px;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }

    img {
      width: 100%;
    }
  }
`
