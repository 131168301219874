/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const ContainerDentistryAwards = styled.section`
  width: 100%;
  height: 100%;

  padding: 4.3rem 5rem 2.5rem 5.1rem;

  position: relative;

  h1 {
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: var(--gray-100-color);
    margin-bottom: 0.3rem;
  }

  span {
    font-size: 1.3rem;
    color: var(--gray-200-color);
  }
  .vw-regis-headerButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -1.6rem;
    .vw-regist-contentNav {
      &.Verified {
        .contentPagination {
          div {
            &:first-child {
              margin-right: 0;
            }
          }
        }
      }
    }
    .vw-regis-boxRight {
      display: flex;
      .vw-regist-buttonDanger {
        margin-right: 2rem;
        .vw-button-buttonstyle {
          background-color: #fd3333;
          padding: 0.9rem 0.6rem 0.9rem 1.1rem;
        }
      }
      .vw-button-buttonstyle {
        width: 11.3rem;
        height: 4.2rem;
        border-radius: 0.5rem;
        background-color: var(--info-color);
        padding: 0.9rem 0.9rem 0.9rem 1rem;
        span {
          margin-bottom: 0.1rem;
          font-family: var(--font-primary);
          font-size: 1.2rem;
          color: white;
        }
      }
    }

    .boxDeleteCard {
      margin-right: 1rem;
      .vw-button-buttonstyle {
        width: 15.4rem;
        height: 4.2rem;
        border-radius: 0.5rem;
        background-color: var(--red-color);
        padding: 0.9rem 0.9rem 0.9rem 1rem;
        span {
          margin-left: 0.7rem;
          margin-bottom: 0.1rem;
          font-family: var(--font-primary);
          font-size: 1.2rem;
          color: white;
        }
      }
    }

    .contentPagination {
      display: none;
      &.block {
        display: flex;
      }
    }
  }
  form {
    width: 147.2rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 2.7rem;
  }
`

export const BoxContentCards = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  .card-item {
    width: 100%;
    max-width: 47.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-right: 2.5rem;
    .vw-card-cardBox {
      padding: 0 2.5rem;
    }
    :last-of-type {
      margin-right: 0;
    }
    .insideCard {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .svgTitle {
        margin-top: 3.9rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        svg {
          width: 4.8rem;
          color: #798089;
          opacity: 0.05;
          transition: all 0.3s ease-in-out;
        }
        span {
          height: 2rem;
          line-height: 2.8rem;
          font-size: 1.4rem;
          font-family: var(--font-primary);
          margin-top: 1rem;
          margin-left: 0.1rem;
          font-weight: 700;
        }
        &.Award {
          svg {
            color: #efd514;
            opacity: 1;
          }
        }
        &.Commended {
          svg {
            color: #ffc690;
            opacity: 1;
          }
        }
        &.Nomination {
          svg {
            color: #b0b0b0;
            opacity: 1;
          }
        }
      }
      .doubleSelect {
        .vw-select-boxContentInput {
          .vw-selectOptions-boxSelectOptions {
            .vw-selectOptions-boxScrollOptions {
              max-height: 40rem;
            }
          }
        }
      }
    }
  }
`

export const ContentAwardForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4.2rem;
  gap: 1rem;
  .vw-select-boxContentInput {
    height: 4rem;
    .vw-select-BoxInput {
      padding-left: 0.7rem;
      input {
        font-size: 1.2rem;
        &::placeholder {
          font-size: 1.2rem;
        }
      }
    }
  }
  .vw-input-boxContentInput {
    height: 4rem;
    .vw-input-boxInput {
      padding-left: 0.7rem;
      input {
        font-size: 1.2rem;
        &::placeholder {
          font-size: 1.2rem;
        }
      }
    }
  }
  .doubleSelect {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.1rem;
    .vw-select-boxContentInput {
      &:last-of-type {
        .vw-selectOptions-boxSelectOptions {
          top: -4rem;
          .vw-selectOptions-boxSelectOptionsLabel {
            padding-top: 2rem;
          }
        }
        .vw-selectOptions-boxScrollOptions {
          height: 7.1rem;
        }
      }
    }
  }

  .divsor {
    width: 100%;
    height: 0.1rem;
    background-color: #333843;
    margin-top: 1rem;
    margin-bottom: 1rem;
    &.last {
      margin-top: 0.9rem;
    }
  }
  .vw-upload-boxContainerInputUpload {
    svg {
      width: 2.1rem;
      transition: all 0.3s ease-in-out;
    }
    .vw-upload-boxInputUpload {
      padding-left: 0rem;
      label {
        font-size: 1.2rem;
      }
    }
    &.vw-filled {
      .vw-upload-boxInputUpload {
        padding-left: 0rem;
        justify-content: center;
        .vw-upload-boxIconUpload {
          width: 24.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s ease-in-out;
          svg {
            width: 1.8rem;
            margin-left: 0rem;
            margin-right: 1.7rem;
            opacity: 1;
          }
          label {
            width: min-content;
            margin-top: -0.1rem;
            margin-right: -0.2rem;
          }
        }
        &.vw-borderError {
          .vw-upload-boxIconUpload {
            transition: all 0.3s ease-in-out;
            width: 100%;
            padding-left: 1rem;
            justify-content: flex-start;
          }
        }
      }
    }
  }
  .contentLast {
    width: 100%;
    .vw-input-boxContentInput {
      height: 4rem;
      .vw-input-boxInput {
        padding-left: 0.7rem;
        input {
          font-size: 1.2rem;
          &::placeholder {
            font-size: 1.2rem;
            color: #79808960;
          }
        }
      }
    }
  }
`
