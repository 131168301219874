/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import {
  Button,
  Card,
  Icon,
  Input,
  QuestionModal,
  Select,
  Upload,
} from '@viswals/components'
import MiniPagination from '@src/components/pagination/SubstepMiniPagination'
import {
  BoxContentCards,
  ContainerDentistryAwards,
  ContentAwardForm,
} from './DentistryAwardsStyles'
import useLogic from './useLogic'
import { yearSelect, dataNominee, dataResultAward } from './data'

const StepDentistryAwards = () => {
  const {
    dispatch,
    confirm,
    fields,
    verified,
    isModalOpen,
    dataCountries,
    indexToDelete,

    errors,

    watch,
    onSubmit,
    register,
    setValue,
    handleSubmit,

    getFieldProps,

    setPaginationActive,
    setNavigationConfirm,

    handleOpenModel,
    handleCancelModelDelete,
    handleConfirmModelDelete,

    pagination,
    paginationActive,
    indexShowStart,
    indexShowEnd,
  } = useLogic()
  return (
    <ContainerDentistryAwards>
      <h1>Dentistry Awards</h1>
      <span>List any team or individual awards here</span>
      <div className="vw-regis-headerButtons">
        <div className={`vw-regist-contentNav ${verified ? 'Verified' : ''}`}>
          {indexToDelete.length > 0 ? (
            <div className="boxDeleteCard">
              <Button
                icon="Remove1"
                label={`Delete Award${indexToDelete.length >= 2 ? 's' : ''}`}
                styleType="danger"
                handleClick={handleOpenModel}
              />
              {isModalOpen ? (
                <QuestionModal
                  title={`Delete Award${indexToDelete.length >= 2 ? 's' : ''}`}
                  description={`You are about to delete ${
                    indexToDelete.length >= 2 ? '' : 'an'
                  } Award${
                    indexToDelete.length >= 2 ? 's' : ''
                  }. This action cannot be undone.
                                    Are you sure you want to proceed?`}
                  cancelLabel="Oops, take me back"
                  cancelFunction={handleCancelModelDelete}
                  confirmLabel={`Yes, delete Award${
                    indexToDelete.length >= 2 ? 's' : ''
                  }`}
                  confirmFunction={handleConfirmModelDelete}
                />
              ) : null}
            </div>
          ) : null}
          <div
            className={`contentPagination ${
              fields.length >= 4 && indexToDelete.length <= 0 ? 'block' : ''
            }`}
          >
            <MiniPagination
              pages={pagination}
              active={paginationActive}
              onChangeActive={setPaginationActive}
            />
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <BoxContentCards>
          {fields.map((award, awardIndex) => {
            const awardResult = watch(`data.${awardIndex}.awardResult`)
            if (pagination > 1) {
              if (
                !(awardIndex >= indexShowStart && awardIndex < indexShowEnd)
              ) {
                return null
              }
            }

            // @ts-ignore
            if (award.destroy === true) {
              return null
            }

            const hasCardError =
              // @ts-ignore
              errors && errors.data && errors.data[awardIndex]

            const awardTitleResult = dataResultAward.find(
              (awardResulted: any) =>
                awardResulted.value ===
                parseInt(watch(`data.${awardIndex}.awardResult`))
            )

            return (
              <div key={award.id} className={`card-item item-${awardIndex}`}>
                <Card
                  readyDelete={indexToDelete.includes(awardIndex)}
                  isVerified={verified}
                  isError={hasCardError}
                >
                  <input
                    type="hidden"
                    {...register(`data.${awardIndex}.destroy`, {
                      value: false,
                    })}
                  />
                  <article className="insideCard">
                    <div
                      className={`svgTitle ${
                        awardResult
                          ? awardResult == 1
                            ? 'Award'
                            : awardResult == 2
                            ? 'Commended'
                            : 'Nomination'
                          : ''
                      }`}
                    >
                      <Icon name="Cup2" />
                      <span>{awardTitleResult?.label ?? 'Award'}</span>
                    </div>
                    <ContentAwardForm>
                      <Select
                        options={dataCountries}
                        placeholder="Select the Country"
                        labelTitle="Select a Country"
                        formRegister={{
                          ...register(`data.${awardIndex}.country`, {
                            required: true,
                          }),
                          watch,
                        }}
                        search
                        {...getFieldProps(`data.${awardIndex}.country`)}
                        placement="bottom"
                      />

                      <Input
                        placeholder="Type here the Awards' official name"
                        formRegister={register(
                          `data.${awardIndex}.officialName`,
                          {
                            required: true,
                          }
                        )}
                        {...getFieldProps(`data.${awardIndex}.officialName`)}
                      />
                      <div className="doubleSelect">
                        <Select
                          options={yearSelect}
                          placeholder="Select the Year"
                          labelTitle="Select the Year"
                          formRegister={{
                            ...register(`data.${awardIndex}.year`, {
                              required: true,
                            }),
                            watch,
                          }}
                          search
                          {...getFieldProps(`data.${awardIndex}.year`)}
                        />
                        <Select
                          options={dataNominee}
                          placeholder="Select the Nominee"
                          labelTitle="Select the Nominee"
                          formRegister={{
                            ...register(`data.${awardIndex}.nominee`, {
                              required: true,
                            }),
                            watch,
                          }}
                          {...getFieldProps(`data.${awardIndex}.nominee`)}
                        />
                      </div>
                      <Input
                        placeholder="Type here the Award Category"
                        formRegister={{
                          ...register(`data.${awardIndex}.awardCategory`, {
                            required: true,
                          }),
                        }}
                        {...getFieldProps(`data.${awardIndex}.awardCategory`)}
                      />
                      <div className="divsor" />
                      <Select
                        options={dataResultAward}
                        placeholder="Select the Award Result"
                        labelTitle="Select the Award Result"
                        formRegister={{
                          ...register(`data.${awardIndex}.awardResult`, {
                            required: true,
                          }),
                          watch,
                        }}
                        {...getFieldProps(`data.${awardIndex}.awardResult`)}
                      />
                      <div className="divsor last" />
                      <Upload
                        placeholder="Upload Certificate"
                        formRegister={{
                          ...register(`data.${awardIndex}.certificate`, {
                            required: !watch(`data.${awardIndex}.certificate`)
                              ?.fileName,
                            onBlur: () => {
                              if (!confirm) {
                                dispatch(setNavigationConfirm(true))
                              }
                            },
                          }),
                          setValue,
                          watch,
                        }}
                        isError={
                          errors &&
                          errors.data &&
                          // @ts-ignore
                          errors?.data[awardIndex]?.certificate
                            ? !watch(`data.${awardIndex}.certificate`)?.fileName
                            : undefined
                        }
                        isDisabled
                      />
                    </ContentAwardForm>
                  </article>
                </Card>
              </div>
            )
          })}
        </BoxContentCards>
      </form>
    </ContainerDentistryAwards>
  )
}

export default StepDentistryAwards
