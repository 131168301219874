import styled from 'styled-components'

export const BoxBoundarySteps = styled.section`
  width: 100%;
  height: 100%;

  padding: 4.3rem 2.4rem 2.5rem 5.1rem;

  h1 {
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: var(--gray-100-color);
  }
  p {
    margin-top: 0.3rem;
    font-size: 1.3rem;
    font-weight: 400;
    font-family: var(--font-primary);
    color: var(--gray-200-color);
    line-height: 2rem;
    margin-bottom: 5.4rem;
  }

  fieldset {
    border: none;
  }
`

export const BoxContentCards = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .vw-card-cardBox {
    width: 100%;
    max-width: 35rem;
    padding: 0 2.5rem 0 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    :nth-child(1) {
      img {
        width: 15.66rem;
        margin-top: 10rem;
        margin-bottom: 18.33rem;
        margin-right: 0.2rem;
      }
      .vw-select-boxLabelText {
        margin-top: 0.1rem;
      }
      .vw-select-BoxInput {
        background: #242426;
      }
      .vw-select-inputFieldStyle {
        margin-left: -0.2rem;
      }
      .vw-selectOptions-boxSelectOptions {
        top: -58rem;
        z-index: 2;
        backdrop-filter: blur(2rem) brightness(115%);
        background-color: #24242670 !important;
        .vw-selectOptions-boxScrollOptions {
          height: 100%;
          max-height: 69.5rem;
        }
      }
    }

    :nth-child(2) {
      img {
        width: 23.5rem;
        margin-top: 9.6rem;
        margin-bottom: 19.79rem;
      }
      .vw-select-boxLabelText {
        margin-top: 0.1rem;
      }
      .vw-select-inputFieldStyle {
        margin-left: -0.2rem;
      }

      .vw-input-boxInput {
        margin-top: 1rem;

        &::before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          margin-left: -2rem;
          z-index: 0;
          background: #242426;
          opacity: 0.5;
          border-radius: 0.5rem;
        }
        input {
          z-index: 2;
          margin-left: -0.4rem;
        }
      }
      .vw-selectOptions-boxSelectOptions {
        z-index: 999;
        backdrop-filter: blur(2rem) brightness(115%);
      }
    }

    :nth-child(3) {
      padding: 0 2.6rem;
      img {
        width: 7.7rem;
        margin-top: 9rem;
        margin-bottom: 19.22rem;
        margin-right: 2.8rem;
      }
      .vw-input-boxContentInput {
        margin-left: -0.1rem;
        input {
          z-index: 2;
          margin-left: -0.3rem;
        }
      }
      .vw-input-boxInput {
        background: #242426;
      }
    }

    :nth-child(4) {
      padding: 0 3rem 0 2rem;
      img {
        width: 9.965rem;
        margin-top: 13.74rem;
        margin-bottom: 20.84rem;
        margin-right: 0.1rem;
      }
      .vw-input-boxInput {
        background: #242426;
      }
      .vw-input-boxInput {
        :nth-child(2) {
          margin-bottom: 1rem;
        }
        input {
          margin-left: -0.3rem;
        }
      }
    }
  }
`
