/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const OverviewBox = styled.div`
  width: 40rem;
  height: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-family: 'Nebula';
    font-size: 8px;
    font-weight: bold;
    color: #7c7c80;
  }

  .details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    b {
      font-family: 'Poppins';
      font-size: 1.2rem;
      font-weight: bold;
      color: #aeaeb2;
    }

    .details-percent {
      font-family: 'Oxanium';
      font-size: 1.2rem;
      color: #545456;
      font-weight: bold;
      .color {
        color: #5bdf6f;
      }
    }
  }

  .progress {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Oxanium';
    font-size: 0.8rem;
    color: #545456;
    gap: 1.6rem;

    div {
      display: flex;
      &:last-child {
        flex: 1;
      }
    }
  }
`
