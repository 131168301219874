import { setActionLoading } from '@src/store/reducers/loading/actions'
import { ReducersProps } from '@src/types/reducers'
import { useDispatch, useSelector } from 'react-redux'

const useLoading = () => {
  const dispatch = useDispatch()
  const loading = useSelector((state: ReducersProps) => state.loading)

  function setLoading(value: boolean) {
    dispatch(setActionLoading(value))
  }

  return { loading, setLoading }
}

export default useLoading
