import { VerificationCardBox } from './style'

type VerificationCardProps = {
  children: React.ReactNode
  className?: string
}
const VerificationCard = ({ children, className }: VerificationCardProps) => {
  return (
    <VerificationCardBox className={className}>{children}</VerificationCardBox>
  )
}
export default VerificationCard
