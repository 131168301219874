const dataColors = [
  // 1
  '#ffcdd2',
  '#ef9a9a',
  '#e57373',
  '#ef5350',
  '#f44336',
  '#e53935',
  '#d32f2f',
  '#c62828',
  '#b71c1c',
  '#A93226',
  // 2
  '#f8bbd0',
  '#f48fb1',
  '#f06292',
  '#ec407a',
  '#e91e63',
  '#d81b60',
  '#c2185b',
  '#ad1457',
  '#880e4f',
  '#78281F',
  // 3
  '#e1bee7',
  '#ce93d8',
  '#ba68c8',
  '#ab47bc',
  '#9c27b0',
  '#8e24aa',
  '#7b1fa2',
  '#6a1b9a',
  '#4a148c',
  '#3D1173',
  // 4
  '#d1c4e9',
  '#b39ddb',
  '#9575cd',
  '#7e57c2',
  '#673ab7',
  '#5e35b1',
  '#512da8',
  '#4527a0',
  '#311b92',
  '#221364',
  // 5
  '#c5cae9',
  '#9fa8da',
  '#7986cb',
  '#5c6bc0',
  '#3f51b5',
  '#3949ab',
  '#303f9f',
  '#283593',
  '#1a237e',
  '#111754',
  // 6
  '#bbdefb',
  '#90caf9',
  '#64b5f6',
  '#42a5f5',
  '#2196f3',
  '#1e88e5',
  '#1976d2',
  '#1565c0',
  '#0d47a1',
  '#0A3270',
  // 7
  '#b3e5fc',
  '#81d4fa',
  '#4fc3f7',
  '#29b6f6',
  '#03a9f4',
  '#039be5',
  '#0288d1',
  '#0277bd',
  '#01579b',
  '#013C6A',
  // 8
  '#b2ebf2',
  '#80deea',
  '#4dd0e1',
  '#26c6da',
  '#00bcd4',
  '#00acc1',
  '#0097a7',
  '#00838f',
  '#006064',
  '#015053',
  // 9
  '#b2dfdb',
  '#80cbc4',
  '#4db6ac',
  '#26a69a',
  '#009688',
  '#00897b',
  '#00796b',
  '#00695c',
  '#004d40',
  '#003B31',
  // 10
  '#c8e6c9',
  '#a5d6a7',
  '#81c784',
  '#66bb6a',
  '#4caf50',
  '#43a047',
  '#388e3c',
  '#2e7d32',
  '#1b5e20',
  '#164D1A',
  // 11
  '#dcedc8',
  '#c5e1a5',
  '#aed581',
  '#9ccc65',
  '#8bc34a',
  '#7cb342',
  '#689f38',
  '#558b2f',
  '#33691e',
  '#2A5718',
  // 12
  '#f0f4c3',
  '#e6ee9c',
  '#dce775',
  '#d4e157',
  '#cddc39',
  '#c0ca33',
  '#afb42b',
  '#9e9d24',
  '#827717',
  '#6D6414',
  // 13
  '#fff9c4',
  '#fff59d',
  '#fff176',
  '#ffee58',
  '#ffeb3b',
  '#fdd835',
  '#fbc02d',
  '#f9a825',
  '#f57f17',
  '#E5730F',
  // 14
  '#ffecb3',
  '#ffe082',
  '#ffd54f',
  '#ffca28',
  '#ffc107',
  '#ffb300',
  '#ffa000',
  '#ff8f00',
  '#ff6f00',
  '#D35C01',
  // 15
  '#ffe0b2',
  '#ffcc80',
  '#ffb74d',
  '#ffa726',
  '#ff9800',
  '#fb8c00',
  '#f57c00',
  '#ef6c00',
  '#e65100',
  '#B94202',
  // 16
  '#ffccbc',
  '#ffab91',
  '#ff8a65',
  '#ff7043',
  '#ff5722',
  '#f4511e',
  '#e64a19',
  '#d84315',
  '#bf360c',
  '#A8300B',
  // 17
  '#d7ccc8',
  '#bcaaa4',
  '#a1887f',
  '#8d6e63',
  '#795548',
  '#6d4c41',
  '#5d4037',
  '#4e342e',
  '#3e2723',
  '#34201D',
  // 18
  '#f5f5f5',
  '#eeeeee',
  '#e0e0e0',
  '#bdbdbd',
  '#9e9e9e',
  '#757575',
  '#616161',
  '#424242',
  '#212121',
  '#000000',
  // 19
  '#cfd8dc',
  '#b0bec5',
  '#90a4ae',
  '#78909c',
  '#607d8b',
  '#546e7a',
  '#455a64',
  '#37474f',
  '#263238',
  '#000000',
]

export default dataColors
