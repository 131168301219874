import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateLanguage from './form_create'
import FormUpdateLanguage from './form_update'
import LanguageTable from './table'

export default function LanguagePages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <LanguageTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateLanguage />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateLanguage />
          </Layout>
        }
      />
    </Routes>
  )
}
