import Pool from '@images/pool.svg'
import { FishPoolStartCircleBox } from './style'

type FishPoolStartProps = {
  onClick: () => void
  disabled: boolean
}

const FishPoolStart = ({ onClick, disabled }: FishPoolStartProps) => {
  return (
    <FishPoolStartCircleBox
      onClick={disabled ? undefined : onClick}
      disabled={disabled}
    >
      <div>
        <img src={Pool} alt="icon pool" />
        <b>START</b>
      </div>
    </FishPoolStartCircleBox>
  )
}

export default FishPoolStart
