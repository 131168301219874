import React from 'react'
import { Input } from '@viswals/components'
import isURL from '@src/core/isUrl'
import Container from './OwnWebsiteStyles'
import useLogic from './useLogic'

const StepOwnWebsite = () => {
  const { handleSubmit, onSubmit, register, getFieldProps } = useLogic()

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-ownWebsite-header">
          <h2>Own Website</h2>
          <span>Enter your website details below</span>
        </div>
        <div className="vw-ownWebsite-inputArea">
          <p>Website</p>
          <Input
            type="text"
            placeholder="Insert your website url Here"
            formRegister={register('websiteUrl', {
              required: true,
              validate: (value) => (value ? isURL(value) : undefined),
            })}
            {...getFieldProps('websiteUrl')}
          />
        </div>
      </form>
    </Container>
  )
}

export default StepOwnWebsite
