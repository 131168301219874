/* eslint-disable @typescript-eslint/no-explicit-any */
import { Dispatch, SetStateAction } from 'react'
import {
  Request,
  CourseTitle,
  CourseTitleFilter,
  retrieveCourseTitle,
  CourseTitleType,
} from '@viswals/services'
import { SelectedOptionsProps } from '@viswals/components'
import transformToSelectOptions from '@src/core/transformToSelectOptions'

type handleRetrieveAwardedTitleProps = {
  type: CourseTitleType
  setCoursesTitle: Dispatch<SetStateAction<SelectedOptionsProps[]>>
}

const dataToOrder: Array<{ value: string; type: CourseTitleType }> = [
  {
    value: 'Doctor of Dental Surgery (DDS)',
    type: 'DEGREE',
  },
  {
    value: 'Bachelor of Dental Surgery (BDS)',
    type: 'DEGREE',
  },
  {
    value: 'Licenciature in Dental Medicine (LMD)',
    type: 'DEGREE',
  },
  {
    value: 'Postgraduate Certificate',
    type: 'MAJOR',
  },
  {
    value: 'Postgraduate Diploma',
    type: 'MAJOR',
  },
  {
    value: 'Master Of Sciences',
    type: 'MAJOR',
  },
  {
    value: 'Attendance Certificate',
    type: 'SHORT',
  },
  {
    value: 'Course Certificate',
    type: 'SHORT',
  },
  {
    value: 'Course Diploma',
    type: 'SHORT',
  },
]

async function handleRetrieveAwardedTitle({
  type,
  setCoursesTitle,
}: handleRetrieveAwardedTitleProps) {
  try {
    const request: Request<CourseTitleFilter> = {
      fields: ['uuid', 'title', 'type'],
      filters: {
        type,
      },
    }
    const resp = await retrieveCourseTitle(request)
    const data: CourseTitle[] | null = resp?.data ?? []

    if (data && data?.length > 0) {
      const titles = dataToOrder.filter((item) => item.type === type)
      const findFirst = data.find(
        (courseT) => courseT.title === titles[0].value
      )
      const findFirstIndex = data.findIndex(
        (courseT) => courseT.title === titles[0].value
      )
      const findSecond = data.find(
        (courseT) => courseT.title === titles[1].value
      )
      const findSecondIndex = data.findIndex(
        (courseT) => courseT.title === titles[1].value
      )
      const findThird = data.find(
        (courseT) => courseT.title === titles[2].value
      )
      const findThirdIndex = data.findIndex(
        (courseT) => courseT.title === titles[2].value
      )

      // Remove Item found
      data.splice(findFirstIndex, 1)
      // Add Item to the index Found;
      data.splice(0, 0, findFirst as CourseTitle)

      // Remove Item found
      data.splice(findSecondIndex, 1)
      // Add Item to the index Found;
      data.splice(1, 0, findSecond as CourseTitle)

      // Remove Item found
      data.splice(findThirdIndex, 1)
      // Add Item to the index Found;
      data.splice(2, 0, findThird as CourseTitle)

      const coursesTitle = transformToSelectOptions({
        array: data ?? [],
        keyLabel: 'title',
        keyValue: 'uuid',
      })

      setCoursesTitle(coursesTitle ?? [])
    } else {
      setCoursesTitle([])
    }

    return null
  } catch (error) {
    return null
  }
}

export default handleRetrieveAwardedTitle
