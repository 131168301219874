import Layout from '@src/components/Layout'
import { FishPoolCircleBox, FishPoolContainer, FishPoolTitle } from './style'
import FishPoolStart from './start'
import FishPoolLineCircle from './lineCircle'
import Fished from './Fished'
import EmptyPool from './emptyPool'
import useLogic from './useLogic'

const FishPool = () => {
  const { image, goFished } = useLogic()
  const color = '#D6CEF6'

  return (
    <Layout>
      <FishPoolContainer>
        <FishPoolTitle>Live Fishing pool</FishPoolTitle>

        <FishPoolCircleBox>
          <FishPoolLineCircle color={color} />
          {image ? <Fished color={color} image={image} /> : <EmptyPool />}

          <FishPoolStart onClick={goFished} disabled={!image} />
        </FishPoolCircleBox>
      </FishPoolContainer>
    </Layout>
  )
}

export default FishPool
