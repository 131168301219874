import { Routes, Route } from 'react-router-dom'

import Layout from '../layout'

import RegulatoryBodyTable from './table'
import FormUpdateInsuranceCompany from './form_update'
import FormCreateInsuranceCompany from './form_create'

export default function InsuranceCompanyPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <RegulatoryBodyTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateInsuranceCompany />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateInsuranceCompany />
          </Layout>
        }
      />
    </Routes>
  )
}
