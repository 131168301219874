import { StatusDetailType } from '../../components/DetailMapitem/type'

const statusData: StatusDetailType[] = [
  {
    status: 'SIGN_UP',
    backgroundActive: '#EBEBEB',
    icon: 'SignUps',
    beforeBorder: '#505052',
    beforeIcon: '#3B3B3D',
  },
  {
    status: 'NEW_ENTRY',
    backgroundActive: '#A123D0',
    icon: 'NewEntries',
    beforeBorder: '#720080',
    beforeIcon: '#720080',
  },
  {
    status: 'RE_ENTRY',
    backgroundActive: '#D619DE',
    icon: 'ReEntries',
    beforeBorder: '#850082',
    beforeIcon: '#850082',
  },
  {
    status: 'IN_PROGRESS',
    backgroundActive: '#4166FF',
    icon: 'InProgress',
    beforeBorder: '#2B3F94',
    beforeIcon: '#2B3F94',
  },
  {
    status: 'QUESTION_RAISED',
    backgroundActive: '#FE9F0A',
    icon: 'QuestionsRaised',
    beforeBorder: '#720080',
    beforeIcon: '#720080',
  },
  {
    status: 'DISQUALIFIED',
    backgroundActive: '#FD453A',
    icon: 'QuestionsRaised',
    beforeBorder: '#992727',
    beforeIcon: '#992727',
  },
  {
    status: 'VERIFIED',
    backgroundActive: '#64D2FF',
    icon: 'VerifiedCc',
    beforeBorder: '#2A7892',
    beforeIcon: '#2A7892',
  },
  {
    status: 'ACQUIRED',
    backgroundActive: '#00FF89',
    icon: 'Acquired',
    beforeBorder: '#00914E',
    beforeIcon: '#00914E',
  },
  {
    status: 'DROPOUT',
    backgroundActive: '#636366',
    icon: 'Dropouts',
    beforeBorder: '',
    beforeIcon: '',
  },
]

export default statusData
