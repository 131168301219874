import { SelectedOptionsProps } from '@viswals/components'
import { AccountType } from '@viswals/services'

export type LocalAccountTypeEnumSelect = {
  label: string
  value: AccountType
}

export const accountTypeValues: LocalAccountTypeEnumSelect[] = [
  {
    label: 'Personal Banking',
    value: 'PERSONAL',
  },
  {
    label: 'Business Banking',
    value: 'BUSINESS',
  },
]

export const bankList: SelectedOptionsProps[] = [
  {
    label: 'Activo Bank',
    value: '7454df98-af60-4b2f-b456-fbc5d0dd46ab',
  },
]
