import {
  MainMenu,
  MainMenuContent,
  MainMenuIconTop,
} from '@viswals/components-platform'
import useLogic from './useLogic'

import SvgViswal from '../../../assets/images/viswalsClinician.svg'

const VwMainMenu = () => {
  const { layout, route, handleContentClick, dataMainMenuItems } = useLogic()

  return (
    <div className="MainMenuArea">
      <MainMenu
        isOpen={layout.openMainMenu}
        className="vw-mainMennu-containerMainMenu"
      >
        <MainMenuIconTop iconName="Viswals3" imagePath={SvgViswal} />

        <MainMenuContent
          isOpen={layout.openMainMenu}
          routeActive={route}
          handleClick={handleContentClick}
          options={dataMainMenuItems}
        />
      </MainMenu>
    </div>
  )
}

export default VwMainMenu
