import { Card, Select, Upload } from '@viswals/components'
import ExpiryDate from '@images/expiryDate.svg'
import RegistrationCertificate from '@images/registrationCertificate.svg'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import { Container } from './PeriodOfCoverageStyles'
import { daySelect, monthSelect, yearSelect } from './data'
import useLogic from './useLogic'

const StepPeriodOfCoverage = () => {
  const {
    confirm,
    dispatch,
    register,
    clearErrors,
    watch,
    setValue,
    handleSubmit,
    onSubmit,
    getFieldProps,
    verified,
    errors,
  } = useLogic()

  return (
    <Container>
      <div className="vw-periodOfCoverage-header">
        <h2>Period of coverage & Certificate</h2>
        <span>
          Insert below the period of coverage and certificate of current
          insurance
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-periodOfCoverage-cardArea">
          <Card
            isVerified={verified}
            isError={
              !!errors?.issueDateDay ||
              !!errors?.issueDateMonth ||
              !!errors?.issueDateYear ||
              !!errors?.expiryDateDay ||
              !!errors?.expiryDateMonth ||
              !!errors?.expiryDateYear
            }
          >
            <div className="vw-periodOfCoverage-img">
              <img src={ExpiryDate} alt="Select your date of birth" />
            </div>
            <div>
              <p>Period of Current Insurance Policy</p>
              <div className="vw-periodOfCoverage-selectArea form">
                <div className="form-dates">
                  <p>Issue Date</p>
                  <Select
                    placeholder="Day"
                    labelTitle="Select the day"
                    options={daySelect}
                    formRegister={{
                      ...register('issueDateDay', {
                        required: true,
                      }),
                      watch,
                    }}
                    {...getFieldProps('issueDateDay')}
                    placement="top"
                  />
                  <Select
                    labelTitle="Select the month"
                    placeholder="Month"
                    options={monthSelect}
                    formRegister={{
                      ...register('issueDateMonth', {
                        required: true,
                      }),
                      watch,
                    }}
                    {...getFieldProps('issueDateMonth')}
                    placement="top"
                  />
                  <Select
                    labelTitle="Select the year"
                    placeholder="Year"
                    options={yearSelect}
                    formRegister={{
                      ...register('issueDateYear', {
                        required: true,
                      }),
                      watch,
                    }}
                    {...getFieldProps('issueDateYear')}
                    placement="top"
                  />
                </div>
                <div className="form-dates">
                  <p>Expiry Date</p>

                  <Select
                    placeholder="Day"
                    labelTitle="Select the day"
                    options={daySelect}
                    formRegister={{
                      ...register('expiryDateDay', {
                        required: true,
                      }),
                      watch,
                    }}
                    {...getFieldProps('expiryDateDay')}
                    placement="top"
                  />
                  <Select
                    labelTitle="Select the month"
                    placeholder="Month"
                    options={monthSelect}
                    formRegister={{
                      ...register('expiryDateMonth', {
                        required: true,
                      }),
                      watch,
                    }}
                    {...getFieldProps('expiryDateMonth')}
                    placement="top"
                  />
                  <Select
                    labelTitle="Select the year"
                    placeholder="Year"
                    options={yearSelect}
                    formRegister={{
                      ...register('expiryDateYear', {
                        required: true,
                      }),
                      watch,
                    }}
                    {...getFieldProps('expiryDateYear')}
                    placement="top"
                  />
                </div>
              </div>
            </div>
          </Card>

          <Card
            isVerified={verified}
            isError={!!errors?.registrationCertificate}
          >
            <div className="vw-periodOfCoverage-img img2">
              <img src={RegistrationCertificate} alt="Select your Gender" />
            </div>
            <div className="upload">
              <p>Registration Certificate</p>

              <Upload
                placeholder="Upload Certificate"
                formRegister={{
                  ...register(`registrationCertificate`, {
                    required: !watch(`registrationCertificate`)?.fileName,
                    onBlur: () => {
                      clearErrors('registrationCertificate')
                      if (!confirm) {
                        dispatch(setNavigationConfirm(true))
                      }
                    },
                  }),
                  watch,
                  setValue,
                }}
                {...getFieldProps('registrationCertificate')}
              />
            </div>
          </Card>
        </div>
      </form>
    </Container>
  )
}

export default StepPeriodOfCoverage
