import Layout from '@src/components/Layout'
import { Icon } from '@viswals/components'
import {
  LinkAccount,
  VerificationBox,
  VerificationHeader,
  Button,
} from './style'
import VerificationOverview from './components/Overview'
import VerificationSteps from './components/Steps'
import VerificationDocumentData from './components/DocumentData'
import useLogic from './useLogic'
import VerificationItems from './components/VerificationItems'
import OriginalDocument from './components/OriginalDocument'
import PhotoVerification from './components/PhotoVerification'
import QuestionPage from './components/Question'

const UserVerificationPage = () => {
  const {
    itemsList,
    items,
    parseIndex,
    image,
    stepActive,
    itemQuestion,
    updateState,
    quickAction,
    previous,
    questionSave,
    changeQuestionReased,
    submit,
  } = useLogic()

  function getNextLabel() {
    if (stepActive === 2) {
      if (items.find((f) => f.state === 'QUESTION_RAISED')) {
        return 'Raise questions'
      }
      if (items.find((f) => f.state === 'ERROR')) {
        return 'REQUEST FIXES'
      }

      return 'verify user'
    }
    return 'NEXT'
  }

  return (
    <Layout>
      <VerificationBox>
        <div className="item col1 steps">
          <VerificationHeader>
            <VerificationOverview steps={itemsList} />
            <VerificationSteps items={itemsList} stepActive={stepActive} />
          </VerificationHeader>
        </div>
        <div className="item col2">
          <LinkAccount>
            <b>Client account Page</b>
            <Icon name="SwitchRight" />
          </LinkAccount>
        </div>

        {stepActive === 1 && (
          <>
            <div className="item col3">
              <VerificationDocumentData parseIndex={parseIndex} items={items} />
            </div>
            <div className="item col4">
              <OriginalDocument image={image} />
            </div>
          </>
        )}

        {stepActive === 2 && (
          <div className="item photos">
            <div className="photo">
              <PhotoVerification
                type="SELFIE_PHOTO"
                url={itemsList[11].value}
                status={itemsList[11].state}
              />
            </div>
            <div className="photo">
              <PhotoVerification
                url={image as string}
                type="PHOTO"
                status={itemsList[0].state}
              />
            </div>
            <div className="photo">
              <PhotoVerification
                type="SELFIE_VIDEO"
                url={itemsList[12].value}
                status={itemsList[12].state}
              />
            </div>
            <div className="photo">
              <PhotoVerification
                type="PROFILE_PHOTO"
                url={itemsList[13].value}
                status={itemsList[13].state}
              />
            </div>
          </div>
        )}

        <div className="item col5">
          <VerificationItems
            items={items}
            updateState={updateState}
            quickAction={quickAction}
          />
        </div>
        <div className="item col6">
          {stepActive > 1 && (
            <Button type="button" className="prev" onClick={previous}>
              PREVIOUS
            </Button>
          )}
          <Button
            type="button"
            className={getNextLabel().toLowerCase().replace(' ', '_')}
            disabled={!!items.find((f) => !f.state)}
            onClick={submit}
          >
            {getNextLabel()}
          </Button>
        </div>
        {itemQuestion && (
          <div className="item question">
            <QuestionPage
              itemQuestion={itemQuestion}
              changeQuestionReased={changeQuestionReased}
            />
            <div className="buttons">
              <Button
                type="button"
                className="prev"
                onClick={() => questionSave(false)}
              >
                CANCEL
              </Button>
              <Button
                type="button"
                className="save"
                disabled={!itemQuestion.question?.length}
                onClick={() => questionSave(true)}
              >
                SAVE
              </Button>
            </div>
          </div>
        )}
      </VerificationBox>
    </Layout>
  )
}

export default UserVerificationPage
