import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  setNavigationConfirm,
  setNavigationActiveNext,
} from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import useStepsError from '@src/hooks/useStepsError'
import {
  ContactInformation,
  ContactInformationFilter,
  createContactInformation,
  Request,
  retrieveContactInformation,
  updateContactInformation,
} from '@viswals/services'
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'

export type SelectCountryNumberOptionsProps = {
  label: string
  value: string | number
  countryCode?: string
  icon?: string
}

export type FormSubmitProps = ContactInformation & {
  contentPhone?: {
    countryCode?: string
    phoneNumber?: string
  }
}

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm<FormSubmitProps>()

  const { getErrors, getFieldProps } = useStepsError(errors)
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const [isReady, setIsReady] = useState<boolean>(false)
  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const { countries } = useSelector((state: ReducersProps) => state.datas)

  const { loading, setLoading } = useLoading()

  const dataCountries: SelectCountryNumberOptionsProps[] = countries.map(
    (country): SelectCountryNumberOptionsProps => ({
      label: `${country.name ?? 'No Name'}`,
      value: `${country.countryCode ?? '-'}`,
      countryCode: country.countryCode,
      icon: country.icon,
    })
  )

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (next && !loading && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])
  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, info) => {
      if (
        (info.type === 'change' || info.name === 'contentPhone') &&
        !confirm
      ) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  async function onSubmit(data: FormSubmitProps) {
    data = {
      ...data,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
    }
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    const dataToSave: FormSubmitProps = {
      ...data,
      countryCode: Number(data.contentPhone?.countryCode),
      phoneNumber: data.contentPhone?.phoneNumber,
      contentPhone: undefined,
    }
    if (!dataToSave.uuid) {
      await createContactInformation(dataToSave as Request<ContactInformation>)
    } else {
      await updateContactInformation(dataToSave as Request<ContactInformation>)
    }
    subStepDone()
    navigate(nextRoute)
  }

  async function getData() {
    setLoading(true)
    try {
      const request: Request<ContactInformationFilter> = {
        fields: [
          'uuid',
          'title',
          'email',
          'countryCode',
          'phoneNumber',
          'clinicianSurname',
          'clinicianForename',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveContactInformation(request)
      const data = resp?.data?.data ? resp.data.data[0] : null
      if (data) {
        setTimeout(() => {
          reset({
            uuid: data?.uuid,
            title: data?.title,
            email: data?.email,
            contentPhone: {
              countryCode: data?.countryCode,
              phoneNumber: data?.phoneNumber,
            },
            clinicianSurname: data?.clinicianSurname,
            clinicianForename: data?.clinicianForename,
          })
          setLoading(false)
          dispatch(setNavigationActiveNext(true))
        }, 200)
      }
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  return {
    handleSubmit,
    onSubmit,
    verified,
    register,
    watch,
    errors,
    dataCountries,
    getFieldProps,
    setValue,
  }
}

export default useLogic
