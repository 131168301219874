import { CardFileBox } from './style'

type CardFileProps = {
  url: string
}

const CardFile = ({ url }: CardFileProps) => {
  const mp4: boolean = url.split('.mp4').length >= 2

  return (
    <CardFileBox>
      {mp4 && (
        <figure>
          <legend>Vídeo</legend>
        </figure>
      )}
      {!mp4 && (
        <figure>
          <img src={url} alt={url} />
        </figure>
      )}
    </CardFileBox>
  )
}

export default CardFile
