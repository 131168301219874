/* eslint-disable @typescript-eslint/no-explicit-any */
import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useForm} from 'react-hook-form'

import {City, Request, updateCity} from '@viswals/services'
import {Button, Input, Select, SelectedOptionsProps, Toggle,} from '@viswals/components'
import useDatas from '@src/hooks/useDatas'

import {StyledContentCity} from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'

export default function FormUpdateCity() {
    const navigate = useNavigate()

    const {countries} = useDatas()

    const [isError, setIsError] = useState<string>('')

    const {watch, register, handleSubmit, reset, control} = useForm()

    useEffect(() => {
        ;(async () => {
            const getCountryToSave: City = JSON.parse(
                window.localStorage.getItem('cityEdit') as string
            )
            reset(getCountryToSave)
        })()
    }, [])

    async function onSubmit(data: any) {
        const dataToSend: City = {
            name: data.name,
            countryUuid: data.countryUuid,
            uuid: watch('uuid'),
            enabled: data.enabled,
        }

        try {
            setIsError('')
            await updateCity(dataToSend as Request<City>)
            window.localStorage.removeItem('cityEdit')
            navigate('/administration/common/city')
        } catch (error: any) {
            setIsError(error.message)
        }
    }

    return (
        <StyledContentCity className="styled-form">
            <form
                onSubmit={handleSubmit(onSubmit)}
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
            >
                <div className="content-mini-form-box">
                    <HeaderContentCommon
                        name="Edit City"
                        description="Here you can edit city"
                    />
                    <Input label="Name" formRegister={{...register('name')}}/>

                    <Select
                        label="Country"
                        labelTitle="Countries"
                        options={
                            countries.map((value) => ({
                                label: value.name,
                                value: value.uuid,
                                icon: value.icon,
                            })) as SelectedOptionsProps[]
                        }
                        formRegister={{...register('countryUuid'), watch}}
                    />
                    <div className="content-toggle-switch">
                        <Toggle isBig name="enabled" control={control}/>
                        <div className="box-preview">Enable City</div>
                    </div>
                    <Button label="Save" type="submit"/>
                    <div
                        className="content-button-cancell"
                        aria-hidden
                        onClick={() => navigate('/administration/common/city')}
                    >
                        Cancel
                    </div>
                    <div className="boxError">
                        {isError.length > 0 ? `${isError}` : ''}
                    </div>
                </div>
            </form>
        </StyledContentCity>
    )
}
