import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateJob from './form_create'
import FormUpdateJob from './form_update'
import JobTable from './table'

export default function HobbyPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <JobTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateJob />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateJob />
          </Layout>
        }
      />
    </Routes>
  )
}
