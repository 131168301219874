import styled from 'styled-components'

import Worldwide from '@images/cc_map_upload_photo.png'

export const ContainerUploadPhoto = styled.form`
  width: 100%;
  height: 100%;

  padding: 4.3rem 5rem 2.5rem 5.1rem;

  position: relative;

  h1 {
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: var(--gray-100-color);
  }
  p {
    max-width: 25.2rem;
    margin-top: 0.3rem;
    font-size: 1.3rem;
    font-weight: 400;
    font-family: var(--font-primary);
    color: var(--gray-200-color);
    line-height: 2rem;
    margin-bottom: 5.4rem;
  }
  .vw-regis-FloadOptions {
    position: absolute;
    top: 8rem;
    right: 4.9rem;
    z-index: 2;

    cursor: pointer;
    .vw-button-buttonstyle {
      height: 4.2rem;
      border-radius: 0.5rem;
      background-color: var(--info-color);

      font-family: var(--font-primary);
      font-size: 1.2rem;
      font-family: 700;

      padding: 1.3rem 1.9rem 1.2rem 2rem;
    }
    .vw-questionModal-backlog {
      p {
        max-width: 100%;
      }
    }
  }
  article {
    background-image: url(${Worldwide});

    margin-top: -9rem;

    .vw-uploadCircle-FieldUpload {
      top: 50.5%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #1c1c1e;
    }
  }
  .vw-questionModal-description {
    margin-bottom: 03.7rem;
    margin-top: -0.3rem;
  }

  .modalDescription {
    width: 35.9rem;
    line-height: 1.8rem;
    > p {
      font-size: 1.3rem;
      font-weight: 400;
      font-family: var(--font-primary);
      color: var(--gray-200-color);
    }
  }
`

export const BoxUploadBox = styled.div``
