/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

export type BoxScrollProps = {
  item: any
  indexCard: number
  handleScroll: (event: any, indexCard: number) => void
}

const BoxScroll = ({ item, indexCard, handleScroll }: BoxScrollProps) => {
  return (
    <div
      className="descriptionArea"
      onScroll={(event) => handleScroll(event, indexCard)}
    >
      <h2>{item.title}</h2>
      <div className="description">{item.description}</div>
    </div>
  )
}

export default React.memo(
  BoxScroll,
  (prev, next) => prev.indexCard === next.indexCard
)
