import {
  Card,
  Icon,
  Input,
  InputPhoneNumber,
  Select,
  Tooltip,
} from '@viswals/components'
import SelectTitle from '@images/selectTitle.svg'
import ClinicalName from '@images/clinicalName.svg'
import ContactDetails from '@images/contactDetails.svg'

import Container from './ContactInformationStyles'

import titleSelect from './data'
import useLogic from './useLogic'
import MessageContactInfo from './messageContactInfo'

const ContactInformation = () => {
  const {
    handleSubmit,
    onSubmit,
    register,
    watch,
    setValue,
    errors,
    verified,
    dataCountries,
    getFieldProps,
  } = useLogic()
  return (
    <Container>
      <div className="vw-contactInformation-header">
        <h2>Contact Information</h2>
        <span>Input your contact information below</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-contactInformation-cardArea">
          <Card isVerified={verified} isError={Boolean(errors?.title)}>
            <div className="vw-contactInformation-img">
              <img src={SelectTitle} alt="Select your title" />
            </div>
            <div className="vw-contactInformation-cardSelectTitle">
              <p>Tell us and select your title</p>
              <div className="vw-oldForm">
                <Select
                  labelTitle="Select the title"
                  options={titleSelect}
                  placeholder="Select title"
                  icon="Dentist1"
                  formRegister={{
                    ...register('title', {
                      required: true,
                    }),
                    watch,
                  }}
                  placement="top"
                  {...getFieldProps('title')}
                />
              </div>
            </div>
          </Card>

          <Card
            isVerified={verified}
            isError={
              Boolean(errors?.clinicianForename) ||
              Boolean(errors?.clinicianSurname)
            }
          >
            <div className="vw-contactInformation-img img2">
              <img src={ClinicalName} alt="Select your title" />
            </div>
            <div>
              <div className="vw-contactInformation-labelIcon">
                <p className="vw-contactInformation-labelText">
                  Tell us your clinical name
                </p>
                <Tooltip
                  placement="top"
                  overlay={MessageContactInfo}
                  destroyTooltipOnHide
                  trigger={['click', 'hover']}
                  motion={{
                    motionName: 'rc-tooltip-fade',
                  }}
                >
                  <div>
                    <Icon name="Info1" />
                  </div>
                </Tooltip>
              </div>
              <div className="vw-oldForm">
                <Input
                  placeholder="Your name"
                  formRegister={{
                    ...register('clinicianForename', {
                      required: true,
                    }),
                  }}
                  {...getFieldProps('clinicianForename')}
                />
                <Input
                  placeholder="Your surname"
                  formRegister={{
                    ...register('clinicianSurname', {
                      required: true,
                    }),
                  }}
                  {...getFieldProps('clinicianSurname')}
                />
              </div>
            </div>
          </Card>

          <Card
            isVerified={verified}
            isError={Boolean(errors?.countryCode || errors?.phoneNumber)}
          >
            <div className="vw-contactInformation-img img3">
              <img src={ContactDetails} alt="Select your title" />
            </div>
            <div>
              <p>Your contact details</p>
              <div className="vw-oldForm vw-emailAndPhone">
                <Input
                  placeholder="Your email"
                  formRegister={{
                    ...register('email', {
                      required: true,
                    }),
                  }}
                  {...getFieldProps('email')}
                />
                <InputPhoneNumber
                  placeholder="Phone number"
                  options={dataCountries}
                  selectSearch
                  selectLabelTitle="Select a Country"
                  formRegister={{
                    ...register('contentPhone'),
                    watch,
                    setValue,
                  }}
                  {...getFieldProps('contentPhone')}
                />
              </div>
            </div>
          </Card>
        </div>
      </form>
    </Container>
  )
}

export default ContactInformation
