/* eslint-disable @typescript-eslint/no-explicit-any */

import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { Button, Input, Select } from '@viswals/components'
import {
  Request,
  CourseTitle,
  createCourseTitle,
  CourseTitleType,
} from '@viswals/services'

import HeaderContentCommon from '../_components/HeaderContentCommon'

import { StyledContentInstitution } from './styles'

export type localCourseTitleItem = {
  label: string
  value: CourseTitleType
}

export const courseTitleTypes: localCourseTitleItem[] = [
  {
    label: 'Degree',
    value: 'DEGREE',
  },
  {
    label: 'Major',
    value: 'MAJOR',
  },
  {
    label: 'Short',
    value: 'SHORT',
  },
]

export default function FormCreateCourseTitle() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { register, handleSubmit, watch } = useForm()

  async function onSubmit(data: any) {
    const dataToSend: any = {
      name: data.title,
      type: data.type,
      acronym: "ACT4",
      enabled: true,
      area: {
        uuid: data.area
      }
    }

    try {
      setIsError('')
      await createCourseTitle(dataToSend as Request<CourseTitle>)
      navigate('/administration/common/course-title')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentInstitution className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Add new Course Title"
            description="Here you can register on new course title"
          />

          <Input label="Title" formRegister={{ ...register('title') }} />

          <Select
            label="Course Type"
            labelTitle="Course Types"
            placeholder="Select here the type of course"
            options={courseTitleTypes.map((value) => ({
              label: value.label,
              value: value.value,
              icon: '',
              isoCode: '',
            }))}
            formRegister={{ ...register('type'), watch }}
          />

          <div style={{ height: 20 }} />

          <Select
            label="Area"
            labelTitle="Select Area"
            placeholder="Select area"
            options={[
              {
                label: 'Medicine',
                value: '84ce4d70-a442-412b-bf27-06f4544a8661',
                icon: '',
                isoCode: '',
              },
              {
                label: 'Dentistry',
                value: '4beed17b-a38a-4da1-8b26-94d2f1513001',
                icon: '',
                isoCode: '',
              },
              {
                label: 'Psychology',
                value: 'e117dcf1-4acd-499f-80d2-7c868f23d6d0',
                icon: '',
                isoCode: '',
              }
            ]}
            formRegister={{ ...register('area'), watch }}
          />

          <Button label="Create" type="submit" />

          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/course-title')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentInstitution>
  )
}
