import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5.1rem;

  .vw-currentWorkingLocation-header,
  .vw-pastWorkingLocation-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
    .buttonArea {
      display: flex;
      align-items: flex-end;
      &.Verified {
        div {
          &:first-child {
            margin-right: 0;
          }
        }
      }
      .vw-contentButtonAdd {
        button {
          width: 13.5rem;
          height: 4.2rem;
          margin-top: 3.6rem;
          margin-right: 2.5rem;
          padding: 1rem;
          border-radius: 0.5rem;
          background-color: #19aeff;

          span {
            font-family: var(--font-primary);
            font-size: 1.2rem;
            color: #fff;
            padding: 0;
            padding-bottom: 0.2rem;
          }
        }
      }
      .DangerButton {
        button {
          width: 13.5rem;
          height: 4.2rem;
          margin-top: 3.6rem;
          margin-right: 2.5rem;
          padding: 1rem;
          border-radius: 0.5rem;
          background-color: #ff0000;

          span {
            font-family: var(--font-primary);
            font-size: 1.2rem;
            color: #fff;
            padding: 0;
            padding-bottom: 0.2rem;
          }
        }
      }
      .vw-questionModal-backlog {
        h2 {
          font-size: 3.6rem;
          font-weight: 900;
          font-family: var(--font-primary);
          color: var(--gray-100-color);
          margin-bottom: 2.1rem;
        }
      }
    }
  }

  .vw-currentWorkingLocation-cardArea {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .card-item {
      margin-right: 2.5rem;
    }
    .vw-card-cardBox {
      width: 47.4rem;
      padding: 0 2.5rem 5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      &:before {
        border: none;
      }

      .form {
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 1rem;

        .vw-currentWorkingLocation-img {
          height: 24rem;
          padding-top: 4.3rem;
          display: flex;
          justify-content: center;
          align-items: flex-start;

          img {
            width: 20.7rem;
          }
        }
        .vw-currentWorkingTitle {
          margin-top: -10px;
          p {
            width: 100%;
            font-size: 1.4rem;
            font-weight: 700;

            + div {
              margin-top: -0.1rem;
            }
          }

          .vw-select-BoxInput,
          .vw-input-boxInput {
            padding-left: 1.7rem;
          }

          .doubleSelect,
          .doubleInput {
            width: 100%;
            height: 5.2rem;
            display: flex;
            align-items: center;
            background-color: var(--content-color);
            border-radius: 0.5rem;

            > p {
              font-family: var(--font-secondary);
              font-size: 1.4rem;
              color: #798089;
              width: 13.8rem;
              padding-left: 2rem;
              font-weight: 500;
            }
            .vw-select-boxContentInput,
            .vw-input-boxContentInput {
              width: 13rem;
              margin-right: 1.5rem;

              &:nth-child(3) {
                margin-right: 1rem;
              }

              .vw-select-BoxInput,
              .vw-input-boxInput {
                background-color: #292e38;
                padding: 1rem 0.8rem 1rem 0.8rem;
                width: 13rem;
                height: 3.4rem;

                > .vw-select-boxIconRight {
                  width: 1.4rem;
                  min-width: 1.4rem;
                  max-width: 2rem;
                }
              }
              .vw-selectOptions-boxSelectOptions {
                top: -20.3rem;
                right: 0.7rem;
                .vw-selectOptions-boxScrollOptions {
                  height: 36rem;
                  width: 13.4rem;
                }
                .vw-selectOptions-boxSelectOptionsLabel {
                  padding: 2.4rem 0rem 0.5rem 0.8rem;
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Container

export const ContentSafeForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
`
