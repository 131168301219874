import { ListNames } from '@viswals/components-platform'

export interface Country {
  name: string
  lat: number
  lng: number
}

export interface MapControl {
  types: ListNames[]
  countries: Country[]
  status: ListNames[]
}

const mapControl: MapControl = {
  types: ['Person6', 'Dentist1', 'Clinic'],
  countries: [
    {
      name: 'UK',
      lat: 52.13252355919099,
      lng: -1.2000413633746532,
    },
    {
      name: 'FR',
      lat: 46.717417799769905,
      lng: 2.576660213349033,
    },
    {
      name: 'ES',
      lat: 39.478773580324535,
      lng: -3.4438475902955603,
    },
    {
      name: 'DE',
      lat: 51.048437872367174,
      lng: 10.530761711064747,
    },
    {
      name: 'IT',
      lat: 43.093119535776296,
      lng: 12.552246090104065,
    },
    {
      name: 'PT',
      lat: 39.665081294378645,
      lng: -8.387695211590012,
    },
  ],
  status: [
    'SignUps',
    'ArrowRight1',
    'ReEntries',
    'InProgress',
    'Chat',
    'Block',
    'Validated',
    'Award1',
    'ArrowLeft',
  ],
}

export { mapControl }
