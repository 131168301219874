import {
  LivePoolPartial,
  LivePoolPriority,
  LivePoolTotal,
  LinkGroup,
} from '@viswals/components-platform'

import Layout from '@src/components/Layout'
import { useState } from 'react'
import { CurrentDate } from '@src/components/Layout/style'
import moment from 'moment'
import { LinkGroupWrapper } from '@src/components/Layout/UpperBar/style'
import { useNavigate } from 'react-router-dom'
import { partials, priorities, totals } from './data'

import { ContainerUpperCalendarChildren } from '../GlobalAccounts/styles'

import {
  Container,
  TotalWrapper,
  PartialWrapper,
  PartialColumn,
  PriorityWrapper,
} from './style'
import useLogic from './useLogic'

const LivePool = () => {
  const navigate = useNavigate()
  const { cards, activeLink, options } = useLogic()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Layout
      upperBarChilden={
        <ContainerUpperCalendarChildren>
          <div className="leftBox">
            <CurrentDate>{moment().format('D MMMM YYYY')}</CurrentDate>

            <LinkGroupWrapper>
              <LinkGroup
                linkActive={activeLink}
                options={options}
                styleButton="CC_BUTTON"
                handleClick={(value) => navigate(value as string)}
              />
            </LinkGroupWrapper>
          </div>
        </ContainerUpperCalendarChildren>
      }
    >
      <Container>
        <TotalWrapper>
          {totals.map((total, index) => (
            <LivePoolTotal key={index} {...total} />
          ))}
        </TotalWrapper>

        <PartialWrapper>
          {partials.map((partial, index) => (
            <PartialColumn key={index}>
              <LivePoolPartial
                {...{
                  ...partial,
                  isOpen,
                  toggleOpen: () => setIsOpen((s) => !s),
                }}
              />
            </PartialColumn>
          ))}
        </PartialWrapper>

        <PriorityWrapper>
          {priorities.map((priority, index) => {
            const cardsFiltereds = cards.filter(
              (f) => f.state === priority.state
            )

            return (
              <LivePoolPriority
                key={index}
                cards={cardsFiltereds}
                {...{
                  ...priority,
                  isOpen,
                }}
              />
            )
          })}
        </PriorityWrapper>
      </Container>
    </Layout>
  )
}

export default LivePool
