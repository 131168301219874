import styled, { css } from 'styled-components'
import WorldFishPoolBG from '@images/world-map-fish-pool.png'

export const FishPoolContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 4rem;
  background-color: #151517;
  border: 1px solid #7c7c8025;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${WorldFishPoolBG});
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center center;
`

export const FishPoolTitle = styled.div`
  position: absolute;
  top: 4rem;
  left: 4rem;
  font-family: 'Nebula';
  font-size: 1.2rem;
  font-weight: bold;
  color: #32d74b;
`

export const FishPoolCircleBox = styled.div`
  position: relative;
  width: 70rem;
  height: 70rem;
  background-color: #151517;
  border: 1px solid #2c2c2e;
  border-radius: 100%;
  box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`

// START
type FishPoolStartCircleBoxProps = {
  disabled: boolean
}
export const FishPoolStartCircleBox = styled.div<FishPoolStartCircleBoxProps>`
  position: absolute;
  bottom: -8rem;
  width: 14.5rem;
  height: 14.5rem;
  background-color: #151517;
  border: 1px solid #2c2c2e;
  border-radius: 100%;
  box-shadow: 0px 1rem 2rem rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  cursor: pointer;

  div {
    width: 100%;
    height: 100%;
    border: 1px solid #5adf70;
    border-radius: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      width: 5.5rem;
      padding-bottom: 2rem;
    }
    b {
      margin-top: 2.5rem;
      background: -webkit-linear-gradient(#008d57, #00ff76);
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 11px;

      letter-spacing: 0.3rem;
    }
  }

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
        div {
          opacity: 0.1;
        }
      `
    }
    return css`
      &:hover {
        opacity: 0.7;
      }
    `
  }}
`

type FishPoolLineCircleBoxProps = {
  color: string
}

export const FishPoolLineCircleBox = styled.div<FishPoolLineCircleBoxProps>`
  position: absolute;
  content: ' ';
  border-radius: 100%;
  width: 54rem;
  height: 54rem;
  border: 1px solid ${({ color }) => color}10;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;

  .line {
    width: 100%;
    height: 100%;
    position: relative;
    content: ' ';
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    padding: 2rem;

    &.l1 {
      border: 1px solid ${({ color }) => color}20;
    }
    &.l2 {
      border: 1px solid ${({ color }) => color}30;
    }
    &.l3 {
      border: 1px solid ${({ color }) => color}40;
    }
  }
`

export const EmptyPoolBox = styled.div`
  width: 38rem;
  height: 38rem;
  background-color: #151517;
  border-radius: 100%;
  padding: 2rem;

  box-shadow: 0px 0rem 6.5rem #8e8e93;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  strong {
    font-family: 'Nebula';
    font-size: 1.5rem;
    color: #ffb23b;
    margin-top: 2.4rem;
  }
  b {
    font-family: 'Oxanium';
    font-size: 1rem;
    color: #7c7c80;
    margin-top: 0.8rem;
  }
`
