import { useCallback, useEffect, useRef, useState } from 'react'
import Config from '@src/services/api/config'
import { LivePoolWSProps } from '@src/types/maps'
import useDatas from '@src/hooks/useDatas'
import { useLocation } from 'react-router-dom'
import { LinkProps } from '@viswals/components-platform'

const useLogic = () => {
  const location = useLocation()
  const { countries, languages } = useDatas()
  const wssUrl = `${Config.WS_URL}/patient/step-status/ws/retrieve`
  const ws = useRef<any>(null)
  const token = localStorage.getItem('access_token')
  const [cards, setCards] = useState<any[]>([])

  useEffect(() => {
    ws.current = new WebSocket(`${wssUrl}?access_token=${token}`)
    ws.current.onopen = () => console.log(`connected websocket   ${wssUrl}`)
    const wsCurrent = ws.current
    return () => wsCurrent.close()
  }, [])

  useEffect(() => {
    if (!ws.current) return
    ws.current.onmessage = ({ data }: any) => addWS(data)
  }, [cards])

  async function addWS(data: string) {
    const infoWS: LivePoolWSProps = JSON.parse(data)
    console.log({ infoWS })
    const newCard = await createCard(infoWS)
    setCards((oldCards) => [
      ...oldCards.filter((f: any) => f.uuid !== newCard.uuid),
      newCard,
    ])
  }

  function getLanguageName(countryUuid: string) {
    const findIsoCode = countries.find((f) => f.uuid === countryUuid)?.isoCode
    const find = languages.find((f) => f.isoCode === findIsoCode)
    return find?.name ?? '-'
  }

  function createCard(info: LivePoolWSProps): any {
    return {
      uuid: info.uuid,
      name: `${info.firstName} ${info.lastName}`,
      photo: info.profileVerificationPhoto.photo,
      skin: getLanguageName(info.countryUuid),
      state: info.state,
      tagIcon: 'Dentist1',
      tagDescription: 'S1',
      startDate: '2022-08-20 13:00:00',
      openDate: '2022-08-22 12:00:00',
      color: '#C1EDE8',
      status: false,
      statusIcon: 'Message1',
      statusColor: '#ff9f0a',
      progressPercent: 0,
      showSituationTag: false,
    }
  }
  const options: LinkProps[] = [
    {
      value: '/global-view',
      title: 'Global View',
      icon: 'GlobalView',
    },
    {
      value: '/global-accounts',
      title: 'Global Accounts',
      icon: 'GlobalView',
    },
    {
      value: '/livepool',
      title: 'Live Pool',
      icon: 'Roadmap',
    },
  ]

  const useRouterCallback = useCallback(() => {
    return options.find((f) => f.value === location.pathname)?.value
  }, [location])

  const activeLink = useRouterCallback()

  return { cards, options, activeLink }
}

export default useLogic
