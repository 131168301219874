import {
  Diary,
  DiaryFilter,
  Request,
  retrieveDiary,
  updateDiary as upDiary,
  DiaryRelation,
  LandingOnType,
} from '@viswals/services'
import { ReducersProps } from '@src/types/reducers'
import { useDispatch, useSelector } from 'react-redux'
import {
  setActionCustomizeDiary,
  setActionCustomizeDiaryLandingOn,
  setActionCustomizeDiaryTemp,
  setActionDiaryUpperCalendarSelect,
  setActionWebSocket,
} from '@src/store/reducers/diary/actions'
import useLoading from './useLoading'

const useDiary = () => {
  const dispatch = useDispatch()

  const { setLoading } = useLoading()
  const { clinician, diary } = useSelector((state: ReducersProps) => state)

  async function getDiary() {
    const request: Request<DiaryFilter, DiaryRelation> = {
      fields: [
        'uuid',
        'weekStart',
        'timeStyle',
        'numberHours',
        'firstHour',
        'landingOn',
        'eventColor',
        'entityUuid',
        'entityType',
      ],
      relations: {
        diaryCardContent: {
          fields: [
            'uuid',
            'patientPhoto',
            'patientStatus',
            'fieldAppointment',
            'natureAppointment',
            'lengthAppointment',
            'typeAppointment',
            'clinicianNote',
            'liveUpdate',
          ],
        },
        diaryCardColor: {
          fields: [
            'uuid',
            'theme',
            'newPatient',
            'existingPatient',
            'diagnostics',
            'treatments',
            'finalReviews',
            'active',
          ],
        },
      },
      filters: {
        entityUuid: clinician?.clinician?.uuid,
      },
    }

    try {
      const responseApi = await retrieveDiary(request)

      const dataDiary: Diary = responseApi.data.data[0]

      dispatch(setActionCustomizeDiary(dataDiary))
      dispatch(setActionCustomizeDiaryLandingOn(dataDiary.landingOn))
    } catch (e) {
      console.error(e)
    }
  }

  function updateDiary(diaryUpdate: Diary, temp = false) {
    if (temp) {
      updateLandingOn(diaryUpdate.landingOn)
      dispatch(setActionCustomizeDiaryTemp(diaryUpdate))
      return null
    }

    setLoading(true)

    dispatch(setActionCustomizeDiary(diaryUpdate))

    upDiary(diaryUpdate as Request<Diary>).then(() => {
      setLoading(false)
    })
  }

  function updateLandingOn(landingOn?: LandingOnType, upperCalendarStartSelect?: string, upperCalendarEndSelect?: string) {
    dispatch(setActionCustomizeDiaryLandingOn(landingOn, upperCalendarStartSelect, upperCalendarEndSelect))
  }

  function updateWebSocket(connected?: boolean) {
    dispatch(setActionWebSocket(connected))
  }

  function updateUpperCalendarSelect(
    upperCalendarStartSelect?: string,
    upperCalendarEndSelect?: string
  ) {
    dispatch(
      setActionDiaryUpperCalendarSelect(
        upperCalendarStartSelect,
        upperCalendarEndSelect
      )
    )
  }

  return {
    clinician,
    diary,
    getDiary,
    updateDiary,
    updateLandingOn,
    updateUpperCalendarSelect,
    updateWebSocket,
  }
}

export default useDiary
