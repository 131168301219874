/* eslint-disable @typescript-eslint/no-explicit-any */
import { RegistrationProp } from '@src/store/reducers/clinician/props'
import { ClinicianStepsProps } from '@src/types/stepsClinician'

function calcPercent(subSteps: any, registrationActive: RegistrationProp) {
  if (!registrationActive?.countryRegistration) {
    return 0
  }
  // const totalSubSteps = subSteps?.length ?? 0
  // const startedSubsteps = 0

  // const calc = (startedSubsteps / totalSubSteps) * 100
  return Math.ceil(0)
}

// function checkStatusSubStep(register: any) {
//   if (register?.errors?.length) {
//     return 'not-correct'
//   }

//   if (register?.verified) {
//     return 'verify'
//   }
//   if (register?.started) {
//     return 'done'
//   }

//   return null
// }

function checkStep(
  subSteps: any,
  registrationActive: RegistrationProp,
  percent: number
) {
  const errors = 0

  if (errors) {
    return 'not-correct'
  }

  if (percent === 100) {
    return 'done'
  }

  return 'normal'
}

function stepsListClinician(
  stepsReceived: any,
  registrationActive: RegistrationProp
) {
  if (!stepsReceived.length) {
    return []
  }
  const percents: number[] = []
  // @ts-ignore
  const stepList = stepsReceived?.map((step, index) => {
    const percent = calcPercent(step.subSteps as any, registrationActive)
    percents.push(percent)

    return {
      ...step,
      id: index + 1,
      label: step.label,
      status: checkStep(step?.subSteps, registrationActive, percent),
      route: `step${index + 1}`,
      percent,
      subSteps: [],
    }
  })
  return stepList as ClinicianStepsProps[]
}
export { stepsListClinician, calcPercent }
