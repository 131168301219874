import { RegistrationModeEnum } from '@viswals/services'

type registrationCardProps = {
  uuid?: string
  img1?: string
  img2?: string
  category?: string
  category2?: string
  description?: string
  priceMonth?: number
  percent1?: string
  percent2?: string
  percent1b?: string
  percent2b?: string
  percentTitle1?: string
  percentTitle1b?: string
  percentTitle2?: string
  percentTitle2b?: string
  percentTitle2c?: string
  percentDescription1?: string
  percentDescription2?: string
  mode?: RegistrationModeEnum
}

const registrationCard: registrationCardProps[] = [
  {
    uuid: '01',
    img1: require('@images/freelancer.svg').default,
    category: 'Viswals Freelancer',
    category2: 'Viswals Freelancer',
    description:
      'For Oral Health Professionals who wish to pursue exclusive work in Freelance Dentistry, set themselves free from employers and work at their own pace',
    priceMonth: 0,
    percent1: '80',
    percentTitle1: 'Your Commission',
    percentTitle1b: 'Your Commission',
    percentDescription1:
      'Full flexibility and a take away 80% commission on all appointments and treatments performed as a freelancer',

    percent2: '20',
    percentTitle2: 'Viswals Commission',
    percentTitle2b: 'Viswals Commission',
    percentDescription2:
      'The commission charged by Viswals on all appointments and  treatments that you perform as a freelancer',
    mode: 0,
  },
  {
    uuid: '02',
    img1: require('@images/inTeam.svg').default,
    category: 'Viswals In Team',
    category2: 'Viswals In Team',
    description:
      ' For Oral Health Professionals who practice as owners, partners or associates within dental clinics and their teams ',

    priceMonth: 0,
    percent1: '40',
    percentTitle1: 'Average Associate Commission',
    percentTitle1b: 'Your Commission',
    percentDescription1:
      'Dental associates take a 35-45% average commission depending on the clinic, employer, clinical expertise, experience, etc',

    percent2: '60',
    percentTitle2: 'Average Clinic Commission',
    percentTitle2b: 'Average Clinic Commission',
    percentDescription2:
      'Dental Clinics take a 55-65% average commission depending on their business model, financial comfort, location, etc',
    mode: 1,
  },
  {
    uuid: '03',
    img1: require('@images/freelancer.svg').default,
    img2: require('@images/inTeam.svg').default,
    category: 'Viswals Freelancer & Viswals In Team',
    category2: 'Viswals Freelancer & In Team',
    description:
      ' For Oral Health Professionals who are owners, partners or associates of dental clinics, however still wanting to perform freelance dentistry ',

    priceMonth: 0,
    percent1: '80',
    percent1b: '20',
    percentTitle1: 'Freelancer-Viswals Commission Share',
    percentTitle1b: 'Your Commission',
    percentDescription1:
      'The ratio of commission shared between yourself (80%) as a Freelancer dental professional and Viswals (20%)',

    percent2: '40',
    percent2b: '60',
    percentTitle2: 'Associate-Clinic Commission Share',
    percentTitle2b: 'Viswals Commission',
    percentTitle2c: 'Average Clinic Commission',
    percentDescription2:
      'The average ratio of commission shared between yourself (40%) and the average Dental Clinic (60%)',
    mode: 2,
  },
]

const plansItemsData = [
  '24hr access to the Viswals Platform',
  'ViswalsApp Pages (Up to 14 Skins)',
  'Working days subjacent to Clinic´s Diary',
  'Freelancer Independent Diary',
  'Perform Freelance Dentistry',
  'Full access to Live X & Live Now',
  '24hr & global access to Live X & Live Now',
  'Earn as you perform, bank out when you wish',
]

const plansItemsCheck = [
  [true, true, false, true, true, true, true, true],
  [true, true, true, false, false, true, false, false],
  [true, true, true, true, true, true, true, true],
]
export { registrationCard, plansItemsData, plansItemsCheck }
