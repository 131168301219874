export type dataMenuAdministrationTypes = {
  label: string
  path: string
}

export const dataMenuAdministration: dataMenuAdministrationTypes[] = [
  {
    label: 'Countries',
    path: '/administration/common/country',
  },
  {
    label: 'Cities',
    path: '/administration/common/city',
  },
  {
    label: 'Banks',
    path: '/administration/common/bank',
  },
  {
    label: 'Languages',
    path: '/administration/common/language',
  },
  {
    label: 'Institutions',
    path: '/administration/common/institution',
  },
  {
    label: 'Currency',
    path: '/administration/common/currency',
  },
  {
    label: 'Course Titles',
    path: '/administration/common/course-title',
  },
  {
    label: 'Regulatory Body',
    path: '/administration/common/regulatory-body',
  },
  {
    label: 'Insurance Companies',
    path: '/administration/common/insurance-company',
  },
  {
    label: 'Hobbies',
    path: '/administration/common/hobby',
  },
  {
    label: 'Sports',
    path: '/administration/common/sport',
  },
  {
    label: 'Occupations',
    path: '/administration/common/occupation',
  },
  {
    label: 'Jobs',
    path: '/administration/common/job',
  },
  {
    label: 'Diseases',
    path: '/administration/common/disease',
  },
  {
    label: 'Oral Health Problems',
    path: '/administration/common/oral-health-problem',
  },
  {
    label: 'Drugs',
    path: '/administration/common/drug',
  },
  {
    label: 'Foods',
    path: '/administration/common/food',
  },
  {
    label: 'Allergies',
    path: '/administration/common/allergy',
  },
  {
    label: 'Fields of Dentistry',
    path: '/administration/common/field-dentistry',
  },
  {
    label: 'Fields of Medicine',
    path: '/administration/common/field-medicine',
  },
  {
    label: 'Fields of Psychology',
    path: '/administration/common/field-psychology',
  }
]
