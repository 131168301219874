import styled, { css } from 'styled-components'

export type ContainerWhereCoursesProps = {
  city?: boolean
}

export const ContainerWhereCourses = styled.div<ContainerWhereCoursesProps>`
  width: 26.3rem;
  padding-right: 3.5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border-right: 1px solid #333843;

  h3 {
    color: #bebebe;
    font-size: 1.6rem;
    font-family: var(--font-primary);
    font-weight: 700;
  }

  img {
    margin-top: 2.7rem;
  }
  p {
    width: 100%;
    margin-top: 2.9rem;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: #bebebe;
    padding-left: 0.1rem;
  }
  .vw-regis-safeArea {
    width: 100%;
    margin: 0.6rem 0 0 0.1rem;
    .vw-regis-safeAreaItems {
      width: 100%;
      height: 3.4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #333843;
      margin-bottom: 1rem;
      border-radius: 0.5rem;
      font-size: 1rem;
      font-family: var(--font-secondary);
      color: #798089;
      cursor: pointer;
      input {
        display: none;
      }
      &.checked {
        background-color: var(--info-color);
        color: white;
        font-weight: 700;
      }
      :last-of-type {
        margin-bottom: 0px;
      }
    }
  }

  .paragraphFirst {
    width: 100%;
    margin-top: 5.9rem;
    padding-left: 0.1rem;
    font-size: 1.1rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: #bebebe;
    ${(props) => {
      if (props.city === true) {
        return css`
          margin-top: 1.6rem;
        `
      }
    }}
  }

  .vw-contentMiniForm-select {
    width: 100%;
    margin-top: 0.5rem;
    margin-left: 0.1rem;
    /* background-color: tan; */
  }
`

export const B = ''

export const ContentMiniForm = styled.div`
  .vw-input-boxContentInput {
    .vw-input-boxInput {
      height: 3.4rem;
    }
    &:last-of-type {
      margin-top: 1rem;
    }
  }
  .vw-select-boxContentInput {
    .vw-select-BoxInput {
      height: 3.4rem;
      padding-left: 0.8rem;
      .vw-select-inputFieldStyle {
        font-size: 1rem;
        line-height: 1.3rem;
        /* padding-top: 0.1rem; */
      }
      .vw-select-boxIconRight {
        width: 3rem;
        max-width: 3rem;
        min-width: 3rem;
        padding: 0;
        svg {
          width: 1rem;
          margin-top: 0.4rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
  .divisor {
    height: 1rem;
  }
  .smallCity {
    .vw-input-boxContentInput {
      .vw-input-boxInput {
        padding-left: 0.8rem;
        input {
          font-size: 1rem;
        }
      }
    }
  }
`
