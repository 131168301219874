import styled from 'styled-components'

// eslint-disable-next-line import/prefer-default-export
export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-residenceInformation-header {
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-residenceInformation-cardArea {
    display: flex;
    .vw-card-cardBox {
      width: 48.4rem;
      padding: 0 2.5rem 5rem;
      margin-right: 2.5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .vw-residenceInformation-img {
        height: 40rem;
        padding-top: 8.4rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
          width: 26.3rem;
        }
        &.img2 {
          padding-top: 7.7rem;
          padding-left: 0.5rem;

          img {
            width: 30.4rem;
          }
        }

        &.img3 {
          padding-top: 8.8em;

          img {
            width: 15.5rem;
          }
        }
      }

      p {
        font-family: var(--font-primary);
        font-weight: 700;
        font-size: 1.4rem;
        text-align: start;

        margin-bottom: 1rem;
      }

      .vw-residenceInformation-oldForm {
        .vw-input-boxContentInput,
        .vw-select-boxContentInput {
          margin-bottom: 1rem;
        }
      }

      .vw-residenceInformation-formCard2 {
        .vw-input-boxContentInput,
        .vw-select-boxContentInput {
          margin-bottom: 1rem;
        }
      }
    }
  }
`
