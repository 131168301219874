import BarProgress from '@src/components/BarProgress'
import { OverviewBox } from './style'
import { VerificationItemProps } from '../../type'

type VerificationOverviewProps = {
  steps: VerificationItemProps[]
}

const VerificationOverview = (props: VerificationOverviewProps) => {
  const totalSteps = props.steps.length
  const totalCompleted = props.steps.filter((f) => !!f.state).length
  const percent = Math.ceil((totalCompleted / totalSteps) * 100)

  const firstName = props?.steps[4] ? props?.steps[4].value : ''
  const lastName = props?.steps[5] ? props?.steps[5].value : ''

  return (
    <OverviewBox>
      <div className="title">Overview</div>
      <div className="details">
        <b>{`${firstName} ${lastName}`}</b>
        <div className="details-percent">
          <span>P. </span>
          <span className={percent > 0 ? 'color' : ''}>{percent}%</span>
        </div>
      </div>
      <div className="progress">
        <div>Certification Progress</div>
        <div>
          <BarProgress percent={percent} />
        </div>
      </div>
    </OverviewBox>
  )
}

export default VerificationOverview
