import { Routes, Route } from 'react-router-dom'
import Layout from '../layout'
import FormCreateCurrency from './form_create'
import FormUpdateCurrency from './form_update'
import CurrencyTable from './table'

export default function CurrencyPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <CurrencyTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateCurrency />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateCurrency />
          </Layout>
        }
      />
    </Routes>
  )
}
