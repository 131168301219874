import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  Country,
  OralHealthProblem,
  OralHealthProblemFilter,
  PaginationRequest,
  Request,
  retrieveOralHealthProblem,
} from '@viswals/services'
import gsap from 'gsap'
import { Button } from '@viswals/components-platform'
import HeaderContentCommon from '../_components/HeaderContentCommon'
import {
  StyledContentContryTable,
  StyledContentOralHealthProblem,
} from './styles'
import gridStyle from '../_components/grid-style'
import { LoadingTable } from '../_components/loading-table'

export type LocalOralHealthProblemAdm = {
  id?: number
} & OralHealthProblem

export type LocalPaginationRequestProps = {
  total?: number
} & PaginationRequest

export default function OralHealthProblemTable() {
  const navigate = useNavigate()

  const [oralHealthProblems, setOralHealthProblems] = useState<
    LocalOralHealthProblemAdm[]
  >([])
  const [pagination, setPagination] = useState<LocalPaginationRequestProps>({
    itemsPerPage: 25,
    page: 1,
    total: 0,
  })

  useEffect(() => {
    ;(async () => {
      await getLocalOralHealthProblems(pagination)
    })()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      flex: 1,
    },

    {
      field: 'groupName',
      headerName: 'Group',
      width: 200,
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      renderCell: (params) => {
        const countryToSave: Country = params.row
        return <>{countryToSave.enabled ? 'YES' : 'NO'}</>
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', gap: 20 }}>
            <Button
              label="Edit"
              uuid=""
              style={{ backgroundColor: 'orange', color: 'black' }}
              handleClick={() => {
                const oralHealthProblemToSave: OralHealthProblem = params.row
                window.localStorage.setItem(
                  'oralHealthProblemEdit',
                  JSON.stringify(oralHealthProblemToSave)
                )
                navigate(
                  `/administration/common/oral-health-problem/edit/${oralHealthProblemToSave.uuid}`
                )
              }}
            />
          </div>
        )
      },
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
    },
  ]

  async function getLocalOralHealthProblems(
    paginationed: LocalPaginationRequestProps | undefined
  ) {
    try {
      await getOralHealthProblems(paginationed)
    } catch (error) {
      console.error({
        error,
      })
    }
  }

  async function getOralHealthProblems(
    paginationed: LocalPaginationRequestProps | undefined
  ) {
    const request: Request<OralHealthProblemFilter> = {
      fields: ['uuid', 'title', 'groupName', 'enabled'],
      pagination: {
        itemsPerPage: 25,
        page: paginationed?.page as number,
      },
      orderBy: {
        column: 'title',
        direction: 'asc',
      },
    }
    try {
      const resp = await retrieveOralHealthProblem(request)
      const { data } = resp

      if (data.code === 200) {
        const dataCities: OralHealthProblem[] = data.data

        const newCities: LocalOralHealthProblemAdm[] = dataCities.map(
          (value, _index) => {
            return {
              id: _index,
              uuid: value.uuid,
              title: value.title,
              groupName: value.groupName,
              enabled: value?.enabled ?? false,
            }
          }
        )
        setOralHealthProblems(newCities)

        setTimeout(() => {
          setPagination(
            (value) =>
              ({
                ...value,
                ...{
                  itemsPerPage: paginationed?.itemsPerPage,
                },
                total: data.pagination
                  ? data.pagination.total
                  : data.data.length,
              } as LocalPaginationRequestProps)
          )
        }, 200)
        disabledLoading()
      }
    } catch (e) {
      console.error(e)
    }
  }

  function handleClickAdd() {
    navigate('/administration/common/oral-health-problem/new')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function addPage(page: number) {
    const pageToSet: PaginationRequest = {
      itemsPerPage: 25,
      page: page + 1,
    }
    try {
      await getLocalOralHealthProblems(pageToSet)
    } catch (error) {
      console.error({
        error,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function enabledLoading() {
    const query = window.document.querySelector('.vw-loadingContainer')
    gsap.to(query, {
      zIndex: 99,
      duration: 0.1,
      onComplete: () => {
        gsap.to(query, {
          opacity: 1,
          delay: 0.1,
          duration: 0.25,
        })
      },
    })
  }

  function disabledLoading() {
    setTimeout(() => {
      const query = window.document.querySelector('.vw-loadingContainer')
      gsap.to(query, {
        opacity: 0,
        duration: 0.25,
        onComplete: () => {
          gsap.to(query, {
            zIndex: -1,
            delay: 0.25,
            duration: 0.25,
          })
        },
      })
    }, 300)
  }

  return (
    <StyledContentOralHealthProblem>
      <HeaderContentCommon
        name="Oral Health Problem"
        description="Manager Oral Health Problem"
        onClickAdd={() => handleClickAdd()}
      />

      <StyledContentContryTable>
        <LoadingTable />
        <DataGrid
          sx={gridStyle}
          rows={oralHealthProblems}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          experimentalFeatures={{ newEditingApi: false }}
          onPageChange={(page) => {
            enabledLoading().then(() => {
              setTimeout(() => {
                addPage(page)
              }, 200)
            })
          }}
          pagination
          rowsPerPageOptions={[25]}
          rowCount={pagination.total}
          initialState={{
            pagination: {
              pageSize: pagination.itemsPerPage,
            },
          }}
          paginationMode="server"
        />
      </StyledContentContryTable>
    </StyledContentOralHealthProblem>
  )
}
