/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'

import { Button, Card, QuestionModal } from '@viswals/components'
import SubstepBigPagination from '@src/components/pagination/SubstepBigPagination'

import transformToSelectOptions from '@src/core/transformToSelectOptions'
import {
  dataContinents,
  dataProviderCourses,
  dataShortCourseDuration,
  dataStudyMode,
} from '../common/data'

import WhatCourses from '../subcomponents/What'
import WhereCourses from '../subcomponents/Where'
import WithWhomCourses from '../subcomponents/WithWhom'
import LengthOfStudyCourses from '../subcomponents/LengthOfStudy'
import WhatsYourTitleCourses from '../subcomponents/WhatsYourTitle'

import {
  BoxContentCards,
  ContainerMajorCourses,
} from '../major-course/MajorCourseStyles'
import useLogic from './useLogic'

const StepShortCourses = () => {
  const {
    cities,
    isModalOpen,
    coursesTitle,
    institutions,
    countries,
    fields,
    selectDelete,
    selectedIndex,
    handleOpenModel,
    setSelectedIndex,
    handleConfirmModelDelete,
    handleCancelModelDelete,
    onSubmit,
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    dataFieldDentistry,
  } = useLogic()

  return (
    <ContainerMajorCourses>
      <h1>Short Courses</h1>
      <div className="vw-regis-headerButtons">
        <div className="vw-regis-boxLeft">
          <SubstepBigPagination
            label="Short Course"
            active={selectedIndex}
            onChangeActive={(index) => {
              setSelectedIndex(index)
            }}
            data={fields as any[]}
          />
        </div>
        <div className="vw-regis-boxRight short">
          {selectDelete !== null ? (
            <div className="vw-regist-buttonDanger">
              <Button
                icon="Remove1"
                label="Delete Short Course"
                styleType="danger"
                handleClick={handleOpenModel}
              />
              {isModalOpen ? (
                <QuestionModal
                  title="Delete Short Course"
                  description="You are about to delete a Short Course. This action cannot be undone. Are you sure you want to proceed?"
                  cancelLabel="Oops, take me back"
                  cancelFunction={handleCancelModelDelete}
                  confirmLabel={`Yes, delete Short Course ${selectedIndex + 1}`}
                  confirmFunction={handleConfirmModelDelete}
                />
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <fieldset disabled>
          <BoxContentCards selectedIndex={selectedIndex}>
            {fields?.map((shortCourse, indexShortCourse) => {
              const countryUuid = watch(`data.${indexShortCourse}.country`)

              const citiesList = transformToSelectOptions({
                array: cities.filter((f) => f.countryUuid === countryUuid),
                keyLabel: 'name',
                keyValue: 'uuid',
              })

              return (
                <div
                  key={shortCourse.id}
                  className={`card-item item-${indexShortCourse}`}
                >
                  <input
                    type="hidden"
                    {...register(`data.${indexShortCourse}.destroy`, {
                      value: false,
                    })}
                  />
                  <Card readyDelete={Boolean(selectedIndex === selectDelete)}>
                    <div className="innerCardBox">
                      {/* Where Information */}
                      <WhereCourses
                        continents={dataContinents as any[]}
                        countries={countries}
                        register={register}
                        watch={watch}
                        keyIndex={indexShortCourse}
                        setValue={setValue}
                        city
                        cities={citiesList}
                      />
                      {/* With Whom Information */}
                      <WithWhomCourses
                        keyIndex={indexShortCourse}
                        watch={watch}
                        register={register}
                        setValue={setValue}
                        courseProviders={dataProviderCourses}
                        institutions={institutions(indexShortCourse)}
                      />
                      {/* What Course */}
                      <WhatCourses
                        keyIndex={indexShortCourse}
                        watch={watch}
                        register={register}
                        fieldsOfDentistry={dataFieldDentistry}
                        type="short"
                        setValue={setValue}
                      />
                      {/* Length of Study Information */}
                      <LengthOfStudyCourses
                        keyIndex={indexShortCourse}
                        watch={watch}
                        register={register}
                        setValue={setValue}
                        getValues={getValues}
                        dataDuration={dataShortCourseDuration}
                        studyModes={dataStudyMode}
                        underCompletion={false}
                      />
                      {/* What's Your Title Information */}
                      <WhatsYourTitleCourses
                        keyIndex={indexShortCourse}
                        watch={watch}
                        register={register}
                        setValue={setValue}
                        type="short"
                        coursesTitle={coursesTitle}
                        isCourseUnderCompletion={false}
                      />
                    </div>
                  </Card>
                </div>
              )
            })}
          </BoxContentCards>
        </fieldset>
      </form>
    </ContainerMajorCourses>
  )
}

export default StepShortCourses
