import { useEffect, useState } from 'react'
import gsap from 'gsap'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  Country,
  CountryFilter,
  PaginationRequest,
  Request,
  retrieveCountry,
} from '@viswals/services'
import { Button } from '@viswals/components-platform'

import dataContinents from '@src/store/continents'

import HeaderContentCommon from '../_components/HeaderContentCommon'
import { StyledContentContry, StyledContentContryTable } from './styles'
import gridStyle from '../_components/grid-style'
import { LoadingTable } from '../_components/loading-table'

export type LocalCountriesAdm = {
  id?: number
} & Country

export type LocalPaginationRequestProps = {
  total?: number
} & PaginationRequest

export default function CountryTable() {
  const navigate = useNavigate()
  const [countries, setCountries] = useState<LocalCountriesAdm[]>([])
  const [pagination, setPagination] = useState<LocalPaginationRequestProps>({
    itemsPerPage: 25,
    page: 1,
    total: 0,
  })

  useEffect(() => {
    ;(async () => {
      await getCountries(pagination)
    })()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'countryCode',
      headerName: 'Country Code',
      width: 140,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'icon',
      headerName: 'Icon',
      width: 140,
      renderCell: (params) => {
        return <img src={params.value} width="25px" alt="Desc" />
      },
      headerAlign: 'center',
      align: 'center',
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'isoCode',
      headerName: 'ISOCode',
      sortable: false,
    },
    {
      field: 'continent',
      headerName: 'Continent',
      renderCell: (params) => {
        const findContinent = dataContinents.find(
          (v) => v.uuid === String(params.value)
        )
        return <>{findContinent?.name}</>
      },
      sortable: false,
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      renderCell: (params) => {
        const countryToSave: Country = params.row
        return <>{countryToSave.enabled ? 'YES' : 'NO'}</>
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', gap: 20 }}>
            <Button
              label="Edit"
              uuid=""
              style={{ backgroundColor: 'orange', color: 'black' }}
              handleClick={() => {
                const countryToSave: Country = params.row
                window.localStorage.setItem(
                  'countryEdit',
                  JSON.stringify(countryToSave)
                )
                navigate(
                  `/administration/common/country/edit/${countryToSave.uuid}`
                )
              }}
            />
          </div>
        )
      },
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
    },
  ]

  async function getCountries(
    paginationed: LocalPaginationRequestProps | undefined
  ) {
    try {
      const request: Request<CountryFilter> = {
        fields: [
          'uuid',
          'name',
          'icon',
          'isoCode',
          'countryCode',
          'latitude',
          'longitude',
          'continent',
          'enabled',
          'updatedAt',
          'createdAt',
        ],
        orderBy: {
          column: 'name',
          direction: 'asc',
        },
        pagination: {
          itemsPerPage: 25,
          page: paginationed?.page as number,
        },
      }
      const resp = await retrieveCountry(request)
      const dataCountries: Country[] = resp.data.data

      const newCountries: LocalCountriesAdm[] = dataCountries.map(
        (value, _index) => ({
          id: _index,
          uuid: value.uuid,
          icon: value.icon,
          countryCode: value.countryCode,
          name: value.name,
          isoCode: value.isoCode,
          latitude: value.latitude,
          longitude: value.longitude,
          continent: value.continent,
          enabled: value.enabled,
        })
      )
      disabledLoading()

      setCountries(newCountries)

      setTimeout(() => {
        setPagination(
          (value) =>
            ({
              ...value,
              ...{
                itemsPerPage: paginationed?.itemsPerPage,
              },
            } as LocalPaginationRequestProps)
        )
      }, 200)
    } catch (e) {
      console.error(e)
      disabledLoading()
    }
  }

  async function addPage(page: number) {
    const pageToSet: PaginationRequest = {
      itemsPerPage: 25,
      page: page + 1,
    }
    try {
      await getCountries(pageToSet)
    } catch (error) {
      console.error({
        error,
      })
    }
  }

  async function enabledLoading() {
    const query = window.document.querySelector('.vw-loadingContainer')
    gsap.to(query, {
      zIndex: 99,
      duration: 0.1,
      onComplete: () => {
        gsap.to(query, {
          opacity: 1,
          delay: 0.1,
          duration: 0.25,
        })
      },
    })
  }

  function disabledLoading() {
    setTimeout(() => {
      const query = window.document.querySelector('.vw-loadingContainer')
      gsap.to(query, {
        opacity: 0,
        duration: 0.25,
        onComplete: () => {
          gsap.to(query, {
            zIndex: -1,
            delay: 0.25,
            duration: 0.25,
          })
        },
      })
    }, 300)
  }

  function handleClickAdd() {
    navigate('/administration/common/country/new')
  }

  return (
    <StyledContentContry>
      <HeaderContentCommon
        name="Country"
        description="Manager country information"
        onClickAdd={() => handleClickAdd()}
      />

      <StyledContentContryTable>
        <LoadingTable />
        <DataGrid
          sx={gridStyle}
          rows={countries}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          experimentalFeatures={{ newEditingApi: false }}
          onPageChange={(page) => {
            enabledLoading().then(() => {
              setTimeout(() => {
                addPage(page)
              }, 200)
            })
          }}
          pagination
          rowsPerPageOptions={[25]}
          rowCount={pagination.total}
          initialState={{
            pagination: {
              pageSize: pagination.itemsPerPage,
            },
          }}
          paginationMode="server"
        />
      </StyledContentContryTable>
    </StyledContentContry>
  )
}
