/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Icon, Radio } from '@viswals/components'
import { RegistrationModeEnum } from '@viswals/services'
import { RegistrationCardArea } from './RegistrationModeStyles'
import { RegistrationCardProps } from './props'

type ItemProp = {
  item: RegistrationCardProps
  selectedMode?: string
  registerRadio: any
}

const RegistrationCard = ({ item, selectedMode, registerRadio }: ItemProp) => {
  const { img1, img2, category, description, priceMonth, mode } = item

  return (
    <div className="gsapCardRegistration">
      <RegistrationCardArea>
        <div className={['imgArea', img2 && 'img2'].join(' ')}>
          <img src={img1} alt="Registration mode" />
          {img2 && <img src={img2} alt="Registration mode" />}
        </div>
        <h2 className="title">{category}</h2>
        <p className="description">{description}</p>
        <div>
          {category === 'Viswals Freelancer' && (
            <div className="tagArea">
              <span className="tagIconArea clinic">
                <Icon name="Clinic" />
                <p>FL</p>
              </span>
              <span className="tagIconArea">
                <Icon name="Cam1" />
                <p>FL</p>
              </span>
              <span className="tagIconArea green">
                <Icon name="Cam1" />
                <p>FL</p>
              </span>
            </div>
          )}
          {category === 'Viswals In Team' && (
            <div className="tagArea">
              <span className="tagIconArea clinic">
                <Icon name="Clinic" />
                <p>IT</p>
              </span>
              <span className="tagIconArea">
                <Icon name="Cam1" />
                <p>IT</p>
              </span>
              <span className="tagIconArea green">
                <Icon name="Cam1" />
                <p>IT</p>
              </span>
            </div>
          )}
          {category === 'Viswals Freelancer & Viswals In Team' && (
            <div className="flAndIt">
              <div className="tagArea">
                <span className="tagIconArea clinic">
                  <Icon name="Clinic" />
                  <p>FL</p>
                </span>
                <span className="tagIconArea">
                  <Icon name="Cam1" />
                  <p>FL</p>
                </span>
                <span className="tagIconArea green">
                  <Icon name="Cam1" />
                  <p>FL</p>
                </span>
              </div>
              <div className="tagArea">
                <span className="tagIconArea clinic">
                  <Icon name="Clinic" />
                  <p>IT</p>
                </span>
                <span className="tagIconArea">
                  <Icon name="Cam1" />
                  <p>IT</p>
                </span>
                <span className="tagIconArea green">
                  <Icon name="Cam1" />
                  <p>IT</p>
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="priceArea">
          <h2>
            ${priceMonth} <span> / Month</span>
          </h2>
          <Radio
            isCheck={selectedMode === mode}
            formRegister={registerRadio}
            id={`radioMode_${item.uuid}`}
            value={RegistrationModeEnum[mode as RegistrationModeEnum]}
          />
        </div>
      </RegistrationCardArea>
    </div>
  )
}

export default RegistrationCard
