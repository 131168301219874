import React from 'react'
import { Card, Icon, PaymentCard, Select, Tooltip } from '@viswals/components'

import TooltipMessage from './tooltipMessage'
import { Container, ContentFlipCards } from './PaymentsStyles'
import TooltipMessagePendingApproval from './tooltipMessage/TooltipMessagePendingApproval'
import useLogic, { PaymentLocal } from './useLogic'
import { accountTypeValues } from '../wallet/data/data'

const StepPayments = () => {
  const {
    fields,
    wallets,
    selectWallets,
    onSubmit,
    handleSubmit,
    register,
    watch,
    setValue,
    banks,
  } = useLogic()

  return (
    <Container>
      <div className="vw-payments-header">
        <div>
          <h2>Payments & Links</h2>
          <span>
            Simply link cards to entities & set up where your income lands
          </span>
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-payments-cardArea">
          {fields.map((i, columnIndex) => {
            const findWatch = watch(`data.${columnIndex}`) as PaymentLocal

            const findWallet = wallets.find((wallet) => {
              return wallet.uuid === findWatch.walletUuid
            })
            const findBank = banks.find((bank) => {
              return bank.uuid === findWallet?.bank
            })
            const findAccountType = accountTypeValues.find(
              (type) => type.value === findWallet?.accountType
            )
            return (
              <Card key={i.id}>
                <div className="entitiesArea">
                  {findWatch.clinicUuid === undefined ? (
                    <img
                      src={require('@images/viswalsLogo.svg').default}
                      alt="Viswals"
                    />
                  ) : (
                    <h2>{findWatch.clinicName}</h2>
                  )}
                  <p>
                    {findWatch.clinicUuid === undefined
                      ? 'freelancer'
                      : 'in team'}
                  </p>
                </div>

                <div className="linkArea">
                  {findWatch.walletUuid ? (
                    findWatch.verified ? (
                      <Tooltip
                        placement="top"
                        overlay={TooltipMessage}
                        destroyTooltipOnHide
                        trigger={['click']}
                        motion={{
                          motionName: 'rc-tooltip-fade',
                        }}
                      >
                        <div>
                          <Icon
                            styles={{
                              color: '#15AEFF',
                            }}
                            name="Link1"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip
                        placement="top"
                        overlay={TooltipMessagePendingApproval}
                        destroyTooltipOnHide
                        trigger={['click']}
                        motion={{
                          motionName: 'rc-tooltip-fade',
                        }}
                      >
                        <div>
                          <Icon
                            styles={{
                              color: '#FF3333',
                            }}
                            name="LinkDisable"
                          />
                        </div>
                      </Tooltip>
                    )
                  ) : (
                    <div>
                      <Icon name="Link1" />
                    </div>
                  )}
                </div>

                <ContentFlipCards className="contentFlipCard">
                  <div
                    className={`innerBox ${
                      watch(`data.${columnIndex}.walletUuid`) ? 'full' : ''
                    }`}
                    onClick={() => {
                      if (findWallet) {
                        setValue(`data.${columnIndex}.walletUuid`, undefined)
                      }
                    }}
                    aria-hidden
                  >
                    <div
                      onClick={() =>
                        setValue(`data.${columnIndex}.isActive`, true)
                      }
                      aria-hidden
                      className={[
                        'selectCardArea',
                        findWatch.isActive ? 'active' : '',
                      ].join(' ')}
                    >
                      <div className="linkCardIcon">
                        <Icon name="LinkLeft" />
                      </div>
                      <Select
                        labelTitle="Select the account type"
                        placeholder="Link Card here"
                        options={selectWallets}
                        formRegister={{
                          ...register(`data.${columnIndex}.walletUuid`),
                          watch,
                        }}
                      />
                      <div className="linkCardIcon right">
                        <Icon name="LinkRight" />
                      </div>
                    </div>
                    <PaymentCard
                      accountName={findWallet?.accountName}
                      accountNumber={findWallet?.accountNumber}
                      accountType={findAccountType?.label}
                      bankName={findBank?.name}
                      sortCode={findWallet?.sortCode}
                      color={findWallet?.color}
                    />
                  </div>
                </ContentFlipCards>
              </Card>
            )
          })}
        </div>
      </form>
    </Container>
  )
}

export default StepPayments
