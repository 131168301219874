import React from 'react'
import { BoxItemArrayItems, ContainerMiniPagination } from './styles'

export type MiniPaginationProps = {
  pages: number
  active: number
  onChangeActive: (index: number) => void
}

const MiniPagination = ({
  onChangeActive,
  pages,
  active,
}: MiniPaginationProps) => {
  return (
    <ContainerMiniPagination>
      {Array.from({ length: pages }, (_, i) => i + 1).map((page) => {
        return (
          <BoxItemArrayItems
            key={`boxArray-${page}`}
            selected={active === page}
            onClick={() => onChangeActive(page)}
          >
            {page}
          </BoxItemArrayItems>
        )
      })}
    </ContainerMiniPagination>
  )
}

export default MiniPagination
