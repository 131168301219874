import moment from 'moment'
import useDatas from '@src/hooks/useDatas'
import { VerificationTitle } from '../../style'
import { ParseTypeProps, VerificationItemProps } from '../../type'
import VerificationCard from '../Card'
import { DocumentDataBox, DocumentItemBox, DocumentListBox } from './stye'

type VerificationDocumentDataProps = {
  items: VerificationItemProps[]
  parseIndex: ParseTypeProps[]
}

const VerificationDocumentData = ({
  items,
  parseIndex,
}: VerificationDocumentDataProps) => {
  const { countries } = useDatas()

  function parseValue(
    parse: ParseTypeProps,
    item: VerificationItemProps
  ): string {
    if (parse.type === 'value') {
      return `${parse.value}`
    }
    if (parse.type === 'date') {
      return moment(item.value).format(parse.format)
    }
    if (parse.type === 'country') {
      const find = countries.find((f) => f.uuid === item.value)
      if (find?.name) {
        return find.name
      }
    }

    return ''
  }

  function getClassname(item: VerificationItemProps): string {
    if (item.state === 'ERROR') {
      return 'error'
    }
    if (item.state === 'QUESTION_RAISED') {
      return 'question'
    }

    return ''
  }

  return (
    <DocumentDataBox>
      <VerificationTitle className="title">Document data</VerificationTitle>

      <DocumentListBox>
        {items.map((item, index) => {
          let { value } = item
          const findParse = parseIndex.find((f) => f.index === index)
          if (findParse) {
            value = parseValue(findParse, item)
          }

          return (
            <VerificationCard key={`verificationData-${index}`}>
              <DocumentItemBox className={getClassname(item)}>
                <span>{item.label}</span>
                <span>{value}</span>
              </DocumentItemBox>
            </VerificationCard>
          )
        })}
      </DocumentListBox>
    </DocumentDataBox>
  )
}

export default VerificationDocumentData
