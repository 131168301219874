/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  Language,
  Request,
  retrieveSpeakLanguage,
  saveSpeakLanguage,
  SpeakLanguage,
  SpeakLanguageFilter,
} from '@viswals/services'
import { ReducersProps } from '@src/types/reducers'
import { SelectedOptionsProps } from '@viswals/components'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'

import useLoading from '@src/hooks/useLoading'

import useStepsError from '@src/hooks/useStepsError'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { dataLanguagesLevel } from './data'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    watch,
    setError,
    formState: { errors },
    control,
    getValues,
  } = useForm()

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  })

  const [indexToDelete, setIndexToDelete] = useState<number[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)

  const [paginationActive, setPaginationActive] = useState<number>(1)
  const [destroyList, setDestroyList] = useState<Language[]>([])
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { loading, setLoading } = useLoading()

  const { getErrors, getFieldProps } = useStepsError(errors)
  const [isReady, setIsReady] = useState<boolean>(false)

  const { confirm, next } = useSelector(
    (state: ReducersProps) => state.navigation
  )

  const { languages: commonLanguages } = useSelector(
    (state: ReducersProps) => state.datas
  )

  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  const newLanguagesLevel: SelectedOptionsProps[] = []
  dataLanguagesLevel.map((item) => {
    newLanguagesLevel.push({
      label: item.name,
      value: item.isoCode,
      isoCode: item.isoCode,
    })
    return null
  })

  const newCommonLanguages: SelectedOptionsProps[] = []

  commonLanguages?.map((item) => {
    newCommonLanguages.push({
      label: item.name as string,
      value: item.uuid as string,
    })
    return null
  })

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, { type }) => {
      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (next && isReady && !loading) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  useEffect(() => {
    if (!verified) {
      document.addEventListener('mousedown', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }
    }
  }, [indexToDelete, verified])

  useEffect(() => {
    ;(async () => {
      setLoading(true)
      try {
        const requestSpeakLanguage: Request<SpeakLanguageFilter> = {
          fields: [
            'uuid',
            'levelListening',
            'levelSpeaking',
            'levelWriting',
            'language',
          ],
          filters: {
            clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          },
        }

        const responseSpeakLanguage = await retrieveSpeakLanguage(
          requestSpeakLanguage
        )

        const dataSpeakLanguage = responseSpeakLanguage.data.data
          ? responseSpeakLanguage.data.data
          : [
              {
                clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
              },
            ]
        setTimeout(() => {
          if (dataSpeakLanguage?.length) {
            dataSpeakLanguage.map((language: SpeakLanguage): SpeakLanguage => {
              const newLanguage = {
                destroy: false,
                clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
                levelListening: language.levelListening,
                levelSpeaking: language.levelSpeaking,
                levelWriting: language.levelWriting,
                language: language.language,
                uuid: language.uuid,
                // @ts-ignore
                // id: language.uuid,
              }
              append(newLanguage)
              return newLanguage
            })
          }
          setLoading(false)
          dispatch(setNavigationActiveNext(true))
        }, 200)
      } finally {
        setLoading(false)
        setIsReady(true)
      }
    })()
  }, [registrationActive])

  function handleClick(e: any) {
    if (document.getElementsByClassName('vw-select').length) {
      return false
    }
    const pathList = e.path as any[]
    const getCardMap = pathList
      ?.map((m) => m.className)
      ?.filter((f) => f && f?.includes('card-item'))
    const checkCard = !!getCardMap?.length

    if (checkCard) {
      const safeArea = !!pathList
        ?.map((m) => m.className)
        ?.filter((f) => f && f?.includes('vw-form'))?.length
      if (!safeArea) {
        const getIndex = Number(getCardMap[0].split('item-')[1])
        handleSelectIndeToDelete(getIndex)
      }
    }
  }

  const handleSelectIndeToDelete = (index: number) => {
    if (indexToDelete.includes(index)) {
      setIndexToDelete((items) => items.filter((item) => item !== index))
      return null
    }
    setIndexToDelete((items) => [...items, index])
    return null
  }

  const handleOpenModel = useCallback(() => {
    setIsModalOpen(true)
  }, [isModalOpen])

  const handleRemoveLanguageModal = useCallback(() => {
    for (const item of indexToDelete) {
      const uuid = getValues(`data.${item}.uuid`)
      const obj = {
        ...watch(`data.${item}`),
        destroy: true,
      }
      if (uuid) {
        setDestroyList((list) => [...list, obj])
      }
    }
    remove(indexToDelete)
    setIndexToDelete([])

    if (!confirm) {
      dispatch(setNavigationConfirm(true))
    }
    setTimeout(() => {
      setIsModalOpen(false)
    }, 200)
  }, [isModalOpen])

  const handleCancelModelDelete = useCallback(() => {
    // TODO => Quando fechar o Modal, deve desmarcar os items?
    setIndexToDelete([])
    setIsModalOpen(false)
  }, [isModalOpen])

  async function onSubmit(data: any) {
    data = {
      ...data,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
    }
    if (data.data.length === 0) {
      return null
    }
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const dataPayload = data.data.map((language: SpeakLanguage) => ({
        ...language,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      }))
      await saveSpeakLanguage([
        ...destroyList,
        ...dataPayload,
      ] as Request<SpeakLanguage>)
      subStepDone()
      navigate(nextRoute)
    } finally {
      setLoading(false)
    }
  }

  // Pagination
  const paginationTotal = 3
  const pagination = Math.ceil(fields.length / paginationTotal)
  const indexShowEnd = paginationActive * paginationTotal
  const indexShowStart = indexShowEnd - paginationTotal

  useLayoutEffect(() => {
    setPaginationActive(pagination)
  }, [pagination])

  return {
    isModalOpen,
    indexToDelete,
    newLanguagesLevel,
    newCommonLanguages,

    fields,
    verified,

    append,
    watch,
    register,
    handleSubmit,
    onSubmit,

    setPaginationActive,

    getFieldProps,

    handleOpenModel,
    handleCancelModelDelete,
    handleRemoveLanguageModal,

    pagination,
    paginationActive,
    indexShowStart,
    indexShowEnd,
  }
}

export default useLogic
