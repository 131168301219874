import styled from 'styled-components'

export const DocumentDataBox = styled.div`
  width: 100%;
  padding: 2rem;

  .title {
    margin-top: 1.6rem;
    margin-bottom: 8rem;
  }
`
export const DocumentItemBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;

  span {
    font-family: 'Oxanium';
    font-size: 1.2rem;
    font-weight: bold;
    color: #7c7c80;

    &:first-child {
    }
    &:last-child {
      color: #aeaeb2;
    }
  }

  &.error {
    background-color: #f72a1e;
    span {
      color: white;
    }
  }
  &.question {
    background-color: #fe9f1f;
    span {
      color: #0c0c0d;
    }
  }
`

export const DocumentListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`
