/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Request, Disease, updateDisease } from '@viswals/services'
import { Button, Input, Toggle, Upload } from '@viswals/components'

import { StyledContentDisease } from './styles'
import HeaderContentCommon from '../_components/HeaderContentCommon'

export default function FormUpdateDisease() {
  const navigate = useNavigate()

  const [isError, setIsError] = useState<string>('')

  const { watch, register, handleSubmit, reset, control, setValue } = useForm()

  useEffect(() => {
    ;(async () => {
      const getCurrencyToSave: Disease = JSON.parse(
        window.localStorage.getItem('currencyEdit') as string
      )
      reset({
        ...getCurrencyToSave,
      })
    })()
  }, [])

  async function onSubmit(data: any) {
    const dataToSend: Disease = {
      uuid: watch('uuid'),
      name: data.name,
      enabled: data.enabled,
    }

    try {
      setIsError('')
      await updateDisease(dataToSend as Request<Disease>)
      window.localStorage.removeItem('diseaseEdit')
      navigate('/administration/common/disease')
    } catch (error: any) {
      setIsError(error.message)
    }
  }

  return (
    <StyledContentDisease className="styled-form">
      <form
        onSubmit={handleSubmit(onSubmit)}
        autoCapitalize="off"
        autoComplete="off"
        autoCorrect="off"
        spellCheck="false"
      >
        <div className="content-mini-form-box">
          <HeaderContentCommon
            name="Edit Currency"
            description="Here you can edit disease"
          />

          <Input label="Name" formRegister={{ ...register('name') }} />

          <div className="content-toggle-switch">
            <Toggle isBig name="enabled" control={control} />
            <div className="box-preview">Enable Currency</div>
          </div>
          <Button label="Save" type="submit" />
          <div
            className="content-button-cancell"
            aria-hidden
            onClick={() => navigate('/administration/common/disease')}
          >
            Cancel
          </div>
          <div className="boxError">
            {isError.length > 0 ? `${isError}` : ''}
          </div>
        </div>
      </form>
    </StyledContentDisease>
  )
}
