/* eslint-disable import/prefer-default-export */
import styled from 'styled-components'

export const MyMapComponentContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #2b2b2b;

  .gm-style-cc {
    display: none;
  }
`

export const LoadingBox = styled.div`
  position: relative;
  width: 30rem;
  height: 20rem;
  content: '';
  .vw-loadingContainer {
    background-color: transparent !important;
    margin-left: 10%;
    margin-top: 10%;
  }
`

export const MapBoxControl = styled.div`
  position: absolute;
  z-index: 10;
  width: 3rem;
  right: 2rem;
`

// MapControl
export const MapControlContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #151517;
`

// ZoomControl
export const ZoomControl = styled.div`
  margin-top: 1rem;
  width: 100%;
  border-radius: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: #151517;

  span {
    color: #636366;
    font-size: 0.8rem;
    font-family: 1rem;
    font-weight: bold;
  }
`

export const ZoomIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 2.8rem;
  height: 2.6rem;

  svg {
    width: 1.1rem;
    color: #636366;
  }

  &:hover {
    background-color: #1c1c1e;
  }
`

export const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;
`

export const ItemWrapper = styled.div`
  width: 2.8rem;
  height: 2.6rem;
  border-radius: 5px;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'normal')};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.1rem;

  &.active {
    span,
    svg {
      color: #5bdf6f;
    }
  }

  svg {
    max-width: 1.1rem;
    color: #48484a;
  }

  span {
    font-family: Oxanium;
    font-size: 1rem;
    font-weight: bold;
    color: #48484a;
  }

  &:hover {
    background-color: #1c1c1e;
  }
`

type SeparatorProps = {
  margin?: string
}
export const Separator = styled.div<SeparatorProps>`
  width: 2.2rem;
  height: 0.1rem;
  border-radius: 0.5px;
  margin: ${({ margin }) => margin ?? '0.4rem 0'};
  background: #3a3a3c50;
`
