import styled, { css } from 'styled-components'
import { DetailPlatformItemProps } from './type'

export const DetailBox = styled.div`
  position: relative;
  width: 310px;
  height: 351px;
  font-size: 10px;
  background-color: #1c1c1e;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
`

export const DetailCountry = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Oxanium;

  figure {
    height: 11px;
    img {
      height: 100%;
      width: auto;
    }
  }
  span {
    margin-top: 5px;
    font-size: 8px;
    color: #7c7c80;
  }
`

export const DetailBoxInfo = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

type DetailIconProps = {
  color: string
}

export const DetailIcon = styled.div<DetailIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  background-color: #1c1c1e;
  border-radius: 100%;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 300;
  overflow: hidden;

  ${({ color }) => {
    return css`
      border: 2px solid ${color};
      color: ${color};
    `
  }}
`
export const DetailName = styled.div`
  margin-top: 20px;
  font-family: Oxanium;
  color: white;
  font-weight: bold;
  font-size: 14px;
`

export const DetailPlatforms = styled.div`
  margin-top: 20px;
  width: 100%;
`

export const DetailPlatform = styled.div`
  margin-top: 4px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  background-color: #222224;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 4px;
`

export const DetailPlatformTitle = styled.h4`
  font-family: Nebula;
  font-size: 10px;
  color: #7c7c80;
`

export const DetailPlatformGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  align-items: center;
`

export const DetailPlatformItem = styled.div<DetailPlatformItemProps>`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor, borderColor }) => {
    if (backgroundColor) {
      return backgroundColor
    }
    if (borderColor) {
      return '#0c0c0d'
    }

    return '#1C1C1E'
  }};

  border: 1px solid ${({ borderColor }) => borderColor ?? '#1C1C1E'}90;

  svg {
    color: ${({ iconColor, backgroundColor }) => {
      if (backgroundColor) {
        return '#0C0C0D'
      }
      if (iconColor) {
        return iconColor
      }

      return '#2c2c2e'
    }};
    max-height: 65%;
    max-width: 65%;
  }
`

export const DetailButtonBox = styled.div`
  margin-top: 20px;
  width: 100%;
`

export const DetailButton = styled.div`
  cursor: pointer;
  font-family: Nebula;
  width: 100%;
  background-color: #2c2c2e;
  border-radius: 4px;
  height: 24px;
  font-weight: bold;
  font-size: 8px;
  color: #aeaeb2;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }
`
