import * as React from "react";

const MenIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={15.155}
        viewBox="0 0 16 15.155"
        {...props}
    >
        <path
            d="M8.01 8.91a3.258 3.258 0 0 0 1.644-.452c.061-.035.121-.073.18-.111l.05-.033q.1-.069.2-.145l.043-.036q.08-.066.157-.136l.044-.04c.059-.055.117-.114.173-.174l.044-.048q.069-.077.135-.158l.038-.046c.052-.065.1-.132.149-.2.014-.019.027-.04.04-.06q.059-.088.114-.18l.029-.049c.043-.073.083-.147.121-.222l.035-.07q.049-.1.093-.2l.021-.047c.033-.08.064-.161.093-.243 0-.012.009-.023.013-.036v-.007c.078-.366-1.4.091-3.42.091s-3.548-.559-3.423-.1a.022.022 0 0 0 0 .016.214.214 0 0 1 .014.037c.029.083.06.166.093.244l.019.043q.045.1.095.206l.034.068q.058.115.123.226l.027.044q.055.095.118.185c.013.019.025.038.038.055q.073.1.151.2l.034.042q.068.084.14.163l.041.045q.085.091.175.176l.042.038c.053.048.106.094.161.139l.041.034q.1.077.2.146l.048.032c.059.04.12.078.181.114h.007a3.254 3.254 0 0 0 1.642.45M4.694 2.054l.013-.022a.134.134 0 0 0-.013.022m3.315.447a.4.4 0 0 1-.279-.117.4.4 0 0 1-.115-.28.4.4 0 0 1 .4-.4.4.4 0 0 1 .28.116.4.4 0 0 1 .116.28.4.4 0 0 1-.116.28.4.4 0 0 1-.28.116m3.3-.47A3.874 3.874 0 0 0 8.009.001a3.873 3.873 0 0 0-3.3 2.032 9.563 9.563 0 0 1 1.889-.429 1.5 1.5 0 0 0-.089.505 1.5 1.5 0 0 0 .062.425l-.019.036a7.123 7.123 0 0 0-2.331.7l-.025.012a5.3 5.3 0 0 0-.178 1.359.788.788 0 0 0 .318.011 4.072 4.072 0 0 0 2.235-2.116A1.5 1.5 0 0 0 8.012 3.61a1.5 1.5 0 0 0 .771-.215 1.5 1.5 0 0 0 .662-.834 1.5 1.5 0 0 0 .071-.455 1.494 1.494 0 0 0-.096-.505 10.134 10.134 0 0 1 1.88.412c0 .007.007.014.012.021"
            fill={props.color || "#36ba91"}
        />
        <path
            d="M8.406 2.109a.4.4 0 0 0-.116-.28.4.4 0 0 0-.28-.116.4.4 0 0 0-.4.4.4.4 0 0 0 .4.4.4.4 0 0 0 .28-.116.4.4 0 0 0 .116-.28m-.023 9.487.083.189 1.56-1.695-1.561 2.429-.284-.721.2-.2Zm-.845.924-1.552-2.419 1.549 1.684.083-.189.2.2Zm7.706-1.159v-.012l-5.109-1.24-.1-.025-2.028.61-2.024-.61-.034.008-5.184 1.256v.012l-.757 3.786h16Z"
            fill={props.color || "#36ba91"}
        />
    </svg>
);

export default MenIcon;