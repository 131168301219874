import React from 'react'
import { CommissionsCardArea } from './RegistrationModeStyles'
import { CommissionCardProps } from './props'
import CirclePercentNumber from './CirclePercentNumber'

type ItemProps = { item: CommissionCardProps }

const CommissionsCard = ({ item }: ItemProps) => {
  const {
    uuid,
    category2,
    priceMonth,
    percent1,
    percent1b,
    percentTitle1,
    percentTitle1b,
    percentDescription1,
    percent2,
    percent2b,
    percentTitle2,
    percentTitle2b,
    percentTitle2c,
    percentDescription2,
  } = item
  return (
    <CommissionsCardArea uuid={uuid}>
      <div className="headerArea">
        <h2>{category2}</h2>
        <h2 className="price">
          ${priceMonth} <span> / Month</span>
        </h2>
      </div>

      <div className="middleArea">
        <div className="middleItem gsapCard">
          {uuid === '03' ? (
            <div className="doublePercent">
              <CirclePercentNumber isMini percent={Number(percent1)} />
              <CirclePercentNumber isMini isRed percent={Number(percent1b)} />
            </div>
          ) : (
            <CirclePercentNumber percent={Number(percent1)} />
          )}
          <div className="descriptionArea">
            <span>{percentTitle1}</span>
            <p>{percentDescription1}</p>
          </div>
        </div>
        <div className="middleItem red gsapCard">
          {uuid === '03' ? (
            <div className="doublePercent">
              <CirclePercentNumber isMini percent={Number(percent2)} />
              <CirclePercentNumber isMini isRed percent={Number(percent2b)} />
            </div>
          ) : (
            <CirclePercentNumber isRed percent={Number(percent2)} />
          )}
          <div className="descriptionArea red ">
            <span className={uuid === '03' ? 'blue' : ''}>{percentTitle2}</span>
            <p>{percentDescription2}</p>
          </div>
        </div>
      </div>

      <div className="footerArea gsapCard">
        {uuid === '03' ? (
          <div className="doublePercentArea">
            <div className="doublePercent">
              <div className="pieChart left" />
              <div className="subtitleArea left">
                <div className="subtitle">
                  <span>
                    {percent1}
                    <p>%</p>
                  </span>
                  <p>{percentTitle1b}</p>
                </div>
                <div className="subtitle red">
                  <span>
                    {percent1b} <p>%</p>
                  </span>
                  <p>{percentTitle2b}</p>
                </div>
              </div>
            </div>

            <div className="doublePercent">
              <div className="pieChart right" />
              <div className="subtitleArea">
                <div className="subtitle">
                  <span>
                    {percent2} <p>%</p>
                  </span>
                  <p>{percentTitle1b}</p>
                </div>
                <div className="subtitle red">
                  <span>
                    {percent2b} <p>%</p>
                  </span>
                  <p>{percentTitle2c}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="subtitle">
              <span>
                {percent1} <p>%</p>
              </span>
              <p>{percentTitle1}</p>
            </div>
            <div className="pieChart" />
            <div className="subtitle red">
              <span>
                {percent2} <p>%</p>
              </span>
              <p>{percentTitle2}</p>
            </div>
          </>
        )}
      </div>
    </CommissionsCardArea>
  )
}
export default CommissionsCard
