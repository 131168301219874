import { ShareOurApp } from '@viswals/services'

import { ActionProps } from './props'

export default function setActionShares(shares: ShareOurApp[]): ActionProps {
  return {
    type: 'SET_SHARE',
    shares,
  }
}
