import styled from 'styled-components'

const ContainerItemListHeader = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 2rem;
  padding-left: 0.5rem;
  padding-top: 0.1rem;

  .item-header-list {
    width: 100%;
    height: 3.3rem;

    display: flex;
    align-items: center;

    color: #545456;
    font-size: 1rem;
    font-family: var(--font-secondary);

    &.first {
      width: 100%;
      max-width: 9rem;
      min-width: 9rem;
      margin-left: 1rem;
    }
    &.date {
      width: 100%;
      max-width: 7rem;
      min-width: 7rem;
    }
    &.time {
      width: 100%;
      max-width: 7rem;
      min-width: 7rem;
    }
    &.country {
      width: 100%;
      max-width: 12.6rem;
      min-width: 12.6rem;
    }
    &.name {
      width: 100%;
      max-width: 13.4rem;
      min-width: 13.4rem;
    }
    &.email {
      width: 100%;
      max-width: 19.5rem;
      min-width: 19.5rem;
    }
    &.global {
      width: 100%;
      max-width: 14.2rem;
      min-width: 14.2rem;
    }
    &.buttons {
      width: 100%;
      max-width: 16.35rem;
      min-width: 16.35rem;
    }
  }
`

export default ContainerItemListHeader
