import { Card, Icon, Radio } from '@viswals/components'
import { Container } from './TypeOfRegistrationStyles'
import useLogic from './useLogic'

const StepTypeOfRegistration = () => {
  const {
    verified,
    initialData,
    professionalRegistration,
    handleSubmit,
    onSubmit,
    register,
    watch,
    getFieldProps,
  } = useLogic()

  return (
    <Container>
      <div className="vw-typeOfRegistration-header">
        <h2>Type of Registration</h2>
        <span>Registration type as per the dental regulatory body</span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register('verified', { value: false })} />
        <div className="vw-typeOfRegistration-cardArea">
          {professionalRegistration.map((item, index) => {
            const isVerified = verified && item.value === watch('registration')

            const isError =
              getFieldProps('registration').isError &&
              initialData?.registration === item.value

            const isSelected =
              !verified && !isError && item.value === watch('registration')

            return (
              <label key={`item_${index}`} className="vw-regis-cardGo">
                <Card
                  key={`item_${index}`}
                  isError={isError}
                  isVerified={isVerified}
                  isSelected={isSelected}
                >
                  <div className="item-ico">
                    <Icon name="Dentist1" />
                  </div>
                  <p>{item.label}</p>
                  <div className="vw-radio-inputRadioArea">
                    <Radio
                      formRegister={{
                        ...register('registration', {
                          required: true,
                        }),
                        watch,
                      }}
                      value={item.value}
                      id={`vw-regis-card-${index}`}
                      {...getFieldProps('registration')}
                    />
                  </div>
                </Card>
              </label>
            )
          })}
        </div>
      </form>
    </Container>
  )
}

export default StepTypeOfRegistration
