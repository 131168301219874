import React from 'react'
import { UploadCircle } from '@viswals/components'

import { ContainerUploadID } from './UploadIDStyles'

import useLogic from './useLogic'

const StepUploadID = () => {
  const { inputRefFile, uploadChanged, setValue, register, watch } = useLogic()

  return (
    <ContainerUploadID>
      <h1>Upload ID</h1>
      <p>
        You can upload a scanned copy of your ID or you can also drag it here
      </p>

      <UploadCircle
        title="JPG, PNG or PDF, smaller than 10MB"
        description="Drag and drop your image here or"
        labelButtonUpload="Upload ID"
        docIcon="Passport"
        docLabel="Passport"
        inputRefExtra={inputRefFile}
        formRegister={{
          ...register('upload', { required: true }),
          watch,
          setValue,
        }}
        onChanged={() => uploadChanged()}
      />
    </ContainerUploadID>
  )
}

export default StepUploadID
