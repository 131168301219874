/* eslint-disable radix */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Input, Select, SelectedOptionsProps } from '@viswals/components'
import {
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from 'react-hook-form'

import CoursesWhere from '@images/step3degreeWhere.svg'
import transformToSelectOptions from '@src/core/transformToSelectOptions'

import { ContainerWhereCourses, ContentMiniForm } from './styles'

type WhereCoursesProps = {
  countries: SelectedOptionsProps[]
  watch: UseFormWatch<FieldValues>
  register: UseFormRegister<FieldValues>
  setValue: UseFormSetValue<FieldValues>
  keyIndex?: number
  city?: boolean
  cities?: SelectedOptionsProps[]
  continents?: any[]
}

const WhereCourses = ({
  continents,
  countries,
  watch,
  register,
  keyIndex,
  city,
  cities,
  setValue,
}: WhereCoursesProps) => {
  const dataContinentsOptions = transformToSelectOptions({
    array: continents as any[],
    keyLabel: 'name',
    keyValue: 'uuid',
  })

  return (
    <ContainerWhereCourses city={city}>
      <h3>Where</h3>
      <img src={CoursesWhere} alt="CoursesWhere" />
      <p>Continent</p>
      <div className="vw-regis-safeArea">
        {dataContinentsOptions.map((continent, _index) => {
          return (
            <label
              key={_index}
              className={`vw-form vw-regis-safeAreaItems ${
                continent.value === watch(`data.${keyIndex}.continent`)
                  ? 'checked'
                  : ''
              }`}
            >
              {continent.label}
              <input
                type="radio"
                value={continent.value}
                {...register(`data.${keyIndex}.continent`, {
                  required: true,
                  onChange: (event) => {
                    if (event?.target.value) {
                      setValue(`data.${keyIndex}.country`, undefined)
                    }
                  },
                })}
              />
            </label>
          )
        })}
      </div>
      <div className="paragraphFirst">
        {city ? 'Country & City' : 'Country'}
      </div>
      <ContentMiniForm className="vw-contentMiniForm-select">
        <Select
          labelTitle="Select Country"
          placeholder="Select Country"
          options={countries}
          search
          placement="top"
          formRegister={{
            ...register(`data.${keyIndex}.country`, {
              required: true,
              onChange: (event) => {
                if (event?.target.value) {
                  setValue(`data.${keyIndex}.city`, undefined)
                  setValue(`data.${keyIndex}.courseProvider`, undefined)
                  setValue(`data.${keyIndex}.institution`, undefined)
                }
              },
            }),
            watch,
          }}
          isDisabled
        />

        {city ? (
          <>
            <div className="divisor" />
            <Select
              labelTitle="Select City"
              placeholder="Select City"
              options={cities as SelectedOptionsProps[]}
              search
              formRegister={{
                ...register(`data.${keyIndex}.city`, {
                  onChange: (e) => {
                    if (e.target.value) {
                      setValue(`data.${keyIndex}.cityText`, undefined)
                      setValue(`data.${keyIndex}.courseProvider`, undefined)
                      setValue(`data.${keyIndex}.institution`, undefined)
                    }
                  },
                }),
                watch,
              }}
              isDisabled
            />
            <div className="smallCity">
              <Input
                placeholder="If other city, write here"
                formRegister={{
                  ...register(`data.${keyIndex}.cityText`, {
                    onChange: (e) => {
                      if (e.target.value) {
                        setValue && setValue(`data.${keyIndex}.city`, undefined)
                      }
                    },
                  }),
                  watch,
                }}
                isDisabled
              />
            </div>
          </>
        ) : null}
      </ContentMiniForm>
    </ContainerWhereCourses>
  )
}

export default WhereCourses
