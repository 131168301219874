import StatusVerified from '@images/statusVerified.svg'
import StatusQuestionRaised from '@images/statusQuestion.svg'
import StatusError from '@images/statusError.svg'
import BoxQuestionReased from '@images/boxQuestionReased.svg'
import { useNavigate } from 'react-router-dom'
import { VerificationItemProps, VerificationStatusProps } from '../../type'
import VerificationCard from '../Card'
import { ItemLabelBox, VerificationItemBox } from './style'

type Props = {
  updateState: (uuid: string, state: VerificationStatusProps) => void
} & VerificationItemProps

const VerificationItem = (props: Props) => {
  const navigate = useNavigate()

  const statusList = [
    { label: 'ERROR', icon: StatusError },
    { label: 'QUESTION_RAISED', icon: StatusQuestionRaised },
    { label: 'VERIFIED', icon: StatusVerified },
  ]

  function addQuestionReased() {
    const params = new URLSearchParams()
    params.append('question', `${props.uuid}`)
    const url = `${window.location.pathname}?${params.toString()}`
    navigate(url, { replace: true })
  }

  return (
    <VerificationItemBox>
      <VerificationCard className="card-label">
        <ItemLabelBox>
          {props.label}
          {props.state === 'QUESTION_RAISED' && (
            <div
              className="action-question"
              onClick={() => addQuestionReased()}
              aria-hidden
            >
              <img src={BoxQuestionReased} alt="question" />
            </div>
          )}
        </ItemLabelBox>
      </VerificationCard>

      {statusList.map((s, i) => (
        <VerificationCard key={`s-${i}`} className="radio">
          <div
            className="click"
            onClick={() =>
              props.updateState(
                `${props.uuid}`,
                s.label as VerificationStatusProps
              )
            }
            aria-hidden
          >
            <div className="circle">
              {s.label === props.state && <img src={s.icon} alt={s.label} />}
            </div>
          </div>
        </VerificationCard>
      ))}
    </VerificationItemBox>
  )
}

export default VerificationItem
