import styled from 'styled-components'

export const ContainerTableReport = styled.div`
  width: 100%;
  margin-top: 0.4rem;

  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;

  .header {
    width: 100%;
    height: 5.6rem;

    display: flex;
    margin-bottom: -0.4rem;
  }

  .td {
    height: 5.6rem;

    font-size: 1rem;
    font-family: 'Poppins';
    font-weight: 700;
    color: #aeaeb2;
    line-height: 1.6rem;

    &.tdOne {
      width: 14.3rem;
      padding: 2.1rem 2rem 2rem;
      border-radius: 0.5rem 0rem 0rem 0.5rem;
    }

    &.tdTwo {
      width: 10rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;
    }

    &.tdThree {
      width: 10.1rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;
    }

    &.tdFour {
      width: 8.8rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;
    }

    &.tdFive {
      width: 4.52rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;
    }

    &.tdSix {
      width: 36.2rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;
    }

    &.tdSeven {
      width: 5.6rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 2rem;
    }

    &.tdEight {
      width: 12rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;
    }

    &.tdNine {
      width: 9.4rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 4.1rem;
    }
  }
`
export const ContainerTableBodyReport = styled.div`
  width: 100%;
  height: 77.6rem;

  display: flex;
  flex-direction: column;

  gap: 0.4rem;

  overflow-y: scroll;

  position: relative;

  ::-webkit-scrollbar {
    display: none;
  }

  .content-item-all {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button-loading {
      height: 5.6rem;
      margin-top: 0.5rem;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      svg {
        color: #5bdf6f;
      }
    }
  }

  .vw-boxListOptionsContainer {
    z-index: -2;
    box-shadow: 0px 7px 10px 6px rgba(0, 0, 0, 0.33);

    border: 1px solid #7c7c8025;
  }

  .itemList {
    width: 100%;
    height: 5.6rem;

    display: flex;

    align-items: center;

    background-color: #222224;

    border-radius: 0.5rem;

    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);

    cursor: pointer;

    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #48484a;
    }

    &.isEnabled {
      background-color: #2c2c2e;
      &:hover {
        background-color: #48484a;
      }
    }

    &.isSelected {
      background-color: #222224;
      border: 0.1rem solid #f5f5f5;
      &:hover {
        background-color: #222224;
      }
    }

    .tbOne {
      width: 14.3rem;
      height: 5.6rem;
      padding: 2.1rem 2rem 2rem;

      display: flex;
      align-items: center;

      font-size: 1rem;
      font-family: 'Oxanium';

      color: #aeaeb2;
      line-height: 1.5rem;

      gap: 2.4rem;

      .tbOneIconBox {
        width: 2.4rem;
        height: 2.2rem;

        padding: 0.55rem 0.6rem 0.6rem;
        border: 0.1rem solid #08a8fd50;
        border-radius: 0.4rem;
        svg {
          color: #08a8fd;
        }
      }
      .labelTbOne {
        color: #08a8fd;
        margin-top: 0.1rem;
      }

      &.app {
        .labelTbOne {
          color: #08a8fd;
        }
        .tbOneIconBox {
          border: 0.1rem solid #08a8fd50;
          svg {
            color: #08a8fd;
          }
        }
      }
      &.clinic {
        .labelTbOne {
          color: #1ac993;
        }
        .tbOneIconBox {
          border: 0.1rem solid #1ac99350;
          svg {
            color: #1ac993;
          }
        }
      }
      &.clinician {
        .labelTbOne {
          color: #b737e6;
        }
        .tbOneIconBox {
          border: 0.1rem solid #b737e650;
          svg {
            color: #b737e6;
          }
        }
      }
    }
    .tbTwo {
      width: 10rem;
      height: 5.6rem;
      padding: 2rem 0rem 2rem 0;
      margin-left: 5rem;

      font-size: 1rem;
      font-family: 'Oxanium';
      color: #aeaeb2;
      line-height: 1.6rem;

      overflow: hidden !important;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1 !important;
    }
    .tbThree {
      width: 10.1rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;

      font-size: 1rem;
      font-family: 'Oxanium';
      color: #aeaeb2;
      line-height: 1.6rem;

      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }
    .tbFour {
      width: 8.8rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;

      font-size: 1rem;
      font-family: 'Oxanium';
      color: #aeaeb2;
      line-height: 1.6rem;

      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }
    .tbFive {
      width: 4.52rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;

      font-size: 1rem;
      font-family: 'Oxanium';
      color: #aeaeb2;
      line-height: 1.6rem;

      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }
    .tbSix {
      width: 12rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 5rem;

      font-size: 1rem;
      font-family: 'Oxanium';
      color: #aeaeb2;
      line-height: 1.6rem;

      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }
    .tbSeven {
      width: 9.4rem;
      padding: 2.1rem 0rem 2rem;

      margin-left: 4.1rem;

      font-size: 1rem;
      font-family: 'Oxanium';
      color: #aeaeb2;
      line-height: 1.6rem;
    }
    .tbEight {
      width: 36.2rem;
      height: 1.6rem;

      margin-left: 5rem;
      margin-right: 2rem;

      font-size: 1rem;
      font-family: 'Oxanium';
      color: #aeaeb2;
      line-height: 1.6rem;

      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      white-space: nowrap;
    }
    .iconRight {
      width: 5.6rem;
      height: 5.6rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      padding-right: 2rem;

      svg {
        height: 1.6rem;
        color: #636366;
      }
    }
  }
`
