/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import {
  Request,
  retrieveCurrentWorkLocation,
  saveCurrentWorkLocation,
  WorkingLocation,
  WorkingLocationFilter,
} from '@viswals/services'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useFieldArray, useForm } from 'react-hook-form'

import { ReducersProps } from '@src/types/reducers'
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useStepsError from '@src/hooks/useStepsError'
import {
  setNavigationConfirm,
  setNavigationSkip,
} from '@src/store/actions/navigation'
import createArrayOfYears from '@src/core/createArrayOfYears'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    setError,
    control,
    formState: { errors },
    clearErrors,
  } = useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  })

  const { subStepDone, verified, nextRoute } = useClinicianRegister()
  const { loading, setLoading } = useLoading()
  const { getFieldProps, getErrors } = useStepsError(errors)

  const [isReady, setIsReady] = useState<boolean>(false)
  const [paginationActive, setPaginationActive] = useState(1)
  const [indexToDelete, setIndexToDelete] = useState<number[]>([])
  const [isModalOpen, setIsModelOpen] = useState<boolean>(false)
  const [destroyList, setDestroyList] = useState<WorkingLocation[]>([])

  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  const { confirm, next } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const yearSelectAgo = createArrayOfYears({
    startYear: 1940,
    finishYear: 2022,
  })
    .map((d) => ({
      label: d.toString(),
      value: d,
    }))
    .reverse()

  // API Requests
  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (fields.length === 0 && destroyList.length === 0) {
      setTimeout(() => {
        dispatch(setNavigationSkip(true))
      }, 100)
    } else {
      setTimeout(() => {
        dispatch(setNavigationSkip(false))
      }, 100)
    }
  }, [fields.length])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  useEffect(() => {
    if (!verified) {
      document.addEventListener('mousedown', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }
    }
  }, [indexToDelete, verified])

  // Change button label NEXT => SAVE
  useEffect(() => {
    const subscription = watch((_, { type, name }) => {
      checkChangeDate(name as string)
      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch, fields])

  async function getData() {
    setIsReady(false)
    setLoading(true)
    try {
      const request: Request<WorkingLocationFilter> = {
        fields: [
          'uuid',
          'clinicianRegisterUuid',
          'countryRegistration',
          'clinicType',
          'clinicName',
          'position',
          'dateStarted',
          'dateFinished',
          'current',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        },
      }
      const resp = await retrieveCurrentWorkLocation(request)
      const data = resp?.data.data ?? []

      data.map((workingLocation: WorkingLocation) => {
        const newWorkings = {
          ...workingLocation,
          dateStarted_year: new Date(workingLocation.dateStarted as string)
            .getFullYear()
            .toString(),
          dateStarted_month: `${
            new Date(workingLocation.dateStarted as string).getMonth() + 1
          }`,
        }
        append(newWorkings)
        return null
      })
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  async function onSubmit(data: any) {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const dataPayload = data.data.map((item: WorkingLocation) => {
        const newWorkingLocation: WorkingLocation = {
          ...item,
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
          current: true,
          dateFinished: item.dateStarted,
          // @ts-ignore
          dateStarted_year: undefined,
          dateStarted_month: undefined,
        }
        return newWorkingLocation
      })
      await saveCurrentWorkLocation([
        ...destroyList,
        ...dataPayload,
      ] as Request<WorkingLocationFilter>)
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setLoading(false)
    }
  }

  function handleClick(e: any) {
    if (document.getElementsByClassName('vw-select').length) {
      return false
    }
    const pathList = e.path as any[]
    const getCardMap = pathList
      ?.map((m) => m.className)
      ?.filter((f) => f && f?.includes('card-item'))
    const checkCard = !!getCardMap?.length

    if (checkCard) {
      const safeArea = !!pathList
        ?.map((m) => m.className)
        ?.filter((f) => f && f?.includes('vw-form'))?.length
      if (!safeArea) {
        const getIndex = Number(getCardMap[0].split('item-')[1])
        handleSelectIndexToDelete(getIndex)
      }
    }
  }

  const handleSelectIndexToDelete = (index: number) => {
    if (indexToDelete.includes(index)) {
      setIndexToDelete((items) => items.filter((item) => item !== index))
      return null
    }
    setIndexToDelete((items) => [...items, index])
    return null
  }

  const handleOpenModel = useCallback(() => {
    setIsModelOpen(true)
  }, [isModalOpen])

  const handleCancelModelDelete = useCallback(() => {
    setIndexToDelete([])
    setIsModelOpen(false)
  }, [isModalOpen])

  const handleConfirmModelDelete = useCallback(() => {
    for (const item of indexToDelete) {
      const uuid = getValues(`data.${item}.uuid`)
      const obj = {
        ...watch(`data.${item}`),
        destroy: true,
      }
      if (uuid) {
        setDestroyList((list) => [...list, obj])
      }
    }
    remove(indexToDelete)
    setIndexToDelete([])

    if (!confirm) {
      dispatch(setNavigationConfirm(true))
    }
    setTimeout(() => {
      setIsModelOpen(false)
    }, 200)
  }, [isModalOpen])

  // Pagination Info

  const paginationTotal = 3
  const pagination = Math.ceil(fields.length / paginationTotal)
  const indexShowEnd = paginationActive * paginationTotal
  const indexShowStart = indexShowEnd - paginationTotal

  useLayoutEffect(() => {
    setPaginationActive(pagination)
  }, [pagination])

  function setDate(name: string, month: string, year: string) {
    if (month && year) {
      const newDAte = moment(new Date(`${year}-${month}-01`)).format(
        'YYYY-MM-DD'
      )
      setValue(name, newDAte)
      clearErrors(name)
    }
  }

  function checkChangeDate(name: string) {
    const nameSplit = name?.split('.')[2] as string
    const observable = ['dateStarted_month', 'dateStarted_year']
    if (observable.includes(nameSplit)) {
      const indexSplit = name?.split('.')[1]
      const nameHidden = nameSplit.split('_')[0]
      const nameValue = `data.${indexSplit}.${nameHidden}`
      const month = watch(`data.${indexSplit}.${nameHidden}_month`)
      const year = watch(`data.${indexSplit}.${nameHidden}_year`)
      setDate(nameValue, month, year)
    }
  }

  return {
    fields,
    errors,
    indexToDelete,
    isModalOpen,
    verified,
    yearSelectAgo,

    handleSubmit,
    onSubmit,
    register,
    watch,
    append,

    handleOpenModel,
    handleCancelModelDelete,
    handleConfirmModelDelete,

    getFieldProps,
    setPaginationActive,
    setValue,
    pagination,
    paginationActive,
    indexShowStart,
    indexShowEnd,
  }
}

export default useLogic
