import { TooltipChildren } from '@viswals/components'
import Container from './TooltipMessageStyles'

const TooltipMessagePendingApproval = () => {
  return (
    <Container>
      <div className="pendingApproved">
        <TooltipChildren>
          <h2>Pending Approval</h2>
          <p>Viswals has yet to confirm you as a Freelancer Clinician</p>
        </TooltipChildren>
      </div>
    </Container>
  )
}

export default TooltipMessagePendingApproval
