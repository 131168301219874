/* eslint-disable no-shadow */
export type AccountGroup = {
  uuid: string
  name: string
  slug: string
  icon: string
  enabled?: boolean
  createdAt?: string
  updatedAt?: string
}

export enum AccountStatusEnum {
  INITIAL = 'INITIAL',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  SUSPEND = 'SUSPEND',
}
export type AccountStatusProps = keyof typeof AccountStatusEnum

export type Account = {
  uuid?: string
  username?: string
  password?: string
  firstName?: string
  lastName?: string
  nationality?: string
  dateOfBirth?: string
  mobileNumber?: string
  photo?: string
  number?: string
  status?: AccountStatusEnum
  permissions?: string[]
  accountGroup?: string
  inviteToken?: string
  createdAt?: string
  updatedAt?: string
}

export type Permission = {
  uuid: string
  name: string
  enabled?: boolean
  createdAt: string
  updatedAt: string
}
