import { MenuContentProps } from '@viswals/components-platform'

const dataMainMenuItems: MenuContentProps[] = [
  {
    label: 'Notifications Center',
    icon: 'Notifications',
    route: '/notifications',
  },
  {
    label: 'Global View',
    icon: 'GlobalView',
    route: '/global-view',
  },
  {
    label: 'Clinician Registration',
    icon: 'Document5',
    route: '/document',
  },
  {
    label: 'Fish Pool',
    icon: 'Pool',
    route: '/fishpool',
  },

  {
    label: 'Chat',
    icon: 'Message1',
    route: '/chat',
  },
  {
    label: 'My Team',
    icon: 'Group1',
    route: '/super-administrator',
  },
  {
    label: 'Tasks',
    icon: 'Document6',
    route: '/tasks',
  },
  {
    label: 'Reports',
    icon: 'Computer',
    route: '/reports',
  },
  {
    label: 'Administration',
    icon: 'Settings',
    route: '/administration',
  },

  {
    label: 'Logout',
    icon: 'Logout1',
    route: '/logout',
  },
]

export default dataMainMenuItems
