import React from 'react'
import { Card, Toggle } from '@viswals/components'

import { FinishRegistrationProps } from '../data'

import useLogic from './useLogic'
import BoxScroll from './BoxScroll'

import Container from './TermsOfServiceStyles'

const StepTermsOfService = () => {
  const {
    fields,
    control,
    verified,

    watch,
    onSubmit,
    handleSubmit,

    handleScroll,
  } = useLogic()
  return (
    <Container>
      <div className="vw-termsOfService-header">
        <div>
          <h2>Terms of Service</h2>
          <span>Read carefully and accept the terms of service</span>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="vw-termsOfService-cardArea">
          {fields.map((i, indexCard) => {
            const item = i as unknown as FinishRegistrationProps
            const isEnabled = watch(`data.${indexCard}.isDisabled`)
            // const isError = watch(`data.${indexCard}.isError`);
            return (
              <React.Fragment key={i.id}>
                <Card isVerified={verified}>
                  <div className="vw-termsOfService-img">
                    <img
                      src={item.img}
                      alt="Terms of Service"
                      className={`img_${item.uuid}`}
                    />
                  </div>
                  <BoxScroll
                    item={item}
                    handleScroll={handleScroll}
                    indexCard={indexCard}
                  />
                  <div className={`toggleArea ${!isEnabled ? 'enabled' : ''}`}>
                    <p>I understand and agree with the above</p>
                    <Toggle
                      name={`data.${indexCard}.isConfirmed`}
                      control={control}
                      isDisabled
                      required
                    />
                  </div>
                </Card>
              </React.Fragment>
            )
          })}
        </div>
      </form>
    </Container>
  )
}

export default StepTermsOfService
