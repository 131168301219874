import styled from 'styled-components'

export const SuperAdministratorBox = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 32rem 1fr;
  grid-template-rows: 3.5rem 1fr;
  gap: 1rem;

  .admCard {
    display: flex;
    border-radius: 0.5rem;
    background-color: #222224;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);

    &:nth-child(1) {
      grid-column: 1 / span 2;
      grid-row: 1;
      padding: 0 4rem;
    }
    &:nth-child(2) {
      grid-column: 1 / span 1;
      grid-row: 2;
    }
    &:nth-child(3) {
      grid-column: 2 / span 1;
      grid-row: 2;
    }
  }
`
