import BarProgress from '@src/components/BarProgress'
import { StepItemBox } from './style'
import { VerificationStatusProps } from '../../type'

export type StepItemProps = {
  title?: string
  subtitle?: string
  steps: VerificationStatusProps[]
  active?: boolean
}

const StepItem = (props: StepItemProps) => {
  const totalSteps = props.steps.length
  const totalCompleted = props.steps.filter((f) => !!f).length
  const percent = Math.ceil((totalCompleted / totalSteps) * 100)

  return (
    <StepItemBox active={props.active}>
      <div className="title">{props.subtitle}</div>
      <div className="details">
        <b>{props.title}</b>
        <div className="details-percent">
          <span>P. </span>
          <span className={percent > 0 ? 'color' : ''}>{percent}%</span>
        </div>
      </div>
      <div className="progress">
        <div className="progress-full">
          <BarProgress percent={percent} />
        </div>
        <div className="progress-steps">
          {props.steps.map((c, index) => {
            const p = c === null ? 0 : 100
            let bgColor
            if (c === 'ERROR') {
              bgColor = '#F72A1E'
            }
            if (c === 'QUESTION_RAISED') {
              bgColor = '#FE9F1F'
            }

            return (
              <BarProgress
                key={`${props.subtitle}-${index}`}
                percent={p}
                bgColor={bgColor}
              />
            )
          })}
        </div>
      </div>
    </StepItemBox>
  )
}

export default StepItem
