import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-payments-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-payments-cardArea {
    display: flex;
    .vw-card-cardBox {
      width: 47.4rem;
      padding: 4.9rem 5.8rem;
      margin-right: 2.5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .entitiesArea {
        width: 35.9rem;
        height: 19.5rem;
        padding-top: 7.55rem;

        background-color: #333843;
        border-radius: 1.3rem;
        box-shadow: 0 0 14px 5px #282b33;

        > img {
          width: 13.3rem;
          margin: -0.1rem 0 0.1rem -0.2rem;
        }

        h2 {
          font-size: 1.4rem;
          margin: -0.1rem 0 0 -0.1rem;
        }

        p {
          font-size: 0.9rem;
          text-transform: uppercase;
          color: #798089;
          letter-spacing: 0.25rem;
          margin-top: 1.3rem;
        }
      }

      .linkArea {
        width: 100%;
        height: 12.1rem;
        padding-top: 4.9rem;

        .vw-icon {
          width: 2rem;
          height: 2rem;
          color: #798089;
        }
      }

      .selectCardArea {
        width: 36.1rem;
        height: 18rem;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: #21252e;
        border-radius: 1.3rem;
        border: 0.1rem solid #333843;
        box-shadow: 0 0 14px 5px #282b33;

        padding-bottom: 0.1rem;

        transition: all 0.6s ease-in-out;

        &.active {
          border-color: #19aeff;
        }

        .vw-select-boxContentInput {
          width: 9rem;
          margin-left: -1.6rem;

          .vw-select-BoxInput {
            background: transparent !important;
            padding: 0;
            margin: 0;
            height: 1.7rem;

            .vw-select-inputFieldStyle {
              &::placeholder {
                font-family: var(--font-primary);
                font-size: 1.2rem;
                color: #15aeff;
              }
            }

            .vw-select-boxIconRight {
              display: none;
            }
          }

          .vw-selectOptions-boxSelectOptions {
            top: -11rem;
            right: -21.5rem;
            padding: 1rem 1rem 2rem;

            .vw-selectOptions-boxScrollOptions {
              width: 24.5rem !important;
              padding: 0rem;
            }
            .vw-selectOptions-boxSelectOptionsLabel {
              padding-bottom: 0.6rem;
              text-align: left;
            }

            .vw-selectOptions-boxContentScrollItem {
              margin-bottom: 0;
            }
          }
        }

        .linkCardIcon {
          width: 3rem !important;
          height: 3rem;
          color: #333843;
          margin-left: -0.5rem;

          &.right {
            margin-left: -1.4rem;
          }
        }
      }
    }
  }
`

const ContentFlipCards = styled.div`
  width: 36rem;
  height: 18rem;
  perspective: 1000px;
  .innerBox {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.3s;
    transform-style: preserve-3d;
    &.full {
      transform: rotateX(180deg);
    }
    .selectCardArea {
      position: absolute;
      backface-visibility: hidden;
    }
    .vw-SafeContentCard {
      position: absolute;
      backface-visibility: hidden;
      transform: rotateX(180deg);
    }
  }
`

export { Container, ContentFlipCards }
