import { LevelType } from '@viswals/services'

export type LanguageLevel = {
  uuid: string
  name: string
  isoCode: LevelType
}

const dataLanguagesLevel: LanguageLevel[] = [
  {
    uuid: '001',
    name: 'Beginner',
    isoCode: 'A1',
  },
  {
    uuid: '002',
    name: 'Elementary',
    isoCode: 'A2',
  },
  {
    uuid: '003',
    name: 'Intermediate',
    isoCode: 'B1',
  },
  {
    uuid: '004',
    name: 'Upper-Intermediate',
    isoCode: 'B2',
  },
  {
    uuid: '005',
    name: 'Advanced',
    isoCode: 'C1',
  },
  {
    uuid: '006',
    name: 'Proficiency',
    isoCode: 'C2',
  },
]

export { dataLanguagesLevel }
