import { ActionProps } from "./props";

const countriesList = [
    { label: "Argentina", isoCode: "AR" },
    { label: "Australia", isoCode: "AU" },
    { label: "United States", isoCode: "US" },
    { label: "United Kingdom", isoCode: "UK" },
];

const subStepList2 = [
    { label: "Upload Photo", done: true, route: "upload-photo" },
    { label: "Contact Information", done: false, route: "contact-information" },
    {
        label: "Personal Information",
        done: false,
        route: "personal-information",
    },
    {
        label: "Upload ID",
        done: false,
        route: "upload-id",
    },
    {
        label: "Residence Information",
        done: false,
        route: "residence-information",
    },
    {
        label: "Languages",
        done: false,
        route: "languages",
    },
];

const subsFake = Array.from({ length: 6 }, (_, i) => i + 1).map((d) => ({
    label: `SubStep ${d.toString()}`,
    done: false,
    route: `substep${d}`,
}));

const stepList = [
    {
        step: "Step 1",
        title: "Company Representative",
        labelProgress: "Step Progress",
        percent: 100,
        route: "step1",
        subSteps: subsFake,
    },
    {
        step: "Step 2",
        title: "Global Profile",
        labelProgress: "Step Progress",
        percent: 20,
        route: "step2",
        subSteps: subStepList2,
    },
    {
        step: "Step 3",
        title: "Account Registration",
        labelProgress: "Step Progress",
        percent: 40,
        route: "step3",
        subSteps: subsFake,
    },
    {
        step: "Step 4",
        title: "Finish Registration",
        labelProgress: "Step Progress",
        percent: 50,
        route: "step4",
        subSteps: subsFake,
    },
];

const INITIAL_STATE = {
    countries: countriesList,
    steps: stepList,
    subSteps: [],
};

export default function reducer(state = INITIAL_STATE, action: ActionProps) {
    if (action.type === "SET_STEP_COUNTRIES") {
        return {
            ...state,
            countries: action.countries,
        };
    }

    if (action.type === "SET_STEP_SUBSTEPS") {
        return {
            ...state,
            subSteps: action.subSteps,
        };
    }

    if (action.type === "SET_STEP_STEPS") {
        return {
            ...state,
            steps: action.steps,
        };
    }

    return state;
}
