import moment from 'moment'
import { CircularProgress } from '@mui/material'

import Layout from '@src/components/Layout'
import { CurrentDate } from '@src/components/Layout/style'
import { LinkGroupWrapper } from '@src/components/Layout/UpperBar/style'
import { Icon, LinkGroup } from '@viswals/components-platform'

import {
  ContainerBodyLayout,
  ContainerUpperCalendarChildren,
  ContentLoadingList,
} from './styles'
import CardLayoutGlobalAccounts from './components/CardLayout'
import GridGlobalAccounts from './components/GridGlobalAccounts'
import ItemListHeader from './components/ItemListHeader'
import ItemListGlobalAccount from './components/ItemList'

import useLogic from './useLogic'
import { LoadingTable } from '../administration/common/_components/loading-table'

export default function GlobalAccounts() {
  const {
    activeLink,
    options,
    navigate,
    dataPatientStatus,
    handleGeneralFilter,
    filterState,
    filterPlatform,
    totalCounter,
    // listTotal,
    loadingBoxRef,
    isRequesting,
    globalIdSearchTxt,
    setGlobalIdSearchTxt,
  } = useLogic()

  return (
    <Layout
      upperBarChilden={
        <ContainerUpperCalendarChildren>
          <div className="leftBox">
            <CurrentDate>{moment().format('D MMMM YYYY')}</CurrentDate>

            <LinkGroupWrapper>
              <LinkGroup
                linkActive={activeLink}
                options={options}
                styleButton="CC_BUTTON"
                handleClick={(value) => navigate(value as string)}
              />
            </LinkGroupWrapper>
          </div>
        </ContainerUpperCalendarChildren>
      }
    >
      <ContainerBodyLayout>
        <CardLayoutGlobalAccounts>
          <GridGlobalAccounts
            platform="PATIENT"
            signUps={totalCounter?.signUps}
            acquired={totalCounter?.acquired}
            dropouts={totalCounter?.dropouts}
            total={totalCounter?.total}
            filtersPlatform={filterPlatform}
            filtersState={filterState}
          />
          <GridGlobalAccounts
            platform="CLINICIAN"
            signUps={0}
            acquired={0}
            dropouts={0}
            total={totalCounter?.total}
            filtersPlatform={filterPlatform}
            filtersState={filterState}
          />
          <GridGlobalAccounts
            platform="CLINIC"
            signUps={0}
            acquired={0}
            dropouts={0}
            total={totalCounter?.total}
            filtersPlatform={filterPlatform}
            filtersState={filterState}
          />
          <GridGlobalAccounts
            platform="ALL"
            signUps={totalCounter?.signUps}
            acquired={totalCounter?.acquired}
            dropouts={totalCounter?.dropouts}
            total={totalCounter?.total}
            filtersPlatform={filterPlatform}
            filtersState={filterState}
          />
        </CardLayoutGlobalAccounts>
        <CardLayoutGlobalAccounts classNames="search-area">
          <div className="content-input-search">
            <Icon name="Search1" />
            <input
              type="search"
              placeholder="Insert Global ID to find a client"
              value={globalIdSearchTxt}
              onChange={(e: any) => setGlobalIdSearchTxt(e.target.value)}
            />
          </div>
          <div className="content-buttons-filter">
            <div className="button-filter active">Sort By</div>
            <div className="button-filter">Date</div>
            <div className="button-filter">Time</div>
            <div className="button-filter">Status</div>
            <div className="button-filter">Platform</div>
          </div>
          <div className="content-buttons-free">
            <div
              className={`button-free app ${filterPlatform[0] === 'PATIENT' ||
                filterPlatform[1] === 'PATIENT'
                ? 'active'
                : ''
                }`}
              aria-hidden
              onClick={() => handleGeneralFilter('PATIENT', undefined)}
            >
              Viswals App
            </div>
            <div
              className={`button-free clinician ${filterPlatform[0] === 'CLINICIAN' ||
                filterPlatform[1] === 'CLINICIAN'
                ? 'active'
                : ''
                }`}
              aria-hidden
              onClick={() => handleGeneralFilter('CLINICIAN', undefined)}
            >
              Viswals Clinician
            </div>
            <div
              className={`button-free clinic ${filterPlatform[0] === 'CLINIC' || filterPlatform[1] === 'CLINIC'
                ? 'active'
                : ''
                }`}
              aria-hidden
              onClick={() => handleGeneralFilter('CLINIC', undefined)}
            >
              Viswals Clinic
            </div>
            <div
              className={`button-free sign_up ${filterState[0] === 'SIGN_UP' || filterState[1] === 'SIGN_UP'
                ? 'active'
                : ''
                }`}
              aria-hidden
              onClick={() => handleGeneralFilter(undefined, 'SIGN_UP')}
            >
              Sign Ups
            </div>
            <div
              className={`button-free acquired ${filterState[0] === 'ACQUIRED' || filterState[1] === 'ACQUIRED'
                ? 'active'
                : ''
                }`}
              aria-hidden
              onClick={() => handleGeneralFilter(undefined, 'ACQUIRED')}
            >
              Acquired
            </div>
            <div
              className={`button-free dropout ${filterState[0] === 'DROPOUT' || filterState[1] === 'DROPOUT'
                ? 'active'
                : ''
                }`}
              aria-hidden
              onClick={() => handleGeneralFilter(undefined, 'DROPOUT')}
            >
              Dropouts
            </div>
          </div>
        </CardLayoutGlobalAccounts>
        <CardLayoutGlobalAccounts classNames="search-list-area">
          <ItemListHeader />
          <div className="content-scroll-items">
            <LoadingTable />
            {dataPatientStatus.map((item, i) => {
              return <ItemListGlobalAccount key={`index_${i}`} item={item} />
            })}
            <ContentLoadingList ref={loadingBoxRef}>
              {isRequesting && <CircularProgress size={30} />}
            </ContentLoadingList>
          </div>
        </CardLayoutGlobalAccounts>
      </ContainerBodyLayout>
    </Layout>
  )
}
