import { VerificationItemProps, VerificationStatusProps } from '../../type'
import StepItem from './item'
import { VerificationStepsBox } from './style'

type VerificationStepsProps = {
  items: VerificationItemProps[]
  stepActive: number
}

const VerificationSteps = ({ items, stepActive }: VerificationStepsProps) => {
  const steps1 = items
    .filter((f) => f.step === 1)
    .map((m) => {
      if (!m.state) {
        return null
      }
      return m.state
    }) as VerificationStatusProps[]

  const steps2 = items
    .filter((f, i) => f.step === 2 || i === 0)
    .map((m) => {
      if (!m.state) {
        return null
      }
      return m.state
    }) as VerificationStatusProps[]

  return (
    <VerificationStepsBox>
      <StepItem
        title="Verification of Document Data"
        subtitle="Step 1"
        steps={steps1}
        active={stepActive === 1}
      />
      <StepItem
        title="Verification Of Facial Features"
        subtitle="Step 2"
        steps={steps2}
        active={stepActive === 2}
      />
    </VerificationStepsBox>
  )
}

export default VerificationSteps
