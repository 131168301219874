import { useEffect } from 'react'

import useDiary from '@src/hooks/useDiary'
import useReports from '@src/hooks/useReports'

import { PlatformType } from '@src/pages/reports/data'
import useClinicianRegister from '@src/hooks/useClinicianRegister'

export type upperBarReportTypes = {
  value: PlatformType
  label: string
}

const dataUpperBarOptions: upperBarReportTypes[] = [
  {
    value: 'ALL',
    label: 'All Platform',
  },
  {
    value: 'PATIENT',
    label: 'Viswals App',
  },
  {
    value: 'CLINICIAN',
    label: 'Viswals Clinician',
  },
  {
    value: 'CLINIC',
    label: 'Viswals Clinic',
  },
]

const useLogic = () => {
  const { diary, updateLandingOn } = useDiary()
  const { clinician } = useClinicianRegister()

  const {
    startSelect,
    endSelect,
    setRangeUpperCalendar,
    platform,
    setPlafform,
    reports,
    feedbacks,
    rates,
  } = useReports()

  useEffect(() => {
    updateLandingOn('DAY')
  }, [])

  function handleSetPlatform(value: PlatformType) {
    setPlafform(value)
  }

  return {
    setRangeUpperCalendar,
    startSelect,
    endSelect,
    landingOn: diary.landingOn,
    diary,
    updateLandingOn,
    clinician,
    dataUpperBarOptions,
    platform,
    reports,
    feedbacks,
    rates,
    handleSetPlatform,
  }
}

export default useLogic
