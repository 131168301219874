import { Wrapper, Status } from '@googlemaps/react-wrapper'
import useLayout from '@src/hooks/useLayout'
import Config from '@src/services/api/config'
// import { Marker as MarkerComponent } from '@viswals/components-platform'
import MyMapComponent from './map'
import Marker from './marker'
import useLogic from './useLogic'

const GoogleMap = () => {
  const googleKey = Config.REACT_APP_GOOGLE_API_KEY
  const { markers } = useLogic()
  const { setLayout } = useLayout()

  const render = (status: Status): React.ReactElement => {
    if (status === Status.FAILURE) return <span>Error</span>
    return <span>Aguarde...</span>
  }

  return (
    <Wrapper apiKey={googleKey} render={render}>
      <MyMapComponent
        zoom={9}
        center={{ lat: 39.3999, lng: -8.2245 }}
        onCenterChanged={(latLng) => setLayout('updateMapLatLng', latLng)}
      >
        {markers?.map((marker, index) => (
          <Marker key={`${index}`} {...marker} />
        ))}
      </MyMapComponent>
    </Wrapper>
  )
}

export default GoogleMap
