import StepCurrentWorkingLocation from './current-working-location'
import StepDentalInsurance from './dental-insurance'
import StepPastWorkingLocations from './past-working-location'
import StepPeriodOfCoverage from './period-of-coverage'
import StepPracticing from './practicing'

const listStep4 = [
  {
    name: 'Practicing',
    subStep: 'PRACTICING',
    route: 'practicing',
    component: <StepPracticing />,
  },
  {
    name: 'Dental Insurance',
    subStep: 'DENTAL_INSURANCE',
    route: 'dental-insurance',
    component: <StepDentalInsurance />,
  },
  {
    name: 'Period of Coverage\n& Certificate ',
    subStep: 'PERIOD_OF_COVERAGE_CERTIFICATE',
    route: 'period-of-coverage',
    component: <StepPeriodOfCoverage />,
  },
  {
    name: 'Current Working Locations',
    subStep: 'CURRENT_WORKING_LOCATIONS',
    route: 'current-working-locations',
    component: <StepCurrentWorkingLocation />,
  },
  {
    name: 'Past Working Locations',
    subStep: 'PAST_WORKING_LOCATIONS',
    route: 'past-working-locations',
    component: <StepPastWorkingLocations />,
  },
]

export default listStep4
