/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import {
  TermService,
  TermServiceFilter,
  createTermService,
  Request,
  retrieveTermService,
  updateTermService,
} from '@viswals/services'
import useLoading from '@src/hooks/useLoading'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { setNavigationConfirm } from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import { useNavigate } from 'react-router-dom'
import { FinishRegistrationProps, TermsOfService } from '../data'

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { watch, control, setValue, handleSubmit } = useForm()

  const { fields } = useFieldArray({
    control,
    name: 'data',
  })

  const { setLoading } = useLoading()
  const { subStepDone, nextRoute, verified } = useClinicianRegister()

  const [isReady, setIsReady] = useState<boolean>(false)
  const [checkAll, setCheckAll] = useState<boolean>(true)
  const { next } = useSelector((state: ReducersProps) => state.navigation)

  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  // detect changes
  useEffect(() => {
    const subscription = watch((form, { type }) => {
      const isAllTrue = form.data.every(
        (item: any) => item.isConfirmed === true
      )

      if (isAllTrue && type === 'change') {
        setCheckAll(true)
        dispatch(setNavigationConfirm(true))
        subStepDone()
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  const handleScroll = (event: any, indexCard: number) => {
    const { scrollTop, offsetHeight, scrollHeight } = event.target

    if (scrollTop + offsetHeight > scrollHeight - 20) {
      const watchValue = watch(`data.${indexCard}.isDisabled`)
      if (watchValue === true && !verified) {
        setValue(`data.${indexCard}.isDisabled`, false)
      }
    }
  }

  const getData = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      const requestRetrieve = await retrieveTermService({
        fields: [
          'uuid',
          'countryRegistration',
          'legalNotice',
          'privacyPolicy',
          'termsConditions',
        ],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
          countryRegistration: registrationActive.countryRegistration,
        },
      })
      const data: TermService[] = requestRetrieve?.data?.data ?? []

      const newArray: FinishRegistrationProps[] = TermsOfService.map(
        (term: FinishRegistrationProps) => {
          let isConfirmed = false
          if (data.length > 0) {
            isConfirmed =
              term.type === 'termsConditions'
                ? data[0].termsConditions === true
                : term.type === 'legalNotice'
                ? data[0].legalNotice === true
                : data[0].privacyPolicy === true
          }
          const newData: FinishRegistrationProps = {
            uuid: term.uuid,
            title: term.title,
            img: term.img,
            description: term.description,
            isDisabled: true,
            isConfirmed,
            isVerified: verified,
            isError: false,
            item: data.length > 0 ? data[0].uuid : null,
          }
          return newData
        }
      )
      if (newArray?.length) {
        setCheckAll(true)
      }
      setValue(`data`, newArray)
    } catch (error) {
      return null
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  const onSubmit = async () => {
    if (!checkAll) {
      return false
    }
    const newFields = watch(`data`)
    const isAllTrue = newFields.every((item: any) => item.isConfirmed === true)

    if (!isAllTrue) {
      return null
    }

    const dataCreate: TermService = {
      uuid: newFields[0].item ?? undefined,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
      countryRegistration: registrationActive.countryRegistration,
      legalNotice: true,
      privacyPolicy: true,
      termsConditions: true,
    }

    if (dataCreate.uuid) {
      await updateTermService(dataCreate as Request<TermServiceFilter>)
    } else {
      await createTermService(dataCreate as Request<TermServiceFilter>)
    }

    navigate(nextRoute)
  }

  return {
    fields,
    control,
    verified,

    watch,
    onSubmit,
    handleSubmit,

    handleScroll,
  }
}

export default useLogic
