import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SelectedOptionsProps } from '@viswals/components'
import { ReducersProps } from '@src/types/reducers'
import {
  setNavigationActiveNext,
  setNavigationConfirm,
} from '@src/store/actions/navigation'
import transformToSelectOptions from '@src/core/transformToSelectOptions'
import {
  City,
  createCity,
  createResidenceInformation,
  Request,
  ResidenceInformation,
  ResidenceInformationFilter,
  retrieveResidenceInformation,
  updateResidenceInformation,
} from '@viswals/services'
import useStepsError from '@src/hooks/useStepsError'
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import useDatas from '@src/hooks/useDatas'

export type FormSubmitProps = ResidenceInformation & {
  newCity?: string
}
type handleCreateCityProps = {
  cityName: string
  countryUuid: string
}

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { getCities } = useDatas()
  const {
    handleSubmit,
    register,
    watch,
    reset,
    setValue,
    setError,
    formState: { errors },
  } = useForm()
  const { getErrors, getFieldProps } = useStepsError(errors)
  const { subStepDone, nextRoute, verified } = useClinicianRegister()
  const { loading, setLoading } = useLoading()
  const [isReady, setIsReady] = useState<boolean>(false)

  const { next, confirm } = useSelector(
    (state: ReducersProps) => state.navigation
  )
  const { registrationActive, steps } = useSelector(
    (state: ReducersProps) => state.clinician
  )

  const selectResidenceCountry: SelectedOptionsProps[] = []
  const { countries, cities } = useSelector(
    (state: ReducersProps) => state.datas
  )

  countries?.map((item) => {
    selectResidenceCountry.push({
      label: `${item.name}`,
      value: `${item.uuid}`,
      icon: `${item.icon}`,
    })
    return null
  })

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (!loading) {
      getErrors(setError)
    }
  }, [steps, loading])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type }) => {
      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  useEffect(() => {
    if (next && !loading && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  const citiesLocal = useCallback((): SelectedOptionsProps[] => {
    const countryUuid = watch('country')
    const filterCities = cities?.filter(
      (city) => city.countryUuid === countryUuid
    )
    const selectResidenceCity = transformToSelectOptions({
      array: filterCities,
      keyLabel: 'name',
      keyValue: 'uuid',
    })
    return selectResidenceCity
  }, [watch('country'), cities])

  async function onSubmit(data: FormSubmitProps) {
    data = {
      ...data,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
    }
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)

    if (data.newCity) {
      try {
        const createdCountry = await handleCreateCity({
          cityName: data.newCity,
          countryUuid: `${data.country}`,
        })
        if (typeof createdCountry !== 'undefined') {
          data.city = createdCountry.uuid
        }
      } catch (error) {
        setLoading(false)
      }
    }

    try {
      const dataToSave: FormSubmitProps = {
        ...data,
        newCity: undefined,
      }
      if (!dataToSave.uuid) {
        await createResidenceInformation(
          dataToSave as Request<ResidenceInformation>
        )
      } else {
        await updateResidenceInformation(
          dataToSave as Request<ResidenceInformation>
        )
      }
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setLoading(false)
    }
  }

  async function getData() {
    setLoading(true)
    try {
      const request: Request<ResidenceInformationFilter> = {
        fields: ['uuid', 'country', 'city', 'address', 'postCode', 'verified'],
        filters: {
          clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        },
      }
      const resp = await retrieveResidenceInformation(request)
      const data: ResidenceInformation | null = resp.data?.data
        ? resp.data.data[0]
        : {}

      if (data?.country) {
        getCities(data.country)
      }
      setTimeout(() => {
        const dataReset: ResidenceInformation = {
          country: data?.country,
          city: data?.city,
          address: data?.address,
          postCode: data?.postCode,
          verified: data?.verified,
          uuid: data?.uuid,
        }
        reset(dataReset)
        setLoading(false)
        dispatch(setNavigationActiveNext(true))
      }, 200)
    } finally {
      setLoading(false)
      setIsReady(true)
    }
  }

  const handleCreateCity = async ({
    countryUuid,
    cityName,
  }: handleCreateCityProps): Promise<undefined | City> => {
    try {
      const request: Request<City> = {
        data: {
          countryUuid,
          name: cityName,
        },
      }
      const resp = await createCity(request)
      const data: City | null = resp.data ?? null
      if (data) {
        return data
      }
      return undefined
    } catch (error) {
      return undefined
    }
  }

  return {
    errors,
    verified,
    selectResidenceCountry,
    handleSubmit,
    onSubmit,
    register,
    watch,
    citiesLocal,
    setValue,
    getFieldProps,
    getCities,
  }
}

export default useLogic
