import { Routes, Route } from 'react-router-dom'

import Layout from '../layout'

import CountryTable from './table'
import FormCreateCountry from './form_create'
import FormUpdateCountry from './form_update'

export default function CountryPages() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <CountryTable />
          </Layout>
        }
      />
      <Route
        path="/new"
        element={
          <Layout>
            <FormCreateCountry />
          </Layout>
        }
      />
      <Route
        path="/edit/:uuid"
        element={
          <Layout>
            <FormUpdateCountry />
          </Layout>
        }
      />
    </Routes>
  )
}
