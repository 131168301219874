import VerificationBoxIcon from '@images/verificationBox.svg'
import StatusVerified from '@images/statusVerified.svg'
import StatusQuestionRaised from '@images/statusQuestionRaised.svg'
import StatusError from '@images/statusError.svg'
import {
  ColorxBox,
  ContainerBox,
  HeaderBox,
  ListBox,
  QuickActions,
} from './style'
import { VerificationItemProps, VerificationStatusProps } from '../../type'
import VerificationItem from './item'

type VerificationItemsProps = {
  items: VerificationItemProps[]
  updateState: (uuid: string, state: VerificationStatusProps) => void
  quickAction: (state: string) => void
}

const VerificationItems = ({
  items,
  updateState,
  quickAction,
}: VerificationItemsProps) => {
  const statusList = [
    { label: 'ERROR', icon: StatusError },
    { label: 'QUESTION_RAISED', icon: StatusQuestionRaised },
    { label: 'VERIFIED', icon: StatusVerified },
  ]

  return (
    <ContainerBox>
      <HeaderBox>
        <div className="col">
          <img src={VerificationBoxIcon} alt="Verification Box Icon" />
        </div>
        <div className="col">
          <div>agent</div>
          <div>abel Moreira</div>
        </div>
        <div className="col">
          <div>COUNTEr</div>
          <div>00:00</div>
        </div>
      </HeaderBox>

      <ColorxBox>
        <div />
        <div />
        <div />
        <div />
      </ColorxBox>

      <ListBox>
        {items.map((item, index) => (
          <VerificationItem
            key={`verification-item-${index}`}
            {...item}
            updateState={updateState}
          />
        ))}
      </ListBox>
      <QuickActions>
        <div>QUICK actions</div>
        {statusList.map((s, i) => (
          <div
            key={`i-${i}`}
            aria-hidden
            className="action"
            onClick={() => quickAction(s.label)}
          >
            <img src={s.icon} alt={s.label} />
          </div>
        ))}
      </QuickActions>
    </ContainerBox>
  )
}

export default VerificationItems
