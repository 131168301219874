import React from 'react'
import ContainerCirclePercentNumber from './CirclePercentNumberStyles'

export type CirclePercentNumberProps = {
  isRed?: boolean
  isMini?: boolean
  percent?: number
}

const CirclePercentNumber = ({
  isRed,
  percent,
  isMini,
}: CirclePercentNumberProps) => {
  return (
    <ContainerCirclePercentNumber
      isRed={isRed}
      isMini={isMini}
      className="vwp-ContainerCirclePercentNumber"
    >
      <div className="vwp-contentInside">
        <div className="vwp-contentNumber">{percent}</div>
        <div className="vwp-contentPercent">%</div>
      </div>
    </ContainerCirclePercentNumber>
  )
}

export default CirclePercentNumber
