/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-await-in-loop */
import { useCallback, useEffect, useState } from 'react'
import transformToSelectOptions from '@src/core/transformToSelectOptions'
import useLoading from '@src/hooks/useLoading'
import useClinicianRegister from '@src/hooks/useClinicianRegister'
import {
  setNavigationConfirm,
  setNavigationSkip,
} from '@src/store/actions/navigation'
import { ReducersProps } from '@src/types/reducers'
import { SelectedOptionsProps } from '@viswals/components'
import {
  Country,
  CourseTitleFilter,
  createCourseTitle,
  createInstitution,
  Institution,
  InstitutionFilter,
  MajorCourse,
  MajorCourseFilter,
  Request,
  saveMajorCourse,
} from '@viswals/services'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import handleRetrieveAwardedTitle from '../degree/utils/handleRetrieveAwardedTitle'
import handleRetrieveInstituions from '../degree/utils/handleRetrieveInstitutions'
import handleRetrieveMajorCourses from './utils/handleRetrieveMajorCourses'

type MajorCourseHookForm = {
  institutionText?: string
  awardCourseTitleText?: string
  awardCourseTitleSelect?: string
  fieldDentistrySelect?: string
  certifiedCourseHoursUnknow?: boolean
  certificate?: {
    file: string
    fileName: string
  }
  courseProgressionText?: string
  isError?: boolean
} & MajorCourse

const useLogic = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setLoading } = useLoading()
  const { subStepDone, nextRoute, verified } = useClinicianRegister()

  const { handleSubmit, watch, register, setValue, getValues, control } =
    useForm()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
  })

  const [selectedIndex, setSelectedIndex] = useState<number>(0)
  const [isReady, setIsReady] = useState<boolean>(false)

  const [selectDelete, setSelectDelete] = useState<number | null>(null)
  const [isModalOpen, setIsModelOpen] = useState<boolean>(false)
  const [destroyList, setDestroyList] = useState<MajorCourseHookForm[]>([])

  const [institutionsLocal, setInstitutionsLocal] = useState<Institution[]>([])
  const [coursesTitle, setCoursesTitle] = useState<SelectedOptionsProps[]>([])

  const countriesState: Country[] = useSelector(
    (state: ReducersProps) => state.datas.countries
  )
  const { registrationActive } = useSelector(
    (state: ReducersProps) => state.clinician
  )
  const fieldDentistry: any = useSelector(
    (state: ReducersProps) => state.datas.fields
  )

  const { next } = useSelector((state: ReducersProps) => state.navigation)

  const countries = transformToSelectOptions({
    array: countriesState.filter(
      (country) =>
        `${country.continent}` === watch(`data.${selectedIndex}.continent`)
    ),
    keyLabel: 'name',
    keyValue: 'uuid',
    keyIcon: 'icon',
  })

  const institutions = transformToSelectOptions({
    array:
      institutionsLocal.filter(
        (inst) =>
          `${inst.countryUuid}` === watch(`data.${selectedIndex}.country`)
      ) ?? [],
    keyLabel: 'name',
    keyValue: 'uuid',
  })

  const dataFieldDentistry = transformToSelectOptions({
    array: fieldDentistry,
    keyLabel: 'name',
    keyValue: 'uuid',
  })

  const { confirm } = useSelector((state: ReducersProps) => state.navigation)

  useEffect(() => {
    getData()
  }, [registrationActive])

  useEffect(() => {
    if (fields.length === 0 && destroyList.length === 0) {
      setTimeout(() => {
        dispatch(setNavigationSkip(true))
      }, 100)
    } else {
      setTimeout(() => {
        dispatch(setNavigationSkip(false))
      }, 100)
    }
  }, [fields.length])

  useEffect(() => {
    if (next && isReady) {
      handleSubmit(onSubmit)()
    }
  }, [next])

  useEffect(() => {
    if (!verified) {
      document.addEventListener('mousedown', handleClick)
      return () => {
        document.removeEventListener('mousedown', handleClick)
      }
    }
  }, [selectedIndex, selectDelete, verified])

  // detect changes
  useEffect(() => {
    const subscription = watch((_, { type, name }) => {
      if (name?.split('.')[2] === 'country' && type === 'change') {
        handleRetrieveInstituions({
          country: watch(`${name}`),
          setInstitutionsLocal,
        })
      }
      if (type === 'change' && !confirm) {
        dispatch(setNavigationConfirm(true))
      }
    })
    return () => subscription?.unsubscribe()
  }, [watch])

  const handleAddMajorCourse = (underCompletion: boolean) => {
    if (underCompletion === true) {
      append({ destroy: false, courseProgression: '0' })
      setSelectedIndex(fields.length)
    } else {
      append({ destroy: false })
      setSelectedIndex(fields.length)
    }
  }

  function handleClick(e: any) {
    if (document.getElementsByClassName('vw-select').length) {
      return false
    }
    const pathList = e.path as any[]
    const getCardMap = pathList
      ?.map((m) => m.className)
      ?.filter((f) => f && f?.includes('card-item'))
    const checkCard = !!getCardMap?.length
    if (checkCard) {
      const safeArea = !!pathList
        ?.map((m) => m.className)
        ?.filter((f) => f && f?.includes('vw-form'))?.length
      if (!safeArea) {
        handleSelectDelete()
      }
    }
  }

  const handleSelectDelete = () => {
    if (selectDelete === null) {
      setSelectDelete(selectedIndex)
    } else {
      setSelectDelete(null)
    }
  }

  const getData = async () => {
    setIsReady(false)
    setLoading(true)
    try {
      await handleRetrieveMajorCourses({
        registrationActive,
        dispatch,
        append,
        institutionsLocal,
        setInstitutionsLocal,
      })
      handleRetrieveAwardedTitle({
        type: 'MAJOR',
        setCoursesTitle,
      })
      setLoading(true)
      setIsReady(true)
    } catch (error) {
      return null
    } finally {
      setLoading(false)
    }
  }

  const handleConfirmModelDelete = useCallback(() => {
    const uuid = getValues(`data.${selectDelete}.uuid`)
    const itemObj = watch(`data.${selectDelete}`)
    const obj = {
      ...itemObj,
      continent: 0,
      courseDuration: 0,
      certifiedCourseHours: 0,
      courseProgression: 0,
      destroy: true,
      clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
    }

    if (uuid) {
      setDestroyList((list) => [...list, obj])
    }
    remove(selectDelete as number)

    if (!confirm) {
      dispatch(setNavigationConfirm(true))
    }

    setSelectedIndex((idx) => (idx > 0 ? idx - 1 : 0))

    setSelectDelete(null)
    setTimeout(() => {
      setIsModelOpen(false)
    }, 200)
  }, [isModalOpen])

  const handleCancelModelDelete = useCallback(() => {
    setSelectDelete(null)
    setIsModelOpen(false)
  }, [isModalOpen])

  const handleOpenModel = useCallback(() => {
    setIsModelOpen(true)
  }, [isModalOpen])

  async function getConvertMajorCourses(array: MajorCourseHookForm[]) {
    const convertDegrees: MajorCourseHookForm[] = []
    for (const [index, item] of array.entries()) {
      let intitutionText = null
      let courseTitleText = null

      if (
        item.certifiedCourseHours === undefined &&
        item.certifiedCourseHoursUnknow === undefined
      ) {
        // setError(`data.${index}.certifiedCourseHours`, {})
        // setError(`data.${index}.certifiedCourseHoursUnknow`, {})
        item.isError = true
        return [item]
      }

      if (
        // @ts-ignore
        item.courseProgression === '0' &&
        (item.courseProgressionText === undefined ||
          item.courseProgressionText === '')
      ) {
        // setError(`data.${index}.courseProgression`, {})
        // setError(`data.${index}.courseProgressionText`, {})

        item.isError = true
        return [item]
      }

      if (
        item.institution === undefined &&
        (item.institutionText === undefined || item.institutionText === '')
      ) {
        // setError(`data.${index}.institution`, {})
        // setError(`data.${index}.institutionText`, {})

        item.isError = true
        return [item]
      }

      if (
        item.fieldDentistry === undefined &&
        item.fieldDentistrySelect === undefined
      ) {
        // setError(`data.${index}.fieldDentistry`, {})
        // setError(`data.${index}.fieldDentistrySelect`, {})
        item.isError = true

        return [item]
      }

      if (
        (item.awardCourseTitleText === undefined ||
          item.awardCourseTitleText === '') &&
        item.awardCourseTitleSelect === undefined &&
        item.awardCourseTitle === undefined
      ) {
        // setError(`data.${index}.awardCourseTitleText`, {})
        // setError(`data.${index}.awardCourseTitleSelect`, {})
        item.isError = true

        return [item]
      }

      if (item)
        if (item.institutionText && item.institutionText.length > 0) {
          const saveIntitutions = await createInstitution({
            name: item.institutionText,
            countryUuid: item.country,
          } as Request<InstitutionFilter>)
          if (saveIntitutions.data) {
            intitutionText = saveIntitutions.data
          }
        }

      if (item.awardCourseTitleText && item.awardCourseTitleText.length > 0) {
        const saveAwardCourseTitle = await createCourseTitle({
          title: item.awardCourseTitleText,
          type: 'MAJOR',
        } as Request<CourseTitleFilter>)
        if (saveAwardCourseTitle.data) {
          courseTitleText = saveAwardCourseTitle.data
        }
      }

      convertDegrees.push({
        ...item,
        clinicianRegisterUuid: registrationActive.clinicianRegisterUuid,
        certificate: undefined,
        destroy: item?.destroy ? item.destroy : false,
        continent: Number(item.continent),
        courseDuration: Number(item.courseDuration),
        courseCertificate: item?.certificate?.file,
        courseCertificateName: item?.certificate?.fileName,
        institution: intitutionText ?? item.institution,
        courseProgression: item.courseProgression
          ? Number(item.courseProgression)
          : undefined,
        institutionText: undefined,
        awardCourseTitle:
          courseTitleText ||
          (item.awardCourseTitleSelect ?? item.awardCourseTitle),
        awardCourseTitleText: undefined,
        awardCourseTitleSelect: undefined,
        certifiedCourseHours:
          item.certifiedCourseHoursUnknow === true
            ? 0
            : Number(item.certifiedCourseHours),
        fieldDentistry: item?.fieldDentistrySelect ?? item?.fieldDentistry,
        fieldDentistrySelect: undefined,
        certifiedCourseHoursUnknow: undefined,
      })
    }
    return convertDegrees
  }

  const onSubmit = async (data: any) => {
    if (!confirm) {
      navigate(nextRoute)
      return false
    }
    setLoading(true)
    try {
      const convertDegreesInArray: MajorCourseHookForm[] = Object.values(
        data.data
      )
      const dataPayload = await getConvertMajorCourses(convertDegreesInArray)

      const findDataPayloadError = dataPayload.filter((item) => {
        return item.isError === true
      })

      if (findDataPayloadError.length > 0) {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
        return null
      }

      await saveMajorCourse([
        ...destroyList,
        ...dataPayload,
      ] as Request<MajorCourseFilter>)
      subStepDone()
      navigate(nextRoute)
    } catch (error) {
      setTimeout(() => {
        setLoading(false)
      }, 1000)
      return null
    }
  }

  return {
    isModalOpen,
    coursesTitle,
    institutions,
    countries,
    fields,
    selectDelete,
    selectedIndex,
    dataFieldDentistry,
    handleOpenModel,
    setSelectedIndex,
    handleAddMajorCourse,
    handleConfirmModelDelete,
    handleCancelModelDelete,
    onSubmit,
    handleSubmit,
    register,
    watch,
    setValue,
  }
}

export default useLogic
