import StepPaymentsAccount from './payments-account'
import StepTermsOfService from './term-of-services'
import StepViswalsPolicies from './viswals-policies'

const listStep7 = [
  {
    name: 'Terms Of Service',
    subStep: 'TERMS_OF_SERVICE',
    route: 'terms-of-service',
    component: <StepTermsOfService />,
  },
  {
    name: 'Viswals Policies',
    subStep: 'VISWALS_POLICIES',
    route: 'viswals-policies',
    component: <StepViswalsPolicies />,
  },
  {
    name: 'Payments Account',
    subStep: 'PAYMENTS_ACCOUNT',
    route: 'payments-account',
    component: <StepPaymentsAccount />,
  },
]

export default listStep7
