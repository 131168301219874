import styled from 'styled-components'

export const ContainerReportStyles = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  gap: 1rem;
`

export const ContainerSection = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  gap: 1rem;

  margin-top: 1rem;

  main {
    width: 32rem;
    height: 100%;

    background-color: #222224;

    border-radius: 0.5rem;

    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);

    .boxTitle {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.8rem 2rem;
      h4 {
        color: #aeaeb2;

        font-size: 1rem;
        font-family: 'Poppins';
      }
      .boxHighlight {
        height: 2.4rem;
        background-color: #5bdf6f09;
        padding: 0.8rem 0.6rem 0.7rem 1.2rem;
        border-radius: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;
        cursor: pointer;
        span {
          color: #5bdf6f;
          font-size: 0.9rem;
          font-family: 'Oxanium';
          font-weight: 700;
          line-height: 1.3rem;
          &:first-of-type {
            color: #d4f7da;
          }
        }
      }
    }

    .itemToClick {
      width: 100%;
      display: flex;
      padding: 2rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      .contentLeft {
        width: 100%;
        display: flex;

        .contentIcon {
          width: 1.6rem;
          height: 1.6rem;
          color: #8e8e93;
          margin-top: 0.3rem;
        }
        .contentLabel {
          padding-left: 2.4rem;
          font-size: 1rem;
          font-family: 'Poppins';
          font-weight: 700;
          color: #aeaeb2;
          margin-top: 0.3rem;
        }
      }
      .contentNotify {
        width: 6rem;
        height: 2.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1.6rem;
        gap: 0.4rem;
        div {
          width: 2.8rem;
          height: 2.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.5rem;
          background-color: #5bdf6f09;
          color: #5bdf6f;
          font-size: 1rem;
          font-family: 'Poppins';
          font-weight: 700;
          &:first-of-type {
            background-color: #f72a1e15;
            color: #f72a1e;
          }
        }
      }

      &:hover {
        background-color: #2c2c2e;
      }
      &.isActive {
        background-color: #1c1c1e;
      }
    }
  }

  section {
    width: 151rem;
    height: 100%;

    padding: 0 2rem;

    background-color: #222224;

    border-radius: 0.5rem;

    -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.27);
  }
`

export const LocalUpperBarLinkGroup = styled.div`
  width: 45.4rem;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 1rem;

  margin-right: 2rem;

  button {
    width: 10.6rem;
    height: 2.4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 0.5rem;

    font-size: 1rem;
    font-family: 'Oxanium';
    line-height: 1.5rem;

    color: #aeaeb2;

    background-color: #1c1c1e;

    outline: none;
    text-decoration: none;
    border: none;

    cursor: pointer;

    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #5bdf6f15;
      color: #5bdf6f;
    }
    &.isActive {
      background-color: #5bdf6f15;
      color: #5bdf6f;
    }
  }
`
