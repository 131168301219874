const NotFoundPage = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1 style={{ fontSize: 60 }}>404</h1>
    </div>
  )
}

export default NotFoundPage
