/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from 'react'
import Layout from '@src/components/Layout'
import CardFile from './card'
import { CardFileGroup, OpenBox } from './style'

const Chat = () => {
  const [list] = useState([])
  const [open, setOpen] = useState(null)

  // async function retrieve() {
  //   try {
  //     const { data } = await api.post(
  //       '/patient/authentication/selfie-verification-video/list'
  //     )

  //     setList(data)
  //   } catch (e) {
  //     console.error(e)
  //   }
  // }

  const mp4: boolean = `${open}`.split('.mp4').length >= 2

  return (
    <Layout>
      <div>
        <OpenBox>
          {open && (
            <>
              {mp4 && (
                <video width="100%" height="100%" controls autoPlay muted>
                  <source src={open} type="video/mp4" />
                </video>
              )}
              {!mp4 && (
                <figure>
                  <img src={`${open}`} alt="image" />
                </figure>
              )}
            </>
          )}
        </OpenBox>

        <CardFileGroup>
          {list.map((e) => (
            <div key={e} aria-hidden onClick={() => setOpen(e)}>
              <CardFile url={e} />
            </div>
          ))}
        </CardFileGroup>
      </div>
    </Layout>
  )
}

export default Chat
