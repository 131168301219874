import styled from 'styled-components'

const ContainerGridGlobalAccounts = styled.div<{
  color: string
  backgroundColor: string
}>`
  width: 100%;
  max-width: 44.75rem;

  display: flex;
  flex-direction: column;

  .header-grid {
    width: 100%;
    max-width: 44.75rem;
    height: 4.8rem;

    margin-bottom: 1rem;

    padding: 1.6rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 0.5rem;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);

    background-color: ${(props) => props.backgroundColor};
    color: ${(props) => props.color};

    font-size: 1.2rem;
    font-weight: bold;
    font-family: var(--font-third);

    transition: all 0.3s ease-in-out;

    cursor: pointer;

    .content-counter {
      font-size: 1.5rem;
    }

    .header-grid-left {
      display: flex;
      align-items: center;
      gap: 1.6rem;
    }

    svg {
      height: 1.6rem;
    }
  }

  .body-grid {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: var(--font-third);
    color: #ebebeb;

    gap: 1rem;

    .item-body-grid {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.8rem 0.85rem 0.6rem;
      background-color: #ebebeb05;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
      border-radius: 0.5rem;

      cursor: pointer;

      transition: all 0.3s ease-in-out;

      font-family: var(--font-third);
      font-size: 1rem;

      span {
        font-weight: bold;
        font-size: 1.5rem;
        margin: 0.4rem 0 0.2rem;
      }

      &.grid-acquired {
        background-color: #00ff8905;
        color: #00ff89;
      }
      &.grid-dropouts {
        color: #636366;
        background-color: #63636615;
      }
      &.DISABLED {
        background-color: #222224 !important;
        color: #363638 !important;
      }
    }
  }

  &.DISABLED {
    .header-grid {
      background-color: #222224 !important;
      color: #363638 !important;

      cursor: not-allowed;
    }
    .body-grid {
      .item-body-grid {
        color: #363638;
        background-color: #222224;
        cursor: not-allowed;
        &.grid-acquired {
          background-color: #222224 !important;
          color: #363638 !important;
        }
        &.grid-dropouts {
          color: #363638;
          background-color: #222224;
        }
      }
    }
  }
`

export default ContainerGridGlobalAccounts
