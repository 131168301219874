import React from 'react'
import {
  Button,
  Card,
  Icon,
  Input,
  QuestionModal,
  Select,
  Tooltip,
  PaymentCard,
  SelectedOptionsProps,
} from '@viswals/components'
import MiniPagination from '@src/components/pagination/SubstepMiniPagination'
// import getColorLuminance from "@src/core/getColorLuminance";
import { Container } from './WalletStyles'
import useLogic, { localWallet } from './useLogic'
import CountriesFlags from './CountriesFlags'
import ColorPicker from './ColorPicker'

import dataColors from './data/arrayColors'
import { accountTypeValues } from './data/data'

const StepWallet = () => {
  const {
    fields,
    FlipCardRef,
    countries,
    errors,
    verified,
    indexToDelete,
    isModalOpen,
    safeArea,
    safeArea2,
    safeArea3,
    safeArea4,

    setPaginationActive,

    getFieldProps,

    watch,
    setValue,
    register,
    onSubmit,
    handleSubmit,

    handleOpenModel,

    handleClickCard,

    handleSelectColor,
    handleOpenCountries,

    handleCancelModelDelete,
    handleConfirmModelDelete,

    pagination,
    paginationActive,
    indexShowStart,
    indexShowEnd,
    banks,
  } = useLogic()

  return (
    <Container>
      <div className="vw-wallet-header">
        <div>
          <h2>Wallet</h2>
          <span>Add cards to your wallet</span>
        </div>

        <div className="buttonArea">
          {indexToDelete.length > 0 ? (
            <>
              <div className="DangerButton">
                <Button
                  icon="Remove1"
                  label={`Delete Card${indexToDelete.length >= 2 ? 's' : ''}`}
                  handleClick={handleOpenModel}
                />
              </div>

              {isModalOpen ? (
                <QuestionModal
                  title={`Delete Card${indexToDelete.length >= 2 ? 's' : ''}`}
                  description={`You are about to delete ${
                    indexToDelete.length >= 2 ? '' : 'a'
                  } bank card${
                    indexToDelete.length >= 2 ? 's' : ''
                  }. This action cannot be undone. 
                                    Are you sure you want to proceed?`}
                  cancelLabel="Oops, take me back"
                  confirmLabel={`Yes, delete card${
                    indexToDelete.length >= 2 ? 's' : ''
                  }`}
                  cancelFunction={handleCancelModelDelete}
                  confirmFunction={handleConfirmModelDelete}
                />
              ) : null}
            </>
          ) : fields.length > 3 ? (
            <MiniPagination
              pages={pagination}
              active={paginationActive}
              onChangeActive={setPaginationActive}
            />
          ) : null}
        </div>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {fields.map((card, indexCard) => {
          if (pagination > 1) {
            if (!(indexCard >= indexShowStart && indexCard < indexShowEnd)) {
              return null
            }
          }

          const itemWatch: localWallet = watch(`data.${indexCard}`)

          const findCountry = countries.find((country) => {
            return country.uuid === itemWatch.country
          })
          const countryUuid = watch(`data.${indexCard}.country`)
          const bankList = banks
            .filter((f) => f.countryUuid === countryUuid)
            .map((bank) => ({
              label: bank.name,
              value: bank.uuid,
            })) as SelectedOptionsProps[]
          const findBank = bankList.find((bank) => {
            return bank.value === itemWatch.bank
          })

          const findAccountType = accountTypeValues.find(
            (type) => type.value === itemWatch.accountType
          )?.label
          // @ts-ignore
          if (card.destroy === true) {
            return null
          }
          // @ts-ignore
          const hasCardError = errors && errors.data && errors.data[indexCard]
          const isReadyDelete = indexToDelete.includes(indexCard)
          const tooltipStatus = itemWatch.colorPickerOpen
          return (
            <div
              key={card.id}
              className="vw-wallet-cardArea"
              onClick={(event) => {
                if (!verified) {
                  handleClickCard(event, indexCard)
                }
              }}
              aria-hidden
            >
              <input
                type="hidden"
                {...register(`data.${indexCard}.color`, {
                  required: true,
                  value: '',
                })}
              />
              <Card isError={hasCardError} readyDelete={isReadyDelete}>
                <div
                  className="headerArea"
                  ref={(ref) => {
                    safeArea2.current[indexCard] = ref
                  }}
                >
                  <div
                    className="countyArea"
                    onClick={() => {
                      handleOpenCountries(undefined, indexCard)
                    }}
                    aria-hidden
                  >
                    <img src={findCountry?.icon} alt={findCountry?.name} />
                    <p>{findCountry?.name}</p>
                  </div>

                  <Tooltip
                    placement="topLeft"
                    visible={tooltipStatus}
                    onVisibleChange={() => {
                      setValue(
                        `data.${indexCard}.colorPickerOpen`,
                        !tooltipStatus
                      )
                    }}
                    align={{
                      offset: [50, 100],
                    }}
                    overlay={
                      <ColorPicker
                        color={watch(`data.${indexCard}.color`)}
                        colors={dataColors}
                        indexCard={indexCard}
                        refColors={safeArea4}
                        handleSelectColor={handleSelectColor}
                      />
                    }
                    destroyTooltipOnHide
                    trigger={['click']}
                    motion={{
                      motionName: 'rc-tooltip-fade',
                    }}
                  >
                    <div className="pickColorArea">
                      <Icon
                        name="ColorPallet"
                        styles={{
                          color: itemWatch.color ?? undefined,
                        }}
                      />
                    </div>
                  </Tooltip>
                </div>

                <div ref={FlipCardRef} className="contentCardPayment">
                  <div className="cardBack">
                    <PaymentCard
                      accountName={itemWatch.accountName}
                      accountNumber={itemWatch.accountNumber}
                      accountType={findAccountType}
                      bankName={findBank?.label}
                      color={itemWatch.color}
                      sortCode={itemWatch.sortCode}
                    />
                  </div>
                </div>
                <div
                  className="formArea"
                  ref={(ref) => {
                    safeArea.current[indexCard] = ref
                  }}
                >
                  {/* <Input
                                        placeholder="Bank name"
                                        formRegister={{
                                            ...register(
                                                `data.${indexCard}.bank`,
                                                {
                                                    required: true,
                                                }
                                            ),
                                        }}
                                        {...getFieldProps(
                                            `data.${indexCard}.bank`
                                        )}
                                    /> */}
                  <Select
                    labelTitle="Select the Bank"
                    placeholder="Bank name"
                    options={bankList}
                    formRegister={{
                      ...register(`data.${indexCard}.bank`, {
                        required: true,
                      }),
                      watch,
                    }}
                    {...getFieldProps(`data.${indexCard}.bank`)}
                  />
                  <Select
                    labelTitle="Select the account type"
                    placeholder="Account type"
                    options={accountTypeValues}
                    formRegister={{
                      ...register(`data.${indexCard}.accountType`, {
                        required: true,
                      }),
                      watch,
                    }}
                    {...getFieldProps(`data.${indexCard}.accountType`)}
                  />
                  <Input
                    placeholder="Account name"
                    formRegister={{
                      ...register(`data.${indexCard}.accountName`, {
                        required: true,
                      }),
                    }}
                    {...getFieldProps(`data.${indexCard}.accountName`)}
                  />
                  <div className="accountNumberArea">
                    <Input
                      placeholder="Account number"
                      formRegister={{
                        ...register(`data.${indexCard}.accountNumber`, {
                          required: true,
                        }),
                      }}
                      {...getFieldProps(`data.${indexCard}.accountNumber`)}
                    />
                    <Input
                      placeholder="Sort Code"
                      formRegister={{
                        ...register(`data.${indexCard}.sortCode`, {
                          required: true,
                        }),
                      }}
                      {...getFieldProps(`data.${indexCard}.sortCode`)}
                    />
                  </div>
                </div>
                {Boolean(watch(`data.${indexCard}.countryOpen`)) === true ? (
                  <div
                    ref={(ref) => {
                      safeArea3.current[indexCard] = ref
                    }}
                  >
                    <CountriesFlags
                      countries={countries}
                      onClickCountry={(country) => {
                        handleOpenCountries(country, indexCard)
                      }}
                    />
                  </div>
                ) : null}
              </Card>
            </div>
          )
        })}
      </form>
    </Container>
  )
}

export default StepWallet
