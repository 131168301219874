import styled, { css } from 'styled-components'

export type LayoutStyledProps = {
  openMainMenu?: boolean
  openWorkspaceSubMenu?: boolean
}

export const LayoutStyled = styled.div<LayoutStyledProps>`
  display: flex;

  transition: all 0.3s ease-in-out;
  background: var(--gray-900-color);

  overflow: hidden;
  .vw-mainMennu-containerMainMenu {
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    /* .vw-mainMenu-mainMenuIconTop {
      overflow: hidden;
      padding: 0px;
      svg {
        color: white;
      }
    } */
  }
  padding-left: 4rem;

  ${(props) => {
    if (props.openMainMenu) {
      return css`
        padding-left: 0rem;
        margin-left: 17.3rem;
        /* .vw-mainMennu-containerMainMenu {
          width: 17.3rem !important;
        } */
      `
    }
    if (props.openWorkspaceSubMenu) {
      return css`
        .vw-WorkSpaceSubMenu {
          transition: opacity 0.3 ease-in-out;
          transform: translateX(5.6rem);
        }
        .vw-mainMennu-containerMainMenu {
          margin-left: 5.6rem !important;
        }
        .vw-upperBar-containerUpperBar {
          margin-left: 5.6rem !important;
        }
      `
    }
  }}

  .vw-upperBar-containerUpperBar {
    transition: all 0.3s ease-in-out;
    width: 100vw;
    margin-bottom: 2rem;
  }

  .vw-stepOverview-boxStepOverview {
    transition: opacity 0.3 ease-in-out;
    &:hover {
      opacity: 0.8;
    }
  }

  .contentArea {
    /* height: 100%; */
    margin: 2rem;

    .contentHeader {
      position: relative;
      height: 9.8rem;
      width: 100%;
    }

    .vw-mainContainer-boxMainContainer {
      margin: 2rem 0;
    }
  }
`

export const UpperBarLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  height: 100%;

  svg {
    height: 1.6rem !important;
  }
`

export const UpperBarRightContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: 61.7rem;
  height: 100%;
`

export const CurrentDate = styled.span`
  margin-bottom: 0.4rem;
  font-family: var(--font-third);
  font-size: 1.4rem;
  color: var(--gray-430-color);
`

export const LinkGroupWrapper = styled.div`
  margin-left: 1.7rem;
`

export const ButtonGroupWrapper = styled.div``

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
`

export const IconWrapper = styled.div`
  cursor: pointer;
`
