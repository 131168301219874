import styled from 'styled-components'

const StyledContentAllergy = styled.div`
  height: 100%;

  &.styled-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .content-mini-form-box {
    width: 40rem;

    .vw-upload-boxInputUpload {
      margin-bottom: 2rem;
    }
    .vw-input-boxContentInput {
      margin-bottom: 2rem;
      .vw-input-boxInput {
        background-color: #1c1c1e;
      }
    }
    .vw-select-BoxInput {
      background-color: #1c1c1e;
    }

    .vw-button-buttonstyle {
      margin-top: 4rem;
      border-radius: 0.8rem;
      font-size: 1.6rem;
      background-color: #8e8e93;
      color: #242426;
    }
    .content-button-cancell {
      width: 100%;
      padding: 3rem 2rem 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;

      cursor: pointer;
    }

    .content-icon-upload {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 2rem;
      padding-right: 2rem;
      input {
        width: 1rem;
      }
      .box-preview {
        /* background-color: teal; */
        height: 7.2rem;

        .box-preview-title {
          color: #bebebe;
          font-size: 1.4rem;
          font-weight: bold;
          font-family: var(--font-primary);
          margin-bottom: 1rem;
        }

        .box-preview-image {
          width: 5.2rem;
          height: 5.2rem;
          margin-top: 1rem;
          img {
            width: 5rem;
            margin-top: 1rem;
          }
        }
      }
    }

    .content-toggle-switch {
      display: flex;

      margin-top: 4rem;

      .vw-toggle-toggleStyle {
        cursor: pointer;
      }

      .box-preview {
        color: #bebebe;
        font-size: 1.4rem;
        font-weight: bold;
        font-family: var(--font-primary);
        margin-bottom: 1rem;
        margin-left: 4rem;
      }
    }
    .boxError {
      color: yellow;
      font-size: 1.2rem;

      font-family: var(--font-primary);
      text-align: center;
    }
  }
`
const StyledContentAllergyTable = styled.div`
  height: calc(100% - 10rem);
  position: relative;
`

export { StyledContentAllergy, StyledContentAllergyTable }
