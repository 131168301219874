import createArrayOfYears from '@src/core/createArrayOfYears'
import { CourseProviderType, StudyModeType } from '@viswals/services'

export type DataApiMockProps = {
  name: string
  uuid: string
}

//
//  Continents
//

export const dataContinents: DataApiMockProps[] = [
  {
    name: 'America',
    uuid: '0',
  },
  {
    name: 'Europe',
    uuid: '1',
  },
  {
    name: 'Asia',
    uuid: '2',
  },
  {
    name: 'Australia',
    uuid: '3',
  },
  {
    name: 'Africa',
    uuid: '4',
  },
]

//
//  Degree Duration
//

export const dataDegreeDurations: DataApiMockProps[] = [
  {
    name: '3 Years',
    uuid: '1080',
  },
  {
    name: '4 Years',
    uuid: '1440',
  },
  {
    name: '5 Years',
    uuid: '1800',
  },
  {
    name: '6 Years',
    uuid: '2160',
  },
  {
    name: '7 Years',
    uuid: '2520',
  },
  {
    name: 'Other',
    uuid: '2880',
  },
]

//
//  Major Course Duration
//

export const dataMajorCourseDuration: DataApiMockProps[] = [
  {
    name: '6 Months',
    uuid: '180',
  },
  {
    name: '9 Months',
    uuid: '270',
  },
  {
    name: '1 Year',
    uuid: '360',
  },
  {
    name: '2 Years',
    uuid: '720',
  },
  {
    name: '3 Years',
    uuid: '1080',
  },
  {
    name: 'Other',
    uuid: '1440',
  },
]

//
//  Short Course Duration
//

export const dataShortCourseDuration: DataApiMockProps[] = [
  {
    name: '1 day',
    uuid: '1',
  },
  {
    name: '2 days',
    uuid: '2',
  },
  {
    name: '3 days',
    uuid: '3',
  },
  {
    name: '4 days',
    uuid: '4',
  },
  {
    name: '5 days',
    uuid: '5',
  },
  {
    name: '6-8 days',
    uuid: '6',
  },
]

//
//  Course Under Completion Progression
//

export const dataCourseUnderProgression: DataApiMockProps[] = [
  {
    name: 'Started Recently',
    uuid: '1',
  },
  {
    name: '1st Year Completed',
    uuid: '360',
  },
  {
    name: '2nd Year Completed',
    uuid: '720',
  },
]

export type CoursesProvider = {
  name: string
  uuid: CourseProviderType
}

export const dataProviderCourses: CoursesProvider[] = [
  {
    name: 'Public University',
    uuid: 'PUBLIC_UNIVERSITY',
  },
  {
    name: 'Private University',
    uuid: 'PRIVATE_UNIVERSITY',
  },
  {
    name: 'Private Provider',
    uuid: 'PRIVATE_PROVIDER',
  },
]

export type StudyModes = {
  name: string
  uuid: StudyModeType
}

export const dataStudyMode: StudyModes[] = [
  {
    name: 'Face-2-Face',
    uuid: 'FACE_2_FACE',
  },
  {
    name: 'Distance Learning',
    uuid: 'DISTANCE_LEARNING',
  },
  {
    name: 'Blended Mode',
    uuid: 'BLENDED_MODE',
  },
]

//
//  Calendar INFO
//

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const monthSelect = Array.from({ length: 12 }, (_, i) => i + 1).map(
  (d) => ({
    label: months[d - 1],
    value: d,
  })
)

export const yearSelect = createArrayOfYears({
  startYear: 1960,
  finishYear: 2008,
})
  .map((d) => ({
    label: d.toString(),
    value: d,
  }))
  .reverse()
