import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import {
  Bank,
  BankFilter,
  Country,
  PaginationRequest,
  Request,
  retrieveBank,
} from '@viswals/services'
import gsap from 'gsap'
import { Button } from '@viswals/components-platform'

import { useSelector } from 'react-redux'
import { ReducersProps } from '@src/types/reducers'
import HeaderContentCommon from '../_components/HeaderContentCommon'
import { StyledContentCity, StyledContentContryTable } from './styles'
import gridStyle from '../_components/grid-style'
import { LoadingTable } from '../_components/loading-table'

export type LocalBankAdm = {
  id?: number
} & Bank

export type LocalPaginationRequestProps = {
  total?: number
} & PaginationRequest

export default function BankTable() {
  const navigate = useNavigate()

  const [banks, setBanks] = useState<LocalBankAdm[]>([])
  const { countries } = useSelector((state: ReducersProps) => state.datas)
  const [pagination, setPagination] = useState<LocalPaginationRequestProps>({
    itemsPerPage: 25,
    page: 1,
    total: 0,
  })

  useEffect(() => {
    ; (async () => {
      await getLocalBanks(pagination)
    })()
  }, [])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },

    {
      field: 'countryUuid',
      headerName: 'Country',
      renderCell: (params) => {
        const findCountry: Country | undefined = countries.find(
          (v) => v.uuid === params.row.countryUuid
        )
        return <>{findCountry?.name}</>
      },
      width: 200,
    },
    {
      field: 'enabled',
      headerName: 'Enabled',
      renderCell: (params) => {
        const countryToSave: Country = params.row
        return <>{countryToSave.enabled ? 'YES' : 'NO'}</>
      },
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        return (
          <div style={{ display: 'flex', gap: 20 }}>
            <Button
              label="Edit"
              uuid=""
              style={{ backgroundColor: 'orange', color: 'black' }}
              handleClick={() => {
                const bankToSave: Bank = params.row
                window.localStorage.setItem(
                  'bankEdit',
                  JSON.stringify(bankToSave)
                )
                navigate(`/administration/common/bank/edit/${bankToSave.uuid}`)
              }}
            />
          </div>
        )
      },
      align: 'center',
      headerAlign: 'center',
      width: 100,
      sortable: false,
    },
  ]

  async function getLocalBanks(
    paginationed: LocalPaginationRequestProps | undefined
  ) {
    try {
      await getBanks(paginationed)
    } catch (error) {
      console.error({
        error,
      })
    }
  }

  async function getBanks(
    paginationed: LocalPaginationRequestProps | undefined
  ) {
    const request: Request<BankFilter> = {
      fields: ['uuid', 'name', 'countryUuid', 'enabled'],
      pagination: {
        itemsPerPage: 25,
        page: paginationed?.page as number,
      },
      orderBy: {
        column: 'name',
        direction: 'asc',
      },
    }
    try {
      const resp = await retrieveBank(request)
      const { data } = resp

      if (data.code === 200) {
        const dataCities: Bank[] = data.data

        const newCities: LocalBankAdm[] = dataCities.map((value, _index) => {
          return {
            id: _index,
            uuid: value.uuid,
            name: value.name,
            countryUuid: value.countryUuid,
            enabled: value?.enabled ?? false,
          }
        })
        setBanks(newCities)

        setTimeout(() => {
          setPagination(
            (value) =>
            ({
              ...value,
              ...{
                itemsPerPage: paginationed?.itemsPerPage,
              },
              total: data.pagination
                ? data.pagination.total
                : data.data.length,
            } as LocalPaginationRequestProps)
          )
        }, 200)
        disabledLoading()
      }
    } catch (e) {
      console.error(e)
    }
  }

  function handleClickAdd() {
    navigate('/administration/common/bank/new')
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function addPage(page: number) {
    const pageToSet: PaginationRequest = {
      itemsPerPage: 25,
      page: page + 1,
    }
    try {
      await getLocalBanks(pageToSet)
    } catch (error) {
      console.error({
        error,
      })
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async function enabledLoading() {
    const query = window.document.querySelector('.vw-loadingContainer')
    gsap.to(query, {
      zIndex: 99,
      duration: 0.1,
      onComplete: () => {
        gsap.to(query, {
          opacity: 1,
          delay: 0.1,
          duration: 0.25,
        })
      },
    })
  }

  function disabledLoading() {
    setTimeout(() => {
      const query = window.document.querySelector('.vw-loadingContainer')
      gsap.to(query, {
        opacity: 0,
        duration: 0.25,
        onComplete: () => {
          gsap.to(query, {
            zIndex: -1,
            delay: 0.25,
            duration: 0.25,
          })
        },
      })
    }, 300)
  }

  return (
    <StyledContentCity>
      <HeaderContentCommon
        name="Bank"
        description="Manager bank information"
        onClickAdd={() => handleClickAdd()}
      />

      <StyledContentContryTable>
        <LoadingTable />
        <DataGrid
          sx={gridStyle}
          rows={banks}
          columns={columns}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          experimentalFeatures={{ newEditingApi: false }}
          onPageChange={(page) => {
            enabledLoading().then(() => {
              setTimeout(() => {
                addPage(page)
              }, 200)
            })
          }}
          pagination
          rowsPerPageOptions={[25]}
          rowCount={pagination.total}
          initialState={{
            pagination: {
              pageSize: pagination.itemsPerPage,
            },
          }}
          paginationMode="server"
        />
      </StyledContentContryTable>
    </StyledContentCity>
  )
}
