import styled from 'styled-components'

export const LoadingContainer = styled.div`
  width: 100%;
  height: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;

  .ring {
    transform: translate(-50%, -50%);
    background: transparent;
    border: 3px solid #3c3c3c;
    border-radius: 50%;
    text-transform: uppercase;
    width: 50px;
    height: 50px;
  }
  .ring:before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 100%;
    height: 100%;
    border: 3px solid transparent;
    border-top: 3px solid var(--emerald-color);
    border-right: 3px solid var(--emerald-color);
    border-radius: 50%;
    animation: animateC 1s linear infinite;
  }

  @keyframes animateC {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(405deg);
    }
  }
`
