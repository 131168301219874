import React, { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import axios from 'axios'

import Images from "@images/index";
import { LoadingTable } from '@src/pages/administration/common/_components/loading-table';
import useDiary from '@src/hooks/useDiary';

import {
    BottomLoadMoreContainer,
    MainPatientContainer,
    NoDataContainer,
    TableChildContainer,
    TableContainer,
    TableIcons,
    TableInnerTxtSpan,
    TableLabel,
    TableLabelsContainer,
    TableRowContainer,
    TableTxt,
    TableUpperBar,
    TableUpperBarsContainer,
    TableWebButton,
    UpperBar,
    UpperBarCount,
    UpperBarIcon,
    UpperBarName,
    UpperBarsContainer
} from "./style";
import Config from '@src/services/api/config';

interface PreRegistrationListProps {
    openUpperCalendar: boolean;
    selectedAgentData: any;
}

const PreRegistrationList = ({
    openUpperCalendar,
    selectedAgentData
}: PreRegistrationListProps) => {

    const [allUSerTypeCount, setAllUSerTypeCount] = useState<any>([
        { Icon: Images.MobileIcon, name: 'app user', count: 0, bg_color: '#1E2A40', text_color: '#2C7DFF' },
        { Icon: Images.MenIcon, name: 'PRO', count: 0, bg_color: '#1F342F', text_color: '#36BA91' },
        { Icon: Images.BuildingIcon, name: 'Business', count: 0, bg_color: '#332739', text_color: '#B665D5' },
        { Icon: Images.MenWithSuitIcon, name: 'Investors', count: 0, bg_color: 'rgb(255, 124, 124, 0.15)', text_color: '#FF7C7C' },
        { Icon: Images.NetworkTowerIcon, name: 'By Interest', count: 0, bg_color: '#0C0C0D', text_color: '#E3E3E3' },
        { Icon: Images.NetworkTowerIcon, name: 'By Logs', count: 0, bg_color: '#0C0C0D', text_color: '#E3E3E3' },
    ]);

    const [tableBars] = useState<any>([
        { name: 'Log Info', text_color: '#7C7C80', bg_color: '#2C2C2E' },
        { name: 'Bio Information & App user', text_color: '#2C7DFF', bg_color: 'rgb(44, 125, 255, 0.05)' },
        { name: 'Pro User', text_color: '#36BA91', bg_color: 'rgb(54, 186, 145, 0.05)' },
        { name: 'Business User', text_color: '#B665D5', bg_color: 'rgb(182, 101, 213, 0.05)' },
        { name: 'Investors', text_color: '#FF7C7C', bg_color: 'rgb(255, 124, 124, 0.05)' },
        { name: 'PR Agents', text_color: '#7c7c80', bg_color: '#2C2C2E' },
    ]);

    const [tableLabels] = useState<any>([
        { name: 'Interests', width: '88px' },
        { name: 'Log Date', width: '72px' },
        { name: 'Time', width: '51px' },
        { name: 'Name', width: '96px' },
        { name: 'Country', width: '80px' },
        { name: 'Email', width: '171px' },
        { name: 'Countries Of Practice', width: '140px' },
        { name: 'Registration Type', width: '110px' },
        { name: 'M Career Level', width: '96px' },
        { name: 'M Expertise', width: '88px' },
        { name: 'D Career Level', width: '96px' },
        { name: 'D Expertise', width: '85px' },
        { name: 'P Career Level', width: '96px' },
        { name: 'P Expertise', width: '64px' },
        { name: 'Business Location', width: '107px' },
        { name: 'Business Type', width: '92px' },
        { name: 'Nr. Units', width: '57px' },
        { name: 'Type of Investor', width: '94px' },
        { name: 'Stage', width: '53px' },
        { name: 'PR Agent', width: '64px' },
    ]);

    let [preRegistrationData, setPreRegistrationData] = useState<any>([])
    const [isApiStarted, setApiStarted] = useState<boolean>(false)
    const [isApiCall, setApiCall] = useState<boolean>(false)

    const { diary } = useDiary()

    const paginationCount = useRef<number>(0)
    const diaryRef = useRef<any>(null)
    const noMoreDataRef = useRef<boolean>(false)

    useEffect(() => {
        if (diaryRef.current && diaryRef.current.landingOn === diary.landingOn &&
            diaryRef.current.upperCalendarEndSelect === diary.upperCalendarEndSelect &&
            diaryRef.current.upperCalendarStartSelect === diary.upperCalendarStartSelect) {
            return
        }

        diaryRef.current = diary
        paginationCount.current = 0
        noMoreDataRef.current = false
        getPreRegistrationData()
        getPreRegistrationCount()
    }, [diary])

    useEffect(() => {
        paginationCount.current = 0
        noMoreDataRef.current = false
        getPreRegistrationData()
        getPreRegistrationCount()
    }, [selectedAgentData])

    const getPreRegistrationCount = async () => {

        let url = '/pre-register/v2/get-counts', hasDateRange = diaryRef.current?.landingOn !== "AS_LEFT" && diaryRef.current?.upperCalendarStartSelect && diaryRef.current?.upperCalendarEndSelect
        if (hasDateRange)
            url = `${url}?from=${diaryRef.current?.upperCalendarStartSelect}&to=${diaryRef.current?.upperCalendarEndSelect}`;

        if (selectedAgentData && selectedAgentData?.referral_code)
            url = `${url}${hasDateRange ? '&' : '?'}referral-code=${selectedAgentData.referral_code}`

        const allData = await axios.get(Config.REACT_APP_GATEWAY_URL + url, { headers: { 'Authorization': "Bearer 21c24623-0daa-452a-a7d4-156b5702a962" } })

        if (allData.status === 200 && allData.data) {
            const { current_total = 0, current_total_unique = 0, current_regular = 0, current_business = 0, current_professional = 0, current_investor = 0 } = allData.data
            setAllUSerTypeCount([
                { Icon: Images.MobileIcon, name: 'app user', count: current_regular, bg_color: '#1E2A40', text_color: '#2C7DFF' },
                { Icon: Images.MenIcon, name: 'PRO', count: current_professional, bg_color: '#1F342F', text_color: '#36BA91' },
                { Icon: Images.BuildingIcon, name: 'Business', count: current_business, bg_color: '#332739', text_color: '#B665D5' },
                { Icon: Images.MenWithSuitIcon, name: 'Investors', count: current_investor, bg_color: 'rgb(255, 124, 124, 0.15)', text_color: '#FF7C7C' },
                { Icon: Images.NetworkTowerIcon, name: 'By Interest', count: current_total, bg_color: '#0C0C0D', text_color: '#E3E3E3' },
                { Icon: Images.NetworkTowerIcon, name: 'By Logs', count: current_total_unique, bg_color: '#0C0C0D', text_color: '#E3E3E3' },
            ])
        }
    }

    const getPreRegistrationData = async () => {
        if (isApiStarted || noMoreDataRef.current) return;

        setApiStarted(true)

        let url = `/pre-register/v2/retrieve`
        if (diaryRef.current?.landingOn !== "AS_LEFT" && diaryRef.current?.upperCalendarStartSelect && diaryRef.current?.upperCalendarEndSelect)
            url = `${url}?from=${diaryRef.current?.upperCalendarStartSelect}&to=${diaryRef.current?.upperCalendarEndSelect}`;

        const body: any = { "fields": [], "pagination": { "page": paginationCount.current, "itemsPerPage": 30 } }

        if (selectedAgentData && selectedAgentData?.referral_code) {
            body.filters = { "referral_code": selectedAgentData.referral_code }
        }

        const allData = await axios.post(Config.REACT_APP_GATEWAY_URL + url, body, { headers: { 'Authorization': "Bearer 21c24623-0daa-452a-a7d4-156b5702a962" } })
        paginationCount.current += 1
        if (allData.status === 200 && allData?.data?.data) {
            if (paginationCount.current === 1) preRegistrationData = [];
            if (!(allData.data.data || []).length) noMoreDataRef.current = true;
            setPreRegistrationData([...preRegistrationData, ...(allData.data.data)]);
        } else if (paginationCount.current === 1) {
            noMoreDataRef.current = true
            setPreRegistrationData([])
        } else {
            noMoreDataRef.current = true
        }

        setApiCall(true)
        setApiStarted(false)
    }

    const handleScroll = (e: any) => {
        if (isApiStarted || noMoreDataRef.current) return;
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            getPreRegistrationData()
        }
    }

    return (
        <MainPatientContainer>
            <UpperBarsContainer>
                {allUSerTypeCount.map(({ Icon, name, count, ...rest }: any, index: number) =>
                    <UpperBar key={index} {...rest}>
                        <UpperBarIcon><Icon /></UpperBarIcon>
                        <UpperBarName>{name}</UpperBarName>
                        <UpperBarCount>{count}</UpperBarCount>
                    </UpperBar>
                )}
            </UpperBarsContainer>
            <TableContainer>
                <TableUpperBarsContainer>
                    {tableBars.map(({ name, ...rest }: any, index: number) => <TableUpperBar key={index} {...rest}>{name}</TableUpperBar>)}
                </TableUpperBarsContainer>
                <TableLabelsContainer grid_cols={tableLabels.map(({ width }: any) => width).join(' ')}>
                    {tableLabels.map(({ name }: any) => <TableLabel>{name}</TableLabel>)}
                </TableLabelsContainer>
                <TableChildContainer onScroll={handleScroll} height={openUpperCalendar ? 365 : 229}>
                    {!!preRegistrationData && !!preRegistrationData.length &&
                        preRegistrationData.map((registrationItem: any, index: number) =>
                            <TableRowContainer key={index} grid_cols={tableLabels.map(({ width }: any) => width).join(' ')}>
                                <TableIcons>
                                    <Images.MobileIcon height="12" width="12" color={(registrationItem?.options || []).find((ele: any) => ele.type === 'user_type' && ele.name === 'regular') ? '#2C7DFF' : "#48484A"} />
                                    <Images.MenIcon height="12" width="12" color={(registrationItem?.options || []).find((ele: any) => ele.type === 'user_type' && ele.name === 'professional') ? '#36BA91' : "#48484A"} />
                                    <Images.BuildingIcon height="12" width="12" color={(registrationItem?.options || []).find((ele: any) => ele.type === 'user_type' && ele.name === 'business') ? '#B665D5' : "#48484A"} />
                                    <Images.MenWithSuitIcon height="12" width="12" color={(registrationItem?.options || []).find((ele: any) => ele.type === 'user_type' && ele.name === 'investor') ? '#FF7C7C' : "#48484A"} />
                                </TableIcons>
                                <TableTxt>{moment.utc(registrationItem.createdAt).local().format("DD.MM.YYYY")}</TableTxt>
                                <TableTxt>{moment.utc(registrationItem.createdAt).local().format("HH:mm")}</TableTxt>
                                <TableTxt>{`${registrationItem.forename || ''} ${registrationItem.surname || ''}`}</TableTxt>
                                <TableTxt>{registrationItem?.country?.name || ''}</TableTxt>
                                <TableTxt>{registrationItem?.email || ''}</TableTxt>
                                <TableTxt gap="10px">
                                    <TableInnerTxtSpan>{(registrationItem?.practiceLocation || []).map((ele: any) => ele.name).join(", ")}</TableInnerTxtSpan>
                                </TableTxt>
                                <TableTxt gap="10px">
                                    <TableInnerTxtSpan>{(registrationItem?.options || []).filter((ele: any) => ele.type === 'registration_type').map((ele: any) => ele.name).join(", ")}</TableInnerTxtSpan>
                                </TableTxt>
                                <TableTxt>{(registrationItem?.options || []).find((ele: any) => ele.type === 'medical_career_level_type')?.name || ''}</TableTxt>
                                <TableTxt>{(registrationItem?.specialization || []).filter((ele: any) => ele.type === 1).map((ele: any) => ele.name).join(", ")}</TableTxt>
                                <TableTxt>{(registrationItem?.options || []).find((ele: any) => ele.type === 'dentist_career_level_type')?.name || ''}</TableTxt>
                                <TableTxt>{(registrationItem?.specialization || []).filter((ele: any) => ele.type === 2).map((ele: any) => ele.name).join(", ")}</TableTxt>
                                <TableTxt>{(registrationItem?.options || []).find((ele: any) => ele.type === 'psychologist_career_level_type')?.name || ''}</TableTxt>
                                <TableTxt>{(registrationItem?.specialization || []).filter((ele: any) => ele.type === 3).map((ele: any) => ele.name).join(", ")}</TableTxt>
                                <TableTxt gap="10px">
                                    <TableInnerTxtSpan>{(registrationItem?.businessLocation || []).map((ele: any) => ele.name).join(", ")}</TableInnerTxtSpan>
                                </TableTxt>
                                <TableIcons>
                                    {(registrationItem?.options || []).some((ele: any) => ele.type === 'expertise_type') &&
                                        <>
                                            <Images.ToothIcon height="12" width="12" color={(registrationItem?.options || []).find((ele: any) => ele.type === 'expertise_type' && ele.name === 'dentist') ? '#13D7E5' : "#48484A"} />
                                            <Images.HeartIcon height="12" width="12" color={(registrationItem?.options || []).find((ele: any) => ele.type === 'expertise_type' && ele.name === 'medical') ? '#4DAFEB' : "#48484A"} />
                                            <Images.BrainIcon height="12" width="12" color={(registrationItem?.options || []).find((ele: any) => ele.type === 'expertise_type' && ele.name === 'mental') ? '#C88BFC' : "#48484A"} />
                                        </>
                                    }
                                </TableIcons>
                                <TableTxt>{registrationItem?.businessSize || ''}</TableTxt>
                                <TableTxt>{(registrationItem?.options || []).filter((ele: any) => ele.type === 'investor_type').map((ele: any) => ele.name).join(", ")}</TableTxt>
                                <TableTxt>{(registrationItem?.options || []).filter((ele: any) => ele.type === 'investor_stage_type').map((ele: any) => ele.name).join(", ")}</TableTxt>
                                <TableTxt>{registrationItem?.referral_code}</TableTxt>
                            </TableRowContainer>
                        )
                    }

                    {!!isApiCall && !preRegistrationData.length &&
                        <NoDataContainer>No Data Found</NoDataContainer>
                    }

                    {isApiStarted && (paginationCount.current === 0 ?
                        <LoadingTable />
                        :
                        <BottomLoadMoreContainer>
                            <div className="ring">
                                <span />
                            </div>
                        </BottomLoadMoreContainer>
                    )}

                </TableChildContainer>
            </TableContainer>
        </MainPatientContainer>
    )
}

export default PreRegistrationList;