import styled, { css } from 'styled-components'

export const ContainerDegree = styled.section`
  width: 100%;
  height: 100%;

  padding: 4.3rem 5rem 2.5rem 5.1rem;

  position: relative;

  fieldset {
    border: none;
  }

  h1 {
    line-height: 3rem;
    font-size: 2rem;
    font-weight: 700;
    font-family: var(--font-primary);
    color: var(--gray-100-color);
  }
  .vw-regis-headerButtons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .vw-regis-boxLeft {
      display: flex;
      margin-top: 0.9rem;
    }
    .vw-regis-boxRight {
      margin-top: 0.6rem;
      display: flex;
      .vw-regist-buttonDanger {
        margin-right: 2rem;
        .vw-button-buttonstyle {
          background-color: #fd3333;
          padding: 0.9rem 0.6rem 0.9rem 1.1rem;
        }
      }
      .vw-button-buttonstyle {
        width: 13.1rem;
        height: 4.2rem;
        border-radius: 0.5rem;
        background-color: var(--info-color);
        padding: 0.9rem 0.9rem 0.9rem 1.1rem;
        span {
          margin-bottom: 0.1rem;
          font-family: var(--font-primary);
          font-size: 1.2rem;
        }
      }
    }
  }
  .vw-card-cardBox {
    margin-top: 2.6rem;
    width: 147.1rem;
    height: 59.5rem;
    padding: 1.8rem 0rem 3.5rem 2.4rem;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    .vw-regis-articleColum {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      border-right: 1px solid #333843;

      &.vw-regis-where {
        width: 33.8rem;

        margin-right: 3.65rem;
        .vw-regis-safeArea {
          .vw-regis-safeAreaBottom {
            p {
              font-size: 1.2rem;
            }
            margin-top: 6.1rem;
            .vw-select-boxContentInput {
              margin-top: 0.6rem;
              .vw-select-BoxInput {
                input {
                  padding-left: 0.1rem;
                }
                .vw-select-boxIconRight {
                  padding-left: 0.2rem;
                  padding-bottom: 0.1rem;
                }
              }
              .vw-selectOptions-boxSelectOptions {
                width: max-content;
                .vw-selectOptions-boxScrollOptions {
                  max-height: 60rem;
                  .vw-selectOptions-boxContentScrollItem {
                    .vw-selectOptions-boxIcon {
                      width: 2.1rem;
                      height: 1.6rem;
                      margin-right: 1rem;
                      img {
                        width: 2.1rem;
                        margin-top: 0;
                        margin-right: 1rem;
                      }
                    }
                    .vw-selectOptions-boxScrollItemOptions {
                      margin-left: 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
      &.vw-regis-withWhom {
        width: 33.8rem;
        img {
          margin-top: 0.3rem;
          margin-bottom: 3.6rem;
        }
        .vw-regis-safeArea {
          width: 100%;
          .vw-regis-safeAreaBottom {
            p {
              font-size: 1.2rem;
            }
            margin-top: 19.2rem;
            .vw-select-boxContentInput {
              margin-top: 0.6rem;
              .vw-select-BoxInput {
                input {
                  padding-left: 0rem;
                }
                .vw-select-boxIconRight {
                  padding-left: 0.2rem;
                  padding-bottom: 0.1rem;
                }
              }
              .vw-selectOptions-boxSelectOptions {
                width: max-content;
                background-color: #10172170;
                backdrop-filter: blur(2rem) brightness(115%);
              }
            }
            .vw-input-boxContentInput {
              margin-top: 1rem;
              .vw-input-boxInput {
                padding-left: 0.9rem;
                background-color: #33384350;
                input {
                  padding-left: 0rem;
                  font-size: 1rem;
                  color: #798089;
                }
              }
            }
          }
        }
      }
      &.vw-regis-lengthOfStudy {
        width: 37.3rem;
        padding-left: 3.55rem;
        img {
          margin-top: 0.7rem;
          margin-bottom: 3.7rem;
          margin-right: 3.9rem;
        }
        .vw-regis-safeArea {
          .vw-regis-contentOptions {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 0.3rem;
            padding-top: 0rem;
            .vw-regis-safeAreaItems {
              width: 9.6rem;
              margin-bottom: 0.7rem;
            }
          }
          .vw-regis-safeAreaBottom {
            p {
              font-size: 1.1rem;
            }
            margin-top: 18.5rem;
            .vw-select-boxContentInput {
              margin-top: 0.6rem;
              .vw-select-BoxInput {
                input {
                  padding-left: 0rem;
                }
                .vw-select-boxIconRight {
                  padding-left: 0.2rem;
                  padding-bottom: 0.1rem;
                }
              }
              .vw-selectOptions-boxSelectOptions {
                width: max-content;
                .vw-selectOptions-boxScrollOptions {
                  max-height: 30rem;
                }
              }
            }
          }
        }
      }
      &.vw-regis-yourTitle {
        width: 33.6rem;
        margin-right: 0;
        border-right: 1px solid transparent;
        padding-left: 3.6rem;

        h3 {
          padding-left: 0.3rem;
          margin-right: 0rem;
        }
        img {
          margin-right: 0rem;
          margin-bottom: 3.8rem;
          margin-top: 0.6rem;
        }
        .vw-regis-safeArea {
          width: calc(100% + 0.2rem);
          padding-right: 0;
          margin-right: -0.2rem;
          .vw-regis-safeAreaItems {
            &.checked {
              background-color: var(--info-color);
              color: white;
              font-weight: 700;
            }
          }
          .vw-contentMiniForm-select {
            .vw-select-boxContentInput {
              .vw-select-BoxInput {
                input {
                  text-align: center;
                  padding-left: 2.1rem;
                }
                .vw-select-boxIconRight {
                  padding-bottom: 0.3rem;
                }
              }
              .vw-selectOptions-boxSelectOptions {
                max-height: auto;
                width: max-content;
              }
            }
          }
          .vw-contentMiniForm-input {
            .vw-input-boxContentInput {
              .vw-input-boxInput {
                padding-left: 0rem;
                input {
                  text-align: center;
                  font-size: 1rem;
                }
                .vw-select-boxIconRight {
                  padding-bottom: 0.3rem;
                }
              }
            }
          }
          .vw-regis-safeAreaBottom {
            margin-top: 5.8rem;
            .vw-upload-boxContainerInputUpload {
              .vw-upload-boxLabel {
                margin-bottom: 0.6rem;
                .vw-upload-boxLabelText {
                  font-size: 1.2rem;
                }
              }
              .vw-upload-boxInputUpload {
                height: 7.8rem;
                .vw-upload-boxIconUpload {
                  margin-top: 0.5rem;
                  flex-direction: column;
                  svg {
                    width: 2.2rem;
                    margin-right: 2rem;
                    margin-top: 0.2rem;
                  }
                  label {
                    font-size: 1rem;
                    margin-right: 2rem;
                    margin-top: 0.3rem;
                  }
                }
              }
              &.vw-filled {
                .vw-upload-boxInputUpload {
                  .vw-upload-boxIconUpload {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    svg {
                      width: 2rem;
                      margin-left: -0.3rem;
                      margin-top: -0.3rem;
                      color: #de5e5e;
                    }
                    label {
                      width: min-content;
                      margin-top: -0.3rem;
                      margin-left: -1rem;
                    }
                  }
                  .vw-upload-boxIconClose {
                    svg {
                      width: 2rem;
                      margin-top: 0.2rem;
                      margin-left: 0.2rem;
                    }
                  }
                }
              }
            }
          }
        }
      }

      h3 {
        color: #bebebe;
        font-size: 1.6rem;
        font-family: var(--font-primary);
        font-weight: 700;
        margin-top: -0.1rem;
        margin-bottom: 2.7rem;
        margin-right: 3.6rem;
      }
      img {
        margin-right: 3.6rem;
        margin-bottom: 2.9rem;
      }
      .vw-regis-safeArea {
        width: 100%;
        padding-right: 3.75rem;
        p {
          font-size: 1.1rem;
          font-family: var(--font-primary);
          font-weight: 700;
          color: #bebebe;
          margin-bottom: 0.5rem;
        }
        .vw-regis-safeAreaItems {
          width: 100%;
          height: 3.4rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #333843;
          margin-bottom: 1rem;
          border-radius: 0.5rem;
          font-size: 1rem;
          font-family: var(--font-secondary);
          color: #798089;
          cursor: pointer;
          input {
            display: none;
          }
          &.checked {
            background-color: var(--info-color);
            color: white;
            font-weight: 700;
          }
          :last-of-type {
            margin-bottom: 0px;
          }
        }
      }
    }
  }
`

export const BoxUploadBox = styled.div``

export const ContentMiniForm = styled.div`
  .vw-input-boxContentInput {
    .vw-input-boxInput {
      height: 3.4rem;
    }
  }
  .vw-select-boxContentInput {
    .vw-select-BoxInput {
      height: 3.4rem;
      padding-left: 0.8rem;
      .vw-select-inputFieldStyle {
        font-size: 1rem;
        line-height: 1.3rem;
        padding-top: 0.1rem;
      }
      .vw-select-boxIconRight {
        width: 3rem;
        max-width: 3rem;
        min-width: 3rem;
        padding: 0;
        svg {
          width: 1rem;
          margin-top: 0.4rem;
          margin-left: 0.2rem;
        }
      }
    }
  }
`

type BoxContentCards = {
  selectedIndex?: number
}

export const BoxContentCards = styled.div<BoxContentCards>`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  .card-item {
    display: none;
    ${(props) => {
      return css`
        &.item-${props.selectedIndex} {
          width: 100%;
          display: block;
        }
      `
    }}
    .innerCardBox {
      display: flex;
    }
  }
`

export const SafeAreaClickDelete = styled.div`
  width: 100%;
`
