import useAuth from '@src/hooks/useAuth'
import { useEffect, useState } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { getDatas } from '@src/utils/getDatas'
import { LoadingTable } from '@src/pages/administration/common/_components/loading-table'

const PrivateRouteCheck = () => {
  const isLogged = useAuth()
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    if (isLogged) {
      gets()
    }
  }, [isLogged])

  async function gets() {
    await getDatas()
    setCompleted(true)
  }

  if (isLogged) {
    if (!completed) {
      return <LoadingTable />
    }
    return <Outlet />
  }
  return <Navigate to="/login" />
}

export default PrivateRouteCheck
