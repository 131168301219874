/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { ReasonDropoutType } from '@viswals/services'

import { Icon } from '@viswals/components-platform'

import svgTool from '../components/svg_tool.svg'

export type ItemReportUnusualProps = {
  itemReport: any
  platformLabel: string
  isSelected: boolean
}

export default function ItemReportUnusual({
  itemReport,
  platformLabel,
}: ItemReportUnusualProps) {
  const itemId = `itemList-${itemReport.uuid}`

  const getReasonLabel = (value: ReasonDropoutType) => {
    if (value === 'NOT_USE') return 'I do not use the app'
    if (value === 'DISLIKE') return 'I dislike the product'
    if (value === 'WHAT_EXPECTED') return 'It wasn’t what I expected'
    if (value === 'OTHER') return 'Other'
    return undefined
  }

  return (
    <div
      className={`itemList itemList-report ${
        !itemReport.read ? 'isEnabled' : ''
      }`}
      id={itemId}
    >
      <div
        className={`tbOne ${
          itemReport.platform === 'PATIENT'
            ? 'app'
            : itemReport.platform === 'CLINICIAN'
            ? 'clinician'
            : itemReport.platform === 'CLINIC'
            ? 'clinic'
            : ''
        }`}
      >
        <div className="tbOneIconBox">
          <Icon name="Viswals3" />
        </div>
        <div className="labelTbOne">{platformLabel}</div>
      </div>
      <div className="tbTwo">{itemReport.accountUsername}</div>
      <div className="tbThree">{itemReport.accountUsername}</div>
      <div className="tbFour">
        {moment(itemReport.createdAt).format('DD MMMM YYYY')}
      </div>
      <div className="tbFive">
        {moment(itemReport.createdAt).format('HH:mm a')}
      </div>

      <div className="tbSix">{getReasonLabel(itemReport.reason)}</div>

      <div className="iconRight" id={`item-icon-${itemReport.uuid}`}>
        <img src={svgTool} alt="More" />
      </div>
    </div>
  )
}
