// eslint-disable-next-line @typescript-eslint/no-explicit-any
const envSettings = window as any
const { REACT_APP_GATEWAY_URL, REACT_APP_GOOGLE_API_KEY } = process.env

export default class Config {
  static REACT_APP_GATEWAY_URL =
    REACT_APP_GATEWAY_URL ?? envSettings.GATEWAY_URL

  static WS_URL = REACT_APP_GATEWAY_URL?.replace('https://', 'wss://')

  static REACT_APP_GOOGLE_API_KEY =
    REACT_APP_GOOGLE_API_KEY ?? envSettings.GOOGLE_API_KEY
}
