import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 4.4rem 2.5rem 2.5rem 5rem;

  .vw-contactInformation-header {
    width: 100%;

    h2 {
      font-size: 2rem;
      font-weight: 700;
      color: var(--gray-100-color);
      margin-bottom: 0.3rem;
    }

    span {
      font-size: 1.3rem;
      color: var(--gray-200-color);
    }
  }

  .vw-contactInformation-cardArea {
    display: flex;
    .vw-card-cardBox {
      width: 48.4rem;
      padding: 0 2.5rem 5rem;
      margin-right: 2.5rem;

      text-align: center;
      color: var(--gray-100-color);
      position: relative;

      &:last-of-type {
        margin-right: 0;
      }

      .vw-select-BoxInput {
        background: #242426;
      }

      .vw-input-boxInput {
        background: #242426;
      }

      .vw-contactInformation-img {
        height: 40rem;
        padding-top: 8.9rem;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        img {
          width: 29.4rem;
        }
        &.img2 {
          padding-top: 15.1rem;

          img {
            width: 19.2rem;
          }
        }

        &.img3 {
          padding-top: 11.8rem;

          img {
            width: 12.9rem;
          }
        }
      }

      .vw-contactInformation-labelIcon {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .vw-icon {
          width: 2rem;
          margin: 0 0.3rem 0.6rem 0;
          color: var(--gray-200-color);
        }
      }
      p {
        font-family: var(--font-primary);
        font-weight: 700;
        font-size: 1.4rem;
        text-align: start;

        margin-bottom: 1rem;
      }

      .vw-oldForm {
        .vw-selectOptions-boxSelectOptions {
          width: 25.9rem;
          background: #24242670;
        }

        .vw-input-boxContentInput {
          margin-bottom: 1rem;
        }
        &.vw-emailAndPhone {
          .vw-selectOptions-boxSelectOptions {
            right: -0.9rem;
            top: -44.4rem;
            height: 66.4rem;
            width: 32rem;

            .vw-selectOptions-boxSelectOptionsLabel {
              text-align: start;
              padding-bottom: 1.8rem;
              margin-top: 1.6rem;
            }
            .vw-selectOptions-boxScrollOptions {
              height: 51rem;
              .vw-selectOptions-boxContentScrollItem {
                .vw-selectOptions-boxImage {
                  img {
                    margin-top: 0.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Container

export const ContainertMessageToolTip = styled.div`
  width: 100%;
  padding-bottom: 1.3rem;
  .vw-tooltip-tooltipChildren {
    height: 10.4rem;
    padding: 1.8rem 0.9rem 0 2rem;
    background-color: #15aeff;
    color: #fff;
    font-family: var(--font-secondary);
    font-size: 1.1rem;
    line-height: 1.7rem;
    margin-right: 0.1rem;
    position: relative;
    .vw-tooltipChildren-title {
      font-weight: 700;
    }
    &::before {
      content: '';
      width: 1.1rem;
      height: 1.1rem;
      background-color: #15aeff;

      position: absolute;
      bottom: -0.45rem;
      left: 16.4rem;
      transform: rotate(45deg);
    }
  }
`
